import React, { useEffect, useState } from "react";
import TextEditor from "./TextEditor";
import { Grid } from "@mui/material";
import CtCmbEditable from "./CustomTool/CtCmbEditable";
import CtMultiSelect from "../Components/CustomTool/CtMultiSelect";

function MoreInfoTextEditor({ TxtChange, updateDetails, Txtid }) {
  const [textInfo1, setTextInfo1] = useState("");
  const [textTobeSet1, setTextTobeSet1] = useState("");
  const [branch, setBranch] = useState([]);
  const [cmbMulBranch, setCmbMulBranch] = useState([]);

  useEffect(() => {
    setDefaultCondition();
  }, [TxtChange]);

  const setDefaultCondition = () => {
    if (TxtChange !== textTobeSet1) setTextTobeSet1(TxtChange);
  };

  const onEditorStateChange = (ctrlInfo) => {
    let hasChanged = false;

    if (ctrlInfo.id === Txtid && ctrlInfo.value !== textInfo1) {
      setTextInfo1(ctrlInfo.value);
      hasChanged = true;
    }

    if (hasChanged) {
      updateDetails(ctrlInfo.id, ctrlInfo.value);
    }
  };

  const resetEditorStateToUndefined = (id) => {
    if (id === Txtid) {
      setTextTobeSet1(undefined);
    }
  };

  const handleOnChange = (e) => {
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: e.target.value },
    });
  };

  return (
    <>
      <TextEditor
        id={Txtid}
        onEditorStateChange={onEditorStateChange}
        EditorState={textTobeSet1}
        resetEditorStateToUndefined={resetEditorStateToUndefined}
      />
    </>
  );
}

export default MoreInfoTextEditor;
