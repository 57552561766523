import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import HOCVerifyIsUser from "../HOC/HOCVerifyIsUser";
import CtBtn from "../Components/CustomTool/CtBtn";
import CtCmb from "../Components/CustomTool/CtCmb";
import CtTxtNum from "../Components/CustomTool/CtTxtNum";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
// import DepartmentMaster from "./DepartmentMaster";
// import PlantMaster from "./PlantMaster";
// import { fetchUser } from "../Components/API";
import HoCtToastContainer from "../HOC/HoCtToastContainer";
import {
  applyTrimOnObjectValues,
  csvFileName,
} from "../SystemUtility/SystemUtility";
import {
  mapSetUserPrivileges_ToProps,
  mapStatetoProps,
} from "../mapRedux/mapReduxProps";
import { connect } from "react-redux";
import { withRouter } from "../HOC/withRouter";
import CtART from "./CustomTool/ResponsiveTable";
import CtTxtAmt from "../Components/CustomTool/CtTxtAmt";
import CtCmbEditable from "./CustomTool/CtCmbEditable";

class AutoDoorFramePriceFormula extends Component {
  state = {
    Unit: [{ vac_type: "Type" }],
    columnsdata: [
      {
        accessorKey: "Brand",
        header: "Brand",
      },
      {
        accessorKey: "Door Type",
        header: "Door Type",
      },

      {
        accessorKey: "Opening Widht",
        header: "Opening Widht",
      },
      {
        accessorKey: "Opening Heigt",
        header: "Opening Heigt",
      },
      {
        accessorKey: "Item Name",
        header: "Item Name",
      },
      {
        accessorKey: "Item Material",
        header: "Item Material",
      },
      {
        accessorKey: "Part Name",
        header: "Part Name",
      },
      {
        accessorKey: "Part Material",
        header: "Part Material",
      },
      {
        accessorKey: "Qty",
        header: "Qty",
      },
    ],

    rows: [
      {
        vac_Rail: "375",
        vac_Price: "204",
        vac_Unit: "579",
      },
    ],
    formData: {
      cmbPassenger: "",
      cmbInsideWidth: "",
      cmbInsideDepth: "",
      cmbInsideHeight: "",
      cmbPlatformWidth: "",
      cmbPlatformDepth: "",
    },
    dataLoadStatus: true,
    ARD: null,
    rowsCount: 0,
    rowsPerPage: 50,
  };

  //#region Component
  componentDidMount() {
    // document.title = "User Management : Top India";
    localStorage.setItem("Heading", "Auto Door Frame Price Formula Master");
    this.props.verifyUserID(
      undefined,
      this.props.setUserPrivileges,
      this.props.modules.autodoorframe
    );
    // this.ShortCut();
    // this.getInfo();
  }

  //   componentWillUnmount() {
  //     this.removeShortCut();
  //   }

  //#endregion Component

  //#region Handle

  //   ShortCut = () => {
  //     this.removeShortCut = setKeyboardShortcuts(
  //       this.SaveUser,
  //       this.clearInfo,
  //       () => {
  //         this.DeleteData(
  //           this.state.formData.EditSrNo,
  //           this.state.formData.EditUserID
  //         );
  //       }
  //     );
  //   };

  handleOnChange = (e) => {
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: e.target.value },
    });
  };

  handleARDonActionClick_Edit = (row) => {
    this.props.notifyProcessing();
    // alert("click on edit");
    // console.log("row", row);

    this.props.updateProcessing("success", "on edit");
    // console.log("click on edit");
  };
  handleARDonActionClick_Delete = (rtRow) => {
    this.props.notifyProcessing();

    this.props.updateProcessing("success", "on Delete");

    // console.log("rtRow", rtRow);
    // const row = getRowOfRT(rtRow, ["bint_srno", "vac_userid"]);
    // // console.log("row", row.bint_srno, row.vac_userid);
    // this.DeleteData(row.bint_srno, row.vac_userid);
  };

  //#endregion Handle

  //#region function

  clearInfo = () => {};

  trimFormData = (nextFunctionCall) => {
    let formData = applyTrimOnObjectValues({
      formData: this.state.formData,
    });
    this.setState({ formData }, () => {
      if (nextFunctionCall) {
        nextFunctionCall();
      }
    });
  };

  //#endregion function

  render() {
    return (
      <form>
        <Grid container direction="row" justifyContent="flex-end">
          <Grid item>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <CtBtn
                  className="secondary-button"
                  label="Save"
                  iconName="save"
                  onClick={() => {
                    alert("work remain");
                  }}
                />
              </Grid>
              <Grid item>
                <CtBtn
                  label="New"
                  className="new-button"
                  iconName="new"
                  onClick={this.clearInfo}
                />
              </Grid>
              <Grid item>
                <CtBtn
                  label="Delete"
                  className="delete-button"
                  iconName="delete"
                  // onClick={() => {
                  //   this.DeleteData(
                  //     this.state.formData.EditSrNo,
                  //     this.state.formData.txtUserId
                  //   );
                  // }}
                  //   disabled={
                  //     this.state.formData.txtUserId.length > 0 ? false : true
                  //   }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          marginTop={0}
        >
          <Grid item>
            <CtCmb
              id="cmbBrand"
              label="Brand"
              items={this.state.Unit}
              value={this.state.formData.cmbBrand}
              handleOnChange={this.handleOnChange}
              width={220}
              maxLength={100}
              colID="vac_type"
            />
          </Grid>
          <Grid item>
            <CtCmb
              id="cmbDoorType"
              label="Door Type"
              items={this.state.Unit}
              value={this.state.formData.cmbDoorType}
              handleOnChange={this.handleOnChange}
              width={220}
              maxLength={100}
              colID="vac_type"
            />
          </Grid>
          <Grid item>
            <CtCmbEditable
              id="cmbOpeningWidht"
              label="Opening Widht"
              items={this.state.Unit}
              value={this.state.formData.cmbOpeningWidht}
              handleOnChange={this.handleOnChange}
              width={220}
              maxLength={100}
              colID="vac_type"
            />
          </Grid>
          <Grid item>
            <CtCmbEditable
              id="cmbOpeningHeigt"
              label="Opening Heigt"
              items={this.state.Unit}
              value={this.state.formData.cmbOpeningHeigt}
              handleOnChange={this.handleOnChange}
              width={220}
              maxLength={100}
              colID="vac_type"
            />
          </Grid>
          <Grid item>
            <CtCmb
              id="cmbItemName"
              label="Item Name"
              items={this.state.Unit}
              value={this.state.formData.cmbItemName}
              handleOnChange={this.handleOnChange}
              width={220}
              maxLength={100}
              colID="vac_type"
            />
          </Grid>
          <Grid item>
            <CtCmb
              id="cmbItemMaterial"
              label="Item Material"
              items={this.state.Unit}
              value={this.state.formData.cmbItemMaterial}
              handleOnChange={this.handleOnChange}
              width={220}
              maxLength={100}
              colID="vac_type"
            />
          </Grid>
          <Grid item>
            <CtCmbEditable
              id="cmbPartName"
              label="Part Name"
              items={this.state.Unit}
              value={this.state.formData.cmbPartName}
              handleOnChange={this.handleOnChange}
              width={220}
              maxLength={100}
              colID="vac_type"
            />
          </Grid>
          <Grid item>
            <CtCmbEditable
              id="cmbPartMaterial"
              label="Part Material"
              items={this.state.Unit}
              value={this.state.formData.cmbPartMaterial}
              handleOnChange={this.handleOnChange}
              width={220}
              maxLength={100}
              colID="vac_type"
            />
          </Grid>
          <Grid item>
            <CtCmbEditable
              id="cmbQty"
              label="Qty"
              items={this.state.Unit}
              value={this.state.formData.cmbQty}
              handleOnChange={this.handleOnChange}
              width={220}
              maxLength={100}
              colID="vac_type"
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtLength"
              label="Length (in mm)"
              maxLength={100}
              width="220"
              value={this.state.formData.txtLength}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtWidth"
              label="Width (in mm)"
              maxLength={100}
              width="220"
              value={this.state.formData.txtWidth}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtThickness"
              label="Thickness (in mm)"
              maxLength={100}
              width="220"
              value={this.state.formData.txtThickness}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtWeight"
              label="Weight (Kg)"
              maxLength={100}
              width="220"
              value={this.state.formData.txtWeight}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtRunningMeter"
              label="Running Meter"
              maxLength={100}
              width="220"
              value={this.state.formData.txtRunningMeter}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtNoOfPiercing"
              label="No Of Piercing"
              maxLength={100}
              width="220"
              value={this.state.formData.txtNoOfPiercing}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtAmt
              id="txtNoOfBend"
              label="No Of Bend"
              maxLength={100}
              width="220"
              value={this.state.formData.txtNoOfBend}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtAmt
              id="txtArea"
              label="Area (SQ FT)"
              maxLength={100}
              width="220"
              value={this.state.formData.txtArea}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtAmt
              id="txtMaterialPrice"
              label="Material Price"
              maxLength={100}
              width="220"
              value={this.state.formData.txtMaterialPrice}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtAmt
              id="txtRunningMTRPrice"
              label="Running MTR Price"
              maxLength={100}
              width="220"
              value={this.state.formData.txtRunningMTRPrice}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtAmt
              id="txtPiercingPrice"
              label="Piercing Price"
              maxLength={100}
              width="220"
              value={this.state.formData.txtPiercingPrice}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtAmt
              id="txtPWDCoatingPrice"
              label="PWD Coating Price"
              maxLength={100}
              width="220"
              value={this.state.formData.txtPWDCoatingPrice}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtAmt
              id="txtBendingCost"
              label="Bending Cost"
              maxLength={100}
              width="220"
              value={this.state.formData.txtBendingCost}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtAmt
              id="txtWeldingCost"
              label="Welding Cost"
              maxLength={100}
              width="220"
              value={this.state.formData.txtWeldingCost}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtAmt
              id="txtPackingCost"
              label="Packing Cost"
              maxLength={100}
              width="220"
              value={this.state.formData.txtPackingCost}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtAmt
              id="txtTotalCost"
              label="Total Cost"
              maxLength={100}
              width="220"
              value={this.state.formData.txtTotalCost}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          marginTop={1}
        >
          {/* responsive table */}
          <Grid item className="maintable">
            <CtART
              rowsdata={this.state.rows}
              columnsdata={this.state.columnsdata}
              rowsPerPage={this.state.rowsPerPage}
              tblmaxHeight={500}
              csvFileName={csvFileName("Passenger Lift Cabin Price")}
              rowIconAction={[
                {
                  label: "Edit",
                  onClick: this.handleARDonActionClick_Edit,
                  icon: "Edit",
                  className: "secondary-button",
                },
                {
                  label: "Delete",
                  onClick: this.handleARDonActionClick_Delete,
                  icon: "delete",
                  className: "delete-button",
                },
              ]}
              showColumnFilters={false}
              actionColSize={90}
              density={"compact"}
              hideColIdList={["bint_srno"]}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Box display={{ xs: "none" }} style={{ textAlign: "right" }}>
            {this.state.ARD}
          </Box>
        </Grid>
      </form>
    );
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <AutoDoorFramePriceFormula {...props} navigate={navigate} />;
}
export default connect(
  mapStatetoProps,
  mapSetUserPrivileges_ToProps
)(HoCtToastContainer(HOCVerifyIsUser(withRouter(WithNavigate))));
