import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import HOCVerifyIsUser from "../HOC/HOCVerifyIsUser";
import CtBtn from "../Components/CustomTool/CtBtn";
import CtTxt from "../Components/CustomTool/CtTxt";
import CtTextFieldPWD from "../Components/CustomTool/CtTxtPWD";
import CtCmb from "../Components/CustomTool/CtCmb";
import CtTxtNum from "../Components/CustomTool/CtTxtNum";
import MaterialTable from "../Components/CustomTool/MaterialTable";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import AlertResponsiveDialog from "../Components/CustomTool/AlertResponsiveDialog";
// import DepartmentMaster from "./DepartmentMaster";
import { Stack } from "@mui/material";
// import PlantMaster from "./PlantMaster";
// import { fetchUser } from "../Components/API";
import HoCtToastContainer from "../HOC/HoCtToastContainer";
import {
  applyTrimOnObjectValues,
  csvFileName,
  getRowOfRT,
  isValidItem,
  setKeyboardShortcuts,
} from "../SystemUtility/SystemUtility";
import {
  mapSetUserPrivileges_ToProps,
  mapStatetoProps,
} from "../mapRedux/mapReduxProps";
import { connect } from "react-redux";
import { withRouter } from "../HOC/withRouter";
import { fetchDrivePrice, fetchUser } from "./API";
import MobileTable from "./CustomTool/MobileTable";
import CtART from "./CustomTool/ResponsiveTable";
import axios from "axios";
import CtTxtAmt from "../Components/CustomTool/CtTxtAmt";
import CtCmbEditable from "./CustomTool/CtCmbEditable";
import CtMultiLineText from "./CustomTool/CtMultiLineText";

class ControllerMainVfDrivePricing extends Component {
  state = {
    DriveType: [{ vac_type: "INTEGRATED" }, { vac_type: "REGULAR" }],
    DriveName: [],
    CommunicatonType: [{ vac_type: "PARALLEL" }, { vac_type: "SERIAL" }],
    columnsdata: [
      {
        accessorKey: "bint_srno",
        header: "bint_srno",
      },
      {
        accessorKey: "vac_drivetype",
        header: "Drive Type",
      },
      {
        accessorKey: "vac_communicationtype",
        header: "Communicaton Type",
      },

      {
        accessorKey: "vac_drivename",
        header: "Drive Name",
      },
      {
        accessorKey: "dec_kw",
        header: "KW",
      },
      {
        accessorKey: "dec_amp",
        header: "AMP",
      },
      {
        accessorKey: "dec_priceofdrive",
        header: "Price Of Drive",
      },
      {
        accessorKey: "dec_ohms",
        header: "OHMS",
      },
      {
        accessorKey: "dec_watt",
        header: "Watt",
      },
      {
        accessorKey: "dec_priceofbrakingresistor",
        header: "Price Of Braking Resistor",
      },
      {
        accessorKey: "dec_fixcontrollercost",
        header: "Fix Controller Cost (W/O Drive)",
      },
      {
        accessorKey: "dec_totalcost",
        header: "Total Cost",
      },
    ],
    rows: [],

    formData: {
      bint_srno: 0,
      cmbDriveType: "",
      cmbDriveName: "",
      cmbCommunicatonType: "",
      txtKW: "",
      txtAMP: "",
      txtPriceOfDrive: "",
      txtOHMS: "",
      txtWatt: "",
      txtPriceOfBrakingResistor: "",
      txtFixControllerCost: "",
      txtTotalCost: "",
    },
    dataLoadStatus: true,
    ARD: null,
    rowsCount: 0,
    rowsPerPage: 50,
  };

  //#region Component
  componentDidMount() {
    // document.title = "User Management : Top India";
    localStorage.setItem("Heading", "Controller & Main VF Drive Pricing");
    this.props.verifyUserID(
      this.getInfo,
      this.props.setUserPrivileges,
      this.props.modules.controllermainvf
    );
    this.ShortCut();
    // this.getInfo();
  }

  componentWillUnmount() {
    this.removeShortCut();
  }

  //#endregion Component

  //#region Handle

  ShortCut = () => {
    this.removeShortCut = setKeyboardShortcuts(
      this.saveDrivePrice,
      this.clearInfo,
      () => {
        this.DeleteData(
          this.state.formData.bint_srno,
          this.state.formData.cmbDriveName,
          this.state.formData.txtKW
        );
      }
    );
  };

  handleOnChange = (e) => {
    this.setState(
      {
        formData: { ...this.state.formData, [e.target.name]: e.target.value },
      },
      () => {
        if (
          e.target.name == "txtPriceOfDrive" ||
          e.target.name == "txtPriceOfBrakingResistor" ||
          e.target.name == "txtFixControllerCost"
        ) {
          let txtTotalCost =
            Number(this.state.formData.txtPriceOfDrive) +
            Number(this.state.formData.txtPriceOfBrakingResistor) +
            Number(this.state.formData.txtFixControllerCost);

          this.setState({
            formData: {
              ...this.state.formData,
              txtTotalCost,
            },
          });
        }
      }
    );
  };

  handleARDonActionClick_Edit = (rtRow) => {
    const row = getRowOfRT(rtRow, [
      "bint_srno",
      "vac_drivetype",
      "vac_communicationtype",
      "vac_drivename",
      "dec_kw",
      "dec_amp",
      "dec_priceofdrive",
      "dec_ohms",
      "dec_watt",
      "dec_priceofbrakingresistor",
      "dec_fixcontrollercost",
      "dec_totalcost",
    ]);

    this.setState(
      {
        formData: {
          ...this.state.formData,
          bint_srno: row["bint_srno"],
          cmbDriveType: row["vac_drivetype"],
          cmbDriveName: row["vac_drivename"],
          cmbCommunicatonType: row["vac_communicationtype"],
          txtKW: row["dec_kw"],
          txtAMP: row["dec_amp"],
          txtPriceOfDrive: row["dec_priceofdrive"],
          txtOHMS: row["dec_ohms"],
          txtWatt: row["dec_watt"],
          txtPriceOfBrakingResistor: row["dec_priceofbrakingresistor"],
          txtFixControllerCost: row["dec_fixcontrollercost"],
          txtTotalCost: row["dec_totalcost"],
        },
      },
      () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    );
  };
  handleARDonActionClick_Delete = (rtRow) => {
    const row = getRowOfRT(rtRow, ["bint_srno", "vac_drivename", "dec_kw"]);
    this.DeleteData(row.bint_srno, row.vac_drivename, row.dec_kw);
  };

  //#endregion Handle

  //#region function

  getInfo = () => {
    this.props.notifyProcessing();
    const reqData = {
      Op: "ListDrivePriceing",
    };
    fetchDrivePrice(reqData)
      .then((res) => {
        this.props.closeUpdateProcessing();
        let rows =
            res.data.ListDrivePriceing.length > 0
              ? res.data.ListDrivePriceing
              : [],
          rowsCount = rows.length,
          DriveName = res.data.DriveName;
        this.setState({
          rowsCount,
          rows,
          DriveName,
        });
      })
      .catch((error) => {
        console.log("Unknown error occurred in onFormLoad.", error);
      });
    document.getElementById("cmbDriveType").focus();
  };

  clearInfo = () => {
    this.setState(
      {
        formData: {
          ...this.state.formData,
          bint_srno: 0,
          cmbDriveType: "",
          cmbCommunicatonType: "",
          cmbDriveName: "",
          txtKW: "",
          txtAMP: "",
          txtPriceOfDrive: "",
          txtOHMS: "",
          txtWatt: "",
          txtPriceOfBrakingResistor: "",
          txtFixControllerCost: "",
          txtTotalCost: "",
        },
      },
      () => {
        document.getElementById("cmbDriveType").focus();
      }
    );
  };

  trimFormData = (nextFunctionCall) => {
    let formData = applyTrimOnObjectValues({
      formData: this.state.formData,
    });
    this.setState({ formData }, () => {
      if (nextFunctionCall) {
        nextFunctionCall();
      }
    });
  };

  validationDrivePrice = () => {
    let result = false;
    if (this.state.formData.cmbDriveType.length <= 0) {
      this.props.toastErrorMsg("Select Drive Type", "cmbDriveType");
    } else if (this.state.formData.cmbCommunicatonType.length <= 0) {
      this.props.toastErrorMsg(
        "Select Communicaton Type",
        "cmbCommunicatonType"
      );
    } else if (this.state.formData.cmbDriveName.length <= 0) {
      this.props.toastErrorMsg("Select Drive Name", "cmbDriveName");
    } else if (
      !isValidItem(
        this.state.DriveName,
        "vac_drivename",
        this.state.formData.cmbDriveName
      )
    ) {
      this.props.toastErrorMsg("Select Valid Drive Name", "cmbDriveName");
    } else if (this.state.formData.txtKW.length <= 0) {
      this.props.toastErrorMsg("Enter KW", "txtKW");
    } else if (this.state.formData.txtAMP.length <= 0) {
      this.props.toastErrorMsg("Enter AMP", "txtAMP");
    } else if (this.state.formData.txtFixControllerCost.length <= 0) {
      this.props.toastErrorMsg(
        "Enter Fix Controller Cost (W/O Drive)",
        "txtFixControllerCost"
      );
    } else {
      result = true;
    }
    return result;
  };

  saveDrivePrice = () => {
    this.trimFormData(() => {
      if (this.validationDrivePrice()) {
        this.props.notifyProcessing();
        const reqData = {
          Op: "SaveDrivePriceing",
          bint_srno: this.state.formData.bint_srno,
          vac_drivetype: this.state.formData.cmbDriveType,
          vac_communicationtype: this.state.formData.cmbCommunicatonType,
          vac_drivename: this.state.formData.cmbDriveName,
          dec_kw: this.state.formData.txtKW,
          dec_amp: this.state.formData.txtAMP,
          dec_priceofdrive:
            Number(this.state.formData.txtPriceOfDrive) > 0
              ? this.state.formData.txtPriceOfDrive
              : 0,
          dec_ohms:
            Number(this.state.formData.txtOHMS) > 0
              ? this.state.formData.txtOHMS
              : 0,
          dec_watt:
            Number(this.state.formData.txtWatt) > 0
              ? this.state.formData.txtWatt
              : 0,
          dec_priceofbrakingresistor:
            Number(this.state.formData.txtPriceOfBrakingResistor) > 0
              ? this.state.formData.txtWatt
              : 0,
          dec_fixcontrollercost: this.state.formData.txtFixControllerCost,
          dec_totalcost: this.state.formData.txtTotalCost,
        };
        fetchDrivePrice(reqData)
          .then((res) => {
            this.props.updateProcessing(res.data.msgType, res.data.message);
            if (res.data.msgType === "success") {
              let rows = [],
                rowsCount = rows.length,
                DriveName = res.data.DriveName;
              if (
                res.data.ListDrivePriceing &&
                res.data.ListDrivePriceing.length > 0
              ) {
                rows = res.data.ListDrivePriceing;
                rowsCount = rows.length;
              }

              this.setState(
                {
                  rows,
                  rowsCount,
                },
                () => {
                  this.clearInfo();
                }
              );
            }
          })
          .catch((error) => {
            console.log("Unknown error occurred in Save Drive Price.", error);
          });
      }
    });
  };

  DeleteData = (bint_srno, vac_drivename, dec_kw) => {
    if (bint_srno > 0) {
      this.setState({ ARD: null }, () => {
        let ARD = (
          <AlertResponsiveDialog
            labelAgree="Delete"
            labelDisagree="Cancel"
            alertTitle={`Do you want to Delete Controller & Main VF Drive Pricing Where Drive Name : ${vac_drivename} And KW : ${dec_kw}?`}
            alertMessage=""
            handleOnClickYes={() => {
              return this.DeleteDrivePrice(bint_srno);
            }}
            defaultOpenDialog={true}
            onYesClickCloseIfExeSuccessfully={true}
          />
        );
        this.setState({ ARD });
      });
    } else {
      this.props.toastErrorMsg("Select a record for deletion.");
    }
  };

  DeleteDrivePrice = (bint_srno) => {
    this.props.notifyProcessing();
    const reqData = {
      Op: "DeleteDrivePriceing",
      bint_srno: bint_srno,
    };
    fetchDrivePrice(reqData)
      .then((res) => {
        this.props.updateProcessing(res.data.msgType, res.data.message);
        if (res.data.msgType === "success") {
          let rows = [],
            rowsCount = rows.length,
            DriveName = res.data.DriveName;
          if (
            res.data.ListDrivePriceing &&
            res.data.ListDrivePriceing.length > 0
          ) {
            rows = res.data.ListDrivePriceing;
            rowsCount = rows.length;
          }
          this.setState(
            {
              rows,
              rowsCount,
              ARD: null,
            },
            () => {
              this.clearInfo();
            }
          );
        }
      })
      .catch((error) => {
        this.props.updateProcessing(
          "error",
          "Unknown error occurred in delete Drive Price. " + error
        );
      });
    return false;
  };

  //#endregion function

  render() {
    return (
      <form>
        <Grid container direction="row" justifyContent="flex-end">
          <Grid item>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <CtBtn
                  className="secondary-button"
                  label="Save"
                  iconName="save"
                  onClick={() => {
                    this.saveDrivePrice();
                  }}
                />
              </Grid>
              <Grid item>
                <CtBtn
                  label="New"
                  className="new-button"
                  iconName="new"
                  onClick={this.clearInfo}
                />
              </Grid>
              <Grid item>
                <CtBtn
                  label="Delete"
                  className="delete-button"
                  iconName="delete"
                  onClick={() => {
                    this.DeleteData(
                      this.state.formData.bint_srno,
                      this.state.formData.cmbDriveName,
                      this.state.formData.txtKW
                    );
                  }}
                  disabled={
                    Number(this.state.formData.bint_srno) > 0 ? false : true
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          marginTop={0}
        >
          <Grid item>
            <CtCmb
              id="cmbDriveType"
              label="Drive Type*"
              items={this.state.DriveType}
              value={this.state.formData.cmbDriveType}
              handleOnChange={this.handleOnChange}
              width={220}
              maxLength={100}
              colID="vac_type"
            />
          </Grid>
          <Grid item>
            <CtCmb
              id="cmbCommunicatonType"
              label="Communicaton Type*"
              items={this.state.CommunicatonType}
              value={this.state.formData.cmbCommunicatonType}
              handleOnChange={this.handleOnChange}
              width={220}
              maxLength={100}
              colID="vac_type"
            />
          </Grid>
          <Grid item>
            <CtCmbEditable
              id="cmbDriveName"
              label="Drive Name*"
              items={this.state.DriveName}
              value={this.state.formData.cmbDriveName}
              handleOnChange={this.handleOnChange}
              width={220}
              maxLength={100}
              colID="vac_drivename"
            />
          </Grid>
          {/* <Grid item>
            <CtCmbEditable
              id="cmbKW"
              label="KW"
              items={this.state.Unit}
              value={this.state.formData.cmbKW}
              handleOnChange={this.handleOnChange}
              width={220}
              maxLength={100}
              colID="vac_type"
            />
          </Grid> */}
          <Grid item>
            <CtTxtAmt
              id="txtKW"
              label="KW*"
              maxLength={10}
              width="100"
              value={this.state.formData.txtKW}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          {/* <Grid item>
            <CtCmbEditable
              id="cmbAMP"
              label="AMP"
              items={this.state.Unit}
              value={this.state.formData.cmbAMP}
              handleOnChange={this.handleOnChange}
              width={220}
              maxLength={100}
              colID="vac_type"
            />
          </Grid> */}
          <Grid item>
            <CtTxtAmt
              id="txtAMP"
              label="AMP*"
              maxLength={10}
              width="100"
              value={this.state.formData.txtAMP}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtAmt
              id="txtPriceOfDrive"
              label="Price Of Drive"
              maxLength={100}
              width={220}
              value={this.state.formData.txtPriceOfDrive}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
              RupeeSymbol={true}
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtOHMS"
              label="OHMS"
              maxLength={100}
              width={220}
              value={this.state.formData.txtOHMS}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtWatt"
              label="Watt"
              maxLength={100}
              width={220}
              value={this.state.formData.txtWatt}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtAmt
              id="txtPriceOfBrakingResistor"
              label="Price Of Braking Resistor"
              maxLength={100}
              width={220}
              value={this.state.formData.txtPriceOfBrakingResistor}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
              RupeeSymbol={true}
            />
          </Grid>
          <Grid item>
            <CtTxtAmt
              id="txtFixControllerCost"
              label="Fix Controller Cost (W/O Drive)*"
              maxLength={100}
              width={220}
              value={this.state.formData.txtFixControllerCost}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
              RupeeSymbol={true}
            />
          </Grid>
          <Grid item>
            <CtTxtAmt
              id="txtTotalCost"
              label="Total Cost*"
              maxLength={100}
              width={220}
              value={this.state.formData.txtTotalCost}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
              RupeeSymbol={true}
              disabled={true}
            />
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          marginTop={1}
        >
          <Grid item className="maintable">
            <CtART
              rowsdata={this.state.rows}
              columnsdata={this.state.columnsdata}
              rowsPerPage={this.state.rowsPerPage}
              tblmaxHeight={500}
              csvFileName={csvFileName("Controller & Main VF Drive Pricing")}
              rowIconAction={[
                {
                  label: "Edit",
                  onClick: this.handleARDonActionClick_Edit,
                  icon: "Edit",
                  className: "secondary-button",
                },
                {
                  label: "Delete",
                  onClick: this.handleARDonActionClick_Delete,
                  icon: "delete",
                  className: "delete-button",
                },
              ]}
              showColumnFilters={false}
              actionColSize={90}
              density={"compact"}
              hideColIdList={["bint_srno"]}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Box display={{ xs: "none" }} style={{ textAlign: "right" }}>
            {this.state.ARD}
          </Box>
        </Grid>
      </form>
    );
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <ControllerMainVfDrivePricing {...props} navigate={navigate} />;
}
export default connect(
  mapStatetoProps,
  mapSetUserPrivileges_ToProps
)(HoCtToastContainer(HOCVerifyIsUser(withRouter(WithNavigate))));
