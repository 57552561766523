import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import HOCVerifyIsUser from "../HOC/HOCVerifyIsUser";
import CtBtn from "../Components/CustomTool/CtBtn";
import CtTxt from "../Components/CustomTool/CtTxt";
import CtTextFieldPWD from "../Components/CustomTool/CtTxtPWD";
import CtCmb from "../Components/CustomTool/CtCmb";
import CtTxtNum from "../Components/CustomTool/CtTxtNum";
import MaterialTable from "../Components/CustomTool/MaterialTable";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import AlertResponsiveDialog from "../Components/CustomTool/AlertResponsiveDialog";
// import DepartmentMaster from "./DepartmentMaster";
import { Stack } from "@mui/material";
// import PlantMaster from "./PlantMaster";
// import { fetchUser } from "../Components/API";
import HoCtToastContainer from "../HOC/HoCtToastContainer";
import {
  applyTrimOnObjectValues,
  csvFileName,
  getRowOfRT,
  setKeyboardShortcuts,
} from "../SystemUtility/SystemUtility";
import {
  mapSetUserPrivileges_ToProps,
  mapStatetoProps,
} from "../mapRedux/mapReduxProps";
import { connect } from "react-redux";
import { withRouter } from "../HOC/withRouter";
import { fetchManualDoorPrice, fetchUser } from "./API";
import MobileTable from "./CustomTool/MobileTable";
import CtART from "./CustomTool/ResponsiveTable";
import axios from "axios";
import CtTxtAmt from "../Components/CustomTool/CtTxtAmt";
import CtCmbEditable from "./CustomTool/CtCmbEditable";
import readXlsxFile from "read-excel-file";

class ManualDoorPrice extends Component {
  state = {
    Brand: [],
    Type: [],
    columnsdata: [
      {
        accessorKey: "bint_srno",
        header: "Sr No",
      },
      {
        accessorKey: "vac_brand",
        header: "Brand",
      },
      {
        accessorKey: "vac_type",
        header: "Type",
      },

      {
        accessorKey: "bint_opening",
        header: "Opening",
      },
      {
        accessorKey: "dec_price",
        header: "Price",
      },
    ],

    rows: [],
    validRows: [],
    inValidRows: [],
    formData: {
      bint_srno: 0,
      cmbBrand: "",
      cmbType: "",
      txtOpening: "",
      txtPrice: "",
    },
    dataLoadStatus: true,
    ARD: null,
    rowsCount: 0,
    rowsPerPage: 50,
  };

  //#region Component
  componentDidMount() {
    // document.title = "User Management : Top India";
    localStorage.setItem("Heading", "Manual Door Price ");
    this.props.verifyUserID(
      this.getInfo,
      this.props.setUserPrivileges,
      this.props.modules.manualdoor
    );
    // this.ShortCut();
  }

  //   componentWillUnmount() {
  //     this.removeShortCut();
  //   }

  //#endregion Component

  //#region Handle

  //   ShortCut = () => {
  //     this.removeShortCut = setKeyboardShortcuts(
  //       this.SaveUser,
  //       this.clearInfo,
  //       () => {
  //         this.DeleteData(
  //           this.state.formData.EditSrNo,
  //           this.state.formData.EditUserID
  //         );
  //       }
  //     );
  //   };

  handleOnChange = (e) => {
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: e.target.value },
    });
  };

  handleARDonActionClick_Edit = (rtRow) => {
    const row = getRowOfRT(rtRow, [
      "bint_srno",
      "bint_opening",
      "vac_type",
      "vac_brand",
      "dec_price",
    ]);

    const bint_srno = row.bint_srno,
      cmbBrand = row.vac_brand,
      cmbType = row.vac_type,
      txtOpening = row.bint_opening,
      txtPrice = row.dec_price;
    this.setState({
      formData: {
        ...this.state.formData,
        cmbBrand,
        cmbType,
        txtOpening,
        bint_srno,
        txtPrice,
      },
    });
    document.getElementById("cmbBrand").focus();
  };

  handleARDonActionClick_Delete = (rtRow) => {
    const row = getRowOfRT(rtRow, ["bint_srno", "bint_opening"]);
    this.DeleteData(row.bint_srno, row.bint_opening);
  };

  //#endregion Handle

  //#region function

  getInfo = () => {
    this.props.notifyProcessing();
    const reqData = {
      Op: "ListManualDoorPrice",
    };
    fetchManualDoorPrice(reqData)
      .then((res) => {
        this.props.closeUpdateProcessing();
        let rows = res.data.ListManualDoorPrice,
          rowsCount = rows.length;
        this.setState({
          rowsCount,
          rows,
          Brand: res.data.Brand,
          Type: res.data.Type,
        });
      })
      .catch((error) => {
        console.log("Unknown error occurred in onFormLoad.", error);
      });
    document.getElementById("cmbBrand").focus();
  };

  clearInfo = () => {
    this.setState({
      formData: {
        ...this.state.formData,
        bint_srno: 0,
        cmbBrand: "",
        cmbType: "",
        txtOpening: "",
        txtPrice: "",
      },
    });
  };

  trimFormData = (nextFunctionCall) => {
    let formData = applyTrimOnObjectValues({
      formData: this.state.formData,
    });
    this.setState({ formData }, () => {
      if (nextFunctionCall) {
        nextFunctionCall();
      }
    });
  };

  validationManualDoor = () => {
    let result = false;
    if (this.state.formData.cmbBrand.length <= 0) {
      this.props.toastErrorMsg("Select Brand", "cmbBrand");
    } else if (this.state.formData.cmbType.length <= 0) {
      this.props.toastErrorMsg("Select Type", "txtOpening");
    } else if (Number(this.state.formData.txtOpening) <= 0) {
      this.props.toastErrorMsg("Enter Opening", "txtOpening");
    } else if (Number(this.state.formData.txtPrice) <= 0) {
      this.props.toastErrorMsg("Enter Price", "txtPrice");
    } else {
      result = true;
    }
    return result;
  };

  saveManualDoor = () => {
    this.trimFormData(() => {
      if (this.validationManualDoor()) {
        this.props.notifyProcessing();
        const reqData = {
          Op: "SaveManualDoorPrice",
          bint_srno: this.state.formData.bint_srno,
          bint_opening: this.state.formData.txtOpening,
          vac_type: this.state.formData.cmbType,
          vac_brand: this.state.formData.cmbBrand,
          dec_price: this.state.formData.txtPrice,
        };
        fetchManualDoorPrice(reqData)
          .then((res) => {
            this.props.updateProcessing(res.data.msgType, res.data.message);
            if (res.data.msgType === "success") {
              let rows = [],
                rowsCount = rows.length;
              if (
                res.data.ListManualDoorPrice &&
                res.data.ListManualDoorPrice.length > 0
              ) {
                rows = res.data.ListManualDoorPrice;
                rowsCount = rows.length;
              }
              this.setState(
                {
                  rows,
                  rowsCount,
                  Brand: res.data.Brand,
                  Type: res.data.Type,
                },
                () => {
                  this.clearInfo();
                }
              );
            }
          })
          .catch((error) => {
            console.log(
              "Unknown error occurred in Save Manual Door Master .",
              error
            );
          });
      }
    });
  };

  DeleteData = (bint_srno, bint_opening) => {
    if (bint_srno > 0) {
      this.setState({ ARD: null }, () => {
        let ARD = (
          <AlertResponsiveDialog
            labelAgree="Delete"
            labelDisagree="Cancel"
            alertTitle={`Do you want to Delete Opening : ${bint_opening} ?`}
            alertMessage=""
            handleOnClickYes={() => {
              return this.DeleteManualDoor(bint_srno);
            }}
            defaultOpenDialog={true}
            onYesClickCloseIfExeSuccessfully={true}
          />
        );

        this.setState({ ARD });
      });
    } else {
      this.props.toastErrorMsg("Select a record for deletion.");
    }
  };

  DeleteManualDoor = (bint_srno) => {
    this.props.notifyProcessing();
    const reqData = {
      Op: "DeleteManualDoorPrice",
      bint_srno: bint_srno,
    };
    fetchManualDoorPrice(reqData)
      .then((res) => {
        this.props.updateProcessing(res.data.msgType, res.data.message);
        if (res.data.msgType === "success") {
          let rows = [],
            rowsCount = rows.length;
          if (
            res.data.ListManualDoorPrice &&
            res.data.ListManualDoorPrice.length > 0
          ) {
            rows = res.data.ListManualDoorPrice;
            rowsCount = rows.length;
          }
          this.setState(
            {
              rows,
              rowsCount,
              ARD: null,
              Brand: res.data.Brand,
              Type: res.data.Type,
            },
            () => {
              this.clearInfo();
            }
          );
        }
      })
      .catch((error) => {
        this.props.updateProcessing(
          "error",
          "Unknown error occurred in delete Rail Price Master. " + error
        );
      });
    return false;
  };

  validateReadExcel = () => {
    let result = false;
    let fileTypes = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/csv",
    ];
    let FileElement = document.getElementById("input");
    let selectedFile = FileElement.files[0];
    if (selectedFile) {
      if (selectedFile && fileTypes.includes(selectedFile.type)) {
        result = true;
      } else {
        this.props.toastErrorMsg("Please Select Vaild Exel File", "input");
      }
    } else {
      this.props.toastErrorMsg("Please Select Your File", "input");
    }
    // console.log("selectedFile", selectedFile);
    return result;
  };

  ReadExcel = () => {
    if (this.validateReadExcel() === true) {
      this.props.notifyProcessing();
      const input = document.getElementById("input");
      let i = 0,
        columnsdata = [],
        rows = [],
        inValidRows = [],
        validRows = [],
        disableSave = false,
        edit_info = [];
      readXlsxFile(input.files[0]).then((c_row) => {
        c_row.map((r) => {
          if (i == 0) {
            columnsdata = this.createColumn(r);
          } else {
            let row = this.createRow(columnsdata, r);

            if (row.ImportStatus && row.ImportStatus.length > 0) {
              inValidRows.push(row);
            } else {
              validRows.push(row);
            }
            // edit_info.push({
            //   vac_attendanceempcode: row["Attendance Emp Code"],
            //   vac_empcode: row["Employee Code"],
            //   vac_aadharno: row["Aadhar Number"],
            // });
            rows.push(row);
          }
          i = i + 1;
        });
        // console.log("rows", rows);
        disableSave = validRows.length <= 0 ? true : false;
        // this.state.columnsdata.push({
        //   accessorKey: "ImportStatus",
        //   header: "Is Empty, Enter Data & Retry",
        // });

        // console.log("colData", colData);
        this.props.closeUpdateProcessing();
        this.setState(
          {
            // columnsdata: coldata,
            rows,
            inValidRows,
            edit_info,
            validRows,
            totalRows: rows,
            disableSave,
          },
          () => {
            // this.duplicateStatus();
          }
        );
      });
    }
  };

  createColumn = (columnsdata) => {
    // console.log("columnsdata", columnsdata);
    let cols = [];
    // cols.push({
    //   id: "ImportStatus",
    //   label: "Is Empty, Enter Data & Retry",
    //   width: 100,
    // });
    // cols.push({
    //   id: "emp_info",
    //   label: "Duplicate Status",
    //   width: 100,
    // });
    columnsdata.map((c, index) => {
      cols.push({ id: c.trim(), label: c, width: 120 });
    });

    return cols;
  };

  createRow = (columnsdata, rows) => {
    // console.log("columnsdata in row", columnsdata);
    let returnrow = {},
      blankFeild = [];

    const checkRowsBlank = (index, c, accessorKey) => {
      if (c === "" || c === undefined || c === null) {
        blankFeild.push(accessorKey);
      }
    };

    rows.map((c, index) => {
      // console.log("columnsdata[index].id", columnsdata[index].id);
      if (
        columnsdata[index].id == "BRAND" ||
        columnsdata[index].id == "TYPE" /* ||
        columnsdata[index + 1].id == "Sub Dept." ||
        columnsdata[index + 1].id == "Name" ||
        columnsdata[index + 1].id == "Employee Category" ||
        columnsdata[index + 1].id == "Designation" ||
        columnsdata[index + 1].id == "Contractor" ||
        columnsdata[index + 1].id == "Father Name" ||
        columnsdata[index + 1].id == "Gender" ||
        columnsdata[index + 1].id == "Attendance Emp Code" */ /*  ||
        columnsdata[index + 1].id == "DOJ" */
      ) {
        checkRowsBlank(index, c, columnsdata[index].id);
      } /* else if (columnsdata[index + 1].id == "DOB") {
        if (c != undefined && c != null && c.length >= 8) {
          c = get_DMY_from_YMD(this.formatDate(c));
        } else {
          c = "";
        }
      } else if (columnsdata[index + 1].id == "DOJ") {
        if (c != undefined && c != null && c.length >= 8) {
          c = get_DMY_from_YMD(this.formatDate(c));
        } else {
          c = "";
        }
        checkRowsBlank(index, c, columnsdata[index + 1].id);
      } else if (columnsdata[index + 1].id == "Present State") {
        c = this.getSateFromPincode(rows[19]);
      } else if (columnsdata[index + 1].id == "Permanent State") {
        c = this.getSateFromPincode(rows[26]);
      } else if (columnsdata[index + 1].id == "Shift") {
        c = this.getFullNameShift(c);
      } else if (columnsdata[index + 1].id == "W/O") {
        c = this.getFullDayName(c);
      } */ else if (c == undefined || c == null) {
        c = "";
      }
      returnrow[this.getColId(columnsdata[index].id.trim())] = c;
    });

    let ImportStatus = blankFeild.join(", ");

    returnrow = { ...returnrow, ImportStatus: ImportStatus };
    blankFeild = [];
    return returnrow;
  };

  getColId = (colHeader) => {
    let colID = "";
    this.state.columnsdata.filter((row) => {
      if (row.header.toUpperCase() == colHeader) {
        colID = row;
      }
    });
    return colID.accessorKey;
  };

  ImportExcel = () => {
    this.setState({ ARD: null }, () => {
      // if (
      //   this.state.validRows.length > 0 ||
      //   this.state.inValidRows.length > 0
      // ) {
      let ARD = (
        <AlertResponsiveDialog
          labelPrimary="Yes"
          labelDisagree="Cancel"
          alertHeading="Import Excel"
          // alertTitle=
          alertMessage={`Total records in the Excel file: ${
            this.state.validRows.length + this.state.inValidRows.length
          }. ${
            this.state.inValidRows.length > 0
              ? `However, only ${this.state.validRows.length} records were successfully imported. ${this.state.inValidRows.length} records are missing required fields such as "Brand" or "Type".`
              : `All records were imported successfully.`
          }`}
          handleOnClickYes={() => {
            return this.ImportExcelInBE(this.state.validRows);
          }}
          defaultOpenDialog={true}
          onYesClickCloseIfExeSuccessfully={true}
        />
      );

      this.setState({ ARD });
      // }
    });
  };

  ImportExcelInBE = () => {};
  //#endregion function

  render() {
    return (
      <form>
        {/* <Grid container direction="row" justifyContent="flex-end" spacing={2}>
          <Grid item>
            <input type="file" id="input" />
            <CtBtn
              className="excel-button"
              label="Read Excel"
              iconName="excel"
              onClick={this.ReadExcel}
              firstletterBig={false}
            />
          </Grid>
          <Grid item>
            <CtBtn
              className="excel-button"
              label="Import Excel"
              iconName="excel"
              firstletterBig={false}
              onClick={this.ImportExcel}
            />
          </Grid>
        </Grid> */}
        <Grid container direction="row" justifyContent="flex-end" marginTop={2}>
          <Grid item>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <CtBtn
                  className="secondary-button"
                  label="Save"
                  iconName="save"
                  onClick={this.saveManualDoor}
                />
              </Grid>
              <Grid item>
                <CtBtn
                  label="New"
                  className="new-button"
                  iconName="new"
                  onClick={this.clearInfo}
                />
              </Grid>
              <Grid item>
                <CtBtn
                  label="Delete"
                  className="delete-button"
                  iconName="delete"
                  onClick={() => {
                    this.DeleteData(
                      this.state.formData.bint_srno,
                      this.state.formData.txtOpening
                    );
                  }}
                  disabled={this.state.formData.bint_srno > 0 ? false : true}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          marginTop={0}
        >
          <Grid item>
            <CtCmbEditable
              id="cmbBrand"
              label="Brand*"
              items={this.state.Brand}
              value={this.state.formData.cmbBrand}
              handleOnChange={this.handleOnChange}
              width={220}
              maxLength={100}
              colID="vac_brand"
            />
          </Grid>
          <Grid item>
            <CtCmbEditable
              id="cmbType"
              label="Type*"
              items={this.state.Type}
              value={this.state.formData.cmbType}
              handleOnChange={this.handleOnChange}
              width={220}
              maxLength={100}
              colID="vac_type"
            />
          </Grid>
          <Grid item>
            <CtTxtAmt
              id="txtOpening"
              label="Opening*"
              maxLength={10}
              width="100"
              value={this.state.formData.txtOpening}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtAmt
              id="txtPrice"
              label="Price*"
              maxLength={10}
              width="100"
              value={this.state.formData.txtPrice}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
              RupeeSymbol={true}
            />
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          marginTop={1}
        >
          {/* responsive table */}
          <Grid item className="maintable">
            <CtART
              rowsdata={this.state.rows}
              columnsdata={this.state.columnsdata}
              rowsPerPage={this.state.rowsPerPage}
              tblmaxHeight={500}
              csvFileName={csvFileName("Passenger Lift Cabin Price")}
              rowIconAction={[
                {
                  label: "Edit",
                  onClick: this.handleARDonActionClick_Edit,
                  icon: "Edit",
                  className: "secondary-button",
                },
                {
                  label: "Delete",
                  onClick: this.handleARDonActionClick_Delete,
                  icon: "delete",
                  className: "delete-button",
                },
              ]}
              showColumnFilters={false}
              actionColSize={90}
              density={"compact"}
              hideColIdList={["bint_srno"]}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Box display={{ xs: "none" }} style={{ textAlign: "right" }}>
            {this.state.ARD}
          </Box>
        </Grid>
      </form>
    );
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <ManualDoorPrice {...props} navigate={navigate} />;
}
export default connect(
  mapStatetoProps,
  mapSetUserPrivileges_ToProps
)(HoCtToastContainer(HOCVerifyIsUser(withRouter(WithNavigate))));
