import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import HOCVerifyIsUser from "../HOC/HOCVerifyIsUser";
import HoCtToastContainer from "../HOC/HoCtToastContainer";
import "../App.css";
import CtTxt from "../Components/CustomTool/CtTxt";
import CtBtn from "../Components/CustomTool/CtBtn";
import MaterialTable from "../Components/CustomTool/MaterialTable";
import "./Table.css";

import { fetchDepartment } from "../Components/API";
import AlertResponsiveDialog from "../Components/CustomTool/AlertResponsiveDialog";
import Progress from "../Components/CustomTool/Progress";
import {
  applyTrimOnObjectValues,
  csvFileName,
  get_30DaysBack_FromCurrentDate,
  get_YMD_from_SYS,
} from "../SystemUtility/SystemUtility";
import ProposalEntry from "./ProposalEntry";
import { withRouter } from "../HOC/withRouter";
import { connect } from "react-redux";
import {
  mapSetUserPrivileges_ToProps,
  mapStatetoProps,
} from "../mapRedux/mapReduxProps";
import CtDtp from "./CustomTool/CtDtp";
import TableList from "./CustomTool/TableList";
import { Done } from "@mui/icons-material";

class SummaryDetaild extends Component {
  state = {
    columns: [
      {
        id: "Description",
        label: "Description",
      },
      {
        id: "Per",
        label: "%",
        width: "100px",
        type: "CtTxtAmt",
        CtrlProps: {
          width: "100%",
          maxLength: 10,
          icon: <Done />,
          visiblecondition: [
            {
              colID: "PerShow",
              value: "true",
              relationalOperator: "==",
            },
          ],
        },
      },
      // {
      //   id: "Amount",
      //   label: "Amount",
      //   type: "CtTxtSave",
      //   CtrlProps: {
      //     width: "100%",
      //     type: "Amt",
      //     maxLength: 10,
      //     icon: <Done />,
      //     visiblecondition: [
      //       {
      //         colID: "AmtDisabled",
      //         value: "true",
      //         relationalOperator: "==",
      //       },
      //     ],
      //   },
      // },
      {
        id: "Amount",
        label: "Amount",
        type: "CtTxtAmt",
        CtrlProps: {
          width: "100%",
          type: "Amt",
          maxLength: 10,
          icon: <Done />,
          // visiblecondition: [
          //   {
          //     colID: "AmtDisabled",
          //     value: "true",
          //     relationalOperator: "==",
          //   },
          // ],
          disablecondition: [
            {
              colID: "AmtDisabled",
              value: "true",
              relationalOperator: "==",
            },
          ],
          visiblelablecondition: [
            {
              colID: "AmtDisabled",
              value: "true",
              relationalOperator: "==",
            },
          ],
        },
      },
    ],
    rows: [
      // {
      //   Description: "A. RAIL BRACKET WITH HARDWARE",
      //   Per: "",
      //   Amount: 0,
      //   AmtDisabled: "true",
      // },
      // {
      //   Description: "B. LANDING & CAR DOOR COMPONENTS",
      //   Per: "",
      //   Amount: 0,
      //   AmtDisabled: "true",
      // },
      // {
      //   Description: "C. MACHINE & MACHINE BASE",
      //   Per: "",
      //   Amount: 0,
      //   AmtDisabled: "true",
      // },
      // {
      //   Description: "D. CAR FRAME & C/W FRAME",
      //   Per: "",
      //   AmtDisabled: "true",
      //   Amount: 0,
      // },
      // {
      //   Description: "E. ROPING",
      //   Per: "",
      //   Amount: 0,
      //   AmtDisabled: "true",
      // },
      // {
      //   Description: "F. CABIN & ACCESSORIES",
      //   Per: "",
      //   Amount: 0,
      //   AmtDisabled: "true",
      // },
      // {
      //   Description: "G. CONTROLLER & WIRING ACCESSORIES",
      //   Per: "",
      //   Amount: 0,
      //   AmtDisabled: "true",
      // },
      // {
      //   Description: "H. MANUFACTURING LABOUR AND EXPENSE ",
      //   Per: "",
      //   Amount: 0,
      //   AmtDisabled: "true",
      // },
      // { Description: "EXTRA -1", Per: "", Amount: 0 },
      // { Description: "EXTRA -2", Per: "", Amount: 0 },
      // {
      //   Description: "TOP MATERIAL POCKET COST FOR KIT",
      //   Per: "",
      //   Amount: 0,
      //   AmtDisabled: "true",
      // },
      // {
      //   Description: "I. ERECTION, TRANSPORTATION & HOISTING",
      //   Per: "",
      //   Amount: 0,
      //   AmtDisabled: "true",
      // },
      // {
      //   Description: "TOP POKET COSTING",
      //   Per: "",
      //   Amount: 0,
      //   AmtDisabled: "true",
      // },
      // {
      //   Description: "TOP SALES PROFIT %AGE FOR MARKET",
      //   PerShow: "true",
      //   Per: "",
      //   Amount: 0,
      //   AmtDisabled: "true",
      // },
      // {
      //   Description: "TOP SALES COST FINAL TO MARKET",
      //   Per: "",
      //   Amount: 0,
      //   AmtDisabled: "true",
      // },
    ],
    formData: {
      dtpfromdate: get_30DaysBack_FromCurrentDate(new Date()),
      dtptodate: get_YMD_from_SYS(new Date()),
    },
    rowsCount: 0,
    rowsPerPage: 50,
    dataLoadStatus: true,
    ARD: null,
  };

  //#region component

  componentDidMount() {
    localStorage.setItem("Heading", "Price Calculator List");
    // document.title = "Top India Elevator : Input Parameters List";
    // this.ShortCut();
    // // this.onFormLoad();
    this.props.verifyUserID(
      this.getInfo,
      this.props.setUserPrivileges,
      this.props.modules.proposal
    );
  }

  // componentWillUnmount() {
  //   this.removeShortCut();
  // }

  //#endregion component

  //#region function

  // ShortCut = () => {
  //   this.removeShortCut = setKeyboardShortcuts(
  //     this.SaveDepartmentMaster,
  //     this.clearInfo,
  //     () => {
  //       this.DeleteData(this.state.formData.EditDepartment);
  //     }
  //   );
  // };

  getInfo = () => {
    this.setState({
      rows: this.props.PriceSummary,
    });
  };

  getUpdateRows = (rows) => {
    let Total = 0;
    let BeforeTopSalesTotal = 0;

    const updatedRows = rows.map((Strow) => {
      const matchingRow = this.props.ListPOutAllSummary.find(
        (row) => row.vac_title === Strow.Description
      );

      if (matchingRow) {
        if (
          matchingRow.vac_title !== "I. ERECTION, TRANSPORTATION & HOISTING"
        ) {
          Total += Number(matchingRow.dec_value);
          BeforeTopSalesTotal += Number(matchingRow.dec_value);
        } else {
          BeforeTopSalesTotal += Number(matchingRow.dec_value);
        }
        return { ...Strow, Amount: Number(matchingRow.dec_value) };
      }

      if (
        Strow.Description === "EXTRA -1" ||
        Strow.Description === "EXTRA -2"
      ) {
        Total += Number(Strow.Amount);
        BeforeTopSalesTotal += Number(Strow.Amount);
        return Strow;
      }

      return Strow;
    });

    let TopSalesProfit = 0;
    const finalRows = updatedRows.map((row) => {
      if (row.Description === "TOP MATERIAL POCKET COST FOR KIT") {
        return { ...row, Amount: Total };
      }

      if (row.Description === "TOP POKET COSTING") {
        return { ...row, Amount: BeforeTopSalesTotal };
      }

      if (row.Description == "TOP SALES PROFIT %AGE FOR MARKET") {
        TopSalesProfit = (Number(row.Per) * BeforeTopSalesTotal) / 100;
        return { ...row, Amount: TopSalesProfit };
      }

      if (row.Description == "TOP SALES COST FINAL TO MARKET") {
        console.log("TopSalesProfit", TopSalesProfit);
        let FinalCost = BeforeTopSalesTotal + TopSalesProfit;
        FinalCost = Math.round(FinalCost);
        return { ...row, Amount: FinalCost };
      }

      return row;
    });

    return finalRows;
  };

  trimFormData = (nextFunctionCall) => {
    let formData = applyTrimOnObjectValues({
      formData: this.state.formData,
    });
    this.setState({ formData }, () => {
      if (nextFunctionCall) {
        nextFunctionCall();
      }
    });
  };

  //#endregion function

  //#region handle

  handleOnChange = (e) => {
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: e.target.value },
    });
  };

  handleOnRowDataChange = (rows, colID) => {
    console.log("rows changes", rows);
    let finalRows = this.getUpdateRows(rows);
    this.setState(
      {
        rows: finalRows,
        rowsCount: rows.length,
      },
      () => {
        // console.log("colID", colID);
        if (rows[colID].Description == "EXTRA -1") {
          console.log(rows[colID].Amount);
          this.props.setAmtPer(
            Number(rows[colID].Amount),
            Number(rows[9].Amount),
            Number(rows[13].Per)
          );
        } else if (rows[colID].Description == "EXTRA -2") {
          console.log(rows[colID].Amount);
          this.props.setAmtPer(
            Number(rows[8].Amount),
            Number(rows[colID].Amount),
            Number(rows[13].Per)
          );
        } else if (
          rows[colID].Description == "TOP SALES PROFIT %AGE FOR MARKET"
        ) {
          console.log(rows[colID].Per);
          this.props.setAmtPer(
            Number(rows[8].Amount),
            Number(rows[9].Amount),
            Number(rows[colID].Per)
          );
        }
      }
    );
  };

  // handleOnRowDataSave = (rows) => {
  //   this.setState({
  //     rows,
  //     rowsCount: rows.length,
  //   });
  // };

  //#endregion handle

  render() {
    let rowsCount = this.state.rows.length;
    return (
      <form>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          marginTop={0}
          //   spacing={3}
        >
          {/* <Grid item marginTop={1.5}>
            <CtBtn
              label={"Back"}
              iconName={"left"}
              className={"child-button"}
              variant={"outlined"}
              firstletterBig={false}
              onClick={() => {
                this.props.navigateTo("InputParametersEntry");
              }}
            />
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              spacing={3}
            >
              <Grid item>
                <CtTxt
                  id="txtSiteName"
                  value={this.state.formData.txtSiteName}
                  label="Site Name"
                  handleOnChange={this.handleOnChange}
                  maxLength={100}
                  width={250}
                  disabled={true}
                />
              </Grid>
              <Grid item>
                <CtTxt
                  id="txtDateTime"
                  value={this.state.formData.txtDateTime}
                  label="Date Time"
                  handleOnChange={this.handleOnChange}
                  maxLength={100}
                  width={250}
                  disabled={true}
                />
              </Grid>
            </Grid>
          </Grid> */}

          {/* <Grid item marginTop={1.5}>
            <CtBtn
              label={"Submit"}
              iconName={"save"}
              //   className={"child-button"}
              //   variant={"outlined"}
              firstletterBig={false}
              onClick={() => {
                this.props.toastMsg("success", "Submit");
              }}
            />
          </Grid> */}
        </Grid>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          className="title"
          marginTop={3}
        >
          <Grid item marginTop={3} className="maintable">
            <TableList
              columns={this.state.columns}
              rows={this.state.rows}
              rowsCount={rowsCount}
              rowsPerPage={this.state.rowsPerPage}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              handleOnRowDataChange={this.handleOnRowDataChange}
              // handleOnRowDataSave={this.handleOnRowDataSave}
            />
          </Grid>
          <Grid item>
            <Box display={{ xs: "none" }} style={{ textAlign: "right" }}>
              {this.state.ARD}
            </Box>
          </Grid>
        </Grid>
      </form>
    );
  }
}

// export default withRouter(SummaryDetaild);

function WithNavigate(props) {
  let navigate = useNavigate();
  return <SummaryDetaild {...props} navigate={navigate} />;
}
export default connect(
  mapStatetoProps,
  mapSetUserPrivileges_ToProps
)(HoCtToastContainer(HOCVerifyIsUser(withRouter(WithNavigate))));
