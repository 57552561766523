import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import HOCVerifyIsUser from "../HOC/HOCVerifyIsUser";
import CtBtn from "./CustomTool/CtBtn";
import CtTxt from "./CustomTool/CtTxt";
import CtTextFieldPWD from "./CustomTool/CtTxtPWD";
import CtCmb from "./CustomTool/CtCmb";
import CtTxtNum from "./CustomTool/CtTxtNum";
import MaterialTable from "./CustomTool/MaterialTable";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";
// import DepartmentMaster from "./DepartmentMaster";
import { Stack } from "@mui/material";
// import PlantMaster from "./PlantMaster";
// import { fetchUser } from "../Components/API";
import HoCtToastContainer from "../HOC/HoCtToastContainer";
import {
  applyTrimOnObjectValues,
  csvFileName,
  getRowOfRT,
  setKeyboardShortcuts,
} from "../SystemUtility/SystemUtility";
import {
  mapSetUserPrivileges_ToProps,
  mapStatetoProps,
} from "../mapRedux/mapReduxProps";
import { connect } from "react-redux";
import { withRouter } from "../HOC/withRouter";
import { fetchAutoDoorMainPrice, fetchUser } from "./API";
import MobileTable from "./CustomTool/MobileTable";
import CtART from "./CustomTool/ResponsiveTable";
import axios from "axios";
import CtTxtAmt from "./CustomTool/CtTxtAmt";
import CtCmbEditable from "./CustomTool/CtCmbEditable";
import readXlsxFile from "read-excel-file";

class AutoDoorMainPrice extends Component {
  state = {
    Unit: [{ vac_type: "Type" }],
    Opening: [{ vac_type: "2000" }],
    Brand: [],
    DoorType: [],
    MaterialGrade: [
      { vac_grade: "MS" },
      { vac_grade: "SS J3" },
      { vac_grade: "SS 304" },
      { vac_grade: "SS 316L" },
    ],

    columnsdata: [
      {
        accessorKey: "bint_srno",
        header: "bint_srno",
        enableColumnDragging: false,
        enableColumnActions: false,
        enableResizing: false,
        enableSorting: false,
        size: 70,
      },
      {
        accessorKey: "vac_brand",
        header: "Brand",
        enableColumnDragging: false,
        enableColumnActions: false,
        enableResizing: false,
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: "vac_doortype",
        header: "Door Type",
        enableColumnDragging: false,
        enableColumnActions: false,
        enableResizing: false,
        enableSorting: false,
        size: 100,
      },
      {
        accessorKey: "bint_opening",
        header: "Opening",
        enableColumnDragging: false,
        enableColumnActions: false,
        enableResizing: false,
        enableSorting: false,
        size: 80,
      },

      {
        accessorKey: "vac_materialgrade",
        header: "Material Grade",
        enableColumnDragging: false,
        enableColumnActions: false,
        enableResizing: false,
        enableSorting: false,
        size: 120,
      },
      {
        accessorKey: "dec_cardoorpanel",
        header: "Car Door Panel Rate",
        enableColumnDragging: false,
        enableColumnActions: false,
        enableResizing: false,
        enableSorting: false,
        size: 160,
      },
      {
        accessorKey: "dec_landing_frame",
        header: "Landing Door + Door Frame Rate",
        enableColumnDragging: false,
        enableColumnActions: false,
        enableResizing: false,
        enableSorting: false,
      },
      {
        accessorKey: "dec_extraframe",
        header: "Extra If 60x120 Door Frame",
        enableColumnDragging: false,
        enableColumnActions: false,
        enableResizing: false,
        enableSorting: false,
      },
      {
        accessorKey: "dec_extraglass",
        header: "Extra If Glass Door",
        enableColumnDragging: false,
        enableColumnActions: false,
        enableResizing: false,
        enableSorting: false,
      },
    ],

    rows: [],
    formData: {
      bint_srno: 0,
      cmbBrand: "",
      txtOpening: "",
      cmbDoorType: "",
      cmbMaterialGrade: "",
      txtCarDoorPanelRate: "",
      txtLandingDoorDoorFrameRate: "",
      txtExtraIfDoorFrame: "",
      txtExtraIfGlassDoor: "",
    },
    dataLoadStatus: true,
    ARD: null,
    rowsCount: 0,
    rowsPerPage: 50,
  };

  //#region Component
  componentDidMount() {
    localStorage.setItem("Heading", "Auto Door Main Price List");
    this.props.verifyUserID(
      this.getInfo,
      this.props.setUserPrivileges,
      this.props.modules.autodoormainprice
    );
    this.ShortCut();
  }

  componentWillUnmount() {
    this.removeShortCut();
  }

  //#endregion Component

  //#region Handle

  ShortCut = () => {
    this.removeShortCut = setKeyboardShortcuts(
      this.saveAutoDoorMainPrice,
      this.clearInfo,
      () => {
        this.DeleteData(
          this.state.formData.bint_srno,
          this.state.formData.txtOpening,
          this.state.formData.cmbDoorType
        );
      }
    );
  };

  handleOnChange = (e) => {
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: e.target.value },
    });
  };

  handleARDonActionClick_Edit = (rtRow) => {
    const row = getRowOfRT(rtRow, [
      "bint_srno",
      "vac_brand",
      "vac_doortype",
      "bint_opening",
      "vac_materialgrade",
      "dec_cardoorpanel",
      "dec_landing_frame",
      "dec_extraframe",
      "dec_extraglass",
    ]);

    console.log("row", row);

    const bint_srno = row.bint_srno,
      cmbBrand = row.vac_brand,
      txtOpening = row.bint_opening,
      cmbDoorType = row.vac_doortype,
      cmbMaterialGrade = row.vac_materialgrade,
      txtCarDoorPanelRate = row.dec_cardoorpanel,
      txtLandingDoorDoorFrameRate = row.dec_landing_frame,
      txtExtraIfDoorFrame = row.dec_extraframe,
      txtExtraIfGlassDoor = row.dec_extraglass;
    console.log("cmbBrand", cmbBrand);
    this.setState(
      {
        formData: {
          ...this.state.formData,
          bint_srno,
          cmbBrand,
          txtOpening,
          cmbDoorType,
          cmbMaterialGrade,
          txtCarDoorPanelRate,
          txtLandingDoorDoorFrameRate,
          txtExtraIfDoorFrame,
          txtExtraIfGlassDoor,
        },
      },
      () => {
        document.getElementById("cmbBrand").focus();
      }
    );
  };

  handleARDonActionClick_Delete = (rtRow) => {
    const row = getRowOfRT(rtRow, [
      "bint_srno",
      "vac_brand",
      "vac_doortype",
      "bint_opening",
      "vac_materialgrade",
      "dec_cardoorpanel",
      "dec_landing_frame",
      "dec_extraframe",
      "dec_extraglass",
    ]);

    const bint_srno = row.bint_srno,
      txtOpening = row.vac_doortype,
      cmbDoorType = row.bint_opening;

    this.DeleteData(bint_srno, txtOpening, cmbDoorType);
  };

  //#endregion Handle

  //#region function

  getInfo = () => {
    this.props.notifyProcessing();
    const reqData = {
      Op: "ListAutoDoorMainPrice",
    };
    fetchAutoDoorMainPrice(reqData)
      .then((res) => {
        this.props.closeUpdateProcessing();
        let rows = res.data.ListAutoDoorMainPrice,
          rowsCount = rows.length;
        this.setState({
          rowsCount,
          rows,
          Brand: res.data.Brand,
          DoorType: res.data.Type,
          MaterialGrade: res.data.MaterialGrade,
        });
      })
      .catch((error) => {
        console.log("Unknown error occurred in onFormLoad.", error);
      });
    document.getElementById("cmbBrand").focus();
  };

  clearInfo = () => {
    this.setState(
      {
        formData: {
          bint_srno: 0,
          cmbBrand: "",
          txtOpening: "",
          cmbDoorType: "",
          cmbMaterialGrade: "",
          txtCarDoorPanelRate: "",
          txtLandingDoorDoorFrameRate: "",
          txtExtraIfDoorFrame: "",
          txtExtraIfGlassDoor: "",
        },
      },
      () => {
        document.getElementById("cmbBrand").focus();
      }
    );
  };

  trimFormData = (nextFunctionCall) => {
    let formData = applyTrimOnObjectValues({
      formData: this.state.formData,
    });
    this.setState({ formData }, () => {
      if (nextFunctionCall) {
        nextFunctionCall();
      }
    });
  };

  validationAutoDoorMainPrice = () => {
    let result = false;
    if (this.state.formData.cmbBrand.length <= 0) {
      this.props.toastErrorMsg("Select Barnd", "cmbBrand");
    } else if (this.state.formData.cmbDoorType.length <= 0) {
      this.props.toastErrorMsg("Select Door Type", "cmbDoorType");
    } else if (this.state.formData.txtOpening.length <= 0) {
      this.props.toastErrorMsg("Enter Opening", "txtOpening");
    } else if (this.state.formData.cmbMaterialGrade.length <= 0) {
      this.props.toastErrorMsg("Select Material Grade", "cmbMaterialGrade");
    } else if (this.state.formData.txtCarDoorPanelRate.length <= 0) {
      this.props.toastErrorMsg(
        "Enter Car Door Panel Rate",
        "txtCarDoorPanelRate"
      );
    } else if (this.state.formData.txtLandingDoorDoorFrameRate.length <= 0) {
      this.props.toastErrorMsg(
        "Enter Landing Door + Door Frame Rate",
        "txtLandingDoorDoorFrameRate"
      );
    } else if (this.state.formData.txtExtraIfDoorFrame.length <= 0) {
      this.props.toastErrorMsg(
        "Enter Extra If 60x120 Door Frame",
        "txtExtraIfDoorFrame"
      );
    } else if (this.state.formData.txtExtraIfGlassDoor.length <= 0) {
      this.props.toastErrorMsg(
        "Enter Extra If Glass Door",
        "txtExtraIfGlassDoor"
      );
    } else {
      result = true;
    }
    return result;
  };

  saveAutoDoorMainPrice = () => {
    this.trimFormData(() => {
      if (this.validationAutoDoorMainPrice()) {
        console.log(
          "this.state.formData.bint_srno",
          this.state.formData.bint_srno
        );
        this.props.notifyProcessing();
        const reqData = {
          Op: "SaveAutoDoorMainPrice",
          bint_srno: this.state.formData.bint_srno,
          vac_brand: this.state.formData.cmbBrand,
          vac_doortype: this.state.formData.cmbDoorType,
          bint_opening: this.state.formData.txtOpening,
          vac_materialgrade: this.state.formData.cmbMaterialGrade,
          dec_cardoorpanel: this.state.formData.txtCarDoorPanelRate,
          dec_landing_frame: this.state.formData.txtLandingDoorDoorFrameRate,
          dec_extraframe: this.state.formData.txtExtraIfDoorFrame,
          dec_extraglass: this.state.formData.txtExtraIfGlassDoor,
        };
        fetchAutoDoorMainPrice(reqData)
          .then((res) => {
            this.props.updateProcessing(res.data.msgType, res.data.message);
            if (res.data.msgType === "success") {
              let rows = [],
                rowsCount = rows.length;
              if (
                res.data.ListAutoDoorMainPrice &&
                res.data.ListAutoDoorMainPrice.length > 0
              ) {
                rows = res.data.ListAutoDoorMainPrice;
                rowsCount = rows.length;
              }
              this.setState(
                {
                  rows,
                  rowsCount,
                  Brand: res.data.Brand,
                  DoorType: res.data.Type,
                  MaterialGrade: res.data.MaterialGrade,
                },
                () => {
                  this.clearInfo();
                }
              );
            }
          })
          .catch((error) => {
            console.log(
              "Unknown error occurred in Save Auto Door Main Price Master .",
              error
            );
          });
      }
    });
  };

  DeleteData = (bint_srno, bint_opening, cmbDoorType) => {
    if (bint_srno > 0) {
      this.setState({ ARD: null }, () => {
        let ARD = (
          <AlertResponsiveDialog
            labelAgree="Delete"
            labelDisagree="Cancel"
            alertTitle={`Do you want to Delete Opening : ${bint_opening} And Door Type : ${cmbDoorType}?`}
            alertMessage=""
            handleOnClickYes={() => {
              return this.DeleteAutoDoorMainPrice(bint_srno);
            }}
            defaultOpenDialog={true}
            onYesClickCloseIfExeSuccessfully={true}
          />
        );

        this.setState({ ARD });
      });
    } else {
      this.props.toastErrorMsg("Select a record for deletion.");
    }
  };

  DeleteAutoDoorMainPrice = (bint_srno) => {
    this.props.notifyProcessing();
    const reqData = {
      Op: "DeleteAutoDoorMainPrice",
      bint_srno: bint_srno,
    };
    fetchAutoDoorMainPrice(reqData)
      .then((res) => {
        this.props.updateProcessing(res.data.msgType, res.data.message);
        if (res.data.msgType === "success") {
          let rows = [],
            rowsCount = rows.length;
          if (
            res.data.ListAutoDoorMainPrice &&
            res.data.ListAutoDoorMainPrice.length > 0
          ) {
            rows = res.data.ListAutoDoorMainPrice;
            rowsCount = rows.length;
          }
          this.setState(
            {
              rows,
              rowsCount,
              ARD: null,
              Brand: res.data.Brand,
              DoorType: res.data.Type,
              MaterialGrade: res.data.MaterialGrade,
            },
            () => {
              this.clearInfo();
            }
          );
        }
      })
      .catch((error) => {
        this.props.updateProcessing(
          "error",
          "Unknown error occurred in delete Auto Door Main Master. " + error
        );
      });
    return false;
  };

  validateReadExcel = () => {
    let result = false;
    let fileTypes = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/csv",
    ];
    let FileElement = document.getElementById("input");
    let selectedFile = FileElement.files[0];
    if (selectedFile) {
      if (selectedFile && fileTypes.includes(selectedFile.type)) {
        result = true;
      } else {
        this.props.toastErrorMsg("Please Select Vaild Exel File", "input");
      }
    } else {
      this.props.toastErrorMsg("Please Select Your File", "input");
    }
    // console.log("selectedFile", selectedFile);
    return result;
  };

  CallReadExcel = () => {
    this.ReadExcel();
  };

  ReadExcel = () => {
    if (this.validateReadExcel() === true) {
      this.props.notifyProcessing();
      const input = document.getElementById("input");
      let i = 0,
        columnsdata = [],
        rows = [],
        inValidRows = [],
        validRows = [],
        disableSave = false,
        edit_info = [];
      readXlsxFile(input.files[0]).then((c_row) => {
        c_row.map((r) => {
          if (i == 0) {
            columnsdata = this.createColumn(r);
          } else {
            let row = this.createRow(columnsdata, r);

            // if (
            //   (row.ImportStatus && row.ImportStatus.length > 0) ||
            //   (row.edit_info && row.edit_info.length > 0)
            // ) {
            //   inValidRows.push(row);
            // } else {
            //   validRows.push(row);
            // }
            // edit_info.push({
            //   vac_attendanceempcode: row["Attendance Emp Code"],
            //   vac_empcode: row["Employee Code"],
            //   vac_aadharno: row["Aadhar Number"],
            // });
            rows.push(row);
          }
          i = i + 1;
        });
        disableSave = validRows.length <= 0 ? true : false;
        this.props.closeUpdateProcessing();
        this.setState(
          {
            columnsdata,
            rows,
            inValidRows,
            edit_info,
            validRows,
            totalRows: rows,
            disableSave,
          },
          () => {
            // this.duplicateStatus();
          }
        );
      });
    }
  };

  createColumn = (columnsdata) => {
    let cols = [];
    // cols.push({
    //   id: "ImportStatus",
    //   label: "Is Empty, Enter Data & Retry",
    //   width: 100,
    // });
    // cols.push({
    //   id: "emp_info",
    //   label: "Duplicate Status",
    //   width: 100,
    // });
    columnsdata.map((c, index) => {
      cols.push({ id: c.trim(), label: c, width: 120 });
    });

    return cols;
  };

  createRow = (columnsdata, rows) => {
    let returnrow = {},
      blankFeild = [];

    const checkRowsBlank = (index, c, accessorKey) => {
      if (c === "" || c === undefined || c === null) {
        blankFeild.push(accessorKey);
      }
    };

    rows.map((c, index) => {
      // if (
      //   columnsdata[index + 1].id == "Employee Code" ||
      //   columnsdata[index + 1].id == "Plant" ||
      //   columnsdata[index + 1].id == "Sub Dept." ||
      //   columnsdata[index + 1].id == "Name" ||
      //   columnsdata[index + 1].id == "Employee Category" ||
      //   columnsdata[index + 1].id == "Designation" ||
      //   columnsdata[index + 1].id == "Contractor" ||
      //   columnsdata[index + 1].id == "Father Name" ||
      //   columnsdata[index + 1].id == "Gender" ||
      //   columnsdata[index + 1].id == "Attendance Emp Code" /*  ||
      //   columnsdata[index + 1].id == "DOJ" */
      // ) {
      //   checkRowsBlank(index, c, columnsdata[index + 1].id);
      // } else if (columnsdata[index + 1].id == "DOB") {
      //   if (c != undefined && c != null && c.length >= 8) {
      //     c = get_DMY_from_YMD(this.formatDate(c));
      //   } else {
      //     c = "";
      //   }
      // } else if (columnsdata[index + 1].id == "DOJ") {
      //   if (c != undefined && c != null && c.length >= 8) {
      //     c = get_DMY_from_YMD(this.formatDate(c));
      //   } else {
      //     c = "";
      //   }
      //   checkRowsBlank(index, c, columnsdata[index + 1].id);
      // } else if (columnsdata[index + 1].id == "Present State") {
      //   c = this.getSateFromPincode(rows[19]);
      // } else if (columnsdata[index + 1].id == "Permanent State") {
      //   c = this.getSateFromPincode(rows[26]);
      // } else if (columnsdata[index + 1].id == "Shift") {
      //   c = this.getFullNameShift(c);
      // } else if (columnsdata[index + 1].id == "W/O") {
      //   c = this.getFullDayName(c);
      // } else if (c == undefined || c == null) {
      //   c = "";
      // }
      returnrow[columnsdata[index + 1].id.trim()] = c;
    });

    // let ImportStatus = blankFeild.join(", ");

    returnrow = { ...returnrow /* ImportStatus: ImportStatus  */ };
    blankFeild = [];
    return returnrow;
  };

  //#endregion function

  render() {
    return (
      <form>
        <Grid container direction="row" justifyContent="flex-end">
          <Grid item>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <CtBtn
                  className="secondary-button"
                  label="Save"
                  iconName="save"
                  onClick={this.saveAutoDoorMainPrice}
                />
              </Grid>
              <Grid item>
                <CtBtn
                  label="New"
                  className="new-button"
                  iconName="new"
                  onClick={this.clearInfo}
                />
              </Grid>
              <Grid item>
                <CtBtn
                  label="Delete"
                  className="delete-button"
                  iconName="delete"
                  onClick={() => {
                    this.DeleteData(
                      this.state.formData.bint_srno,
                      this.state.formData.txtOpening,
                      this.state.formData.cmbDoorType
                    );
                  }}
                  disabled={this.state.formData.bint_srno > 0 ? false : true}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={3}
          marginTop={0}
        >
          <Grid item>
            <CtCmbEditable
              id="cmbBrand"
              label="Brand"
              items={this.state.Brand}
              value={this.state.formData.cmbBrand}
              handleOnChange={this.handleOnChange}
              width={220}
              maxLength={100}
              colID="vac_brand"
            />
          </Grid>
          <Grid item>
            <CtCmbEditable
              id="cmbDoorType"
              label="Door Type"
              items={this.state.DoorType}
              value={this.state.formData.cmbDoorType}
              handleOnChange={this.handleOnChange}
              width={220}
              maxLength={100}
              colID="vac_doortype"
            />
          </Grid>
          <Grid item>
            <CtTxtAmt
              id="txtOpening"
              label="Opening"
              maxLength={10}
              width="220"
              value={this.state.formData.txtOpening}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>

          <Grid item>
            <CtCmbEditable
              id="cmbMaterialGrade"
              label="Material Grade"
              items={this.state.MaterialGrade}
              value={this.state.formData.cmbMaterialGrade}
              handleOnChange={this.handleOnChange}
              width={220}
              maxLength={100}
              colID="vac_doormaterialgrade"
            />
          </Grid>

          <Grid item>
            <CtTxtAmt
              id="txtCarDoorPanelRate"
              label="Car Door + Header Rate"
              maxLength={10}
              width="220"
              value={this.state.formData.txtCarDoorPanelRate}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
              RupeeSymbol={true}
            />
          </Grid>
          <Grid item>
            <CtTxtAmt
              id="txtLandingDoorDoorFrameRate"
              label="Landing Door + Door Frame Rate"
              maxLength={10}
              width="220"
              value={this.state.formData.txtLandingDoorDoorFrameRate}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
              RupeeSymbol={true}
            />
          </Grid>
          <Grid item>
            <CtTxtAmt
              id="txtExtraIfDoorFrame"
              label="Extra If 60x120 Door Frame"
              maxLength={10}
              width="220"
              value={this.state.formData.txtExtraIfDoorFrame}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
              RupeeSymbol={true}
            />
          </Grid>
          <Grid item>
            <CtTxtAmt
              id="txtExtraIfGlassDoor"
              label="Extra If Glass Door"
              maxLength={10}
              width="220"
              value={this.state.formData.txtExtraIfGlassDoor}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
              RupeeSymbol={true}
            />
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          marginTop={1}
        >
          <Grid item className="maintable">
            <CtART
              rowsdata={this.state.rows}
              columnsdata={this.state.columnsdata}
              rowsPerPage={this.state.rowsPerPage}
              tblmaxHeight={500}
              csvFileName={csvFileName("Passenger Lift Cabin Price")}
              rowIconAction={[
                {
                  label: "Edit",
                  onClick: this.handleARDonActionClick_Edit,
                  icon: "Edit",
                  className: "secondary-button",
                },
                {
                  label: "Delete",
                  onClick: this.handleARDonActionClick_Delete,
                  icon: "delete",
                  className: "delete-button",
                },
              ]}
              showColumnFilters={false}
              actionColSize={90}
              density={"compact"}
              hideColIdList={["bint_srno"]}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Box display={{ xs: "none" }} style={{ textAlign: "right" }}>
            {this.state.ARD}
          </Box>
        </Grid>
      </form>
    );
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <AutoDoorMainPrice {...props} navigate={navigate} />;
}
export default connect(
  mapStatetoProps,
  mapSetUserPrivileges_ToProps
)(HoCtToastContainer(HOCVerifyIsUser(withRouter(WithNavigate))));
