import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import HOCVerifyIsUser from "../HOC/HOCVerifyIsUser";
import CtBtn from "../Components/CustomTool/CtBtn";
import CtTxt from "../Components/CustomTool/CtTxt";
import CtTextFieldPWD from "../Components/CustomTool/CtTxtPWD";
import CtCmb from "../Components/CustomTool/CtCmb";
import CtTxtNum from "../Components/CustomTool/CtTxtNum";
import MaterialTable from "../Components/CustomTool/MaterialTable";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import AlertResponsiveDialog from "../Components/CustomTool/AlertResponsiveDialog";
// import DepartmentMaster from "./DepartmentMaster";
import { Stack } from "@mui/material";
// import PlantMaster from "./PlantMaster";
// import { fetchUser } from "../Components/API";
import HoCtToastContainer from "../HOC/HoCtToastContainer";
import {
  applyTrimOnObjectValues,
  csvFileName,
  getRowOfRT,
  setKeyboardShortcuts,
} from "../SystemUtility/SystemUtility";
import {
  mapSetUserPrivileges_ToProps,
  mapStatetoProps,
} from "../mapRedux/mapReduxProps";
import { connect } from "react-redux";
import { withRouter } from "../HOC/withRouter";
import { fetchcombinationbracketprice, fetchUser } from "./API";
import MobileTable from "./CustomTool/MobileTable";
import CtART from "./CustomTool/ResponsiveTable";
import axios from "axios";
import CtTxtAmt from "../Components/CustomTool/CtTxtAmt";
import CtCmbEditable from "./CustomTool/CtCmbEditable";
import CtMultiLineText from "./CustomTool/CtMultiLineText";
import TableList from "./CustomTool/TableList";

class CombinationBracketPrice extends Component {
  state = {
    Unit: [{ vac_type: "Type" }],
    columnsdata: [
      {
        id: "vac_partname",
        label: "Part Name",
      },
      {
        id: "dec_part_qty_nos",
        label: "PART QTY IN NOS",
        type: "CtTxtAmt",
        CtrlProps: { maxLength: 10 },
      },
      {
        id: "dec_length",
        label: "LENGTH",
        type: "CtTxtAmt",
        CtrlProps: {
          maxLength: 10,

          visiblecondition: [
            {
              colID: "enableHeight",
              value: true,
              relationalOperator: "==",
            },
          ],
        },
      },
      {
        id: "dec_width",
        label: "WIDTH",
        type: "CtTxtAmt",
        CtrlProps: { maxLength: 10 },
      },
      {
        id: "dec_thickness",
        label: "THICKNESS",
        type: "CtTxtAmt",
        CtrlProps: { maxLength: 10 },
      },
      {
        id: "dec_noofbendsperpar",
        label: "NO OF BENDS PER PART",
        type: "CtTxtAmt",
        CtrlProps: { maxLength: 10 },
      },
      {
        id: "dec_packgingcost",
        label: "PACKAGING COST",
        type: "CtTxtAmt",
        CtrlProps: { maxLength: 10 },
      },
      {
        id: "dec_transportationcost",
        label: "TRANSPORTATION COST",
        type: "CtTxtAmt",
        CtrlProps: { maxLength: 10 },
      },
    ],

    rows: [],
    ARD: null,
    rowsCount: 0,
    rowsPerPage: 50,
  };

  //#region Component
  componentDidMount() {
    // document.title = "User Management : Top India";
    localStorage.setItem("Heading", "Combination Bracket Price");
    this.props.verifyUserID(
      this.getInfo,
      this.props.setUserPrivileges,
      this.props.modules.combintionbracket
    );
    // this.ShortCut();
  }

  //   componentWillUnmount() {
  //     this.removeShortCut();
  //   }

  //#endregion Component

  //#region Handle

  //   ShortCut = () => {
  //     this.removeShortCut = setKeyboardShortcuts(
  //       this.SaveUser,
  //       this.clearInfo,
  //       () => {
  //         this.DeleteData(
  //           this.state.formData.EditSrNo,
  //           this.state.formData.EditUserID
  //         );
  //       }
  //     );
  //   };

  handleOnChange = (e) => {
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: e.target.value },
    });
  };

  handleOnRowDataChange = (rows) => {
    // console.log("rows changes", rows);
    this.setState({
      rows: rows,
      rowsCount: rows.length,
    });
  };

  handleChangeRowsPerPage = (rowsPerPage) => {
    this.setState({ rowsPerPage });
  };

  //#endregion Handle

  //#region function

  trimFormData = (nextFunctionCall) => {
    let formData = applyTrimOnObjectValues({
      formData: this.state.formData,
    });
    this.setState({ formData }, () => {
      if (nextFunctionCall) {
        nextFunctionCall();
      }
    });
  };

  getInfo = () => {
    this.props.notifyProcessing();
    const reqData = {
      Op: "ListCombinationBracketPrice",
    };
    fetchcombinationbracketprice(reqData)
      .then((res) => {
        this.props.closeUpdateProcessing();
        let rows = res.data.ListCombinationBracketPrice.map((row) => {
            return {
              ...row,
              enableHeight:
                row["vac_partname"] === "COMBINATION FRONT" ? false : true,
            };
          }),
          rowsCount = rows.length;

        this.setState({
          rowsCount,
          rows,
        });
      })
      .catch((error) => {
        console.log("Unknown error occurred in onFormLoad.", error);
      });
    // document.getElementById("txtPartName").focus();
  };

  validateCombinationBracket = () => {
    let result = false;
    // if (this.state.formData.txtPartName.length <= 0) {
    //   this.props.toastErrorMsg("Enter Part Name", "txtPartName");
    // } else if (this.state.formData.txtPartQty.length <= 0) {
    //   this.props.toastErrorMsg("Enter Part Qty In NOS", "txtPartQty");
    // } else if (this.state.formData.txtWidth.length <= 0) {
    //   this.props.toastErrorMsg("Enter Width", "txtWidth");
    // } else if (this.state.formData.txtThickness.length <= 0) {
    //   this.props.toastErrorMsg("Enter Thickness", "txtThickness");
    // } else if (this.state.formData.txtNoOfBendsPerPart.length <= 0) {
    //   this.props.toastErrorMsg(
    //     "Enter No Of Bends Per Part",
    //     "txtNoOfBendsPerPart"
    //   );
    // } else {
    //   result = true;
    // }
    return result;
  };

  SaveCombinationBracketPrice = () => {
    console.log("this.state.rows", this.state.rows);
    // this.trimFormData(() => {
    // if (this.validateCombinationBracket()) {
    this.props.notifyProcessing();
    const reqData = {
      Op: "SaveCombinationBracketPrice",
      Combination_data: this.state.rows,
    };
    fetchcombinationbracketprice(reqData)
      .then((res) => {
        this.props.updateProcessing(res.data.msgType, res.data.message);
        if (res.data.msgType === "success") {
          let rows = [],
            rowsCount = rows.length;
          if (
            res.data.ListCombinationBracketPrice &&
            res.data.ListCombinationBracketPrice.length > 0
          ) {
            rows = res.data.ListCombinationBracketPrice.map((row) => {
              return {
                ...row,
                enableHeight:
                  row["vac_partname"] === "COMBINATION FRONT" ? false : true,
              };
            });
            rowsCount = rows.length;
          }
          this.setState(
            {
              rows,
              rowsCount,
            },
            () => {
              // this.clearInfo();
            }
          );
        }
      })
      .catch((error) => {
        console.log(
          "Unknown error occurred in Save General Item Price Master .",
          error
        );
      });
    // }
    // });
  };

  //#endregion function

  render() {
    let RowsCount = this.state.rows.length;
    return (
      <form>
        <Grid container direction="row" justifyContent="flex-end">
          <Grid item>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <CtBtn
                  className="secondary-button"
                  label="Save"
                  iconName="save"
                  onClick={this.SaveCombinationBracketPrice}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent={"center"} marginTop={2}>
          <Grid item>
            <TableList
              columns={this.state.columnsdata}
              rows={this.state.rows}
              rowsCount={RowsCount}
              rowsPerPage={this.state.rowsPerPage}
              // handleOnNewEntryClick={this.handleOnNewEntryClick}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              handleOnRowDataChange={this.handleOnRowDataChange}

              // provideSearch={true}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Box display={{ xs: "none" }} style={{ textAlign: "right" }}>
            {this.state.ARD}
          </Box>
        </Grid>
      </form>
    );
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <CombinationBracketPrice {...props} navigate={navigate} />;
}
export default connect(
  mapStatetoProps,
  mapSetUserPrivileges_ToProps
)(HoCtToastContainer(HOCVerifyIsUser(withRouter(WithNavigate))));
