import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import HOCVerifyIsUser from "../HOC/HOCVerifyIsUser";
import CtBtn from "../Components/CustomTool/CtBtn";
import CtTxt from "../Components/CustomTool/CtTxt";
import CtTextFieldPWD from "../Components/CustomTool/CtTxtPWD";
import CtCmb from "../Components/CustomTool/CtCmb";
import CtTxtNum from "../Components/CustomTool/CtTxtNum";
import MaterialTable from "../Components/CustomTool/MaterialTable";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import AlertResponsiveDialog from "../Components/CustomTool/AlertResponsiveDialog";
// import DepartmentMaster from "./DepartmentMaster";
import { Stack } from "@mui/material";
// import PlantMaster from "./PlantMaster";
// import { fetchUser } from "../Components/API";
import HoCtToastContainer from "../HOC/HoCtToastContainer";
import {
  applyTrimOnObjectValues,
  csvFileName,
  getRowOfRT,
  setKeyboardShortcuts,
} from "../SystemUtility/SystemUtility";
import {
  mapSetUserPrivileges_ToProps,
  mapStatetoProps,
} from "../mapRedux/mapReduxProps";
import { connect } from "react-redux";
import { withRouter } from "../HOC/withRouter";
import { fetchUser } from "./API";
import MobileTable from "./CustomTool/MobileTable";
import CtART from "./CustomTool/ResponsiveTable";
import axios from "axios";
import CtTxtAmt from "../Components/CustomTool/CtTxtAmt";
import CtCmbEditable from "./CustomTool/CtCmbEditable";
import CtMultiLineText from "./CustomTool/CtMultiLineText";

class SafetyMainPrice extends Component {
  state = {
    Unit: [{ vac_type: "Type" }],
    columnsdata: [
      {
        accessorKey: "Description",
        header: "Description",
      },
      {
        accessorKey: "Section",
        header: "Section",
      },

      {
        accessorKey: "Yes / No",
        header: "Yes / No",
      },
      {
        accessorKey: "Qty",
        header: "Qty",
      },
      {
        accessorKey: "Unit",
        header: "Unit",
      },
      {
        accessorKey: "Length",
        header: "Length",
      },
      {
        accessorKey: "Width",
        header: "Width",
      },
    ],

    rows: [
      {
        vac_Rail: "375",
        vac_Price: "204",
        vac_Unit: "579",
      },
    ],
    formData: {
      cmbPassenger: "",
      cmbInsideWidth: "",
      cmbInsideDepth: "",
      cmbInsideHeight: "",
      cmbPlatformWidth: "",
      cmbPlatformDepth: "",
    },
    dataLoadStatus: true,
    ARD: null,
    rowsCount: 0,
    rowsPerPage: 50,
  };

  //#region Component
  componentDidMount() {
    // document.title = "User Management : Top India";
    localStorage.setItem("Heading", "Safety Main Price");
    this.props.verifyUserID(
      undefined,
      this.props.setUserPrivileges,
      this.props.modules.safetymain
    );
    // this.ShortCut();
    // this.getInfo();
  }

  //   componentWillUnmount() {
  //     this.removeShortCut();
  //   }

  //#endregion Component

  //#region Handle

  //   ShortCut = () => {
  //     this.removeShortCut = setKeyboardShortcuts(
  //       this.SaveUser,
  //       this.clearInfo,
  //       () => {
  //         this.DeleteData(
  //           this.state.formData.EditSrNo,
  //           this.state.formData.EditUserID
  //         );
  //       }
  //     );
  //   };

  handleOnChange = (e) => {
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: e.target.value },
    });
  };

  handleARDonActionClick_Edit = (row) => {
    this.props.notifyProcessing();
    // alert("click on edit");
    // console.log("row", row);

    this.props.updateProcessing("success", "on edit");
    // console.log("click on edit");
  };
  handleARDonActionClick_Delete = (rtRow) => {
    this.props.notifyProcessing();

    this.props.updateProcessing("success", "on Delete");

    // console.log("rtRow", rtRow);
    // const row = getRowOfRT(rtRow, ["bint_srno", "vac_userid"]);
    // // console.log("row", row.bint_srno, row.vac_userid);
    // this.DeleteData(row.bint_srno, row.vac_userid);
  };

  //#endregion Handle

  //#region function

  clearInfo = () => {};

  trimFormData = (nextFunctionCall) => {
    let formData = applyTrimOnObjectValues({
      formData: this.state.formData,
    });
    this.setState({ formData }, () => {
      if (nextFunctionCall) {
        nextFunctionCall();
      }
    });
  };

  //#endregion function

  render() {
    return (
      <form>
        <Grid container direction="row" justifyContent="flex-end">
          <Grid item>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <CtBtn
                  className="secondary-button"
                  label="Save"
                  iconName="save"
                  onClick={() => {
                    alert("work remain");
                  }}
                />
              </Grid>
              <Grid item>
                <CtBtn
                  label="New"
                  className="new-button"
                  iconName="new"
                  onClick={this.clearInfo}
                />
              </Grid>
              <Grid item>
                <CtBtn
                  label="Delete"
                  className="delete-button"
                  iconName="delete"
                  // onClick={() => {
                  //   this.DeleteData(
                  //     this.state.formData.EditSrNo,
                  //     this.state.formData.txtUserId
                  //   );
                  // }}
                  //   disabled={
                  //     this.state.formData.txtUserId.length > 0 ? false : true
                  //   }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          marginTop={0}
        >
          <Grid item lg={10} md={12} sm={12} xs={12}>
            <CtMultiLineText
              id="txtDescription"
              label="Description"
              maxLength={100}
              width="100%"
              value={this.state.formData.txtDescription}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtCmb
              id="cmbSection"
              label="Section"
              items={this.state.Unit}
              value={this.state.formData.cmbSection}
              handleOnChange={this.handleOnChange}
              width={195}
              maxLength={100}
              colID="vac_type"
            />
          </Grid>
          <Grid item>
            <CtCmb
              id="cmbYesNo"
              label="Yes / No"
              items={this.state.Unit}
              value={this.state.formData.cmbYesNo}
              handleOnChange={this.handleOnChange}
              width={195}
              maxLength={100}
              colID="vac_type"
            />
          </Grid>
          <Grid item>
            <CtCmbEditable
              id="cmbQty"
              label="Qty"
              items={this.state.Unit}
              value={this.state.formData.cmbQty}
              handleOnChange={this.handleOnChange}
              width={195}
              maxLength={100}
              colID="vac_type"
            />
          </Grid>
          <Grid item>
            <CtCmb
              id="cmbUnit"
              label="Unit"
              items={this.state.Unit}
              value={this.state.formData.cmbUnit}
              handleOnChange={this.handleOnChange}
              width={195}
              maxLength={100}
              colID="vac_type"
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtLength"
              label="Length"
              maxLength={100}
              width="100%"
              value={this.state.formData.txtLength}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtWidth"
              label="Width"
              maxLength={100}
              width="100%"
              value={this.state.formData.txtWidth}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtThickness"
              label="Thickness"
              maxLength={100}
              width="100%"
              value={this.state.formData.txtThickness}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtMaterial"
              label="Material KG"
              maxLength={100}
              width="100%"
              value={this.state.formData.txtMaterial}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtCuttingMeter"
              label="Cutting Meter"
              maxLength={100}
              width="100%"
              value={this.state.formData.txtCuttingMeter}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtPiercing"
              label="Piercing"
              maxLength={100}
              width="100%"
              value={this.state.formData.txtPiercing}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtArea"
              label="PWD Area (SQ FT)"
              maxLength={100}
              width="100%"
              value={this.state.formData.txtArea}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtMaterialCost"
              label="Material Cost"
              maxLength={100}
              width="100%"
              value={this.state.formData.txtMaterialCost}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtLaserCuttingCost"
              label="Laser Cutting Cost"
              maxLength={100}
              width="100%"
              value={this.state.formData.txtLaserCuttingCost}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtPWDCoatingCost"
              label="PWD Coating Cost"
              maxLength={100}
              width="100%"
              value={this.state.formData.txtPWDCoatingCost}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtWeldingLabour"
              label="Welding Labour"
              maxLength={100}
              width="100%"
              value={this.state.formData.txtWeldingLabour}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtAssemclingLabour"
              label="Assemcling Labour"
              maxLength={100}
              width="100%"
              value={this.state.formData.txtAssemclingLabour}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtPackingLabour"
              label="Packing Labour + MAT Cost"
              maxLength={100}
              width="100%"
              value={this.state.formData.txtPackingLabour}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtTotalCost"
              label="Total Cost"
              maxLength={100}
              width="100%"
              value={this.state.formData.txtTotalCost}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          marginTop={1}
        >
          {/* responsive table */}
          <Grid item className="maintable">
            <CtART
              rowsdata={this.state.rows}
              columnsdata={this.state.columnsdata}
              rowsPerPage={this.state.rowsPerPage}
              tblmaxHeight={500}
              csvFileName={csvFileName("Passenger Lift Cabin Price")}
              rowIconAction={[
                {
                  label: "Edit",
                  onClick: this.handleARDonActionClick_Edit,
                  icon: "Edit",
                  className: "secondary-button",
                },
                {
                  label: "Delete",
                  onClick: this.handleARDonActionClick_Delete,
                  icon: "delete",
                  className: "delete-button",
                },
              ]}
              showColumnFilters={false}
              actionColSize={90}
              density={"compact"}
              hideColIdList={["bint_srno"]}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Box display={{ xs: "none" }} style={{ textAlign: "right" }}>
            {this.state.ARD}
          </Box>
        </Grid>
      </form>
    );
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <SafetyMainPrice {...props} navigate={navigate} />;
}
export default connect(
  mapStatetoProps,
  mapSetUserPrivileges_ToProps
)(HoCtToastContainer(HOCVerifyIsUser(withRouter(WithNavigate))));
