import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { Component } from "react";
import CtTxt from "../Components/CustomTool/CtTxt";
import CtTxtPWD from "../Components/CustomTool/CtTxtPWD";
import CtBtn from "../Components/CustomTool/CtBtn";
import HoCtToastContainer from "../HOC/HoCtToastContainer";
import { Link, Navigate } from "react-router-dom";
import { withRouter } from "../HOC/withRouter";
import AlertResponsiveDialog from "../Components/CustomTool/AlertResponsiveDialog";
// import { fetchUser } from "../Components/API";
import NewPassword from "./NewPassword";
import { fetchUserManagement } from "./API";

class CurrentPassword extends Component {
  state = {
    formData: {
      txtPassword: "",
    },
  };

  componentDidMount() {
    document.getElementById("txtPassword").focus();
    this.PageTitle();
  }

  PageTitle = () => {
    document.title = "Current Password : Makson";
  };

  handleOnChange = (e) => {
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: e.target.value },
    });
  };

  validatePassword = () => {
    let result = false;
    if (this.state.formData.txtPassword.length <= 0) {
      this.props.toastErrorMsg("Enter Current Password", "txtPassword");
    } else if (this.state.formData.txtPassword.length < 4) {
      this.props.toastErrorMsg(
        "The Length Of The Current Password Should Be At Least 4",
        "txtPassword"
      );
    } else {
      result = true;
    }
    return result;
  };

  onVerifyPassword = () => {
    if (this.validatePassword()) {
      this.props.notifyProcessing();
      const reqData = {
        Op: "VerifyLoginUser",
        vac_userid: localStorage.getItem("vac_userid"),
        vac_password: this.state.formData.txtPassword,
      };
      fetchUserManagement(reqData)
        .then((res) => {
          this.props.updateProcessing(res.data.msgType, res.data.message);
          if (res.data.msgType === "success") {
            this.OnVerifyclick();
          }
        })
        .catch((error) => {
          console.log("Unknown error occurred in onFormLoad.", error);
        });
    }
  };

  OnVerifyclick = () => {
    if (this.validatePassword()) {
      let ARD = null;
      this.setState({ ARD }, () => {
        ARD = (
          <AlertResponsiveDialog
            alertMessage={
              <NewPassword
                nullARD={this.props.nullARD}
                UserId={localStorage.getItem("vac_userid")}
                loginMsgHide={true}
              />
            }
            defaultOpenDialog={true}
          />
        );
        this.setState({ ARD });
      });
      //if beckend call and success then close and go to login page
    }
  };

  //   nullARD = () => {
  //     this.setState({
  //       ARD: null,
  //     });
  //   };

  render() {
    return (
      <div>
        <Grid
          container
          display="flex"
          direction="row"
          justifyContent="center"
          alignItems="center"
          minheight="90vh"
          // spacing={2}
        >
          {/* <Grid item lg={4} md={6} style={{ marginTop: "100px" }}> */}
          <Container>
            <Card>
              <CardContent>
                <Typography
                  fontSize="30px"
                  display="flex"
                  justifyContent="Center"
                  fontWeight="600"
                  color="#05699d"
                >
                  Enter Current Password
                </Typography>
                <form>
                  <Grid
                    container
                    //   display="flex"
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item marginTop={3}>
                      {/* <CtTxt
                        id="txtPassword"
                        label="Current Password"
                        variant="outlined"
                        handleOnChange={this.handleOnChange}
                        value={this.state.formData.txtPassword}
                        fullWidth={true}
                        onKeyDown={this.props.onKeyDown}
                        maxLength="50"
                        width={300}
                      /> */}
                      <CtTxtPWD
                        id="txtPassword"
                        label="Current Password"
                        variant="outlined"
                        handleOnChange={this.handleOnChange}
                        value={this.state.formData.txtPassword}
                        type="password"
                        fullWidth={true}
                        formcontrolStyle={{ marginTop: "20px" }}
                        onKeyDown={this.props.onKeyDown}
                        // defaultAction={this.verifyLogin}
                        maxLength="50"
                      />
                    </Grid>
                    <Grid item marginTop={2}>
                      <Stack direction="row" spacing={3}>
                        <CtBtn
                          variant="contained"
                          //   className={classes.submit}
                          width="auto"
                          // onClick={this.OnSaveclick}
                          onClick={this.onVerifyPassword}
                          label="Verify"
                          bgColor="#05699d"
                          firstletterBig={false}
                        />
                        <CtBtn
                          variant="outlined"
                          //   className={classes.submit}
                          firstletterBig={false}
                          width="auto"
                          onClick={() => {
                            this.props.nullARD();
                          }}
                          label="Close"
                          className={"child-button"}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Container>
          {/* </Grid> */}
        </Grid>
        <Grid item>
          <Box display={{ xs: "none" }} style={{ textAlign: "right" }}>
            {this.state.ARD}
          </Box>
        </Grid>
      </div>
    );
  }
}

// export default Login;
export default HoCtToastContainer(withRouter(CurrentPassword));
