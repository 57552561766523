import { setActiveMenu, setSubMenu_Master_Entry } from "../actions/navAction";
import {
  setButtonType,
  setPriceCalculation,
  setUserPrivileges,
} from "../actions/privilegesAction";

export const mapStatetoProps = (state) => {
  return {
    navInfo: state.navInfo,
    lists: state.lists,
    userPrivilege: state.userPrivilege,
    modules: state.modules,
    PriceCalculation: state.PriceCalculation,
    ButtonType: [
      { vac_button_type: "PUSH BUTTON" },
      { vac_button_type: "TOUCH BUTTON" },
    ],
  };
};

export const mapSetActiveMenuToProps = (dispatch) => {
  return {
    setActiveMenu: (ActiveMenu) => {
      dispatch(setActiveMenu(ActiveMenu));
    },
  };
};

export const mapSetActiveMenu_MenuVisibility_ToProps = (dispatch) => {
  return {
    setActiveMenu: (ActiveMenu) => {
      dispatch(setActiveMenu(ActiveMenu));
    },
    setSubMenu_Master_Entry: (
      subMenuMaster,
      subMenuEntry,
      subMenuReport,
      subMenuSetting
    ) => {
      dispatch(
        setSubMenu_Master_Entry(
          subMenuMaster,
          subMenuEntry,
          subMenuReport,
          subMenuSetting
        )
      );
    },
    setUserPrivileges: (UserPrivileges) => {
      dispatch(setUserPrivileges(UserPrivileges));
    },
  };
};

export const mapSetUserPrivileges_ToProps = (dispatch) => {
  return {
    setUserPrivileges: (UserPrivileges) => {
      dispatch(setUserPrivileges(UserPrivileges));
    },
    setPriceCalculation: (PriceCalculation) => {
      dispatch(setPriceCalculation(PriceCalculation));
    },
    // setButtonType: (ButtonType) => {
    //   dispatch(setButtonType(ButtonType));
    // },
  };
};
