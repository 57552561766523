import React from "react";
import Button from "@mui/material/Button";
import {
  CurrencyRupee,
  Edit,
  FileCopy,
  PlusOne,
  TextSnippet,
  Visibility,
} from "@mui/icons-material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddBoxIcon from "@mui/icons-material/AddBox";
import PrintIcon from "@mui/icons-material/Print";
import SaveIcon from "@mui/icons-material/Save";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ViewListIcon from "@mui/icons-material/ViewList";
import CachedIcon from "@mui/icons-material/Cached";
import { Upgrade } from "@mui/icons-material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import excel from "../../Images/excel.png";

// import "../theam.css";

const CtBtn = ({
  label,
  disabled,
  onClick,
  width,
  variant,
  color,
  bgColor,
  fontColor,
  fontWeight,
  justifyContent,
  hide,
  className,
  iconName,
  size,
  margin,
  firstletterBig = true,
}) => {
  let buttonStyle = {};
  buttonStyle.width = width > 0 ? width + "px" : "auto";
  if (
    (disabled === undefined || disabled !== true) &&
    (variant === undefined || variant === "contained")
  ) {
    buttonStyle.backgroundColor = "#42AA46";
    buttonStyle.color = "#ffffff";
  }
  if (bgColor !== undefined && bgColor.length > 0) {
    buttonStyle.backgroundColor = bgColor;
    buttonStyle.color = "#ffffff";
  }
  if (fontColor !== undefined && fontColor.length > 0) {
    // buttonStyle.backgroundColor = fontColor;
    buttonStyle.color = fontColor;
  }
  if (fontWeight !== undefined) {
    buttonStyle.fontWeight = fontWeight;
  }
  if (justifyContent !== undefined) {
    buttonStyle.justifyContent = justifyContent;
  }

  if (margin !== undefined) {
    buttonStyle.margin = margin;
  }

  if (className === undefined) {
    className = "secondary-button";
  }
  let icon = "";
  let endicon = "";
  let FirstLetterBigLable = (
    <>
      <span
        style={{
          // /* fontSize: "22px", */ /* color: "#00f3fc"  */ color: "#fff",
          textDecoration: "underline",
        }}
      >
        {label.charAt(0)}
      </span>{" "}
      {label.slice(1)}
    </>
  );
  if (iconName == "delete") {
    icon = <DeleteOutlineIcon />;
  } else if (iconName == "new") {
    icon = <AddBoxIcon />;
  } else if (iconName == "print") {
    icon = <PrintIcon />;
  } else if (iconName == "save") {
    icon = <SaveIcon />;
  } else if (iconName == "right") {
    endicon = <ArrowForwardIcon />;
  } else if (iconName == "left") {
    icon = <ArrowBackIcon />;
  } else if (iconName == "List") {
    icon = <ViewListIcon />;
  } else if (iconName == "load") {
    icon = <CachedIcon />;
  } else if (iconName == "Status") {
    icon = <Upgrade />;
  } else if (iconName == "Edit") {
    icon = <Edit />;
  } else if (iconName == "excel") {
    icon = <img src={excel} alt="excel" height={"25px"} width={"25px"} />;
  } else if (iconName == "Currency") {
    icon = <CurrencyRupee />;
  } else if (iconName == "Pdf") {
    icon = <TextSnippet />;
  } else if (iconName == "revise") {
    icon = <FileCopy />;
  }
  // if (disabled) {
  //   buttonStyle.cursor = "no-drop !important";
  // }

  let btnColor = color ? color : "primary";
  let button = (
    <Button
      variant={variant ? variant : "contained"}
      color={btnColor}
      disabled={disabled}
      style={buttonStyle}
      onClick={onClick}
      className={disabled ? "disable-button" : className}
      startIcon={icon}
      endIcon={endicon}
      size={size ? size : "medium"}
    >
      {firstletterBig === true ? FirstLetterBigLable : label}
      {/* {label} */}
    </Button>
  );
  if (disabled) {
    button = <span className="disable-button-cursor">{button}</span>;
  }
  return button;
};

export default CtBtn;
