import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { Component } from "react";
import CtTxt from "../Components/CustomTool/CtTxt";
import CtTxtPWD from "../Components/CustomTool/CtTxtPWD";
import CtBtn from "../Components/CustomTool/CtBtn";
import HoCtToastContainer from "../HOC/HoCtToastContainer";
import { Link, Navigate } from "react-router-dom";
import { withRouter } from "../HOC/withRouter";
import AlertResponsiveDialog from "../Components/CustomTool/AlertResponsiveDialog";
import { fetchUserManagement } from "./API";
// import { fetchUser } from "../Components/API";

class NewPassword extends Component {
  state = {
    formData: {
      txtNewPassword: "",
      txtConfrimNewPassword: "",
    },
  };

  componentDidMount() {
    document.getElementById("txtNewPassword").focus();
    this.PageTitle();
  }

  PageTitle = () => {
    document.title = "New Password : Micro Products";
  };

  handleOnChange = (e) => {
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: e.target.value },
    });
  };

  validatePassword = () => {
    let result = false;
    if (this.state.formData.txtNewPassword.length <= 0) {
      this.props.toastErrorMsg("Enter New Password", "txtNewPassword");
    } else if (this.state.formData.txtConfrimNewPassword.length <= 0) {
      this.props.toastErrorMsg(
        "Enter Confrim New Password",
        "txtConfrimNewPassword"
      );
    } else if (this.state.formData.txtNewPassword.length < 4) {
      this.props.toastErrorMsg(
        "The Length Of The New Password Should Be At Least 4",
        "txtNewPassword"
      );
    } else if (this.state.formData.txtConfrimNewPassword.length < 4) {
      this.props.toastErrorMsg(
        "The Length Of The Confrim New Password Should Be At Least 4",
        "txtConfrimNewPassword"
      );
    } else if (
      this.state.formData.txtConfrimNewPassword !==
      this.state.formData.txtNewPassword
    ) {
      this.props.toastErrorMsg(
        "New Password And Confrim New Password Are Not Same",
        "txtConfrimNewPassword"
      );
    } else {
      result = true;
    }
    return result;
  };

  onSetPassword = () => {
    if (this.validatePassword()) {
      this.props.notifyProcessing();
      const reqData = {
        Op: "NewPassword",
        vac_userid: this.props.UserId,
        vac_password: this.state.formData.txtConfrimNewPassword,
      };
      fetchUserManagement(reqData)
        .then((res) => {
          this.props.updateProcessing(res.data.msgType, res.data.message);
          if (res.data.msgType === "success") {
            this.OnSaveclick();
          }
        })
        .catch((error) => {
          console.log("Unknown error occurred in onFormLoad.", error);
        });
    }
  };

  OnSaveclick = () => {
    if (this.validatePassword()) {
      //if beckend call and success then close and go to login page
      if (
        this.props.loginMsgHide == undefined &&
        this.props.loginMsgHide != true
      ) {
        this.props.toastErrorMsg("Login With New credential");
      }
      this.props.nullARD();
    }
  };

  render() {
    return (
      <div>
        <Grid
          container
          display="flex"
          direction="row"
          justifyContent="center"
          alignItems="center"
          minheight="90vh"
          // spacing={2}
        >
          {/* <Grid item lg={4} md={6} style={{ marginTop: "100px" }}> */}
          <Container>
            <Card>
              <CardContent>
                <Typography
                  fontSize="30px"
                  display="flex"
                  justifyContent="Center"
                  fontWeight="600"
                  color="#05699d"
                >
                  Set New Password for {this.props.UserId}
                </Typography>
                <form>
                  <Grid
                    container
                    //   display="flex"
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item marginTop={3}>
                      {/* <CtTxt
                        id="txtNewPassword"
                        label="New Password"
                        variant="outlined"
                        handleOnChange={this.handleOnChange}
                        value={this.state.formData.txtNewPassword}
                        fullWidth={true}
                        onKeyDown={this.props.onKeyDown}
                        maxLength="50"
                        width={300}
                      /> */}
                      <CtTxtPWD
                        id="txtNewPassword"
                        label="New Password"
                        variant="outlined"
                        handleOnChange={this.handleOnChange}
                        value={this.state.formData.txtNewPassword}
                        type="password"
                        fullWidth={true}
                        formcontrolStyle={{ marginTop: "20px" }}
                        onKeyDown={this.props.onKeyDown}
                        // defaultAction={this.verifyLogin}
                        maxLength="50"
                      />
                    </Grid>
                    <Grid item marginTop={3}>
                      {/* <CtTxt
                        id="txtConfrimNewPassword"
                        label="Confrim New Password"
                        variant="outlined"
                        handleOnChange={this.handleOnChange}
                        value={this.state.formData.txtConfrimNewPassword}
                        fullWidth={true}
                        onKeyDown={this.props.onKeyDown}
                        maxLength="50"
                        width={300}
                      /> */}
                      <CtTxtPWD
                        id="txtConfrimNewPassword"
                        label="Confrim New Password"
                        variant="outlined"
                        handleOnChange={this.handleOnChange}
                        value={this.state.formData.txtConfrimNewPassword}
                        type="password"
                        fullWidth={true}
                        formcontrolStyle={{ marginTop: "20px" }}
                        onKeyDown={this.props.onKeyDown}
                        defaultAction={this.verifyLogin}
                        maxLength="50"
                      />
                    </Grid>
                    <Grid item marginTop={2}>
                      <Stack direction="row" spacing={3}>
                        <CtBtn
                          variant="contained"
                          //   className={classes.submit}
                          width="auto"
                          // onClick={this.OnSaveclick}
                          onClick={this.onSetPassword}
                          label="Set Password"
                          bgColor="#05699d"
                          firstletterBig={false}
                        />
                        <CtBtn
                          variant="outlined"
                          //   className={classes.submit}
                          firstletterBig={false}
                          width="auto"
                          onClick={() => {
                            this.props.nullARD();
                          }}
                          label="Close"
                          className={"child-button"}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Container>
          {/* </Grid> */}
        </Grid>
        <Grid item>
          <Box display={{ xs: "none" }} style={{ textAlign: "right" }}>
            {this.state.ARD}
          </Box>
        </Grid>
      </div>
    );
  }
}

// export default Login;
export default HoCtToastContainer(withRouter(NewPassword));
