import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import HOCVerifyIsUser from "../HOC/HOCVerifyIsUser";
import CtBtn from "../Components/CustomTool/CtBtn";
import CtCmb from "../Components/CustomTool/CtCmb";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import AlertResponsiveDialog from "../Components/CustomTool/AlertResponsiveDialog";
import HoCtToastContainer from "../HOC/HoCtToastContainer";
import {
  applyTrimOnObjectValues,
  csvFileName,
  getRowOfRT,
  setKeyboardShortcuts,
} from "../SystemUtility/SystemUtility";
import {
  mapSetUserPrivileges_ToProps,
  mapStatetoProps,
} from "../mapRedux/mapReduxProps";
import { connect } from "react-redux";
import { withRouter } from "../HOC/withRouter";
import { fetchRailSelection } from "./API";
import CtART from "./CustomTool/ResponsiveTable";
import CtCmbEditable from "./CustomTool/CtCmbEditable";

class RailSelection extends Component {
  state = {
    columnsdata: [
      {
        accessorKey: "bint_srno",
        header: "Sr No",
      },
      {
        accessorKey: "dec_passenger",
        header: "Passenger / Load Capacity",
        size: 360,
      },
      {
        accessorKey: "dec_speed",
        header: "Speed",
        size: 265,
      },

      {
        accessorKey: "vac_carrail",
        header: "Car Rail",
        size: 265,
      },
      {
        accessorKey: "vac_c_wrail",
        header: "C/W Rail",
        size: 265,
      },
    ],

    rows: [],
    Passanger: [],
    Speed: [],
    CarRail: [{ vac_carrail: "70x65x9" }, { vac_carrail: "89x62x16" }],
    CarCW: [{ vac_c_wrail: "45x45x5" }, { vac_c_wrail: "70x65x9" }],
    formData: {
      bint_srno: 0,
      cmbPassenger: "",
      cmbSpeed: "",
      cmbCarRail: "",
      cmbCWRail: "",
    },
    dataLoadStatus: true,
    ARD: null,
    rowsCount: 0,
    rowsPerPage: 50,
  };

  //#region Component
  componentDidMount() {
    // document.title = "User Management : Top India";
    // localStorage.setItem("Heading", "Auto Door Frame Price Formula Master");
    localStorage.setItem("Heading", "Master For Rail Selection");
    this.props.verifyUserID(
      this.getInfo,
      this.props.setUserPrivileges,
      this.props.modules.railselection
    );
    this.ShortCut();
    // this.getInfo();
  }

  componentWillUnmount() {
    this.removeShortCut();
  }

  // #endregion Component

  // #region Handle

  ShortCut = () => {
    this.removeShortCut = setKeyboardShortcuts(
      this.SaveRailSelection,
      this.clearInfo,
      () => {
        this.DeleteData(
          this.state.formData.bint_srno,
          this.state.formData.cmbPassenger
        );
      }
    );
  };

  handleOnChange = (e) => {
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: e.target.value },
    });
  };

  handleARDonActionClick_Edit = (rtRow) => {
    const row = getRowOfRT(rtRow, [
      "bint_srno",
      "dec_passenger",
      "dec_speed",
      "vac_carrail",
      "vac_c_wrail",
    ]);

    const bint_srno = row.bint_srno,
      cmbPassenger = row.dec_passenger,
      cmbSpeed = row.dec_speed,
      cmbCarRail = row.vac_carrail,
      cmbCWRail = row.vac_c_wrail;
    this.setState({
      formData: {
        ...this.state.formData,
        cmbPassenger,
        cmbSpeed,
        bint_srno,
        cmbCarRail,
        cmbCWRail,
      },
    });
    // window.scrollTo(0, 0);
    document.getElementById("cmbPassenger").focus();
  };

  handleARDonActionClick_Delete = (rtRow) => {
    const row = getRowOfRT(rtRow, ["bint_srno", "dec_passenger"]);
    this.DeleteData(row.bint_srno, row.dec_passenger);
  };

  //#endregion Handle

  //#region function

  trimFormData = (nextFunctionCall) => {
    let formData = applyTrimOnObjectValues({
      formData: this.state.formData,
    });
    this.setState({ formData }, () => {
      if (nextFunctionCall) {
        nextFunctionCall();
      }
    });
  };

  validationRailSelection = () => {
    let result = false;
    if (this.state.formData.cmbPassenger.length <= 0) {
      this.props.toastErrorMsg("Select Passenger Name", "cmbPassenger");
    } else if (
      this.state.formData.cmbPassenger.length > 0 &&
      isNaN(Number(this.state.formData.cmbPassenger))
    ) {
      this.props.toastErrorMsg(
        "Select Or Enter Valid Passenger Name",
        "cmbPassenger"
      );
    } else if (this.state.formData.cmbSpeed.length <= 0) {
      this.props.toastErrorMsg("Select Speed", "cmbSpeed");
    } else if (
      this.state.formData.cmbSpeed.length > 0 &&
      isNaN(Number(this.state.formData.cmbSpeed))
    ) {
      this.props.toastErrorMsg("Select Or Enter Valid Speed", "cmbSpeed");
    } else if (this.state.formData.cmbCarRail.length <= 0) {
      this.props.toastErrorMsg("Select Car Rail", "cmbCarRail");
    } else if (this.state.formData.cmbCWRail.length <= 0) {
      this.props.toastErrorMsg("Select C/W Rail", "cmbCWRail");
    } else {
      result = true;
    }
    return result;
  };

  getInfo = () => {
    this.props.notifyProcessing();
    const reqData = {
      Op: "ListRailSelection",
    };
    fetchRailSelection(reqData)
      .then((res) => {
        this.props.closeUpdateProcessing();
        let rows = res.data.ListRailSelection,
          rowsCount = rows.length;
        let Passanger = [];
        if (res.data.Passanger && res.data.Passanger.length > 0) {
          Passanger = res.data.Passanger;
        }
        let Speed = [];
        if (res.data.Speed && res.data.Speed.length > 0) {
          Speed = res.data.Speed;
        }

        this.setState({
          rowsCount,
          rows,
          Passanger,
          Speed,
        });
      })
      .catch((error) => {
        console.log("Unknown error occurred in onFormLoad.", error);
      });
    document.getElementById("cmbPassenger").focus();
  };

  clearInfo = () => {
    this.setState({
      formData: {
        ...this.state.formData,
        bint_srno: 0,
        cmbPassenger: "",
        cmbSpeed: "",
        cmbCarRail: "",
        cmbCWRail: "",
      },
    });
    document.getElementById("cmbPassenger").focus();
  };

  DeleteData = (bint_srno, Passenger) => {
    if (bint_srno > 0) {
      this.setState({ ARD: null }, () => {
        let ARD = (
          <AlertResponsiveDialog
            labelAgree="Delete"
            labelDisagree="Cancel"
            alertTitle={
              "Do you want to Delete Rail Selection : " + Passenger + " ?"
            }
            alertMessage=""
            handleOnClickYes={() => {
              return this.DeleteRailSelection(bint_srno);
            }}
            defaultOpenDialog={true}
            onYesClickCloseIfExeSuccessfully={true}
          />
        );

        this.setState({ ARD });
      });
    } else {
      this.props.toastErrorMsg("Select a record for deletion.");
    }
  };

  DeleteRailSelection = (bint_srno) => {
    this.props.notifyProcessing();
    const reqData = {
      Op: "DeleteRailSelection",
      bint_srno: bint_srno,
    };
    fetchRailSelection(reqData)
      .then((res) => {
        this.props.updateProcessing(res.data.msgType, res.data.message);
        if (res.data.msgType === "success") {
          let rows = [],
            rowsCount = rows.length;
          if (
            res.data.List_RailSelection &&
            res.data.List_RailSelection.length > 0
          ) {
            rows = res.data.List_RailSelection;
            rowsCount = rows.length;
          }
          let Passanger = [];
          if (res.data.Passanger && res.data.Passanger.length > 0) {
            Passanger = res.data.Passanger;
          }
          let Speed = [];
          if (res.data.Speed && res.data.Speed.length > 0) {
            Speed = res.data.Speed;
          }
          this.setState(
            {
              rows,
              rowsCount,
              ARD: null,
              Passanger,
              Speed,
            },
            () => {
              this.clearInfo();
            }
          );
          document.getElementById("cmbPassenger").focus();
        }
      })
      .catch((error) => {
        this.props.updateProcessing(
          "error",
          "Unknown error occurred in delete Rail Selection. " + error
        );
      });
    return false;
  };

  SaveRailSelection = () => {
    this.trimFormData(() => {
      if (this.validationRailSelection()) {
        this.props.notifyProcessing();
        const reqData = {
          Op: "SaveRailSelection",
          bint_srno: this.state.formData.bint_srno,
          dec_passenger: this.state.formData.cmbPassenger,
          dec_speed: this.state.formData.cmbSpeed,
          vac_carrail: this.state.formData.cmbCarRail,
          vac_c_wrail: this.state.formData.cmbCWRail,
        };
        fetchRailSelection(reqData)
          .then((res) => {
            this.props.updateProcessing(res.data.msgType, res.data.message);
            if (res.data.msgType === "success") {
              let rows = [],
                rowsCount = rows.length;
              if (
                res.data.List_RailSelection &&
                res.data.List_RailSelection.length > 0
              ) {
                rows = res.data.List_RailSelection;
                rowsCount = rows.length;
              }
              let Passanger = [];
              if (res.data.Passanger && res.data.Passanger.length > 0) {
                Passanger = res.data.Passanger;
              }
              let Speed = [];
              if (res.data.Speed && res.data.Speed.length > 0) {
                Speed = res.data.Speed;
              }
              this.setState(
                {
                  rows,
                  rowsCount,
                  Passanger,
                  Speed,
                },
                () => {
                  this.clearInfo();
                }
              );
            }
          })
          .catch((error) => {
            console.log(
              "Unknown error occurred in Save Rail Selection .",
              error
            );
          });
      }
    });
  };

  //#endregion function

  render() {
    return (
      <form>
        <Grid container direction="row" justifyContent="flex-end">
          <Grid item>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <CtBtn
                  className="secondary-button"
                  label="Save"
                  iconName="save"
                  onClick={this.SaveRailSelection}
                />
              </Grid>
              <Grid item>
                <CtBtn
                  label="New"
                  className="new-button"
                  iconName="new"
                  onClick={this.clearInfo}
                />
              </Grid>
              <Grid item>
                <CtBtn
                  label="Delete"
                  className="delete-button"
                  iconName="delete"
                  onClick={() => {
                    this.DeleteData(
                      this.state.formData.bint_srno,
                      this.state.formData.cmbPassenger
                    );
                  }}
                  disabled={this.state.formData.bint_srno > 0 ? false : true}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          marginTop={0}
        >
          <Grid item>
            <CtCmbEditable
              id="cmbPassenger"
              label="Passenger / Load Capacity*"
              items={this.state.Passanger}
              value={this.state.formData.cmbPassenger}
              handleOnChange={this.handleOnChange}
              width={250}
              maxLength={100}
              colID="dec_passenger"
            />
          </Grid>
          <Grid item>
            <CtCmbEditable
              id="cmbSpeed"
              label="Speed*"
              items={this.state.Speed}
              value={this.state.formData.cmbSpeed}
              handleOnChange={this.handleOnChange}
              width={160}
              maxLength={100}
              colID="dec_speed"
            />
          </Grid>
          <Grid item>
            <CtCmb
              id="cmbCarRail"
              label="Car Rail*"
              items={this.state.CarRail}
              value={this.state.formData.cmbCarRail}
              handleOnChange={this.handleOnChange}
              width={160}
              maxLength={100}
              colID="vac_carrail"
            />
          </Grid>
          <Grid item>
            <CtCmb
              id="cmbCWRail"
              label="C/W Rail*"
              items={this.state.CarCW}
              value={this.state.formData.cmbCWRail}
              handleOnChange={this.handleOnChange}
              width={160}
              maxLength={100}
              colID="vac_c_wrail"
            />
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          marginTop={1}
        >
          {/* responsive table */}
          <Grid item className="maintable">
            <CtART
              rowsdata={this.state.rows}
              columnsdata={this.state.columnsdata}
              rowsPerPage={this.state.rowsPerPage}
              tblmaxHeight={500}
              csvFileName={csvFileName("Passenger Lift Cabin Price")}
              rowIconAction={[
                {
                  label: "Edit",
                  onClick: this.handleARDonActionClick_Edit,
                  icon: "Edit",
                  className: "secondary-button",
                },
                {
                  label: "Delete",
                  onClick: this.handleARDonActionClick_Delete,
                  icon: "delete",
                  className: "delete-button",
                },
              ]}
              showColumnFilters={false}
              actionColSize={90}
              density={"compact"}
              hideColIdList={["bint_srno"]}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Box display={{ xs: "none" }} style={{ textAlign: "right" }}>
            {this.state.ARD}
          </Box>
        </Grid>
      </form>
    );
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <RailSelection {...props} navigate={navigate} />;
}
export default connect(
  mapStatetoProps,
  mapSetUserPrivileges_ToProps
)(HoCtToastContainer(HOCVerifyIsUser(withRouter(WithNavigate))));
