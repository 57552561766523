import React, { Component } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  mapSetUserPrivileges_ToProps,
  mapStatetoProps,
} from "../mapRedux/mapReduxProps";
import { withRouter } from "../HOC/withRouter";
import HOCVerifyIsUser from "../HOC/HOCVerifyIsUser";
import HoCtToastContainer from "../HOC/HoCtToastContainer";
import { connect } from "react-redux";
import {
  AddCircleOutlined,
  Description,
  ExpandLess,
  ExpandMore,
  ListAltOutlined,
  MeetingRoom,
} from "@mui/icons-material";
import Packing from "../Images/packing.png";
import calculations from "../Images/calculations.png";
import {
  Box,
  Card,
  CardContent,
  Collapse,
  Grid,
  ListItem,
  ListItemIcon,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import "./Dash.css";
import axios from "axios";
import CalculateIcon from "@mui/icons-material/Calculate";
import { apiAngularURLProposal, fetchDashboard, fetchProposal } from "./API";
import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";
import SaveIcon from "@mui/icons-material/Save";
import ProposalEntry from "./ProposalEntry";
import {
  applyTrimOnObjectValues,
  isValidItem,
  validatedate_min_max,
  validateEmail,
} from "../SystemUtility/SystemUtility";

class Dashboard extends Component {
  state = {
    ARD: null,
    Person: [],
    Branches: [],
    childValues: {},
    LoadCapacity: [],
    EntryList: [
      {
        module: "Entry:Proposal",
        ListName: "Proposal",
        Icon: <Description /* fontSize="large" */ />,
        listEntry: true,
        listLink: "/Proposal",
        EntryLink: "/ProposalEntry",
      },
      // {
      //   module: "Entry:JSD",
      //   ListName: "JSD",
      //   Icon: <MeetingRoom /* fontSize="large" */ />,
      //   listEntry: true,
      //   // listLink: "/Proposal",
      //   // EntryLink: "/ProposalEntry",
      // },
      // {
      //   module: "Entry:Packing",
      //   ListName: "Packing",
      //   Icon: (
      //     <img
      //       src={Packing}
      //       alt="Packing"
      //       height={"27px"}
      //       width={"27px"}
      //       style={{
      //         filter:
      //           "invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(0%) contrast(100%)",
      //       }}
      //     />
      //   ),
      //   listEntry: true,
      //   listLink: "/Proposal",
      //   EntryLink: "/ProposalEntry",
      // },
      {
        module: "Entry:Price Calculator",
        ListName: "Price Calculator",
        Icon: (
          <CalculateIcon />
          // <img
          //   src={calculations}
          //   alt="calculations"
          //   height={"27px"}
          //   width={"27px"}
          //   style={{
          //     filter:
          //       "invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(0%) contrast(100%)",
          //   }}
          // />
        ),
        listEntry: true,
        listLink: "/InputParametersList",
        EntryLink: "/InputParametersEntry",
      },
    ],

    LinkEntryList: [
      {
        module: "Entry:JSD",
        ListName: "JSD",
        Icon: <MeetingRoom /* fontSize="large" */ />,
        listEntry: true,
        hrefEntry: `${apiAngularURLProposal}Page=JSDEntry`,
        hrefList: `${apiAngularURLProposal}Page=JSDList`,
        // hrefEntry:
        //   "http://127.0.0.1/Development/TopIndiaElevator/TOP_ERP/JSD/setsession_fromreact.php?SessionUserID=admin&Page=JSDEntry",
        // hrefList:
        //   "http://127.0.0.1/Development/TopIndiaElevator/TOP_ERP/JSD/setsession_fromreact.php?SessionUserID=admin&Page=JSDList",
      },
      {
        module: "Entry:Material Packing Edit",
        ListName: "Packing",
        Icon: (
          <img
            src={Packing}
            alt="Packing"
            height={"27px"}
            width={"27px"}
            style={{
              filter:
                "invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(0%) contrast(100%)",
            }}
          />
        ),
        listEntry: true,
        // hrefEntry:
        //   "http://127.0.0.1/Development/Top-India/php/top-india-proposal-jsd-packing/tin/index.html#/",
        // hrefList:
        //   "http://127.0.0.1/Development/Top-India/php/top-india-proposal-jsd-packing/tin/index.html#/",
        // hrefEntry: "https://ravitechworld.in/tiepl/tin/index.html/",
        // hrefList: "https://ravitechworld.in/tiepl/tin/index.html/",
        hrefEntry: `${apiAngularURLProposal}Page=Packing`,
        hrefList: `${apiAngularURLProposal}Page=Packing`,
      },
    ],

    // OutPutList: [
    //   {
    //     module: "Entry:Proposal",
    //     ListName: "Rail Price Master",
    //     Icon: <Description />,
    //     list: true,
    //     listLink: "/RailPriceMaster",
    //   },
    //   {
    //     module: "Entry:Proposal",
    //     ListName: "Gp Bracket Price Master",
    //     Icon: <MeetingRoom />,
    //     list: true,
    //     listLink: "/GpBracketPriceMaster",
    //   },
    //   {
    //     module: "Entry:Proposal",
    //     ListName: "Counter Weight Price List",
    //     Icon: <Description />,
    //     list: true,
    //     listLink: "/CounterWeightPrice",
    //   },
    //   {
    //     module: "Entry:Proposal",
    //     ListName: "General Item Price List",
    //     Icon: <Description />,
    //     list: true,
    //     listLink: "/GeneralItemPrice",
    //   },
    //   {
    //     module: "Entry:Proposal",
    //     ListName: "Main & Governor Wire Rope Price List & Tecnical Detail",
    //     Icon: <Description />,
    //     list: true,
    //     listLink: "/MainGovernorWire",
    //   },
    //   {
    //     module: "Entry:JSD",
    //     ListName: "Raw Material & Process Price List",
    //     Icon: <MeetingRoom />,
    //     list: true,
    //     listLink: "/RawMaterialProcess",
    //   },
    //   {
    //     module: "Entry:JSD",
    //     ListName: "Counter Weight KG",
    //     Icon: <MeetingRoom />,
    //     list: true,
    //     listLink: "/CounterWeightKg",
    //   },
    //   {
    //     module: "Entry:JSD",
    //     ListName: "Pulley Price List",
    //     Icon: <MeetingRoom />,
    //     list: true,
    //     listLink: "/PulleyPriceList",
    //   },
    //   {
    //     module: "Entry:JSD",
    //     ListName: "Items Related To Safety Set Price List",
    //     Icon: <MeetingRoom />,
    //     list: true,
    //     listLink: "/ItemsRelatedSafety",
    //   },
    //   {
    //     module: "Entry:JSD",
    //     ListName: "Passenger Lift Cabin Price List",
    //     Icon: <MeetingRoom />,
    //     list: true,
    //     listLink: "/PassengerLiftCabinPrice",
    //   },
    //   {
    //     module: "Entry:JSD",
    //     ListName: "Goods Lift Cabin Entry",
    //     Icon: <MeetingRoom />,
    //     list: true,
    //     listLink: "/GoodsLiftCabin",
    //   },
    //   {
    //     module: "Entry:JSD",
    //     ListName: "Auto Door Main Price List",
    //     Icon: <MeetingRoom />,
    //     list: true,
    //     listLink: "/AutoDoorMainPrice",
    //   },
    //   {
    //     module: "Entry:JSD",
    //     ListName: "Auto Door Panel Price Formula Master",
    //     Icon: <MeetingRoom />,
    //     list: true,
    //     listLink: "/AutoDoorPanelPriceFomula",
    //   },
    //   {
    //     module: "Entry:JSD",
    //     ListName: "Auto Door Frame Price Formula Master",
    //     Icon: <MeetingRoom />,
    //     list: true,
    //     listLink: "/AutoDoorFramePriceFormula",
    //   },
    //   {
    //     module: "Entry:JSD",
    //     ListName: "Master For Rail Selection",
    //     Icon: <MeetingRoom />,
    //     list: true,
    //     listLink: "/RailSelection",
    //   },
    //   {
    //     module: "Entry:JSD",
    //     ListName: "Master For Car Inside, Car DBG & C/W DBG Selection",
    //     Icon: <MeetingRoom />,
    //     list: true,
    //     listLink: "/CarInsideDbgCwSelection",
    //   },
    //   {
    //     module: "Entry:JSD",
    //     ListName: "Safety Main Price",
    //     Icon: <MeetingRoom />,
    //     list: true,
    //     listLink: "/SafetyMainPrice",
    //   },
    //   {
    //     module: "Entry:JSD",
    //     ListName: "Manual Door Price",
    //     Icon: <MeetingRoom />,
    //     list: true,
    //     listLink: "/ManualDoorPrice",
    //   },
    //   {
    //     module: "Entry:JSD",
    //     ListName: "Controller & Main VF Drive Pricing",
    //     Icon: <MeetingRoom />,
    //     list: true,
    //     listLink: "/ControllerMainVfDrivePricing",
    //   },
    //   {
    //     module: "Entry:JSD",
    //     ListName: "Combination Bracket Price",
    //     Icon: <MeetingRoom />,
    //     list: true,
    //     listLink: "/CombinationBracketPrice",
    //   },
    //   {
    //     module: "Entry:JSD",
    //     ListName: "Car Frame & C/W Frame Selection",
    //     Icon: <MeetingRoom />,
    //     list: true,
    //     listLink: "/CarAndCwFrameSelection",
    //   },
    // ],
    isChecked: localStorage.getItem("isChecked") === "false" ? false : true,
    OutPutListCollapse: localStorage.getItem("OutPutListCollapse") === "true",
  };

  componentDidMount() {
    // document.title = "Top India Elevator : Dashboard";
    localStorage.setItem("Heading", "Dashboard");

    this.props.verifyUserID(
      this.getInfo,
      this.props.setUserPrivileges
      // this.props.proposal
    );
    // this.getInfo();
  }

  getInfo = () => {
    this.props.notifyProcessing();
    const reqData = {
      Op: "LoadDashboardData",
    };
    fetchDashboard(reqData)
      .then((res) => {
        this.props.closeUpdateProcessing();
        // let Person =
        //   res.data.PassangerList && res.data.PassangerList.length > 0
        //     ? res.data.PassangerList
        //     : [];
        let Person = [],
          LoadCapacity = [],
          Branches = res.data.Branches.length > 0 ? res.data.Branches : [];
        res.data.PassangerList.forEach((row) => {
          if (row.vac_type == "Passenger") {
            Person.push({ dec_passenger: `${row.dec_passenger}` });
          } else {
            LoadCapacity.push({ dec_passenger: `${row.dec_passenger}` });
          }
        });
        this.setState({
          Person,
          LoadCapacity,
          Branches,
        });
      })
      .catch((error) => {
        console.log("Unknown error occurred in onFormLoad.", error);
      });
  };

  toggleClick = () => {
    if (this.state.isChecked == true) {
      this.setState(
        {
          OutPutListCollapse: true,
          // TraningCollapse: true,
          // PayRollMasterCollapse: true,
          // PurchaseCollapse: true,
          // GatePassCollapse: true,
        },
        () => {
          localStorage.setItem("OutPutListCollapse", true);
          // localStorage.setItem("TraningCollapse", true);
          // localStorage.setItem("PayRollMasterCollapse", true);
          // localStorage.setItem("PurchaseCollapse", true);
          // localStorage.setItem("GatePassCollapse", true);
        }
      );
    } else {
      this.setState(
        {
          OutPutListCollapse: false,
          // TraningCollapse: false,
          // PayRollMasterCollapse: false,
          // PurchaseCollapse: false,
          // GatePassCollapse: false,
        },
        () => {
          localStorage.setItem("OutPutListCollapse", false);
          // localStorage.setItem("TraningCollapse", false);
          // localStorage.setItem("PayRollMasterCollapse", false);
          // localStorage.setItem("PurchaseCollapse", false);
          // localStorage.setItem("GatePassCollapse", false);
        }
      );
    }
  };

  handleOnCheck = () => {
    const isChecked = !this.state.isChecked;
    this.setState({ isChecked }, () => {
      this.toggleClick();
    });
    localStorage.setItem("isChecked", isChecked);
  };

  handleCollapse = (CollapseName) => {
    this.setState((prevState) => {
      const newState = !prevState[CollapseName];
      localStorage.setItem(CollapseName, newState);
      if (
        localStorage.getItem("OutPutListCollapse") === "true" /* &&
        localStorage.getItem("TraningCollapse") === "true" &&
        localStorage.getItem("PayRollMasterCollapse") === "true" &&
        localStorage.getItem("PurchaseCollapse") === "true" &&
        localStorage.getItem("GatePassCollapse") === "true" */
      ) {
        localStorage.setItem("isChecked", true);
        return {
          [CollapseName]: newState,
          isChecked: true,
        };
      } else if (
        localStorage.getItem("OutPutListCollapse") === "false" /* &&
        localStorage.getItem("TraningCollapse") === "false" &&
        localStorage.getItem("PayRollMasterCollapse") === "false" &&
        localStorage.getItem("PurchaseCollapse") === "false" &&
        localStorage.getItem("GatePassCollapse") === "false" */
      ) {
        localStorage.setItem("isChecked", false);
        return {
          [CollapseName]: newState,
          isChecked: false,
        };
      } else {
        return {
          [CollapseName]: newState,
        };
      }
      // return {
      //   [CollapseName]: newState,
      // };
    });
  };

  handleOnNewEntryClick = () => {
    let ARD = null;
    this.setState({ ARD }, () => {
      ARD = (
        <AlertResponsiveDialog
          labelPrimary="Save"
          PrimaryIcon={<SaveIcon />}
          labelDisagree="Close"
          alertHeading={"Proposal Entry"}
          alertMessage={<ProposalEntry getChildValues={this.getChildValues} />}
          defaultOpenDialog={true}
          onYesClickCloseIfExeSuccessfully={true}
          handleOnClickYes={this.saveProposalEntry}
          handleOnClickNo={() => {}}
          maxWidthClass="100%"
        />
      );
      this.setState({ ARD });
    });
  };

  getChildValues = (childValues) => {
    this.setState({
      childValues,
    });
  };

  validateProposalEntry = (formData) => {
    let result = false;
    if (formData.dtpDate.length <= 0) {
      this.props.toastErrorMsg("Select Date", "dtpDate");
    } else if (
      validatedate_min_max(
        formData.dtpDate,
        "dtpDate",
        this.props.toastErrorMsg
      ) == false
    ) {
      // return result;
    } else if (formData.cmbEntryBranch.length <= 0) {
      this.props.toastErrorMsg("Select Branch ", "cmbEntryBranch");
    } else if (
      formData.cmbEntryBranch.length > 0 &&
      !isValidItem(this.state.Branches, "vac_brname", formData.cmbEntryBranch)
    ) {
      this.props.toastErrorMsg("Select Valid Branch ", "cmbEntryBranch");
    } else if (formData.txtRef.length <= 0) {
      this.props.toastErrorMsg("Enter Ref. No.", "txtRef");
    } else if (Number(formData.txtUnique) <= 0) {
      this.props.toastErrorMsg("Enter Unique Lift Count", "txtUnique");
    } else if (formData.txtBilling.length <= 0) {
      this.props.toastErrorMsg("Enter Billing Name", "txtBilling");
    } else if (formData.txtBillingAdd.length <= 0) {
      this.props.toastErrorMsg(
        "Enter Billing Address (Without Billing Name)",
        "txtBillingAdd"
      );
    } else if (formData.txtSite.length <= 0) {
      this.props.toastErrorMsg("Enter Site Name", "txtSite");
    } else if (formData.txtSiteAdd.length <= 0) {
      this.props.toastErrorMsg(
        "Enter Site Address (Without Site Name)",
        "txtSiteAdd"
      );
    } else if (formData.txtCust.length <= 0) {
      this.props.toastErrorMsg("Enter Customer Name", "txtCust");
    } else if (formData.txtCusCity.length <= 0) {
      this.props.toastErrorMsg("Enter Customer City", "txtCusCity");
    } else if (!/^\d{10}$/.test(formData.txtCustPhone)) {
      this.props.toastErrorMsg(
        "Enter a valid 10-digit Customer Phone No.",
        "txtCustPhone"
      );
    } else if (
      formData.txtEmail.length > 0 &&
      !validateEmail(formData.txtEmail)
    ) {
      this.props.toastErrorMsg("Enter a valid Customer Email ID.", "txtEmail");
    } else {
      result = true;
    }
    return result;
  };

  trimFormData = (nextFunctionCall) => {
    let formData = applyTrimOnObjectValues({
      formData: this.state.formData,
    });
    this.setState({ formData }, () => {
      if (nextFunctionCall) {
        nextFunctionCall();
      }
    });
  };

  saveProposalEntry = () => {
    // this.trimFormData(() => {
    if (this.validateProposalEntry(this.state.childValues)) {
      this.props.notifyProcessing();
      const reqData = {
        Op: "SaveNewProposalEntry",
        bint_uniqueliftcount: this.state.childValues.txtUnique,
        vac_branchname: this.state.childValues.cmbEntryBranch,
        vac_refno: this.state.childValues.txtRef,
        dat_date: this.state.childValues.dtpDate,
        vac_companyname: this.state.childValues.txtBilling,
        vac_companyadd: this.state.childValues.txtBillingAdd,
        vac_sitename: this.state.childValues.txtSite,
        vac_siteaddress: this.state.childValues.txtSiteAdd,
        vac_name: this.state.childValues.txtCust,
        vac_companycity: this.state.childValues.txtCusCity,
        vac_mobileno: this.state.childValues.txtCustPhone,
        vac_emailid: this.state.childValues.txtEmail,
        vac_userid: localStorage.getItem("vac_userid"),
        int_status: 1,
        bol_revision: false,
        bint_totalliftcount: 0,
      };
      fetchProposal(reqData)
        .then((res) => {
          this.props.updateProcessing(res.data.msgType, res.data.message);
          if (res.data.msgType === "success") {
            let ProposalDashboardList =
                res.data.RailPriceList && res.data.RailPriceList.length > 0
                  ? res.data.RailPriceList
                  : [],
              bint_summarysrno = res.data.bint_summarysrno,
              TblYear = res.data.TblYear,
              vac_brcode = res.data.vac_brcode;
            this.setState(
              {
                ProposalDashboardList,
                bint_summarysrno,
              },
              () => {
                this.props.navigateTo("ProposalAllEntry", {
                  bint_summarysrno: this.state.bint_summarysrno,
                  dtpDate: this.state.childValues.dtpDate,
                  cmbBranch: this.state.childValues.cmbEntryBranch,
                  TblYear,
                  vac_brcode,
                });
              }
            );
          }
        })
        .catch((error) => {
          console.log("Unknown error occurred in Save Proposal .", error);
        });
    }
    // });
  };

  render() {
    return (
      <>
        {this.state.OutPutList && this.state.OutPutList.length > 0 ? (
          <Grid container justifyContent={"flex-end"}>
            <b
              style={{
                paddingRight: "12px",
                paddingTop: "8px",
                cursor: "pointer",
              }}
              onClick={this.handleOnCheck}
            >
              {/* {this.state.isChecked ? "Expand All" : "Collapse All"} */}
              {this.state.isChecked ? "Collapse All" : "Expand All"}
            </b>

            <Switch
              checked={this.state.isChecked}
              onChange={this.handleOnCheck}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Grid>
        ) : (
          ""
        )}
        <Grid
          container
          justifyContent="flex-start"
          direction="row"
          alignContent={"center"}
          spacing={1}
          marginTop={1}
          paddingBottom={1}
          paddingRight={1}
          style={{
            backgroundColor: "#e8e9eb",
            borderRadius: "10px",
          }}
        >
          {this.state.EntryList.map((row, index) => {
            if (this.props.userPrivilege[row.module]) {
              return (
                <Grid item lg={3} md={4} sm={6} xs={12} key={index}>
                  <Link to={row.listLink} style={{ textDecoration: "none" }}>
                    <Card
                      style={{
                        height: "80px",
                        justifyContent: "center",
                        alignContent: "center",
                      }}
                      className="card"
                    >
                      <CardContent>
                        <Stack
                          direction={"row"}
                          spacing={3}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                        >
                          <Stack
                            direction={"row"}
                            spacing={1}
                            alignItems={"center"}
                          >
                            {row.Icon}
                            <Typography fontSize={18}>
                              {row.ListName}
                            </Typography>
                          </Stack>
                          {row.listEntry && row.listEntry == true ? (
                            <>
                              <Stack
                                direction={"row"}
                                // justifyContent={"center"}
                                spacing={0.5}
                              >
                                <Tooltip title={"Add " + row.ListName} arrow>
                                  {row.EntryLink == "/ProposalEntry" ? (
                                    // alert("call")
                                    <AddCircleOutlined
                                      style={{
                                        color: "#177cdd",
                                      }}
                                      className="addIcon"
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        event.preventDefault();
                                        this.handleOnNewEntryClick();
                                      }}
                                    />
                                  ) : (
                                    <Link
                                      to={row.EntryLink}
                                      state={
                                        row.EntryLink ===
                                        "/InputParametersEntry"
                                          ? {
                                              Person: this.state.Person,
                                              LoadCapacity:
                                                this.state.LoadCapacity,
                                            }
                                          : {}
                                      }
                                      style={{ textDecoration: "none" }}
                                    >
                                      <AddCircleOutlined
                                        style={{
                                          color: "#177cdd",
                                        }}
                                        className="addIcon"
                                      />
                                    </Link>
                                  )}
                                </Tooltip>
                                <Tooltip title={row.ListName + " List"} arrow>
                                  <ListAltOutlined />
                                </Tooltip>
                              </Stack>
                            </>
                          ) : (
                            ""
                          )}
                          {row.list && row.list == true ? (
                            <Stack
                              direction={"row"}
                              // justifyContent={"center"}
                              spacing={0.5}
                            >
                              <Tooltip title={row.ListName + " List"} arrow>
                                <ListAltOutlined />
                              </Tooltip>
                            </Stack>
                          ) : (
                            ""
                          )}
                        </Stack>
                      </CardContent>
                    </Card>
                  </Link>
                </Grid>
              );
            }
          })}
          {this.state.LinkEntryList.map((row, index) => {
            if (this.props.userPrivilege[row.module]) {
              return (
                <Grid item lg={3} md={4} sm={6} xs={12} key={index}>
                  <a
                    href={row.hrefList}
                    rel="noreferrer noopener"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <Card
                      style={{
                        height: "80px",
                        justifyContent: "center",
                        alignContent: "center",
                      }}
                      className="card"
                    >
                      <CardContent>
                        <Stack
                          direction={"row"}
                          spacing={3}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                        >
                          <Stack
                            direction={"row"}
                            spacing={1}
                            alignItems={"center"}
                          >
                            {row.Icon}
                            <Typography fontSize={18}>
                              {row.ListName}
                            </Typography>
                          </Stack>
                          {row.listEntry && row.listEntry == true ? (
                            <>
                              <Stack direction={"row"} spacing={0.5}>
                                <Tooltip title={"Add " + row.ListName} arrow>
                                  <a
                                    href={row.hrefEntry}
                                    rel="noreferrer noopener"
                                    target="_blank"
                                  >
                                    <AddCircleOutlined
                                      style={{
                                        color: "#177cdd",
                                      }}
                                      className="addIcon"
                                    />
                                  </a>
                                </Tooltip>
                                <a
                                  href={row.hrefList}
                                  rel="noreferrer noopener"
                                  target="_blank"
                                  style={{ textDecoration: "none" }}
                                >
                                  <Tooltip title={row.ListName + " List"} arrow>
                                    <ListAltOutlined className="ListIcon" />
                                  </Tooltip>
                                </a>
                              </Stack>
                            </>
                          ) : (
                            ""
                          )}
                        </Stack>
                      </CardContent>
                    </Card>
                  </a>
                </Grid>
              );
            }
          })}
        </Grid>

        {/* <Card>
          <CardContent>
           apiAngularURL
              Hello
            </a>
          </CardContent>
        </Card> */}

        {/* <Grid container>
          <Grid item>
            <Card>
              <CardContent>
                <a
                  href="http://127.0.0.1/Development/TopIndiaElevator/topindiaelevator.in-proposal/topindiaelevatorproposal/"
                  // rel="noreferrer noopener"
                  target="_blank"
                  // className="menulink"
                >
                  JSD
                </a>
              </CardContent>
            </Card>
          </Grid>
        </Grid> */}

        {this.state.OutPutList && this.state.OutPutList.length > 0 ? (
          <Grid
            container
            direction="column"
            marginTop={2}
            paddingBottom={1}
            spacing={1}
            paddingRight={1}
            paddingLeft={1}
            style={{ backgroundColor: "#e8e9eb", borderRadius: "10px" }}
          >
            <Grid item>
              <Grid container direction="row" justifyContent={"space-between"}>
                <Grid item>
                  <Typography
                    fontWeight={"bold"}
                    fontSize={20}
                    style={{
                      color: "black",
                      textTransform: "uppercase",
                      marginTop: "4px",
                    }}
                  >
                    Price Calculator Master
                  </Typography>
                </Grid>
                <Grid item>
                  <ListItem
                    onClick={() => {
                      this.handleCollapse("OutPutListCollapse");
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        cursor: "pointer",
                        justifyContent: "center",
                      }}
                    >
                      {this.state.OutPutListCollapse ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </ListItemIcon>
                  </ListItem>
                </Grid>
              </Grid>
            </Grid>
            <Collapse in={this.state.OutPutListCollapse}>
              <Grid item>
                <Grid
                  container
                  //   justifyContent="center"
                  justifyContent="flex-start"
                  //   alignItems={"center"}
                  direction="row"
                  alignContent={"center"}
                  spacing={1}
                >
                  {this.state.OutPutList.map((row, index) => {
                    if (this.props.userPrivilege[row.module]) {
                      return (
                        <Grid item lg={4} md={6} sm={6} xs={12} key={index}>
                          <Link
                            to={row.listLink}
                            style={{ textDecoration: "none" }}
                          >
                            <Card
                              style={{
                                height: "auto",
                                justifyContent: "center",
                                alignContent: "center",
                                minHeight: "90px",
                              }}
                              className="card"
                            >
                              <CardContent>
                                <Stack
                                  direction={"row"}
                                  spacing={3}
                                  alignItems={"center"}
                                  justifyContent={"space-between"}
                                >
                                  <Stack
                                    direction={"row"}
                                    spacing={1}
                                    alignItems={"center"}
                                  >
                                    {row.Icon}
                                    <Typography>{row.ListName}</Typography>
                                  </Stack>
                                  {row.listEntry && row.listEntry == true ? (
                                    <>
                                      <Stack
                                        direction={"row"}
                                        // justifyContent={"center"}
                                        spacing={0.5}
                                      >
                                        {this.props.userPrivilege[row.module] ==
                                          "M" ||
                                        this.props.userPrivilege[row.module] ==
                                          "W" ? (
                                          <Tooltip
                                            title={"Add " + row.ListName}
                                            arrow
                                          >
                                            <Link
                                              to={row.EntryLink}
                                              style={{ textDecoration: "none" }}
                                            >
                                              <AddCircleOutlined
                                                style={{
                                                  color: "#177cdd",
                                                }}
                                                className="addIcon"
                                              />
                                            </Link>
                                          </Tooltip>
                                        ) : (
                                          ""
                                        )}
                                        <Tooltip
                                          title={row.ListName + " List"}
                                          arrow
                                        >
                                          <ListAltOutlined />
                                        </Tooltip>
                                      </Stack>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {row.list && row.list == true ? (
                                    <Stack
                                      direction={"row"}
                                      // justifyContent={"center"}
                                      spacing={0.5}
                                    >
                                      <Tooltip
                                        title={row.ListName + " List"}
                                        arrow
                                      >
                                        <ListAltOutlined />
                                      </Tooltip>
                                    </Stack>
                                  ) : (
                                    ""
                                  )}
                                </Stack>
                              </CardContent>
                            </Card>
                          </Link>
                        </Grid>
                      );
                    }
                  })}
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        ) : (
          ""
        )}
        {/* <a
          // href="http://127.0.0.1/Development/TopIndiaElevator/topindiaelevator.in-proposal/topindiaelevatorproposal/setsession_fromreact.php?SessionUserID=rite&SessionUserName=Rite"
          href="http://127.0.0.1/Development/TopIndiaElevator/TOP_ERP/JSD/setsession_fromreact.php?SessionUserID=rite&SessionUserName=Rite"
          rel="noreferrer noopener"
          target="_blank"
        >
          <AddCircleOutlined
            style={{
              color: "#177cdd",
            }}
          />
        </a>
        <a
          href="http://127.0.0.1/Development/TopIndiaElevator/TOP_ERP/PackingMaterial/index.html"
          rel="noreferrer noopener"
          target="_blank"
        >
          <AddCircleOutlined
            style={{
              color: "#177cdd",
            }}
          />
        </a> */}
        <Grid item>
          <Box display={{ xs: "none" }} style={{ textAlign: "right" }}>
            {this.state.ARD}
          </Box>
        </Grid>
      </>
    );
  }
}

// export default Dashboard;
function WithNavigate(props) {
  let navigate = useNavigate();
  return <Dashboard {...props} navigate={navigate} />;
}
export default connect(
  mapStatetoProps,
  mapSetUserPrivileges_ToProps
)(HoCtToastContainer(HOCVerifyIsUser(withRouter(WithNavigate))));
