import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import HOCVerifyIsUser from "../HOC/HOCVerifyIsUser";
import HoCtToastContainer from "../HOC/HoCtToastContainer";
import "../App.css";
import CtTxt from "../Components/CustomTool/CtTxt";
import CtTxtSave from "../Components/CustomTool/CtTxtSave";
import CtBtn from "../Components/CustomTool/CtBtn";
import MaterialTable from "../Components/CustomTool/MaterialTable";
import "./Table.css";

import { fetchDepartment } from "../Components/API";
import AlertResponsiveDialog from "../Components/CustomTool/AlertResponsiveDialog";
import Progress from "../Components/CustomTool/Progress";
import {
  applyTrimOnObjectValues,
  csvFileName,
  get_30DaysBack_FromCurrentDate,
  get_YMD_from_SYS,
} from "../SystemUtility/SystemUtility";
import ProposalEntry from "./ProposalEntry";
import { withRouter } from "../HOC/withRouter";
import { connect } from "react-redux";
import {
  mapSetUserPrivileges_ToProps,
  mapStatetoProps,
} from "../mapRedux/mapReduxProps";
import CtDtp from "./CustomTool/CtDtp";
import TableList from "./CustomTool/TableList";
import { Done, Edit } from "@mui/icons-material";

class CheckSaveText extends Component {
  state = {
    formData: {
      dtpfromdate: get_30DaysBack_FromCurrentDate(new Date()),
      dtptodate: get_YMD_from_SYS(new Date()),
      txtSave: "",
    },
    rowsCount: 0,
    rowsPerPage: 50,
    dataLoadStatus: true,
    ARD: null,
    inStatus: true,
  };

  //#region component

  componentDidMount() {
    localStorage.setItem("Heading", "check save");
    // document.title = "Top India Elevator : Input Parameters List";
    // this.ShortCut();
    // // this.onFormLoad();
    this.props.verifyUserID(
      undefined,
      this.props.setUserPrivileges,
      this.props.modules.proposal
    );
  }

  // componentWillUnmount() {
  //   this.removeShortCut();
  // }

  //#endregion component

  //#region function

  trimFormData = (nextFunctionCall) => {
    let formData = applyTrimOnObjectValues({
      formData: this.state.formData,
    });
    this.setState({ formData }, () => {
      if (nextFunctionCall) {
        nextFunctionCall();
      }
    });
  };

  //#endregion function

  //#region handle

  handleOnChange = (e) => {
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: e.target.value },
    });
  };

  //   handleOnResetClick = () => {
  //     this.props.toastMsg("success", "on reset click");
  //   };
  //   handleOnUndoClick = () => {
  //     this.props.toastMsg("success", "on undo click");
  //   };

  handleOnSaveClick = () => {
    this.props.toastMsg("success", "on Save click");
    this.setState({
      inStatus: !this.state.inStatus,
    });
  };

  //#endregion handle

  render() {
    return (
      <form>
        <Grid container direction="row" justifyContent="flex-start" spacing={3}>
          <Grid item>
            <CtTxtSave
              id="txtSave"
              value={this.state.formData.txtSave}
              label="Edit And Save"
              handleOnChange={this.handleOnChange}
              //   handleOnResetClick={this.handleOnResetClick}
              //   handleOnUndoClick={this.handleOnUndoClick}
              handleOnSaveClick={this.handleOnSaveClick}
              maxLength={100}
              width={250}
              icon={
                this.state.inStatus === true ? (
                  <Edit
                    color="primary"
                    style={{
                      disabled:
                        this.props.MaterialStatus === "C" ? "true" : "false",
                    }}
                  />
                ) : (
                  <Done
                    color="primary"
                    style={{
                      disabled:
                        this.props.MaterialStatus === "C" ? "true" : "false",
                    }}
                  />
                )
              }
            />
          </Grid>
        </Grid>
      </form>
    );
  }
}

// export default withRouter(CheckSaveText);

function WithNavigate(props) {
  let navigate = useNavigate();
  return <CheckSaveText {...props} navigate={navigate} />;
}
export default connect(
  mapStatetoProps,
  mapSetUserPrivileges_ToProps
)(HoCtToastContainer(HOCVerifyIsUser(withRouter(WithNavigate))));
