const initState = {
  navInfo: {
    activeMenu: "",
    subMenuMaster: [],
    subMenuEntry: [],
    subMenuSetting: [],
    subMenuReport: [],
  },
  userPrivilege: {},
  modules: {
    importexport: "Master:Import Export",
    proposal: "Entry:Proposal",
    pricecalculation: "Entry:Price Calculator",
    railprice: "Master:Price Calculator:Rail Price",
    gpbracketprice: "Master:Price Calculator:Gp Bracket Price",
    counterweightprice: "Master:Price Calculator:Counter Weight Price List",
    genrelitemprice: "Master:Price Calculator:General Item Price List",
    maingovernorwirerope:
      "Master:Price Calculator:Main & Governor Wire Rope List & Technical Detail",
    rawmaterial: "Master:Price Calculator:Raw Material & Process Price",
    counterweightkg: "Master:Price Calculator:Counter Weight KG",
    pulleyprice: "Master:Price Calculator:Pulley Price List",
    itemrelatedsefety:
      "Master:Price Calculator:Items Related To Safety Set Price",
    passengercabin: "Master:Price Calculator:Passenger Lift Cabin Price",
    goodscabin: "Master:Price Calculator:Goods Lift Cabin Entry",
    autodoormainprice: "Master:Price Calculator:Auto Door Main Price",
    autodoorpanel: "Master:Price Calculator:Auto Door Panel Price Formula",
    autodoorframe: "Master:Price Calculator:Auto Door Frame Price Formula",
    railselection: "Master:Price Calculator:Master Of Rail Selacion",
    carinsidecardbgcwdbg:
      "Master:Price Calculator:Car Inside, Car DBG & CW DBG Selection",
    safetymain: "Master:Price Calculator:Safety Main Price",
    manualdoor: "Master:Price Calculator:Manual Door Price",
    controllermainvf:
      "Master:Price Calculator:Controller & Main VF Drive Pricing",
    combintionbracket: "Master:Price Calculator:Combination Bracket Price",
    carframe: "Master:Price Calculator:Car Frame & CW Frame Selection",
    bracketmaster: "Master:Price Calculator:Price OF Bracket Master",
    manualdoorframe: "Master:Price Calculator:Manual Door Frame Price",
    machineprice: "Master:Price Calculator:Machine Price",
    doormaterialgrade: "Master:Price Calculator:Door Material Grade",
    doorbrand: "Master:Price Calculator:Door Brand",
    drivename: "Master:Price Calculator:Drive Name",
    ardpricelist: "Master:Price Calculator:ARD Price List",
    comchainpriceperkg: "Master:Price Calculator:Com Chain Price Per KG",
    pulleydiameterselection:
      "Master:Price Calculator:Pulley Diameter Selection",
    copprice: "Master:Price Calculator:Cop Price Master",
    lopprice: "Master:Price Calculator:Lop Price Master",
    employee: "Master:Proposal:User Management",
  },
  TblYear_YYYY: "",
  AcYear_YYYY_YY: "",
  lists: {
    YearList: [],
    CompanyList: [],
    StateList: [],
  },
  PriceCalculation: {},
};

const RootReducers = (state = initState, action) => {
  if (action.type === "SET_ActiveMenu") {
    return {
      ...state,
      navInfo: { ...state.navInfo, activeMenu: action.ActiveMenu },
    };
  } else if (action.type === "SET_UserPrivileges") {
    let newstate = {
      ...state,
      userPrivilege: action.UserPrivileges,
    };
    // console.log("newstate", newstate);
    return newstate;
  } else if (action.type === "SET_PriceCalculation") {
    let newstate = {
      ...state,
      PriceCalculation: action.PriceCalculation,
    };
    // console.log("newstate of Basic Info", newstate);
    return newstate;
  }
  return state;
};
export default RootReducers;
