export const setUserPrivileges = (UserPrivileges) => {
  return {
    type: "SET_UserPrivileges",
    UserPrivileges,
  };
};

export const setPriceCalculation = (PriceCalculation) => {
  return {
    type: "SET_PriceCalculation",
    PriceCalculation,
  };
};

// export const setButtonType = (ButtonType) => {
//   return {
//     type: "SET_ButtonType",
//     ButtonType,
//   };
// };
