import { Grid, Typography } from "@mui/material";
import React, { Component } from "react";
import CtTxt from "../Components/CustomTool/CtTxt";
import CtTxtPWD from "../Components/CustomTool/CtTxtPWD";
import CtBtn from "../Components/CustomTool/CtBtn";
import HoCtToastContainer from "../HOC/HoCtToastContainer";
import { Link, Navigate } from "react-router-dom";
import { withRouter } from "../HOC/withRouter";
import {
  fetchLogin,
  fetchUser,
  fetchUserManagement,
  UserManagement,
  apiURL,
} from "../Components/API";
import axios from "axios";
import {
  get_YMD_from_DMY,
  getFinancialYear,
} from "../SystemUtility/SystemUtility";
import refreshIcon from "../Images/refresh.png";
import lineBackground from "../Images/images.png";

class Login extends Component {
  state = {
    formData: {
      txtUserId: "",
      txtPassword: "",
      txtCaptcha: "",
    },
    captchaText: this.generateCaptcha(),
  };

  componentDidMount() {
    document.getElementById("txtUserId").focus();
    document.title = "Top India Elevator : Login";
    this.clearLocalStorage();
  }

  generateCaptcha() {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    return Array.from(
      { length: 5 },
      () => chars[Math.floor(Math.random() * chars.length)]
    ).join("");
  }

  handleOnChange = (e) => {
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: e.target.value },
    });
  };

  validateLogin = () => {
    let result = false;
    if (this.state.formData.txtUserId.length <= 0) {
      this.props.toastErrorMsg("Enter User ID", "txtUserId");
    } else if (this.state.formData.txtPassword.length <= 0) {
      this.props.toastErrorMsg("Enter Password", "txtPassword");
    } else if (this.state.formData.txtCaptcha.length <= 0) {
      this.props.toastErrorMsg("Enter CAPTCHA", "txtCaptcha");
    } else if (this.state.formData.txtCaptcha !== this.state.captchaText) {
      this.props.toastErrorMsg("Enter Valid CAPTCHA");
      this.clearInfo();
    } else {
      result = true;
    }
    return result;
  };

  clearInfo = () => {
    this.setState({
      captchaText: this.generateCaptcha(),
      ...this.state.formData,
      formData: {
        txtUserId: "",
        txtPassword: "",
        txtCaptcha: "",
      },
    });
  };

  refreshCaptcha = () => {
    this.setState({
      captchaText: this.generateCaptcha(),
      formData: { ...this.state.formData, txtCaptcha: "" },
    });
  };

  verifyLogin = () => {
    if (this.validateLogin()) {
      this.props.notifyProcessing();
      const reqData = {
        Op: "LoginUser",
        name: this.state.formData.txtUserId,
        password: this.state.formData.txtPassword,
      };
      // const res = axios
      //   // .post("http://localhost:7000/Login", data)
      fetchUserManagement(reqData)
        .then((response) => {
          this.props.closeUpdateProcessing(
            response.data.msgType,
            response.data.message
          );
          if (response.data.msgType === "success") {
            localStorage.setItem("authtoken", response.data.token);
            localStorage.setItem("user_id", response.data.user_id);
            localStorage.setItem("UserID", this.state.formData.txtUserId);
            localStorage.setItem("vac_userid", this.state.formData.txtUserId);
            this.props.navigateTo("Dashboard");

            localStorage.setItem(
              "isChecked",
              localStorage.getItem("isChecked") === null
                ? true
                : localStorage.getItem("isChecked")
            );

            let Year = getFinancialYear(get_YMD_from_DMY(new Date()));

            // console.log("Year", Year);

            localStorage.setItem("ACY", Year);

            if (localStorage.getItem("isChecked") === "true") {
              localStorage.setItem("OutPutListCollapse", true);
            } else {
              localStorage.setItem("OutPutListCollapse", false);
            }
          }
        })
        .catch((error) => {
          console.log("Error fetching modules:", error);
        });
    }
  };

  clearLocalStorage = () => {
    localStorage.removeItem("authtoken");
    localStorage.removeItem("user_id");
  };

  // verifyLogin = () => {
  //   // if (this.validateLogin()) {
  //   //   if (
  //   //     this.state.formData.txtUserId === "MRProduct" &&
  //   //     this.state.formData.txtPassword === "123"
  //   //   ) {

  //   localStorage.setItem(
  //     "isChecked",
  //     localStorage.getItem("isChecked") === null
  //       ? true
  //       : localStorage.getItem("isChecked")
  //   );

  //   localStorage.setItem("ACY", "2024");

  //   if (localStorage.getItem("isChecked") === "true") {
  //     localStorage.setItem("OutPutListCollapse", true);
  //     // localStorage.setItem("TraningCollapse", true);
  //     // localStorage.setItem("PayRollMasterCollapse", true);
  //     // localStorage.setItem("PurchaseCollapse", true);
  //     // localStorage.setItem("GatePassCollapse", true);
  //   } else {
  //     localStorage.setItem("OutPutListCollapse", false);
  //     // localStorage.setItem("TraningCollapse", false);
  //     // localStorage.setItem("PayRollMasterCollapse", false);
  //     // localStorage.setItem("PurchaseCollapse", false);
  //     // localStorage.setItem("GatePassCollapse", false);
  //   }

  //   this.props.navigateTo("Dashboard");
  //   //   } else {
  //   //     this.props.toastErrorMsg("Invalid Constrain", "txtUserId");
  //   //   }
  //   // }
  // };

  // verifyLogin = () => {
  //   if (this.validateLogin()) {
  //     this.props.notifyProcessing();

  //     const reqData = {
  //       Op: "VerifyUser",
  //       vac_userid: this.state.formData.txtUserId,
  //       vac_password: this.state.formData.txtPassword,
  //     };
  //     fetchUser(reqData)
  //       .then((res) => {
  //         this.setState({ res: res.data });
  //         this.props.closeUpdateProcessing(res.data.msgType, res.data.message);
  //         console.log(this.state.res);
  //         if (res.data.msgType === "success") {
  //           this.setLoginDetailToLocalStorage(
  //             this.state.formData.txtUserId,
  //             res.data.vac_username
  //           );
  //           // if (res.data.vac_privileges && res.data.vac_privileges.length > 0) {
  //           //   console.log("this.props", this.props);
  //           //   setMenuPrivileges(
  //           //     res.data.vac_privileges,
  //           //     this.props.setSubMenu_Master_Entry,
  //           //     this.props.setUserPrivileges
  //           //   );
  //           // }
  //           this.props.navigateTo("Home");
  //         }
  //         // set local storage of UserType, USerID.
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         this.props.updateProcessing(
  //           "error",
  //           "Unknown error occured in VerifyUser.\n\n" + err
  //         );
  //       });
  //   }
  // };

  // setLoginDetailToLocalStorage = (logInID, UserName) => {
  //   // console.log('logInID, UserName', logInID, UserName)
  //   this.clearLocalStorage();
  //   localStorage.setItem("logInID", logInID);
  //   // localStorage.setItem("logInLinkText", "Log Out");
  //   localStorage.setItem("UserName", UserName);
  // };

  // clearLocalStorage = () => {
  //   localStorage.removeItem("logInID");
  //   localStorage.removeItem("UserName");
  //   // localStorage.setItem("logInLinkText", "Log In");
  //   // localStorage.removeItem("FullACY");
  //   // localStorage.removeItem("ACY");
  //   // localStorage.removeItem("CompanyName");
  //   // localStorage.removeItem("acid");
  //   // localStorage.removeItem("CompStateCode");
  //   // localStorage.removeItem("CI");
  //   // localStorage.removeItem("defaultsettings");
  // };

  render() {
    return (
      <div>
        {/* <p>{apiURL}</p> */}
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          marginTop={10}
          direction="column"
        >
          <Grid item>
            <Typography fontSize="20px" color="#365add" fontWeight="bolder">
              <h1 style={{ margin: "0px", textAlign: "center" }}>
                Welcome Back{" "}
              </h1>
              <h2
                style={{ textAlign: "center", color: "#258ce6", margin: "0px" }}
              >
                Login Here!
              </h2>
            </Typography>
          </Grid>
          <Grid item padding={4} paddingTop={1}>
            <form>
              <CtTxt
                id="txtUserId"
                label="User ID"
                variant="outlined"
                handleOnChange={this.handleOnChange}
                value={this.state.formData.txtUserId}
                fullWidth={true}
                onKeyDown={this.props.onKeyDown}
                maxLength="50"
              />
              <CtTxtPWD
                id="txtPassword"
                label="Password"
                variant="outlined"
                handleOnChange={this.handleOnChange}
                value={this.state.formData.txtPassword}
                type="password"
                fullWidth={true}
                formcontrolStyle={{ marginTop: "20px" }}
                onKeyDown={this.props.onKeyDown}
                defaultAction={this.verifyLogin}
                maxLength="50"
              />

              <Grid container alignItems="center" spacing={1} marginTop={1}>
                <Grid item>
                  <CtTxt
                    id="txtCaptcha"
                    label="Enter CAPTCHA"
                    variant="outlined"
                    handleOnChange={this.handleOnChange}
                    value={this.state.formData.txtCaptcha}
                    fullWidth={true}
                    maxLength="5"
                  />
                </Grid>
                <Grid item>
                  <Typography
                    style={{
                      fontSize: "24px",
                      fontWeight: "bold",
                      letterSpacing: "2px",
                      // backgroundColor: "#f0f0f0",
                      backgroundImage: `url(${lineBackground})`,
                      padding: "10px",
                      display: "inline-block",
                      borderRadius: "5px",
                      textAlign: "center",
                      // color: "#1b75bc",
                      color: "#9e9e9e",
                    }}
                  >
                    {this.state.captchaText}
                  </Typography>
                </Grid>
                <Grid item>
                  <button
                    type="button"
                    onClick={this.refreshCaptcha}
                    style={{
                      cursor: "pointer",
                      background: "none",
                      border: "none",
                    }}
                  >
                    <img
                      src={refreshIcon}
                      alt="logo"
                      width={"25px"}
                      height={"25px"}
                    />
                  </button>
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={3}
                marginTop={0}
              >
                <Grid item>
                  <CtBtn
                    className={"primary-button"}
                    variant="contained"
                    width="auto"
                    onClick={this.verifyLogin}
                    label="Login"
                    bgColor="#05699d"
                    firstletterBig={false}
                  />
                </Grid>
              </Grid>
              <Grid item>
                <Typography
                  fontSize="15px"
                  textAlign="center"
                  fontWeight="300"
                  color="#575759"
                  marginTop="10px"
                >
                  Copyright @ 2024
                </Typography>
                <Typography
                  fontSize="15px"
                  textAlign="center"
                  fontWeight="300"
                  color="black"
                  marginBottom="15px"
                >
                  <span
                    style={{
                      marginRight: "4px",
                      textAlign: "center",
                      color: "#258ce8",
                    }}
                  >
                    Developed By
                  </span>
                  <a
                    href="https://ravitechworld.com/"
                    underline="hover"
                    target="_blank"
                  >
                    RavitechWorld
                  </a>
                </Typography>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </div>
    );
  }
}

// export default Login;
export default HoCtToastContainer(withRouter(Login));
