import React, { useEffect, useState } from "react";
import TextEditor from "./TextEditor";
import { Box, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import CtBtn from "./CustomTool/CtBtn";
import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";

function MoreInfoTextEditorWithMaster({
  TxtChange,
  updateDetails,
  Txtid,
  MasterNotes,
}) {
  const [textInfo1, setTextInfo1] = useState("");
  const [textTobeSet1, setTextTobeSet1] = useState("");
  const [ARD, setARD] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);

  const columns = [
    {
      field: "vac_note",
      headerName: "NOTE",
      flex: 1,
      minWidth: 700,
      disableColumnMenu: true,
      sortable: false,
      resizable: false,
    },
  ];

  const rows = MasterNotes.map((row) => ({
    ...row,
    id: row.bint_srno,
  }));

  const paginationModel = { page: 0, pageSize: 10 };

  useEffect(() => {
    setDefaultCondition();
  }, [TxtChange]);

  const setDefaultCondition = () => {
    if (TxtChange !== textTobeSet1) setTextTobeSet1(TxtChange);
  };

  const onEditorStateChange = (ctrlInfo) => {
    let hasChanged = false;

    if (ctrlInfo.id === Txtid && ctrlInfo.value !== textInfo1) {
      setTextInfo1(ctrlInfo.value);
      hasChanged = true;
    }

    if (hasChanged) {
      updateDetails(ctrlInfo.id, ctrlInfo.value);
    }
  };

  const resetEditorStateToUndefined = (id) => {
    if (id === Txtid) {
      setTextTobeSet1(undefined);
    }
  };

  const openMasterTable = () => {
    setARD(null);
    let newARD = (
      <AlertResponsiveDialog
        labelDisagree="Add Notes"
        handleOnClickNo={() => {
          setARD(null);
        }}
        fullscreenOnly={true}
        alertIcon=""
        alertHeading={"Notes Master"}
        alertTitle={
          <>
            <Paper sx={{ width: "80vw" }}>
              <Box
                sx={{
                  width: "100%",
                  overflowX: "auto",
                }}
              >
                <DataGrid
                  rows={rows}
                  columns={columns}
                  initialState={{ pagination: { paginationModel } }}
                  pageSizeOptions={[5, 10, 50]}
                  checkboxSelection
                  onRowSelectionModelChange={(ids) => {
                    const selected = ids.map((id) =>
                      rows.find((row) => row.id === id)
                    );
                    setSelectedRows(selected);
                    const selectedNotes = `
                    <ol>
                      ${selected
                        .map((row) => `<li>${row.vac_note}</li>`)
                        .join("\n")}
                    </ol>`;
                    setTextTobeSet1(`${textInfo1}\n${selectedNotes}`);
                  }}
                />
              </Box>
            </Paper>
          </>
        }
        alertMessage=""
        defaultOpenDialog={true}
        onYesClickCloseIfExeSuccessfully={true}
      />
    );

    setARD(newARD);
  };

  console.log("MasterNotes", MasterNotes);

  return (
    <>
      <Grid container>
        <Grid item marginBottom={2}>
          <CtBtn
            label={"Add Notes"}
            firstletterBig={false}
            onClick={openMasterTable}
          />
        </Grid>
        <Grid item>
          <TextEditor
            id={Txtid}
            onEditorStateChange={onEditorStateChange}
            EditorState={textTobeSet1}
            resetEditorStateToUndefined={resetEditorStateToUndefined}
          />
        </Grid>
      </Grid>
      <Grid item>
        <Box display={{ xs: "none" }} style={{ textAlign: "right" }}>
          {ARD}
        </Box>
      </Grid>
    </>
  );
}

export default MoreInfoTextEditorWithMaster;
