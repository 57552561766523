import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import HOCVerifyIsUser from "../HOC/HOCVerifyIsUser";
import HoCtToastContainer from "../HOC/HoCtToastContainer";
import "../App.css";
import CtTxt from "../Components/CustomTool/CtTxt";
import CtBtn from "../Components/CustomTool/CtBtn";
import MaterialTable from "../Components/CustomTool/MaterialTable";
import "./Table.css";

import { fetchDepartment, fetchProposal } from "../Components/API";
import AlertResponsiveDialog from "../Components/CustomTool/AlertResponsiveDialog";
import Progress from "../Components/CustomTool/Progress";
import {
  applyTrimOnObjectValues,
  csvFileName,
  get_30DaysBack_FromCurrentDate,
  get_YMD_from_SYS,
  getRowOfRT,
  isValidItem,
  validatedate_min_max,
  validateEmail,
} from "../SystemUtility/SystemUtility";
import ProposalEntry from "./ProposalEntry";
import { withRouter } from "../HOC/withRouter";
import { connect } from "react-redux";
import {
  mapSetUserPrivileges_ToProps,
  mapStatetoProps,
} from "../mapRedux/mapReduxProps";
import CtDtp from "./CustomTool/CtDtp";
import CtART from "./CustomTool/ResponsiveTable";
import CtCmbEditable from "./CustomTool/CtCmbEditable";
import RestoreIcon from "@mui/icons-material/Restore";
import { Tooltip, Typography } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { FileCopy } from "@mui/icons-material";

class Proposal extends Component {
  state = {
    columnsdata: [
      {
        accessorKey: "bint_summarysrno",
        header: "Sr No",
        enableColumnActions: false,
        enableResizing: false,
        enableSorting: false,
        enableColumnDragging: false,
        size: 60,
      },
      {
        accessorKey: "vac_brname",
        header: "Branch Name",
      },
      {
        accessorKey: "vac_summary_refno",
        header: "Ref No.",
        size: 150,
      },
      {
        accessorKey: "Date", //DMY Format
        header: "Date",
        size: 110,
      },
      {
        accessorKey: "dat_date_YMD",
        header: "Date",
      },
      {
        accessorKey: "vac_sitename",
        header: "Site Name",
        // size: 200,
      },
      {
        accessorKey: "vac_name",
        header: "Customer Name",
        size: 200,
      },
      {
        accessorKey: "vac_mobileno",
        header: "Cust. Mobile No.",
        enableColumnActions: false,
        enableResizing: false,
        enableSorting: false,
        enableColumnDragging: false,
        size: 140,
      },
      {
        accessorKey: "bint_totalliftcount",
        header: "No. Of Lift",
        enableColumnActions: false,
        enableResizing: false,
        enableColumnDragging: false,
        size: 110,
        // size: 200,
      },
      {
        accessorKey: "TblYear",
        header: "TblYear",
        // size: 200,
      },

      {
        accessorKey: "vac_brcode",
        header: "vac_brcode",
        // size: 200,
      },
    ],
    Branches: [],
    rows: [],
    formData: {
      dtpfromdate:
        localStorage.getItem("proposalFromDate") &&
        Math.floor(
          (new Date() - new Date(localStorage.getItem("setFromDateTime"))) /
            (1000 * 60)
        ) <= 30
          ? localStorage.getItem("proposalFromDate")
          : get_30DaysBack_FromCurrentDate(new Date()),
      dtptodate:
        localStorage.getItem("proposalToDate") &&
        Math.floor(
          (new Date() - new Date(localStorage.getItem("setTodateTime"))) /
            (1000 * 60)
        ) <= 30
          ? localStorage.getItem("proposalToDate")
          : get_YMD_from_SYS(new Date()),
      cmbBranch:
        localStorage.getItem("proposalBranch") &&
        Math.floor(
          (new Date() - new Date(localStorage.getItem("setBranchTime"))) /
            (1000 * 60)
        ) <= 30
          ? localStorage.getItem("proposalBranch")
          : "ALL",
    },
    childValues: {},
    rowsPerPage: 50,
    dataLoadStatus: true,
    ARD: null,
  };

  //#region component

  componentDidMount() {
    localStorage.setItem("Heading", "Proposal");
    // document.title = "Top India Elevator : Proposal";
    // this.ShortCut();
    // // this.onFormLoad();
    this.props.verifyUserID(
      this.getInfo,
      this.props.setUserPrivileges,
      this.props.modules.proposal
    );
  }

  // componentWillUnmount() {
  //   this.removeShortCut();
  // }

  //#endregion component

  //#region function

  // ShortCut = () => {
  //   this.removeShortCut = setKeyboardShortcuts(
  //     this.SaveDepartmentMaster,
  //     this.clearInfo,
  //     () => {
  //       this.DeleteData(this.state.formData.EditDepartment);
  //     }
  //   );
  // };

  trimFormData = (nextFunctionCall) => {
    let formData = applyTrimOnObjectValues({
      formData: this.state.formData,
    });
    this.setState({ formData }, () => {
      if (nextFunctionCall) {
        nextFunctionCall();
      }
    });
  };

  getInfo = () => {
    this.props.notifyProcessing();
    const reqData = {
      Op: "LoadProposalData",
      vac_userid: localStorage.getItem("vac_userid"),
    };
    fetchProposal(reqData)
      .then((res) => {
        this.props.closeUpdateProcessing();
        let Branches = res.data.Branch.length > 0 ? res.data.Branch : [];
        Branches.unshift({ vac_brname: "ALL" });
        this.setState(
          {
            Branches,
          },
          () => {
            this.onProposalLoad();
          }
        );
      })
      .catch((error) => {
        console.log("Unknown error occurred in onFormLoad.", error);
      });
  };

  validateProposal = () => {
    let result = false;
    if (this.state.formData.dtpfromdate.length <= 0) {
      this.props.toastErrorMsg("Select From Date", "dtpfromdate");
    } else if (this.state.formData.dtptodate.length <= 0) {
      this.props.toastErrorMsg("Select To Date ", "dtptodate");
    } else if (
      this.state.formData.dtptodate < this.state.formData.dtpfromdate
    ) {
      this.props.toastErrorMsg("To Date Is Grater Then From Date", "dtptodate");
    } else if (this.state.formData.cmbBranch.length <= 0) {
      this.props.toastErrorMsg("Select Branch", "cmbBranch");
    } else if (
      this.state.formData.cmbBranch.length > 0 &&
      !isValidItem(
        this.state.Branches,
        "vac_brname",
        this.state.formData.cmbBranch
      )
    ) {
      this.props.toastErrorMsg("Select Valid Branch ", "cmbBranch");
    } else {
      result = true;
    }
    return result;
  };

  onProposalLoad = () => {
    if (this.validateProposal()) {
      let BranchName = [];
      if (this.state.formData.cmbBranch == "ALL") {
        BranchName = this.state.Branches.filter(
          (branch) => branch.vac_brname !== "ALL"
        );
      } else {
        BranchName.push({ vac_brname: this.state.formData.cmbBranch });
      }
      this.props.notifyProcessing();
      const reqData = {
        Op: "ProposalSummaryList",
        dat_fromdate: this.state.formData.dtpfromdate,
        dat_todate: this.state.formData.dtptodate,
        vac_branchname: BranchName,
      };
      fetchProposal(reqData)
        .then((res) => {
          this.props.closeUpdateProcessing();
          let rows =
            res.data.ProposalSummaryList.length > 0
              ? res.data.ProposalSummaryList
              : [];
          this.setState({
            rows,
          });
        })
        .catch((error) => {
          console.log("Unknown error occurred in onFormLoad.", error);
        });
    }
  };

  DeleteData = (vac_refno, bint_summarysrno, dat_date, vac_brname) => {
    if (bint_summarysrno > 0) {
      this.setState({ ARD: null }, () => {
        let ARD = (
          <AlertResponsiveDialog
            labelAgree="Delete"
            labelDisagree="Cancel"
            alertTitle={
              "Do you want to Delete Proposal Ref No : " + vac_refno + " ?"
            }
            alertMessage=""
            handleOnClickYes={() => {
              return this.DeleteproposalData(
                bint_summarysrno,
                dat_date,
                vac_brname
              );
            }}
            defaultOpenDialog={true}
            onYesClickCloseIfExeSuccessfully={true}
          />
        );

        this.setState({ ARD });
      });
    } else {
      this.props.toastErrorMsg("Select a record for deletion.");
    }
  };

  DeleteproposalData = (bint_summarysrno, dat_date, vac_brname) => {
    let LoadBranchName = [];
    if (this.state.formData.cmbBranch == "ALL") {
      LoadBranchName = this.state.Branches.filter(
        (branch) => branch.vac_brname !== "ALL"
      );
    } else {
      LoadBranchName.push({ vac_brname: this.state.formData.cmbBranch });
    }

    this.props.notifyProcessing();
    const reqData = {
      Op: "DeleteProposalData",
      bint_summarysrno: bint_summarysrno,
      vac_branchname: vac_brname,
      vac_dataloadbranch: LoadBranchName,
      dat_date: dat_date,
      dat_fromdate: this.state.formData.dtpfromdate,
      dat_todate: this.state.formData.dtptodate,
    };
    fetchProposal(reqData)
      .then((res) => {
        this.props.updateProcessing(res.data.msgType, res.data.message);
        if (res.data.msgType === "success") {
          let rows = [],
            rowsCount = rows.length;
          if (
            res.data.List_Proposaldata &&
            res.data.List_Proposaldata.length > 0
          ) {
            rows = res.data.List_Proposaldata;
            rowsCount = rows.length;
          }

          this.setState({
            rows,
            rowsCount,
            ARD: null,
          });
          document.getElementById("dtpfromdate").focus();
        }
      })
      .catch((error) => {
        this.props.updateProcessing(
          "error",
          "Unknown error occurred in delete Proposal Data. " + error
        );
      });
    return false;
  };

  //#endregion function

  //#region handle

  handleOnChange = (e) => {
    this.setState(
      {
        formData: { ...this.state.formData, [e.target.name]: e.target.value },
      },
      () => {
        if (e.target.name == "dtpfromdate") {
          localStorage.setItem("proposalFromDate", e.target.value);
          localStorage.setItem("setFromDateTime", new Date());
        } else if (e.target.name == "dtptodate") {
          localStorage.setItem("proposalToDate", e.target.value);
          localStorage.setItem("setTodateTime", new Date());
        } else if (e.target.name == "cmbBranch") {
          localStorage.setItem("proposalBranch", e.target.value);
          localStorage.setItem("setBranchTime", new Date());
        }
      }
    );
  };

  handleARDonActionClick_Delete = (rtRow) => {
    const row = getRowOfRT(rtRow, [
      "vac_summary_refno",
      "bint_summarysrno",
      "dat_date_YMD",
      "vac_brname",
    ]);
    const vac_refno = row.vac_summary_refno,
      bint_summarysrno = row.bint_summarysrno,
      dat_date = row.dat_date_YMD,
      vac_brname = row.vac_brname;
    this.DeleteData(vac_refno, bint_summarysrno, dat_date, vac_brname);
  };

  handleARDonActionClick_Edit = (rtRow) => {
    const row = getRowOfRT(rtRow, [
      "bint_summarysrno",
      "vac_brname",
      "dat_date_YMD",
      "TblYear",
      "vac_brcode",
    ]);
    const bint_summarysrno = row.bint_summarysrno,
      cmbBranch = row.vac_brname,
      dtpDate = row.dat_date_YMD,
      TblYear = row.TblYear,
      vac_brcode = row.vac_brcode;
    this.props.navigateTo("ProposalAllEntry", {
      bint_summarysrno,
      cmbBranch,
      dtpDate,
      TblYear,
      vac_brcode,
    });
  };

  revised = (bint_summarysrno, vac_summary_refno, vac_branchname) => {
    let LoadBranchName = [];
    if (this.state.formData.cmbBranch == "ALL") {
      LoadBranchName = this.state.Branches.filter(
        (branch) => branch.vac_brname !== "ALL"
      );
    } else {
      LoadBranchName.push({ vac_brname: this.state.formData.cmbBranch });
    }

    // const reqData = {
    //   Op: "DeleteProposalData",
    //   bint_summarysrno: bint_summarysrno,
    //   vac_branchname: vac_brname,
    //   vac_dataloadbranch: LoadBranchName,
    //   dat_date: dat_date,
    //   dat_fromdate: this.state.formData.dtpfromdate,
    //   dat_todate: this.state.formData.dtptodate,
    // };

    this.props.notifyProcessing();
    const reqData = {
      Op: "GenerataRivisedProposal",
      bint_summarysrno: bint_summarysrno,
      dat_date: get_YMD_from_SYS(new Date()),
      vac_summary_refno: vac_summary_refno,
      vac_branchname,
      vac_dataloadbranch: LoadBranchName,
      dat_fromdate: this.state.formData.dtpfromdate,
      dat_todate: this.state.formData.dtptodate,
    };
    fetchProposal(reqData)
      .then((res) => {
        this.props.updateProcessing(res.data.msgType, res.data.message);
        if (res.data.msgType === "success") {
          let rows = [],
            rowsCount = rows.length;
          if (
            res.data.List_Proposaldata &&
            res.data.List_Proposaldata.length > 0
          ) {
            rows = res.data.List_Proposaldata;
            rowsCount = rows.length;
          }
          this.setState({
            rows,
            rowsCount,
            ARD: null,
          });
          document.getElementById("dtpfromdate").focus();
        }
      })
      .catch((error) => {
        this.props.updateProcessing(
          "error",
          "Unknown error occurred in Revised Proposal Data. " + error
        );
      });
  };

  handleARDonActionClick_Revised = (rtRow) => {
    const row = getRowOfRT(rtRow, [
      "bint_summarysrno",
      "vac_brname",
      "dat_date_YMD",
      "TblYear",
      "vac_brcode",
      "vac_summary_refno",
      "vac_sitename",
    ]);

    const alertMsg = () => {
      return (
        <>
          <Typography variant="h6">
            Do you want to revised proposal Ref.NO: {row.vac_summary_refno}
          </Typography>
          <Typography variant="h6">Site Name : {row.vac_sitename}</Typography>
        </>
      );
    };

    this.setState({ ARD: null }, () => {
      let ARD = (
        <AlertResponsiveDialog
          labelPrimary="Revised Proposal"
          PrimaryIcon={<FileCopy />}
          labelDisagree="Cancel"
          alertHeading={"Proposal Revised"}
          alertMessage={alertMsg()}
          handleOnClickYes={() => {
            this.revised(
              row.bint_summarysrno,
              row.vac_summary_refno,
              row.vac_brname
            );
          }}
          defaultOpenDialog={true}
          onYesClickCloseIfExeSuccessfully={true}
        />
      );

      this.setState({ ARD });
    });
  };

  getChildValues = (childValues) => {
    this.setState({
      childValues,
    });
  };

  validateProposalEntry = (formData) => {
    let result = false;
    if (formData.dtpDate.length <= 0) {
      this.props.toastErrorMsg("Select Date", "dtpDate");
    } else if (
      validatedate_min_max(
        formData.dtpDate,
        "dtpDate",
        this.props.toastErrorMsg
      ) == false
    ) {
      // return result;
    } else if (formData.cmbEntryBranch.length <= 0) {
      this.props.toastErrorMsg("Select Branch ", "cmbEntryBranch");
    } else if (
      formData.cmbEntryBranch.length > 0 &&
      !isValidItem(this.state.Branches, "vac_brname", formData.cmbEntryBranch)
    ) {
      this.props.toastErrorMsg("Select Valid Branch ", "cmbEntryBranch");
    } else if (formData.txtRef.length <= 0) {
      this.props.toastErrorMsg("Enter Ref. No.", "txtRef");
    } else if (Number(formData.txtUnique) <= 0) {
      this.props.toastErrorMsg("Enter Unique Lift Count", "txtUnique");
    } else if (formData.txtBilling.length <= 0) {
      this.props.toastErrorMsg("Enter Billing Name", "txtBilling");
    } else if (formData.txtBillingAdd.length <= 0) {
      this.props.toastErrorMsg(
        "Enter Billing Address (Without Billing Name)",
        "txtBillingAdd"
      );
    } else if (formData.txtSite.length <= 0) {
      this.props.toastErrorMsg("Enter Site Name", "txtSite");
    } else if (formData.txtSiteAdd.length <= 0) {
      this.props.toastErrorMsg(
        "Enter Site Address (Without Site Name)",
        "txtSiteAdd"
      );
    } else if (formData.txtCust.length <= 0) {
      this.props.toastErrorMsg("Enter Customer Name", "txtCust");
    } else if (formData.txtCusCity.length <= 0) {
      this.props.toastErrorMsg("Enter Customer City", "txtCusCity");
    } else if (!/^\d{10}$/.test(formData.txtCustPhone)) {
      this.props.toastErrorMsg(
        "Enter a valid 10-digit Customer Phone No.",
        "txtCustPhone"
      );
    } else if (
      formData.txtEmail.length > 0 &&
      !validateEmail(formData.txtEmail)
    ) {
      this.props.toastErrorMsg("Enter a valid Customer Email ID.", "txtEmail");
    } else {
      result = true;
    }
    return result;
  };

  saveProposalEntry = () => {
    this.trimFormData(() => {
      if (this.validateProposalEntry(this.state.childValues)) {
        this.props.notifyProcessing();
        const reqData = {
          Op: "SaveNewProposalEntry",
          bint_uniqueliftcount: this.state.childValues.txtUnique,
          vac_branchname: this.state.childValues.cmbEntryBranch,
          vac_refno: this.state.childValues.txtRef,
          dat_date: this.state.childValues.dtpDate,
          vac_companyname: this.state.childValues.txtBilling,
          vac_companyadd: this.state.childValues.txtBillingAdd,
          vac_sitename: this.state.childValues.txtSite,
          vac_siteaddress: this.state.childValues.txtSiteAdd,
          vac_name: this.state.childValues.txtCust,
          vac_companycity: this.state.childValues.txtCusCity,
          vac_mobileno: this.state.childValues.txtCustPhone,
          vac_emailid: this.state.childValues.txtEmail,
          vac_userid: localStorage.getItem("vac_userid"),
          int_status: 1,
          bol_revision: false,
          bint_totalliftcount: 0,
        };
        fetchProposal(reqData)
          .then((res) => {
            this.props.updateProcessing(res.data.msgType, res.data.message);
            if (res.data.msgType === "success") {
              let ProposalDashboardList =
                  res.data.RailPriceList && res.data.RailPriceList.length > 0
                    ? res.data.RailPriceList
                    : [],
                bint_summarysrno = res.data.bint_summarysrno,
                TblYear = res.data.TblYear,
                vac_brcode = res.data.vac_brcode;
              this.setState(
                {
                  ProposalDashboardList,
                  bint_summarysrno,
                },
                () => {
                  this.props.navigateTo("ProposalAllEntry", {
                    bint_summarysrno: this.state.bint_summarysrno,
                    dtpDate: this.state.childValues.dtpDate,
                    cmbBranch: this.state.childValues.cmbEntryBranch,
                    TblYear,
                    vac_brcode,
                  });
                }
              );
            }
          })
          .catch((error) => {
            console.log("Unknown error occurred in Save Proposal .", error);
          });
      }
    });
  };

  handleOnNewEntryClick = () => {
    let ARD = null;
    this.setState({ ARD }, () => {
      ARD = (
        <AlertResponsiveDialog
          labelPrimary="Save"
          PrimaryIcon={<SaveIcon />}
          labelDisagree="Close"
          alertHeading={"Proposal Entry"}
          alertMessage={<ProposalEntry getChildValues={this.getChildValues} />}
          defaultOpenDialog={true}
          onYesClickCloseIfExeSuccessfully={true}
          handleOnClickYes={this.saveProposalEntry}
          handleOnClickNo={() => {}}
          maxWidthClass="100%"
        />
      );
      this.setState({ ARD });
    });
  };

  //#endregion handle

  render() {
    return (
      <form>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          marginTop={0}
          spacing={3}
        >
          <Grid item>
            <CtDtp
              id="dtpfromdate"
              value={this.state.formData.dtpfromdate}
              label="From Date*"
              handleOnChange={this.handleOnChange}
              maxLength={50}
              onKeyDown={this.props.onKeyDown}
              // disabled={true}
              width={150}
            />
          </Grid>
          <Grid item>
            <CtDtp
              id="dtptodate"
              value={this.state.formData.dtptodate}
              label="To Date*"
              handleOnChange={this.handleOnChange}
              maxLength={50}
              onKeyDown={this.props.onKeyDown}
              // disabled={true}
              width={150}
            />
          </Grid>
          <Grid item>
            <CtCmbEditable
              id="cmbBranch"
              label="Branch *"
              items={this.state.Branches}
              value={this.state.formData.cmbBranch}
              handleOnChange={this.handleOnChange}
              width={190}
              maxLength={100}
              colID="vac_brname"
            />
          </Grid>

          <Grid item marginTop={1}>
            <CtBtn
              label={"Load"}
              firstletterBig={false}
              variant={"outlined"}
              className={"child-button"}
              iconName={"load"}
              onClick={this.onProposalLoad}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          className="title"
          marginTop={3}
        >
          <Grid item marginTop={3} className="maintable">
            <CtART
              rowsdata={this.state.rows}
              columnsdata={this.state.columnsdata}
              rowsPerPage={this.state.rowsPerPage}
              tblmaxHeight={500}
              csvFileName={csvFileName("Department")}
              rowIconAction={[
                {
                  label: "Edit",
                  onClick: this.handleARDonActionClick_Edit,
                  icon: "Edit",
                  className: "secondary-button",
                },
                this.props.userPrivilege["Entry:Proposal"] == "M"
                  ? {
                      label: "Delete",
                      onClick: this.handleARDonActionClick_Delete,
                      icon: "delete",
                      className: "delete-button",
                    }
                  : "",
                {
                  label: "Revise",
                  onClick: this.handleARDonActionClick_Revised,
                  icon: "revise",
                  // className: "delete-button",
                },
              ]}
              tableButtonAction={[
                this.props.userPrivilege["Entry:Proposal"] != "R"
                  ? {
                      label: "Add New Proposal",
                      onClick: this.handleOnNewEntryClick,
                      addPluseIcon: true,
                    }
                  : "",
              ]}
              showColumnFilters={false}
              actionColSize={130}
              hideColIdList={["dat_date_YMD", "vac_brcode", "TblYear"]}
              density={"compact"}
            />
          </Grid>
          <Grid item>
            <Box display={{ xs: "none" }} style={{ textAlign: "right" }}>
              {this.state.ARD}
            </Box>
          </Grid>
        </Grid>
      </form>
    );
  }
}

// export default withRouter(Proposal);

function WithNavigate(props) {
  let navigate = useNavigate();
  return <Proposal {...props} navigate={navigate} />;
}
export default connect(
  mapStatetoProps,
  mapSetUserPrivileges_ToProps
)(HoCtToastContainer(HOCVerifyIsUser(withRouter(WithNavigate))));
