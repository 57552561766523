import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import HOCVerifyIsUser from "../HOC/HOCVerifyIsUser";
import CtBtn from "../Components/CustomTool/CtBtn";
import CtTxt from "../Components/CustomTool/CtTxt";
import CtTextFieldPWD from "../Components/CustomTool/CtTxtPWD";
import CtCmb from "../Components/CustomTool/CtCmb";
import CtTxtNum from "../Components/CustomTool/CtTxtNum";
import MaterialTable from "../Components/CustomTool/MaterialTable";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import AlertResponsiveDialog from "../Components/CustomTool/AlertResponsiveDialog";
// import DepartmentMaster from "./DepartmentMaster";
import { Stack } from "@mui/material";
// import PlantMaster from "./PlantMaster";
// import { fetchUser } from "../Components/API";
import HoCtToastContainer from "../HOC/HoCtToastContainer";
import {
  applyTrimOnObjectValues,
  csvFileName,
  getRowOfRT,
  setKeyboardShortcuts,
} from "../SystemUtility/SystemUtility";
import {
  mapSetUserPrivileges_ToProps,
  mapStatetoProps,
} from "../mapRedux/mapReduxProps";
import { connect } from "react-redux";
import { withRouter } from "../HOC/withRouter";
import { fetchUser } from "./API";
import MobileTable from "./CustomTool/MobileTable";
import CtART from "./CustomTool/ResponsiveTable";
import axios from "axios";
import CtTxtAmt from "../Components/CustomTool/CtTxtAmt";

class ItemsRelatedSafety extends Component {
  state = {
    Unit: [
      { vac_type: "PER SET" },
      { vac_type: "PER NOS" },
      { vac_type: "PER SQ FT" },
      { vac_type: "PER KG" },
    ],
    columnsdata: [
      {
        accessorKey: "bint_srno",
        header: "Sr No",
      },
      {
        accessorKey: "vac_Rail",
        header: "Item Name",
        size: 300,
      },
      {
        accessorKey: "vac_Price",
        header: "Price Per Unit",
        size: 220,
      },

      {
        accessorKey: "vac_Unit",
        header: "Unit",
      },
    ],

    rows: [
      {
        vac_Rail: "REGULAR SAFETY BLOCK",
        vac_Price: "20",
        vac_Unit: "PER NOS",
      },
    ],
    formData: {
      txtType: "",
      txtPrice: "",
      cmbUnit: "",
    },
    dataLoadStatus: true,
    ARD: null,
    rowsCount: 0,
    rowsPerPage: 50,
  };

  //#region Component
  componentDidMount() {
    // document.title = "User Management : Top India";
    localStorage.setItem("Heading", "Items Related To Safety Set Price List");
    this.props.verifyUserID(
      undefined,
      this.props.setUserPrivileges,
      this.props.modules.itemrelatedsefety
    );
    // this.ShortCut();
    // this.getInfo();
  }

  //   componentWillUnmount() {
  //     this.removeShortCut();
  //   }

  //#endregion Component

  //#region Handle

  //   ShortCut = () => {
  //     this.removeShortCut = setKeyboardShortcuts(
  //       this.SaveUser,
  //       this.clearInfo,
  //       () => {
  //         this.DeleteData(
  //           this.state.formData.EditSrNo,
  //           this.state.formData.EditUserID
  //         );
  //       }
  //     );
  //   };

  handleOnChange = (e) => {
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: e.target.value },
    });
  };

  handleARDonActionClick_Edit = (row) => {
    this.props.notifyProcessing();
    // alert("click on edit");
    // console.log("row", row);

    this.props.updateProcessing("success", "on edit");
    // console.log("click on edit");
  };
  handleARDonActionClick_Delete = (rtRow) => {
    this.props.notifyProcessing();

    this.props.updateProcessing("success", "on Delete");

    // console.log("rtRow", rtRow);
    // const row = getRowOfRT(rtRow, ["bint_srno", "vac_userid"]);
    // // console.log("row", row.bint_srno, row.vac_userid);
    // this.DeleteData(row.bint_srno, row.vac_userid);
  };

  //#endregion Handle

  //#region function

  clearInfo = () => {};

  trimFormData = (nextFunctionCall) => {
    let formData = applyTrimOnObjectValues({
      formData: this.state.formData,
    });
    this.setState({ formData }, () => {
      if (nextFunctionCall) {
        nextFunctionCall();
      }
    });
  };

  //#endregion function

  render() {
    return (
      <form>
        <Grid container direction="row" justifyContent="flex-end">
          <Grid item>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <CtBtn
                  className="secondary-button"
                  label="Save"
                  iconName="save"
                  onClick={() => {
                    alert("work remain");
                  }}
                />
              </Grid>
              <Grid item>
                <CtBtn
                  label="New"
                  className="new-button"
                  iconName="new"
                  onClick={this.clearInfo}
                />
              </Grid>
              <Grid item>
                <CtBtn
                  label="Delete"
                  className="delete-button"
                  iconName="delete"
                  // onClick={() => {
                  //   this.DeleteData(
                  //     this.state.formData.EditSrNo,
                  //     this.state.formData.txtUserId
                  //   );
                  // }}
                  //   disabled={
                  //     this.state.formData.txtUserId.length > 0 ? false : true
                  //   }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          marginTop={0}
        >
          <Grid item>
            <CtTxt
              id="txtType"
              label="Item Name"
              maxLength={100}
              width="220"
              value={this.state.formData.txtType}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
              nextCtrlID="txtPrice"
            />
          </Grid>
          <Grid item>
            <CtTxtAmt
              id="txtPrice"
              label="Price Per Unit"
              maxLength={100}
              width="220"
              value={this.state.formData.txtPrice}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
              nextCtrlID="cmbERPRole"
            />
          </Grid>
          <Grid item>
            <CtCmb
              id="cmbUnit"
              label="Unit"
              items={this.state.Unit}
              value={this.state.formData.cmbUnit}
              handleOnChange={this.handleOnChange}
              width={220}
              maxLength={100}
              colID="vac_type"
            />
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          marginTop={1}
        >
          {/* responsive table */}
          <Grid item className="maintable">
            <CtART
              rowsdata={this.state.rows}
              columnsdata={this.state.columnsdata}
              rowsPerPage={this.state.rowsPerPage}
              tblmaxHeight={500}
              csvFileName={csvFileName("Rail Price Master")}
              rowIconAction={[
                {
                  label: "Edit",
                  onClick: this.handleARDonActionClick_Edit,
                  icon: "Edit",
                  className: "secondary-button",
                },
                {
                  label: "Delete",
                  onClick: this.handleARDonActionClick_Delete,
                  icon: "delete",
                  className: "delete-button",
                },
              ]}
              showColumnFilters={false}
              actionColSize={90}
              density={"compact"}
              hideColIdList={["bint_srno"]}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Box display={{ xs: "none" }} style={{ textAlign: "right" }}>
            {this.state.ARD}
          </Box>
        </Grid>
      </form>
    );
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <ItemsRelatedSafety {...props} navigate={navigate} />;
}
export default connect(
  mapStatetoProps,
  mapSetUserPrivileges_ToProps
)(HoCtToastContainer(HOCVerifyIsUser(withRouter(WithNavigate))));
