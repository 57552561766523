import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import HOCVerifyIsUser from "../HOC/HOCVerifyIsUser";
import CtBtn from "../Components/CustomTool/CtBtn";
import CtTxt from "../Components/CustomTool/CtTxt";
import CtCmb from "../Components/CustomTool/CtCmb";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import AlertResponsiveDialog from "../Components/CustomTool/AlertResponsiveDialog";
import HoCtToastContainer from "../HOC/HoCtToastContainer";
import {
  applyTrimOnObjectValues,
  csvFileName,
  getRowOfRT,
  setKeyboardShortcuts,
} from "../SystemUtility/SystemUtility";
import {
  mapSetUserPrivileges_ToProps,
  mapStatetoProps,
} from "../mapRedux/mapReduxProps";
import { connect } from "react-redux";
import { withRouter } from "../HOC/withRouter";
import MobileTable from "./CustomTool/MobileTable";
import CtART from "./CustomTool/ResponsiveTable";
import CtTxtAmt from "../Components/CustomTool/CtTxtAmt";
import { fetchgeneralitemprice } from "./API";

class GeneralItemPrice extends Component {
  state = {
    Unit: [
      { vac_unit: "PER SET" },
      { vac_unit: "PER NOS" },
      { vac_unit: "PER SQ FT" },
      { vac_unit: "PER KG" },
    ],
    columnsdata: [
      {
        accessorKey: "bint_srno",
        header: "Sr No",
      },
      {
        accessorKey: "vac_generalitemtype",
        header: "General Item Name",
        size: 400,
      },
      {
        accessorKey: "vac_logicdesc",
        header: "Logic Description",
        size: 255,
      },
      {
        accessorKey: "dec_priceperunit",
        header: "Price Unit",
        size: 250,
      },
      {
        accessorKey: "vac_unit",
        header: "Unit",
        size: 250,
      },
    ],

    rows: [],
    formData: {
      txtlogic: "",
      txtPrice: "",
      txtGeneral: "",
      cmbUnit: "",
      bint_srno: 0,
    },
    dataLoadStatus: true,
    ARD: null,
    rowsCount: 0,
    rowsPerPage: 50,
  };

  //#region Component
  componentDidMount() {
    // document.title = "User Management : Top India";
    localStorage.setItem("Heading", "General Item Price");
    this.props.verifyUserID(
      this.getInfo,
      this.props.setUserPrivileges,
      this.props.modules.genrelitemprice
    );
    this.ShortCut();
  }

  componentWillUnmount() {
    this.removeShortCut();
  }

  //#endregion Component

  //#region Handle

  ShortCut = () => {
    this.removeShortCut = setKeyboardShortcuts(
      this.SaveGeneralItemPrice,
      this.clearInfo,
      () => {
        this.DeleteData(
          this.state.formData.bint_srno,
          this.state.formData.txtGeneral
        );
      }
    );
  };

  handleOnChange = (e) => {
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: e.target.value },
    });
  };

  handleARDonActionClick_Edit = (rtRow) => {
    const row = getRowOfRT(rtRow, [
      "bint_srno",
      "vac_generalitemtype",
      "vac_logicdesc",
      "dec_priceperunit",
      "vac_unit",
    ]);

    // console.log("row", row);

    const bint_srno = row.bint_srno,
      txtGeneral = row.vac_generalitemtype,
      txtlogic = row.vac_logicdesc,
      txtPrice = row.dec_priceperunit,
      cmbUnit = row.vac_unit;
    this.setState({
      formData: {
        ...this.state.formData,
        bint_srno,
        txtGeneral,
        txtlogic,
        txtPrice,
        cmbUnit,
      },
    });
    // window.scrollTo(0, 0);
    document.getElementById("txtGeneral").focus();
  };

  handleARDonActionClick_Delete = (rtRow) => {
    const row = getRowOfRT(rtRow, ["bint_srno", "vac_generalitemtype"]);
    this.DeleteData(row.bint_srno, row.vac_generalitemtype);
  };

  //#endregion Handle

  //#region function
  DeleteData = (bint_srno, vac_generalitemtype) => {
    if (bint_srno > 0) {
      this.setState({ ARD: null }, () => {
        let ARD = (
          <AlertResponsiveDialog
            labelAgree="Delete"
            labelDisagree="Cancel"
            alertTitle={
              "Do you want to Delete General Item Price : " +
              vac_generalitemtype +
              " ?"
            }
            alertMessage=""
            handleOnClickYes={() => {
              return this.DeleteGeneralItemPrice(bint_srno);
            }}
            defaultOpenDialog={true}
            onYesClickCloseIfExeSuccessfully={true}
          />
        );

        this.setState({ ARD });
      });
    } else {
      this.props.toastErrorMsg("Select a record for deletion.");
    }
  };

  DeleteGeneralItemPrice = (bint_srno) => {
    this.props.notifyProcessing();
    const reqData = {
      Op: "DeleteGeneralItemPrice",
      bint_srno: bint_srno,
    };
    fetchgeneralitemprice(reqData)
      .then((res) => {
        this.props.updateProcessing(res.data.msgType, res.data.message);
        if (res.data.msgType === "success") {
          let rows = [],
            rowsCount = rows.length;
          if (
            res.data.ListGeneralItemPrice &&
            res.data.ListGeneralItemPrice.length > 0
          ) {
            rows = res.data.ListGeneralItemPrice;
            rowsCount = rows.length;
          }
          this.setState(
            {
              rows,
              rowsCount,
              ARD: null,
            },
            () => {
              this.clearInfo();
            }
          );
          document.getElementById("txtGeneral").focus();
        }
      })
      .catch((error) => {
        this.props.updateProcessing(
          "error",
          "Unknown error occurred in delete General Item Price Master. " + error
        );
      });
    return false;
  };

  getInfo = () => {
    this.props.notifyProcessing();
    const reqData = {
      Op: "ListGeneralItemPrice",
    };
    fetchgeneralitemprice(reqData)
      .then((res) => {
        this.props.closeUpdateProcessing();
        let rows = res.data.ListGeneralItemPrice,
          rowsCount = rows.length;
        this.setState({
          rowsCount,
          rows,
        });
      })
      .catch((error) => {
        console.log("Unknown error occurred in onFormLoad.", error);
      });
    document.getElementById("txtGeneral").focus();
  };

  clearInfo = () => {
    this.setState({
      formData: {
        ...this.state.formData,
        txtlogic: "",
        txtPrice: "",
        txtGeneral: "",
        cmbUnit: "",
        bint_srno: "",
      },
    });
    document.getElementById("txtGeneral").focus();
  };

  trimFormData = (nextFunctionCall) => {
    let formData = applyTrimOnObjectValues({
      formData: this.state.formData,
    });
    this.setState({ formData }, () => {
      if (nextFunctionCall) {
        nextFunctionCall();
      }
    });
  };

  validationGeneral = () => {
    let result = false;
    if (this.state.formData.txtGeneral.length <= 0) {
      this.props.toastErrorMsg("Enter General Item Name", "txtGeneral");
    } else if (this.state.formData.txtlogic.length <= 0) {
      this.props.toastErrorMsg("Enter Logic Description", "txtlogic");
    } else if (this.state.formData.txtPrice.length <= 0) {
      this.props.toastErrorMsg("Enter Price Per Unit", "txtPrice");
    } else if (this.state.formData.cmbUnit.length <= 0) {
      this.props.toastErrorMsg("Select Unit", "cmbUnit");
    } else {
      result = true;
    }
    return result;
  };

  SaveGeneralItemPrice = () => {
    this.trimFormData(() => {
      if (this.validationGeneral()) {
        this.props.notifyProcessing();
        const reqData = {
          Op: "SaveGeneralItemPrice",
          bint_srno: this.state.formData.bint_srno,
          vac_generalitemtype: this.state.formData.txtGeneral,
          vac_logicdesc: this.state.formData.txtlogic,
          dec_priceperunit: this.state.formData.txtPrice,
          vac_unit: this.state.formData.cmbUnit,
        };
        fetchgeneralitemprice(reqData)
          .then((res) => {
            this.props.updateProcessing(res.data.msgType, res.data.message);
            if (res.data.msgType === "success") {
              let rows = [],
                rowsCount = rows.length;
              if (
                res.data.ListGeneralItemPrice &&
                res.data.ListGeneralItemPrice.length > 0
              ) {
                rows = res.data.ListGeneralItemPrice;
                rowsCount = rows.length;
              }
              this.setState(
                {
                  rows,
                  rowsCount,
                },
                () => {
                  this.clearInfo();
                }
              );
            }
          })
          .catch((error) => {
            console.log(
              "Unknown error occurred in Save General Item Price Master .",
              error
            );
          });
      }
    });
  };

  //#endregion function

  render() {
    return (
      <form>
        <Grid container direction="row" justifyContent="flex-end">
          <Grid item>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <CtBtn
                  className="secondary-button"
                  label="Save"
                  iconName="save"
                  onClick={this.SaveGeneralItemPrice}
                />
              </Grid>
              <Grid item>
                <CtBtn
                  label="New"
                  className="new-button"
                  iconName="new"
                  onClick={this.clearInfo}
                />
              </Grid>
              <Grid item>
                <CtBtn
                  label="Delete"
                  className="delete-button"
                  iconName="delete"
                  onClick={() => {
                    this.DeleteData(
                      this.state.formData.bint_srno,
                      this.state.formData.txtGeneral
                    );
                  }}
                  disabled={
                    Number(this.state.formData.bint_srno) > 0 ? false : true
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          marginTop={0}
        >
          <Grid item>
            <CtTxt
              id="txtGeneral"
              label="General Item Name*"
              maxLength={100}
              width="180"
              value={this.state.formData.txtGeneral}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
              nextCtrlID="txtlogic"
            />
          </Grid>
          <Grid item>
            <CtTxt
              id="txtlogic"
              label="Logic Description*"
              maxLength={100}
              width="180"
              value={this.state.formData.txtlogic}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
              nextCtrlID="txtPrice"
              disabled={
                Number(this.state.formData.bint_srno) > 0 ? true : false
              }
            />
          </Grid>
          <Grid item>
            <CtTxtAmt
              id="txtPrice"
              label="Price Per Unit*"
              maxLength={10}
              width="180"
              value={this.state.formData.txtPrice}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
              nextCtrlID="cmbUnit"
              RupeeSymbol={true}
            />
          </Grid>
          <Grid item>
            <CtCmb
              id="cmbUnit"
              label="Unit*"
              items={this.state.Unit}
              value={this.state.formData.cmbUnit}
              handleOnChange={this.handleOnChange}
              width={170}
              maxLength={100}
              colID="vac_unit"
            />
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          marginTop={1}
        >
          {/* responsive table */}
          <Grid item className="maintable">
            <CtART
              rowsdata={this.state.rows}
              columnsdata={this.state.columnsdata}
              rowsPerPage={this.state.rowsPerPage}
              tblmaxHeight={500}
              csvFileName={csvFileName("General Item Price Master")}
              rowIconAction={[
                {
                  label: "Edit",
                  onClick: this.handleARDonActionClick_Edit,
                  icon: "Edit",
                  className: "secondary-button",
                },
                {
                  label: "Delete",
                  onClick: this.handleARDonActionClick_Delete,
                  icon: "delete",
                  className: "delete-button",
                },
              ]}
              showColumnFilters={false}
              actionColSize={90}
              density={"compact"}
              hideColIdList={["bint_srno"]}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Box display={{ xs: "none" }} style={{ textAlign: "right" }}>
            {this.state.ARD}
          </Box>
        </Grid>
      </form>
    );
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <GeneralItemPrice {...props} navigate={navigate} />;
}
export default connect(
  mapStatetoProps,
  mapSetUserPrivileges_ToProps
)(HoCtToastContainer(HOCVerifyIsUser(withRouter(WithNavigate))));
