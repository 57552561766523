import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Grid, Tab, Typography } from "@mui/material";
import React, { Component } from "react";
import CtCmbEditable from "./CustomTool/CtCmbEditable";
import CtTxt from "../Components/CustomTool/CtTxt";
import CtTxtNum from "../Components/CustomTool/CtTxtNum";
import CtTxtAmt from "../Components/CustomTool/CtTxtAmt";
import ControlledCheckbox from "../Components/CustomTool/CtCheckBox";
import CtCmb from "./CustomTool/CtCmb";
import TableList from "../Components/CustomTool/TableList";
import CtMultiLineText from "./CustomTool/CtMultiLineText";
import CtBtn from "./CustomTool/CtBtn";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import HoCtToastContainer from "../HOC/HoCtToastContainer";
import { withRouter } from "../HOC/withRouter";
import CtCRT from "./CustomTool/CustomResponsiveTable";
import { Delete, Edit } from "@mui/icons-material";
import { fetchProposal } from "./API";
import TextEditor from "./TextEditor";
import {
  applyTrimOnObjectValues,
  get_DMY_from_ISODate,
  get_YMD_from_ISODate,
  getNumber_or_Blank4Null,
  getRowColData,
  getRowData,
  getString_or_Blank4Null,
  isValidItem,
  roundOff,
} from "../SystemUtility/SystemUtility";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  mapSetUserPrivileges_ToProps,
  mapStatetoProps,
} from "../mapRedux/mapReduxProps";
import HOCVerifyIsUser from "../HOC/HOCVerifyIsUser";
import ModeIcon from "@mui/icons-material/Mode";
import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";
import MoreInfoTextEditor from "./MoreInfoTextEditor";

class MoreInfo extends Component {
  state = {
    HoistWayStr: "",
    firstHeader: "",
    SecondHeader: "",
    thirdHeader: "",
    FourHeader: "",
    ProposalLiftDetails:
      this.props.location.state && this.props.location.state.ProposalLiftDetails
        ? this.props.location.state.ProposalLiftDetails
        : [],
    formData: {
      value: "1",
      int_status: 1,
      TblYear:
        this.props.location.state && this.props.location.state.TblYear
          ? this.props.location.state.TblYear
          : "",

      vac_brcode:
        this.props.location.state && this.props.location.state.vac_brcode
          ? this.props.location.state.vac_brcode
          : "",
      bint_summarysrno:
        this.props.location.state && this.props.location.state.bint_summarysrno
          ? this.props.location.state.bint_summarysrno
          : "",
      dtpDate:
        this.props.location.state && this.props.location.state.dtpDate
          ? this.props.location.state.dtpDate
          : "",
      cmbBranch:
        this.props.location.state && this.props.location.state.cmbBranch
          ? this.props.location.state.cmbBranch
          : "",

      txtTotalCount:
        this.props.location.state && this.props.location.state.txtTotal
          ? this.props.location.state.txtTotal
          : "",

      //Site Value
      txtLiftName: "",
      cmbLiftType: "",
      txtBasement: "",
      chkGroundFloor: false,
      txtNoFloor: "",
      txtFloor: "",
      basementString: "",
      cmbOpeningSite: "YES",
      tableHide: false,
      txtFloorHeight: 0,
      txtNoStep: "",
      txtNoOpening: "",
      txtWide: 0,
      txtDeep: 0,
      cmbHoistWay: "",
      cmbMachineRoomAvailable: "YES",
      txtSiteRemark: "",

      // Machine Type
      cmbMachineType: "",
      cmbMachineRoom: "",
      cmbMachinePlacement: "",
      txtMachineOther: "",
      cmbCounter: "",
      txtMachineRemark: "",
      txtMachineSet: "",

      // Door Type
      cmbDoorType: "",
      cmbLandingDoorSelection: "",
      cmbCarDoorSelection: "",
      cmbGlassDoor: "NO",
      cmbVisiblePanel: "NO",
      cmbCarDoorEnclosure: "",
      cmbReverseCarDoorEnclosure: "",
      cmbDoorOpening: "",
      txtOtherWide: 0,
      txtHeight: 2000,
      cmbDoorFramed: "",
      txtDoorOperation: "",
      txtCarEntrance: "",
      txtHoistWayEntrance: "",

      //Cabin Types
      txtCarWide: 0,
      txtCarDeep: 0,
      cmbCarHeight: "2200",
      txtCarHeight: 0,
      cmbCarEnclosure: "",
      PreviousValuecmbCarEnclosure: "",
      txtCarCarpetArea: 0,
      txtCarEnclosureInfo: "",

      //Controller Types
      cmbDriveType: "",
      cmbControllerOperation: "",
      cmbARD: "",
      cmbOLNS: "",
      txtControlOperation: "",

      //Lift Specification
      txtNoOfPassenger: "",
      txtLoadCapacity: 0,
      cmbSpeed: "",
      txtOtherSpeed: 0,
      txtPowerSupply:
        "400 Volts, AC. 3 phase 420 volt, 50-HZ. Light Supply: - AC.1 phase 230 volts, 50 HZ.",
      cmbCOL: "",
      cmbCOLDisplay: "",
      cmbSecurity: "",
      txtSignal:
        "(1. LOP & COP Digital up/down arrows 2. On/ Off switch for Fan & light)",
      txtLiftQuantity: "2",
      txtRate: 0,
      txtAmount: 0,
      txtGST: 0,
      txtTotal: 0,
      txtBoxPaymentSet: "",
      txtBoxCancel: "",
      txtBoxNote: "",
    },
    // nextBtnName: "Continue To Machine Room",
    nextBtnName: "",
    previousBtnName: "",

    //site state
    LiftTypes: [],
    HoistWayStructure: [],
    SiteOpen: [{ open: "YES" }, { open: "NO" }],

    // Machine State
    MachineType: [],
    MachineRoom: [],
    MachinePlacement: [],
    Counter: [{ vac_counter: "Back" }, { vac_counter: "Side" }],
    MachineRoomAvailable: [
      { room: "YES" },
      { room: "NO" },
      { room: "NOT DECIDED" },
    ],

    // Door State
    DoorTypes: [{ vac_doortype: "AUTO" }, { vac_doortype: "MANUAL" }],
    DoorDetails: [],
    AutoDoorDetails: [],
    ManualDoorDetails: [],
    CarDoor: [
      { vac_car_door: "Imperforated" },
      { vac_car_door: "Collapsible" },
    ],
    GlassDoor: [{ vac_glass_door: "YES" }, { vac_glass_door: "NO" }],
    CarEnclosure: [],
    DoorOpening: [{ vac_door_opening: "Other" }],
    DoorFramed: [{ vac_door_framed: "TOP" }, { vac_door_framed: "CLIENT" }],

    //Cabin State
    CarEnclosure: [
      // { vac_car_enclosure: "S.S Silver Linen" },
      // { vac_car_enclosure: "S.S Golden Linen" },
      // { vac_car_enclosure: "S.S Silver Mirror" },
      // { vac_car_enclosure: "S.S Golden Mirror" },
    ],
    CarInsideHeight: [
      { vac_carinsideheight: "2200" },
      { vac_carinsideheight: "2300" },
      { vac_carinsideheight: "2400" },
      { vac_carinsideheight: "Other" },
    ],

    //Controller State
    DriveType: [],

    ControllerOperation: [
      { vac_controller_operation: "Full Collective" },
      { vac_controller_operation: "Down Collective" },
    ],

    // Lift Specification State

    Speed: [
      { vac_speed: "Other" },
      { vac_speed: "0.3" },
      { vac_speed: "0.5" },
      { vac_speed: "0.7" },
      { vac_speed: "1.0" },
      { vac_speed: "1.25" },
      { vac_speed: "1.5" },
      { vac_speed: "2.0" },
      { vac_speed: "2.5" },
      { vac_speed: "3.0" },
    ],
    Col: [{ vac_col: "Push Button" }, { vac_col: "Touch Button" }],
    COLDisplay: [],
    Security: [
      { vac_security: "Biometric" },
      { vac_security: "RFID Card" },
      { vac_security: "None" },
    ],
    TermsPayment: [],
    TermsCancellation: [],
    Note: [],

    // Site Info
    siteColumn: [
      // { id: "Action", label: "Action", name: "", width: 80 },
      {
        id: "vac_floor",
        label: "Floor",
        // type: "CtMultiLineText",
        // CtrlProps: {
        //   maxLength: 100,
        //   items: [],
        //   width: 180,
        // },
      },

      {
        id: "int_front",
        label: "Front",
        type: "Chk",
        CtrlProps: {
          behaviour: "check",
          disabled:
            this.props.location.state.ProposalLiftDetails.int_status == 4
              ? true
              : false,
        },
      },
      {
        id: "int_reverse",
        label: "Reverse",
        type: "Chk",
        CtrlProps: {
          behaviour: "check",
          disabled:
            this.props.location.state.ProposalLiftDetails.int_status == 4
              ? true
              : false,
        },

        headertype: "CtCmb",
        headerCtrlProps: {
          colID: "type",
          items: [
            // { type: " " },
            { type: "Reverse" },
            { type: "Left" },
            { type: "Right" },
          ],
          disabled:
            this.props.location.state.ProposalLiftDetails.int_status == 4
              ? true
              : false,
          width: 100,
          value: "",
        },
      },
    ],
    SiteTableHeaderValue: "",
    siteRows: [],
    floorRows: [],
    basementRows: [],
    chkRows: [],
    floorDoorRows: [],
    basementDoorRows: [],
    chkDoorRows: [],
    previousSiteRows: [],
    siteRowsPerPage: 50,
    doorRowsPerPage: 50,
    doorRows: [],
    doorRowsCount: 0,

    // Door Info

    doorColumn: [
      {
        id: "vac_floor",
        label: "Floor",
      },

      {
        id: "vac_carenclosure",
        label: "Front Door Enclosure",
        type: "CtCmb",
        CtrlProps: {
          colID: "vac_carenclosure",
          defValue: "",
          items: [],
          width: 180,
          // disabled:
          //   this.props.location.state.ProposalLiftDetails.int_status == 4
          //     ? true
          //     : false,
          disablecondition: [
            {
              colID: "enableFront",
              value: false,
              relationalOperator: "==",
            },
            {
              colID: "finalizeDisabled",
              value: true,
              relationalOperator: "==",
            },
          ],
          visiblecondition: [
            {
              colID: "enableFront",
              value: true,
              relationalOperator: "==",
            },
          ],
        },
        headertype: "CtCmb",
        headerCtrlProps: {
          colID: "vac_carenclosure",
          label: "Front Door Enclosure",
          items: [],
          width: 180,
          value: "",
          disabled:
            this.props.location.state.ProposalLiftDetails.int_status == 4
              ? true
              : false,
        },
      },

      {
        id: "vac_reversecarenclosure",
        label: "Door Enclosure",
        type: "CtCmb",
        CtrlProps: {
          colID: "vac_carenclosure",
          defValue: "",
          items: [],
          width: 180,
          // disabled:
          //   this.props.location.state.ProposalLiftDetails.int_status == 4
          //     ? true
          //     : false,
          disablecondition: [
            {
              colID: "enableReverse",
              value: false,
              relationalOperator: "==",
            },
            {
              colID: "finalizeDisabled",
              value: true,
              relationalOperator: "==",
            },
          ],
          visiblecondition: [
            {
              colID: "enableReverse",
              value: true,
              relationalOperator: "==",
            },
          ],
        },
        headertype: "CtCmb",
        headerCtrlProps: {
          colID: "vac_carenclosure",
          label: "Reverse Door Enclosure",
          items: [],
          width: 180,
          value: "",
          disabled:
            this.props.location.state.ProposalLiftDetails.int_status == 4
              ? true
              : false,
        },
      },

      {
        id: "vac_doorframeenclosure",
        label: "Front Door Frame Enclosure",
        type: "CtCmb",
        CtrlProps: {
          colID: "vac_carenclosure",
          defValue: "",
          items: [],
          width: 180,
          // disabled:
          //   this.props.location.state.ProposalLiftDetails.int_status == 4
          //     ? true
          //     : false,
          disablecondition: [
            {
              colID: "enableFront",
              value: false,
              relationalOperator: "==",
            },
            {
              colID: "finalizeDisabled",
              value: true,
              relationalOperator: "==",
            },
          ],
          visiblecondition: [
            {
              colID: "enableFront",
              value: true,
              relationalOperator: "==",
            },
          ],
        },
        headertype: "CtCmb",
        headerCtrlProps: {
          colID: "vac_carenclosure",
          label: "Front Door Frame Enclosure",
          items: [],
          width: 180,
          value: "",
          disabled:
            this.props.location.state.ProposalLiftDetails.int_status == 4
              ? true
              : false,
        },
      },

      {
        id: "vac_reversedoorframeenclosure",
        label: "Door Frame Enclosure",
        type: "CtCmb",
        CtrlProps: {
          colID: "vac_carenclosure",
          defValue: "",
          items: [],
          width: 180,
          // disabled:
          //   this.props.location.state.ProposalLiftDetails.int_status == 4
          //     ? true
          //     : false,
          disablecondition: [
            {
              colID: "enableReverse",
              value: false,
              relationalOperator: "==",
            },
            {
              colID: "finalizeDisabled",
              value: true,
              relationalOperator: "==",
            },
          ],
          visiblecondition: [
            {
              colID: "enableReverse",
              value: true,
              relationalOperator: "==",
            },
          ],
        },
        headertype: "CtCmb",
        headerCtrlProps: {
          colID: "vac_carenclosure",
          label: "Revrse Door Frame Enclosure",
          items: [],
          width: 180,
          value: "",
          disabled:
            this.props.location.state.ProposalLiftDetails.int_status == 4
              ? true
              : false,
        },
      },
    ],

    doorColumnIfYes: [
      {
        id: "vac_floor",
        label: "Floor",
      },

      {
        id: "vac_carenclosure",
        label: "Front Door Enclosure",
        type: "CtCmb",
        CtrlProps: {
          colID: "vac_carenclosure",
          defValue: "",
          items: [],
          width: 180,
          // disabled:
          //   this.props.location.state.ProposalLiftDetails.int_status == 4
          //     ? true
          //     : false,
          disablecondition: [
            {
              colID: "enableFront",
              value: false,
              relationalOperator: "==",
            },
            {
              colID: "finalizeDisabled",
              value: true,
              relationalOperator: "==",
            },
          ],
          visiblecondition: [
            {
              colID: "enableFront",
              value: true,
              relationalOperator: "==",
            },
          ],
        },
        headertype: "CtCmb",
        headerCtrlProps: {
          colID: "vac_carenclosure",
          label: "Front Door Enclosure",
          items: [],
          width: 180,
          value: "",
          disabled:
            this.props.location.state.ProposalLiftDetails.int_status == 4
              ? true
              : false,
        },
      },

      {
        id: "vac_doorframeenclosure",
        label: "Front Door Frame Enclosure",
        type: "CtCmb",
        CtrlProps: {
          colID: "vac_carenclosure",
          defValue: "",
          items: [],
          width: 180,
          // disabled:
          //   this.props.location.state.ProposalLiftDetails.int_status == 4
          //     ? true
          //     : false,
          disablecondition: [
            {
              colID: "enableFront",
              value: false,
              relationalOperator: "==",
            },
            {
              colID: "finalizeDisabled",
              value: true,
              relationalOperator: "==",
            },
          ],
          visiblecondition: [
            {
              colID: "enableFront",
              value: true,
              relationalOperator: "==",
            },
          ],
        },
        headertype: "CtCmb",
        headerCtrlProps: {
          colID: "vac_carenclosure",
          label: "Front Door Frame Enclosure",
          items: [],
          width: 180,
          value: "",
          disabled:
            this.props.location.state.ProposalLiftDetails.int_status == 4
              ? true
              : false,
        },
      },
    ],
  };

  componentDidMount() {
    document.title = "Top India Elevator : More Info";
    // this.getInfo();
    this.props.verifyUserID(
      this.getInfo,
      this.props.setUserPrivileges,
      this.props.modules.proposal
    );
  }

  getInfo = () => {
    this.props.notifyProcessing();
    const reqData = {
      Op: "LoadAllMaster",
      vac_brcode: this.state.formData.vac_brcode,
      TBL_YEAR: this.state.formData.TblYear,
      vac_refno: this.state.ProposalLiftDetails.vac_refno,
    };
    fetchProposal(reqData)
      .then((res) => {
        this.props.closeUpdateProcessing();
        let LiftTypes =
            res.data.LiftType && res.data.LiftType.length > 0
              ? res.data.LiftType
              : [],
          HoistWayStructure =
            res.data.HoistWayStructure && res.data.HoistWayStructure.length > 0
              ? res.data.HoistWayStructure
              : [],
          MachineType =
            res.data.MachineType && res.data.MachineType.length > 0
              ? res.data.MachineType
              : [],
          MachineRoom =
            res.data.MachineRoom && res.data.MachineRoom.length > 0
              ? res.data.MachineRoom
              : [],
          MachinePlacement =
            res.data.MachinePlacement && res.data.MachinePlacement.length > 0
              ? res.data.MachinePlacement
              : [],
          DriveType =
            res.data.DriveType && res.data.DriveType.length > 0
              ? res.data.DriveType
              : [],
          COLDisplay =
            res.data.DisplayType && res.data.DisplayType.length > 0
              ? res.data.DisplayType
              : [],
          TermsPayment =
            res.data.TermsPayment && res.data.TermsPayment.length > 0
              ? res.data.TermsPayment
              : [],
          TermsCancellation =
            res.data.TermsCancellation && res.data.TermsCancellation.length > 0
              ? res.data.TermsCancellation
              : [],
          Note = res.data.Note && res.data.Note.length > 0 ? res.data.Note : [],
          DoorDetails =
            res.data.LandingDoorType && res.data.LandingDoorType.length > 0
              ? res.data.LandingDoorType
              : [],
          CarEnclosure =
            res.data.CarEnclosure && res.data.CarEnclosure.length > 0
              ? res.data.CarEnclosure
              : [],
          CarEnclosureTbl =
            res.data.CarEnclosureTbl && res.data.CarEnclosureTbl.length > 0
              ? res.data.CarEnclosureTbl
              : [],
          AutoDoorDetails = res.data.LandingDoorType.filter((row) => {
            if (row.vac_doortype == "AUTO") {
              return row;
            }
          }),
          ManualDoorDetails = res.data.LandingDoorType.filter((row) => {
            if (row.vac_doortype == "MANUAL") {
              return row;
            }
          }),
          ProposalLiftDetails = res.data.ProposalLiftDetails,
          siteRows = ProposalLiftDetails.ReverseOpening,
          floorRows = ProposalLiftDetails.ReverseOpening.filter((item) =>
            /^\d+$/.test(item.vac_floor)
          ).map((row) => {
            return {
              vac_floor: row.vac_floor,
              int_front: true,
              int_reverse: false,
            };
          }),
          basementRows = ProposalLiftDetails.ReverseOpening.filter((item) =>
            /^B\d+$/.test(item.vac_floor)
          ).map((row) => {
            return {
              vac_floor: row.vac_floor,
              int_front: true,
              int_reverse: false,
            };
          }),
          chkRows = ProposalLiftDetails.ReverseOpening.filter(
            (item) => item.vac_floor === "G"
          ).map((row) => {
            return {
              vac_floor: row.vac_floor,
              int_front: true,
              int_reverse: false,
            };
          }),
          floorDoorRows = ProposalLiftDetails.LandingDoorEnclosure.filter(
            (item) => /^\d+$/.test(item.vac_floor)
          ).map((row) => {
            return {
              vac_floor: row.vac_floor,
              vac_carenclosure: "",
              enableFront: true,
              enableReverse: false,
            };
          }),
          basementDoorRows = ProposalLiftDetails.LandingDoorEnclosure.filter(
            (item) => /^B\d+$/.test(item.vac_floor)
          ).map((row) => {
            return {
              vac_floor: row.vac_floor,
              vac_carenclosure: "",
              enableFront: true,
              enableReverse: false,
            };
          }),
          chkDoorRows = ProposalLiftDetails.LandingDoorEnclosure.filter(
            (item) => item.vac_floor === "G"
          ).map((row) => {
            return {
              vac_floor: row.vac_floor,
              vac_carenclosure: "",
              enableFront: true,
              enableReverse: false,
            };
          });

        let doorRows = [];

        if (siteRows.length > 0) {
          siteRows.map((row) => {
            ProposalLiftDetails.LandingDoorEnclosure.map((doorRow) => {
              if (row.vac_floor == doorRow.vac_floor) {
                doorRows.push({
                  ...doorRow,
                  enableFront: row.int_front == 1 ? true : false,
                  enableReverse: row.int_reverse == 1 ? true : false,
                  finalizeDisabled:
                    this.props.location.state.ProposalLiftDetails.int_status ==
                    4
                      ? true
                      : false,
                });
              }
            });
          });
        } else if (ProposalLiftDetails.LandingDoorEnclosure.length > 0) {
          ProposalLiftDetails.LandingDoorEnclosure.map((doorRow) => {
            doorRows.push({
              ...doorRow,
              enableFront: true,
              enableReverse: false,
              finalizeDisabled:
                this.props.location.state.ProposalLiftDetails.int_status == 4
                  ? true
                  : false,
            });
          });
        }

        let doorColumn = this.state.doorColumn.map((col) => {
          if (col.id === "vac_carenclosure") {
            return {
              ...col,
              CtrlProps: {
                ...col.CtrlProps,
                items: CarEnclosureTbl,
              },
              headerCtrlProps: {
                ...col.headerCtrlProps,
                items: CarEnclosureTbl,
              },
            };
          } else if (col.id === "vac_reversecarenclosure") {
            return {
              ...col,
              CtrlProps: {
                ...col.CtrlProps,
                items: CarEnclosureTbl,
              },
              label:
                ProposalLiftDetails.vac_openingsameside == "NO"
                  ? ProposalLiftDetails.vac_openingheading + " Door Enclosure"
                  : "Door Enclosure",
              headerCtrlProps: {
                ...col.headerCtrlProps,
                items: CarEnclosureTbl,
                label:
                  ProposalLiftDetails.vac_openingsameside == "NO"
                    ? ProposalLiftDetails.vac_openingheading + " Door Enclosure"
                    : "Door Enclosure",
              },
            };
          } else if (col.id === "vac_doorframeenclosure") {
            return {
              ...col,
              CtrlProps: {
                ...col.CtrlProps,
                items: CarEnclosureTbl,
              },
              headerCtrlProps: {
                ...col.headerCtrlProps,
                items: CarEnclosureTbl,
              },
            };
          } else if (col.id === "vac_reversedoorframeenclosure") {
            return {
              ...col,
              CtrlProps: {
                ...col.CtrlProps,
                items: CarEnclosureTbl,
              },
              label:
                ProposalLiftDetails.vac_openingsameside == "NO"
                  ? ProposalLiftDetails.vac_openingheading +
                    " Door Frame Enclosure"
                  : "Door Frame Enclosure",
              headerCtrlProps: {
                ...col.headerCtrlProps,
                items: CarEnclosureTbl,
                label:
                  ProposalLiftDetails.vac_openingsameside == "NO"
                    ? ProposalLiftDetails.vac_openingheading +
                      " Door Frame Enclosure"
                    : "Door Frame Enclosure",
              },
            };
          }
          return col;
        });

        let doorColumnIfYes = this.state.doorColumnIfYes.map((col) => {
          if (col.id === "vac_carenclosure") {
            return {
              ...col,
              CtrlProps: {
                ...col.CtrlProps,
                items: CarEnclosureTbl,
              },
              headerCtrlProps: {
                ...col.headerCtrlProps,
                items: CarEnclosureTbl,
              },
            };
          } else if (col.id === "vac_doorframeenclosure") {
            return {
              ...col,
              CtrlProps: {
                ...col.CtrlProps,
                items: CarEnclosureTbl,
              },
              headerCtrlProps: {
                ...col.headerCtrlProps,
                items: CarEnclosureTbl,
              },
            };
          }
          return col;
        });

        let siteColumn = this.state.siteColumn.map((col) => {
          if (col.id === "int_reverse") {
            return {
              ...col,
              headerCtrlProps: {
                ...col.headerCtrlProps,
                value: getString_or_Blank4Null(
                  ProposalLiftDetails.vac_openingheading
                ),
              },
            };
          }
          return col;
        });

        let str = "",
          enclosureArray = [];

        doorRows.forEach((row) => {
          if (
            !enclosureArray.some(
              (item) => item.vac_carenclosure === row.vac_carenclosure
            )
          ) {
            enclosureArray.push({ vac_carenclosure: row.vac_carenclosure });
          }
        });

        if (
          enclosureArray.length == 1 &&
          enclosureArray[0].vac_carenclosure != null
        ) {
          str += ` ${enclosureArray[0].vac_carenclosure} `;
          str += ` ${
            this.state.formData.cmbGlassDoor == "YES"
              ? "Framed Glass Landing Door On All Floor. "
              : "Landing Door On All Floor"
          }  `;
        } else {
          enclosureArray.map((enclosure) => {
            let No = 0;
            doorRows.map((row) => {
              if (enclosure.vac_carenclosure == row.vac_carenclosure) {
                No++;
                if (No == 1) {
                  str += ` ${
                    row.vac_carenclosure == null ? "" : row.vac_carenclosure
                  } `;
                  str += ` ${
                    this.state.formData.cmbGlassDoor == "YES"
                      ? "Framed Glass Landing Door on Floor No."
                      : "Landing Door on Floor No."
                  }  `;
                }
                str += ` ${row.vac_floor},`;
              }
            });
          });
        }

        this.setState(
          {
            LiftTypes,
            HoistWayStructure,
            MachineType,
            MachineRoom,
            MachinePlacement,
            DriveType,
            COLDisplay,
            TermsPayment,
            TermsCancellation,
            Note,
            DoorDetails,
            CarEnclosure,
            AutoDoorDetails,
            ManualDoorDetails,
            siteRows,
            floorRows,
            basementRows,
            chkRows,
            doorRows,
            floorDoorRows,
            basementDoorRows,
            chkDoorRows,
            siteColumn,
            doorColumn,
            doorColumnIfYes,
            HoistWayStr: str,
            SiteTableHeaderValue: ProposalLiftDetails.vac_openingheading,
            formData: {
              ...this.state.formData,
              int_status: getNumber_or_Blank4Null(
                ProposalLiftDetails.int_status
              ),
              txtLiftName: getString_or_Blank4Null(
                ProposalLiftDetails.vac_liftname
              ),
              cmbLiftType: getString_or_Blank4Null(
                ProposalLiftDetails.vac_lifttype
              ),
              txtBasement: getString_or_Blank4Null(
                ProposalLiftDetails.bint_noofbasement
              ),
              chkGroundFloor:
                ProposalLiftDetails.int_groundfloor == 1 ? true : false,
              txtNoFloor: ProposalLiftDetails.bint_nooffloor,
              txtFloor: getString_or_Blank4Null(ProposalLiftDetails.vac_floor),
              cmbOpeningSite:
                ProposalLiftDetails.vac_openingsameside == null
                  ? "YES"
                  : ProposalLiftDetails.vac_openingsameside,
              tableHide:
                ProposalLiftDetails.vac_openingsameside == "NO" ? false : true,
              txtFloorHeight: getNumber_or_Blank4Null(
                ProposalLiftDetails.dec_floorheight
              ),
              txtNoStep: getString_or_Blank4Null(
                ProposalLiftDetails.bint_noofstops
              ),
              txtNoOpening: getString_or_Blank4Null(
                ProposalLiftDetails.bint_noofopening
              ),
              txtWide: getNumber_or_Blank4Null(
                ProposalLiftDetails.dec_hoistwaywide
              ),
              txtDeep: getNumber_or_Blank4Null(
                ProposalLiftDetails.dec_hoistwaydeep
              ),
              cmbHoistWay: getString_or_Blank4Null(
                ProposalLiftDetails.vac_hoistwaystructure
              ),
              cmbMachineRoomAvailable: getString_or_Blank4Null(
                ProposalLiftDetails.vac_machineroomavailable
              ),
              txtSiteRemark: getString_or_Blank4Null(
                ProposalLiftDetails.vac_siteremark
              ),

              cmbMachineType: getString_or_Blank4Null(
                ProposalLiftDetails.vac_machine
              ),
              cmbMachineRoom: getString_or_Blank4Null(
                ProposalLiftDetails.vac_machineroom
              ),
              cmbMachinePlacement: getString_or_Blank4Null(
                ProposalLiftDetails.vac_machineplacement
              ),
              txtMachineOther: getString_or_Blank4Null(
                ProposalLiftDetails.vac_machineplacementother
              ),
              cmbCounter: getString_or_Blank4Null(
                ProposalLiftDetails.vac_counterwtposition
              ),
              txtMachineRemark: getString_or_Blank4Null(
                ProposalLiftDetails.vac_machineremark
              ),

              // Door Type
              cmbDoorType: getString_or_Blank4Null(
                ProposalLiftDetails.vac_doortype
              ),
              cmbLandingDoorSelection: getString_or_Blank4Null(
                ProposalLiftDetails.vac_subdoortype
              ),
              cmbCarDoorSelection: getString_or_Blank4Null(
                ProposalLiftDetails.vac_cardoorselection
              ),
              cmbGlassDoor:
                ProposalLiftDetails.vac_glassdoor == null
                  ? "NO"
                  : ProposalLiftDetails.vac_glassdoor,
              cmbVisiblePanel: getString_or_Blank4Null(
                ProposalLiftDetails.vac_visiblepanel
              ),
              cmbCarDoorEnclosure: getString_or_Blank4Null(
                ProposalLiftDetails.vac_cardoorenclosure
              ),
              cmbReverseCarDoorEnclosure: getString_or_Blank4Null(
                ProposalLiftDetails.vac_frontcardoorenclosure
              ),
              cmbDoorOpening: getString_or_Blank4Null(
                ProposalLiftDetails.vac_dooropeningwidth
              ),
              txtOtherWide: getNumber_or_Blank4Null(
                ProposalLiftDetails.dec_dooropeningwidthother
              ),
              txtHeight:
                ProposalLiftDetails.dec_dooropeningheight == null
                  ? 2000
                  : ProposalLiftDetails.dec_dooropeningheight,
              cmbDoorFramed: getString_or_Blank4Null(
                ProposalLiftDetails.vac_doorframedby
              ),
              txtDoorOperation: getString_or_Blank4Null(
                ProposalLiftDetails.vac_dooroperation
              ),
              txtCarEntrance: getString_or_Blank4Null(
                ProposalLiftDetails.vac_carentrance
              ),
              txtHoistWayEntrance: getString_or_Blank4Null(
                ProposalLiftDetails.vac_hoistwayentrance
              ),

              //Cabin Types
              txtCarWide: getNumber_or_Blank4Null(
                ProposalLiftDetails.dec_carinsidewide
              ),
              txtCarDeep: getNumber_or_Blank4Null(
                ProposalLiftDetails.dec_carinsidedeep
              ),
              cmbCarEnclosure: getString_or_Blank4Null(
                ProposalLiftDetails.vac_carenclosure
              ),
              cmbCarHeight:
                ProposalLiftDetails.vac_supply == null ||
                ProposalLiftDetails.vac_supply == ""
                  ? "2200"
                  : getNumber_or_Blank4Null(
                      ProposalLiftDetails.vac_carinsideheight
                    ),
              txtCarHeight:
                ProposalLiftDetails.vac_supply == null ||
                ProposalLiftDetails.vac_supply == ""
                  ? 0
                  : getString_or_Blank4Null(
                      ProposalLiftDetails.dec_carinsideheight
                    ),
              txtCarCarpetArea: getNumber_or_Blank4Null(
                ProposalLiftDetails.dec_carcarpetarea
              ),
              txtCarEnclosureInfo: getString_or_Blank4Null(
                ProposalLiftDetails.vac_carenclosureinfo
              ),

              //Controller Types
              cmbDriveType: getString_or_Blank4Null(
                ProposalLiftDetails.vac_drive
              ),
              cmbControllerOperation: getString_or_Blank4Null(
                ProposalLiftDetails.vac_controller
              ),
              cmbARD: getString_or_Blank4Null(ProposalLiftDetails.vac_ard),
              cmbOLNS: getString_or_Blank4Null(ProposalLiftDetails.vac_olns),
              txtControlOperation: getString_or_Blank4Null(
                ProposalLiftDetails.vac_controloperation
              ),

              //Lift Specification

              txtNoOfPassenger: getString_or_Blank4Null(
                ProposalLiftDetails.bint_noofpassenger
              ),
              txtLoadCapacity: getNumber_or_Blank4Null(
                ProposalLiftDetails.dec_load
              ),
              cmbSpeed: getString_or_Blank4Null(ProposalLiftDetails.vac_speed),
              txtOtherSpeed: getNumber_or_Blank4Null(
                ProposalLiftDetails.dec_speed
              ),
              txtPowerSupply:
                ProposalLiftDetails.vac_supply == null ||
                ProposalLiftDetails.vac_supply == ""
                  ? "400 Volts, AC. 3 phase 420 volt, 50-HZ. Light Supply: - AC.1 phase 230 volts, 50 HZ."
                  : getString_or_Blank4Null(ProposalLiftDetails.vac_supply),
              cmbCOL: getString_or_Blank4Null(ProposalLiftDetails.vac_coplop),
              cmbCOLDisplay: getString_or_Blank4Null(
                ProposalLiftDetails.vac_displaytype
              ),
              cmbSecurity: getString_or_Blank4Null(
                ProposalLiftDetails.vac_security
              ),
              txtSignal:
                ProposalLiftDetails.vac_signal == null
                  ? "(1. LOP & COP Digital up/down arrows 2. On/ Off switch for Fan & light)"
                  : ProposalLiftDetails.vac_signal,
              txtLiftQuantity: getString_or_Blank4Null(
                ProposalLiftDetails.bint_qty
              ),
              txtRate: getNumber_or_Blank4Null(ProposalLiftDetails.dec_rate),
              txtAmount: getNumber_or_Blank4Null(
                ProposalLiftDetails.dec_amount
              ),
              txtGST: getNumber_or_Blank4Null(ProposalLiftDetails.dec_taxamt),
              txtTotal: getNumber_or_Blank4Null(ProposalLiftDetails.dec_total),
            },
          },
          () => {
            if (
              this.state.formData.cmbDoorType == "AUTO" &&
              this.state.formData.cmbLandingDoorSelection.length > 0 &&
              isValidItem(
                this.state.AutoDoorDetails,
                "vac_subdoortype",
                this.state.formData.cmbLandingDoorSelection
              ) &&
              Number(this.state.formData.txtWide) > 0
            ) {
              this.getDoorWide();
            } else if (
              this.state.formData.cmbDoorType == "MANUAL" &&
              this.state.formData.cmbLandingDoorSelection.length > 0 &&
              Number(this.state.formData.txtWide) > 0
            ) {
              this.getDoorWide();
            }

            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }
        );
      })
      .catch((error) => {
        console.log("Unknown error occurred in onFormLoad.", error);
      });
    // document.getElementById("txtName").focus();
  };

  getSiteRows = (Number, Basement = false) => {
    let rows = [];
    if (Number > 0) {
      Array.from({ length: Number }).forEach((_, index) => {
        rows.push({
          // vac_floor:
          //   Basement == true ? `B${Number - index}` : `${Number - index}`,
          vac_floor: Basement == true ? `B${index + 1}` : `${Number - index}`,
          int_front: true,
          int_reverse: false,
        });
      });
    }
    return rows;
  };

  getDoorRows = (Number, Basement = false) => {
    let rows = [];
    if (Number > 0) {
      Array.from({ length: Number }).forEach((_, index) => {
        rows.push({
          vac_floor: Basement == true ? `B${index + 1}` : `${Number - index}`,
          vac_carenclosure: "",
          vac_reversecarenclosure: "",
          vac_doorframeenclosure: "",
          vac_reversedoorframeenclosure: "",
          enableFront: true,
          enableReverse: false,
        });
      });
    }
    return rows;
  };

  onEditorStateChange = (ctrlInfo) => {
    if (ctrlInfo.id === "Machine") {
      this.setState({
        formData: {
          ...this.state.formData,
          txtMachineRemark: ctrlInfo.value,
        },
      });
    }
  };

  resetEditorStateToUndefined = (id) => {
    if (id === "Machine") {
      this.setState({
        formData: {
          ...this.state.formData,
          txtMachineSet: undefined,
        },
      });
      // setTextTobeSet1(undefined);
    }
  };

  updateDetails = (id, Details) => {
    let txtMachineRemark = this.state.formData.txtMachineRemark,
      txtDoorOperation = this.state.formData.txtDoorOperation,
      txtCarEnclosureInfo = this.state.formData.txtCarEnclosureInfo,
      txtControlOperation = this.state.formData.txtControlOperation,
      txtPowerSupply = this.state.formData.txtPowerSupply,
      txtSignal = this.state.formData.txtSignal,
      txtCarEntrance = this.state.formData.txtCarEntrance,
      txtHoistWayEntrance = this.state.formData.txtHoistWayEntrance;

    if (id === "MachineRemark") {
      txtMachineRemark = Details;
    } else if (id == "DoorOperation") {
      txtDoorOperation = Details;
    } else if (id == "CarEnclosureInfo") {
      txtCarEnclosureInfo = Details;
    } else if (id == "ControlOperation") {
      txtControlOperation = Details;
    } else if (id == "Signal") {
      txtSignal = Details;
    } else if (id == "CarEntrance") {
      txtCarEntrance = Details;
    } else if (id == "HoistWayEntrance") {
      txtHoistWayEntrance = Details;
    }

    this.setState(
      {
        formData: {
          ...this.state.formData,
          txtMachineRemark,
          txtDoorOperation,
          txtCarEnclosureInfo,
          txtControlOperation,
          txtPowerSupply,
          txtSignal,
          txtCarEntrance,
          txtHoistWayEntrance,
        },
      },
      () => {}
    );
  };

  openRichTextBox = (Heading, id, TxtChange) => {
    this.setState({ ARD: null }, () => {
      let ARD = (
        <AlertResponsiveDialog
          labelDisagree="Close"
          fullscreenOnly={true}
          alertIcon=""
          alertHeading={Heading}
          alertTitle={
            <MoreInfoTextEditor
              TxtChange={TxtChange}
              Txtid={id}
              updateDetails={this.updateDetails}
            />
          }
          alertMessage=""
          defaultOpenDialog={true}
          onYesClickCloseIfExeSuccessfully={true}
        />
      );

      this.setState({ ARD });
    });
  };

  handleOnChange = (e) => {
    this.setState(
      {
        formData: { ...this.state.formData, [e.target.name]: e.target.value },
      },
      () => {
        let LandingDoorSelection = this.state.formData.cmbLandingDoorSelection;
        if (e.target.name == "cmbLiftType") {
          if (this.state.formData.cmbLiftType != "PASSENGER") {
            this.setState({
              formData: {
                ...this.state.formData,
                txtNoOfPassenger: 0,
              },
            });
          }
        } else if (e.target.name == "txtBasement") {
          // console.log(
          //   "this.state.formData.txtFloor",
          //   this.state.formData.txtFloor
          // );
          let basementRows = this.getSiteRows(Number(e.target.value), true),
            basementDoorRows = this.getDoorRows(Number(e.target.value), true),
            floorRows = this.getSiteRows(
              Number(this.state.formData.txtNoFloor)
            ),
            floorDoorRows = this.getDoorRows(
              Number(this.state.formData.txtNoFloor)
            ),
            chkRows = [],
            chkDoorRows = [],
            txtNoOpening =
              Number(this.state.formData.txtBasement) +
              Number(this.state.formData.txtNoFloor) +
              (this.state.formData.chkGroundFloor == true ? 1 : 0);

          if (this.state.formData.chkGroundFloor == true) {
            chkRows.push({ vac_floor: "G", int_front: true });

            chkDoorRows.push({
              vac_floor: "G",
              vac_carenclosure: "",
              enableFront: true,
              enableReverse: false,
            });
          }

          this.setState({
            formData: {
              ...this.state.formData,
              txtNoOpening: txtNoOpening,
              txtNoStep: txtNoOpening,
              txtFloorHeight: (Number(txtNoOpening) - 1) * 3,
            },
            basementRows: basementRows,
            chkRows: chkRows,
            floorRows: floorRows,
            floorDoorRows: floorDoorRows,
            chkDoorRows: chkDoorRows,
            basementDoorRows: basementDoorRows,
            siteRows: [...floorRows, ...chkRows, ...basementRows],
            doorRows: [...floorDoorRows, ...chkDoorRows, ...basementDoorRows],
          });
        } else if (e.target.name == "txtNoFloor") {
          let txtNoOpening =
              Number(this.state.formData.txtBasement) +
              Number(this.state.formData.txtNoFloor) +
              (this.state.formData.chkGroundFloor == true ? 1 : 0),
            floorRows = this.getSiteRows(Number(e.target.value)),
            floorDoorRows = this.getDoorRows(Number(e.target.value)),
            chkRows = [],
            chkDoorRows = [],
            basementRows = this.getSiteRows(
              Number(this.state.formData.txtBasement),
              true
            ),
            basementDoorRows = this.getDoorRows(
              Number(this.state.formData.txtBasement),
              true
            );

          if (this.state.formData.chkGroundFloor == true) {
            chkRows.push({ vac_floor: "G", int_front: true });

            chkDoorRows.push({
              vac_floor: "G",
              vac_carenclosure: "",
              enableFront: true,
              enableReverse: false,
            });
          }

          this.setState({
            formData: {
              ...this.state.formData,
              txtNoOpening: txtNoOpening,
              txtNoStep: txtNoOpening,
              txtFloorHeight: (Number(txtNoOpening) - 1) * 3,
            },
            floorRows: floorRows,
            chkRows,
            basementRows,
            floorDoorRows: floorDoorRows,
            basementDoorRows: basementDoorRows,
            chkDoorRows: chkDoorRows,
            siteRows: [...floorRows, ...chkRows, ...basementRows],
            doorRows: [...floorDoorRows, ...chkDoorRows, ...basementDoorRows],
          });
        } else if (e.target.name == "cmbOpeningSite") {
          let tableHide = false,
            siteRows = this.state.siteRows,
            basementRows = this.state.basementRows,
            floorRows = this.state.floorRows,
            chkRows = this.state.chkRows,
            cmbReverseCarDoorEnclosure =
              this.state.formData.cmbReverseCarDoorEnclosure,
            doorRows = this.state.doorRows,
            SiteTableHeaderValue = this.state.SiteTableHeaderValue,
            siteColumn = this.state.siteColumn;
          if (e.target.value == "YES") {
            doorRows = [];
            tableHide = true;
            cmbReverseCarDoorEnclosure = "";
            SiteTableHeaderValue = "";
            siteRows = [];

            this.state.doorRows.map((row) => {
              doorRows.push({
                ...row,
                enableFront: true,
                enableReverse: false,
              });
            });
          } else if (e.target.value == "NO") {
            siteColumn = [
              {
                id: "vac_floor",
                label: "Floor",
              },

              {
                id: "int_front",
                label: "Front",
                type: "Chk",
                CtrlProps: {
                  behaviour: "check",
                  disabled:
                    this.props.location.state.ProposalLiftDetails.int_status ==
                    4
                      ? true
                      : false,
                },
              },
              {
                id: "int_reverse",
                label: "Reverse",
                type: "Chk",
                CtrlProps: {
                  behaviour: "check",
                  disabled:
                    this.props.location.state.ProposalLiftDetails.int_status ==
                    4
                      ? true
                      : false,
                },

                headertype: "CtCmb",
                headerCtrlProps: {
                  colID: "type",
                  items: [
                    { type: "Reverse" },
                    { type: "Left" },
                    { type: "Right" },
                  ],
                  disabled:
                    this.props.location.state.ProposalLiftDetails.int_status ==
                    4
                      ? true
                      : false,
                  width: 180,
                  value: "",
                },
              },
            ];
            tableHide = false;
            if (siteRows.length <= 0) {
              basementRows = this.getSiteRows(
                Number(this.state.formData.txtBasement),
                true
              );
              floorRows = this.getSiteRows(
                Number(this.state.formData.txtNoFloor)
              );
              if (
                chkRows.length <= 0 &&
                this.state.formData.chkGroundFloor == true
              ) {
                chkRows.push({ vac_floor: "G", int_front: true });
              }
              siteRows = [...floorRows, ...chkRows, ...basementRows];
            }
            SiteTableHeaderValue =
              this.state.siteColumn[2].headerCtrlProps.value;
          }
          this.setState({
            formData: {
              ...this.state.formData,
              tableHide,
              cmbReverseCarDoorEnclosure,
            },
            SiteTableHeaderValue,
            siteRows,
            siteColumn,
            basementRows,
            floorRows,
            chkRows,
            doorRows,
          });
        } else if (
          e.target.name == "cmbMachineType" ||
          e.target.name == "cmbMachinePlacement" ||
          e.target.name == "cmbMachineRoom" ||
          e.target.name == "txtMachineOther"
        ) {
          let txtMachineRemark = "",
            txtMachineOther = this.state.formData.txtMachineOther;

          txtMachineRemark =
            (this.state.formData.cmbMachineType.length > 0
              ? `${this.state.formData.cmbMachineType}`
              : "") +
            " Machine directly placed at " +
            (this.state.formData.cmbMachinePlacement == "Other"
              ? `${this.state.formData.txtMachineOther}`
              : `${this.state.formData.cmbMachinePlacement}`) +
            " " +
            (this.state.formData.cmbMachineRoom.vac_machineroom == ""
              ? ""
              : this.state.formData.cmbMachineRoom);

          if (this.state.formData.cmbMachinePlacement != "Other") {
            txtMachineOther = "";
          }
          this.setState({
            formData: {
              ...this.state.formData,
              txtMachineOther,
              txtMachineRemark,
            },
          });
        } /*  else if (e.target.name == "cmbMachineRoomAvailable") {
          if (this.state.formData.cmbMachineRoomAvailable == "NO") {
            this.setState({
              formData: {
                ...this.state.formData,
                cmbMachineRoom: "",
              },
            });
          }
        }  */ else if (
          e.target.name == "cmbDoorType" ||
          e.target.name == "cmbLandingDoorSelection" ||
          e.target.name == "cmbCarDoorSelection"
        ) {
          let cmbLandingDoorSelection =
              this.state.formData.cmbLandingDoorSelection,
            doorRows = this.state.doorRows,
            basementDoorRows = this.state.basementDoorRows,
            floorDoorRows = this.state.floorDoorRows,
            chkDoorRows = this.state.chkDoorRows,
            cmbGlassDoor = this.state.formData.cmbGlassDoor,
            cmbVisiblePanel = this.state.formData.cmbVisiblePanel,
            cmbCarEnclosure = this.state.formData.cmbCarEnclosure,
            cmbCarDoorSelection = this.state.formData.cmbCarDoorSelection,
            cmbDoorFramed = this.state.formData.cmbDoorFramed;

          if (e.target.name == "cmbDoorType") {
            LandingDoorSelection = "";
            if (this.state.formData.cmbDoorType == "AUTO") {
              cmbLandingDoorSelection = "";
              cmbCarDoorSelection = "";
              cmbDoorFramed = "";
            } else if (this.state.formData.cmbDoorType == "MANUAL") {
              cmbLandingDoorSelection = "";
              cmbGlassDoor = "";
              cmbVisiblePanel = "";
              cmbCarEnclosure = "";
            }
          }
          if (
            this.state.formData.cmbDoorType == "AUTO" &&
            doorRows.length <= 0
          ) {
            basementDoorRows = this.getDoorRows(
              Number(this.state.formData.txtBasement),
              true
            );
            floorDoorRows = this.getDoorRows(
              Number(this.state.formData.txtNoFloor)
            );

            if (
              chkDoorRows.length <= 0 &&
              this.state.formData.chkGroundFloor == true
            ) {
              chkDoorRows.push({
                vac_floor: "G",
                vac_carenclosure: "",
                enableFront: true,
                enableReverse: false,
              });
            }
            doorRows = [...floorDoorRows, ...chkDoorRows, ...basementDoorRows];
          }

          let doorRowsUpdate = [];

          if (this.state.siteRows.length > 0) {
            this.state.siteRows.map((row) => {
              doorRows.map((doorRow) => {
                if (row.vac_floor == doorRow.vac_floor) {
                  doorRowsUpdate.push({
                    ...doorRow,
                    enableFront:
                      row.int_front == 1 || row.int_front == true
                        ? true
                        : false,
                    enableReverse:
                      row.int_reverse == 1 || row.int_reverse == true
                        ? true
                        : false,
                  });
                }
              });
            });
          } else {
            doorRowsUpdate = doorRows;
          }

          this.setState({
            formData: {
              ...this.state.formData,
              cmbLandingDoorSelection,
              cmbGlassDoor,
              cmbVisiblePanel,
              cmbCarEnclosure,
              cmbCarDoorSelection,
              cmbDoorFramed,
            },
            doorRows: doorRowsUpdate,
            basementDoorRows,
            chkDoorRows,
            floorDoorRows,
          });
        } else if (e.target.name == "cmbDoorOpening") {
          this.setState({
            formData: {
              ...this.state.formData,
              txtOtherWide: 0,
            },
          });
        } else if (e.target.name == "cmbGlassDoor") {
          let str = "",
            enclosureArray = [];

          this.state.doorRows.forEach((row) => {
            if (
              !enclosureArray.some(
                (item) => item.vac_carenclosure === row.vac_carenclosure
              )
            ) {
              enclosureArray.push({ vac_carenclosure: row.vac_carenclosure });
            }
          });

          if (
            enclosureArray.length == 1 &&
            enclosureArray[0].vac_carenclosure != null
          ) {
            str += ` ${enclosureArray[0].vac_carenclosure} `;
            str += ` ${
              this.state.formData.cmbGlassDoor == "YES"
                ? "Framed Glass Landing Door On All Floor. "
                : "Landing Door On All Floor"
            }  `;
          } else {
            enclosureArray.map((enclosure) => {
              let No = 0;
              this.state.doorRows.map((row) => {
                if (enclosure.vac_carenclosure == row.vac_carenclosure) {
                  No++;
                  if (No == 1) {
                    str += ` ${
                      row.vac_carenclosure == null ? "" : row.vac_carenclosure
                    } `;
                    str += ` ${
                      this.state.formData.cmbGlassDoor == "YES"
                        ? "Framed Glass Landing Door on Floor No."
                        : "Landing Door on Floor No."
                    }  `;
                  }
                  str += ` ${row.vac_floor},`;
                }
              });
            });
          }
          this.setState(
            {
              HoistWayStr: str,
            },
            () => {
              this.setCarAndHoistWay();
            }
          );
        } else if (
          e.target.name == "txtCarWide" ||
          e.target.name == "txtCarDeep"
        ) {
          let CarInsideWide = this.state.formData.txtCarWide,
            CarInsideDeep = this.state.formData.txtCarDeep,
            txtCarCarpetArea = 0;
          txtCarCarpetArea = (CarInsideWide * CarInsideDeep) / 1000000;

          this.setState({
            formData: {
              ...this.state.formData,
              txtCarCarpetArea,
            },
          });
        } else if (
          e.target.name == "cmbDriveType" ||
          e.target.name == "cmbControllerOperation" ||
          e.target.name == "cmbARD" ||
          e.target.name == "cmbOLNS"
        ) {
          let txtControlOperation = "";
          txtControlOperation =
            (this.state.formData.cmbDriveType == "Regular"
              ? "VF"
              : this.state.formData.cmbDriveType == "Integrated"
              ? "Integrated"
              : "") +
            (this.state.formData.cmbControllerOperation.length > 0
              ? ` Drive based Electronic controller for ${this.state.formData.cmbControllerOperation} Electronic Controller`
              : "") +
            (this.state.formData.cmbARD == "YES"
              ? " with ARD System"
              : this.state.formData.cmbARD == "NO"
              ? " without ARD System"
              : "") +
            (this.state.formData.cmbOLNS == "YES"
              ? " / with OLNS (Overload Non Start system)"
              : this.state.formData.cmbOLNS == "NO"
              ? " / without OLNS (Overload Non Start system)"
              : "");
          this.setState({
            formData: {
              ...this.state.formData,
              txtControlOperation,
            },
          });
        } else if (e.target.name == "txtNoOfPassenger") {
          let txtLoadCapacity = "";
          txtLoadCapacity = this.state.formData.txtNoOfPassenger * 68;
          this.setState({
            formData: {
              ...this.state.formData,
              txtLoadCapacity,
            },
          });
        } else if (
          e.target.name == "cmbCOL" ||
          e.target.name == "cmbCOLDisplay" ||
          e.target.name == "cmbSecurity"
        ) {
          let txtSignal = "";
          txtSignal =
            "(1. LOP & COP " +
            (this.state.formData.cmbCOL.length > 0
              ? `with ${this.state.formData.cmbCOL} `
              : "") +
            " " +
            (this.state.formData.cmbCOLDisplay.length > 0
              ? `with ${this.state.formData.cmbCOLDisplay} `
              : "") +
            "& Digital up/down arrows with " +
            (this.state.formData.cmbSecurity == "RFID Card"
              ? "RFID Card input"
              : "Biometric input") +
            " 2. On/ Off switch for Fan & light)";

          this.setState({
            formData: {
              ...this.state.formData,
              txtSignal,
            },
          });
        } else if (e.target.name == "txtRate") {
          let txtAmount = 0,
            txtGST = 0,
            txtTotal = 0;
          txtAmount = Math.round(
            Number(this.state.formData.txtLiftQuantity) *
              Number(this.state.formData.txtRate)
          );
          txtGST = Math.round((Number(txtAmount) * 18) / 100);
          txtTotal = Math.round(Number(txtAmount) + Number(txtGST));
          this.setState({
            formData: {
              ...this.state.formData,
              txtAmount,
              txtGST,
              txtTotal,
            },
          });
        } else if (e.target.name == "cmbCarEnclosure") {
          let etcText =
            this.state.formData.cmbCarEnclosure.length <= 0
              ? ["", ""]
              : this.state.formData.txtCarEnclosureInfo.length > 0
              ? this.state.formData.txtCarEnclosureInfo.split(
                  this.state.formData.PreviousValuecmbCarEnclosure.length > 0
                    ? this.state.formData.PreviousValuecmbCarEnclosure
                    : this.state.formData.cmbCarEnclosure
                )
              : "";

          etcText = [
            etcText[1] == "undefined" ? etcText[0] + " " : etcText[0],
            etcText[1] == undefined || etcText[1] == "undefined"
              ? ""
              : etcText[1],
          ];

          this.setState({
            formData: {
              ...this.state.formData,
              txtCarEnclosureInfo:
                this.state.formData.txtCarEnclosureInfo.length > 0
                  ? etcText[0] +
                    this.state.formData.cmbCarEnclosure +
                    etcText[1]
                  : this.state.formData.cmbCarEnclosure.length > 0
                  ? this.state.formData.cmbCarEnclosure
                  : "",
              PreviousValuecmbCarEnclosure: this.state.formData.cmbCarEnclosure,
            },
          });
        } else if (e.target.name == "cmbCarHeight") {
          this.setState({
            formData: {
              ...this.state.formData,
              txtCarHeight: 0,
            },
          });
        }

        if (
          e.target.name == "cmbDoorType" ||
          e.target.name == "cmbLandingDoorSelection" ||
          e.target.name == "txtWide"
        ) {
          if (
            this.state.formData.cmbDoorType == "AUTO" &&
            this.state.formData.cmbLandingDoorSelection.length > 0 &&
            isValidItem(
              this.state.AutoDoorDetails,
              "vac_subdoortype",
              this.state.formData.cmbLandingDoorSelection
            ) &&
            Number(this.state.formData.txtWide) > 0
          ) {
            this.getDoorWide();
          } else if (
            this.state.formData.cmbDoorType == "MANUAL" &&
            this.state.formData.cmbLandingDoorSelection.length > 0 &&
            Number(this.state.formData.txtWide) > 0
          ) {
            this.getDoorWide();
          }
        }

        if (
          e.target.name == "cmbDoorType" ||
          e.target.name == "cmbLandingDoorSelection" ||
          e.target.name == "txtWide" ||
          e.target.name == "txtDeep" ||
          e.target.name == "cmbLiftType" ||
          e.target.name == "cmbCounter"
        ) {
          if (
            this.state.formData.cmbDoorType.length > 0 &&
            isValidItem(
              this.state.DoorTypes,
              "vac_doortype",
              this.state.formData.cmbDoorType
            ) &&
            LandingDoorSelection.length > 0 &&
            Number(this.state.formData.txtWide) > 0 &&
            Number(this.state.formData.txtDeep) > 0 &&
            this.state.formData.cmbLiftType.length > 0 &&
            isValidItem(
              this.state.LiftTypes,
              "vac_lifttype",
              this.state.formData.cmbLiftType
            ) &&
            this.state.formData.cmbCounter.length > 0 &&
            isValidItem(
              this.state.Counter,
              "vac_counter",
              this.state.formData.cmbCounter
            )
          ) {
            this.cabinInfoDetails(LandingDoorSelection);
          }
        }

        if (
          e.target.name == "cmbDoorType" ||
          e.target.name == "cmbCarDoorEnclosure" ||
          e.target.name == "cmbCarDoorSelection" ||
          e.target.name == "cmbLandingDoorSelection" ||
          e.target.name == "cmbDoorOpening" ||
          e.target.name == "txtOtherWide" ||
          e.target.name == "txtHeight"
        ) {
          let txtDoorOperation = this.state.formData.txtDoorOperation;
          txtDoorOperation =
            this.state.formData.cmbDoorType +
            " Door Operation with " +
            this.state.formData.cmbLandingDoorSelection +
            (this.state.formData.cmbCarDoorSelection.length > 0 &&
            this.state.formData.cmbDoorType == "MANUAL"
              ? ` & Car Door ${this.state.formData.cmbCarDoorSelection}`
              : "");
          this.setState(
            {
              formData: {
                ...this.state.formData,
                txtDoorOperation,
              },
            },
            () => {
              this.setCarAndHoistWay();
            }
          );
        }
      }
    );
  };

  setCarAndHoistWay = () => {
    let txtCarEntrance = `Protected by ${
        this.state.formData.cmbDoorType == "AUTO"
          ? this.state.formData.cmbCarDoorEnclosure
          : this.state.formData.cmbCarDoorSelection
      } ${
        this.state.formData.cmbOpeningSite == "NO" ? ` Car Door - 2 nos` : ""
      },<br />${
        this.state.formData.cmbLandingDoorSelection.length > 0
          ? this.state.formData.cmbLandingDoorSelection + " Door "
          : ""
      }${
        this.state.formData.cmbDoorOpening != "Other"
          ? this.state.formData.cmbDoorOpening
          : this.state.formData.txtOtherWide
      } mm W x ${this.state.formData.txtHeight} mm H. <br />
    <br />`,
      txtHoistWayEntrance = `Protected by ${
        this.state.formData.cmbDoorType == "MANUAL"
          ? this.state.formData.cmbLandingDoorSelection
          : this.state.HoistWayStr
      }
   <br /> ${
     this.state.formData.cmbDoorType == "AUTO"
       ? this.state.formData.cmbLandingDoorSelection
       : ""
   } ${
        this.state.formData.cmbDoorOpening == "Other"
          ? this.state.formData.txtOtherWide
          : this.state.formData.cmbDoorOpening
      } mm W x ${this.state.formData.txtHeight} mm H.`;

    this.setState({
      formData: {
        ...this.state.formData,
        txtCarEntrance,
        txtHoistWayEntrance,
      },
    });
  };

  getDoorWide = () => {
    const reqData = {
      Op: "LoadOpeningWidht",
      vac_doortype: this.state.formData.cmbDoorType,
      vac_subdoortype: this.state.formData.cmbLandingDoorSelection,
      dec_hostwaywide: this.state.formData.txtWide,
    };
    fetchProposal(reqData)
      .then((res) => {
        let DoorOpening =
            res.data.DoorOpeningWidht && res.data.DoorOpeningWidht.length > 0
              ? res.data.DoorOpeningWidht
              : [],
          cmbDoorOpening =
            this.state.formData.cmbDoorType == "MANUAL"
              ? "Other"
              : this.state.formData.cmbDoorOpening;
        this.setState({
          DoorOpening,
          formData: {
            ...this.state.formData,
            cmbDoorOpening,
          },
        });
      })
      .catch((error) => {
        console.log("Unknown error occurred in onFormLoad.", error);
      });
  };

  cabinInfoDetails = (LandingDoorSelection) => {
    const reqData = {
      Op: "GetCabinInfoDetails",
      vac_doortype: this.state.formData.cmbDoorType,
      vac_subdoortype: LandingDoorSelection,
      dec_hostwaywide: this.state.formData.txtWide,
      dec_hostwaydeep: this.state.formData.txtDeep,
      vac_lifttype: this.state.formData.cmbLiftType,
      vac_counterposition: this.state.formData.cmbCounter,
    };
    fetchProposal(reqData)
      .then((res) => {
        let txtNoOfPassenger =
            res.data.bint_noofpassenger &&
            Number(res.data.bint_noofpassenger) > 0
              ? res.data.bint_noofpassenger
              : 0,
          txtCarCarpetArea =
            res.data.dec_carcarpetarea && Number(res.data.dec_carcarpetarea) > 0
              ? res.data.dec_carcarpetarea
              : 0,
          txtCarDeep =
            res.data.dec_carinsidedeep && Number(res.data.dec_carinsidedeep) > 0
              ? res.data.dec_carinsidedeep
              : 0,
          txtCarWide =
            res.data.dec_carinsidewide && Number(res.data.dec_carinsidewide) > 0
              ? res.data.dec_carinsidewide
              : 0,
          txtLoadCapacity =
            res.data.dec_loadcapacity && Number(res.data.dec_loadcapacity) > 0
              ? res.data.dec_loadcapacity
              : 0;
        this.setState({
          formData: {
            ...this.state.formData,
            txtNoOfPassenger,
            txtCarCarpetArea,
            txtCarDeep,
            txtCarWide,
            txtLoadCapacity,
          },
        });
      })
      .catch((error) => {
        console.log("Unknown error occurred in onFormLoad.", error);
      });
  };

  setStateFunCtion = (newValue) => {
    this.setState(
      {
        formData: { ...this.state.formData, value: newValue },
      }
      // () => {
      //   this.changeBtnName(newValue);
      // }
    );
  };

  handleOnChangeTab = (event, newValue) => {
    let value = this.state.formData.value;
    this.setStateFunCtion(newValue);

    // if (value == 1) {
    //   if (this.validateSiteInfo()) {
    //     this.setStateFunCtion(newValue);
    //   }
    // } else if (value == 2) {
    //   if (this.validateMachineInfo()) {
    //     this.setStateFunCtion(newValue);
    //   }
    // } else if (value == 3) {
    //   if (this.validateDoorInfo()) {
    //     this.setStateFunCtion(newValue);
    //   }
    // } else if (value == 4) {
    //   if (this.validateCabinInfo()) {
    //     this.setStateFunCtion(newValue);
    //   }
    // } else if (value == 5) {
    //   if (this.validateControllerInfo()) {
    //     this.setStateFunCtion(newValue);
    //   }
    // } else if (value == 6) {
    //   if (this.validateSpecification()) {
    //     this.setStateFunCtion(newValue);
    //   }
    // }
  };

  handleOnCheckChange = (e) => {
    this.setState(
      {
        formData: { ...this.state.formData, [e.target.name]: e.target.checked },
      },
      () => {
        if (e.target.name == "chkGroundFloor") {
          let txtNoOpening =
            Number(this.state.formData.txtBasement) +
            Number(this.state.formData.txtNoFloor) +
            (this.state.formData.chkGroundFloor == true ? 1 : 0);
          let chkRows = [],
            chkDoorRows = [];
          if (
            this.state.formData.chkGroundFloor == 1 ||
            this.state.formData.chkGroundFloor == "true"
          ) {
            chkRows.push({ vac_floor: "G", int_front: true });
            chkDoorRows.push({
              vac_floor: "G",
              vac_carenclosure: "",
              enableFront: true,
              enableReverse: false,
            });
          } else {
            chkRows = [];
            chkDoorRows = [];
          }

          this.setState({
            formData: {
              ...this.state.formData,
              txtNoOpening: txtNoOpening,
              txtNoStep: txtNoOpening,
              txtFloorHeight: (Number(txtNoOpening) - 1) * 3,
            },
            chkRows,
            chkDoorRows,
            siteRows: [
              ...this.state.floorRows,
              ...chkRows,
              ...this.state.basementRows,
            ],
            doorRows: [
              ...this.state.floorDoorRows,
              ...chkDoorRows,
              ...this.state.basementDoorRows,
            ],
          });
        }
      }
    );
  };

  handleOnSiteColumnChange = (siteColumn) => {
    this.setState({ siteColumn }, () => {
      let HeaderLabelForDoorTable = siteColumn[2].headerCtrlProps.value;

      let doorColumn = this.state.doorColumn.map((col) => {
        if (col.id == "vac_reversecarenclosure") {
          return {
            ...col,
            label: HeaderLabelForDoorTable + " Door Enclosure",
            headerCtrlProps: {
              ...col.headerCtrlProps,
              label: HeaderLabelForDoorTable + " Door Enclosure",
            },
          };
        } else if (col.id == "vac_reversedoorframeenclosure") {
          return {
            ...col,
            label: HeaderLabelForDoorTable + " Door Frame Enclosure",
            headerCtrlProps: {
              ...col.headerCtrlProps,
              label: HeaderLabelForDoorTable + " Door Frame Enclosure",
            },
          };
        } else {
          return col;
        }
      });

      this.setState({
        doorColumn,
        SiteTableHeaderValue: siteColumn[2].headerCtrlProps.value,
      });
    });
  };

  handleOnDoorColumnChange = (Column) => {
    let FrontHeaderValue = "",
      ReverseHeaderValue = "",
      FrontFramedHeader = "",
      ReverseFramedHeader = "",
      firstHeader = this.state.firstHeader,
      SecondHeader = this.state.SecondHeader,
      thirdHeader = this.state.thirdHeader,
      FourHeader = this.state.FourHeader,
      columDataChangeIndex = 0;

    let doorColumn = Column.map((col) => {
      if (col.id === "vac_carenclosure" && col.headerCtrlProps) {
        FrontHeaderValue = col.headerCtrlProps.value;
        firstHeader = col.headerCtrlProps.value;

        columDataChangeIndex =
          FrontHeaderValue != this.state.firstHeader ? 1 : columDataChangeIndex;

        return {
          ...col,
          CtrlProps: {
            ...col.CtrlProps,
            defValue: col.headerCtrlProps.value,
          },
        };
      } else if (col.id === "vac_reversecarenclosure" && col.headerCtrlProps) {
        ReverseHeaderValue = col.headerCtrlProps.value;
        SecondHeader = col.headerCtrlProps.value;

        columDataChangeIndex =
          ReverseHeaderValue != this.state.SecondHeader
            ? 2
            : columDataChangeIndex;

        return {
          ...col,
          CtrlProps: {
            ...col.CtrlProps,
            defValue: col.headerCtrlProps.value,
          },
        };
      } else if (col.id === "vac_doorframeenclosure" && col.headerCtrlProps) {
        FrontFramedHeader = col.headerCtrlProps.value;
        thirdHeader = col.headerCtrlProps.value;

        columDataChangeIndex =
          FrontFramedHeader != this.state.thirdHeader
            ? 3
            : columDataChangeIndex;

        return {
          ...col,
          CtrlProps: {
            ...col.CtrlProps,
            defValue: col.headerCtrlProps.value,
          },
        };
      } else if (
        col.id === "vac_reversedoorframeenclosure" &&
        col.headerCtrlProps
      ) {
        ReverseFramedHeader = col.headerCtrlProps.value;
        FourHeader = col.headerCtrlProps.value;

        columDataChangeIndex =
          ReverseFramedHeader != this.state.FourHeader
            ? 4
            : columDataChangeIndex;

        return {
          ...col,
          CtrlProps: {
            ...col.CtrlProps,
            defValue: col.headerCtrlProps.value,
          },
        };
      }
      return col;
    });

    let doorColumnIfYes = Column.map((col) => {
      if (col.id === "vac_carenclosure" && col.headerCtrlProps) {
        FrontHeaderValue = col.headerCtrlProps.value;
        firstHeader = col.headerCtrlProps.value;

        columDataChangeIndex =
          FrontHeaderValue != this.state.firstHeader ? 1 : columDataChangeIndex;

        return {
          ...col,
          CtrlProps: {
            ...col.CtrlProps,
            defValue: col.headerCtrlProps.value,
          },
        };
      } else if (col.id === "vac_doorframeenclosure" && col.headerCtrlProps) {
        FrontFramedHeader = col.headerCtrlProps.value;
        thirdHeader = col.headerCtrlProps.value;

        columDataChangeIndex =
          FrontFramedHeader != this.state.thirdHeader
            ? 3
            : columDataChangeIndex;

        return {
          ...col,
          CtrlProps: {
            ...col.CtrlProps,
            defValue: col.headerCtrlProps.value,
          },
        };
      }
      return col;
    });

    let doorRows = this.state.doorRows.map((row) => {
      return {
        ...row,
        vac_carenclosure:
          columDataChangeIndex == 1 && row.enableFront == true
            ? FrontHeaderValue
            : row.vac_carenclosure,
        vac_reversecarenclosure:
          columDataChangeIndex == 2 && row.enableReverse == true
            ? ReverseHeaderValue
            : row.vac_reversecarenclosure,
        vac_doorframeenclosure:
          columDataChangeIndex == 3 && row.enableFront == true
            ? FrontFramedHeader
            : row.vac_doorframeenclosure,
        vac_reversedoorframeenclosure:
          columDataChangeIndex == 4 && row.enableReverse == true
            ? ReverseFramedHeader
            : row.vac_reversedoorframeenclosure,
      };
    });

    let str = "",
      enclosureArray = [];

    doorRows.forEach((row) => {
      if (
        !enclosureArray.some(
          (item) => item.vac_carenclosure === row.vac_carenclosure
        )
      ) {
        enclosureArray.push({ vac_carenclosure: row.vac_carenclosure });
      }
    });

    if (
      enclosureArray.length == 1 &&
      enclosureArray[0].vac_carenclosure != null
    ) {
      str += ` ${enclosureArray[0].vac_carenclosure} `;
      str += ` ${
        this.state.formData.cmbGlassDoor == "YES"
          ? "Framed Glass Landing Door On All Floor. "
          : "Landing Door On All Floor"
      }  `;
    } else {
      enclosureArray.map((enclosure) => {
        let No = 0;
        doorRows.map((row) => {
          if (enclosure.vac_carenclosure == row.vac_carenclosure) {
            No++;
            if (No == 1) {
              str += ` ${
                row.vac_carenclosure == null ? "" : row.vac_carenclosure
              } `;
              str += ` ${
                this.state.formData.cmbGlassDoor == "YES"
                  ? "Framed Glass Landing Door on Floor No."
                  : "Landing Door on Floor No."
              }  `;
            }
            str += ` ${row.vac_floor},`;
          }
        });
      });
    }

    this.setState(
      {
        doorColumn,
        doorColumnIfYes,
        doorRows,
        HoistWayStr: str,
        firstHeader,
        SecondHeader,
        thirdHeader,
        FourHeader,
      },
      () => {
        this.setCarAndHoistWay();
      }
    );
  };

  handleOnSiteRowDataChange = (rows) => {
    let doorRows = [];
    this.setState(
      {
        siteRows: rows,
        siteRowsCount: rows.length,
      },
      () => {
        let txtNoOpening = 0;
        this.state.siteRows.map((row) => {
          if (
            (row.int_front === true || row.int_front === 1) &&
            (row.int_reverse === true || row.int_reverse === 1)
          ) {
            txtNoOpening += 2;
          } else if (row.int_front === true || row.int_front === 1) {
            txtNoOpening += 1;
          } else if (row.int_reverse === true || row.int_reverse === 1) {
            txtNoOpening += 1;
          }
          this.state.doorRows.map((doorRow) => {
            if (row.vac_floor == doorRow.vac_floor) {
              doorRows.push({
                ...doorRow,
                enableFront: row.int_front == 1 ? true : false,
                enableReverse: row.int_reverse == 1 ? true : false,
              });
            }
          });
        });

        this.setState({
          formData: {
            ...this.state.formData,
            txtNoOpening: txtNoOpening,
          },
          doorRows,
        });
      }
    );
  };

  handleOnDoorRowDataChange = (rows) => {
    let str = "",
      enclosureArray = [];

    rows.forEach((row) => {
      if (
        !enclosureArray.some(
          (item) => item.vac_carenclosure === row.vac_carenclosure
        )
      ) {
        enclosureArray.push({ vac_carenclosure: row.vac_carenclosure });
      }
    });

    if (
      enclosureArray.length == 1 &&
      enclosureArray[0].vac_carenclosure != null
    ) {
      str += ` ${enclosureArray[0].vac_carenclosure} `;
      str += ` ${
        this.state.formData.cmbGlassDoor == "YES"
          ? "Framed Glass Landing Door On All Floor. "
          : "Landing Door On All Floor"
      }  `;
    } else {
      enclosureArray.map((enclosure) => {
        let No = 0;
        rows.map((row) => {
          if (enclosure.vac_carenclosure == row.vac_carenclosure) {
            No++;
            if (No == 1) {
              str += ` ${
                row.vac_carenclosure == null ? "" : row.vac_carenclosure
              } `;
              str += ` ${
                this.state.formData.cmbGlassDoor == "YES"
                  ? "Framed Glass Landing Door on Floor No."
                  : "Landing Door on Floor No."
              }  `;
            }
            str += ` ${row.vac_floor},`;
          }
        });
      });
    }

    this.setState(
      {
        doorRows: rows,
        doorRowsCount: rows.length,
        HoistWayStr: str,
      },
      () => {
        this.setCarAndHoistWay();
      }
    );
  };

  handleChangeSiteRowsPerPage = (siteRowsPerPage) => {
    this.setState({ siteRowsPerPage });
  };

  handleChangeDoorRowsPerPage = (doorRowsPerPage) => {
    this.setState({ doorRowsPerPage });
  };

  validateSiteInfo = () => {
    let result = false;
    if (this.state.formData.cmbLiftType.length <= 0) {
      this.props.toastErrorMsg("Select Lift Type ", "cmbLiftType");
    } else if (this.state.formData.txtLiftName.length <= 0) {
      this.props.toastErrorMsg("Enter Lift Name", "txtLiftName");
    } else if (Number(this.state.formData.txtNoFloor) <= 0) {
      this.props.toastErrorMsg("Enter No. of Floor", "txtNoFloor");
    } else if (this.state.formData.cmbOpeningSite.length <= 0) {
      this.props.toastErrorMsg(
        "Select All Opening at Same Side",
        "cmbOpeningSite"
      );
    } else if (Number(this.state.formData.txtFloorHeight) <= 0) {
      this.props.toastErrorMsg("Enter Floor Height in Meter", "txtFloorHeight");
    } else if (Number(this.state.formData.txtNoStep) <= 0) {
      this.props.toastErrorMsg("Enter No. of Stops", "txtNoStep");
    } else if (Number(this.state.formData.txtNoOpening) <= 0) {
      this.props.toastErrorMsg("Enter No. of Opening", "txtNoOpening");
    } else if (Number(this.state.formData.txtWide) <= 0) {
      this.props.toastErrorMsg(
        "Enter Wide - Host Way availale (in MM)",
        "txtWide"
      );
    } else if (Number(this.state.formData.txtDeep) <= 0) {
      this.props.toastErrorMsg(
        "Enter Deep - Host Way Available(in MM)",
        "txtDeep"
      );
    } else if (this.state.formData.cmbHoistWay.length <= 0) {
      this.props.toastErrorMsg("Select Hoist Way Structure", "cmbHoistWay");
    } /* else if (this.state.formData.cmbMachineRoomAvailable.length <= 0) {
      this.props.toastErrorMsg(
        "Select Machine Room Available",
        "cmbMachineRoomAvailable"
      );
    }  */ else if (
      this.state.formData.cmbOpeningSite == "NO" &&
      this.state.siteColumn[2].headerCtrlProps.value.length <= 0
    ) {
      this.props.toastErrorMsg("Select Site Opening In Table");
    } else {
      result = true;
    }
    return result;
  };

  validateMachineInfo = () => {
    let result = false;
    if (this.state.formData.cmbMachineType.length <= 0) {
      this.props.toastErrorMsg("Select Machine Type ", "cmbMachineType");
    } else if (
      this.state.formData.cmbMachineRoomAvailable == "YES" &&
      this.state.formData.cmbMachineRoom.length <= 0
    ) {
      this.props.toastErrorMsg("Select Machine Room ", "cmbMachineRoom");
    } else if (
      this.state.formData.cmbMachineRoom.length > 0 &&
      !isValidItem(
        this.state.MachineRoom,
        "vac_machineroom",
        this.state.formData.cmbMachineRoom
      )
    ) {
      this.props.toastErrorMsg("Select Valid Machine Room ", "cmbMachineRoom");
    } else if (this.state.formData.cmbMachinePlacement.length <= 0) {
      this.props.toastErrorMsg(
        "Select Machine Placement",
        "cmbMachinePlacement"
      );
    } else if (
      this.state.formData.cmbMachinePlacement == "Other" &&
      this.state.formData.txtMachineOther.length <= 0
    ) {
      this.props.toastErrorMsg(
        "Enter Machine Placement Other",
        "txtMachineOther"
      );
    } else if (this.state.formData.cmbCounter.length <= 0) {
      this.props.toastErrorMsg("Select Counter Wt Position", "cmbCounter");
    } else {
      result = true;
    }
    return result;
  };

  validateDoorInfo = () => {
    let result = false;
    if (this.state.formData.cmbDoorType.length <= 0) {
      this.props.toastErrorMsg("Select Door Type", "cmbDoorType");
    } else if (this.state.formData.cmbLandingDoorSelection.length <= 0) {
      this.props.toastErrorMsg(
        "Select Landing Door selection",
        "cmbLandingDoorSelection"
      );
    } else if (
      this.state.formData.cmbDoorType == "AUTO" &&
      this.state.formData.cmbLandingDoorSelection.length > 0 &&
      !isValidItem(
        this.state.AutoDoorDetails,
        "vac_subdoortype",
        this.state.formData.cmbLandingDoorSelection
      )
    ) {
      this.props.toastErrorMsg(
        "Select Valid Landing Door selection ",
        "cmbLandingDoorSelection"
      );
    } else if (
      this.state.formData.cmbDoorType == "MANUAL" &&
      this.state.formData.cmbLandingDoorSelection.length > 0 &&
      !isValidItem(
        this.state.ManualDoorDetails,
        "vac_subdoortype",
        this.state.formData.cmbLandingDoorSelection
      )
    ) {
      this.props.toastErrorMsg(
        "Select Valid Landing Door selection ",
        "cmbLandingDoorSelection"
      );
    } else if (
      this.state.formData.cmbDoorType == "MANUAL" &&
      this.state.formData.cmbCarDoorSelection.length <= 0
    ) {
      this.props.toastErrorMsg(
        "Select Car Door Selection",
        "cmbCarDoorSelection"
      );
    } else if (
      this.state.formData.cmbDoorType == "AUTO" &&
      this.state.formData.cmbGlassDoor.length <= 0
    ) {
      this.props.toastErrorMsg("Select Glass Door", "cmbGlassDoor");
    } else if (
      this.state.formData.cmbDoorType == "AUTO" &&
      this.state.formData.cmbVisiblePanel.length <= 0
    ) {
      this.props.toastErrorMsg("Select Vision Panel", "cmbVisiblePanel");
    } /* else if (this.state.formData.txtDoorOperation.length <= 0) {
      this.props.toastErrorMsg("Enter Door Operation", "txtDoorOperation");
    } */ else if (
      this.state.formData.cmbDoorType == "AUTO" &&
      this.state.formData.cmbCarDoorEnclosure.length <= 0
    ) {
      this.props.toastErrorMsg(
        "Select Car Door Enclosure",
        "cmbCarDoorEnclosure"
      );
    } else if (
      this.state.formData.cmbDoorType == "AUTO" &&
      this.state.formData.cmbOpeningSite == "NO" &&
      this.state.formData.cmbReverseCarDoorEnclosure.length <= 0
    ) {
      this.props.toastErrorMsg(
        `Select ${this.state.SiteTableHeaderValue} Car Door Enclosure`,
        "cmbReverseCarDoorEnclosure"
      );
    } else if (
      this.state.formData.cmbDoorType == "AUTO" &&
      this.state.formData.cmbCarDoorEnclosure.length > 0 &&
      !isValidItem(
        this.state.CarEnclosure,
        "vac_carenclosure",
        this.state.formData.cmbCarDoorEnclosure
      )
    ) {
      this.props.toastErrorMsg(
        "Select Valid Car Door Enclosure",
        "cmbCarDoorEnclosure"
      );
    } else if (
      this.state.formData.cmbDoorType == "AUTO" &&
      this.state.formData.cmbOpeningSite == "NO" &&
      this.state.formData.cmbReverseCarDoorEnclosure.length > 0 &&
      !isValidItem(
        this.state.CarEnclosure,
        "vac_carenclosure",
        this.state.formData.cmbReverseCarDoorEnclosure
      )
    ) {
      this.props.toastErrorMsg(
        `Select Valid ${this.state.SiteTableHeaderValue} Car Door Enclosure`,
        "cmbReverseCarDoorEnclosure"
      );
    } else if (this.state.formData.cmbDoorOpening.length <= 0) {
      this.props.toastErrorMsg("Select Door Opening", "cmbDoorOpening");
    } else if (
      this.state.formData.cmbDoorOpening == "Other" &&
      Number(this.state.formData.txtOtherWide) <= 0
    ) {
      this.props.toastErrorMsg("Enter Other Wide", "txtOtherWide");
    } else if (Number(this.state.formData.txtHeight) <= 0) {
      this.props.toastErrorMsg("Enter Height", "txtHeight");
    } else if (
      this.state.formData.cmbDoorType == "MANUAL" &&
      this.state.formData.cmbDoorFramed.length <= 0
    ) {
      this.props.toastErrorMsg("Select Door Framed By", "cmbDoorFramed");
    } else if (
      this.state.formData.cmbDoorType == "AUTO" &&
      !this.doorRowsValidate()
    ) {
    } else {
      result = true;
    }
    return result;
  };

  doorRowsValidate = () => {
    let valid = true;
    for (let row of this.state.doorRows) {
      if (
        row.enableFront == true &&
        (row.vac_carenclosure == null || row.vac_carenclosure == "")
      ) {
        this.props.toastErrorMsg(
          `Select Front Door Enclosure For Floor ${row.vac_floor}`
        );
        valid = false;
        break;
      } else if (
        row.enableFront == true &&
        (row.vac_doorframeenclosure == null || row.vac_doorframeenclosure == "")
      ) {
        this.props.toastErrorMsg(
          `Select Front Door Frame Enclosure For Floor ${row.vac_floor}`
        );
        valid = false;
        break;
      } else if (
        row.enableReverse == true &&
        (row.vac_reversecarenclosure == null ||
          row.vac_reversecarenclosure == "")
      ) {
        this.props.toastErrorMsg(
          `Select ${this.state.SiteTableHeaderValue} Door Enclosure For Floor ${row.vac_floor}`
        );
        valid = false;
        break;
      } else if (
        row.enableReverse == true &&
        (row.vac_reversedoorframeenclosure == null ||
          row.vac_reversedoorframeenclosure == "")
      ) {
        this.props.toastErrorMsg(
          `Select ${this.state.SiteTableHeaderValue} Door Frame Enclosure For Floor ${row.vac_floor}`
        );
        valid = false;
        break;
      }
    }

    return valid;
  };

  validateCabinInfo = () => {
    let result = false;
    if (Number(this.state.formData.txtCarWide) <= 0) {
      this.props.toastErrorMsg("Enter Car Inside (In mm) Wide", "txtCarWide");
    } else if (Number(this.state.formData.txtCarDeep) <= 0) {
      this.props.toastErrorMsg("Enter Car Inside (In mm) Deep", "txtCarDeep");
    } else if (this.state.formData.cmbCarHeight.length <= 0) {
      this.props.toastErrorMsg(
        "Select Car Inside (in mm) Height",
        "cmbCarHeight"
      );
    } else if (
      this.state.formData.cmbCarHeight == "Other" &&
      Number(this.state.formData.txtCarHeight) <= 0
    ) {
      this.props.toastErrorMsg(
        "Enter Car Inside (In mm) Height",
        "txtCarHeight"
      );
    } else if (this.state.formData.cmbCarEnclosure.length <= 0) {
      this.props.toastErrorMsg("Select Car Enclosure", "cmbCarEnclosure");
    } else if (
      this.state.formData.cmbCarEnclosure.length > 0 &&
      !isValidItem(
        this.state.CarEnclosure,
        "vac_carenclosure",
        this.state.formData.cmbCarEnclosure
      )
    ) {
      this.props.toastErrorMsg("Select Valid Car Enclosure", "cmbCarEnclosure");
    } else if (Number(this.state.formData.txtCarCarpetArea) <= 0) {
      this.props.toastErrorMsg("Enter Car Carpet Area", "txtCarCarpetArea");
    } else if (this.state.formData.txtCarEnclosureInfo.length <= 0) {
      this.props.toastErrorMsg(
        "Enter Car Enclosure Info ",
        "txtCarEnclosureInfo"
      );
    } else {
      result = true;
    }
    return result;
  };

  validateControllerInfo = () => {
    let result = false;
    if (this.state.formData.cmbDriveType.length <= 0) {
      this.props.toastErrorMsg("Select Drive Type", "cmbDriveType");
    } else if (this.state.formData.cmbControllerOperation.length <= 0) {
      this.props.toastErrorMsg(
        "Select Controller Operation",
        "cmbControllerOperation"
      );
    } else if (this.state.formData.cmbARD.length <= 0) {
      this.props.toastErrorMsg("Select ARD", "cmbARD");
    } else if (this.state.formData.cmbOLNS.length <= 0) {
      this.props.toastErrorMsg("Select OLNS", "cmbOLNS");
    } else {
      result = true;
    }
    return result;
  };

  validateSpecification = () => {
    let result = false;
    if (
      this.state.formData.cmbLiftType != "GOODS" &&
      Number(this.state.formData.txtNoOfPassenger) <= 0
    ) {
      this.props.toastErrorMsg("Enter No. of Passenger", "txtNoOfPassenger");
    } else if (
      this.state.formData.cmbLiftType == "GOODS" &&
      Number(this.state.formData.txtLoadCapacity) <= 0
    ) {
      this.props.toastErrorMsg("Enter Load Capacity", "txtLoadCapacity");
    } else if (this.state.formData.cmbSpeed.length <= 0) {
      this.props.toastErrorMsg("Select Speed", "cmbSpeed");
    } else if (
      this.state.formData.cmbSpeed.length > 0 &&
      !isValidItem(this.state.Speed, "vac_speed", this.state.formData.cmbSpeed)
    ) {
      this.props.toastErrorMsg("Select Valid Speed", "cmbSpeed");
    } else if (
      this.state.formData.cmbSpeed == "Other" &&
      this.state.formData.txtOtherSpeed.length <= 0
    ) {
      this.props.toastErrorMsg("Enter Other Speed", "txtOtherSpeed");
    } else if (this.state.formData.txtPowerSupply.length <= 0) {
      this.props.toastErrorMsg("Enter Power Supply", "txtPowerSupply");
    } else if (this.state.formData.cmbCOL.length <= 0) {
      this.props.toastErrorMsg("Select COL / LOP", "cmbCOL");
    } else if (this.state.formData.cmbCOLDisplay.length <= 0) {
      this.props.toastErrorMsg(
        "Select COL / LOP Display Type",
        "cmbCOLDisplay"
      );
    } else if (this.state.formData.cmbSecurity.length <= 0) {
      this.props.toastErrorMsg("Select Security", "cmbSecurity");
    } else if (this.state.formData.txtSignal.length <= 0) {
      this.props.toastErrorMsg("Enter Signal", "txtSignal");
    } /*  else if (Number(this.state.formData.txtRate) <= 0) {
      this.props.toastErrorMsg("Enter Rate", "txtRate");
    } */ else {
      result = true;
    }
    return result;
  };

  siteInfo = () => {
    let Floor = "";
    let FinalizeDisabled = this.state.formData.int_status == 4 ? true : false;

    if (Number(this.state.formData.txtBasement) > 0) {
      Array.from({ length: Number(this.state.formData.txtBasement) }).forEach(
        (_, index) => {
          Floor +=
            index == 0
              ? `B${Number(this.state.formData.txtBasement) - index}`
              : ` + B${Number(this.state.formData.txtBasement) - index}`;
        }
      );
    }

    if (
      this.state.formData.chkGroundFloor == 1 &&
      Number(this.state.formData.txtBasement) > 0
    ) {
      Floor += " + G";
    } else if (this.state.formData.chkGroundFloor == 1) {
      Floor += "G";
    }

    if (
      Number(this.state.formData.txtNoFloor) > 0 &&
      (Number(this.state.formData.txtBasement) > 0 ||
        this.state.formData.chkGroundFloor == 1)
    ) {
      Floor += ` + ${this.state.formData.txtNoFloor}`;
    } else if (Number(this.state.formData.txtNoFloor) > 0) {
      Floor += this.state.formData.txtNoFloor;
    }

    let siteRowsCount = this.state.siteRows.length;

    return (
      <>
        <form>
          <Grid
            container
            direction={"row"}
            justifyContent={"flex-start"}
            spacing={2}
            marginTop={1}
          >
            <Grid item>
              <CtCmb
                id="cmbLiftType"
                label="Lift Type*"
                items={this.state.LiftTypes}
                value={this.state.formData.cmbLiftType}
                handleOnChange={this.handleOnChange}
                width={220}
                maxLength={100}
                onKeyDown={this.props.onKeyDown}
                colID="vac_lifttype"
                disabled={FinalizeDisabled}
              />
            </Grid>
            <Grid item>
              <CtTxt
                id="txtLiftName"
                value={this.state.formData.txtLiftName}
                label="Lift Name*"
                handleOnChange={this.handleOnChange}
                maxLength={100}
                width={220}
                onKeyDown={this.props.onKeyDown}
                disabled={FinalizeDisabled}
              />
            </Grid>
            {/* <Grid item>
            <CtCmbEditable
              id="cmbBranch"
              label="Branch"
              items={this.state.LiftTypes}
              value={this.state.formData.cmbBranch}
              handleOnChange={this.handleOnChange}
              width={200}
              maxLength={100}
              onKeyDown={this.props.onKeyDown}
              colID="vac_type"
            />
          </Grid> */}
            <Grid item>
              <CtTxtNum
                id="txtBasement"
                value={this.state.formData.txtBasement}
                label="No Of Basement"
                handleOnChange={this.handleOnChange}
                maxLength={100}
                width={140}
                onKeyDown={this.props.onKeyDown}
                disabled={FinalizeDisabled}
              />
            </Grid>
            <Grid item marginTop={1}>
              <ControlledCheckbox
                id={"chkGroundFloor"}
                label={"Ground Floor"}
                handleCheckChange={this.handleOnCheckChange}
                checkboxColor={"#3179ff"}
                checked={this.state.formData.chkGroundFloor}
                disabled={FinalizeDisabled}
              />
            </Grid>
            <Grid item>
              <CtTxtNum
                id="txtNoFloor"
                value={this.state.formData.txtNoFloor}
                label="No Of Floor*"
                handleOnChange={this.handleOnChange}
                maxLength={100}
                width={140}
                onKeyDown={this.props.onKeyDown}
                disabled={FinalizeDisabled}
              />
            </Grid>
            <Grid item>
              <CtTxt
                id="txtFloor"
                value={Floor}
                label="Floor*"
                handleOnChange={this.handleOnChange}
                maxLength={100}
                width={250}
                disabled={true}
                onKeyDown={this.props.onKeyDown}
              />
            </Grid>
            <Grid item>
              <CtCmb
                id="cmbOpeningSite"
                label="All Opening At Same Side*"
                items={this.state.SiteOpen}
                value={this.state.formData.cmbOpeningSite}
                handleOnChange={this.handleOnChange}
                width={220}
                maxLength={100}
                onKeyDown={this.props.onKeyDown}
                colID="open"
                disabled={FinalizeDisabled}
              />
            </Grid>
            <Grid item>
              <CtTxtNum
                id="txtFloorHeight"
                value={this.state.formData.txtFloorHeight}
                label="Floor Height (mtr Aprox)*"
                handleOnChange={this.handleOnChange}
                maxLength={100}
                width={220}
                onKeyDown={this.props.onKeyDown}
                disabled={FinalizeDisabled}
              />
            </Grid>
            <Grid item>
              <CtTxtNum
                id="txtNoStep"
                value={this.state.formData.txtNoStep}
                label="No. Of Stops*"
                handleOnChange={this.handleOnChange}
                maxLength={100}
                width={140}
                onKeyDown={this.props.onKeyDown}
                disabled={FinalizeDisabled}
              />
            </Grid>
            <Grid item>
              <CtTxtNum
                id="txtNoOpening"
                value={this.state.formData.txtNoOpening}
                label="No Of Opening*"
                handleOnChange={this.handleOnChange}
                maxLength={100}
                width={140}
                onKeyDown={this.props.onKeyDown}
                disabled={FinalizeDisabled}
              />
            </Grid>
            <Grid item>
              <CtTxtAmt
                id="txtWide"
                value={this.state.formData.txtWide}
                label="Wide - Host Way Available (in MM)*"
                handleOnChange={this.handleOnChange}
                maxLength={100}
                width={260}
                onKeyDown={this.props.onKeyDown}
                disabled={FinalizeDisabled}
              />
            </Grid>
            <Grid item>
              <CtTxtAmt
                id="txtDeep"
                value={this.state.formData.txtDeep}
                label="Deep - Host Way Available(in MM)*"
                handleOnChange={this.handleOnChange}
                maxLength={100}
                width={250}
                onKeyDown={this.props.onKeyDown}
                disabled={FinalizeDisabled}
              />
            </Grid>
            <Grid item>
              <CtCmb
                id="cmbHoistWay"
                label="Hoist Way Stucture*"
                items={this.state.HoistWayStructure}
                value={this.state.formData.cmbHoistWay}
                handleOnChange={this.handleOnChange}
                width={224}
                maxLength={100}
                onKeyDown={this.props.onKeyDown}
                colID="vac_hoistwaystructure"
                disabled={FinalizeDisabled}
              />
            </Grid>
            {/* <Grid item>
              <CtCmb
                id="cmbMachineRoomAvailable"
                label="Machine Room Available*"
                items={this.state.MachineRoomAvailable}
                value={this.state.formData.cmbMachineRoomAvailable}
                handleOnChange={this.handleOnChange}
                width={230}
                maxLength={100}
                onKeyDown={this.props.onKeyDown}
                colID="room"
                disabled={FinalizeDisabled}
              />
            </Grid> */}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <CtMultiLineText
                id="txtSiteRemark"
                value={this.state.formData.txtSiteRemark}
                label="Remark"
                handleOnChange={this.handleOnChange}
                maxLength={4000}
                width={"100%"}
                // maxWidth={750}
                disabled={FinalizeDisabled}
              />
            </Grid>
          </Grid>

          {/* </Grid> */}
          {this.state.formData.tableHide == true ? (
            ""
          ) : (
            <Grid container justifyContent={"flex-start"} marginTop={2}>
              <Grid item>
                <TableList
                  noMobileLayout={true}
                  columns={this.state.siteColumn}
                  rows={this.state.siteRows}
                  rowsCount={siteRowsCount}
                  rowsPerPage={this.state.siteRowsPerPage}
                  handleChangeRowsPerPage={this.handleChangeSiteRowsPerPage}
                  keyColumn={this.state.keyColumn}
                  handleOnRowDataChange={this.handleOnSiteRowDataChange}
                  handleOnColumnChange={this.handleOnSiteColumnChange}

                  // provideSearch={true}
                />
              </Grid>
            </Grid>
          )}
        </form>
      </>
    );
  };

  machineRoom = () => {
    let FinalizeDisabled = this.state.formData.int_status == 4 ? true : false;

    return (
      <form>
        <Grid
          container
          direction={"row"}
          justifyContent={"flex-start"}
          spacing={2}
          marginTop={1}
        >
          <Grid item>
            <CtCmb
              id="cmbMachineType"
              label="Machine Type*"
              items={this.state.MachineType}
              value={this.state.formData.cmbMachineType}
              handleOnChange={this.handleOnChange}
              width={230}
              maxLength={100}
              onKeyDown={this.props.onKeyDown}
              colID="vac_machine"
              disabled={FinalizeDisabled}
            />
          </Grid>
          <Grid item>
            <CtCmb
              id="cmbMachineRoom"
              label="Machine Room*"
              items={this.state.MachineRoom}
              value={this.state.formData.cmbMachineRoom}
              handleOnChange={this.handleOnChange}
              width={230}
              maxLength={100}
              onKeyDown={this.props.onKeyDown}
              colID="vac_machineroom"
              disabled={
                this.state.formData.cmbMachineRoomAvailable == "NO"
                  ? true
                  : FinalizeDisabled
              }
            />
          </Grid>
          <Grid item>
            <CtCmb
              id="cmbMachinePlacement"
              label="Machine Placement*"
              items={this.state.MachinePlacement}
              value={this.state.formData.cmbMachinePlacement}
              handleOnChange={this.handleOnChange}
              width={230}
              maxLength={100}
              onKeyDown={this.props.onKeyDown}
              colID="vac_machineplacement"
              disabled={FinalizeDisabled}
            />
          </Grid>
          {this.state.formData.cmbMachinePlacement == "Other" ? (
            <Grid item>
              <CtTxt
                id="txtMachineOther"
                label="Machine Placement Other*"
                value={this.state.formData.txtMachineOther}
                handleOnChange={this.handleOnChange}
                width={230}
                maxLength={100}
                onKeyDown={this.props.onKeyDown}
                disabled={FinalizeDisabled}
              />
            </Grid>
          ) : (
            ""
          )}
          <Grid item>
            <CtCmb
              id="cmbCounter"
              label="Counter Wt. Position*"
              items={this.state.Counter}
              value={this.state.formData.cmbCounter}
              handleOnChange={this.handleOnChange}
              width={230}
              maxLength={100}
              onKeyDown={this.props.onKeyDown}
              colID="vac_counter"
              disabled={FinalizeDisabled}
            />
          </Grid>
          <Grid
            item
            lg={this.state.formData.cmbMachinePlacement == "Other" ? 8 : 12}
            md={12}
            sm={12}
            xs={12}
          >
            <p
              style={{
                color: "#666666",
                margin: "0",
                padding: "0",
                fontSize: "14px",
              }}
            >
              Machine Remark
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                padding: "0",
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: this.state.formData.txtMachineRemark,
                }}
                style={{ margin: "0", padding: "0" }}
              />
              <div style={{ margin: "0", padding: "0" }}>
                <Edit
                  className={"child-button"}
                  style={{
                    border: "none",
                    cursor: "pointer",
                    // color: "#4687FF",
                  }}
                  onClick={() => {
                    this.openRichTextBox(
                      "Machine Remark",
                      "MachineRemark",
                      this.state.formData.txtMachineRemark
                    );
                  }}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </form>
    );
  };

  doorInfo = () => {
    let doorTypeManual = this.state.formData.cmbDoorType === "MANUAL",
      doorTypeAuto = this.state.formData.cmbDoorType === "AUTO",
      doorRowsCount = this.state.doorRows.length,
      CarDoorSelection = this.state.DoorDetails.filter((row) => {
        if (this.state.formData.cmbDoorType == row.vac_doortype.trim()) {
          return row.vac_cardoorselection;
        }
      });

    let FinalizeDisabled = this.state.formData.int_status == 4 ? true : false;

    return (
      <>
        <form>
          <Grid
            container
            direction={"row"}
            justifyContent={"flex-start"}
            spacing={2}
            marginTop={1}
          >
            <Grid item>
              <CtCmb
                id="cmbDoorType"
                label="Door Type*"
                items={this.state.DoorTypes}
                value={this.state.formData.cmbDoorType}
                handleOnChange={this.handleOnChange}
                width={180}
                maxLength={100}
                onKeyDown={this.props.onKeyDown}
                colID="vac_doortype"
                nextCtrlID={"cmbLandingDoorSelection"}
                disabled={FinalizeDisabled}
              />
            </Grid>
            <Grid item>
              <CtCmb
                id="cmbLandingDoorSelection"
                label="Landing Door Selection*"
                items={
                  this.state.formData.cmbDoorType == "AUTO"
                    ? this.state.AutoDoorDetails
                    : this.state.ManualDoorDetails
                }
                value={this.state.formData.cmbLandingDoorSelection}
                handleOnChange={this.handleOnChange}
                width={250}
                maxLength={100}
                onKeyDown={this.props.onKeyDown}
                colID="vac_subdoortype"
                nextCtrlID={"cmbCarDoorSelection"}
                // dataFilter={[
                //   {
                //     filterColID: "vac_doortype",
                //     value: this.state.formData.cmbDoorType,
                //   },
                // ]}
                disabled={FinalizeDisabled}
                strictDataFilter={true}
              />
            </Grid>
            {doorTypeManual ? (
              <Grid item>
                <CtCmb
                  id="cmbCarDoorSelection"
                  label="Car Door Selection*"
                  items={getRowColData(
                    CarDoorSelection,
                    "vac_cardoorselection"
                  )}
                  value={this.state.formData.cmbCarDoorSelection}
                  handleOnChange={this.handleOnChange}
                  width={180}
                  maxLength={100}
                  onKeyDown={this.props.onKeyDown}
                  colID="vac_cardoorselection"
                  nextCtrlID={"cmbGlassDoor"}
                  disabled={FinalizeDisabled}
                />
              </Grid>
            ) : (
              ""
            )}
            {doorTypeAuto ? (
              <>
                <Grid item>
                  <CtCmb
                    id="cmbGlassDoor"
                    label="Glass Door*"
                    items={this.state.GlassDoor}
                    value={this.state.formData.cmbGlassDoor}
                    handleOnChange={this.handleOnChange}
                    width={180}
                    maxLength={100}
                    onKeyDown={this.props.onKeyDown}
                    colID="vac_glass_door"
                    nextCtrlID={"cmbVisiblePanel"}
                    disabled={FinalizeDisabled}
                  />
                </Grid>

                <Grid item>
                  <CtCmb
                    id="cmbVisiblePanel"
                    label="Vision Panel*"
                    items={this.state.GlassDoor}
                    value={this.state.formData.cmbVisiblePanel}
                    handleOnChange={this.handleOnChange}
                    width={180}
                    maxLength={100}
                    onKeyDown={this.props.onKeyDown}
                    colID="vac_glass_door"
                    disabled={FinalizeDisabled}
                  />
                </Grid>
              </>
            ) : (
              ""
            )}
            {/* <Grid item lg={12} md={12} sm={12} xs={12}>
              <CtMultiLineText
                id="txtDoorOperation"
                value={this.state.formData.txtDoorOperation}
                label="Door Operation"
                handleOnChange={this.handleOnChange}
                maxLength={500}
                width={"100%"}
                disabled={FinalizeDisabled}
              />
            </Grid> */}

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <p
                style={{
                  color: "#666666",
                  margin: "0",
                  padding: "0",
                  fontSize: "14px",
                }}
              >
                Door Operation
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  padding: "0",
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.state.formData.txtDoorOperation,
                  }}
                  style={{ margin: "0", padding: "0" }}
                />
                <div style={{ margin: "0", padding: "0" }}>
                  <Edit
                    className={"child-button"}
                    style={{
                      border: "none",
                      cursor: "pointer",
                      // color: "#4687FF",
                    }}
                    onClick={() => {
                      this.openRichTextBox(
                        "Door Operation",
                        "DoorOperation",
                        this.state.formData.txtDoorOperation
                      );
                    }}
                  />
                </div>
              </div>
            </Grid>

            {doorTypeAuto ? (
              <>
                <Grid item>
                  <CtCmbEditable
                    id="cmbCarDoorEnclosure"
                    label="Front Car Door Enclosure*"
                    items={this.state.CarEnclosure}
                    value={this.state.formData.cmbCarDoorEnclosure}
                    handleOnChange={this.handleOnChange}
                    width={230}
                    maxLength={100}
                    onKeyDown={this.props.onKeyDown}
                    colID="vac_carenclosure"
                    nextCtrlID={"cmbDoorOpening"}
                    disabled={FinalizeDisabled}
                  />
                </Grid>
                {this.state.formData.cmbOpeningSite == "NO" ? (
                  <Grid item>
                    <CtCmbEditable
                      id="cmbReverseCarDoorEnclosure"
                      label={
                        this.state.SiteTableHeaderValue + " Car Door Enclosure*"
                      }
                      items={this.state.CarEnclosure}
                      value={this.state.formData.cmbReverseCarDoorEnclosure}
                      handleOnChange={this.handleOnChange}
                      width={230}
                      maxLength={100}
                      onKeyDown={this.props.onKeyDown}
                      colID="vac_carenclosure"
                      nextCtrlID={"cmbDoorOpening"}
                      disabled={FinalizeDisabled}
                    />
                  </Grid>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
            <Grid item>
              <CtCmb
                id="cmbDoorOpening"
                label="Door Opening Wide*"
                items={this.state.DoorOpening}
                value={this.state.formData.cmbDoorOpening}
                handleOnChange={this.handleOnChange}
                width={190}
                maxLength={100}
                onKeyDown={this.props.onKeyDown}
                colID="vac_door_opening"
                disabled={FinalizeDisabled}
              />
            </Grid>
            {this.state.formData.cmbDoorOpening == "Other" ? (
              <Grid item>
                <CtTxtAmt
                  id="txtOtherWide"
                  value={this.state.formData.txtOtherWide}
                  label="Door Opening Other Wide*"
                  handleOnChange={this.handleOnChange}
                  maxLength={100}
                  width={220}
                  onKeyDown={this.props.onKeyDown}
                  nextCtrlID={"txtHeight"}
                  disabled={FinalizeDisabled}
                />
              </Grid>
            ) : (
              ""
            )}
            <Grid item>
              <CtTxtAmt
                id="txtHeight"
                value={this.state.formData.txtHeight}
                label="Height*"
                handleOnChange={this.handleOnChange}
                maxLength={100}
                width={180}
                onKeyDown={this.props.onKeyDown}
                disabled={FinalizeDisabled}
                nextCtrlID={"cmbDoorFramed"}
              />
            </Grid>

            {doorTypeManual ? (
              <Grid item>
                <CtCmb
                  id="cmbDoorFramed"
                  label="Door Framed By*"
                  items={this.state.DoorFramed}
                  value={this.state.formData.cmbDoorFramed}
                  handleOnChange={this.handleOnChange}
                  width={230}
                  maxLength={100}
                  colID="vac_door_framed"
                  disabled={FinalizeDisabled}
                />
              </Grid>
            ) : (
              ""
            )}
          </Grid>
          <Grid
            container
            direction={"row"}
            // justifyContent={"space-between"}
            // marginLeft={2}
            marginTop={2}
            spacing={3}
          >
            <Grid item lg={5} sm={5} md={5} paddingBottom={3}>
              {/* <Typography>
                <b>CAR ENTRANCE</b>
                <br /> Protected by{" "}
                {doorTypeAuto
                  ? this.state.formData.cmbCarDoorEnclosure
                  : this.state.formData.cmbCarDoorSelection}
                ,
                <br />
                {this.state.formData.cmbLandingDoorSelection.length > 0
                  ? this.state.formData.cmbLandingDoorSelection + " Door"
                  : ""}{" "}
                {this.state.formData.cmbDoorOpening != "Other"
                  ? this.state.formData.cmbDoorOpening
                  : this.state.formData.txtOtherWide}{" "}
                mm W x {this.state.formData.txtHeight} mm H. , <br />
                <br />
                <b>HOIST WAY ENTRANCE</b> <br /> Protected by{" "}
                {doorTypeManual
                  ? this.state.formData.cmbLandingDoorSelection
                  : this.state.HoistWayStr}
                <br />
                {doorTypeAuto
                  ? this.state.formData.cmbLandingDoorSelection
                  : ""}{" "}
                {this.state.formData.cmbDoorOpening == "Other"
                  ? this.state.formData.txtOtherWide
                  : this.state.formData.cmbDoorOpening}{" "}
                mm W x {this.state.formData.txtHeight} mm H.
              </Typography> */}

              <Typography>
                <b>CAR ENTRANCE</b>
              </Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  gap: "10px",
                  padding: "0",
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.state.formData.txtCarEntrance,
                  }}
                  style={{ margin: "0", padding: "0" }}
                />

                <div style={{ margin: "0", padding: "0" }}>
                  <Edit
                    className={"child-button"}
                    style={{
                      border: "none",
                      cursor: "pointer",
                      // color: "#4687FF",
                    }}
                    onClick={() => {
                      this.openRichTextBox(
                        "Car Entrance",
                        "CarEntrance",
                        this.state.formData.txtCarEntrance
                      );
                    }}
                  />
                </div>
              </div>

              <Typography>
                <b>HOIST WAY ENTRANCE</b>
              </Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  gap: "10px",
                  padding: "0",
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.state.formData.txtHoistWayEntrance,
                  }}
                  style={{ margin: "0", padding: "0" }}
                />
                <div style={{ margin: "0", padding: "0" }}>
                  <Edit
                    className={"child-button"}
                    style={{
                      border: "none",
                      cursor: "pointer",
                      // color: "#4687FF",
                    }}
                    onClick={() => {
                      this.openRichTextBox(
                        "Hoist Way Entrance",
                        "HoistWayEntrance",
                        this.state.formData.txtHoistWayEntrance
                      );
                    }}
                  />
                </div>
              </div>
            </Grid>
            {doorTypeAuto ? (
              <Grid item style={{ maxWidth: "100%" }}>
                <TableList
                  noMobileLayout={false}
                  columns={
                    this.state.formData.cmbOpeningSite == "YES"
                      ? this.state.doorColumnIfYes
                      : this.state.doorColumn
                  }
                  TableHeaderText={"Table Header Control"}
                  rows={this.state.doorRows}
                  rowsCount={doorRowsCount}
                  rowsPerPage={this.state.doorRowsPerPage}
                  handleChangeRowsPerPage={this.handleChangeDoorRowsPerPage}
                  keyColumn={this.state.keyColumn}
                  handleOnRowDataChange={this.handleOnDoorRowDataChange}
                  handleOnColumnChange={this.handleOnDoorColumnChange}
                />
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </form>
      </>
    );
  };

  cabinInfo = () => {
    let FinalizeDisabled = this.state.formData.int_status == 4 ? true : false,
      CarInsideHeight = this.state.formData.cmbCarHeight === "Other";

    return (
      <form>
        <Grid
          container
          direction={"row"}
          justifyContent={"flex-start"}
          spacing={2}
          marginTop={1}
        >
          <Grid item>
            <CtTxtAmt
              id="txtCarWide"
              value={this.state.formData.txtCarWide}
              label="Car Inside (In mm) Wide*"
              handleOnChange={this.handleOnChange}
              maxLength={100}
              width={230}
              onKeyDown={this.props.onKeyDown}
              disabled={FinalizeDisabled}
            />
          </Grid>
          <Grid item>
            <CtTxtAmt
              id="txtCarDeep"
              value={this.state.formData.txtCarDeep}
              label="Car Inside (In mm) Deep*"
              handleOnChange={this.handleOnChange}
              maxLength={100}
              width={230}
              onKeyDown={this.props.onKeyDown}
              disabled={FinalizeDisabled}
            />
          </Grid>
          <Grid item>
            <CtCmb
              id="cmbCarHeight"
              label="Car Inside (In mm) Height*"
              items={this.state.CarInsideHeight}
              value={this.state.formData.cmbCarHeight}
              handleOnChange={this.handleOnChange}
              width={230}
              maxLength={100}
              onKeyDown={this.props.onKeyDown}
              colID="vac_carinsideheight"
              disabled={FinalizeDisabled}
            />
          </Grid>
          {CarInsideHeight ? (
            <Grid item>
              <CtTxtAmt
                id="txtCarHeight"
                value={this.state.formData.txtCarHeight}
                label="Car Inside (In mm) Height*"
                handleOnChange={this.handleOnChange}
                maxLength={100}
                width={230}
                onKeyDown={this.props.onKeyDown}
                disabled={FinalizeDisabled}
              />
            </Grid>
          ) : (
            ""
          )}

          <Grid item>
            <CtCmbEditable
              id="cmbCarEnclosure"
              label="Car Enclosure*"
              items={this.state.CarEnclosure}
              value={this.state.formData.cmbCarEnclosure}
              handleOnChange={this.handleOnChange}
              width={230}
              maxLength={100}
              onKeyDown={this.props.onKeyDown}
              colID="vac_carenclosure"
              disabled={FinalizeDisabled}
              dataFilter={[
                {
                  filterColID: "int_carcabin",
                  value: 1,
                },
              ]}
            />
          </Grid>
          <Grid item>
            {/* Car Inside Wide x Car Inside Deep) / 10,00,000 */}
            <CtTxt
              id="txtCarCarpetArea"
              value={this.state.formData.txtCarCarpetArea}
              label="Car Carpet Area (sq. mtr)*"
              handleOnChange={this.handleOnChange}
              maxLength={100}
              disabled={true}
              width={230}
            />
          </Grid>
          {/* <Grid
            item
            lg={this.state.formData.cmbMachinePlacement == "Other" ? 8 : 12}
            md={12}
            sm={12}
            xs={12}
          >
            <CtMultiLineText
              id="txtCarEnclosureInfo"
              value={this.state.formData.txtCarEnclosureInfo}
              label="Car Enclosure Info"
              handleOnChange={this.handleOnChange}
              maxLength={1000}
              width={"100%"}
              disabled={FinalizeDisabled}
            />
          </Grid> */}

          <Grid
            item
            lg={this.state.formData.cmbMachinePlacement == "Other" ? 8 : 12}
            md={12}
            sm={12}
            xs={12}
          >
            <p
              style={{
                color: "#666666",
                margin: "0",
                padding: "0",
                fontSize: "14px",
              }}
            >
              Car Enclosure Info
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                padding: "0",
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: this.state.formData.txtCarEnclosureInfo,
                }}
                style={{ margin: "0", padding: "0" }}
              />
              <div style={{ margin: "0", padding: "0" }}>
                <Edit
                  className={"child-button"}
                  style={{
                    border: "none",
                    cursor: "pointer",
                    // color: "#4687FF",
                  }}
                  onClick={() => {
                    this.openRichTextBox(
                      "Car Enclosure Info",
                      "CarEnclosureInfo",
                      this.state.formData.txtCarEnclosureInfo
                    );
                  }}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </form>
    );
  };

  controllerInfo = () => {
    let FinalizeDisabled = this.state.formData.int_status == 4 ? true : false;
    return (
      <form>
        <Grid
          container
          direction={"row"}
          justifyContent={"flex-start"}
          spacing={2}
          marginTop={1}
        >
          <Grid item>
            <CtCmb
              id="cmbDriveType"
              label="Drive Type*"
              items={this.state.DriveType}
              value={this.state.formData.cmbDriveType}
              handleOnChange={this.handleOnChange}
              width={240}
              maxLength={100}
              onKeyDown={this.props.onKeyDown}
              colID="vac_drive"
              disabled={FinalizeDisabled}
            />
          </Grid>
          <Grid item>
            <CtCmb
              id="cmbControllerOperation"
              label="Controller Operation*"
              items={this.state.ControllerOperation}
              value={this.state.formData.cmbControllerOperation}
              handleOnChange={this.handleOnChange}
              width={240}
              maxLength={100}
              onKeyDown={this.props.onKeyDown}
              colID="vac_controller_operation"
              disabled={FinalizeDisabled}
            />
          </Grid>
          <Grid item>
            <CtCmb
              id="cmbARD"
              label="ARD*"
              items={this.state.SiteOpen}
              value={this.state.formData.cmbARD}
              handleOnChange={this.handleOnChange}
              width={115}
              maxLength={100}
              onKeyDown={this.props.onKeyDown}
              colID="open"
              disabled={FinalizeDisabled}
            />
          </Grid>
          <Grid item>
            <CtCmb
              id="cmbOLNS"
              label="OLNS*"
              items={this.state.SiteOpen}
              value={this.state.formData.cmbOLNS}
              handleOnChange={this.handleOnChange}
              width={115}
              maxLength={100}
              onKeyDown={this.props.onKeyDown}
              colID="open"
              disabled={FinalizeDisabled}
            />
          </Grid>
          {/* <Grid item lg={12} md={12} sm={12} xs={12}>
            <CtMultiLineText
              id="txtControlOperation"
              value={this.state.formData.txtControlOperation}
              label="Control Operation"
              handleOnChange={this.handleOnChange}
              maxLength={4000}
              width={"100%"}
              maxWidth={760}
              disabled={FinalizeDisabled}
            />
          </Grid> */}
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <p
              style={{
                color: "#666666",
                margin: "0",
                padding: "0",
                fontSize: "14px",
              }}
            >
              Control Operation
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                padding: "0",
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: this.state.formData.txtControlOperation,
                }}
                style={{ margin: "0", padding: "0" }}
              />
              <div style={{ margin: "0", padding: "0" }}>
                <Edit
                  className={"child-button"}
                  style={{
                    border: "none",
                    cursor: "pointer",
                    // color: "#4687FF",
                  }}
                  onClick={() => {
                    this.openRichTextBox(
                      "Control Operation",
                      "ControlOperation",
                      this.state.formData.txtControlOperation
                    );
                  }}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </form>
    );
  };

  liftSpecification = () => {
    let FinalizeDisabled = this.state.formData.int_status == 4 ? true : false;

    return (
      <>
        <form>
          <Grid
            container
            direction={"row"}
            justifyContent={"flex-start"}
            spacing={2}
            marginTop={1}
          >
            <Grid item>
              <CtTxtNum
                id="txtNoOfPassenger"
                value={this.state.formData.txtNoOfPassenger}
                label="No Of Passenger*"
                handleOnChange={this.handleOnChange}
                maxLength={100}
                width={150}
                onKeyDown={this.props.onKeyDown}
                disabled={
                  this.state.formData.cmbLiftType == "GOODS"
                    ? true
                    : FinalizeDisabled
                }
              />
            </Grid>
            <Grid item>
              <CtTxtAmt
                id="txtLoadCapacity"
                value={this.state.formData.txtLoadCapacity}
                label="Load Capacity*"
                handleOnChange={this.handleOnChange}
                maxLength={100}
                disabled={
                  this.state.formData.cmbLiftType != "GOODS"
                    ? true
                    : FinalizeDisabled
                }
                width={240}
                onKeyDown={this.props.onKeyDown}
              />
            </Grid>
            <Grid item>
              <CtCmbEditable
                id="cmbSpeed"
                label="Speed*"
                items={this.state.Speed}
                value={this.state.formData.cmbSpeed}
                handleOnChange={this.handleOnChange}
                width={130}
                maxLength={100}
                onKeyDown={this.props.onKeyDown}
                colID="vac_speed"
                disabled={FinalizeDisabled}
              />
            </Grid>
            {this.state.formData.cmbSpeed == "Other" ? (
              <Grid item>
                <CtTxtAmt
                  id="txtOtherSpeed"
                  value={this.state.formData.txtOtherSpeed}
                  label="Other Speed*"
                  handleOnChange={this.handleOnChange}
                  maxLength={100}
                  width={120}
                  onKeyDown={this.props.onKeyDown}
                  disabled={FinalizeDisabled}
                />
              </Grid>
            ) : (
              ""
            )}
          </Grid>
          <Grid
            container
            direction={"row"}
            justifyContent={"flex-start"}
            spacing={2}
            marginTop={1}
          >
            {/* <Grid item lg={8} md={12} sm={12} xs={12}>
              <CtMultiLineText
                id="txtPowerSupply"
                value={this.state.formData.txtPowerSupply}
                label="Power Supply*"
                handleOnChange={this.handleOnChange}
                maxLength={500}
                width={"100%"}
                disabled={FinalizeDisabled}
              />
            </Grid> */}

            <Grid item lg={8} md={12} sm={12} xs={12}>
              <p
                style={{
                  color: "#666666",
                  margin: "0",
                  padding: "0",
                  fontSize: "14px",
                }}
              >
                Power Supply
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  padding: "0",
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.state.formData.txtPowerSupply,
                  }}
                  style={{ margin: "0", padding: "0" }}
                />
                <div style={{ margin: "0", padding: "0" }}>
                  <Edit
                    className={"child-button"}
                    style={{
                      border: "none",
                      cursor: "pointer",
                      // color: "#4687FF",
                    }}
                    onClick={() => {
                      this.openRichTextBox(
                        "Power Supply",
                        "PowerSupply",
                        this.state.formData.txtPowerSupply
                      );
                    }}
                  />
                </div>
              </div>
            </Grid>
            <Grid item>
              <CtCmb
                id="cmbCOL"
                label="COL/LOP*"
                items={this.state.Col}
                value={this.state.formData.cmbCOL}
                handleOnChange={this.handleOnChange}
                width={150}
                maxLength={100}
                onKeyDown={this.props.onKeyDown}
                colID="vac_col"
                disabled={FinalizeDisabled}
              />
            </Grid>
            <Grid item>
              <CtCmb
                id="cmbCOLDisplay"
                label="COL/LOP Display Type*"
                items={this.state.COLDisplay}
                value={this.state.formData.cmbCOLDisplay}
                handleOnChange={this.handleOnChange}
                width={240}
                maxLength={100}
                onKeyDown={this.props.onKeyDown}
                colID="vac_displaytype"
                disabled={FinalizeDisabled}
              />
            </Grid>
            <Grid item>
              <CtCmb
                id="cmbSecurity"
                label="Security*"
                items={this.state.Security}
                value={this.state.formData.cmbSecurity}
                handleOnChange={this.handleOnChange}
                width={150}
                maxLength={100}
                onKeyDown={this.props.onKeyDown}
                colID="vac_security"
                disabled={FinalizeDisabled}
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction={"row"}
            justifyContent={"flex-start"}
            spacing={2}
            marginTop={1}
          >
            {/* <Grid item lg={9.9} md={12} sm={12} xs={12}>
              <CtMultiLineText
                id="txtSignal"
                value={this.state.formData.txtSignal}
                label="Signal*"
                handleOnChange={this.handleOnChange}
                maxLength={500}
                width={"100%"}
                maxWidth={"100%"}
                disabled={FinalizeDisabled}
              />
            </Grid> */}

            <Grid item lg={9.9} md={12} sm={12} xs={12}>
              <p
                style={{
                  color: "#666666",
                  margin: "0",
                  padding: "0",
                  fontSize: "14px",
                }}
              >
                Signal
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  padding: "0",
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.state.formData.txtSignal,
                  }}
                  style={{ margin: "0", padding: "0" }}
                />
                <div style={{ margin: "0", padding: "0" }}>
                  <Edit
                    className={"child-button"}
                    style={{
                      border: "none",
                      cursor: "pointer",
                      // color: "#4687FF",
                    }}
                    onClick={() => {
                      this.openRichTextBox(
                        "Signal",
                        "Signal",
                        this.state.formData.txtSignal
                      );
                    }}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            direction={"row"}
            justifyContent={"flex-start"}
            spacing={2}
            marginTop={1}
          >
            <Grid item>
              <CtTxtAmt
                id="txtLiftQuantity"
                value={this.state.formData.txtLiftQuantity}
                label="Lift Quantity*"
                handleOnChange={this.handleOnChange}
                maxLength={100}
                disabled={true}
                width={120}
                onKeyDown={this.props.onKeyDown}
              />
            </Grid>
            <Grid item>
              <CtTxtAmt
                id="txtRate"
                value={this.state.formData.txtRate}
                label="Rate"
                handleOnChange={this.handleOnChange}
                maxLength={100}
                width={200}
                onKeyDown={this.props.onKeyDown}
                disabled={FinalizeDisabled}
                RupeeSymbol={true}
              />
            </Grid>
            <Grid item>
              <CtTxtAmt
                id="txtAmount"
                value={this.state.formData.txtAmount}
                label="Amount*"
                handleOnChange={this.handleOnChange}
                maxLength={100}
                width={170}
                onKeyDown={this.props.onKeyDown}
                disabled={true}
                RupeeSymbol={true}
              />
            </Grid>
            <Grid item>
              <CtTxtAmt
                id="txtGST"
                value={this.state.formData.txtGST}
                label="18% GST*"
                handleOnChange={this.handleOnChange}
                maxLength={100}
                width={150}
                onKeyDown={this.props.onKeyDown}
                disabled={true}
                RupeeSymbol={true}
              />
            </Grid>
            <Grid item>
              <CtTxtAmt
                id="txtTotal"
                value={this.state.formData.txtTotal}
                label="Total*"
                handleOnChange={this.handleOnChange}
                maxLength={100}
                width={170}
                disabled={true}
                onKeyDown={this.props.onKeyDown}
                RupeeSymbol={true}
              />
            </Grid>
          </Grid>
        </form>
      </>
    );
  };

  nextBtnClick = () => {
    let tabNum = "",
      currTabNum = this.state.formData.value,
      save = false;
    if (currTabNum == "1") {
      if (this.validateSiteInfo()) {
        tabNum = "2";
        this.saveMoreIfo();
      } else {
        tabNum = "1";
      }
    } else if (currTabNum == "2") {
      if (this.validateMachineInfo()) {
        tabNum = "3";
        this.saveMoreIfo();
      } else {
        tabNum = "2";
      }
    } else if (currTabNum == "3") {
      if (this.validateDoorInfo()) {
        tabNum = "4";
        this.saveMoreIfo();
      } else {
        tabNum = "3";
      }
    } else if (currTabNum == "4") {
      if (this.validateCabinInfo()) {
        tabNum = "5";
        this.saveMoreIfo();
      } else {
        tabNum = "4";
      }
    } else if (currTabNum == "5") {
      if (this.validateControllerInfo()) {
        tabNum = "6";
        this.saveMoreIfo();
      } else {
        tabNum = "5";
      }
    } else if (currTabNum == "6") {
      tabNum = "6";
      if (this.validateSpecification()) {
        save = true;
      }
    }

    // this.changeBtnName(tabNum);

    this.setState(
      {
        formData: {
          ...this.state.formData,
          value: tabNum,
        },
      },
      () => {
        if (save == true) {
          this.save();
        }
      }
    );
  };

  previousBtnClick = () => {
    let tabNum = "",
      currTabNum = this.state.formData.value;
    if (currTabNum == "2") {
      tabNum = "1";
    } else if (currTabNum == "3") {
      tabNum = "2";
    } else if (currTabNum == "4") {
      tabNum = "3";
    } else if (currTabNum == "5") {
      tabNum = "4";
    } else if (currTabNum == "6") {
      tabNum = "5";
    }

    // this.changeBtnName(tabNum);

    this.setState({
      formData: {
        ...this.state.formData,
        value: tabNum,
      },
    });
  };

  changeBtnName = (newValue) => {
    let nextBtnName = "Continue To Machine Room",
      previousBtnName = "";
    if (newValue === "2") {
      nextBtnName = "Continue Door Info";
      previousBtnName = "Site Info";
    } else if (newValue === "3") {
      nextBtnName = "Continue Cabin Info";
      previousBtnName = "Machine Room";
    } else if (newValue === "4") {
      nextBtnName = "Continue Controller Info";
      previousBtnName = "Door Info";
    } else if (newValue === "5") {
      nextBtnName = "Continue Lift Specification";
      previousBtnName = "Cabin Info";
    } else if (newValue === "6") {
      nextBtnName = "Save";
      previousBtnName = "Controller Info";
    }

    this.setState({
      nextBtnName,
      previousBtnName,
    });
  };

  save = () => {
    let value = this.state.formData.value,
      int_status = this.state.formData.int_status;
    if (
      this.state.formData.cmbLiftType.length <= 0 ||
      this.state.formData.txtLiftName.length <= 0 ||
      this.state.formData.txtNoFloor.length <= 0 ||
      this.state.formData.cmbOpeningSite.length <= 0 ||
      this.state.formData.txtFloorHeight.length <= 0 ||
      this.state.formData.txtNoOpening.length <= 0 ||
      this.state.formData.txtWide.length <= 0 ||
      this.state.formData.txtDeep.length <= 0 ||
      this.state.formData.cmbHoistWay.length <= 0 ||
      // this.state.formData.cmbMachineRoomAvailable.length <= 0 ||
      (this.state.formData.cmbOpeningSite == "NO" &&
        this.state.siteColumn[2].headerCtrlProps.value.length <= 0)
    ) {
      value = "1";
    } else if (
      this.state.formData.cmbMachineType.length <= 0 ||
      (this.state.formData.cmbMachineRoomAvailable == "YES" &&
        this.state.formData.cmbMachineRoom.length <= 0) ||
      this.state.formData.cmbMachinePlacement.length <= 0 ||
      (this.state.formData.cmbMachinePlacement == "Other" &&
        this.state.formData.txtMachineOther.length <= 0) ||
      this.state.formData.cmbCounter.length <= 0
    ) {
      value = "2";
    } else if (
      this.state.formData.cmbDoorType.length <= 0 ||
      this.state.formData.cmbLandingDoorSelection.length <= 0 ||
      (this.state.formData.cmbDoorType == "AUTO" &&
        this.state.formData.cmbCarDoorEnclosure.length <= 0) ||
      (this.state.formData.cmbDoorType == "AUTO" &&
        this.state.formData.cmbOpeningSite == "NO" &&
        this.state.formData.cmbReverseCarDoorEnclosure.length <= 0) ||
      (this.state.formData.cmbDoorType == "AUTO" &&
        this.state.formData.cmbGlassDoor.length <= 0) ||
      (this.state.formData.cmbDoorType == "AUTO" &&
        this.state.formData.cmbVisiblePanel.length <= 0) ||
      this.state.formData.cmbCarEnclosure.length <= 0 ||
      this.state.formData.cmbDoorOpening.length <= 0 ||
      (this.state.formData.cmbDoorOpening == "Other" &&
        this.state.formData.txtOtherWide.length <= 0) ||
      this.state.formData.txtHeight.length <= 0 ||
      (this.state.formData.cmbDoorType == "MANUAL" &&
        this.state.formData.cmbDoorFramed.length <= 0)
    ) {
      value = "3";
    } else if (
      this.state.formData.txtCarWide.length <= 0 ||
      this.state.formData.txtCarDeep.length <= 0 ||
      this.state.formData.cmbCarEnclosure.length <= 0 ||
      this.state.formData.txtCarEnclosureInfo.length <= 0
    ) {
      value = "4";
    } else if (
      this.state.formData.cmbDriveType.length <= 0 ||
      this.state.formData.cmbControllerOperation.length <= 0 ||
      this.state.formData.cmbARD.length <= 0 ||
      this.state.formData.cmbOLNS.length <= 0
    ) {
      value = "5";
    } else if (
      (this.state.formData.cmbLiftType != "GOODS" &&
        Number(this.state.formData.txtNoOfPassenger) <= 0) ||
      (this.state.formData.cmbLiftType == "GOODS" &&
        Number(this.state.formData.txtLoadCapacity) <= 0) ||
      this.state.formData.cmbSpeed.length <= 0 ||
      (this.state.formData.cmbSpeed == "Other" &&
        this.state.formData.txtOtherSpeed.length <= 0) ||
      this.state.formData.txtPowerSupply.length <= 0 ||
      this.state.formData.cmbCOL.length <= 0 ||
      this.state.formData.cmbCOLDisplay.length <= 0 ||
      this.state.formData.cmbSecurity.length <= 0
    ) {
      value = "6";
    } else if (Number(this.state.formData.txtRate) <= 0) {
      value = "6";
      int_status = 2;
    } else {
      int_status = 3;
    }

    this.setState(
      {
        formData: {
          ...this.state.formData,
          value: value,
          int_status,
        },
      },
      () => {
        // this.changeBtnName(this.state.formData.value);
        if (value == "1") {
          this.validateSiteInfo();
        } else if (value == "2") {
          this.validateMachineInfo();
        } else if (value == "3") {
          this.validateDoorInfo();
        } else if (value == "4") {
          this.validateCabinInfo();
        } else if (value == "5") {
          this.validateControllerInfo();
        } else if (value == "6") {
          if (this.validateSpecification()) {
            this.saveMoreIfo(true);
          }
        } /*  else {
          // this.changeBtnName("1");
        } */
      }
    );
  };

  trimFormData = (nextFunctionCall) => {
    let formData = applyTrimOnObjectValues({
      formData: this.state.formData,
    });
    this.setState({ formData }, () => {
      if (nextFunctionCall) {
        nextFunctionCall();
      }
    });
  };

  saveMoreIfo = (lastSave = false) => {
    let Floor = "",
      ProposalLiftDetails = this.state.ProposalLiftDetails;

    if (Number(this.state.formData.txtBasement) > 0) {
      Array.from({ length: Number(this.state.formData.txtBasement) }).forEach(
        (_, index) => {
          Floor +=
            index == 0
              ? `B${Number(this.state.formData.txtBasement) - index}`
              : ` + B${Number(this.state.formData.txtBasement) - index}`;
        }
      );
    }
    if (
      this.state.formData.chkGroundFloor == 1 &&
      Number(this.state.formData.txtBasement) > 0
    ) {
      Floor += " + G";
    } else if (this.state.formData.chkGroundFloor == 1) {
      Floor += "G";
    }
    if (
      Number(this.state.formData.txtNoFloor) > 0 &&
      (Number(this.state.formData.txtBasement) > 0 ||
        this.state.formData.chkGroundFloor == 1)
    ) {
      Floor += ` + ${this.state.formData.txtNoFloor}`;
    } else if (Number(this.state.formData.txtNoFloor) > 0) {
      Floor += this.state.formData.txtNoFloor;
    }

    let doorRows = this.state.doorRows.map((row) => {
      if (this.state.formData.cmbOpeningSite == "YES") {
        return {
          ...row,
          vac_reversecarenclosure: "",
          vac_reversedoorframeenclosure: "",
        };
      } else if (this.state.formData.cmbOpeningSite == "NO") {
        return {
          ...row,
        };
      }
    });

    this.trimFormData(() => {
      this.props.notifyProcessing();

      const reqData = {
        Op: "UpdateProposalLiftInfo",
        vac_brcode: this.state.formData.vac_brcode,
        TblYear: this.state.formData.TblYear,
        bint_totalliftcount: this.state.formData.txtTotalCount,
        vac_branchname: ProposalLiftDetails.vac_brname,
        bint_srno: ProposalLiftDetails.bint_srno,
        bint_revno: ProposalLiftDetails.bint_revno,
        dat_date: ProposalLiftDetails.dat_date_YMD,
        vac_refno: ProposalLiftDetails.vac_refno,
        vac_companyname: ProposalLiftDetails.vac_companyname,
        vac_companyadd: ProposalLiftDetails.vac_companyadd,
        vac_siteaddress: ProposalLiftDetails.vac_siteaddress,
        vac_name: ProposalLiftDetails.vac_name,
        vac_companycity: ProposalLiftDetails.vac_companycity,
        vac_mobileno: ProposalLiftDetails.vac_mobileno,
        vac_emailid: ProposalLiftDetails.vac_emailid,
        vac_lifttype: this.state.formData.cmbLiftType,
        vac_liftname: this.state.formData.txtLiftName,
        bint_noofbasement: this.state.formData.txtBasement,
        bint_groundfloor:
          this.state.formData.chkGroundFloor == true ||
          this.state.formData.chkGroundFloor == "true"
            ? 1
            : 0,
        bint_noofloor: this.state.formData.txtNoFloor,
        vac_floor: Floor,
        vac_openingsameside: this.state.formData.cmbOpeningSite,
        dec_floorheight: this.state.formData.txtFloorHeight,
        bint_noofstops: this.state.formData.txtNoStep,
        bint_noofopening: this.state.formData.txtNoOpening,
        dec_hostwaywide: this.state.formData.txtWide,
        dec_hostwaydeep: this.state.formData.txtDeep,
        vac_hoistwaystructure: this.state.formData.cmbHoistWay,
        vac_machineroomavailable: this.state.formData.cmbMachineRoomAvailable,
        vac_siteremark: this.state.formData.txtSiteRemark,
        vac_openingheading: this.state.siteColumn[2].headerCtrlProps.value,

        //machine
        vac_machine: this.state.formData.cmbMachineType,
        vac_machineroom: this.state.formData.cmbMachineRoom,
        vac_machineplacement: this.state.formData.cmbMachinePlacement,
        vac_machineplacementother: this.state.formData.txtMachineOther,
        vac_counterwtposition: this.state.formData.cmbCounter,
        vac_machineremark: this.state.formData.txtMachineRemark,

        //Door Info
        vac_doortype: this.state.formData.cmbDoorType,
        vac_subdoortype: this.state.formData.cmbLandingDoorSelection,
        vac_cardoorselection:
          this.state.formData.cmbDoorType == "MANUAL"
            ? this.state.formData.cmbCarDoorSelection
            : "",
        vac_visiblepanel: this.state.formData.cmbVisiblePanel,
        vac_glassdoor: this.state.formData.cmbGlassDoor,
        vac_dooroperation: this.state.formData.txtDoorOperation,
        vac_cardoorenclosure: this.state.formData.cmbCarDoorEnclosure,
        vac_frontcardoorenclosure:
          this.state.formData.cmbReverseCarDoorEnclosure,
        vac_dooropeningwidth: this.state.formData.cmbDoorOpening,
        dec_dooropeningwidth: this.state.formData.txtOtherWide,
        dec_dooropeningheight: this.state.formData.txtHeight,
        vac_doorframedby: this.state.formData.cmbDoorFramed,
        vac_carentrance: this.state.formData.txtCarEntrance,
        vac_hoistwayentrance: this.state.formData.txtHoistWayEntrance,

        //Cabin Info
        dec_carinsidewide: this.state.formData.txtCarWide,
        dec_carinsidedeep: this.state.formData.txtCarDeep,
        vac_carinsideheight: this.state.formData.cmbCarHeight,
        dec_carinsideheight: this.state.formData.txtCarHeight,
        vac_carenclosure: this.state.formData.cmbCarEnclosure,
        dec_carcarpetarea: this.state.formData.txtCarCarpetArea,
        vac_carenclosureinfo: this.state.formData.txtCarEnclosureInfo,

        //Controller Info
        vac_drivetype: this.state.formData.cmbDriveType,
        vac_controlleroperation: this.state.formData.cmbControllerOperation,
        vac_ard: this.state.formData.cmbARD,
        vac_onls: this.state.formData.cmbOLNS,
        vac_controloperation: this.state.formData.txtControlOperation,

        //Lift Specification
        bint_noofpassenger: this.state.formData.txtNoOfPassenger,
        dec_loadcapacity: this.state.formData.txtLoadCapacity,
        vac_speed: this.state.formData.cmbSpeed,
        dec_speed: this.state.formData.txtOtherSpeed,
        vac_powersupply: this.state.formData.txtPowerSupply,
        vac_collop: this.state.formData.cmbCOL,
        vac_displaytype: this.state.formData.cmbCOLDisplay,
        vac_security: this.state.formData.cmbSecurity,
        vac_signal: this.state.formData.txtSignal,
        bint_qty: this.state.formData.txtLiftQuantity,
        dec_rate: Number(this.state.formData.txtRate),
        dec_amount: this.state.formData.txtAmount,
        dec_gst: this.state.formData.txtGST,
        dec_total: this.state.formData.txtTotal,
        vac_username: "",
        vac_usermobileno: "",
        int_final: 0,
        int_status: this.state.formData.int_status,
        ReverseOpening:
          this.state.formData.cmbOpeningSite == "NO" ? this.state.siteRows : [],
        LandingDoorEnclouser:
          this.state.formData.cmbDoorType == "AUTO" ? doorRows : [],
      };

      fetchProposal(reqData)
        .then((res) => {
          this.props.updateProcessing(res.data.msgType, res.data.message);
          if (lastSave) {
            this.props.navigateTo("ProposalAllEntry", {
              bint_summarysrno: this.state.formData.bint_summarysrno,
              dtpDate: this.state.formData.dtpDate,
              cmbBranch: this.state.formData.cmbBranch,
              TblYear: this.state.formData.TblYear,
              vac_brcode: this.state.formData.vac_brcode,
            });
          }
        })
        .catch((error) => {
          console.log(
            "Unknown error occurred in Update Proposal Info .",
            error
          );
        });
    });
  };

  render() {
    let TabStyle = {
      padding: "0px",
    };
    let ArrowHideInSave = this.state.nextBtnName == "Save" ? true : false;
    let FinalizeDisabled = this.state.formData.int_status == 4 ? true : false;

    return (
      <div>
        <Grid container justifyContent={"flex-start"}>
          <Grid item>
            <CtBtn
              label={"Back To Proposal Entry"}
              iconName={"left"}
              className={"child-button"}
              variant={"outlined"}
              firstletterBig={false}
              onClick={() => {
                this.props.navigateTo("ProposalAllEntry", {
                  bint_summarysrno: this.state.formData.bint_summarysrno,
                  dtpDate: this.state.formData.dtpDate,
                  cmbBranch: this.state.formData.cmbBranch,
                  TblYear: this.state.formData.TblYear,
                  vac_brcode: this.state.formData.vac_brcode,
                });
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          spacing={0}
          // justifyContent={"center"}
          justifyContent={"left"}
          marginTop={4}
        >
          {/* <Grid item></Grid> */}
          <Grid item marginRight={2}>
            <Typography>
              <b>Branch:</b> {this.state.ProposalLiftDetails.vac_brname}
            </Typography>
          </Grid>
          <Grid item marginRight={2}>
            <Typography>
              <b>Ref No:</b> {this.state.ProposalLiftDetails.vac_refno}
            </Typography>
          </Grid>
          <Grid item marginRight={2}>
            <Typography>
              <b>Date:</b> {this.state.ProposalLiftDetails.dat_date}
            </Typography>
          </Grid>
          <Grid item marginRight={2}>
            <Typography>
              <b>Site Name:</b> {this.state.ProposalLiftDetails.vac_sitename}
            </Typography>
          </Grid>
          <Grid item marginRight={2}>
            <Typography>
              <b>Lift Name:</b> {this.state.ProposalLiftDetails.vac_liftname}
            </Typography>
          </Grid>
          <Grid item marginRight={2}>
            <Typography>
              <b>Lift Quantity:</b> {this.state.ProposalLiftDetails.bint_qty}
            </Typography>
          </Grid>
        </Grid>
        <Box sx={{ width: "100%", typography: "body1" }} marginTop={3}>
          <TabContext value={this.state.formData.value}>
            <Box
              sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}
            >
              <TabList
                onChange={this.handleOnChangeTab}
                aria-label="lab API tabs example"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab
                  label="Site Info"
                  value="1"
                  TypographyProps={{ style: { fontWeight: "bold" } }}
                />
                <Tab
                  label="Machine Room"
                  value="2"
                  TypographyProps={{ style: { fontWeight: "bold" } }}
                />
                <Tab
                  label="Door Info"
                  value="3"
                  TypographyProps={{ style: { fontWeight: "bold" } }}
                />
                <Tab
                  label="Cabin Info"
                  value="4"
                  TypographyProps={{ style: { fontWeight: "bold" } }}
                />
                <Tab
                  label="Controller Info"
                  value="5"
                  TypographyProps={{ style: { fontWeight: "bold" } }}
                />
                <Tab
                  label="Lift Specification"
                  value="6"
                  TypographyProps={{ style: { fontWeight: "bold" } }}
                />
              </TabList>
            </Box>
            <TabPanel value="1" style={TabStyle}>
              {this.siteInfo()}
            </TabPanel>
            <TabPanel value="2" style={TabStyle}>
              {this.machineRoom()}
            </TabPanel>
            <TabPanel value="3" style={TabStyle}>
              {this.doorInfo()}
            </TabPanel>
            <TabPanel value="4" style={TabStyle}>
              {this.cabinInfo()}
            </TabPanel>
            <TabPanel value="5" style={TabStyle}>
              {this.controllerInfo()}
            </TabPanel>
            <TabPanel value="6" style={TabStyle}>
              {this.liftSpecification()}
            </TabPanel>
            <Grid
              container
              justifyContent={"space-between"}
              spacing={2}
              marginTop={1}
            >
              {this.state.formData.value != 1 ? (
                <Grid item>
                  <CtBtn
                    label={this.state.previousBtnName}
                    iconName={"left"}
                    firstletterBig={false}
                    onClick={() => {
                      this.previousBtnClick();
                    }}
                  />
                </Grid>
              ) : (
                <Grid item></Grid>
              )}
              {this.props.userPrivilege["Entry:Proposal"] == "M" && (
                <Grid item>
                  <CtBtn
                    label={this.state.nextBtnName}
                    iconName={ArrowHideInSave ? "save" : "right"}
                    onClick={() => {
                      this.nextBtnClick();
                    }}
                    firstletterBig={false}
                  />
                </Grid>
              )}
            </Grid>
          </TabContext>
        </Box>
        <Grid item>
          <Box display={{ xs: "none" }} style={{ textAlign: "right" }}>
            {this.state.ARD}
          </Box>
        </Grid>
      </div>
    );
  }
}

// export default MoreInfo;

// export default HoCtToastContainer(withRouter(MoreInfo));

function WithNavigate(props) {
  let navigate = useNavigate();
  return <MoreInfo {...props} navigate={navigate} />;
}
export default connect(
  mapStatetoProps,
  mapSetUserPrivileges_ToProps
)(HoCtToastContainer(HOCVerifyIsUser(withRouter(WithNavigate))));
