import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import HOCVerifyIsUser from "../HOC/HOCVerifyIsUser";
import CtBtn from "../Components/CustomTool/CtBtn";
import CtTxt from "../Components/CustomTool/CtTxt";
import CtTextFieldPWD from "../Components/CustomTool/CtTxtPWD";
import CtCmb from "../Components/CustomTool/CtCmb";
import CtTxtNum from "../Components/CustomTool/CtTxtNum";
import MaterialTable from "../Components/CustomTool/MaterialTable";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import AlertResponsiveDialog from "../Components/CustomTool/AlertResponsiveDialog";
// import DepartmentMaster from "./DepartmentMaster";
import { Stack } from "@mui/material";
// import PlantMaster from "./PlantMaster";
// import { fetchUser } from "../Components/API";
import HoCtToastContainer from "../HOC/HoCtToastContainer";
import {
  applyTrimOnObjectValues,
  csvFileName,
  getRowOfRT,
  setKeyboardShortcuts,
} from "../SystemUtility/SystemUtility";
import {
  mapSetUserPrivileges_ToProps,
  mapStatetoProps,
} from "../mapRedux/mapReduxProps";
import { connect } from "react-redux";
import { withRouter } from "../HOC/withRouter";
import { fetchPulleyDiameterSelection } from "./API";
import MobileTable from "./CustomTool/MobileTable";
import CtART from "./CustomTool/ResponsiveTable";
import axios from "axios";
import CtTxtAmt from "../Components/CustomTool/CtTxtAmt";
import CtCmbEditable from "./CustomTool/CtCmbEditable";

class PulleyDiameterSelection extends Component {
  state = {
    Unit: [{ vac_type: "Type" }],
    columnsdata: [
      {
        accessorKey: "bint_srno",
        header: "SR No",
      },
      {
        accessorKey: "bint_passenger",
        header: "Passenger / Load Capacity",
      },
      {
        accessorKey: "bint_car_diverter",
        header: "Car Diverter Pulley Dia",
      },
      {
        accessorKey: "bint_cw_diverter",
        header: "CW Diverter Pulley Dia",
      },
      {
        accessorKey: "bint_car_upper_diverter",
        header: "Car Upper Diverter Pulley Dia",
      },
      {
        accessorKey: "bint_cw_upper_diverter",
        header: "CW Upper Diverter Pulley Dia",
      },
      // {
      //   accessorKey: "bint_thickness_of_safety_bottom",
      //   header: "Thickness Of Safety Bottom",
      // },
      // {
      //   accessorKey: "bint_thickness_of_safety_upright",
      //   header: "Thickness Of Safety Upright",
      // },
      // {
      //   accessorKey: "bint_thickness_of_safety_top",
      //   header: "Thickness Of Safety Top",
      // },
    ],

    rows: [],
    formData: {
      bint_srno: 0,
      txtPassenger: "",
      txtCarDiverterPulleyDia: "",
      txtCWDiverterPulleyDia: "",
      txtCarUpperDiverterPulleyDia: "",
      txtCWUpperDiverterPulleyDia: "",
      // txtThicknessOfSafetyBottom: "",
      // txtThicknessOfSafetyUpright: "",
      // txtThicknessOfSafetyTop: "",
    },
    dataLoadStatus: true,
    ARD: null,
    rowsCount: 0,
    rowsPerPage: 50,
  };

  //#region Component
  componentDidMount() {
    localStorage.setItem("Heading", "Pulley Diameter Selection");
    this.props.verifyUserID(
      this.getInfo,
      this.props.setUserPrivileges,
      this.props.modules.pulleydiameterselection
    );
    this.ShortCut();
  }

  componentWillUnmount() {
    this.removeShortCut();
  }

  //#endregion Component

  //#region Handle

  ShortCut = () => {
    this.removeShortCut = setKeyboardShortcuts(
      this.savePulleyDiameterSelection,
      this.clearInfo,
      () => {
        this.DeleteData(
          this.state.formData.bint_srno,
          this.state.formData.txtPassenger
        );
      }
    );
  };

  handleOnChange = (e) => {
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: e.target.value },
    });
  };

  handleARDonActionClick_Edit = (rtRow) => {
    const row = getRowOfRT(rtRow, [
      "bint_srno",
      "bint_passenger",
      "bint_car_diverter",
      "bint_cw_diverter",
      "bint_car_upper_diverter",
      "bint_cw_upper_diverter",
      "bint_thickness_of_safety_bottom",
      "bint_thickness_of_safety_upright",
      "bint_thickness_of_safety_top",
    ]);
    const bint_srno = row.bint_srno,
      txtPassenger = row.bint_passenger,
      txtCarDiverterPulleyDia = row.bint_car_diverter,
      txtCWDiverterPulleyDia = row.bint_cw_diverter,
      txtCarUpperDiverterPulleyDia = row.bint_car_upper_diverter,
      txtCWUpperDiverterPulleyDia = row.bint_cw_upper_diverter;
    // txtThicknessOfSafetyBottom = row.bint_thickness_of_safety_bottom,
    // txtThicknessOfSafetyUpright = row.bint_thickness_of_safety_upright,
    // txtThicknessOfSafetyTop = row.bint_thickness_of_safety_top;
    this.setState({
      formData: {
        ...this.state.formData,
        bint_srno,
        txtPassenger,
        txtCarDiverterPulleyDia,
        txtCWDiverterPulleyDia,
        txtCarUpperDiverterPulleyDia,
        txtCWUpperDiverterPulleyDia,
        // txtThicknessOfSafetyBottom,
        // txtThicknessOfSafetyUpright,
        // txtThicknessOfSafetyTop,
      },
    });
    document.getElementById("txtPassenger").focus();
  };

  handleARDonActionClick_Delete = (rtRow) => {
    const row = getRowOfRT(rtRow, ["bint_srno", "bint_passenger"]);
    this.DeleteData(row.bint_srno, row.bint_passenger);
  };

  //#endregion Handle

  //#region function

  getInfo = () => {
    this.props.notifyProcessing();
    const reqData = {
      Op: "ListPulleyDiameterSelection",
    };
    fetchPulleyDiameterSelection(reqData)
      .then((res) => {
        this.props.closeUpdateProcessing();
        let rows = res.data.ListPulleyDiameterSelection,
          rowsCount = rows.length;
        this.setState({
          rowsCount,
          rows,
        });
      })
      .catch((error) => {
        console.log("Unknown error occurred in onFormLoad.", error);
      });
    document.getElementById("txtPassenger").focus();
  };

  clearInfo = () => {
    this.setState(
      {
        formData: {
          ...this.state.formData,
          bint_srno: 0,
          txtPassenger: "",
          txtCarDiverterPulleyDia: "",
          txtCWDiverterPulleyDia: "",
          txtCarUpperDiverterPulleyDia: "",
          txtCWUpperDiverterPulleyDia: "",
          // txtThicknessOfSafetyBottom: "",
          // txtThicknessOfSafetyUpright: "",
          // txtThicknessOfSafetyTop: "",
        },
      },
      () => {
        document.getElementById("txtPassenger").focus();
      }
    );
  };

  trimFormData = (nextFunctionCall) => {
    let formData = applyTrimOnObjectValues({
      formData: this.state.formData,
    });
    this.setState({ formData }, () => {
      if (nextFunctionCall) {
        nextFunctionCall();
      }
    });
  };

  validationPulleyDiameterSelection = () => {
    let result = false;
    if (this.state.formData.txtPassenger.length <= 0) {
      this.props.toastErrorMsg(
        "Enter Passenger / Load Capacity",
        "txtPassenger"
      );
    } else if (this.state.formData.txtCarDiverterPulleyDia.length <= 0) {
      this.props.toastErrorMsg(
        "Enter Car Diverter Pulley Dia",
        "txtCarDiverterPulleyDia"
      );
    } else if (this.state.formData.txtCWDiverterPulleyDia.length <= 0) {
      this.props.toastErrorMsg(
        "Enter CW Diverter Pulley Dia",
        "txtCWDiverterPulleyDia"
      );
    } /* else if (this.state.formData.txtThicknessOfSafetyBottom.length <= 0) {
      this.props.toastErrorMsg(
        "Enter Thickness Of Safety Bottom",
        "txtThicknessOfSafetyBottom"
      );
    } else if (this.state.formData.txtThicknessOfSafetyUpright.length <= 0) {
      this.props.toastErrorMsg(
        "Enter Thickness Of Safety Upright",
        "txtThicknessOfSafetyUpright"
      );
    } else if (this.state.formData.txtThicknessOfSafetyTop.length <= 0) {
      this.props.toastErrorMsg(
        "Enter Thickness Of Safety Top",
        "txtThicknessOfSafetyTop"
      );
    } */ else {
      result = true;
    }
    return result;
  };

  savePulleyDiameterSelection = () => {
    this.trimFormData(() => {
      if (this.validationPulleyDiameterSelection()) {
        this.props.notifyProcessing();
        const reqData = {
          Op: "SavePulleyDiameterSelection",
          bint_srno: this.state.formData.bint_srno,
          bint_passenger: this.state.formData.txtPassenger,
          bint_car_diverter: this.state.formData.txtCarDiverterPulleyDia,
          bint_cw_diverter: this.state.formData.txtCWDiverterPulleyDia,
          bint_car_upper_diverter:
            this.state.formData.txtCarUpperDiverterPulleyDia,
          bint_cw_upper_diverter:
            this.state.formData.txtCWUpperDiverterPulleyDia,
          // bint_thickness_of_safety_bottom:
          //   this.state.formData.txtThicknessOfSafetyBottom,
          // bint_thickness_of_safety_upright:
          //   this.state.formData.txtThicknessOfSafetyUpright,
          // bint_thickness_of_safety_top:
          //   this.state.formData.txtThicknessOfSafetyTop,
        };
        fetchPulleyDiameterSelection(reqData)
          .then((res) => {
            this.props.updateProcessing(res.data.msgType, res.data.message);
            if (res.data.msgType === "success") {
              let rows = [],
                rowsCount = rows.length;
              if (
                res.data.ListPulleyDiameterSelection &&
                res.data.ListPulleyDiameterSelection.length > 0
              ) {
                rows = res.data.ListPulleyDiameterSelection;
                rowsCount = rows.length;
              }
              this.setState(
                {
                  rows,
                  rowsCount,
                },
                () => {
                  this.clearInfo();
                }
              );
            }
          })
          .catch((error) => {
            console.log(
              "Unknown error occurred in Save Pulley Diameter Selection .",
              error
            );
          });
      }
    });
  };

  DeleteData = (bint_srno, bint_passenger) => {
    if (bint_srno > 0) {
      this.setState({ ARD: null }, () => {
        let ARD = (
          <AlertResponsiveDialog
            labelAgree="Delete"
            labelDisagree="Cancel"
            alertTitle={`Do you want to Delete Passenger : ${bint_passenger} ?`}
            alertMessage=""
            handleOnClickYes={() => {
              return this.DeletePulleyDiameterSelection(bint_srno);
            }}
            defaultOpenDialog={true}
            onYesClickCloseIfExeSuccessfully={true}
          />
        );
        this.setState({ ARD });
      });
    } else {
      this.props.toastErrorMsg("Select a record for deletion.");
    }
  };

  DeletePulleyDiameterSelection = (bint_srno) => {
    this.props.notifyProcessing();
    const reqData = {
      Op: "DeletePulleyDiameterSelection",
      bint_srno: bint_srno,
    };
    fetchPulleyDiameterSelection(reqData)
      .then((res) => {
        this.props.updateProcessing(res.data.msgType, res.data.message);
        if (res.data.msgType === "success") {
          let rows = [],
            rowsCount = rows.length;
          if (
            res.data.ListPulleyDiameterSelection &&
            res.data.ListPulleyDiameterSelection.length > 0
          ) {
            rows = res.data.ListPulleyDiameterSelection;
            rowsCount = rows.length;
          }
          this.setState(
            {
              rows,
              rowsCount,
              ARD: null,
            },
            () => {
              this.clearInfo();
            }
          );
        }
      })
      .catch((error) => {
        this.props.updateProcessing(
          "error",
          "Unknown error occurred in delete Pulley Diameter Selection. " + error
        );
      });
    return false;
  };

  //#endregion function

  render() {
    return (
      <form>
        <Grid container direction="row" justifyContent="flex-end">
          <Grid item>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <CtBtn
                  className="secondary-button"
                  label="Save"
                  iconName="save"
                  onClick={this.savePulleyDiameterSelection}
                />
              </Grid>
              <Grid item>
                <CtBtn
                  label="New"
                  className="new-button"
                  iconName="new"
                  onClick={this.clearInfo}
                />
              </Grid>
              <Grid item>
                <CtBtn
                  label="Delete"
                  className="delete-button"
                  iconName="delete"
                  onClick={() => {
                    this.DeleteData(
                      this.state.formData.bint_srno,
                      this.state.formData.txtPassenger
                    );
                  }}
                  disabled={
                    Number(this.state.formData.bint_srno) > 0 ? false : true
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          marginTop={0}
        >
          <Grid item>
            <CtTxtNum
              id="txtPassenger"
              label="Passenger / Load Capacity*"
              maxLength={10}
              width="220"
              value={this.state.formData.txtPassenger}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtCarDiverterPulleyDia"
              label="Car Diverter Pulley Dia*"
              maxLength={10}
              width="220"
              value={this.state.formData.txtCarDiverterPulleyDia}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtCWDiverterPulleyDia"
              label="CW Diverter Pulley Dia*"
              maxLength={10}
              width="220"
              value={this.state.formData.txtCWDiverterPulleyDia}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtCarUpperDiverterPulleyDia"
              label="Car Upper Diverter Pulley Dia"
              maxLength={100}
              width="220"
              value={this.state.formData.txtCarUpperDiverterPulleyDia}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtCWUpperDiverterPulleyDia"
              label="CW Upper Diverter Pulley Dia"
              maxLength={10}
              width="220"
              value={this.state.formData.txtCWUpperDiverterPulleyDia}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          {/* <Grid item>
            <CtTxtNum
              id="txtThicknessOfSafetyBottom"
              label="Thickness Of Safety Bottom*"
              maxLength={10}
              width="220"
              value={this.state.formData.txtThicknessOfSafetyBottom}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtThicknessOfSafetyUpright"
              label="Thickness Of Safety Upright*"
              maxLength={10}
              width="220"
              value={this.state.formData.txtThicknessOfSafetyUpright}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtThicknessOfSafetyTop"
              label="Thickness Of Safety Top*"
              maxLength={10}
              width="220"
              value={this.state.formData.txtThicknessOfSafetyTop}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
              defaultAction={this.savePulleyDiameterSelection}
            />
          </Grid> */}
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          marginTop={1}
        >
          <Grid item className="maintable">
            <CtART
              rowsdata={this.state.rows}
              columnsdata={this.state.columnsdata}
              rowsPerPage={this.state.rowsPerPage}
              tblmaxHeight={500}
              csvFileName={csvFileName("Pulley Diameter Selection")}
              rowIconAction={[
                {
                  label: "Edit",
                  onClick: this.handleARDonActionClick_Edit,
                  icon: "Edit",
                  className: "secondary-button",
                },
                {
                  label: "Delete",
                  onClick: this.handleARDonActionClick_Delete,
                  icon: "delete",
                  className: "delete-button",
                },
              ]}
              showColumnFilters={false}
              actionColSize={80}
              density={"compact"}
              hideColIdList={["bint_srno"]}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Box display={{ xs: "none" }} style={{ textAlign: "right" }}>
            {this.state.ARD}
          </Box>
        </Grid>
      </form>
    );
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <PulleyDiameterSelection {...props} navigate={navigate} />;
}
export default connect(
  mapStatetoProps,
  mapSetUserPrivileges_ToProps
)(HoCtToastContainer(HOCVerifyIsUser(withRouter(WithNavigate))));
