import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import HOCVerifyIsUser from "../HOC/HOCVerifyIsUser";
import HoCtToastContainer from "../HOC/HoCtToastContainer";
import "../App.css";
import CtTxt from "../Components/CustomTool/CtTxt";
import CtBtn from "../Components/CustomTool/CtBtn";
import CtTxtAmt from "../Components/CustomTool/CtTxtAmt";
import MaterialTable from "../Components/CustomTool/MaterialTable";
import "./Table.css";

import { fetchDepartment, fetchProposal } from "../Components/API";
import AlertResponsiveDialog from "../Components/CustomTool/AlertResponsiveDialog";
import Progress from "../Components/CustomTool/Progress";
import {
  applyTrimOnObjectValues,
  csvFileName,
  get_YMD_from_SYS,
  isValidItem,
  validatedate_min_max,
  validateEmail,
} from "../SystemUtility/SystemUtility";
import CtCmbEditable from "./CustomTool/CtCmbEditable";
import CtDtp from "./CustomTool/CtDtp";
import CtMultiLineText from "./CustomTool/CtMultiLineText";
import { withRouter } from "../HOC/withRouter";
import ControlledCheckbox from "./CustomTool/CtCheckBox";
import { connect } from "react-redux";
import {
  mapSetUserPrivileges_ToProps,
  mapStatetoProps,
} from "../mapRedux/mapReduxProps";
import CtTxtNum from "../Components/CustomTool/CtTxtNum";

class ProposalEntry extends Component {
  state = {
    formData: {
      txtUnique: "",
      txtTotal: "",
      cmbEntryBranch: "",
      txtRef: "",
      dtpDate: get_YMD_from_SYS(new Date()),
      txtCust: "",
      txtBilling: "",
      txtSite: "",
      txtBillingAdd: "",
      chkSameAdd: false,
      txtSiteAdd: "",
      txtCusCity: "",
      txtCustPhone: "",
      txtEmail: "",
    },
    ProposalDashboardList: [],
    bint_summarysrno: 0,
    Branches: [
      // { vac_branch: "BHAVNAGAR" },
      // { vac_branch: "BARODA" },
      // { vac_branch: "SURAT" },
    ],
    ARD: null,
  };

  //#region component

  componentDidMount() {
    // localStorage.setItem("Heading", "Department");
    document.title = "Top India Elevator : Proposal Entry";
    // this.ShortCut();
    // // this.onFormLoad();
    this.props.verifyUserID(
      this.getInfo,
      this.props.setUserPrivileges,
      this.props.modules.proposal
    );
  }

  getInfo = () => {
    this.props.notifyProcessing();
    const reqData = {
      Op: "LoadProposalData",
      vac_userid: localStorage.getItem("vac_userid"),
    };
    fetchProposal(reqData)
      .then((res) => {
        this.props.closeUpdateProcessing();
        let Branches = res.data.Branch.length > 0 ? res.data.Branch : [];
        this.setState(
          {
            Branches,
          },
          () => {
            if (this.props.getChildValues) {
              this.props.getChildValues(this.state.formData);
            }
          }
        );
      })
      .catch((error) => {
        console.log("Unknown error occurred in onFormLoad.", error);
      });
  };

  // componentWillUnmount() {
  //   this.removeShortCut();
  // }

  //#endregion component

  //#region function

  // ShortCut = () => {
  //   this.removeShortCut = setKeyboardShortcuts(
  //     this.SaveDepartmentMaster,
  //     this.clearInfo,
  //     () => {
  //       this.DeleteData(this.state.formData.EditDepartment);
  //     }
  //   );
  // };

  trimFormData = (nextFunctionCall) => {
    let formData = applyTrimOnObjectValues({
      formData: this.state.formData,
    });
    this.setState({ formData }, () => {
      if (nextFunctionCall) {
        nextFunctionCall();
      }
    });
  };

  // saveProposalEntry = () => {
  //   this.trimFormData(() => {
  //     if (this.validateProposal()) {
  //       this.props.notifyProcessing();
  //       const reqData = {
  //         Op: "SaveNewProposalEntry",
  //         bint_uniqueliftcount: this.state.formData.txtUnique,
  //         vac_branchname: this.state.formData.cmbEntryBranch,
  //         vac_refno: this.state.formData.txtRef,
  //         dat_date: this.state.formData.dtpDate,
  //         vac_companyname: this.state.formData.txtBilling,
  //         vac_companyadd: this.state.formData.txtBillingAdd,
  //         vac_sitename: this.state.formData.txtSite,
  //         vac_siteaddress: this.state.formData.txtSiteAdd,
  //         vac_name: this.state.formData.txtCust,
  //         vac_companycity: this.state.formData.txtCusCity,
  //         vac_mobileno: this.state.formData.txtCustPhone,
  //         vac_emailid: this.state.formData.txtEmail,
  //         vac_userid: localStorage.getItem("vac_userid"),
  //         int_status: 1,
  //         bol_revision: false,
  //         bint_totalliftcount: 0,
  //       };
  //       fetchProposal(reqData)
  //         .then((res) => {
  //           this.props.updateProcessing(res.data.msgType, res.data.message);
  //           if (res.data.msgType === "success") {
  //             let ProposalDashboardList =
  //                 res.data.RailPriceList && res.data.RailPriceList.length > 0
  //                   ? res.data.RailPriceList
  //                   : [],
  //               bint_summarysrno = res.data.bint_summarysrno,
  //               TblYear = res.data.TblYear,
  //               vac_brcode = res.data.vac_brcode;
  //             // console.log("TblYear", res.data.TblYear);
  //             // console.log("vac_brcode", res.data.vac_brcode);
  //             this.setState(
  //               {
  //                 ProposalDashboardList,
  //                 bint_summarysrno,
  //               },
  //               () => {
  //                 this.props.navigateTo("ProposalAllEntry", {
  //                   bint_summarysrno: this.state.bint_summarysrno,
  //                   dtpDate: this.state.formData.dtpDate,
  //                   cmbBranch: this.state.formData.cmbEntryBranch,
  //                   TblYear,
  //                   vac_brcode,
  //                 });
  //               }
  //             );
  //           }
  //         })
  //         .catch((error) => {
  //           console.log(
  //             "Unknown error occurred in Save Rail Price Master .",
  //             error
  //           );
  //         });
  //     }
  //   });
  // };

  //#endregion function

  //#region handle

  handleOnChange = (e) => {
    this.setState(
      {
        formData: { ...this.state.formData, [e.target.name]: e.target.value },
      },
      () => {
        if (e.target.name === "txtBillingAdd") {
          if (this.state.formData.chkSameAdd == true) {
            this.setState({
              formData: {
                ...this.state.formData,
                txtSiteAdd: e.target.value,
              },
            });
          }
        } else if (e.target.name === "txtSiteAdd") {
          let chkSameAdd = this.state.formData.chkSameAdd;
          if (e.target.value != this.state.formData.txtBillingAdd) {
            chkSameAdd = false;
          }
          this.setState({
            formData: {
              ...this.state.formData,
              chkSameAdd,
            },
          });
        } else if (
          e.target.name === "cmbEntryBranch" ||
          e.target.name === "dtpDate"
        ) {
          if (
            this.state.formData.cmbEntryBranch.length > 0 &&
            this.state.formData.dtpDate.length > 0 &&
            isValidItem(
              this.state.Branches,
              "vac_brname",
              this.state.formData.cmbEntryBranch
            )
          ) {
            this.getRefNo();
          }
        }
        if (this.props.getChildValues) {
          this.props.getChildValues(this.state.formData);
        }
      }
    );
  };

  getRefNo = () => {
    this.props.notifyProcessing();
    const reqData = {
      Op: "GetRefCode",
      vac_branchname: this.state.formData.cmbEntryBranch,
      dat_date: this.state.formData.dtpDate,
      tblname: "tbl_propsummary_",
      colname: "vac_summary_refno",
      bol_response: true,
    };
    fetchProposal(reqData)
      .then((res) => {
        this.props.closeUpdateProcessing();
        let vac_refno = res.data.vac_refno.length > 0 ? res.data.vac_refno : "";
        this.setState(
          {
            formData: {
              ...this.state.formData,
              txtRef: vac_refno,
            },
          },
          () => {
            if (this.props.getChildValues) {
              this.props.getChildValues(this.state.formData);
            }
          }
        );
      })
      .catch((error) => {
        console.log("Unknown error occurred in onFormLoad.", error);
      });
  };

  handleOnCheckChange = (e) => {
    this.setState(
      {
        formData: { ...this.state.formData, [e.target.name]: e.target.checked },
      },
      () => {
        if (e.target.name == "chkSameAdd") {
          let txtSiteAdd = "";
          if (e.target.checked == true) {
            txtSiteAdd = this.state.formData.txtBillingAdd;
          }
          this.setState(
            {
              formData: {
                ...this.state.formData,
                txtSiteAdd: txtSiteAdd,
              },
            },
            () => {
              if (this.props.getChildValues) {
                this.props.getChildValues(this.state.formData);
              }
            }
          );
        }
      }
    );
  };

  handleARDonActionClick_Delete = (row) => {
    const EditDepartment = row.getValue("vac_department");
    this.DeleteData(EditDepartment);
  };

  handleARDonActionClick_Edit = (row) => {
    // console.log("row", row);
    // console.log("row.getValue()", row.getvalue("vac_storename"));
    const EditDepartment = row.getValue("vac_department"),
      txtDepartment = row.getValue("vac_department");
    this.setState({
      formData: {
        ...this.state.formData,
        EditDepartment,
        txtDepartment,
      },
    });
    window.scrollTo(0, 0);
    document.getElementById("txtDepartment").focus();
  };

  handleOnNewEntryClick = () => {
    let ARD = null;
    this.setState({ ARD }, () => {
      ARD = (
        <AlertResponsiveDialog
          labelDisagree="Close"
          defaultOpenDialog={true}
          onYesClickCloseIfExeSuccessfully={true}
          handleOnClickNo={() => {}}
          maxWidthClass="100%"
        />
      );
      this.setState({ ARD });
    });
  };

  // validateProposal = () => {
  //   let result = false;

  //   if (this.state.formData.dtpDate.length <= 0) {
  //     this.props.toastErrorMsg("Select Date", "dtpDate");
  //   } else if (
  //     validatedate_min_max(
  //       this.state.formData.dtpDate,
  //       "dtpDate",
  //       this.props.toastErrorMsg
  //     ) == false
  //   ) {
  //     return result;
  //   } else if (this.state.formData.cmbEntryBranch.length <= 0) {
  //     this.props.toastErrorMsg("Select Branch ", "cmbEntryBranch");
  //   } else if (
  //     this.state.formData.cmbEntryBranch.length > 0 &&
  //     !isValidItem(
  //       this.state.Branches,
  //       "vac_brname",
  //       this.state.formData.cmbEntryBranch
  //     )
  //   ) {
  //     this.props.toastErrorMsg("Select Valid Branch ", "cmbEntryBranch");
  //   } else if (this.state.formData.txtRef.length <= 0) {
  //     this.props.toastErrorMsg("Enter Ref. No.", "txtRef");
  //   } else if (Number(this.state.formData.txtUnique) <= 0) {
  //     this.props.toastErrorMsg("Enter Unique Lift Count", "txtUnique");
  //   } else if (this.state.formData.txtBilling.length <= 0) {
  //     this.props.toastErrorMsg("Enter Billing Name", "txtBilling");
  //   } else if (this.state.formData.txtBillingAdd.length <= 0) {
  //     this.props.toastErrorMsg(
  //       "Enter Billing Address (Without Billing Name)",
  //       "txtBillingAdd"
  //     );
  //   } else if (this.state.formData.txtSite.length <= 0) {
  //     this.props.toastErrorMsg("Enter Site Name", "txtSite");
  //   } else if (this.state.formData.txtSiteAdd.length <= 0) {
  //     this.props.toastErrorMsg(
  //       "Enter Site Address (Without Site Name)",
  //       "txtSiteAdd"
  //     );
  //   } else if (this.state.formData.txtCust.length <= 0) {
  //     this.props.toastErrorMsg("Enter Customer Name", "txtCust");
  //   } else if (this.state.formData.txtCusCity.length <= 0) {
  //     this.props.toastErrorMsg("Enter Customer City", "txtCusCity");
  //   } else if (!/^\d{10}$/.test(this.state.formData.txtCustPhone)) {
  //     this.props.toastErrorMsg(
  //       "Enter a valid 10-digit Customer Phone No.",
  //       "txtCustPhone"
  //     );
  //   } else if (
  //     this.state.formData.txtEmail.length > 0 &&
  //     !validateEmail(this.state.formData.txtEmail)
  //   ) {
  //     this.props.toastErrorMsg("Enter a valid Customer Email ID.", "txtEmail");
  //   } else {
  //     result = true;
  //   }
  //   return result;
  // };

  // saveProposal = () => {
  //   if (this.validateProposal()) {
  //     let txtUnique = this.state.formData.txtUnique;
  //     console.log("txtUnique", txtUnique);
  //     this.props.navigateTo("ProposalAllEntry", {
  //       txtUnique: txtUnique,
  //     });
  //   }
  // };

  //#endregion handle

  render() {
    return (
      <form>
        {/* <Grid container direction="row" justifyContent="flex-end">
          <Grid item>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <CtBtn
                  className="secondary-button"
                  label="Save"
                  iconName="save"
                  onClick={this.saveProposalEntry}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid> */}
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          marginTop={1}
          spacing={2}
        >
          <Grid item>
            <CtDtp
              id="dtpDate"
              value={this.state.formData.dtpDate}
              label="Date *"
              handleOnChange={this.handleOnChange}
              maxLength={50}
              onKeyDown={this.props.onKeyDown}
              nextCtrlID={"cmbEntryBranch"}
              accountYear={localStorage.getItem("ACY")}
            />
          </Grid>
          <Grid item>
            <CtCmbEditable
              id="cmbEntryBranch"
              label="Branch *"
              items={this.state.Branches}
              value={this.state.formData.cmbEntryBranch}
              handleOnChange={this.handleOnChange}
              width={190}
              maxLength={100}
              onKeyDown={this.props.onKeyDown}
              colID="vac_brname"
              nextCtrlID={"txtRef"}
            />
          </Grid>
          <Grid item>
            <CtTxt
              id="txtRef"
              value={this.state.formData.txtRef}
              label="Ref No.*"
              handleOnChange={this.handleOnChange}
              maxLength={50}
              width={200}
              onKeyDown={this.props.onKeyDown}
              nextCtrlID={"txtUnique"}
            />
          </Grid>

          <Grid item>
            <CtTxtAmt
              id="txtUnique"
              value={this.state.formData.txtUnique}
              label="Unique Lift Count *"
              handleOnChange={this.handleOnChange}
              maxLength={11}
              width={150}
              onKeyDown={this.props.onKeyDown}
              nextCtrlID={"txtBilling"}
            />
          </Grid>
          {/* <Grid item>
            <CtTxt
              id="txtCust"
              value={this.state.formData.txtCust}
              label="Customer Name"
              handleOnChange={this.handleOnChange}
              maxLength={50}
              width={220}
              onKeyDown={this.props.onKeyDown}
            />
          </Grid> */}
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          marginTop={1}
          spacing={2}
        >
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CtMultiLineText
              id="txtBilling"
              value={this.state.formData.txtBilling}
              label="Billing Name *"
              handleOnChange={this.handleOnChange}
              maxLength={50}
              width={"100%"}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CtMultiLineText
              id="txtBillingAdd"
              value={this.state.formData.txtBillingAdd}
              label="Billing Address (Without Billing Name) *"
              handleOnChange={this.handleOnChange}
              maxLength={2000}
              width={"100%"}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="flex-end" marginTop={1}>
          <Grid item>
            <ControlledCheckbox
              id={"chkSameAdd"}
              label={"Same As Billing Address"}
              handleCheckChange={this.handleOnCheckChange}
              checkboxColor={"#3179ff"}
              checked={this.state.formData.chkSameAdd}
            />
          </Grid>
        </Grid>

        <Grid container direction="row" justifyContent="flex-start" spacing={2}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CtMultiLineText
              id="txtSite"
              value={this.state.formData.txtSite}
              label="Site Name *"
              handleOnChange={this.handleOnChange}
              maxLength={50}
              width={"100%"}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CtMultiLineText
              id="txtSiteAdd"
              value={this.state.formData.txtSiteAdd}
              label="Site Address * (Without Site Name)"
              handleOnChange={this.handleOnChange}
              maxLength={2000}
              width={"100%"}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          marginTop={1}
          spacing={2}
        >
          <Grid item>
            <CtTxt
              id="txtCust"
              value={this.state.formData.txtCust}
              label="Customer Name *"
              handleOnChange={this.handleOnChange}
              maxLength={50}
              width={200}
              onKeyDown={this.props.onKeyDown}
            />
          </Grid>
          <Grid item>
            <CtTxt
              id="txtCusCity"
              value={this.state.formData.txtCusCity}
              label="Customer City *"
              handleOnChange={this.handleOnChange}
              maxLength={50}
              width={200}
              onKeyDown={this.props.onKeyDown}
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtCustPhone"
              value={this.state.formData.txtCustPhone}
              label="Customer Phone No *"
              handleOnChange={this.handleOnChange}
              maxLength={10}
              width={200}
              onKeyDown={this.props.onKeyDown}
              nextCtrlID={"txtEmail"}
            />
          </Grid>
          <Grid item>
            <CtTxt
              id="txtEmail"
              value={this.state.formData.txtEmail}
              label="Customer Email ID"
              handleOnChange={this.handleOnChange}
              maxLength={50}
              width={200}
            />
          </Grid>
        </Grid>
      </form>
    );
  }
}

// export default HoCtToastContainer(withRouter(ProposalEntry));

function WithNavigate(props) {
  let navigate = useNavigate();
  return <ProposalEntry {...props} navigate={navigate} />;
}
export default connect(
  mapStatetoProps,
  mapSetUserPrivileges_ToProps
)(HoCtToastContainer(HOCVerifyIsUser(withRouter(WithNavigate))));
