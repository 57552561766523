import React, { useMemo } from "react";
import { Box } from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import { Button, Tooltip, IconButton } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { mkConfig, generateCsv, download } from "export-to-csv";
import {
  Edit,
  Delete,
  Print,
  Label,
  Upgrade,
  PriceChangeOutlined,
  CurrencyRupee,
  FileCopy,
} from "@mui/icons-material";
import AddBoxIcon from "@mui/icons-material/AddBox";

const CtMRT = ({
  id,
  columnsdata,
  rowsdata,
  rowsPerPage,
  tblmaxHeight,
  csvFileName,
  tableButtonAction,
  rowIconAction,
  showColumnFilters,
  hideColIdList,
  actionColSize,
  density,
  cust_filters,
  enableRowSelection,
  rowSelectionAction,
  rowSelectionActionBtnColor,
  hideBottomToolbar,

  // rowSelection,
}) => {
  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    filename: csvFileName,
    showColumnHeaders: true,
    columnHeaders: columnsdata.map((column) => ({
      key: column.accessorKey,
      displayLabel: column.header,
    })),
  });

  if ((density == undefined) | (density == "")) {
    density = "comfortable";
  }

  const handleExportRows = (rows) => {
    const visibleColumnIds = columnsdata.map((column) => column.id);
    const rowData = rows.map((row) => {
      const visibleData = {};
      for (const columnId of visibleColumnIds) {
        visibleData[columnId] = row.original[columnId];
      }
      return visibleData;
    });
    const columnHeaders = columnsdata.map((column) => column.Header);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = (rows) => {
    const visibleColumnIds = columnsdata.map((column) => column.id);
    const rowData = rows.map((row) => {
      const visibleData = {};
      for (const columnId of visibleColumnIds) {
        visibleData[columnId] = row[columnId];
      }
      return visibleData;
    });
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  let columnVisibility = {};
  hideColIdList &&
    hideColIdList.map((col) => {
      columnVisibility[col] = false;
    });

  return (
    <MaterialReactTable
      columns={columnsdata}
      data={rowsdata}
      displayColumnDefOptions={{
        "mrt-row-actions": {
          muiTableHeadCellProps: {
            align: "center",
          },
          size: actionColSize,
          maxSize: actionColSize,
          muiTableBodyCellProps: {
            align: "left",
          },
        },
      }}
      renderRowActions={({ row }) => (
        <Box sx={{ display: "flex", gap: "1rem" }}>
          {rowIconAction &&
            rowIconAction.map((action, index) => {
              return action ? (
                <Tooltip arrow placement="left" title={action.label}>
                  <IconButton
                    color={action.label === "Delete" ? "error" : ""}
                    onClick={() => action.onClick(row)}
                    style={{ padding: "0px" }}
                  >
                    {action.label === "Edit" ? <Edit /> : null}
                    {action.label === "Revise" ? <FileCopy /> : null}
                    {action.label === "Delete" ? <Delete /> : null}
                    {action.label === "Print" ? <Print /> : null}
                    {action.label === "Update Status" ? <Upgrade /> : null}
                    {action.label === "Price Summary" ? (
                      <CurrencyRupee />
                    ) : null}
                  </IconButton>
                </Tooltip>
              ) : (
                ""
              );
            })}
        </Box>
      )}
      renderTopToolbarCustomActions={({ table }) => {
        return (
          <div style={{ display: "flex", gap: "0.5rem" }}>
            <div>
              <Box>
                {tableButtonAction &&
                  tableButtonAction.map((action, index) => {
                    return action ? (
                      <Button
                        key={index}
                        bgcolor="#177CDD"
                        variant="contained"
                        onClick={() => {
                          action.onClick(table);
                        }}
                        style={{
                          marginRight: "5px",
                          marginLeft: "5px",
                          marginBottom: "5px",
                        }}
                      >
                        <label style={{ display: "flex" }}>
                          {action.addPluseIcon ? (
                            <AddBoxIcon style={{ marginRight: "5px" }} />
                          ) : null}
                          {action.label}
                        </label>
                      </Button>
                    ) : (
                      ""
                    );
                  })}

                {rowSelectionAction &&
                  rowSelectionAction.map((action, index) => {
                    return (
                      <Button
                        key={index}
                        color={
                          rowSelectionActionBtnColor
                            ? rowSelectionActionBtnColor
                            : "success"
                        }
                        variant="contained"
                        disabled={
                          !table.getIsSomeRowsSelected() &&
                          !table.getIsAllRowsSelected()
                        }
                        onClick={() => {
                          action.onClick(table);
                          // console.log(
                          //   "table.getIsSomeRowsSelected",
                          //   table.getIsSomeRowsSelected
                          // );
                        }}
                        style={{
                          marginRight: "5px",
                          marginLeft: "5px",
                          marginBottom: "5px",
                        }}
                      >
                        {action.label}
                      </Button>
                    );
                  })}
              </Box>
            </div>
          </div>
        );
      }}
      enableRowSelection={enableRowSelection}
      enableEditing
      enableHiding
      enableColumnOrdering
      enableColumnResizing
      enableClickToCopy
      enableColumnFilterModes
      enableColumnDragging
      enableGrouping
      muiTablePaginationProps={{
        showFirstButton: true,
        showLastButton: true,
      }}
      initialState={{
        density,
        columnVisibility,
        showColumnFilters,
        pagination: { pageSize: rowsPerPage },
        filters: { cust_filters },
        // rowSelection: { rowSelection },
      }}
      muiTableHeadCellFilterTextFieldProps={{
        sx: { m: "0.2rem 0", width: "100%" },
        // variant: "outlined",
      }}
      enableFacetedValues
      enablePinning
      enableStickyHeader
      enableStickyFooter
      muiTableContainerProps={{
        sx: { maxHeight: tblmaxHeight },
      }}
      muiTableProps={{
        sx: {
          border: "0.5px solid rgb(23,124,221)",
          // maxWidth: "200vw",
        },
      }}
      muiTableHeadCellProps={{
        sx: {
          border: "0.5px solid rgb(185,215,244)",
          padding: "4px 10px 0px 6px",
        },
      }}
      muiTableBodyCellProps={{
        sx: {
          border: "0.5px solid rgb(185,215,244)",
        },
      }}
      renderBottomToolbarCustomActions={({ table }) =>
        !hideBottomToolbar && (
          <Box
            sx={{
              display: "flex",
              gap: "16px",
              padding: "20px",
              flexWrap: "wrap",
            }}
          >
            <Button
              bgcolor="#177CDD"
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={() => handleExportData(rowsdata)}
              startIcon={<FileDownloadIcon />}
            >
              All Data
            </Button>
            <Button
              bgcolor="#177CDD"
              disabled={table.getPrePaginationRowModel().rows.length === 0}
              //export all rows, including from the next page, (still respects filtering and sorting)
              onClick={() =>
                handleExportRows(table.getPrePaginationRowModel().rows)
              }
              startIcon={<FileDownloadIcon />}
            >
              All Rows
            </Button>
            <Button
              bgcolor="#177CDD"
              disabled={table.getRowModel().rows.length === 0}
              //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
              onClick={() => handleExportRows(table.getRowModel().rows)}
              startIcon={<FileDownloadIcon />}
            >
              Page Rows
            </Button>
            <Button
              bgcolor="#177CDD"
              disabled={
                !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
              }
              //only export selected rows
              onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
              startIcon={<FileDownloadIcon />}
            >
              Selected Rows
            </Button>
          </Box>
        )
      }
    />
  );
};

export default CtMRT;
