import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import HOCVerifyIsUser from "../HOC/HOCVerifyIsUser";
import HoCtToastContainer from "../HOC/HoCtToastContainer";
import "../App.css";
import CtTxt from "../Components/CustomTool/CtTxt";
import CtBtn from "../Components/CustomTool/CtBtn";
import CtTxtAmt from "../Components/CustomTool/CtTxtAmt";
import "./Table.css";
import { apiURL, fetchProposal } from "../Components/API";
import AlertResponsiveDialog from "../Components/CustomTool/AlertResponsiveDialog";
import {
  applyTrimOnObjectValues,
  get_DMY_from_ISODate,
  get_DMY_from_YMD,
  get_YMD_from_DMY,
  get_YMD_from_ISODate,
  getNumber_or_Blank4Null,
  getString_or_Blank4Null,
  validatedate_min_max,
  validateEmail,
} from "../SystemUtility/SystemUtility";
import CtCmbEditable from "./CustomTool/CtCmbEditable";
import CtDtp from "./CustomTool/CtDtp";
import CtMultiLineText from "./CustomTool/CtMultiLineText";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Stack, Typography } from "@mui/material";
import { withRouter } from "../HOC/withRouter";
import Terms from "./Terms";
import "./ProposalAllEntry.css";
import ControlledCheckbox from "./CustomTool/CtCheckBox";
import { connect } from "react-redux";
import {
  mapSetUserPrivileges_ToProps,
  mapStatetoProps,
} from "../mapRedux/mapReduxProps";
import CtTxtNum from "../Components/CustomTool/CtTxtNum";
import TextEditor from "./TextEditor";
import { Api, Edit } from "@mui/icons-material";
import MoreInfoTextEditor from "./MoreInfoTextEditor";
import MoreInfoTextEditorWithMaster from "./MoreInfoTextEditorWithMaster";

class ProposalAllEntry extends Component {
  state = {
    TermsConditionDetailsInfo: undefined,
    PreparatoryWorkInfo: undefined,
    TermsCancellationInfo: undefined,
    TermsPaymentInfo: undefined,
    NoteInfo: undefined,
    MasterNotes: [],
    formData: {
      TermsConditionDetails: "",
      PreparatoryWork: "",
      TermsCancellation: "",
      TermsPayment: "",
      Note: "",
      txtUnique:
        this.props.location.state && this.props.location.state.txtUnique
          ? this.props.location.state.txtUnique
          : "",
      txtTotal: 0,
      cmbBranch:
        this.props.location.state && this.props.location.state.cmbBranch
          ? this.props.location.state.cmbBranch
          : "",
      txtRef: "",
      dtpDate:
        /* 
        this.props.location.state && this.props.location.state.dtpDate
          ? this.props.location.state.dtpDate
          : */ "",
      bint_summarysrno:
        this.props.location.state && this.props.location.state.bint_summarysrno
          ? this.props.location.state.bint_summarysrno
          : "",

      TblYear:
        this.props.location.state && this.props.location.state.TblYear
          ? this.props.location.state.TblYear
          : "",

      vac_brcode:
        this.props.location.state && this.props.location.state.vac_brcode
          ? this.props.location.state.vac_brcode
          : "",

      txtCust: "",
      txtBilling: "",
      txtSite: "",
      txtBillingAdd: "",
      chkSameAdd: false,
      txtSiteAdd: "",
      txtCusCity: "",
      txtCustPhone: "",
      txtEmail: "",
      txttotalprice: "",
      value: "1",
      txtLiftName: "",
    },
    showSaveBtn: false,
    TermsCondition: [],
    PreparatoryWorkEntry: [],
    LiftDetails: [],

    Branchs: [
      { vac_branch: "Bhavnagar" },
      { vac_branch: "Broda" },
      { vac_branch: "Surat" },
    ],
    ARD: null,

    UniqueLift: [
      { no: "1", name: "Lift 1" },
      { no: "2", name: "Lift 2" },
      { no: "3", name: "Lift 3" },
    ],
  };

  //#region component

  componentDidMount() {
    // localStorage.setItem("Heading", "Department");
    document.title = "Top India Elevator : Proposal All Entry";
    // this.ShortCut();
    // // this.onFormLoad();
    this.props.verifyUserID(
      this.getInfo,
      this.props.setUserPrivileges,
      this.props.modules.proposal
    );
  }

  // componentWillUnmount() {
  //   this.removeShortCut();
  // }

  //#endregion component

  //#region function

  // ShortCut = () => {
  //   this.removeShortCut = setKeyboardShortcuts(
  //     this.SaveDepartmentMaster,
  //     this.clearInfo,
  //     () => {
  //       this.DeleteData(this.state.formData.EditDepartment);
  //     }
  //   );
  // };

  trimFormData = (nextFunctionCall) => {
    let formData = applyTrimOnObjectValues({
      formData: this.state.formData,
    });
    this.setState({ formData }, () => {
      if (nextFunctionCall) {
        nextFunctionCall();
      }
    });
  };

  getInfo = () => {
    this.props.notifyProcessing();
    const reqData = {
      Op: "EditProposalList",
      bint_summarysrno: this.state.formData.bint_summarysrno,
      vac_branchname: this.state.formData.cmbBranch,
      dat_date:
        this.props.location.state && this.props.location.state.dtpDate
          ? this.props.location.state.dtpDate
          : this.state.formData.dtpDate,
    };
    fetchProposal(reqData)
      .then((res) => {
        this.props.closeUpdateProcessing();
        let EditProposalList = res.data.EditProposalList;
        let LiftDetails =
            EditProposalList.MoreLiftInfo &&
            EditProposalList.MoreLiftInfo.length > 0
              ? EditProposalList.MoreLiftInfo
              : [],
          MasterNotes = res.data.Notes.length > 0 ? res.data.Notes : [];

        let formData = {};
        EditProposalList.MoreLiftInfo.map((row, index) => {
          formData[`txtname${index + 1}`] = row.vac_liftname;
          formData[`txtqty${index + 1}`] = row.bint_qty;
          formData[`allBtnHide${index + 1}`] =
            row.vac_liftname.length > 0 && Number(row.bint_qty) > 0
              ? false
              : true;
        });

        this.setState(
          {
            TermsConditionDetailsInfo: EditProposalList.vac_termsandcondition,
            TermsCancellationInfo: EditProposalList.vac_termscancellation,
            TermsPaymentInfo: EditProposalList.vac_termspayment,
            PreparatoryWorkInfo: EditProposalList.vac_preparatorywork,
            NoteInfo: EditProposalList.vac_note,
            MasterNotes,
            formData: {
              ...this.state.formData,
              ...formData,
              bint_summarysrno: EditProposalList.bint_summarysrno,
              txtUnique: EditProposalList.MoreLiftInfo.length,
              txtTotal: EditProposalList.bint_totalliftcount,
              cmbBranch: EditProposalList.vac_brname,
              txtRef: EditProposalList.vac_summary_refno,
              dtpDate: EditProposalList.dat_date_YMD,
              txtBilling: EditProposalList.vac_companyname,
              txtBillingAdd: EditProposalList.vac_companyadd,
              txtSite: EditProposalList.vac_sitename,
              txtSiteAdd: EditProposalList.vac_siteaddress,
              txtCust: EditProposalList.vac_name,
              txtCusCity: EditProposalList.vac_companycity,
              txtCustPhone: EditProposalList.vac_mobileno,
              txtEmail: EditProposalList.vac_emailid,
              txttotalprice: EditProposalList.dec_totalprice,
            },
            LiftDetails,
          },
          () => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }
        );
      })
      .catch((error) => {
        console.log("Unknown error occurred in onFormLoad.", error);
      });
  };

  validateProposal = () => {
    let result = false;
    if (this.state.formData.cmbBranch.length <= 0) {
      this.props.toastErrorMsg("Select Branch ", "cmbBranch");
    } else if (this.state.formData.dtpDate.length <= 0) {
      this.props.toastErrorMsg("Select Date", "dtpDate");
    } else if (
      validatedate_min_max(
        this.state.formData.dtpDate,
        "dtpDate",
        this.props.toastErrorMsg
      ) == false
    ) {
      return result;
    } else if (this.state.formData.txtBilling.length <= 0) {
      this.props.toastErrorMsg("Enter Billing Name", "txtBilling");
    } else if (this.state.formData.txtBillingAdd.length <= 0) {
      this.props.toastErrorMsg(
        "Enter Billing Address (Without Billing Name)",
        "txtBillingAdd"
      );
    } else if (this.state.formData.txtSite.length <= 0) {
      this.props.toastErrorMsg("Enter Site Name", "txtSite");
    } else if (this.state.formData.txtSiteAdd.length <= 0) {
      this.props.toastErrorMsg(
        "Enter Site Address (Without Site Name)",
        "txtSiteAdd"
      );
    } else if (this.state.formData.txtCust.length <= 0) {
      this.props.toastErrorMsg("Enter Customer Name", "txtCust");
    } else if (this.state.formData.txtCusCity.length <= 0) {
      this.props.toastErrorMsg("Enter Customer City", "txtCusCity");
    } else if (!/^\d{10}$/.test(this.state.formData.txtCustPhone)) {
      this.props.toastErrorMsg(
        "Enter a valid 10-digit Customer Phone No.",
        "txtCustPhone"
      );
    } else if (
      this.state.formData.txtEmail.length > 0 &&
      !validateEmail(this.state.formData.txtEmail)
    ) {
      this.props.toastErrorMsg("Enter a valid Customer Email ID.", "txtEmail");
    } else {
      result = true;
    }
    return result;
  };

  updateProposal = () => {
    if (this.validateProposal()) {
      this.props.notifyProcessing();
      const reqData = {
        Op: "UpdateProposalSummary",
        bint_totalliftcount: this.state.formData.txtTotal,
        dat_date: this.state.formData.dtpDate,
        vac_companyname: this.state.formData.txtBilling,
        vac_companyadd: this.state.formData.txtBillingAdd,
        vac_sitename: this.state.formData.txtSite,
        vac_siteaddress: this.state.formData.txtSiteAdd,
        vac_name: this.state.formData.txtCust,
        vac_companycity: this.state.formData.txtCusCity,
        vac_mobileno: this.state.formData.txtCustPhone,
        vac_emailid: this.state.formData.txtEmail,
        vac_termspayment:
          this.state.formData.TermsPayment.length > 0
            ? this.state.formData.TermsPayment
            : this.state.TermsPaymentInfo,
        vac_termscancellation:
          this.state.formData.TermsCancellation.length > 0
            ? this.state.formData.TermsCancellation
            : this.state.TermsCancellationInfo,
        vac_note:
          this.state.formData.Note.length > 0
            ? this.state.formData.Note
            : this.state.NoteInfo,
        vac_termsandcondition:
          this.state.formData.TermsConditionDetails.length > 0
            ? this.state.formData.TermsConditionDetails
            : this.state.TermsConditionDetailsInfo,
        vac_preparatorywork:
          this.state.formData.PreparatoryWork.length > 0
            ? this.state.formData.PreparatoryWork
            : this.state.PreparatoryWorkInfo,
        vac_refno: this.state.formData.txtRef,
        vac_brcode: this.state.formData.vac_brcode,
        TblYear: this.state.formData.TblYear,
      };

      fetchProposal(reqData)
        .then((res) => {
          this.props.updateProcessing(res.data.msgType, res.data.message);
          if (res.data.msgType === "success") {
            let rows = [],
              rowsCount = rows.length;

            this.setState({
              rows,
              rowsCount,
            });
          }
        })
        .catch((error) => {
          console.log(
            "Unknown error occurred in Update Proposal Info .",
            error
          );
        });
    }
  };

  //#endregion function

  //#region handle

  handleOnChange = (e) => {
    this.setState(
      {
        formData: { ...this.state.formData, [e.target.name]: e.target.value },
      },
      () => {
        if (e.target.name.includes("txtqty")) {
          let no = Number(e.target.name.replace("txtqty", "")),
            index = no - 1,
            ProposalLiftDetails = this.state.LiftDetails[index],
            txttotalprice = 0,
            LiftDetails = this.state.LiftDetails.map((row, curRowIndex) => {
              if (index == curRowIndex) {
                row.vac_liftname = this.state.formData[`txtname${no}`];
                row.bint_qty = this.state.formData[`txtqty${no}`];
                row.dec_rate = getNumber_or_Blank4Null(
                  ProposalLiftDetails.dec_rate
                );
                row.dec_amount =
                  ProposalLiftDetails.dec_rate != null
                    ? ProposalLiftDetails.dec_rate *
                      Number(this.state.formData[`txtqty${no}`])
                    : getNumber_or_Blank4Null(ProposalLiftDetails.dec_amount);
                row.dec_taxamt =
                  ProposalLiftDetails.dec_rate != null
                    ? (ProposalLiftDetails.dec_amount * 18) / 100
                    : getNumber_or_Blank4Null(ProposalLiftDetails.dec_taxamt);
                row.dec_total =
                  ProposalLiftDetails.dec_rate != null
                    ? ProposalLiftDetails.dec_rate *
                        Number(this.state.formData[`txtqty${no}`]) +
                      (ProposalLiftDetails.dec_amount * 18) / 100
                    : getNumber_or_Blank4Null(ProposalLiftDetails.vac_total);

                txttotalprice += row.dec_total;
              } else {
                txttotalprice += row.dec_total;
              }
              return row;
            });
          this.setState(
            {
              formData: {
                ...this.state.formData,
                txttotalprice,
              },
              LiftDetails,
            },
            () => {
              this.findTotalCount();
            }
          );
        } else if (e.target.name.includes("txtname")) {
          this.findTotalCount();
          let no = Number(e.target.name.replace("txtname", "")),
            index = no - 1,
            ProposalLiftDetails = this.state.LiftDetails[index];
          this.setState(
            {
              LiftDetails: this.state.LiftDetails.map((row, curRowIndex) => {
                if (index == curRowIndex) {
                  row.vac_liftname = this.state.formData[`txtname${no}`];
                }
                return row;
              }),
            },
            () => {}
          );
        } else if (e.target.name === "txtBillingAdd") {
          if (this.state.formData.chkSameAdd == true) {
            this.setState({
              formData: {
                ...this.state.formData,
                txtSiteAdd: e.target.value,
              },
            });
          }
        } else if (e.target.name === "txtSiteAdd") {
          let chkSameAdd = this.state.formData.chkSameAdd;
          if (e.target.value != this.state.formData.txtBillingAdd) {
            chkSameAdd = false;
          }
          this.setState({
            formData: {
              ...this.state.formData,
              chkSameAdd,
            },
          });
        }
        if (
          e.target.name === "dtpDate" ||
          e.target.name === "txtBillingAdd" ||
          e.target.name === "txtSiteAdd" ||
          e.target.name === "txtBilling" ||
          e.target.name === "txtSite" ||
          e.target.name === "txtCust" ||
          e.target.name === "txtCustPhone" ||
          e.target.name === "txtEmail" ||
          e.target.name === "txtCusCity"
        ) {
          let dat_date =
            this.state.formData.dtpDate.length > 0
              ? get_DMY_from_YMD(this.state.formData.dtpDate)
              : "";
          let LiftDetails = this.state.LiftDetails.map((row) => {
            return {
              ...row,
              dat_date: dat_date,
              dat_date_YMD: this.state.formData.dtpDate,
              vac_companyadd: this.state.formData.txtBillingAdd,
              vac_companycity: this.state.formData.txtCusCity,
              vac_companyname: this.state.formData.txtBilling,
              vac_emailid: this.state.formData.txtEmail,
              vac_mobileno: this.state.formData.txtCustPhone,
              vac_name: this.state.formData.txtCust,
              vac_sitename: this.state.formData.txtSite,
              vac_siteaddress: this.state.formData.txtSiteAdd,
            };
          });

          this.setState({
            LiftDetails,
          });
        }
      }
    );
  };

  handleOnCheckChange = (e) => {
    this.setState(
      {
        formData: { ...this.state.formData, [e.target.name]: e.target.checked },
      },
      () => {
        if (e.target.name == "chkSameAdd") {
          let txtSiteAdd = "";
          if (e.target.checked == true) {
            txtSiteAdd = this.state.formData.txtBillingAdd;
          }
          this.setState({
            formData: {
              ...this.state.formData,
              txtSiteAdd: txtSiteAdd,
            },
          });
        }
      }
    );
  };

  handleARDonActionClick_Delete = (row) => {
    const EditDepartment = row.getValue("vac_department");
    this.DeleteData(EditDepartment);
  };

  handleOnChangeTab = (event, newValue) => {
    this.setState({
      formData: { ...this.state.formData, value: newValue },
    });
  };

  handleOnNewEntryClick = () => {
    let ARD = null;
    this.setState({ ARD }, () => {
      ARD = (
        <AlertResponsiveDialog
          labelDisagree="Close"
          alertMessage={""}
          defaultOpenDialog={true}
          onYesClickCloseIfExeSuccessfully={true}
          handleOnClickNo={() => {}}
          maxWidthClass="100%"
        />
      );
      this.setState({ ARD });
    });
  };

  findTotalCount = () => {
    let txtTotal = 0;

    for (let i = 1; i <= Number(this.state.formData.txtUnique); i++) {
      let qtyValue = Number(this.state.formData[`txtqty${i}`]) || 0;
      txtTotal += qtyValue;
    }

    this.setState(
      {
        formData: {
          ...this.state.formData,
          txtTotal,
        },
      },
      () => {}
    );
  };

  NewLift = () => {
    // document
    //   .getElementById(`txtname${Number(this.state.formData.txtUnique) + 1}`)
    //   .focus();
    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems={"center"}
        spacing={2}
      >
        <Grid item>
          <CtTxt
            id={`txtname${Number(this.state.formData.txtUnique) + 1}`}
            value={
              this.state.formData[
                `txtname${Number(this.state.formData.txtUnique) + 1}`
              ]
            }
            label="Lift Name"
            handleOnChange={this.handleOnChange}
            maxLength={11}
            width={200}
            onKeyDown={this.props.onKeyDown}
            nextCtrlID={`txtqty${Number(this.state.formData.txtUnique) + 1}`}
          />
        </Grid>
        <Grid item>
          <CtTxtAmt
            id={`txtqty${Number(this.state.formData.txtUnique) + 1}`}
            value={
              this.state.formData[
                `txtqty${Number(this.state.formData.txtUnique) + 1}`
              ]
            }
            label="Lift Qty"
            handleOnChange={this.handleOnChange}
            maxLength={11}
            width={95}
          />
        </Grid>
        <Grid item marginTop={1}>
          <CtBtn
            className="secondary-button"
            label="Save"
            iconName="save"
            onClick={this.newLiftAdd}
            firstletterBig={false}
          />
        </Grid>
      </Grid>
    );
  };

  validateAddNewLift = () => {
    let result = false;
    if (
      this.state.formData[
        `txtname${Number(this.state.formData.txtUnique) + 1}`
      ] == "" ||
      this.state.formData[
        `txtname${Number(this.state.formData.txtUnique) + 1}`
      ] == undefined
    ) {
      this.props.toastErrorMsg(
        `Enter Lift Name`,
        `txtname${Number(this.state.formData.txtUnique) + 1}`
      );
    } else if (
      this.state.formData[
        `txtqty${Number(this.state.formData.txtUnique) + 1}`
      ] == undefined
    ) {
      this.props.toastErrorMsg(
        `Enter Valid Lift Count`,
        `txtqty${Number(this.state.formData.txtUnique) + 1}`
      );
    } else if (
      Number(
        this.state.formData[
          `txtqty${Number(this.state.formData.txtUnique) + 1}`
        ]
      ) <= 0
    ) {
      this.props.toastErrorMsg(
        `Enter Valid Lift Count`,
        `txtqty${Number(this.state.formData.txtUnique) + 1}`
      );
    } else {
      result = true;
    }
    return result;
  };

  newLiftAdd = () => {
    if (this.validateAddNewLift()) {
      this.props.notifyProcessing();
      let txtTotal =
        Number(this.state.formData.txtTotal) +
        Number(
          this.state.formData[
            `txtqty${Number(this.state.formData.txtUnique) + 1}`
          ]
        );
      const reqData = {
        Op: "NewProposalLift",
        vac_branchname: this.state.formData.cmbBranch,
        vac_refno: "",
        dat_date: this.state.formData.dtpDate,
        vac_sitename: this.state.formData.txtSite,
        vac_siteaddress: this.state.formData.txtSiteAdd,
        vac_companyname: this.state.formData.txtBilling,
        vac_companyadd: this.state.formData.txtBillingAdd,
        vac_name: this.state.formData.txtCust,
        vac_companycity: this.state.formData.txtCusCity,
        vac_mobileno: this.state.formData.txtCustPhone,
        vac_emailid: this.state.formData.txtEmail,
        int_status: 1,
        bint_qty: Number(
          this.state.formData[
            `txtqty${Number(this.state.formData.txtUnique) + 1}`
          ]
        ),
        vac_liftname:
          this.state.formData[
            `txtname${Number(this.state.formData.txtUnique) + 1}`
          ],
        bint_summarysrno: this.state.formData.bint_summarysrno,
        bint_totalliftcount: txtTotal,
      };
      fetchProposal(reqData)
        .then((res) => {
          this.props.closeUpdateProcessing();

          let LiftDetails =
              res.data.MoreLiftInfo && res.data.MoreLiftInfo.length > 0
                ? res.data.MoreLiftInfo
                : [],
            txtUnique = Number(this.state.formData.txtUnique) + 1;
          this.setState({
            LiftDetails,
            formData: {
              ...this.state.formData,
              txtTotal,
              txtUnique,
            },
            ARD: null,
          });
        })
        .catch((error) => {
          console.log("Unknown error occurred in onFormLoad.", error);
        });
    }

    // if (this.validateAddNewLift()) {
    //   this.props.notifyProcessing();
    //   const reqData = {
    //     Op: "NewProposalLift",
    //     vac_branchname: this.state.formData.cmbBranch,
    //     vac_brcode: this.state.formData.vac_brcode,
    //     vac_refno: this.state.formData.txtRef,
    //     dat_date: this.state.formData.dtpDate,
    //     vac_sitename: "",
    //     bint_qty: 0,
    //     vac_liftname: this.state.formData.cmbUnit,
    //     bint_summarysrno: 0,
    //   };
    //   fetchrailprice(reqData)
    //     .then((res) => {
    //       this.props.updateProcessing(res.data.msgType, res.data.message);
    //     })
    //     .catch((error) => {
    //       console.log(
    //         "Unknown error occurred in Save Rail Price Master .",
    //         error
    //       );
    //     });
    // }
  };

  openLiftDetails = () => {
    let ARD = null;
    let NewLift = this.NewLift();
    this.setState({ ARD }, () => {
      ARD = (
        <AlertResponsiveDialog
          labelDisagree="Close"
          alertHeading={"Lift Dialog"}
          alertMessage={NewLift}
          defaultOpenDialog={true}
          onYesClickCloseIfExeSuccessfully={true}
          handleOnClickNo={() => {}}
          maxWidthClass="100%"
        />
      );
      this.setState({ ARD });
    });
  };

  handleMouseEnter = () => {
    this.setState({
      showSaveBtn: true,
    });
  };

  handleMouseLeave = () => {
    this.setState({
      showSaveBtn: false,
    });
  };

  // updateDetails = (id, Details) => {
  //   let TermsConditionDetails =
  //       this.state.formData.TermsConditionDetails.length > 0
  //         ? this.state.formData.TermsConditionDetails
  //         : this.state.TermsConditionDetailsInfo,
  //     PreparatoryWork =
  //       this.state.formData.PreparatoryWork.length > 0
  //         ? this.state.formData.PreparatoryWork
  //         : this.state.PreparatoryWorkInfo,
  //     TermsPayment =
  //       this.state.formData.TermsPayment.length > 0
  //         ? this.state.formData.TermsPayment
  //         : this.state.TermsPaymentInfo,
  //     TermsCancellation =
  //       this.state.formData.TermsCancellation.length > 0
  //         ? this.state.formData.TermsCancellation
  //         : this.state.TermsCancellationInfo,
  //     Note =
  //       this.state.formData.Note.length > 0
  //         ? this.state.formData.Note
  //         : this.state.NoteInfo;

  //   if (id === "TermsConditionDetails") {
  //     TermsConditionDetails = Details;
  //   } else if (id === "PreparatoryWork") {
  //     PreparatoryWork = Details;
  //   } else if (id === "TermsPayment") {
  //     TermsPayment = Details;
  //   } else if (id === "TermsCancellation") {
  //     TermsCancellation = Details;
  //   } else if (id === "Note") {
  //     Note = Details;
  //   }

  //   // console.log("Note", Note);

  //   this.setState(
  //     {
  //       formData: {
  //         ...this.state.formData,
  //         TermsConditionDetails: TermsConditionDetails,
  //         PreparatoryWork: PreparatoryWork,
  //         TermsPayment: TermsPayment,
  //         TermsCancellation: TermsCancellation,
  //         Note: Note,
  //       },
  //     },
  //     () => {
  //       // console.log(
  //       //   "this.state.TermsConditionDetails",
  //       //   this.state.formData.TermsConditionDetails
  //       // );
  //       // console.log(
  //       //   "this.state.formData.PreparatoryWork",
  //       //   this.state.formData.PreparatoryWork
  //       // );
  //       // console.log(
  //       //   "this.state.formData.PreparatoryWork",
  //       //   this.state.formData.PreparatoryWork
  //       // );
  //       // console.log(
  //       //   "this.state.formData.TermsPayment",
  //       //   this.state.formData.TermsPayment
  //       // );
  //       // console.log(
  //       //   "this.state.formData.TermsCancellation",
  //       //   this.state.formData.TermsCancellation
  //       // );
  //       // console.log("this.state.formData.Note", this.state.formData.Note);
  //     }
  //   );
  // };

  // clearDetails = (id) => {
  //   if (id === "TermsConditionDetails") {
  //     console.log("call", id);
  //     this.setState({
  //       formData: {
  //         ...this.state.formData,
  //         TermsConditionDetails: "",
  //       },
  //       TermsConditionDetailsInfo: "",
  //     });
  //   } else if (id === "PreparatoryWork") {
  //     this.setState({
  //       formData: {
  //         ...this.state.formData,
  //         PreparatoryWork: "",
  //       },
  //       PreparatoryWorkInfo: "",
  //     });
  //   } else if (id === "TermsPayment") {
  //     this.setState({
  //       formData: {
  //         ...this.state.formData,
  //         TermsPayment: "",
  //       },
  //       TermsPaymentInfo: "",
  //     });
  //   } else if (id === "TermsCancellation") {
  //     this.setState({
  //       formData: {
  //         ...this.state.formData,
  //         TermsCancellation: "",
  //       },
  //       TermsCancellationInfo: "",
  //     });
  //   } else if (id === "Note") {
  //     this.setState({
  //       formData: {
  //         ...this.state.formData,
  //         Note: "",
  //       },
  //       NoteInfo: "",
  //     });
  //   }
  // };

  openFinalizeARD = (index, no, LiftName) => {
    let ARD = null;
    this.setState({ ARD }, () => {
      ARD = (
        <AlertResponsiveDialog
          labelDisagree="Close"
          alertHeading={"Proposal Finalize Dialog"}
          alertTitle={`Do You Want To Create Final Proposal For Lift : ${LiftName} ?`}
          defaultOpenDialog={true}
          alertIcon=""
          labelPrimary={"Yes"}
          handleOnClickYes={() => {
            this.finalizeYes(index, no);
          }}
          onYesClickCloseIfExeSuccessfully={true}
          handleOnClickNo={() => {}}
          maxWidthClass="100%"
        />
      );
      this.setState({ ARD });
    });
  };

  finalizeYes = (index, no) => {
    // console.log("yess");
    this.setState(
      {
        LiftDetails: this.state.LiftDetails.map((row, curRowIndex) => {
          if (index == curRowIndex) {
            row.int_status = 4;
          }
          return row;
        }),
      },
      () => {
        this.saveBtn(index, no, 1);
      }
    );
  };

  validateLiftCard = (no) => {
    let result = false;
    if (this.state.formData[`txtname${no}`].length <= 0) {
      this.props.toastErrorMsg(`Enter ${no}. Lift Name`, `txtname${no}`);
    } else if (Number(this.state.formData[`txtqty${no}`]) <= 0) {
      this.props.toastErrorMsg(`Enter ${no}. Lift Qty`, `txtqty${no}`);
    } else {
      result = true;
    }
    return result;
  };

  saveBtn = (index, no, int_final = 0) => {
    if (this.validateLiftCard(no)) {
      let ProposalLiftDetails = this.state.LiftDetails[index];
      // console.log("ProposalLiftDetails", ProposalLiftDetails);
      this.props.notifyProcessing();
      const reqData = {
        Op: "UpdateProposalLiftInfo",
        vac_brcode: this.state.formData.vac_brcode,
        TblYear: this.state.formData.TblYear,
        bint_totalliftcount: this.state.formData.txtTotal,
        vac_branchname: getString_or_Blank4Null(ProposalLiftDetails.vac_brname),
        bint_srno: getString_or_Blank4Null(ProposalLiftDetails.bint_srno),
        bint_revno: getString_or_Blank4Null(ProposalLiftDetails.bint_revno),
        dat_date: getString_or_Blank4Null(ProposalLiftDetails.dat_date_YMD),
        vac_refno: getString_or_Blank4Null(ProposalLiftDetails.vac_refno),
        vac_companyname: getString_or_Blank4Null(
          ProposalLiftDetails.vac_companyname
        ),
        vac_companyadd: getString_or_Blank4Null(
          ProposalLiftDetails.vac_companyadd
        ),
        vac_siteaddress: getString_or_Blank4Null(
          ProposalLiftDetails.vac_siteaddress
        ),
        vac_name: getString_or_Blank4Null(ProposalLiftDetails.vac_name),
        vac_companycity: getString_or_Blank4Null(
          ProposalLiftDetails.vac_companycity
        ),
        vac_openingheading: getString_or_Blank4Null(
          ProposalLiftDetails.vac_openingheading
        ),
        vac_mobileno: getString_or_Blank4Null(ProposalLiftDetails.vac_mobileno),
        vac_emailid: getString_or_Blank4Null(ProposalLiftDetails.vac_emailid),
        vac_lifttype: getString_or_Blank4Null(ProposalLiftDetails.vac_lifttype),
        vac_liftname: this.state.formData[`txtname${no}`],
        bint_noofbasement: getString_or_Blank4Null(
          ProposalLiftDetails.bint_noofbasement
        ),
        bint_groundfloor: getString_or_Blank4Null(
          ProposalLiftDetails.int_groundfloor
        ),
        bint_noofloor: getString_or_Blank4Null(
          ProposalLiftDetails.bint_nooffloor
        ),
        vac_floor: getString_or_Blank4Null(ProposalLiftDetails.vac_floor),
        vac_openingsameside: getString_or_Blank4Null(
          ProposalLiftDetails.vac_openingsameside
        ),
        dec_floorheight: getNumber_or_Blank4Null(
          ProposalLiftDetails.dec_floorheight
        ),
        bint_noofstops: getString_or_Blank4Null(
          ProposalLiftDetails.bint_noofstops
        ),
        bint_noofopening: getString_or_Blank4Null(
          ProposalLiftDetails.bint_noofopening
        ),
        dec_hostwaywide: getNumber_or_Blank4Null(
          ProposalLiftDetails.dec_hoistwaywide
        ),
        dec_hostwaydeep: getNumber_or_Blank4Null(
          ProposalLiftDetails.dec_hoistwaydeep
        ),
        vac_hoistwaystructure: getString_or_Blank4Null(
          ProposalLiftDetails.vac_hoistwaystructure
        ),
        vac_machineroomavailable: getString_or_Blank4Null(
          ProposalLiftDetails.vac_machineroomavailable
        ),
        vac_siteremark: getString_or_Blank4Null(
          ProposalLiftDetails.vac_siteremark
        ),
        vac_machine: getString_or_Blank4Null(ProposalLiftDetails.vac_machine),
        vac_machineroom: getString_or_Blank4Null(
          ProposalLiftDetails.vac_machineroom
        ),
        vac_machineplacement: getString_or_Blank4Null(
          ProposalLiftDetails.vac_machineplacement
        ),
        vac_machineplacementother: getString_or_Blank4Null(
          ProposalLiftDetails.vac_machineplacementother
        ),
        vac_counterwtposition: getString_or_Blank4Null(
          ProposalLiftDetails.vac_counterwtposition
        ),
        vac_machineremark: getString_or_Blank4Null(
          ProposalLiftDetails.vac_machineremark
        ),
        vac_doortype: getString_or_Blank4Null(ProposalLiftDetails.vac_doortype),
        vac_subdoortype: getString_or_Blank4Null(
          ProposalLiftDetails.vac_subdoortype
        ),
        vac_cardoorselection: getString_or_Blank4Null(
          ProposalLiftDetails.vac_cardoorselection
        ),
        vac_visiblepanel: getString_or_Blank4Null(
          ProposalLiftDetails.vac_visiblepanel
        ),
        vac_dooroperation: getString_or_Blank4Null(
          ProposalLiftDetails.vac_dooroperation
        ),
        vac_cardoorenclosure: getString_or_Blank4Null(
          ProposalLiftDetails.vac_cardoorenclosure
        ),
        vac_dooropeningwidth: getString_or_Blank4Null(
          ProposalLiftDetails.vac_dooropeningwidth
        ),
        dec_dooropeningwidth: getNumber_or_Blank4Null(
          ProposalLiftDetails.dec_dooropeningwidthother
        ),
        dec_dooropeningheight: getNumber_or_Blank4Null(
          ProposalLiftDetails.dec_dooropeningheight
        ),
        vac_doorframedby: getString_or_Blank4Null(
          ProposalLiftDetails.vac_doorframedby
        ),
        vac_frontcardoorenclosure: getString_or_Blank4Null(
          ProposalLiftDetails.vac_frontcardoorenclosure
        ),
        dec_carinsidewide: getNumber_or_Blank4Null(
          ProposalLiftDetails.dec_carinsidewide
        ),
        dec_carinsidedeep: getNumber_or_Blank4Null(
          ProposalLiftDetails.dec_carinsidedeep
        ),
        vac_carenclosure: getString_or_Blank4Null(
          ProposalLiftDetails.vac_carenclosure
        ),
        vac_carinsideheight: getString_or_Blank4Null(
          ProposalLiftDetails.vac_carinsideheight
        ),
        dec_carinsideheight: getNumber_or_Blank4Null(
          ProposalLiftDetails.dec_carinsideheight
        ),
        dec_carcarpetarea: getNumber_or_Blank4Null(
          ProposalLiftDetails.dec_carcarpetarea
        ),
        vac_carenclosureinfo: getString_or_Blank4Null(
          ProposalLiftDetails.vac_carenclosureinfo
        ),
        vac_carentrance: getString_or_Blank4Null(
          ProposalLiftDetails.vac_carentrance
        ),
        vac_hoistwayentrance: getString_or_Blank4Null(
          ProposalLiftDetails.vac_hoistwayentrance
        ),
        vac_drivetype: getString_or_Blank4Null(ProposalLiftDetails.vac_drive),
        vac_controlleroperation: getString_or_Blank4Null(
          ProposalLiftDetails.vac_controller
        ),
        vac_ard: getString_or_Blank4Null(ProposalLiftDetails.vac_ard),
        vac_onls: getString_or_Blank4Null(ProposalLiftDetails.vac_olns),
        vac_controloperation: getString_or_Blank4Null(
          ProposalLiftDetails.vac_controloperation
        ),
        bint_noofpassenger: getString_or_Blank4Null(
          ProposalLiftDetails.bint_noofpassenger
        ),
        dec_loadcapacity: getNumber_or_Blank4Null(ProposalLiftDetails.dec_load),
        vac_speed: getString_or_Blank4Null(ProposalLiftDetails.vac_speed),
        dec_speed: getNumber_or_Blank4Null(ProposalLiftDetails.dec_speed),
        vac_powersupply: getString_or_Blank4Null(
          ProposalLiftDetails.vac_supply
        ),
        vac_collop: getString_or_Blank4Null(ProposalLiftDetails.vac_coplop),
        vac_displaytype: getString_or_Blank4Null(
          ProposalLiftDetails.vac_displaytype
        ),
        vac_security: getString_or_Blank4Null(ProposalLiftDetails.vac_security),
        vac_signal: getString_or_Blank4Null(ProposalLiftDetails.vac_signal),
        bint_qty: Number(this.state.formData[`txtqty${no}`]),
        dec_rate: getNumber_or_Blank4Null(ProposalLiftDetails.dec_rate),
        dec_amount:
          ProposalLiftDetails.dec_rate != null
            ? Math.round(
                Number(ProposalLiftDetails.dec_rate) *
                  Number(this.state.formData[`txtqty${no}`])
              )
            : getNumber_or_Blank4Null(ProposalLiftDetails.dec_amount),
        dec_gst:
          ProposalLiftDetails.dec_rate != null
            ? Math.round((Number(ProposalLiftDetails.dec_amount) * 18) / 100)
            : getNumber_or_Blank4Null(ProposalLiftDetails.dec_taxamt),
        dec_total:
          ProposalLiftDetails.dec_rate != null
            ? Math.round(
                Number(ProposalLiftDetails.dec_rate) *
                  Number(this.state.formData[`txtqty${no}`]) +
                  (Number(ProposalLiftDetails.dec_amount) * 18) / 100
              )
            : getNumber_or_Blank4Null(ProposalLiftDetails.vac_total),
        vac_username: getString_or_Blank4Null(ProposalLiftDetails.vac_username),
        vac_usermobileno: getString_or_Blank4Null(
          ProposalLiftDetails.vac_usermobileno
        ),
        int_final:
          int_final == 1
            ? int_final
            : getNumber_or_Blank4Null(ProposalLiftDetails.int_final),
        int_status: getString_or_Blank4Null(ProposalLiftDetails.int_status),
        ReverseOpening: getString_or_Blank4Null(
          ProposalLiftDetails.ReverseOpening
        ),
        LandingDoorEnclouser: getString_or_Blank4Null(
          ProposalLiftDetails.LandingDoorEnclosure
        ),
      };
      fetchProposal(reqData)
        .then((res) => {
          this.props.updateProcessing(res.data.msgType, res.data.message);
          if (res.data.msgType === "success") {
            this.setState(
              {
                ARD: null,
                formData: {
                  ...this.state.formData,
                  [`allBtnHide${no}`]: false,
                },
                // LiftDetails: this.state.LiftDetails.map(
                //   (row, curRowIndex) => {
                //     if (index == curRowIndex) {
                //       row.vac_liftname =
                //         this.state.formData[`txtname${no}`];
                //       row.bint_qty = this.state.formData[`txtqty${no}`];
                //       row.dec_rate = getNumber_or_Blank4Null(
                //         ProposalLiftDetails.dec_rate
                //       );
                //       row.dec_amount =
                //         ProposalLiftDetails.dec_rate != null
                //           ? ProposalLiftDetails.dec_rate *
                //             Number(this.state.formData[`txtqty${no}`])
                //           : getNumber_or_Blank4Null(
                //               ProposalLiftDetails.dec_amount
                //             );
                //       row.dec_taxamt =
                //         ProposalLiftDetails.dec_rate != null
                //           ? (ProposalLiftDetails.dec_amount * 18) / 100
                //           : getNumber_or_Blank4Null(
                //               ProposalLiftDetails.dec_taxamt
                //             );
                //       row.dec_total =
                //         ProposalLiftDetails.dec_rate != null
                //           ? ProposalLiftDetails.dec_rate *
                //               Number(this.state.formData[`txtqty${no}`]) +
                //             (ProposalLiftDetails.dec_amount * 18) / 100
                //           : getNumber_or_Blank4Null(
                //               ProposalLiftDetails.vac_total
                //             );
                //     }
                //     return row;
                //   }
                // ),
              },
              () => {}
            );
          }
        })
        .catch((error) => {
          console.log(
            "Unknown error occurred in Update Proposal Info .",
            error
          );
        });
    }
  };

  print = (formate) => {
    this.updateProposal();
    // this.props.notifyProcessing();
    let reqData = [
      { name: "year", value: this.state.formData.TblYear },
      { name: "vac_refno", value: this.state.formData.txtRef },
      { name: "formate", value: formate },
    ];

    // Create a query string from the request data
    let queryString = reqData
      .map(
        (data) =>
          encodeURIComponent(data.name) + "=" + encodeURIComponent(data.value)
      )
      .join("&");

    // Set the URL of the popup window
    let popupURL = apiURL + "Print?" + queryString;
    // let popupURL = apiURL + "Print";

    // Open the popup window
    window.open(
      popupURL,
      "Print",
      "width=" +
        window.screen.availWidth +
        ",height=" +
        window.screen.availHeight
    );

    this.props.updateProcessing("success", "PDF Open in popup.");
  };

  convertListToArray = (htmlString, id) => {
    let stringToArray = htmlString
      .split(/<\/?li>/)
      .filter(
        (item) =>
          item.trim() !== "" &&
          !item.includes("<ol>") &&
          !item.includes("</ol>")
      )
      .map((item) => item.trim());

    if (stringToArray.length > 0) {
      this.props.notifyProcessing();
      const reqData = {
        Op: "UpdateTermsAndCondition",
        id: id,
        masterValues: stringToArray,
      };
      fetchProposal(reqData)
        .then((res) => {
          this.props.updateProcessing(res.data.msgType, res.data.message);
          this.setState({ ARD: null });
        })
        .catch((error) => {
          console.log("Unknown error occurred in Update Master", error);
        });
    }
  };

  openRichTextBox = (Heading, id, TxtChange) => {
    this.setState({ ARD: null }, () => {
      let ARD = (
        <AlertResponsiveDialog
          labelDisagree="Close"
          labelPrimary="Update In Master"
          handleOnClickYes={() => {
            // console.log("id", this.state[id]);
            this.convertListToArray(this.state[id], id);
          }}
          fullscreenOnly={true}
          alertIcon=""
          alertHeading={Heading}
          alertTitle={
            <MoreInfoTextEditor
              TxtChange={TxtChange}
              Txtid={id}
              updateDetails={this.updateDetails}
            />
          }
          alertMessage=""
          defaultOpenDialog={true}
          onYesClickCloseIfExeSuccessfully={true}
        />
      );

      this.setState({ ARD });
    });
  };

  openRichTextBox2 = (Heading, id, TxtChange) => {
    this.setState({ ARD: null }, () => {
      let ARD = (
        <AlertResponsiveDialog
          labelDisagree="Close"
          fullscreenOnly={true}
          alertIcon=""
          alertHeading={Heading}
          alertTitle={
            <MoreInfoTextEditorWithMaster
              TxtChange={TxtChange}
              Txtid={id}
              updateDetails={this.updateDetails}
              MasterNotes={this.state.MasterNotes}
            />
          }
          alertMessage=""
          defaultOpenDialog={true}
          onYesClickCloseIfExeSuccessfully={true}
        />
      );

      this.setState({ ARD });
    });
  };

  updateDetails = (id, Details) => {
    let TermsConditionDetailsInfo = this.state.TermsConditionDetailsInfo,
      PreparatoryWorkInfo = this.state.PreparatoryWorkInfo,
      TermsCancellationInfo = this.state.TermsCancellationInfo,
      TermsPaymentInfo = this.state.TermsPaymentInfo,
      NoteInfo = this.state.NoteInfo;

    if (id === "TermsConditionDetailsInfo") {
      TermsConditionDetailsInfo = Details;
    } else if (id === "PreparatoryWorkInfo") {
      PreparatoryWorkInfo = Details;
    } else if (id === "TermsCancellationInfo") {
      TermsCancellationInfo = Details;
    } else if (id === "TermsPaymentInfo") {
      TermsPaymentInfo = Details;
    } else if (id === "NoteInfo") {
      NoteInfo = Details;
    }

    this.setState(
      {
        TermsConditionDetailsInfo,
        PreparatoryWorkInfo,
        TermsCancellationInfo,
        TermsPaymentInfo,
        NoteInfo,
      },
      () => {}
    );
  };

  clear = (id) => {
    if (id == "TermsConditionDetailsInfo") {
      this.setState({
        TermsConditionDetailsInfo: undefined,
      });
    } else if (id == "PreparatoryWorkInfo") {
      this.setState({
        PreparatoryWorkInfo: undefined,
      });
    } else if (id == "TermsCancellationInfo") {
      this.setState({
        TermsCancellationInfo: undefined,
      });
    } else if (id == "TermsPaymentInfo") {
      this.setState({
        TermsPaymentInfo: undefined,
      });
    } else if (id == "NoteInfo") {
      this.setState({
        NoteInfo: undefined,
      });
    }
  };

  //#endregion handle

  render() {
    const buttonStyle = {
        backgroundColor: "#177cdd",
        color: "white",
        border: "none",
        height: "30px",
        width: "auto",
        fontSize: "13px",
        minWidth: "70px",
        fontWeight: 600,
      },
      borderRadius = {
        borderRadius: "5px",
      };

    const UniqueLift = (row, index) => {
      const disabledButtonStyle = {
        backgroundColor: "#e0e0e0",
        color: "#979797",
        cursor: "not-allowed",
        border: "none",
        height: "30px",
        width: "auto",
        fontSize: "13px",
        minWidth: "70px",
        fontWeight: 600,
      };

      let no = Number(index + 1);

      return (
        <div
          style={{
            border: "1px solid #e0e0e0",
            width: "100%",
            borderRadius: "20px",
            height: "100%",
            // backgroundColor: "#FFB8B1",
            // backgroundColor: "#9ED8A1",
            // backgroundColor: "#FFD75F",
            // backgroundColor: "#B7E3D9",
            // backgroundColor:
            //   no % 2 == 0
            //     ? "#B7E3D9"
            //     : no % 3 == 0
            //     ? "#9ED8A1"
            //     : no % 5 == 0
            //     ? "#FFD75F"
            //     : "#FFB8B1",

            backgroundColor:
              row.int_status == 1
                ? "#FFB8B1"
                : row.int_status == 2
                ? "#FFD75F"
                : row.int_status == 3
                ? "#9ED8A1"
                : "#B7E3D9",
            // padding: "0 10px 10px 10px",
          }}
          className="liftCard"
        >
          <Grid container direction="row" spacing={1}>
            <Grid item lg={1} md={1} sm={1} xs={2}>
              <Grid
                container
                direction="row"
                spacing={1}
                justifyContent={"flex-start"}
              >
                <Grid item marginLeft={2} marginTop={0.5}>
                  <b>{no}.</b>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={11} md={11} sm={11} xs={10}>
              <Grid
                container
                direction="row"
                spacing={1}
                justifyContent={"flex-end"}
              >
                <Grid item>
                  <button
                    type="button"
                    style={
                      this.props.userPrivilege["Entry:Proposal"] != "M" ||
                      row.int_status == 4
                        ? disabledButtonStyle
                        : { ...buttonStyle, ...borderRadius }
                    }
                    onClick={
                      row.int_status != 4
                        ? () => {
                            this.saveBtn(index, no);
                          }
                        : () => {}
                    }
                    className={"btn-save"}
                  >
                    Save
                  </button>
                </Grid>
                <Grid item>
                  <button
                    type="button"
                    style={{
                      ...disabledButtonStyle,
                      ...borderRadius,
                    }}
                    className={"btn-clone"}
                  >
                    Clone
                  </button>
                </Grid>
                <Grid item>
                  <button
                    type="button"
                    style={
                      row.int_status == 3 &&
                      this.props.userPrivilege["Entry:Proposal"] == "M"
                        ? buttonStyle
                        : disabledButtonStyle
                    }
                    onClick={
                      row.int_status == 3
                        ? () => {
                            this.openFinalizeARD(
                              index,
                              no,
                              this.state.formData[`txtname${no}`]
                            );
                          }
                        : () => {}
                    }
                    className={"btn-print"}
                  >
                    Finalize
                  </button>
                </Grid>
                <Grid item>
                  <button
                    type="button"
                    style={{
                      ...(this.state.formData[`allBtnHide${no}`]
                        ? disabledButtonStyle
                        : buttonStyle),
                    }}
                    className={"btn-more-info"}
                    onClick={
                      this.state.formData[`allBtnHide${no}`]
                        ? () => {
                            "";
                          }
                        : () => {
                            this.props.navigateTo("MoreInfo", {
                              ProposalLiftDetails:
                                this.state.LiftDetails[index],
                              TblYear: this.state.formData.TblYear,
                              txtTotal: this.state.formData.txtTotal,
                              vac_brcode: this.state.formData.vac_brcode,
                              bint_summarysrno:
                                this.state.formData.bint_summarysrno,
                              dtpDate: this.state.formData.dtpDate,
                              cmbBranch: this.state.formData.cmbBranch,
                            });
                          }
                    }
                  >
                    More Info
                  </button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems={"flex-start"}
            spacing={2}
            marginTop={0}
          >
            <Grid
              item
              paddingTop={0}
              paddingBottom={1}
              paddingRight={2}
              paddingLeft={4}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems={"center"}
                marginTop={0}
                paddingLeft={2}
                spacing={2}
              >
                {/* <Grid item marginTop={2}>
                  {no}.
                </Grid> */}
                <Grid item className="Lift-Name">
                  <CtTxt
                    id={`txtname${no}`}
                    value={this.state.formData[`txtname${no}`]}
                    label="Lift Name"
                    handleOnChange={this.handleOnChange}
                    maxLength={100}
                    width={"100%"}
                    onKeyDown={this.props.onKeyDown}
                    nextCtrlID={`txtqty${no}`}
                    disabled={row.int_status == 4 ? true : false}
                  />
                </Grid>
                <Grid item className="lift-qty">
                  <CtTxtAmt
                    id={`txtqty${no}`}
                    value={this.state.formData[`txtqty${no}`]}
                    label="Lift Qty"
                    handleOnChange={this.handleOnChange}
                    maxLength={100}
                    width={80}
                    disabled={row.int_status == 4 ? true : false}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item marginLeft={2}>
              <div>
                <b>{row.vac_lifttype == "PASSENGER" ? "Pass" : "Load"}</b> :{" "}
                {row.vac_lifttype == "PASSENGER"
                  ? getNumber_or_Blank4Null(row.bint_noofpassenger) + "P"
                  : getNumber_or_Blank4Null(row.dec_load) + "Kg"}
                <br /> <b>Floor</b> : {row.vac_floor}
                <br />
                <b>Stops</b> : {getNumber_or_Blank4Null(row.bint_noofstops)},{" "}
                <b>Opening </b>: {getNumber_or_Blank4Null(row.bint_noofopening)}
                <br />
                <b>Door Type</b> : {row.vac_doortype}
                <br />
                <b>Entrance</b> :{" "}
                {`${
                  row.vac_dooropeningwidth == "Other"
                    ? getNumber_or_Blank4Null(row.dec_dooropeningwidthother)
                    : getString_or_Blank4Null(row.vac_dooropeningwidth)
                } mm W x ${getNumber_or_Blank4Null(
                  row.dec_dooropeningheight
                )} mm H`}
                <br />
                <b>M/c Type</b> : {row.vac_machine}
              </div>
            </Grid>
            {/* <Grid item marginBottom={2}>
              <CtBtn label={"save"} width={"100%"} firstletterBig={false} />
            </Grid> */}
          </Grid>
        </div>
      );
    };

    return (
      <form>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          style={{
            position: "fixed",
            marginTop: "-20px",
            left: 0,
            backgroundColor: "white",
            zIndex: "100",
            boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
            padding: "10px",
          }}
          className="fix-Btn-Header-ProposalDas"
        >
          <Grid item>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <CtBtn
                  className="child-button"
                  variant={"outlined"}
                  label="List"
                  iconName="List"
                  onClick={() => {
                    this.props.navigateTo("Proposal");
                  }}
                  firstletterBig={false}
                />
              </Grid>
              <Grid item>
                <CtBtn
                  className="secondary-button"
                  label="Update"
                  iconName="save"
                  onClick={this.updateProposal}
                  disabled={this.props.userPrivilege["Entry:Proposal"] != "M"}
                />
              </Grid>
              <Grid item>
                <CtBtn
                  className="child-button"
                  variant={"outlined"}
                  label="PDF"
                  iconName="Pdf"
                  onClick={() => {
                    this.print("PDF");
                  }}
                  firstletterBig={false}
                />
              </Grid>
              <Grid item>
                <CtBtn
                  className="print-button"
                  label="Print"
                  iconName="print"
                  onClick={() => {
                    this.print("PRINT");
                  }}
                  firstletterBig={false}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          marginTop={1}
          paddingTop={"40px"}
          spacing={2}
          className="Unfreez-ProposalDas"
        >
          <Grid item>
            <CtTxtAmt
              id="txtTotal"
              value={this.state.formData.txtTotal}
              label="Total Lift Count"
              handleOnChange={this.handleOnChange}
              maxLength={11}
              width={150}
              onKeyDown={this.props.onKeyDown}
              disabled={true}
            />
          </Grid>
          <Grid item>
            <CtTxtAmt
              id="txtUnique"
              value={this.state.formData.txtUnique}
              label="Unique Lift Count"
              handleOnChange={this.handleOnChange}
              maxLength={11}
              width={150}
              disabled={true}
              onKeyDown={this.props.onKeyDown}
            />
          </Grid>

          <Grid item>
            <CtCmbEditable
              id="cmbBranch"
              label="Branch *"
              items={this.state.Branchs}
              value={this.state.formData.cmbBranch}
              handleOnChange={this.handleOnChange}
              width={190}
              maxLength={100}
              onKeyDown={this.props.onKeyDown}
              colID="vac_branch"
              nextCtrlID={"txtRef"}
              disabled={true}
            />
          </Grid>
          <Grid item>
            <CtTxt
              id="txtRef"
              value={this.state.formData.txtRef}
              label="Ref No."
              handleOnChange={this.handleOnChange}
              maxLength={50}
              width={200}
              onKeyDown={this.props.onKeyDown}
              nextCtrlID={"dtpDate"}
              disabled={true}
            />
          </Grid>
          <Grid item>
            <CtDtp
              id="dtpDate"
              value={this.state.formData.dtpDate}
              label="Date *"
              handleOnChange={this.handleOnChange}
              maxLength={50}
              onKeyDown={this.props.onKeyDown}
              accountYear={this.state.formData.TblYear}
              // nextCtrlID={"cmbEducation"}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          marginTop={1}
          spacing={2}
        >
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CtMultiLineText
              id="txtBilling"
              value={this.state.formData.txtBilling}
              label="Billing Name *"
              handleOnChange={this.handleOnChange}
              maxLength={50}
              width={"100%"}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CtMultiLineText
              id="txtBillingAdd"
              value={this.state.formData.txtBillingAdd}
              label="Billing Address (Without Billing Name)"
              handleOnChange={this.handleOnChange}
              maxLength={2000}
              width={"100%"}
            />
          </Grid>
        </Grid>
        {/* <Grid container direction="row" justifyContent="flex-end" marginTop={1}>
          <Grid item>
            <ControlledCheckbox
              id={"chkSameAdd"}
              label={"Same As Billing Address"}
              handleCheckChange={this.handleOnCheckChange}
              checkboxColor={"#3179ff"}
              checked={this.state.formData.chkSameAdd}
            />
          </Grid>
        </Grid> */}
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          spacing={2}
          marginTop={1}
        >
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CtMultiLineText
              id="txtSite"
              value={this.state.formData.txtSite}
              label="Site Name *"
              handleOnChange={this.handleOnChange}
              maxLength={50}
              width={"100%"}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CtMultiLineText
              id="txtSiteAdd"
              value={this.state.formData.txtSiteAdd}
              label="Site Address * (Without Site Name)"
              handleOnChange={this.handleOnChange}
              maxLength={2000}
              width={"100%"}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="flex-end" marginTop={1}>
          <Grid item>
            <ControlledCheckbox
              id={"chkSameAdd"}
              label={"Same As Billing Address"}
              handleCheckChange={this.handleOnCheckChange}
              checkboxColor={"#3179ff"}
              checked={this.state.formData.chkSameAdd}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          // marginTop={0}
          spacing={2}
        >
          <Grid item>
            <CtTxt
              id="txtCust"
              value={this.state.formData.txtCust}
              label="Customer Name *"
              handleOnChange={this.handleOnChange}
              maxLength={50}
              width={210}
              onKeyDown={this.props.onKeyDown}
            />
          </Grid>
          <Grid item>
            <CtTxt
              id="txtCusCity"
              value={this.state.formData.txtCusCity}
              label="Customer City *"
              handleOnChange={this.handleOnChange}
              maxLength={50}
              width={210}
              onKeyDown={this.props.onKeyDown}
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtCustPhone"
              value={this.state.formData.txtCustPhone}
              label="Customer Phone No *"
              handleOnChange={this.handleOnChange}
              maxLength={10}
              width={210}
              onKeyDown={this.props.onKeyDown}
            />
          </Grid>
          <Grid item>
            <CtTxt
              id="txtEmail"
              value={this.state.formData.txtEmail}
              label="Customer Email ID"
              handleOnChange={this.handleOnChange}
              maxLength={50}
              width={210}
            />
          </Grid>
          <Grid item>
            <CtTxtAmt
              id="txttotalprice"
              value={this.state.formData.txttotalprice}
              label="Total Price"
              handleOnChange={this.handleOnChange}
              maxLength={10}
              width={150}
              disabled={true}
              RupeeSymbol={true}
            />
          </Grid>
          <Grid item marginTop={2}>
            <Typography fontSize={18}>
              <b>TO,</b> <br />
              {this.state.formData.txtBilling.length > 0 ? (
                <b>
                  {this.state.formData.txtBilling + ","}
                  <br />
                </b>
              ) : (
                ""
              )}
              {this.state.formData.txtBillingAdd.length > 0 ? (
                <b>
                  {this.state.formData.txtBillingAdd + ","}
                  <br />
                </b>
              ) : (
                ""
              )}
              Proposal for Lift material supply for site of{" "}
              <b>
                {this.state.formData.txtSite.length > 0
                  ? this.state.formData.txtSite + ", "
                  : ""}
              </b>
              <b>{this.state.formData.txtSiteAdd}</b>. In accordance with the
              following details & specification.
            </Typography>
          </Grid>
        </Grid>
        <Box sx={{ width: "100%", typography: "body1" }} marginTop={3}>
          <TabContext value={this.state.formData.value}>
            <Box
              sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}
            >
              <TabList
                onChange={this.handleOnChangeTab}
                aria-label="lab API tabs example"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab
                  label="Lifts"
                  value="1"
                  TypographyProps={{ style: { fontWeight: "bold" } }}
                />
                <Tab
                  label="Terms And Condition"
                  value="2"
                  TypographyProps={{ style: { fontWeight: "bold" } }}
                />
                <Tab
                  label="Preparatory Work Entry"
                  value="3"
                  TypographyProps={{ style: { fontWeight: "bold" } }}
                />
                <Tab
                  label="Terms of Payment"
                  value="4"
                  TypographyProps={{ style: { fontWeight: "bold" } }}
                />
                <Tab
                  label="Terms of Cancellation"
                  value="5"
                  TypographyProps={{ style: { fontWeight: "bold" } }}
                />
                <Tab
                  label="Note"
                  value="6"
                  TypographyProps={{ style: { fontWeight: "bold" } }}
                />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                // spacing={6}
                spacing={2}
              >
                <Grid item>
                  <span style={{ backgroundColor: "#FFB8B1", padding: "6px" }}>
                    1. Incomplete Data
                  </span>
                </Grid>
                <Grid item>
                  <span style={{ backgroundColor: "#FFD75F", padding: "6px" }}>
                    2. Incomplete Price
                  </span>
                </Grid>
                <Grid item>
                  <span style={{ backgroundColor: "#9ED8A1", padding: "6px" }}>
                    3. Pending For Finalize{" "}
                  </span>
                </Grid>
                <Grid item>
                  <span style={{ backgroundColor: "#B7E3D9", padding: "6px" }}>
                    4. Finalized
                  </span>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                marginTop={2}
              >
                <Grid item marginTop={1}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "#757575",
                      fontSize: "20px",
                    }}
                  >
                    Unique Lift
                  </Typography>
                </Grid>
                <Grid item marginTop={0.5}>
                  <CtBtn
                    className="secondary-button"
                    label="Add New Lift"
                    iconName="new"
                    firstletterBig={false}
                    onClick={this.openLiftDetails}
                    disabled={this.props.userPrivilege["Entry:Proposal"] != "M"}
                  />
                </Grid>
              </Grid>
              <Grid container marginTop={2} direction={"row"} spacing={2}>
                {/* {Array.from({ length: this.state.formData.txtUnique }).map(
                  (_, index) => (
                    <Grid item key={index}>
                      {UniqueLift(index + 1)}
                    </Grid>
                  )
                )} */}
                {this.state.LiftDetails.map((row, index) => (
                  <Grid item key={index}>
                    {UniqueLift(row, index)}
                  </Grid>
                ))}
              </Grid>
            </TabPanel>
            <TabPanel value="2">
              {/* <Terms
                TermsCondition={this.state.TermsConditionDetailsInfo}
                PreparatoryWorkEntry={this.state.PreparatoryWorkInfo}
                TermsPayment={this.state.TermsPaymentInfo}
                TermsCancellation={this.state.TermsCancellationInfo}
                Note={this.state.NoteInfo}
                updateDetails={this.updateDetails}
                clearDetails={this.clearDetails}
              /> */}

              <Grid
                container
                direction={"column"}
                spacing={2}
                justifyContent="flex-start"
                alignItems={"flex-start"}
              >
                <Grid item>
                  <Grid container direction="row" spacing={2}>
                    <Grid item>
                      <CtBtn
                        label={"Edit"}
                        firstletterBig={false}
                        iconName={"Edit"}
                        onClick={() => {
                          this.openRichTextBox(
                            "Terms And Condition",
                            "TermsConditionDetailsInfo",
                            this.state.TermsConditionDetailsInfo
                          );
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <CtBtn
                        label={"Clear"}
                        firstletterBig={false}
                        onClick={() => {
                          this.clear("TermsConditionDetailsInfo");
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.state.TermsConditionDetailsInfo,
                    }}
                    style={{ margin: "0", padding: "0" }}
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="3">
              <Grid
                container
                direction={"column"}
                spacing={2}
                justifyContent="flex-start"
                alignItems={"flex-start"}
              >
                <Grid item>
                  <Grid container direction="row" spacing={2}>
                    <Grid item>
                      <CtBtn
                        label={"Edit"}
                        firstletterBig={false}
                        iconName={"Edit"}
                        onClick={() => {
                          this.openRichTextBox(
                            "Preparatory Work Entry",
                            "PreparatoryWorkInfo",
                            this.state.PreparatoryWorkInfo
                          );
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <CtBtn
                        label={"Clear"}
                        firstletterBig={false}
                        onClick={() => {
                          this.clear("PreparatoryWorkInfo");
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.state.PreparatoryWorkInfo,
                    }}
                    style={{ margin: "0", padding: "0" }}
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="4">
              <Grid
                container
                direction={"column"}
                spacing={2}
                justifyContent="flex-start"
                alignItems={"flex-start"}
              >
                <Grid item>
                  <Grid container direction="row" spacing={2}>
                    <Grid item>
                      <CtBtn
                        label={"Edit"}
                        firstletterBig={false}
                        iconName={"Edit"}
                        onClick={() => {
                          this.openRichTextBox(
                            "Terms of Payment",
                            "TermsPaymentInfo",
                            this.state.TermsPaymentInfo
                          );
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <CtBtn
                        label={"Clear"}
                        firstletterBig={false}
                        onClick={() => {
                          this.clear("TermsPaymentInfo");
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.state.TermsPaymentInfo,
                    }}
                    style={{ margin: "0", padding: "0" }}
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="5">
              <Grid
                container
                direction={"column"}
                spacing={2}
                justifyContent="flex-start"
                alignItems={"flex-start"}
              >
                <Grid item>
                  <Grid container direction="row" spacing={2}>
                    <Grid item>
                      <CtBtn
                        label={"Edit"}
                        firstletterBig={false}
                        iconName={"Edit"}
                        onClick={() => {
                          this.openRichTextBox(
                            "Terms of Cancellation",
                            "TermsCancellationInfo",
                            this.state.TermsCancellationInfo
                          );
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <CtBtn
                        label={"Clear"}
                        firstletterBig={false}
                        onClick={() => {
                          this.clear("TermsCancellationInfo");
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.state.TermsCancellationInfo,
                    }}
                    style={{ margin: "0", padding: "0" }}
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="6">
              <Grid
                container
                direction={"column"}
                spacing={2}
                justifyContent="flex-start"
                alignItems={"flex-start"}
              >
                <Grid item>
                  <Grid container direction="row" spacing={2}>
                    <Grid item>
                      <CtBtn
                        label={"Edit"}
                        firstletterBig={false}
                        iconName={"Edit"}
                        onClick={() => {
                          this.openRichTextBox2(
                            "Note",
                            "NoteInfo",
                            this.state.NoteInfo
                          );
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <CtBtn
                        label={"Clear"}
                        firstletterBig={false}
                        onClick={() => {
                          this.clear("NoteInfo");
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.state.NoteInfo,
                    }}
                    style={{ margin: "0", padding: "0" }}
                  />
                </Grid>
              </Grid>
            </TabPanel>
          </TabContext>

          <Grid item>
            <Box display={{ xs: "none" }} style={{ textAlign: "right" }}>
              {this.state.ARD}
            </Box>
          </Grid>
        </Box>
      </form>
    );
  }
}

// export default HoCtToastContainer(withRouter(ProposalAllEntry));

function WithNavigate(props) {
  let navigate = useNavigate();
  return <ProposalAllEntry {...props} navigate={navigate} />;
}
export default connect(
  mapStatetoProps,
  mapSetUserPrivileges_ToProps
)(HoCtToastContainer(HOCVerifyIsUser(withRouter(WithNavigate))));
