import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import HOCVerifyIsUser from "../HOC/HOCVerifyIsUser";
import CtBtn from "../Components/CustomTool/CtBtn";
import CtTxt from "../Components/CustomTool/CtTxt";
import CtTextFieldPWD from "../Components/CustomTool/CtTxtPWD";
import CtCmb from "../Components/CustomTool/CtCmb";
import CtTxtNum from "../Components/CustomTool/CtTxtNum";
import MaterialTable from "../Components/CustomTool/MaterialTable";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import AlertResponsiveDialog from "../Components/CustomTool/AlertResponsiveDialog";
// import DepartmentMaster from "./DepartmentMaster";
import { Stack } from "@mui/material";
// import PlantMaster from "./PlantMaster";
// import { fetchUser } from "../Components/API";
import HoCtToastContainer from "../HOC/HoCtToastContainer";
import {
  applyTrimOnObjectValues,
  csvFileName,
  getRowOfRT,
  setKeyboardShortcuts,
} from "../SystemUtility/SystemUtility";
import {
  mapSetUserPrivileges_ToProps,
  mapStatetoProps,
} from "../mapRedux/mapReduxProps";
import { connect } from "react-redux";
import { withRouter } from "../HOC/withRouter";
import { fetchComChainPrice } from "./API";
import MobileTable from "./CustomTool/MobileTable";
import CtART from "./CustomTool/ResponsiveTable";
import axios from "axios";
import CtTxtAmt from "../Components/CustomTool/CtTxtAmt";
import CtCmbEditable from "./CustomTool/CtCmbEditable";

class ComChainPricePerKg extends Component {
  state = {
    Unit: [{ vac_type: "Type" }],
    columnsdata: [
      {
        accessorKey: "bint_srno",
        header: "SR No",
      },
      {
        accessorKey: "vac_comchain",
        header: "Com Chain",
      },
      {
        accessorKey: "dec_kgmtr",
        header: "KG / MTR",
      },
      {
        accessorKey: "dec_rate",
        header: "RATE / MTR",
      },
    ],

    rows: [],
    formData: {
      bint_srno: 0,
      txtComChain: "",
      txtKGMTR: "",
      txtRATEMTR: "",
    },
    dataLoadStatus: true,
    ARD: null,
    rowsCount: 0,
    rowsPerPage: 50,
  };

  //#region Component
  componentDidMount() {
    localStorage.setItem("Heading", "Com Chain Price Per Kg");
    this.props.verifyUserID(
      this.getInfo,
      this.props.setUserPrivileges,
      this.props.modules.comchainpriceperkg
    );
    this.ShortCut();
  }

  componentWillUnmount() {
    this.removeShortCut();
  }

  //#endregion Component

  //#region Handle

  ShortCut = () => {
    this.removeShortCut = setKeyboardShortcuts(
      this.saveComChain,
      this.clearInfo,
      () => {
        this.DeleteData(
          this.state.formData.bint_srno,
          this.state.formData.txtComChain
        );
      }
    );
  };

  handleOnChange = (e) => {
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: e.target.value },
    });
  };

  handleARDonActionClick_Edit = (rtRow) => {
    const row = getRowOfRT(rtRow, [
      "bint_srno",
      "vac_comchain",
      "dec_kgmtr",
      "dec_rate",
    ]);
    const bint_srno = row.bint_srno,
      txtComChain = row.vac_comchain,
      txtKGMTR = row.dec_kgmtr,
      txtRATEMTR = row.dec_rate;
    this.setState({
      formData: {
        ...this.state.formData,
        bint_srno,
        txtComChain,
        txtKGMTR,
        txtRATEMTR,
      },
    });
    document.getElementById("txtComChain").focus();
  };

  handleARDonActionClick_Delete = (rtRow) => {
    const row = getRowOfRT(rtRow, ["bint_srno", "vac_comchain"]);
    this.DeleteData(row.bint_srno, row.vac_comchain);
  };

  //#endregion Handle

  //#region function

  getInfo = () => {
    this.props.notifyProcessing();
    const reqData = {
      Op: "ListComChianPrice",
    };
    fetchComChainPrice(reqData)
      .then((res) => {
        this.props.closeUpdateProcessing();
        let rows = res.data.ListComChianPrice,
          rowsCount = rows.length;
        this.setState({
          rowsCount,
          rows,
        });
      })
      .catch((error) => {
        console.log("Unknown error occurred in onFormLoad.", error);
      });
    document.getElementById("txtComChain").focus();
  };

  clearInfo = () => {
    this.setState(
      {
        formData: {
          ...this.state.formData,
          bint_srno: 0,
          txtComChain: "",
          txtKGMTR: "",
          txtRATEMTR: "",
        },
      },
      () => {
        document.getElementById("txtComChain").focus();
      }
    );
  };

  trimFormData = (nextFunctionCall) => {
    let formData = applyTrimOnObjectValues({
      formData: this.state.formData,
    });
    this.setState({ formData }, () => {
      if (nextFunctionCall) {
        nextFunctionCall();
      }
    });
  };

  validationDoorBrand = () => {
    let result = false;
    if (this.state.formData.txtComChain.length <= 0) {
      this.props.toastErrorMsg("Enter Com Chain Price", "txtComChain");
    } else if (Number(this.state.formData.txtKGMTR) <= 0) {
      this.props.toastErrorMsg("Enter KG / MTR", "txtKGMTR");
    } else if (Number(this.state.formData.txtRATEMTR) <= 0) {
      this.props.toastErrorMsg("Enter RATE / MTR", "txtRATEMTR");
    } else {
      result = true;
    }
    return result;
  };

  saveComChain = () => {
    this.trimFormData(() => {
      if (this.validationDoorBrand()) {
        this.props.notifyProcessing();
        const reqData = {
          Op: "SaveComChianPrice",
          bint_srno: this.state.formData.bint_srno,
          vac_comchain: this.state.formData.txtComChain,
          dec_kgmtr: this.state.formData.txtKGMTR,
          dec_rate: this.state.formData.txtRATEMTR,
        };
        fetchComChainPrice(reqData)
          .then((res) => {
            this.props.updateProcessing(res.data.msgType, res.data.message);
            if (res.data.msgType === "success") {
              let rows = [],
                rowsCount = rows.length;
              if (
                res.data.ListComChianPrice &&
                res.data.ListComChianPrice.length > 0
              ) {
                rows = res.data.ListComChianPrice;
                rowsCount = rows.length;
              }
              this.setState(
                {
                  rows,
                  rowsCount,
                },
                () => {
                  this.clearInfo();
                }
              );
            }
          })
          .catch((error) => {
            console.log(
              "Unknown error occurred in Save Com Chain Price .",
              error
            );
          });
      }
    });
  };

  DeleteData = (bint_srno, vac_comchain) => {
    if (bint_srno > 0) {
      this.setState({ ARD: null }, () => {
        let ARD = (
          <AlertResponsiveDialog
            labelAgree="Delete"
            labelDisagree="Cancel"
            alertTitle={`Do you want to Delete Com Chain : ${vac_comchain} ?`}
            alertMessage=""
            handleOnClickYes={() => {
              return this.DeleteComChain(bint_srno);
            }}
            defaultOpenDialog={true}
            onYesClickCloseIfExeSuccessfully={true}
          />
        );
        this.setState({ ARD });
      });
    } else {
      this.props.toastErrorMsg("Select a record for deletion.");
    }
  };

  DeleteComChain = (bint_srno) => {
    this.props.notifyProcessing();
    const reqData = {
      Op: "DeleteComChianPrice",
      bint_srno: bint_srno,
    };
    fetchComChainPrice(reqData)
      .then((res) => {
        this.props.updateProcessing(res.data.msgType, res.data.message);
        if (res.data.msgType === "success") {
          let rows = [],
            rowsCount = rows.length;
          if (
            res.data.ListComChianPrice &&
            res.data.ListComChianPrice.length > 0
          ) {
            rows = res.data.ListComChianPrice;
            rowsCount = rows.length;
          }
          this.setState(
            {
              rows,
              rowsCount,
              ARD: null,
            },
            () => {
              this.clearInfo();
            }
          );
        }
      })
      .catch((error) => {
        this.props.updateProcessing(
          "error",
          "Unknown error occurred in delete Com Chain Price. " + error
        );
      });
    return false;
  };

  //#endregion function

  render() {
    return (
      <form>
        <Grid container direction="row" justifyContent="flex-end">
          <Grid item>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <CtBtn
                  className="secondary-button"
                  label="Save"
                  iconName="save"
                  onClick={this.saveComChain}
                />
              </Grid>
              <Grid item>
                <CtBtn
                  label="New"
                  className="new-button"
                  iconName="new"
                  onClick={this.clearInfo}
                />
              </Grid>
              <Grid item>
                <CtBtn
                  label="Delete"
                  className="delete-button"
                  iconName="delete"
                  onClick={() => {
                    this.DeleteData(
                      this.state.formData.bint_srno,
                      this.state.formData.txtComChain
                    );
                  }}
                  disabled={this.state.formData.bint_srno > 0 ? false : true}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          marginTop={0}
        >
          <Grid item>
            <CtTxt
              id="txtComChain"
              label="Com Chain*"
              maxLength={100}
              width="220"
              value={this.state.formData.txtComChain}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtAmt
              id="txtKGMTR"
              label="KG / MTR*"
              maxLength={10}
              width="220"
              value={this.state.formData.txtKGMTR}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxt
              id="txtRATEMTR"
              label="RATE / MTR*"
              maxLength={10}
              width="220"
              value={this.state.formData.txtRATEMTR}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
              defaultAction={this.saveComChain}
            />
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          marginTop={1}
        >
          {/* responsive table */}
          <Grid item className="maintable">
            <CtART
              rowsdata={this.state.rows}
              columnsdata={this.state.columnsdata}
              rowsPerPage={this.state.rowsPerPage}
              tblmaxHeight={500}
              csvFileName={csvFileName("Com Chain Price")}
              rowIconAction={[
                {
                  label: "Edit",
                  onClick: this.handleARDonActionClick_Edit,
                  icon: "Edit",
                  className: "secondary-button",
                },
                {
                  label: "Delete",
                  onClick: this.handleARDonActionClick_Delete,
                  icon: "delete",
                  className: "delete-button",
                },
              ]}
              showColumnFilters={false}
              actionColSize={40}
              density={"compact"}
              hideColIdList={["bint_srno"]}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Box display={{ xs: "none" }} style={{ textAlign: "right" }}>
            {this.state.ARD}
          </Box>
        </Grid>
      </form>
    );
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <ComChainPricePerKg {...props} navigate={navigate} />;
}
export default connect(
  mapStatetoProps,
  mapSetUserPrivileges_ToProps
)(HoCtToastContainer(HOCVerifyIsUser(withRouter(WithNavigate))));
