//#region Constants
export const PROCESSING = "Processing";
export const NO_DATA_FOUND = "No Data Found";
export const SUCCESS = "success";
export const ERROR = "error";
//#endregion

//#region Function

export function getAgeOfDOB(date) {
  var today = new Date();
  var birthDate = new Date(date);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export function validatePANNo(panNo) {
  var result = false;
  if (panNo.length == 10) {
    result = true;
  }
  return result;
}

export function validateIFSCCode(ifscCode) {
  var result = false;
  if (ifscCode.length == 11) {
    result = true;
  }
  return result;
}

export function validateEmail(email) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function getIndianFormatedNumber(number, decimalPlace = 2) {
  number = Number(number);
  return number.toLocaleString("en-IN", {
    maximumFractionDigits: decimalPlace,
    style: "currency",
    currency: "INR",
  });
}

export function getItemlocalStorage(id) {
  return localStorage.getItem(id);
}

export function get_YMD_from_SYS(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month <= 3) {
    year -= 1;
  }

  let localyear = getItemlocalStorage("ACY");
  // console.log("localyear", localyear);
  // console.log("year", year);
  if (year != localyear) {
    date = new Date(Number(localyear) + 1, 2, 31);
    month = "" + (date.getMonth() + 1);
    day = "" + date.getDate();
    year = date.getFullYear();
  } else {
    date = new Date();
    if (month <= 3) {
      year += 1;
    }
  }

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  // console.log("Date", [year, month, day].join("-"));
  // console.log("Date Year", year);
  return [year, month, day].join("-");
}

export function getMonthFromDate(dateString) {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const [year, month, day] = dateString.split("-");
  // console.log("month", parseInt(month) - 1);
  const monthIndex = parseInt(month) - 1;

  return monthNames[monthIndex];
}

export function get_DMY_from_YMD(date) {
  var datePart = date.match(/\d+/g),
    year = datePart[0],
    month = datePart[1],
    day = datePart[2];
  return `${day}/${month}/${year}`;
}

export function get_YMD_from_ISODate(isoDate) {
  const date = new Date(isoDate);
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export function get_DMY_from_ISODate(isoDate) {
  const date = new Date(isoDate);
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");
  return `${day}/${month}/${year}`;
}

export function get_YMD_from_DMY(strDate, separator = "/") {
  if (strDate) {
    var datePart = strDate.toString().split(separator);
    var day = datePart[0],
      month = datePart[1],
      year = datePart[2];
    return `${year}-${month}-${day}`;
  } else {
    return `00-00-0000`;
  }
}

export function getDateAcYearfromDate(strDate) {
  var result = "",
    month = "",
    year = "";
  if (strDate.length > 0) {
    var res = strDate.split("-");
    month = res[1];
    year = res[0];
    if (Number(month) >= 1 && Number(month) <= 3) {
      result = Number(year) - Number(1);
    } else if (Number(month) >= 4 && Number(month) <= 12) {
      result = year;
    }
  }
  return result;
}

export function csvFileName(pageName) {
  var result = "";
  if (pageName.length > 0) {
    result = pageName + " Data as on " + getFormattedDate();
  }
  return result;
}

export function getMonthNumber(monthName) {
  const months = {
    Jan: 1,
    Feb: 2,
    Mar: 3,
    Apr: 4,
    May: 5,
    Jun: 6,
    Jul: 7,
    Aug: 8,
    Sep: 9,
    Oct: 10,
    Nov: 11,
    Dec: 12,
  };
  return months[monthName];
}

export function getMonthNameFromMonthNumber(Number) {
  const months = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };
  return months[Number];
}

const getFormattedDate = () => {
  const today = new Date();
  const day = today.getDate();
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = monthNames[today.getMonth()];
  const year = today.getFullYear();

  return `${day}-${month}-${year}`;
};

export function getLastDayofMonthofGivenDate(
  dateYMD_Dash,
  returnDataAsObject = false
) {
  let arraySplitFromDt = dateYMD_Dash.split("-"),
    month = Number(arraySplitFromDt[1]),
    year = Number(arraySplitFromDt[0]),
    day = 0,
    newDate = convertStrDate_To_YMD(new Date(year, month, day)),
    arrnewDate = newDate.split("-");
  day = Number(arrnewDate[2]);
  if (month < 10) month = "0" + month;
  if (day < 10) day = "0" + day;
  // return new Date(year, month, 0);
  if (returnDataAsObject) {
    return { year, month, day };
  } else {
    return [year, month, day].join("-");
  }
}

export function getTowDigits_DayORMonth(intDay_Month) {
  if (Number(intDay_Month) < 10) {
    return "0" + intDay_Month;
  } else {
    return intDay_Month;
  }
}

export function convertStrDate_To_YMD(strDate) {
  var date = new Date(strDate),
    month = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), month, day].join("-");
}

export function get_DayMonthYear_of_Given_YMD_Date(dateYMD_Dash) {
  let arrDtParts = dateYMD_Dash.split("-"),
    intYear = Number(arrDtParts[0]),
    intMonth = Number(arrDtParts[1]),
    intDay = Number(arrDtParts[2]);
  return { intYear, intMonth, intDay };
}

export function get_NextDate_of_Given_YMD_Date(dateYMD_Dash) {
  var tomorrow = new Date(dateYMD_Dash);
  tomorrow.setDate(tomorrow.getDate() + 1);
  return convertStrDate_To_YMD(tomorrow);
}

export function get_FirstDate_of_Given_YMD_Date(dateYMD_Dash) {
  var date = new Date(dateYMD_Dash),
    month = "" + (date.getMonth() + 1),
    day = "" + 1,
    year = date.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export function get_30DaysBack_FromCurrentDate(dateYMD_Dash = new Date()) {
  let n = new Date(dateYMD_Dash),
    d = new Date(dateYMD_Dash);
  d.setDate(n.getDate() - 30);
  var month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export function get_90DaysBack_FromCurrentDate() {
  let n = new Date(),
    d = new Date();
  d.setDate(n.getDate() - 90);

  var month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export function getRowData(Rows, ColID, Data) {
  let resultRow = "";
  Rows.filter((row) => {
    if (row[ColID] == Data) {
      resultRow = row;
    }
  });
  return resultRow;
}

export function getRowDataList(Rows, ColID, Distinct = true) {
  let resultList = [];
  Rows.filter((row) => {
    if (Distinct) {
      const found = resultList.some((el) => el[ColID] === row[ColID]);
      if (!found) {
        resultList.push({ ...row });
      }
    } else {
      resultList.push({ ...row });
    }
  });
  return resultList;
}

export function getRowColData(Rows, ColID, Distinct = true) {
  let resultList = [];
  Rows.filter((row) => {
    if (Distinct) {
      const found = resultList.some((el) => el[ColID] === row[ColID]);
      if (!found) {
        resultList.push({ [ColID]: row[ColID] });
      }
    } else {
      resultList.push({ [ColID]: row[ColID] });
    }
  });
  return resultList;
}

export function getTextSummary(strData, startIndex = 0, lastIndex = 5) {
  return (
    strData.substring(startIndex, lastIndex) + (strData.length > 5 ? "..." : "")
  );
}

export function getString_or_Blank4Null(strData) {
  return strData !== null && strData !== undefined ? strData : "";
}

export function getNumber_or_Blank4Null(strData) {
  return strData !== null && strData !== undefined ? strData : 0;
}

export function formatDateTime(dateVal) {
  dateVal = new Date(dateVal);
  const tzoffset = (5 * 60 + 30) * 60000; //dateVal.getTimezoneOffset() * 60000
  const localTime = new Date(dateVal.getTime() + tzoffset);
  return localTime;
}

export function getAcYear_YYYY(fullAcYear) {
  return fullAcYear.substring(0, 4);
}

function padValue(value) {
  return value < 10 ? "0" + value : value;
}

export function getTaxNumFromStr(StrTaxName) {
  return Number(StrTaxName.substring(0, StrTaxName.length - 1));
}

export function getTaxableFromValue(numTax, value) {
  return roundOff((Number(value) / (100 + Number(numTax))) * 100, 2);
}

export function roundOff(num, precision = 2) {
  let precisionValue = 1;
  for (let i = 1; i <= precision; i++) {
    precisionValue *= 10;
  }
  return Math.round((num + Number.EPSILON) * precisionValue) / precisionValue;
}

export function isValidItem(rows, colID, value) {
  let result = false;
  if (rows.length > 0) {
    for (let i = 0; i < rows.length > 0; i++) {
      if (rows[i][colID] !== undefined && rows[i][colID] == value) {
        result = true;
        return result;
      }
    }
  }
  return result;
}

export function getRowsWithColsExists({
  dataArray,
  colList,
  excludeBlankColValue = true,
}) {
  const resultArray = [];
  dataArray.map((row) => {
    let rowColFound = 0;
    colList.map((col) => {
      if (
        row[col] !== undefined &&
        row[col] !== null &&
        (excludeBlankColValue !== true || row[col] !== "")
      ) {
        rowColFound++;
      }
    });
    if (rowColFound === colList.length) {
      resultArray.push(row);
    }
  });
  return resultArray;
}

//Old TrimData

// export function applyTrimOnArray(dataArray) {
//   return dataArray.map((data) => applyTrimOnObjectValues(data));
// }

// export function applyTrimOnObjectValues(
//   formData,
//   excludeKeysArray,
//   replaceNullwithBlank = true
// ) {
//   const keys = Object.keys(formData);
//   let trimFormData = {};
//   keys.forEach((key) => {
//     let isToExclude = false;
//     if (
//       excludeKeysArray !== undefined &&
//       excludeKeysArray.length > 0 &&
//       excludeKeysArray.includes(key)
//     ) {
//       isToExclude = true;
//     }
//     if (replaceNullwithBlank) {
//       formData[key] = getString_or_Blank4Null(formData[key]);
//     }
//     if (isToExclude === false) {
//       trimFormData[key] = String(formData[key]).trim();
//     } else {
//       trimFormData[key] = formData[key];
//     }
//   });
//   return trimFormData;
// }

//New Trimdata Function

export function applyTrimOnArray(dataArray) {
  return dataArray.map((data) => applyTrimOnObjectValues({ formData: data }));
}

export function applyTrimOnObjectValues({
  formData,
  excludeKeysArray,
  replaceNullwithBlank = true,
  numberKeysArray,
}) {
  const keys = Object.keys(formData);
  let trimFormData = {};
  keys.forEach((key) => {
    let isToExclude = false;
    if (
      excludeKeysArray !== undefined &&
      excludeKeysArray.length > 0 &&
      excludeKeysArray.includes(key)
    ) {
      isToExclude = true;
    }
    if (replaceNullwithBlank) {
      formData[key] = getString_or_Blank4Null(formData[key]);
    }
    if (isToExclude === false) {
      if (numberKeysArray && numberKeysArray.includes(key)) {
        trimFormData[key] = Number(formData[key]);
      } else {
        trimFormData[key] = String(formData[key]).trim();
      }
    } else {
      trimFormData[key] = formData[key];
    }
  });
  return trimFormData;
}

export function loadValuesIntoMainArray({
  MainArray,
  MainArrayColList,
  ValueArray,
  compareColID,
  copyValueColIDArray,
}) {
  const resultArray = [];
  MainArray.map((MA) => {
    let MainCol = {};
    MainArrayColList.map((col) => {
      MainCol[col] = MA[col];
    });
    resultArray.push(MainCol);
  });
  if (ValueArray && ValueArray.length > 0) {
    ValueArray.map((VA) => {
      let isFound = 0;
      resultArray.map((RA) => {
        if (RA[compareColID] === VA[compareColID]) {
          copyValueColIDArray.map((curCol) => {
            let value =
              curCol.id !== null && curCol.id !== undefined
                ? VA[curCol.id]
                : curCol.datatype === "string"
                ? ""
                : 0;
            RA[curCol.id] = value;
          });
          isFound++;
        }
      });
      if (isFound === 0) {
        let newItem = {
          [compareColID]: VA[compareColID],
        };
        copyValueColIDArray.map((curCol) => {
          newItem[curCol.id] =
            curCol.id !== null && curCol.id !== undefined
              ? VA[curCol.id]
              : curCol.datatype === "string"
              ? ""
              : 0;
        });
        MainArray.push(newItem);
      }
    });
  }
  return resultArray;
}

export function copyArrayValue(ValueArray) {
  let blankArray = [];
  ValueArray.map((VA) => {
    blankArray.push(VA);
  });
  return blankArray;
}

export function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export function getFromToDate4AcYear(yyyy) {
  /**
   * step : 1 => provide first & last date of given year
   * step : 2 => return both date in YYYY-MM-DD in JO
   */
  var firstDate = formatDate(new Date(yyyy + "-04-01")); // Apr 1st of 2023 the given year
  var lastDate = formatDate(new Date(Number(yyyy) + 1 + "-03-31")); // mar 31st of 2024 the given year

  return {
    firstDate,
    lastDate,
  };
}

export function getRoundOff(number) {
  let roff = 0,
    roffnumber = 0;
  if (Number(number - Math.floor(number)) !== Number(0)) {
    roff = roundOff(
      Number(number - Math.floor(number)) >= Number(0.5)
        ? Math.ceil(number) - number
        : Math.floor(number) - number,
      2
    );
    roffnumber = roundOff(
      Number(number - Math.floor(number)) >= Number(0.5)
        ? Math.ceil(number)
        : Math.floor(number),
      2
    );
  } else {
    roffnumber = number;
  }
  return { number: number, roff: roff, roff_number: roffnumber };
}

export function isDateInRange(strDateYMD, minDateYMD, maxDateYMD) {
  const date = new Date(strDateYMD);
  return date >= new Date(minDateYMD) && date <= new Date(maxDateYMD);
}

export function getFinancialYear(date) {
  const givenDate = new Date(date);
  const year = givenDate.getFullYear();
  const startOfFY = new Date(year, 3, 1); // April 1 of the same year

  if (givenDate >= startOfFY) {
    return year;
  } else {
    return year - 1;
  }
}

export function validatedate_min_max(strDateYMD, id, toastErrorMsg) {
  // console.log("strDateYMD", strDateYMD);
  // console.log("document.getElementById(id)", document.getElementById(id));
  let minDateYMD = document.getElementById(id).min,
    maxDateYMD = document.getElementById(id).max;
  // console.log("minDateYMD", minDateYMD);
  // console.log("maxDateYMD", maxDateYMD);
  if (strDateYMD && !isDateInRange(strDateYMD, minDateYMD, maxDateYMD)) {
    if (toastErrorMsg) {
      toastErrorMsg(
        `Select date between ${get_DMY_from_YMD(
          minDateYMD
        )} and ${get_DMY_from_YMD(maxDateYMD)}`,
        id
      );
    } else {
      alert(
        `Select date between ${get_DMY_from_YMD(
          minDateYMD
        )} and ${get_DMY_from_YMD(maxDateYMD)}`,
        id
      );
    }
    return false;
  } else {
    return true;
  }
}

export function setTaxPaidRate(NumTaxName, rate) {
  let arrTaxPaidRate = getRoundOff((rate / (100 + NumTaxName)) * 100, 2);
  let amt_TaxPaidRate = arrTaxPaidRate["roff_number"];
  return amt_TaxPaidRate;
}

export function setKeyboardShortcuts(
  handleOnclickSave,
  handleOnclickNew,
  handleOnclickDelete,
  handleOnclickPrint
) {
  const handleKeyDown = (event) => {
    if (event.altKey && event.key.toLowerCase() === "s") {
      event.preventDefault();
      {
        if (handleOnclickSave) {
          handleOnclickSave();
        } else {
          console.log("Save function not available");
        }
      }
    } else if (event.altKey && event.key.toLowerCase() === "n") {
      event.preventDefault();
      {
        if (handleOnclickNew) {
          handleOnclickNew();
        } else {
          console.log("New function not available");
        }
      }
    } else if (event.altKey && event.key.toLowerCase() === "d") {
      event.preventDefault();
      {
        if (handleOnclickDelete) {
          handleOnclickDelete();
        } else {
          console.log("Delete function not available");
        }
      }
    } else if (event.altKey && event.key.toLowerCase() === "p") {
      event.preventDefault();
      {
        if (handleOnclickPrint) {
          handleOnclickPrint();
        } else {
          console.log("Print function not available");
        }
      }
    }
  };
  document.addEventListener("keydown", handleKeyDown);
  return () => {
    document.removeEventListener("keydown", handleKeyDown);
  };
}

export function setPrivileges_Redux(UserPrivileges, setUserPrivileges) {
  let userPrivilege = {},
    arrModule = [];
  UserPrivileges.map((user_privilege) => {
    // userPrivilege['Plant'] = 'A'
    userPrivilege[user_privilege.Module] = user_privilege.Access;
  });
  // console.log("userPrivilege", userPrivilege);
  setUserPrivileges(userPrivilege);
  return userPrivilege;
}

export function checkModulePrivilegesAssignToUser(UserPrivileges, module_name) {
  return UserPrivileges[module_name] !== undefined;
}

export function displaySaveBtnVisibility(
  userPrivileges_action,
  propsEditValue
) {
  let retDisplayStyle = "none";
  if (
    (userPrivileges_action && userPrivileges_action === "M") ||
    (userPrivileges_action &&
      userPrivileges_action === "W" &&
      propsEditValue &&
      (propsEditValue === "" || Number(propsEditValue) === Number(0)))
  ) {
    retDisplayStyle = "block";
  }
  return retDisplayStyle;
}

export function displayDeleteBtnVisibility(
  userPrivileges_action,
  propsEditValue
) {
  let retDisplayStyle = "none";
  if (userPrivileges_action && userPrivileges_action === "M") {
    retDisplayStyle = "block";
  }
  return retDisplayStyle;
}

export function getlocalstoragedate(type) {
  let datesettings = getItemlocalStorage("defaultsettings");
  let storedFromDate = "";
  let storedToDate = "";
  if (datesettings) {
    let jsondatesettings = JSON.parse(datesettings).date;
    if (jsondatesettings != undefined) {
      if (type === "Candidate") {
        storedFromDate = jsondatesettings.candidatelistfromdate;
        storedToDate = jsondatesettings.candidatelisttodate;
      } else if (type === "Recruitment") {
        storedFromDate = jsondatesettings.recruitmentlistfromdate;
        storedToDate = jsondatesettings.recruitmentlisttodate;
      } else if (type === "Resume") {
        storedFromDate = jsondatesettings.resumelistfromdate;
        storedToDate = jsondatesettings.resumelisttodate;
      } else if (type === "Leave") {
        storedFromDate = jsondatesettings.leavelistfromdate;
        storedToDate = jsondatesettings.leavelisttodate;
      } else if (type === "HRGatePass") {
        storedFromDate = jsondatesettings.hrgatepassfromdate;
        storedToDate = jsondatesettings.hrgatepasstodate;
      } /*else if (type === "Loading") {
        storedFromDate = jsondatesettings.loadinglistfromdate;
        storedToDate = jsondatesettings.loadinglisttodate;
      } else if (type === "Unloading") {
        storedFromDate = jsondatesettings.unloadinglistfromdate;
        storedToDate = jsondatesettings.unloadinglisttodate;
      } else if (type === "SalesReport") {
        storedFromDate = jsondatesettings.salesreportlistfromdate;
        storedToDate = jsondatesettings.salesreportlisttodate;
      } else if (type === "PurchaseReport") {
        storedFromDate = jsondatesettings.purchasereportlistfromdate;
        storedToDate = jsondatesettings.purchasereportlisttodate;
      } else if (type === "DayBookReport") {
        storedFromDate = jsondatesettings.daybookreportlistfromdate;
        storedToDate = jsondatesettings.daybookreportlisttodate;
      } else if (type === "LedgerBookReport") {
        storedFromDate = jsondatesettings.ledgerbookreportlistfromdate;
        storedToDate = jsondatesettings.ledgerbookreportlisttodate;
      } else if (type === "StockReport") {
        storedFromDate = jsondatesettings.stockreportlistfromdate;
        storedToDate = jsondatesettings.stockreportlisttodate;
      } */
    }
  }
  const Storeddate = new Date(storedFromDate);
  // console.log("Storeddate", Storeddate);
  let storedYear = Storeddate.getFullYear();
  let storedmonth = Storeddate.getMonth() + 1;

  let localyear = getItemlocalStorage("ACY");

  const curdate = new Date();
  let curyear = curdate.getFullYear();
  let curmonth = curdate.getMonth() + 1; // Adding 1 to convert it to a 1-based month

  if (storedmonth <= 3) {
    storedYear = storedYear - 1;
  }
  if (curmonth <= 3) {
    curyear = curyear - 1;
  }
  // console.log("curyear", curyear);
  // console.log("storedYear", storedYear);

  const startDate = Number(storedYear) + "-04-01";
  const endDate = Number(storedYear) + 1 + "-03-31";
  if (
    isDateInRange(storedFromDate, startDate, endDate) == false &&
    isDateInRange(storedToDate, startDate, endDate) == false &&
    curyear == localyear &&
    storedYear != curyear
  ) {
    // console.log("storedFromDate", storedFromDate);

    storedFromDate = get_30DaysBack_FromCurrentDate();
    // console.log("storedFromDate After", get_YMD_from_SYS(storedFromDate));
    storedToDate = get_YMD_from_SYS(new Date());
  } else if ((storedYear == NaN) | (storedYear != localyear)) {
    storedFromDate = get_30DaysBack_FromCurrentDate(
      new Date(Number(localyear) + 1, 2, 1)
    );
    storedToDate = get_YMD_from_SYS(new Date(Number(localyear) + 1, 2, 31));
  }

  return {
    fromdate: storedFromDate
      ? storedFromDate
      : get_30DaysBack_FromCurrentDate(),
    todate: storedToDate ? storedToDate : get_YMD_from_SYS(new Date()),
  };
}

export function setlocalstoragedate(type, fromdate, todate, reset = false) {
  if (reset) {
    fromdate = get_30DaysBack_FromCurrentDate();
    todate = get_YMD_from_SYS(new Date());
  }
  let datesettings = getItemlocalStorage("defaultsettings");
  if (datesettings) {
    const jsonData = JSON.parse(datesettings);
    if (type === "Candidate") {
      jsonData.date.candidatelistfromdate = fromdate;
      jsonData.date.candidatelisttodate = todate;
    } else if (type === "Recruitment") {
      jsonData.date.recruitmentlistfromdate = fromdate;
      jsonData.date.recruitmentlisttodate = todate;
    } else if (type === "Resume") {
      jsonData.date.resumelistfromdate = fromdate;
      jsonData.date.resumelisttodate = todate;
    } else if (type === "Leave") {
      jsonData.date.leavelistfromdate = fromdate;
      jsonData.date.leavelisttodate = todate;
    } else if (type === "HRGatePass") {
      jsonData.date.hrgatepassfromdate = fromdate;
      jsonData.date.hrgatepasslisttodate = todate;
    } /*else if (type === "Loading") {
      jsonData.date.loadinglistfromdate = fromdate;
      jsonData.date.loadinglisttodate = todate;
    } else if (type === "Unloading") {
      jsonData.date.unloadinglistfromdate = fromdate;
      jsonData.date.unloadinglisttodate = todate;
    } else if (type === "SalesReport") {
      jsonData.date.salesreportlistfromdate = fromdate;
      jsonData.date.salesreportlisttodate = todate;
    } else if (type === "PurchaseReport") {
      jsonData.date.purchasereportlistfromdate = fromdate;
      jsonData.date.purchasereportlisttodate = todate;
    } else if (type === "DayBookReport") {
      jsonData.date.daybookreportlistfromdate = fromdate;
      jsonData.date.daybookreportlisttodate = todate;
    } else if (type === "LedgerBookReport") {
      jsonData.date.ledgerbookreportlistfromdate = fromdate;
      jsonData.date.ledgerbookreportlisttodate = todate;
    } else if (type === "StockReport") {
      jsonData.date.stockreportlistfromdate = fromdate;
      jsonData.date.stockreportlisttodate = todate;
    } */
    localStorage.setItem("defaultsettings", JSON.stringify(jsonData));
  } else {
    let jsonData = {};
    if (type === "Candidate") {
      jsonData = {
        date: {
          candidatelistfromdate: fromdate,
          candidatelisttodate: todate,
        },
      };
    } else if (type === "Recruitment") {
      jsonData = {
        date: {
          recruitmentlistfromdate: fromdate,
          recruitmentlisttodate: todate,
        },
      };
    } else if (type === "Resume") {
      jsonData = {
        date: {
          resumelistfromdate: fromdate,
          resumelisttodate: todate,
        },
      };
    } else if (type === "Leave") {
      jsonData = {
        date: {
          leavelistfromdate: fromdate,
          leavelisttodate: todate,
        },
      };
    } else if (type === "HRGatePass") {
      jsonData = {
        date: {
          hrgatepassfromdate: fromdate,
          hrgatepasstodate: todate,
        },
      };
    } /*else if (type === "Loading") {
      jsonData = {
        date: {
          loadinglistfromdate: fromdate,
          loadinglisttodate: todate,
        },
      };
    } else if (type === "Unloading") {
      jsonData = {
        date: {
          unloadinglistfromdate: fromdate,
          unloadinglisttodate: todate,
        },
      };
    } else if (type === "SalesReport") {
      jsonData = {
        date: {
          salesreportlistfromdate: fromdate,
          salesreportlisttodate: todate,
        },
      };
    } else if (type === "PurchaseReport") {
      jsonData = {
        date: {
          purchasereportlistfromdate: fromdate,
          purchasereportlisttodate: todate,
        },
      };
    } else if (type === "DayBookReport") {
      jsonData = {
        date: {
          daybookreportlistfromdate: fromdate,
          daybookreportlisttodate: todate,
        },
      };
    } else if (type === "LedgerBookReport") {
      jsonData = {
        date: {
          ledgerbookreportlistfromdate: fromdate,
          ledgerbookreportlisttodate: todate,
        },
      };
    } else if (type === "StockReport") {
      jsonData = {
        date: {
          stockreportlistfromdate: fromdate,
          stockreportlisttodate: todate,
        },
      };
    } */
    localStorage.setItem("defaultsettings", JSON.stringify(jsonData));
  }
}

export function getRowOfRT(row, keyArray) {
  let result = {};
  keyArray.map((key) => {
    result[key] = row.getValue ? row.getValue(key) : row[key];
  });
  return result;
}

//#endregion
