// import logo from './logo.svg';
import "./App.css";
import React, { useState } from "react";
import { HashRouter } from "react-router-dom";
import "./Components/theam.css";
import HoCtToastContainer from "./HOC/HoCtToastContainer";
import { ToastContainer, Zoom } from "react-toastify";
import AppNavigation from "./Components/AppNavigation";

function App() {
  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={4000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Zoom}
      />
      <HashRouter>
        <AppNavigation />
      </HashRouter>
    </div>
  );
}

// export default App;
export default HoCtToastContainer(App);
