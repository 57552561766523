import React, { Component } from "react";
import Box from "@mui/material/Box";
import { Routes, Route, withRouter, useLocation } from "react-router-dom";
import Proposal from "./Proposal";
import Login from "./Login";
import SideNav from "./SideNav";
import Navbar from "./Navbar";
import JDS from "./JDS";
import "./Table.css";
import ProposalEntry from "./ProposalEntry";
import ProposalAllEntry from "./ProposalAllEntry";
import MoreInfo from "./MoreInfo";
import UserManagement from "./UserManagement";
import Dashboard from "./Dashboard";
import InputParametersList from "./InputParametersList";
import InputParametersEntry from "./InputParametersEntry";
import OutputPaeameters from "./OutputPaeameters";
import RailPriceMaster from "./RailPriceMaster";
import GpBracketPriceMaster from "./GpBracketPriceMaster";
import CounterWeightPrice from "./CounterWeightPrice";
import MainGovernorWire from "./MainGovernorWire";
import RawMaterialProcess from "./RawMaterialProcess";
import CounterWeightKg from "./CounterWeightKg";
import PulleyPriceList from "./PulleyPriceList";
import ItemsRelatedSafety from "./ItemsRelatedSafety";
import GeneralItemPrice from "./GeneralItemPrice";
import PassengerLiftCabinPrice from "./PassengerLiftCabinPrice";
import GoodsLiftCabin from "./GoodsLiftCabin";
import AutoDoorMainPrice from "./AutoDoorMainPriceList";
import AutoDoorPanelPriceFomula from "./AutoDoorPanelPriceFomula";
import AutoDoorFramePriceFormula from "./AutoDoorFramePriceFormula";
import RailSelection from "./RailSelection";
import CarInsideDbgCwSelection from "./CarInsideDbgCwSelection";
import SafetyMainPrice from "./SafetyMainPrice";
import ManualDoorPrice from "./ManualDoorPrice";
import ControllerMainVfDrivePricing from "./ControllerMainVfDrivePricing";
import CombinationBracketPrice from "./CombinationBracketPrice";
import CarAndCwFrameSelection from "./CarAndCwFrameSelection";
import SummaryDetaild from "./SummaryDetaild";
import CheckSaveText from "./CheckSaveText";
import PriceOfBracket from "./PriceOfBracket";
import ManualDoorFrameMaster from "./ManualDoorFrameMaster";
import MachinePrice from "./MachinePrice";
import DoorMaterialGrade from "./DoorMaterialGrade";
import DoorBrand from "./DoorBrand";
import ImportExportExcel from "./ImportExportExcel";
import DriveNameMaster from "./DriveNameMaster";
import ArdPriceList from "./ArdPriceList";
import ComChainPricePerKg from "./ComChainPricePerKg";
import PulleyDiameterSelection from "./PulleyDiameterSelection";
import CopPriceMaster from "./CopPriceMaster";
import LopPriceMaster from "./LopPriceMaster";

const AppNavigation = (props) => {
  const location = useLocation();
  const isItemActive = (path) => location.pathname === path;

  let Condition = isItemActive("/");

  return (
    <div>
      {Condition ? "" : <Navbar Heading="Dashboard" />}
      {Condition ? "" : <Box height={50} />}
      <Box sx={{ display: "flex" }}>
        {Condition ? "" : props.userPrivileges ? "" : <SideNav />}
        <Box
          className={Condition ? "" : "mainBox mainComponent"}
          component="main"
          sx={isItemActive("/") ? { flexGrow: 1 } : { flexGrow: 1, p: 3 }}
        >
          <Routes>
            {/* <Route path="Dashboard" exact element={<Dashboard />}></Route> */}
            <Route path="/" exact element={<Login />}></Route>
            <Route path="Dashboard" exact element={<Dashboard />}></Route>
            <Route
              path="UserManagement"
              exact
              element={<UserManagement />}
            ></Route>
            <Route path="Proposal" exact element={<Proposal />}></Route>
            <Route path="JDS" exact element={<JDS />}></Route>
            <Route
              path="ProposalEntry"
              exact
              element={<ProposalEntry />}
            ></Route>
            <Route
              path="ProposalAllEntry"
              exact
              element={<ProposalAllEntry />}
            ></Route>
            <Route path="MoreInfo" exact element={<MoreInfo />}></Route>
            <Route
              path="InputParametersList"
              exact
              element={<InputParametersList />}
            ></Route>
            <Route
              path="InputParametersEntry"
              exact
              element={<InputParametersEntry />}
            ></Route>
            <Route
              path="OutputPaeameters"
              exact
              element={<OutputPaeameters />}
            ></Route>
            <Route
              path="RailPriceMaster"
              exact
              element={<RailPriceMaster />}
            ></Route>
            <Route
              path="GpBracketPriceMaster"
              exact
              element={<GpBracketPriceMaster />}
            ></Route>
            <Route
              path="CounterWeightPrice"
              exact
              element={<CounterWeightPrice />}
            ></Route>
            <Route
              path="MainGovernorWire"
              exact
              element={<MainGovernorWire />}
            ></Route>
            <Route
              path="RawMaterialProcess"
              exact
              element={<RawMaterialProcess />}
            ></Route>
            <Route
              path="CounterWeightKg"
              exact
              element={<CounterWeightKg />}
            ></Route>
            <Route
              path="PulleyPriceList"
              exact
              element={<PulleyPriceList />}
            ></Route>
            <Route
              path="ItemsRelatedSafety"
              exact
              element={<ItemsRelatedSafety />}
            ></Route>
            <Route
              path="GeneralItemPrice"
              exact
              element={<GeneralItemPrice />}
            ></Route>
            <Route
              path="PassengerLiftCabinPrice"
              exact
              element={<PassengerLiftCabinPrice />}
            ></Route>
            <Route
              path="GoodsLiftCabin"
              exact
              element={<GoodsLiftCabin />}
            ></Route>
            <Route
              path="AutoDoorMainPrice"
              exact
              element={<AutoDoorMainPrice />}
            ></Route>
            <Route
              path="AutoDoorPanelPriceFomula"
              exact
              element={<AutoDoorPanelPriceFomula />}
            ></Route>
            <Route
              path="AutoDoorFramePriceFormula"
              exact
              element={<AutoDoorFramePriceFormula />}
            ></Route>
            <Route
              path="RailSelection"
              exact
              element={<RailSelection />}
            ></Route>
            <Route
              path="CarInsideDbgCwSelection"
              exact
              element={<CarInsideDbgCwSelection />}
            ></Route>
            <Route
              path="SafetyMainPrice"
              exact
              element={<SafetyMainPrice />}
            ></Route>
            <Route
              path="ManualDoorPrice"
              exact
              element={<ManualDoorPrice />}
            ></Route>
            <Route
              path="ControllerMainVfDrivePricing"
              exact
              element={<ControllerMainVfDrivePricing />}
            ></Route>
            <Route
              path="CombinationBracketPrice"
              exact
              element={<CombinationBracketPrice />}
            ></Route>
            <Route
              path="CarAndCwFrameSelection"
              exact
              element={<CarAndCwFrameSelection />}
            ></Route>
            <Route
              path="SummaryDetaild"
              exact
              element={<SummaryDetaild />}
            ></Route>
            <Route
              path="CheckSaveText"
              exact
              element={<CheckSaveText />}
            ></Route>
            <Route
              path="PriceOfBracket"
              exact
              element={<PriceOfBracket />}
            ></Route>
            <Route
              path="ManualDoorFrameMaster"
              exact
              element={<ManualDoorFrameMaster />}
            ></Route>
            <Route path="MachinePrice" exact element={<MachinePrice />}></Route>
            <Route
              path="DoorMaterialGrade"
              exact
              element={<DoorMaterialGrade />}
            ></Route>
            <Route path="DoorBrand" exact element={<DoorBrand />}></Route>
            <Route
              path="ComChainPricePerKg"
              exact
              element={<ComChainPricePerKg />}
            ></Route>
            <Route path="DriveName" exact element={<DriveNameMaster />}></Route>
            <Route path="ArdPrice" exact element={<ArdPriceList />}></Route>
            <Route
              path="PulleyDiameterSelection"
              exact
              element={<PulleyDiameterSelection />}
            ></Route>
            <Route
              path="CopPriceMaster"
              exact
              element={<CopPriceMaster />}
            ></Route>
            <Route
              path="LopPriceMaster"
              exact
              element={<LopPriceMaster />}
            ></Route>
            <Route
              path="ImportExportExcel"
              exact
              element={<ImportExportExcel />}
            ></Route>
          </Routes>
        </Box>
      </Box>
    </div>
  );
};

// export default connect(
//   mapStatetoProps,
//   mapSetUserPrivileges_ToProps
// )(AppNavigation);
export default AppNavigation;
