import React, { useState } from "react";
import { fetchUser, fetchUserManagement, loginPath } from "../Components/API";
import { toast } from "react-toastify";
import {
  setPrivileges_Redux,
  checkModulePrivilegesAssignToUser,
} from "../SystemUtility/SystemUtility";
import axios from "axios";

const HOCVerifyIsUser = (UserArea) => {
  // const verifyUserID = (funCallForValidUser, setUserPrivileges, moduleName) => {
  //   // const userID = localStorage.getItem("logInID");
  //   const userID = "admin";
  //   if (userID && userID.length > 0) {
  //     const reqData = {
  //       Op: "VerifyUser",
  //       vac_userid: userID,
  //     };
  //     fetchUser(reqData).then((res) => {
  //       if (
  //         // res.data.status &&
  //         res.data.UserPrivileges &&
  //         // res.data.status == 1 &&
  //         res.data.UserPrivileges.length > 0
  //       ) {
  //         if (funCallForValidUser) {
  //           funCallForValidUser();
  //         }
  //         let userPrivilege = setPrivileges_Redux(
  //           res.data.UserPrivileges,
  //           setUserPrivileges
  //         );
  //         if (moduleName) {
  //           if (!checkModulePrivilegesAssignToUser(userPrivilege, moduleName)) {
  //             navigateToLogin();
  //           }
  //         }
  //       } else {
  //         navigateToLogin();
  //       }
  //     });
  //   } else {
  //     navigateToLogin();
  //   }
  // };

  const verifyUserID = (funCallForValidUser, setUserPrivileges, moduleName) => {
    // const userID = localStorage.getItem("logInID");
    const userID = localStorage.getItem("user_id");
    const authtoken = localStorage.getItem("authtoken");
    // console.log("userID from localStorage:", userID);
    if (userID && userID.length > 0) {
      const data = {
        Op: "VerifyUser",
        user_id: userID,
        authtoken: authtoken,
      };
      // const res = axios
      //   .post("http://localhost:7000/UserManagement", data)
      fetchUserManagement(data)
        .then((response) => {
          if (
            response.data.UserPrivileges &&
            response.data.UserPrivileges.length > 0
          ) {
            if (localStorage.getItem("ErpRole") != response.data.ErpRole) {
              localStorage.setItem("ErpRole", response.data.ErpRole);
            }
            if (funCallForValidUser) {
              funCallForValidUser();
            }
            let userPrivilege = setPrivileges_Redux(
              response.data.UserPrivileges,
              setUserPrivileges
            );
            if (moduleName) {
              if (
                !checkModulePrivilegesAssignToUser(userPrivilege, moduleName)
              ) {
                navigateToLogin();
              }
            }
          } else {
            navigateToLogin();
          }

          // console.log("response.data", response.data);
        })
        .catch((error) => {
          console.log("Error fetching modules:", error);
          // navigateToLogin();
        });
    } else {
      navigateToLogin();
    }
  };

  const navigateToLogin = () => {
    // alert("navigateToLogin Called : " + i);
    clearLocalStorage();
    window.location.assign(loginPath);
  };

  const clearLocalStorage = () => {
    // localStorage.removeItem("logInID");
    // localStorage.removeItem("UserName");
    // localStorage.removeItem("ERPRole");
    // localStorage.removeItem("UserSrNo");
    localStorage.removeItem("authtoken");
    localStorage.removeItem("user_id");
  };

  return (props) => {
    return (
      <div className="UserArea">
        <UserArea {...props} verifyUserID={verifyUserID} />
      </div>
    );
  };
};
export default HOCVerifyIsUser;
