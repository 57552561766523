import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import HOCVerifyIsUser from "../HOC/HOCVerifyIsUser";
import CtBtn from "../Components/CustomTool/CtBtn";
import CtTxt from "../Components/CustomTool/CtTxt";
import CtTextFieldPWD from "../Components/CustomTool/CtTxtPWD";
import CtCmb from "../Components/CustomTool/CtCmb";
import CtTxtNum from "../Components/CustomTool/CtTxtNum";
import MaterialTable from "../Components/CustomTool/MaterialTable";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import AlertResponsiveDialog from "../Components/CustomTool/AlertResponsiveDialog";
// import DepartmentMaster from "./DepartmentMaster";
import { Stack } from "@mui/material";
// import PlantMaster from "./PlantMaster";
// import { fetchUser } from "../Components/API";
import HoCtToastContainer from "../HOC/HoCtToastContainer";
import {
  applyTrimOnObjectValues,
  csvFileName,
  getRowOfRT,
  setKeyboardShortcuts,
} from "../SystemUtility/SystemUtility";
import {
  mapSetUserPrivileges_ToProps,
  mapStatetoProps,
} from "../mapRedux/mapReduxProps";
import { connect } from "react-redux";
import { withRouter } from "../HOC/withRouter";
import { fetchCounterWeightKg } from "./API";
import MobileTable from "./CustomTool/MobileTable";
import CtART from "./CustomTool/ResponsiveTable";
import axios from "axios";
import CtTxtAmt from "../Components/CustomTool/CtTxtAmt";

class CounterWeightPrice extends Component {
  state = {
    Type: [{ vac_type: "Persons" }, { vac_type: "Load Capacity" }],
    columnsdata: [
      {
        accessorKey: "bint_srno",
        header: "bint_srno",
      },
      {
        accessorKey: "vac_loadtype",
        header: "Load Type",
      },
      {
        accessorKey: "bint_persons",
        header: "Person / Load Capacity",
      },
      {
        accessorKey: "bint_balancce",
        header: "Balance Weight (KG)",
        size: 300,
      },
      {
        accessorKey: "bint_overbalanceweight",
        header: "Overbalance Weight (KG)",
        size: 220,
      },

      {
        accessorKey: "bint_totalcounterweight",
        header: "Total Counter Weight (KG)",
      },
    ],

    rows: [],
    formData: {
      bint_srno: 0,
      cmbLoadType: "",
      txtPerson: "",
      txtBalance: "",
      txtOverbalance: "",
      txtTotal: "",
    },
    dataLoadStatus: true,
    ARD: null,
    rowsCount: 0,
    rowsPerPage: 50,
  };

  //#region Component
  componentDidMount() {
    // document.title = "User Management : Top India";
    localStorage.setItem("Heading", "Counter Weight Kg");
    this.props.verifyUserID(
      this.getInfo,
      this.props.setUserPrivileges,
      this.props.modules.counterweightkg
    );
    this.ShortCut();
  }

  componentWillUnmount() {
    this.removeShortCut();
  }

  //#endregion Component

  //#region Handle

  ShortCut = () => {
    this.removeShortCut = setKeyboardShortcuts(
      this.saveCounterWeightKg,
      this.clearInfo,
      () => {
        this.DeleteData(
          this.state.formData.bint_srno,
          this.state.formData.txtPerson
        );
      }
    );
  };

  handleOnChange = (e) => {
    this.setState(
      {
        formData: { ...this.state.formData, [e.target.name]: e.target.value },
      },
      () => {
        if (
          (e.target.name == "cmbLoadType" ||
            e.target.name == "txtPerson" ||
            e.target.name == "txtBalance") &&
          this.state.formData.cmbLoadType.length > 0
        ) {
          let txtOverbalance = this.state.formData.txtOverbalance,
            txtTotal = this.state.formData.txtTotal;

          if (this.state.formData.cmbLoadType == "Persons") {
            txtOverbalance = (Number(this.state.formData.txtPerson) * 68) / 2;
          } else if (this.state.formData.cmbLoadType == "Load Capacity") {
            txtOverbalance = Number(this.state.formData.txtPerson) * 0.5;
          }

          if (
            Number(this.state.formData.txtBalance) > 0 &&
            Number(txtOverbalance) > 0
          ) {
            txtTotal =
              Number(this.state.formData.txtBalance) + Number(txtOverbalance);
          }

          this.setState({
            formData: {
              ...this.state.formData,
              txtOverbalance,
              txtTotal,
            },
          });
        }
      }
    );
  };

  handleARDonActionClick_Edit = (rtRow) => {
    const row = getRowOfRT(rtRow, [
      "bint_srno",
      "vac_loadtype",
      "bint_persons",
      "bint_balancce",
      "bint_overbalanceweight",
      "bint_totalcounterweight",
    ]);
    const bint_srno = row.bint_srno,
      cmbLoadType = row.vac_loadtype,
      txtPerson = row.bint_persons,
      txtBalance = row.bint_balancce,
      txtOverbalance = row.bint_overbalanceweight,
      txtTotal = row.bint_totalcounterweight;
    this.setState({
      formData: {
        ...this.state.formData,
        bint_srno,
        cmbLoadType,
        txtPerson,
        txtBalance,
        txtOverbalance,
        txtTotal,
      },
    });
    document.getElementById("cmbLoadType").focus();
  };

  handleARDonActionClick_Delete = (rtRow) => {
    const row = getRowOfRT(rtRow, ["bint_srno", "bint_persons"]);
    this.DeleteData(row.bint_srno, row.bint_persons);
  };

  //#endregion Handle

  //#region function

  getInfo = () => {
    this.props.notifyProcessing();
    const reqData = {
      Op: "ListCounterWeightKg",
    };
    fetchCounterWeightKg(reqData)
      .then((res) => {
        this.props.closeUpdateProcessing();
        let rows = res.data.ListCounterWeightKg,
          rowsCount = rows.length;
        this.setState({
          rowsCount,
          rows,
        });
      })
      .catch((error) => {
        console.log("Unknown error occurred in onFormLoad.", error);
      });
    document.getElementById("cmbLoadType").focus();
  };

  clearInfo = () => {
    this.setState({
      formData: {
        ...this.state.formData,
        bint_srno: 0,
        cmbLoadType: "",
        txtPerson: "",
        txtBalance: "",
        txtOverbalance: "",
        txtTotal: "",
      },
    });
  };

  trimFormData = (nextFunctionCall) => {
    let formData = applyTrimOnObjectValues({
      formData: this.state.formData,
    });
    this.setState({ formData }, () => {
      if (nextFunctionCall) {
        nextFunctionCall();
      }
    });
  };

  validationCounterWeightKg = () => {
    let result = false;
    if (this.state.formData.cmbLoadType.length <= 0) {
      this.props.toastErrorMsg("Select Load Type", "cmbLoadType");
    } else if (this.state.formData.txtPerson.length <= 0) {
      this.props.toastErrorMsg("Enter Person / Load Capacity", "txtPerson");
    } else if (this.state.formData.txtBalance.length <= 0) {
      this.props.toastErrorMsg("Enter Balance Weight (KG)", "txtBalance");
    } else if (this.state.formData.txtOverbalance.length <= 0) {
      this.props.toastErrorMsg(
        "Enter Overbalance Weight (KG)",
        "txtOverbalance"
      );
    } else if (this.state.formData.txtTotal.length <= 0) {
      this.props.toastErrorMsg("Enter Total Counter Weight (KG)", "txtTotal");
    } else {
      result = true;
    }
    return result;
  };

  saveCounterWeightKg = () => {
    this.trimFormData(() => {
      if (this.validationCounterWeightKg()) {
        this.props.notifyProcessing();
        const reqData = {
          Op: "SaveCounterWeightKg",
          bint_srno: this.state.formData.bint_srno,
          vac_loadtype: this.state.formData.cmbLoadType,
          bint_persons: this.state.formData.txtPerson,
          bint_balancce: this.state.formData.txtBalance,
          bint_overbalanceweight: this.state.formData.txtOverbalance,
          bint_totalcounterweight: this.state.formData.txtTotal,
        };
        fetchCounterWeightKg(reqData)
          .then((res) => {
            this.props.updateProcessing(res.data.msgType, res.data.message);
            if (res.data.msgType === "success") {
              let rows = [],
                rowsCount = rows.length;
              if (
                res.data.ListCounterWeightKg &&
                res.data.ListCounterWeightKg.length > 0
              ) {
                rows = res.data.ListCounterWeightKg;
                rowsCount = rows.length;
              }
              this.setState(
                {
                  rows,
                  rowsCount,
                },
                () => {
                  this.clearInfo();
                }
              );
            }
          })
          .catch((error) => {
            console.log(
              "Unknown error occurred in Save Counter Weight Kg .",
              error
            );
          });
      }
    });
  };

  DeleteData = (bint_srno, bint_person) => {
    if (bint_srno > 0) {
      this.setState({ ARD: null }, () => {
        let ARD = (
          <AlertResponsiveDialog
            labelAgree="Delete"
            labelDisagree="Cancel"
            alertTitle={`Do you want to Delete Passenger / Load Capacity : ${bint_person} ?`}
            alertMessage=""
            handleOnClickYes={() => {
              return this.DeleteCounterWeightKg(bint_srno);
            }}
            defaultOpenDialog={true}
            onYesClickCloseIfExeSuccessfully={true}
          />
        );
        this.setState({ ARD });
      });
    } else {
      this.props.toastErrorMsg("Select a record for deletion.");
    }
  };

  DeleteCounterWeightKg = (bint_srno) => {
    this.props.notifyProcessing();
    const reqData = {
      Op: "DeleteCounterWeightKg",
      bint_srno: bint_srno,
    };
    fetchCounterWeightKg(reqData)
      .then((res) => {
        this.props.updateProcessing(res.data.msgType, res.data.message);
        if (res.data.msgType === "success") {
          let rows = [],
            rowsCount = rows.length;
          if (
            res.data.ListCounterWeightKg &&
            res.data.ListCounterWeightKg.length > 0
          ) {
            rows = res.data.ListCounterWeightKg;
            rowsCount = rows.length;
          }
          this.setState(
            {
              rows,
              rowsCount,
              ARD: null,
            },
            () => {
              this.clearInfo();
            }
          );
        }
      })
      .catch((error) => {
        this.props.updateProcessing(
          "error",
          "Unknown error occurred in delete Counter Weight Kg. " + error
        );
      });
    return false;
  };

  //#endregion function

  render() {
    return (
      <form>
        <Grid container direction="row" justifyContent="flex-end">
          <Grid item>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <CtBtn
                  className="secondary-button"
                  label="Save"
                  iconName="save"
                  onClick={this.saveCounterWeightKg}
                />
              </Grid>
              <Grid item>
                <CtBtn
                  label="New"
                  className="new-button"
                  iconName="new"
                  onClick={this.clearInfo}
                />
              </Grid>
              <Grid item>
                <CtBtn
                  label="Delete"
                  className="delete-button"
                  iconName="delete"
                  onClick={() => {
                    this.DeleteData(
                      this.state.formData.bint_srno,
                      this.state.formData.txtPerson
                    );
                  }}
                  disabled={
                    Number(this.state.formData.bint_srno) > 0 ? false : true
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          marginTop={0}
        >
          <Grid item>
            <CtCmb
              id="cmbLoadType"
              label="Load Type"
              items={this.state.Type}
              maxLength={100}
              width="220"
              value={this.state.formData.cmbLoadType}
              colID={"vac_type"}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtPerson"
              label="Person / Load Capacity"
              maxLength={100}
              width="220"
              value={this.state.formData.txtPerson}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtBalance"
              label="Balance Weight (KG)"
              maxLength={100}
              width="220"
              value={this.state.formData.txtBalance}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
              defaultAction={this.saveCounterWeightKg}
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtOverbalance"
              label="Overbalance Weight (KG)"
              maxLength={100}
              width="220"
              value={this.state.formData.txtOverbalance}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
              disabled={true}
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtTotal"
              label="Total Counter Weight (KG)"
              maxLength={100}
              width="220"
              value={this.state.formData.txtTotal}
              handleOnChange={this.handleOnChange}
              disabled={true}
            />
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          marginTop={1}
        >
          {/* responsive table */}
          <Grid item className="maintable">
            <CtART
              rowsdata={this.state.rows}
              columnsdata={this.state.columnsdata}
              rowsPerPage={this.state.rowsPerPage}
              tblmaxHeight={500}
              csvFileName={csvFileName("Rail Price Master")}
              rowIconAction={[
                {
                  label: "Edit",
                  onClick: this.handleARDonActionClick_Edit,
                  icon: "Edit",
                  className: "secondary-button",
                },
                {
                  label: "Delete",
                  onClick: this.handleARDonActionClick_Delete,
                  icon: "delete",
                  className: "delete-button",
                },
              ]}
              showColumnFilters={false}
              actionColSize={90}
              density={"compact"}
              hideColIdList={["bint_srno"]}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Box display={{ xs: "none" }} style={{ textAlign: "right" }}>
            {this.state.ARD}
          </Box>
        </Grid>
      </form>
    );
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <CounterWeightPrice {...props} navigate={navigate} />;
}
export default connect(
  mapStatetoProps,
  mapSetUserPrivileges_ToProps
)(HoCtToastContainer(HOCVerifyIsUser(withRouter(WithNavigate))));
