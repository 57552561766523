import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import HOCVerifyIsUser from "../HOC/HOCVerifyIsUser";
import CtBtn from "../Components/CustomTool/CtBtn";
import CtTxt from "../Components/CustomTool/CtTxt";
import CtTextFieldPWD from "../Components/CustomTool/CtTxtPWD";
import CtCmb from "../Components/CustomTool/CtCmb";
import CtTxtNum from "../Components/CustomTool/CtTxtNum";
import MaterialTable from "../Components/CustomTool/MaterialTable";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import AlertResponsiveDialog from "../Components/CustomTool/AlertResponsiveDialog";
// import DepartmentMaster from "./DepartmentMaster";
import { Stack } from "@mui/material";
// import PlantMaster from "./PlantMaster";
import HoCtToastContainer from "../HOC/HoCtToastContainer";
import {
  applyTrimOnObjectValues,
  csvFileName,
  getRowOfRT,
  setKeyboardShortcuts,
} from "../SystemUtility/SystemUtility";
import {
  mapSetUserPrivileges_ToProps,
  mapStatetoProps,
} from "../mapRedux/mapReduxProps";
import { connect } from "react-redux";
import { withRouter } from "../HOC/withRouter";
import { fetchrailprice } from "./API";
import MobileTable from "./CustomTool/MobileTable";
import CtART from "./CustomTool/ResponsiveTable";
import axios from "axios";
import CtTxtAmt from "../Components/CustomTool/CtTxtAmt";

class RailPriceMaster extends Component {
  state = {
    Unit: [{ vac_type: "SET" }],
    columnsdata: [
      {
        accessorKey: "bint_srno",
        header: "Sr No",
      },
      {
        accessorKey: "vac_railtype",
        header: "Rail Type",
      },
      {
        accessorKey: "dec_priceperunit",
        header: "Price Per Unit",
        size: 220,
      },

      {
        accessorKey: "vac_unit",
        header: "Unit",
      },
    ],

    rows: [],
    formData: {
      txtType: "",
      txtPrice: "",
      cmbUnit: "",
      bint_srno: 0,
    },
    dataLoadStatus: true,
    ARD: null,
    rowsCount: 0,
    rowsPerPage: 50,
  };

  //#region Component
  componentDidMount() {
    // document.title = "User Management : Top India";
    localStorage.setItem("Heading", "Rail Price");
    this.props.verifyUserID(
      this.getInfo,
      this.props.setUserPrivileges,
      this.props.modules.railprice
    );
    this.ShortCut();
    // this.getInfo();
  }

  componentWillUnmount() {
    this.removeShortCut();
  }

  //#endregion Component

  //#region Handle

  ShortCut = () => {
    this.removeShortCut = setKeyboardShortcuts(
      this.SaveRailPrice,
      this.clearInfo,
      () => {
        this.DeleteData(
          this.state.formData.bint_srno,
          this.state.formData.txtType
        );
      }
    );
  };

  handleOnChange = (e) => {
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: e.target.value },
    });
  };

  handleARDonActionClick_Edit = (rtRow) => {
    const row = getRowOfRT(rtRow, [
      "bint_srno",
      "vac_railtype",
      "dec_priceperunit",
      "vac_unit",
    ]);

    const bint_srno = row.bint_srno,
      txtType = row.vac_railtype,
      txtPrice = row.dec_priceperunit,
      cmbUnit = row.vac_unit;
    this.setState({
      formData: {
        ...this.state.formData,
        txtType,
        txtPrice,
        bint_srno,
        cmbUnit,
      },
    });
    // window.scrollTo(0, 0);
    document.getElementById("txtType").focus();
  };

  handleARDonActionClick_Delete = (row) => {
    const bint_srno = row.getValue("bint_srno"),
      Rain_price = row.getValue("vac_railtype");
    this.DeleteData(bint_srno, Rain_price);
  };

  //#endregion Handle

  //#region function
  DeleteData = (bint_srno, Rain_price) => {
    if (bint_srno > 0) {
      this.setState({ ARD: null }, () => {
        let ARD = (
          <AlertResponsiveDialog
            labelAgree="Delete"
            labelDisagree="Cancel"
            alertTitle={
              "Do you want to Delete Rail Price : " + Rain_price + " ?"
            }
            alertMessage=""
            handleOnClickYes={() => {
              return this.DeleteRailPrice(bint_srno);
            }}
            defaultOpenDialog={true}
            onYesClickCloseIfExeSuccessfully={true}
          />
        );

        this.setState({ ARD });
      });
    } else {
      this.props.toastErrorMsg("Select a record for deletion.");
    }
  };

  DeleteRailPrice = (bint_srno) => {
    this.props.notifyProcessing();
    const reqData = {
      Op: "DeleteRailPrice",
      bint_srno: bint_srno,
    };
    fetchrailprice(reqData)
      .then((res) => {
        this.props.updateProcessing(res.data.msgType, res.data.message);
        if (res.data.msgType === "success") {
          let rows = [],
            rowsCount = rows.length;
          if (res.data.RailPriceList && res.data.RailPriceList.length > 0) {
            rows = res.data.RailPriceList;
            rowsCount = rows.length;
          }
          this.setState(
            {
              rows,
              rowsCount,
              ARD: null,
            },
            () => {
              this.clearInfo();
            }
          );
          document.getElementById("txtType").focus();
        }
      })
      .catch((error) => {
        this.props.updateProcessing(
          "error",
          "Unknown error occurred in delete Rail Price Master. " + error
        );
      });
    return false;
  };

  getInfo = () => {
    this.props.notifyProcessing();
    const reqData = {
      Op: "ListRailPrice",
    };
    fetchrailprice(reqData)
      .then((res) => {
        this.props.closeUpdateProcessing();
        let rows = res.data.ListRailPrice,
          rowsCount = rows.length;
        this.setState({
          rowsCount,
          rows,
        });
      })
      .catch((error) => {
        console.log("Unknown error occurred in onFormLoad.", error);
      });
    document.getElementById("txtType").focus();
  };

  clearInfo = () => {
    this.setState(
      {
        formData: {
          ...this.state.formData,
          bint_srno: "",
          txtType: "",
          txtPrice: "",
          cmbUnit: "",
        },
      },
      () => {
        document.getElementById("txtType").focus();
      }
    );
  };

  trimFormData = (nextFunctionCall) => {
    let formData = applyTrimOnObjectValues({
      formData: this.state.formData,
    });
    this.setState({ formData }, () => {
      if (nextFunctionCall) {
        nextFunctionCall();
      }
    });
  };

  validationRailPrice = () => {
    let result = false;
    if (this.state.formData.txtType.length <= 0) {
      this.props.toastErrorMsg("Enter Rail Type", "txtType");
    } else if (this.state.formData.txtPrice.length <= 0) {
      this.props.toastErrorMsg("Enter Price Per Unit", "txtPrice");
    } else if (this.state.formData.cmbUnit.length <= 0) {
      this.props.toastErrorMsg("Select Unit", "cmbUnit");
    } else {
      result = true;
    }
    return result;
  };

  SaveRailPrice = () => {
    this.trimFormData(() => {
      if (this.validationRailPrice()) {
        this.props.notifyProcessing();
        const reqData = {
          Op: "SaveRailPrice",
          bint_srno: this.state.formData.bint_srno,
          vac_railtype: this.state.formData.txtType,
          dec_priceperunit: this.state.formData.txtPrice,
          vac_unit: this.state.formData.cmbUnit,
        };
        fetchrailprice(reqData)
          .then((res) => {
            this.props.updateProcessing(res.data.msgType, res.data.message);
            if (res.data.msgType === "success") {
              let rows = [],
                rowsCount = rows.length;
              if (res.data.RailPriceList && res.data.RailPriceList.length > 0) {
                rows = res.data.RailPriceList;
                rowsCount = rows.length;
              }
              this.setState(
                {
                  rows,
                  rowsCount,
                },
                () => {
                  this.clearInfo();
                }
              );
            }
          })
          .catch((error) => {
            console.log(
              "Unknown error occurred in Save Rail Price Master .",
              error
            );
          });
      }
    });
  };

  //#endregion function

  render() {
    return (
      <form>
        <Grid container direction="row" justifyContent="flex-end">
          <Grid item>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <CtBtn
                  className="secondary-button"
                  label="Save"
                  iconName="save"
                  onClick={this.SaveRailPrice}
                />
              </Grid>
              <Grid item>
                <CtBtn
                  label="New"
                  className="new-button"
                  iconName="new"
                  onClick={this.clearInfo}
                />
              </Grid>
              <Grid item>
                <CtBtn
                  label="Delete"
                  className="delete-button"
                  iconName="delete"
                  onClick={() => {
                    this.DeleteData(
                      this.state.formData.bint_srno,
                      this.state.formData.txtType
                    );
                  }}
                  disabled={
                    Number(this.state.formData.bint_srno) > 0 ? false : true
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          marginTop={0}
        >
          <Grid item>
            <CtTxt
              id="txtType"
              label="Rail Type*"
              maxLength={100}
              width="220"
              value={this.state.formData.txtType}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
              nextCtrlID="txtPrice"
            />
          </Grid>
          <Grid item>
            <CtTxtAmt
              id="txtPrice"
              label="Price Per Unit*"
              maxLength={10}
              width="220"
              value={this.state.formData.txtPrice}
              handleOnChange={this.handleOnChange}
              RupeeSymbol={true}
            />
          </Grid>
          <Grid item>
            <CtCmb
              id="cmbUnit"
              label="Unit*"
              items={this.state.Unit}
              value={this.state.formData.cmbUnit}
              handleOnChange={this.handleOnChange}
              width={220}
              maxLength={100}
              colID="vac_type"
            />
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          marginTop={1}
        >
          {/* responsive table */}
          <Grid item className="maintable">
            <CtART
              rowsdata={this.state.rows}
              columnsdata={this.state.columnsdata}
              rowsPerPage={this.state.rowsPerPage}
              tblmaxHeight={500}
              csvFileName={csvFileName("Rail Price Master")}
              rowIconAction={[
                {
                  label: "Edit",
                  onClick: this.handleARDonActionClick_Edit,
                  icon: "Edit",
                  className: "secondary-button",
                },
                {
                  label: "Delete",
                  onClick: this.handleARDonActionClick_Delete,
                  icon: "delete",
                  className: "delete-button",
                },
              ]}
              showColumnFilters={false}
              actionColSize={90}
              density={"compact"}
              hideColIdList={["bint_srno"]}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Box display={{ xs: "none" }} style={{ textAlign: "right" }}>
            {this.state.ARD}
          </Box>
        </Grid>
      </form>
    );
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <RailPriceMaster {...props} navigate={navigate} />;
}
export default connect(
  mapStatetoProps,
  mapSetUserPrivileges_ToProps
)(HoCtToastContainer(HOCVerifyIsUser(withRouter(WithNavigate))));
