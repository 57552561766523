import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import HOCVerifyIsUser from "../HOC/HOCVerifyIsUser";
import CtBtn from "./CustomTool/CtBtn";
import CtTxt from "./CustomTool/CtTxt";
import CtTextFieldPWD from "./CustomTool/CtTxtPWD";
import CtCmb from "./CustomTool/CtCmb";
import CtTxtNum from "./CustomTool/CtTxtNum";
import MaterialTable from "./CustomTool/MaterialTable";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";
// import DepartmentMaster from "./DepartmentMaster";
import { Stack } from "@mui/material";
// import PlantMaster from "./PlantMaster";
// import { fetchUser } from "../Components/API";
import HoCtToastContainer from "../HOC/HoCtToastContainer";
import {
  applyTrimOnObjectValues,
  csvFileName,
  getRowOfRT,
  setKeyboardShortcuts,
} from "../SystemUtility/SystemUtility";
import {
  mapSetUserPrivileges_ToProps,
  mapStatetoProps,
} from "../mapRedux/mapReduxProps";
import { connect } from "react-redux";
import { withRouter } from "../HOC/withRouter";
import { fetchMachinePrice, fetchUser } from "./API";
import MobileTable from "./CustomTool/MobileTable";
import CtART from "./CustomTool/ResponsiveTable";
import axios from "axios";
import CtTxtAmt from "./CustomTool/CtTxtAmt";
import CtCmbEditable from "./CustomTool/CtCmbEditable";

class MachinePrice extends Component {
  state = {
    McType: [{ vac_mctype: "GEARLESS" }, { vac_mctype: "GRARED" }],
    McMake: [],
    Roping: [{ vac_roping: "1:1" }, { vac_roping: "2:1" }],

    columnsdata: [
      {
        accessorKey: "bint_srno",
        header: "bint_srno",
        enableColumnDragging: false,
        enableColumnActions: false,
        enableResizing: false,
        enableSorting: false,
        size: 70,
      },
      {
        accessorKey: "vac_machinetype",
        header: "MC Type",
        enableColumnDragging: false,
        enableColumnActions: false,
        enableResizing: false,
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: "vac_machinemake",
        header: "MC Make",
        enableColumnDragging: false,
        enableColumnActions: false,
        enableResizing: false,
        enableSorting: false,
        size: 100,
      },
      {
        accessorKey: "vac_model",
        header: "Model No",
        enableColumnDragging: false,
        enableColumnActions: false,
        enableResizing: false,
        enableSorting: false,
        size: 80,
      },

      {
        accessorKey: "bint_person",
        header: "Person",
        enableColumnDragging: false,
        enableColumnActions: false,
        enableResizing: false,
        enableSorting: false,
        size: 120,
      },
      {
        accessorKey: "bint_loadcapacity",
        header: "Load Capacity",
        enableColumnDragging: false,
        enableColumnActions: false,
        enableResizing: false,
        enableSorting: false,
        size: 160,
      },
      {
        accessorKey: "dec_speed",
        header: "Speed",
        enableColumnDragging: false,
        enableColumnActions: false,
        enableResizing: false,
        enableSorting: false,
      },
      {
        accessorKey: "vac_roping",
        header: "Roping",
        enableColumnDragging: false,
        enableColumnActions: false,
        enableResizing: false,
        enableSorting: false,
      },
      {
        accessorKey: "bint_sheave",
        header: "Sheave",
        enableColumnDragging: false,
        enableColumnActions: false,
        enableResizing: false,
        enableSorting: false,
      },
      {
        accessorKey: "bint_ropedia",
        header: "Rope Dia",
        enableColumnDragging: false,
        enableColumnActions: false,
        enableResizing: false,
        enableSorting: false,
      },
      {
        accessorKey: "bint_noofrope",
        header: "No Of Rope",
        enableColumnDragging: false,
        enableColumnActions: false,
        enableResizing: false,
        enableSorting: false,
      },
      {
        accessorKey: "dec_amp",
        header: "AMP",
        enableColumnDragging: false,
        enableColumnActions: false,
        enableResizing: false,
        enableSorting: false,
      },
      {
        accessorKey: "dec_kw",
        header: "KW",
        enableColumnDragging: false,
        enableColumnActions: false,
        enableResizing: false,
        enableSorting: false,
      },
      {
        accessorKey: "dec_hz",
        header: "HZ",
        enableColumnDragging: false,
        enableColumnActions: false,
        enableResizing: false,
        enableSorting: false,
      },
      {
        accessorKey: "dec_pole",
        header: "Pole",
        enableColumnDragging: false,
        enableColumnActions: false,
        enableResizing: false,
        enableSorting: false,
      },
      {
        accessorKey: "dec_rpm",
        header: "RPM",
        enableColumnDragging: false,
        enableColumnActions: false,
        enableResizing: false,
        enableSorting: false,
      },
      {
        accessorKey: "vac_gearratio",
        header: "Gear Ratio",
        enableColumnDragging: false,
        enableColumnActions: false,
        enableResizing: false,
        enableSorting: false,
      },
      {
        accessorKey: "dec_purchaseprice",
        header: "Purchase Price",
        enableColumnDragging: false,
        enableColumnActions: false,
        enableResizing: false,
        enableSorting: false,
      },
    ],

    rows: [],
    formData: {
      bint_srno: 0,
      cmbMcMake: "",
      cmbMcType: "",
      txtModel: "",
      txtPerson: "",
      txtLoadCapacity: "",
      txtSpeed: "",
      cmbRoping: "",
      txtSheave: "",
      txtRopeDia: "",
      txtNoOfRope: "",
      txtAMP: "",
      txtKW: "",
      txtHZ: "",
      txtPole: "",
      txtRPM: "",
      txtGearRatio: "",
      txtPurchasePrice: "",
    },
    dataLoadStatus: true,
    ARD: null,
    rowsCount: 0,
    rowsPerPage: 50,
  };

  //#region Component
  componentDidMount() {
    localStorage.setItem("Heading", "Machine Price");
    this.props.verifyUserID(
      this.getInfo,
      this.props.setUserPrivileges,
      this.props.modules.machineprice
    );
    this.ShortCut();
  }

  componentWillUnmount() {
    this.removeShortCut();
  }

  //#endregion Component

  //#region Handle

  ShortCut = () => {
    this.removeShortCut = setKeyboardShortcuts(
      this.saveMachinePrice,
      this.clearInfo,
      () => {
        this.DeleteData(
          this.state.formData.bint_srno,
          this.state.formData.txtModel
        );
      }
    );
  };

  handleOnChange = (e) => {
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: e.target.value },
    });
  };

  handleARDonActionClick_Edit = (rtRow) => {
    const row = getRowOfRT(rtRow, [
      "bint_srno",
      "vac_machinetype",
      "vac_machinemake",
      "vac_model",
      "bint_person",
      "bint_loadcapacity",
      "dec_speed",
      "vac_roping",
      "bint_sheave",
      "bint_ropedia",
      "bint_noofrope",
      "dec_amp",
      "dec_kw",
      "dec_hz",
      "dec_pole",
      "dec_rpm",
      "vac_gearratio",
      "dec_purchaseprice",
    ]);

    console.log("row", row);

    const bint_srno = row.bint_srno,
      cmbMcType = row.vac_machinetype,
      cmbMcMake = row.vac_machinemake,
      txtModel = row.vac_model,
      txtPerson = row.bint_person,
      txtLoadCapacity = row.bint_loadcapacity,
      txtSpeed = row.dec_speed,
      cmbRoping = row.vac_roping,
      txtSheave = row.bint_sheave,
      txtRopeDia = row.bint_ropedia,
      txtNoOfRope = row.bint_noofrope,
      txtAMP = row.dec_amp,
      txtKW = row.dec_kw,
      txtHZ = row.dec_hz,
      txtPole = row.dec_pole,
      txtRPM = row.dec_rpm,
      txtGearRatio = row.vac_gearratio,
      txtPurchasePrice = row.dec_purchaseprice;
    this.setState(
      {
        formData: {
          ...this.state.formData,
          bint_srno,
          cmbMcMake,
          cmbMcType,
          txtModel,
          txtPerson,
          txtLoadCapacity,
          txtSpeed,
          cmbRoping,
          txtSheave,
          txtRopeDia,
          txtNoOfRope,
          txtAMP,
          txtKW,
          txtHZ,
          txtPole,
          txtRPM,
          txtGearRatio,
          txtPurchasePrice,
        },
      },
      () => {
        document.getElementById("cmbMcType").focus();
      }
    );
  };

  handleARDonActionClick_Delete = (rtRow) => {
    const row = getRowOfRT(rtRow, ["bint_srno", "vac_model"]);

    const bint_srno = row.bint_srno,
      vac_model = row.vac_model;

    this.DeleteData(bint_srno, vac_model);
  };

  //#endregion Handle

  //#region function

  getInfo = () => {
    this.props.notifyProcessing();
    const reqData = {
      Op: "ListMachinePrice",
    };
    fetchMachinePrice(reqData)
      .then((res) => {
        this.props.closeUpdateProcessing();
        let rows = res.data.ListMachinePrice,
          rowsCount = rows.length;
        this.setState({
          rowsCount,
          rows,
          McMake: res.data.MachineMake,
        });
      })
      .catch((error) => {
        console.log("Unknown error occurred in onFormLoad.", error);
      });
    document.getElementById("cmbMcType").focus();
  };

  clearInfo = () => {
    this.setState(
      {
        formData: {
          bint_srno: 0,
          cmbMcMake: "",
          cmbMcType: "",
          txtModel: "",
          txtPerson: "",
          txtLoadCapacity: "",
          txtSpeed: "",
          cmbRoping: "",
          txtSheave: "",
          txtRopeDia: "",
          txtNoOfRope: "",
          txtAMP: "",
          txtKW: "",
          txtHZ: "",
          txtPole: "",
          txtRPM: "",
          txtGearRatio: "",
          txtPurchasePrice: "",
        },
      },
      () => {
        document.getElementById("cmbMcType").focus();
      }
    );
  };

  trimFormData = (nextFunctionCall) => {
    let formData = applyTrimOnObjectValues({
      formData: this.state.formData,
    });
    this.setState({ formData }, () => {
      if (nextFunctionCall) {
        nextFunctionCall();
      }
    });
  };

  validationMachinePrice = () => {
    let result = false;
    if (this.state.formData.cmbMcType.length <= 0) {
      this.props.toastErrorMsg("Select MC Type", "cmbMcType");
    } else if (this.state.formData.cmbMcMake.length <= 0) {
      this.props.toastErrorMsg("Select MC Make", "cmbMcMake");
    } else if (this.state.formData.txtModel.length <= 0) {
      this.props.toastErrorMsg("Enter Model No", "txtModel");
    } else if (Number(this.state.formData.txtPerson) <= 0) {
      this.props.toastErrorMsg("Enter Person", "txtPerson");
    } else if (Number(this.state.formData.txtLoadCapacity) <= 0) {
      this.props.toastErrorMsg("Enter Load Capacity", "txtLoadCapacity");
    } else if (Number(this.state.formData.txtSpeed) <= 0) {
      this.props.toastErrorMsg("Enter Speed", "txtSpeed");
    } else if (this.state.formData.cmbRoping.length <= 0) {
      this.props.toastErrorMsg("Select Roping", "cmbRoping");
    } else if (Number(this.state.formData.txtSheave) <= 0) {
      this.props.toastErrorMsg("Enter Sheave", "txtSheave");
    } else if (Number(this.state.formData.txtRopeDia) <= 0) {
      this.props.toastErrorMsg("Enter RopeDia", "txtRopeDia");
    } else if (Number(this.state.formData.txtNoOfRope) <= 0) {
      this.props.toastErrorMsg("Enter NoOfRope", "txtNoOfRope");
    } else if (Number(this.state.formData.txtAMP) <= 0) {
      this.props.toastErrorMsg("Enter AMP", "txtAMP");
    } else if (Number(this.state.formData.txtKW) <= 0) {
      this.props.toastErrorMsg("Enter KW", "txtKW");
    } else if (Number(this.state.formData.txtHZ) <= 0) {
      this.props.toastErrorMsg("Enter HZ", "txtHZ");
    } else if (Number(this.state.formData.txtPole) <= 0) {
      this.props.toastErrorMsg("Enter Pole", "txtPole");
    } else if (Number(this.state.formData.txtRPM) <= 0) {
      this.props.toastErrorMsg("Enter RPM", "txtRPM");
    } else if (Number(this.state.formData.txtPurchasePrice) <= 0) {
      this.props.toastErrorMsg("Enter PurchasePrice", "txtPurchasePrice");
    } else {
      result = true;
    }
    return result;
  };

  saveMachinePrice = () => {
    this.trimFormData(() => {
      if (this.validationMachinePrice()) {
        this.props.notifyProcessing();
        const reqData = {
          Op: "SaveMachinePrice",
          bint_srno: this.state.formData.bint_srno,
          vac_machinetype: this.state.formData.cmbMcType,
          vac_machinemake: this.state.formData.cmbMcMake,
          vac_model: this.state.formData.txtModel,
          bint_person: this.state.formData.txtPerson,
          bint_loadcapacity: this.state.formData.txtLoadCapacity,
          dec_speed: this.state.formData.txtSpeed,
          vac_roping: this.state.formData.cmbRoping,
          bint_sheave: this.state.formData.txtSheave,
          bint_ropedia: this.state.formData.txtRopeDia,
          bint_noofrope: this.state.formData.txtNoOfRope,
          dec_amp: this.state.formData.txtAMP,
          dec_kw: this.state.formData.txtKW,
          dec_hz: this.state.formData.txtHZ,
          dec_pole: this.state.formData.txtPole,
          dec_rpm: this.state.formData.txtRPM,
          vac_gearratio: this.state.formData.txtGearRatio,
          dec_purchaseprice: this.state.formData.txtPurchasePrice,
        };
        fetchMachinePrice(reqData)
          .then((res) => {
            this.props.updateProcessing(res.data.msgType, res.data.message);
            if (res.data.msgType === "success") {
              let rows = [],
                rowsCount = rows.length;
              if (
                res.data.ListMachinePrice &&
                res.data.ListMachinePrice.length > 0
              ) {
                rows = res.data.ListMachinePrice;
                rowsCount = rows.length;
              }
              this.setState(
                {
                  rows,
                  rowsCount,
                  McMake: res.data.MachineMake,
                },
                () => {
                  this.clearInfo();
                }
              );
            }
          })
          .catch((error) => {
            console.log(
              "Unknown error occurred in Save Auto Door Main Price Master .",
              error
            );
          });
      }
    });
  };

  DeleteData = (bint_srno, vac_model) => {
    if (bint_srno > 0) {
      this.setState({ ARD: null }, () => {
        let ARD = (
          <AlertResponsiveDialog
            labelAgree="Delete"
            labelDisagree="Cancel"
            alertTitle={`Do you want to Delete Model No : ${vac_model} ?`}
            alertMessage=""
            handleOnClickYes={() => {
              return this.DeleteMachinePrice(bint_srno);
            }}
            defaultOpenDialog={true}
            onYesClickCloseIfExeSuccessfully={true}
          />
        );

        this.setState({ ARD });
      });
    } else {
      this.props.toastErrorMsg("Select a record for deletion.");
    }
  };

  DeleteMachinePrice = (bint_srno) => {
    this.props.notifyProcessing();
    const reqData = {
      Op: "DeleteMachinePrice",
      bint_srno: bint_srno,
    };
    fetchMachinePrice(reqData)
      .then((res) => {
        this.props.updateProcessing(res.data.msgType, res.data.message);
        if (res.data.msgType === "success") {
          let rows = [],
            rowsCount = rows.length;
          if (
            res.data.ListMachinePrice &&
            res.data.ListMachinePrice.length > 0
          ) {
            rows = res.data.ListMachinePrice;
            rowsCount = rows.length;
          }
          this.setState(
            {
              rows,
              rowsCount,
              ARD: null,
              McMake: res.data.MachineMake,
            },
            () => {
              this.clearInfo();
            }
          );
        }
      })
      .catch((error) => {
        this.props.updateProcessing(
          "error",
          "Unknown error occurred in delete Auto Door Main Master. " + error
        );
      });
    return false;
  };

  //#endregion function

  render() {
    return (
      <form>
        <Grid container direction="row" justifyContent="flex-end">
          <Grid item>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <CtBtn
                  className="secondary-button"
                  label="Save"
                  iconName="save"
                  onClick={this.saveMachinePrice}
                />
              </Grid>
              <Grid item>
                <CtBtn
                  label="New"
                  className="new-button"
                  iconName="new"
                  onClick={this.clearInfo}
                />
              </Grid>
              <Grid item>
                <CtBtn
                  label="Delete"
                  className="delete-button"
                  iconName="delete"
                  onClick={() => {
                    this.DeleteData(
                      this.state.formData.bint_srno,
                      this.state.formData.txtModel
                    );
                  }}
                  disabled={this.state.formData.bint_srno > 0 ? false : true}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={3}
          marginTop={0}
        >
          <Grid item>
            <CtCmb
              id="cmbMcType"
              label="MC Type*"
              items={this.state.McType}
              value={this.state.formData.cmbMcType}
              handleOnChange={this.handleOnChange}
              width={220}
              maxLength={100}
              colID="vac_mctype"
            />
          </Grid>
          <Grid item>
            <CtCmbEditable
              id="cmbMcMake"
              label="Mc Make*"
              items={this.state.McMake}
              value={this.state.formData.cmbMcMake}
              handleOnChange={this.handleOnChange}
              width={220}
              maxLength={100}
              colID="vac_machinemake"
            />
          </Grid>
          <Grid item>
            <CtTxt
              id="txtModel"
              label="Model No*"
              maxLength={100}
              width="220"
              value={this.state.formData.txtModel}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtPerson"
              label="Person*"
              maxLength={10}
              width="70"
              value={this.state.formData.txtPerson}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtLoadCapacity"
              label="Load Capacity*"
              maxLength={10}
              width="130"
              value={this.state.formData.txtLoadCapacity}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtAmt
              id="txtSpeed"
              label="Speed*"
              maxLength={10}
              width="100"
              value={this.state.formData.txtSpeed}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtCmb
              id="cmbRoping"
              label="Roping*"
              items={this.state.Roping}
              value={this.state.formData.cmbRoping}
              handleOnChange={this.handleOnChange}
              width={100}
              maxLength={100}
              colID="vac_roping"
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtSheave"
              label="Sheave*"
              maxLength={10}
              width="100"
              value={this.state.formData.txtSheave}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtRopeDia"
              label="Rope Dia*"
              maxLength={10}
              width="90"
              value={this.state.formData.txtRopeDia}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtNoOfRope"
              label="No Of Rope*"
              maxLength={10}
              width="120"
              value={this.state.formData.txtNoOfRope}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtAmt
              id="txtAMP"
              label="AMP*"
              maxLength={10}
              width="80"
              value={this.state.formData.txtAMP}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtAmt
              id="txtKW"
              label="KW*"
              maxLength={10}
              width="100"
              value={this.state.formData.txtKW}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtAmt
              id="txtHZ"
              label="HZ*"
              maxLength={10}
              width="100"
              value={this.state.formData.txtHZ}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtPole"
              label="Pole*"
              maxLength={10}
              width="100"
              value={this.state.formData.txtPole}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtRPM"
              label="RPM*"
              maxLength={10}
              width="100"
              value={this.state.formData.txtRPM}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxt
              id="txtGearRatio"
              label="Gear Ratio"
              maxLength={10}
              width="100"
              value={this.state.formData.txtGearRatio}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtAmt
              id="txtPurchasePrice"
              label="Purchase Price*"
              maxLength={10}
              width="220"
              value={this.state.formData.txtPurchasePrice}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
              RupeeSymbol={true}
            />
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          marginTop={1}
        >
          <Grid item className="maintable">
            <CtART
              rowsdata={this.state.rows}
              columnsdata={this.state.columnsdata}
              rowsPerPage={this.state.rowsPerPage}
              tblmaxHeight={500}
              csvFileName={csvFileName("Passenger Lift Cabin Price")}
              rowIconAction={[
                {
                  label: "Edit",
                  onClick: this.handleARDonActionClick_Edit,
                  icon: "Edit",
                  className: "secondary-button",
                },
                {
                  label: "Delete",
                  onClick: this.handleARDonActionClick_Delete,
                  icon: "delete",
                  className: "delete-button",
                },
              ]}
              showColumnFilters={false}
              actionColSize={90}
              density={"compact"}
              hideColIdList={["bint_srno"]}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Box display={{ xs: "none" }} style={{ textAlign: "right" }}>
            {this.state.ARD}
          </Box>
        </Grid>
      </form>
    );
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <MachinePrice {...props} navigate={navigate} />;
}
export default connect(
  mapStatetoProps,
  mapSetUserPrivileges_ToProps
)(HoCtToastContainer(HOCVerifyIsUser(withRouter(WithNavigate))));
