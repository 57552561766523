/*** Version 2.0 */

import React, { Component } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Search, Close, Add, Rowing } from "@mui/icons-material";
import {
  Tooltip,
  IconButton,
  Checkbox,
  Card,
  CardContent,
  Typography,
  Grid,
  Divider,
} from "@mui/material";
import CtCheckBox from "./CtCheckBox";
// import CtTxtAdornNum from './CtTxtAdornNum'
import CtCmb from "./CtCmb";
import CtTxt from "./CtTxt";
import CtTxtNum from "./CtTxtNum";
import CtTxtAmt from "./CtTxtAmt";
import CtDtp from "./CtDtp";
import CtPhoneNumber from "./CtPhoneNumber";
import CtMultiLineText from "./CtMultiLineText";

class CustomMobileTable extends Component {
  state = {
    page: 0,
    rows: [
      {
        ID: "",
        Scheme: "",
        ReferenceCode: "",
        SponsorId: "",
        Name: "",
        MobileNo: "",
      },
    ],
    rowsCount: 0,
    columns: [],
    searchColumns: [],
    searchOpen: false,
    searchData: {},
    provideSearch: this.props.provideSearch,
    autoEnableDisalbe_Col_Info: [],
    inputColsInfo: {
      firstColIndex: undefined,
      firstColID: undefined,
      lastColIndex: undefined,
      lastColID: undefined,
    },
    theme: {
      tableborder: { border: "0.5px solid rgb(23,124,221)" },
      cellborder: { border: "0.5px solid rgb(185,215,244)" },
    },
  };

  listResult = "Processing the List";

  handleChangeColumnsRows = () => {
    const columns = this.props.columns;
    const rows = this.props.rows;
    const rowsCount = this.props.rowsCount;
    const inputColsInfo = this.getInputColsInfo(columns);
    this.setState({ columns, rows, rowsCount, inputColsInfo });
  };

  getInputColsInfo = (columns) => {
    let inputColsInfo = {};
    columns.map((col, index) => {
      if (
        col.type &&
        (col.type === "CtTxtAmt" ||
          col.type === "CtTxtNum" ||
          col.type === "CtTxtNum" ||
          col.type === "CtDtp" ||
          col.type === "CtMultiLineText")
      ) {
        if (
          inputColsInfo.firstColID === undefined &&
          inputColsInfo.firstColIndex === undefined
        ) {
          //#To Set first col info
          inputColsInfo["firstColIndex"] = index;
          inputColsInfo["firstColID"] = col.id;
        } else {
          //#To Set last col info
          inputColsInfo["lastColIndex"] = index;
          inputColsInfo["lastColID"] = col.id;
        }
      }
    });
    if (
      inputColsInfo.lastColID === undefined &&
      inputColsInfo.lastColIndex === undefined
    ) {
      inputColsInfo.lastColID = inputColsInfo.firstColID;
      inputColsInfo.lastColIndex = inputColsInfo.lastColIndex;
    }
    return inputColsInfo;
  };

  getNextCtrlID = (rowIndex, colIndex) => {
    let nextCtrlID = undefined;
    if (
      this.state.inputColsInfo.firstColID !== undefined &&
      this.state.inputColsInfo.firstColIndex !== undefined &&
      this.state.inputColsInfo.lastColID !== undefined &&
      this.state.inputColsInfo.lastColIndex !== undefined &&
      rowIndex < this.state.rows.length - 1 &&
      Number(colIndex) === Number(this.state.inputColsInfo.lastColIndex)
    ) {
      nextCtrlID =
        this.state.inputColsInfo.firstColID +
        "#" +
        (Number(rowIndex) + 1) +
        "#" +
        this.state.inputColsInfo.firstColIndex;
    }
    return nextCtrlID;
  };

  setPage = (pageNumber) => {
    this.setState({ page: pageNumber });
  };

  setRowsPerPage = (rowsPerPage) => {
    this.setState({ rowsPerPage });
  };

  handleChangePage = (event, newPage) => {
    this.setPage(newPage);
  };

  handleChangeRowsPerPage = (event, tblIndex) => {
    this.setRowsPerPage(+event.target.value);
    this.setPage(0);
    if (this.props.handleChangeRowsPerPage) {
      this.props.handleChangeRowsPerPage(event.target.value, tblIndex);
    }
  };

  componentDidMount() {
    this.handleChangeColumnsRows();
  }

  componentWillReceiveProps(newProps) {
    this.setState(
      {
        rows: newProps.rows,
        rowsCount: newProps.rowsCount,
        searchColumns: newProps.searchColumns,
        searchData: newProps.searchData,
        provideSearch: newProps.provideSearch,
        columns: newProps.columns,
      },
      () => {
        // console.log('columns in CustomMobileTable @ ', this.state.columns)
      }
    );
  }

  handleOnChangeRowCtrlChk = (e, behaviour) => {
    if (this.props.handleOnRowDataChange) {
      const nameParts = e.target.name.split("#"),
        colID = nameParts[0],
        rowIndex = Number(nameParts[1]);
      let rows = this.state.rows.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            [colID]:
              behaviour && behaviour.toLowerCase() === "radio"
                ? true
                : e.target.checked,
          };
        } else {
          if (
            e.target.checked === true &&
            behaviour &&
            behaviour.toLowerCase() === "radio" /*  && index !== rowIndex */
          ) {
            return { ...row, [colID]: false };
          } else {
            return row;
          }
        }
      });
      this.props.handleOnRowDataChange(rows, rowIndex, colID);
    } else {
      alert("Missing props handleOnRowDataChange for CustomMobileTable");
    }
  };

  handleOnChangeRowCtrl = (e) => {
    if (this.props.handleOnRowDataChange) {
      const nameParts = e.target.name.split("#"),
        colID = nameParts[0],
        rowIndex = Number(nameParts[1]);
      let rows = this.state.rows.map((row, index) => {
        if (index === rowIndex) {
          return { ...row, [colID]: e.target.value };
        } else {
          return row;
        }
      });
      this.props.handleOnRowDataChange(rows, rowIndex, colID);
    } else {
      alert("Missing props handleOnRowDataChange for CustomMobileTable");
    }
  };

  CtrlChk = ({
    column_id,
    rowIndex,
    colIndex,
    actionIndex,
    label,
    checked,
    behaviour,
    disabled,
  }) => {
    let ctrlID = this.getCtrlID(rowIndex, colIndex, actionIndex, column_id);
    return (
      <CtCheckBox
        checkboxColor={"#177CDD"}
        label={label}
        checked={checked}
        id={ctrlID}
        handleCheckChange={(e) => this.handleOnChangeRowCtrlChk(e, behaviour)}
        disabled={disabled}
      />
    );
  };

  CtrlTxt = ({
    column_id,
    rowIndex,
    colIndex,
    label,
    value,
    width,
    maxLength,
    disabled,
  }) => {
    let ctrlID = this.getCtrlID(rowIndex, colIndex, undefined, column_id);
    // console.log('onKeyDown', this.props.onKeyDown)
    return (
      <CtTxt
        id={ctrlID}
        label={label}
        value={value}
        handleOnChange={this.handleOnChangeRowCtrl}
        width={width}
        maxLength={maxLength}
        disabled={disabled}
        onKeyDown={this.props.onKeyDown}
        nextCtrlID={this.getNextCtrlID(rowIndex, colIndex)}
      />
    );
  };

  CtrlDtp = ({
    column_id,
    rowIndex,
    colIndex,
    label,
    value,
    width,
    disabled,
  }) => {
    let ctrlID = this.getCtrlID(rowIndex, colIndex, undefined, column_id);
    return (
      <CtDtp
        id={ctrlID}
        label={label}
        value={value}
        handleOnChange={this.handleOnChangeRowCtrl}
        width={width}
        disabled={disabled}
        onKeyDown={this.props.onKeyDown}
        nextCtrlID={this.getNextCtrlID(rowIndex, colIndex)}
      />
    );
  };

  CtrlTxtNum = ({
    column_id,
    rowIndex,
    colIndex,
    label,
    value,
    width,
    maxLength,
    disabled,
  }) => {
    let ctrlID = this.getCtrlID(rowIndex, colIndex, undefined, column_id);
    return (
      <CtTxtNum
        id={ctrlID}
        label={label}
        value={value}
        handleOnChange={this.handleOnChangeRowCtrl}
        width={width}
        maxLength={maxLength}
        disabled={disabled}
        onKeyDown={this.props.onKeyDown}
        nextCtrlID={this.getNextCtrlID(rowIndex, colIndex)}
      />
    );
  };

  CtrlTxtAmt = ({
    column_id,
    rowIndex,
    colIndex,
    label,
    value,
    width,
    maxLength,
    disabled,
  }) => {
    let ctrlID = this.getCtrlID(rowIndex, colIndex, undefined, column_id);
    return (
      <CtTxtAmt
        id={ctrlID}
        label={label}
        value={value}
        handleOnChange={this.handleOnChangeRowCtrl}
        width={width}
        maxLength={maxLength}
        disabled={disabled}
        onKeyDown={this.props.onKeyDown}
        nextCtrlID={this.getNextCtrlID(rowIndex, colIndex)}
      />
    );
  };

  CtrlMultiLineText = ({
    column_id,
    rowIndex,
    colIndex,
    label,
    value,
    width,
    maxLength,
    disabled,
  }) => {
    let ctrlID = this.getCtrlID(rowIndex, colIndex, undefined, column_id);
    // console.log(`ctrlID: ${ctrlID}, rowIndex: ${rowIndex}, colIndex: ${colIndex}, firstColIndex: ${this.state.inputColsInfo.firstColIndex}, firstColID: ${this.state.inputColsInfo.firstColID}, lastColIndex: ${this.state.inputColsInfo.lastColIndex}, lastColID: ${this.state.inputColsInfo.lastColID}`)
    return (
      <CtMultiLineText
        id={ctrlID}
        label={label}
        value={value}
        handleOnChange={this.handleOnChangeRowCtrl}
        width={width}
        maxLength={maxLength}
        disabled={disabled}
        onKeyDown={this.props.onKeyDown}
        nextCtrlID={this.getNextCtrlID(rowIndex, colIndex)}
      />
    );
  };

  CtrlCmb = ({
    column_id,
    rowIndex,
    colIndex,
    label,
    items,
    colID,
    value,
    width,
    maxLength,
    disabled,
  }) => {
    // console.log("disabled", disabled);
    let ctrlID = this.getCtrlID(rowIndex, colIndex, undefined, column_id);
    return (
      <CtCmb
        id={ctrlID}
        label={label}
        items={items}
        value={value}
        handleOnChange={this.handleOnChangeRowCtrl}
        width={width}
        maxLength={maxLength}
        disabled={disabled}
        colID={colID}
        onKeyDown={this.props.onKeyDown}
        nextCtrlID={this.getNextCtrlID(rowIndex, colIndex)}
      />
    );
  };

  checkConditionOnRow = (conditions, row) => {
    let conditionFinalResult = false,
      iterationResults = [];

    conditions.forEach((curcondition) => {
      let conditionResult = false;
      if (
        curcondition.relationalOperator === undefined ||
        curcondition.relationalOperator == "=="
      ) {
        if (row[curcondition.colID] == curcondition.value)
          conditionResult = true;
      } else if (
        curcondition.relationalOperator &&
        curcondition.relationalOperator == "==="
      ) {
        if (row[curcondition.colID] === curcondition.value)
          conditionResult = true;
      } else if (
        curcondition.relationalOperator &&
        curcondition.relationalOperator == "!="
      ) {
        if (row[curcondition.colID] != curcondition.value)
          conditionResult = true;
      } else if (
        curcondition.relationalOperator &&
        curcondition.relationalOperator == "!=="
      ) {
        if (row[curcondition.colID] !== curcondition.value)
          conditionResult = true;
      } else if (
        curcondition.relationalOperator &&
        curcondition.relationalOperator == "<="
      ) {
        if (row[curcondition.colID] <= curcondition.value)
          conditionResult = true;
      } else if (
        curcondition.relationalOperator &&
        curcondition.relationalOperator == "<"
      ) {
        if (row[curcondition.colID] < curcondition.value)
          conditionResult = true;
      } else if (
        curcondition.relationalOperator &&
        curcondition.relationalOperator == ">="
      ) {
        if (row[curcondition.colID] >= curcondition.value)
          conditionResult = true;
      } else if (
        curcondition.relationalOperator &&
        curcondition.relationalOperator == ">"
      ) {
        if (row[curcondition.colID] > curcondition.value)
          conditionResult = true;
      }
      iterationResults.push({
        conditionResult,
        logicalOperator:
          curcondition.logicalOperator &&
          curcondition.logicalOperator.length > 0
            ? curcondition.logicalOperator
            : null,
      });
    });

    if (iterationResults.length === 1) {
      conditionFinalResult = iterationResults[0].conditionResult;
    } else {
      iterationResults.forEach((curResult) => {
        if (curResult.logicalOperator === null) {
          conditionFinalResult = curResult.conditionResult;
        } else {
          if (curResult.logicalOperator === "&&") {
            conditionFinalResult =
              conditionFinalResult && curResult.conditionResult;
          } else {
            conditionFinalResult =
              conditionFinalResult || curResult.conditionResult;
          }
        }
      });
    }
    return conditionFinalResult;
  };

  displayCurrentCell = (colIndex, column, value, row, rowIndex) => {
    let cellValue = "";
    if (
      colIndex === 0 &&
      this.props.actionList &&
      this.props.actionList.length > 0
    ) {
      cellValue = this.props.actionList.map((action, actionIndex) => {
        let result = null;
        if (
          (action.actionType &&
            action.actionType === "Conditional" &&
            action.visiblecondition &&
            action.visiblecondition.length > 0) ||
          (action.visiblecondition && action.visiblecondition.length > 0)
        ) {
          if (this.checkConditionOnRow(action.visiblecondition, row) === true) {
            result = this.getActionIcon(action, row);
          }
        } else if (action.hide !== undefined) {
          if (!(action.hide === true || action.hide === "true")) {
            result = this.getActionIcon(action, row);
          }
        } else if (action.actionType && action.actionType === "Chk") {
          result = this.CtrlChk({
            column_id: column.id,
            rowIndex,
            colIndex,
            actionIndex,
            label:
              action.CtrlProps && action.CtrlProps.label !== undefined
                ? action.CtrlProps.label
                : "",
            checked: row[column.id] !== undefined ? row[column.id] : false,
            behaviour:
              action.CtrlProps && action.CtrlProps.behaviour
                ? action.CtrlProps.behaviour
                : undefined,
            disabled:
              column.CtrlProps &&
              column.CtrlProps.disablecondition &&
              this.checkConditionOnRow(
                column.CtrlProps.disablecondition,
                row
              ) === true
                ? true
                : false,
          });
        } else {
          result = this.getActionIcon(action, row);
        }
        return result;
      });
      if (cellValue.length > 0) {
        cellValue = <div style={{ display: "flex" }}>{cellValue}</div>;
      }
    } else if (column.format && value) {
      // alert('before format cellValue : ' + cellValue)
      cellValue = column.format(value);
      // alert('after format cellValue : ' + cellValue)
    } else if (column.type && column.type === "Chk") {
      let visible =
        column.CtrlProps &&
        column.CtrlProps.visiblecondition &&
        this.checkConditionOnRow(column.CtrlProps.visiblecondition, row) !==
          true
          ? false
          : true;
      cellValue =
        visible === true
          ? this.CtrlChk({
              column_id: column.id,
              rowIndex,
              colIndex,
              label:
                column.CtrlProps && column.CtrlProps.label !== undefined
                  ? column.CtrlProps.label
                  : "",
              checked: row[column.id] !== undefined ? row[column.id] : false,
              behaviour:
                column.CtrlProps && column.CtrlProps.behaviour
                  ? column.CtrlProps.behaviour
                  : undefined,
              disabled:
                column.CtrlProps &&
                column.CtrlProps.disablecondition &&
                this.checkConditionOnRow(
                  column.CtrlProps.disablecondition,
                  row
                ) === true
                  ? true
                  : false,
            })
          : "";
    } else if (column.type && column.type === "CtDtp") {
      let visible =
          column.CtrlProps &&
          column.CtrlProps.visiblecondition &&
          this.checkConditionOnRow(column.CtrlProps.visiblecondition, row) !==
            true
            ? false
            : true,
        disabled =
          column.CtrlProps && column.CtrlProps.disabled !== undefined
            ? column.CtrlProps.disabled
            : column.CtrlProps &&
              column.CtrlProps.disablecondition &&
              this.checkConditionOnRow(
                column.CtrlProps.disablecondition,
                row
              ) === true
            ? true
            : false;
      cellValue =
        visible === true
          ? this.CtrlDtp({
              column_id: column.id,
              rowIndex,
              colIndex,
              label:
                column.CtrlProps && column.CtrlProps.label !== undefined
                  ? column.CtrlProps.label
                  : "",
              value:
                row[column.id] !== undefined
                  ? row[column.id]
                  : column.CtrlProps && column.CtrlProps.defValue !== undefined
                  ? column.CtrlProps.defValue
                  : "",
              width:
                column.CtrlProps && column.CtrlProps.width !== undefined
                  ? column.CtrlProps.width
                  : 120,
              disabled,
            })
          : "";
    } else if (column.type && column.type === "CtTxt") {
      let visible =
          column.CtrlProps &&
          column.CtrlProps.visiblecondition &&
          this.checkConditionOnRow(column.CtrlProps.visiblecondition, row) !==
            true
            ? false
            : true,
        disabled =
          column.CtrlProps && column.CtrlProps.disabled !== undefined
            ? column.CtrlProps.disabled
            : column.CtrlProps &&
              column.CtrlProps.disablecondition &&
              this.checkConditionOnRow(
                column.CtrlProps.disablecondition,
                row
              ) === true
            ? true
            : false;
      cellValue =
        visible === true
          ? this.CtrlTxt({
              column_id: column.id,
              rowIndex,
              colIndex,
              label:
                column.CtrlProps && column.CtrlProps.label !== undefined
                  ? column.CtrlProps.label
                  : "",
              value:
                row[column.id] !== undefined
                  ? row[column.id]
                  : column.CtrlProps && column.CtrlProps.defValue !== undefined
                  ? column.CtrlProps.defValue
                  : "",
              width:
                column.CtrlProps && column.CtrlProps.width !== undefined
                  ? column.CtrlProps.width
                  : 50,
              maxLength:
                column.CtrlProps && column.CtrlProps.maxLength !== undefined
                  ? column.CtrlProps.maxLength
                  : 1,
              disabled,
            })
          : "";
    } else if (column.type && column.type === "CtTxtAmt") {
      let visible =
          column.CtrlProps &&
          column.CtrlProps.visiblecondition &&
          this.checkConditionOnRow(column.CtrlProps.visiblecondition, row) !==
            true
            ? false
            : true,
        disabled =
          column.CtrlProps && column.CtrlProps.disabled !== undefined
            ? column.CtrlProps.disabled
            : column.CtrlProps &&
              column.CtrlProps.disablecondition &&
              this.checkConditionOnRow(
                column.CtrlProps.disablecondition,
                row
              ) === true
            ? true
            : false;
      cellValue =
        visible === true
          ? this.CtrlTxtAmt({
              column_id: column.id,
              rowIndex,
              colIndex,
              label:
                column.CtrlProps && column.CtrlProps.label !== undefined
                  ? column.CtrlProps.label
                  : "",
              value:
                row[column.id] !== undefined
                  ? row[column.id]
                  : column.CtrlProps && column.CtrlProps.defValue !== undefined
                  ? column.CtrlProps.defValue
                  : "",
              width:
                column.CtrlProps && column.CtrlProps.width !== undefined
                  ? column.CtrlProps.width
                  : 50,
              maxLength:
                column.CtrlProps && column.CtrlProps.maxLength !== undefined
                  ? column.CtrlProps.maxLength
                  : 1,
              disabled,
            })
          : "";
    } else if (column.type && column.type === "CtTxtNum") {
      let visible =
          column.CtrlProps &&
          column.CtrlProps.visiblecondition &&
          this.checkConditionOnRow(column.CtrlProps.visiblecondition, row) !==
            true
            ? false
            : true,
        disabled =
          column.CtrlProps && column.CtrlProps.disabled !== undefined
            ? column.CtrlProps.disabled
            : column.CtrlProps &&
              column.CtrlProps.disablecondition &&
              this.checkConditionOnRow(
                column.CtrlProps.disablecondition,
                row
              ) === true
            ? true
            : false;
      cellValue =
        visible === true
          ? this.CtrlTxtNum({
              column_id: column.id,
              rowIndex,
              colIndex,
              label:
                column.CtrlProps && column.CtrlProps.label !== undefined
                  ? column.CtrlProps.label
                  : "",
              value:
                row[column.id] !== undefined
                  ? row[column.id]
                  : column.CtrlProps && column.CtrlProps.defValue !== undefined
                  ? column.CtrlProps.defValue
                  : "",
              width:
                column.CtrlProps && column.CtrlProps.width !== undefined
                  ? column.CtrlProps.width
                  : 50,
              maxLength:
                column.CtrlProps && column.CtrlProps.maxLength !== undefined
                  ? column.CtrlProps.maxLength
                  : 1,
              disabled,
            })
          : "";
    } else if (column.type && column.type === "CtMultiLineText") {
      let visible =
          column.CtrlProps &&
          column.CtrlProps.visiblecondition &&
          this.checkConditionOnRow(column.CtrlProps.visiblecondition, row) !==
            true
            ? false
            : true,
        disabled =
          column.CtrlProps && column.CtrlProps.disabled !== undefined
            ? column.CtrlProps.disabled
            : column.CtrlProps &&
              column.CtrlProps.disablecondition &&
              this.checkConditionOnRow(
                column.CtrlProps.disablecondition,
                row
              ) === true
            ? true
            : false;
      cellValue =
        visible === true
          ? this.CtrlMultiLineText({
              column_id: column.id,
              rowIndex,
              colIndex,
              label:
                column.CtrlProps && column.CtrlProps.label !== undefined
                  ? column.CtrlProps.label
                  : "",
              value:
                row[column.id] !== undefined
                  ? row[column.id]
                  : column.CtrlProps && column.CtrlProps.defValue !== undefined
                  ? column.CtrlProps.defValue
                  : "",
              width:
                column.CtrlProps && column.CtrlProps.width !== undefined
                  ? column.CtrlProps.width
                  : 100,
              maxLength:
                column.CtrlProps && column.CtrlProps.maxLength !== undefined
                  ? column.CtrlProps.maxLength
                  : 1,
              disabled,
            })
          : "";
    } else if (column.type && column.type === "CtCmb") {
      let items = [],
        visible =
          column.CtrlProps &&
          column.CtrlProps.visiblecondition &&
          this.checkConditionOnRow(column.CtrlProps.visiblecondition, row) !==
            true
            ? false
            : true,
        disabled =
          column.CtrlProps && column.CtrlProps.disabled !== undefined
            ? column.CtrlProps.disabled
            : column.CtrlProps &&
              column.CtrlProps.disablecondition &&
              this.checkConditionOnRow(
                column.CtrlProps.disablecondition,
                row
              ) === true
            ? true
            : false;
      if (
        column.CtrlProps.rowItemsColID &&
        row[column.CtrlProps.rowItemsColID]
      ) {
        items = row[column.CtrlProps.rowItemsColID];
      } else if (column.CtrlProps.items) {
        items = column.CtrlProps.items;
      }
      cellValue =
        visible === true
          ? this.CtrlCmb({
              column_id: column.id,
              rowIndex,
              colIndex,
              label:
                column.CtrlProps && column.CtrlProps.label !== undefined
                  ? column.CtrlProps.label
                  : "",
              items,
              colID: column.CtrlProps.colID,
              value:
                row[column.id] !== undefined
                  ? row[column.id]
                  : column.CtrlProps && column.CtrlProps.defValue !== undefined
                  ? column.CtrlProps.defValue
                  : "",
              width:
                column.CtrlProps && column.CtrlProps.width !== undefined
                  ? column.CtrlProps.width
                  : 50,
              maxLength:
                column.CtrlProps && column.CtrlProps.maxLength !== undefined
                  ? column.CtrlProps.maxLength
                  : 1,
              disabled,
            })
          : "";
    } else {
      cellValue = value;
    }
    return cellValue;
  };

  add_autoEnableDisalbe_ColIdxs = (CtrlProps, colIndex) => {
    if (
      CtrlProps &&
      CtrlProps.autoEnableDisable &&
      !(
        this.state.autoEnableDisalbe_Col_Info.length > 0 &&
        this.state.autoEnableDisalbe_Col_Info.find(
          (col) => col.index === colIndex
        )
      )
    ) {
      if (this.props.handleOnChangeAutoEnableDisalbe_ColIdxs) {
        const autoEnableDisalbe_Col_Info =
          this.state.autoEnableDisalbe_Col_Info;
        const blankIfDisabled = CtrlProps.blankIfDisabled;
        autoEnableDisalbe_Col_Info.push({ index: colIndex, blankIfDisabled });
        this.setState({ autoEnableDisalbe_Col_Info }, () => {
          this.props.handleOnChangeAutoEnableDisalbe_ColIdxs(
            this.state.autoEnableDisalbe_Col_Info
          );
        });
      } else {
        alert(
          "Remain to set handleOnChangeAutoEnableDisalbe_ColIdxs for CustomMobileTable"
        );
      }
    }
  };

  getCtrlID = (rowIndex, colIndex, actionIndex, colID) => {
    return (
      (colID !== undefined && colID.length > 0 ? colID + "#" : "") +
      rowIndex.toString() +
      "#" +
      colIndex.toString() +
      (actionIndex !== undefined ? "#" + actionIndex.toString() : "")
    );
  };

  checkCondition = (action, row) => {
    let result = false;
    if (
      (action.actionType && action.actionType === "Conditional") ||
      (action.condition && action.condition.length > 0)
    ) {
      let conditionFinalResult = false,
        iterationResults = [];

      action.condition.forEach((curcondition) => {
        let conditionResult = false;
        if (
          curcondition.relationalOperator === undefined ||
          curcondition.relationalOperator == "=="
        ) {
          if (row[curcondition.colID] == curcondition.value)
            conditionResult = true;
        } else if (
          curcondition.relationalOperator &&
          curcondition.relationalOperator == "==="
        ) {
          if (row[curcondition.colID] === curcondition.value)
            conditionResult = true;
        } else if (
          curcondition.relationalOperator &&
          curcondition.relationalOperator == "!="
        ) {
          if (row[curcondition.colID] != curcondition.value)
            conditionResult = true;
        } else if (
          curcondition.relationalOperator &&
          curcondition.relationalOperator == "!=="
        ) {
          if (row[curcondition.colID] !== curcondition.value)
            conditionResult = true;
        } else if (
          curcondition.relationalOperator &&
          curcondition.relationalOperator == "<="
        ) {
          if (row[curcondition.colID] <= curcondition.value)
            conditionResult = true;
        } else if (
          curcondition.relationalOperator &&
          curcondition.relationalOperator == "<"
        ) {
          if (row[curcondition.colID] < curcondition.value)
            conditionResult = true;
        } else if (
          curcondition.relationalOperator &&
          curcondition.relationalOperator == ">="
        ) {
          if (row[curcondition.colID] >= curcondition.value)
            conditionResult = true;
        } else if (
          curcondition.relationalOperator &&
          curcondition.relationalOperator == ">"
        ) {
          if (row[curcondition.colID] > curcondition.value)
            conditionResult = true;
        }
        iterationResults.push({
          conditionResult,
          logicalOperator:
            curcondition.logicalOperator &&
            curcondition.logicalOperator.length > 0
              ? curcondition.logicalOperator
              : null,
        });
      });

      if (iterationResults.length === 1) {
        conditionFinalResult = iterationResults[0].conditionResult;
      } else {
        iterationResults.forEach((curResult) => {
          if (curResult.logicalOperator === null) {
            conditionFinalResult = curResult.conditionResult;
          } else {
            if (curResult.logicalOperator === "&&") {
              conditionFinalResult =
                conditionFinalResult && curResult.conditionResult;
            } else {
              conditionFinalResult =
                conditionFinalResult || curResult.conditionResult;
            }
          }
        });
      }

      if (conditionFinalResult === true) {
        result = true;
      } else {
        result = false;
      }
    } else {
      result = true;
    }
    return result;
  };

  getActionIcon = (action, row) => {
    const actionIcon = (
      <Tooltip title={action.name}>
        <IconButton
          style={{ padding: "0px", margin: "0px 5px" }}
          onClick={() => {
            this.props.handleOnActionClick({
              ...row,
              actionName: action.name,
              action,
            });
          }}
        >
          {action.icon}
        </IconButton>
      </Tooltip>
    );
    return actionIcon;
  };

  onSearchClick = () => {
    this.setState(
      {
        searchOpen: !this.state.searchOpen,
      },
      () => {
        this.props.handleOnSearchChange(
          this.state.searchOpen,
          this.state.searchData
        );
      }
    );
  };

  //   render() {
  //     const {
  //       classes,
  //       width,
  //       height,
  //       maxHeight,
  //       minHeight,
  //       rowsPerPage,
  //       dataRowBgColor,
  //       dataRowBgColorCondition,
  //       cellPadding,
  //     } = this.props;
  //     const fullWidth = { width: "100%" };
  //     const fixWidthHeight = { width: width + "px", height: height + "px" };
  //     const defaultMaxMinHeight = { maxHeight: "440px", minHeight: "440px" };
  //     const maxMinHeight = {
  //       maxHeight: maxHeight + "px",
  //       minHeight: minHeight + "px",
  //     };

  //     let rowStyle = {};
  //     let cellPaddingValue = cellPadding !== undefined ? cellPadding : "4px";

  //     const tableheadingBgColor = "A2CAF1"; // '#bfc7dc'// '#b3bcd5'//'#a6b1ce'
  //     return (
  //       <Paper
  //         style={
  //           width && width > 0 && height && height > 0
  //             ? { fixWidthHeight }
  //             : { fullWidth }
  //         }
  //       >
  //         <TableContainer
  //           style={
  //             minHeight && maxHeight && minHeight > 0 && maxHeight > 0
  //               ? { maxMinHeight }
  //               : { defaultMaxMinHeight }
  //           }
  //         >
  //           <Table
  //             stickyHeader
  //             aria-label="sticky table"
  //             sx={this.state.theme.tableborder}
  //           >
  //             <TableHead>
  //               <TableRow>
  //                 {this.props.columns.map((column, index) => (
  //                   <TableCell
  //                     key={column.id}
  //                     align={column.align}
  //                     //Red Shade ED1C24, f44336, ef9a9a, ff8a80
  //                     //Violage Shade 8556A6
  //                     style={
  //                       column.hide == true || column.hide == "true"
  //                         ? {
  //                             display: "none",
  //                             minWidth: column.minWidth,
  //                             width: column.width,
  //                             backgroundColor: tableheadingBgColor,
  //                             padding: cellPaddingValue,
  //                             fontWeight: "bold",
  //                           }
  //                         : {
  //                             minWidth: column.minWidth,
  //                             width: column.width,
  //                             backgroundColor: tableheadingBgColor,
  //                             padding: cellPaddingValue,
  //                             fontWeight: "bold",
  //                           }
  //                     }
  //                     sx={this.state.theme.cellborder}
  //                     // { minWidth: column.minWidth, backgroundColor: '#ff8a80' },
  //                   >
  //                     {index === 0 && this.props.handleOnNewEntryClick ? (
  //                       <Tooltip title={this.props.toolTipNewEntry}>
  //                         <IconButton
  //                           onClick={this.props.handleOnNewEntryClick}
  //                           style={{ padding: "0px", margin: "0px 5px" }}
  //                         >
  //                           <Add />
  //                         </IconButton>
  //                       </Tooltip>
  //                     ) : (
  //                       ""
  //                     )}
  //                     {index === 0 &&
  //                     this.state.provideSearch &&
  //                     this.state.provideSearch === true ? (
  //                       this.state.searchOpen === true ? (
  //                         <Tooltip title={"Close Search"}>
  //                           <IconButton
  //                             onClick={this.onSearchClick}
  //                             style={{ padding: "0px", margin: "0px 5px" }}
  //                           >
  //                             <Close />
  //                           </IconButton>
  //                         </Tooltip>
  //                       ) : (
  //                         <Tooltip title={"Search"}>
  //                           <IconButton
  //                             onClick={this.onSearchClick}
  //                             style={{ padding: "0px", margin: "0px 5px" }}
  //                           >
  //                             <Search />
  //                           </IconButton>
  //                         </Tooltip>
  //                       )
  //                     ) : index > 0 &&
  //                       this.state.searchOpen === true &&
  //                       this.state.provideSearch &&
  //                       this.state.provideSearch === true ? (
  //                       this.state.searchColumns.map((col, scIndex) => {
  //                         if (col.cType === "CtCmb" && index === scIndex + 1) {
  //                         }
  //                         return col.cType === "CtTxtAdornNum" &&
  //                           index === scIndex + 1 ? (
  //                           <span />
  //                         ) : col.cType === "CtCmb" && index === scIndex + 1 ? (
  //                           <CtCmb
  //                             id={col.id}
  //                             label={col.label}
  //                             items={col.items}
  //                             value={this.state.searchData[col.id]}
  //                             handleOnChange={col.handleOnChange}
  //                             width={col.width}
  //                             colID={col.colID}
  //                           />
  //                         ) : col.cType === "CtTxt" && index === scIndex + 1 ? (
  //                           <CtTxt
  //                             id={col.id}
  //                             label={col.label}
  //                             value={this.state.searchData[col.id]}
  //                             handleOnChange={col.handleOnChange}
  //                             width={col.width}
  //                             maxLength={col.maxLength}
  //                           />
  //                         ) : col.cType === "CtPhoneNumber" &&
  //                           index === scIndex + 1 ? (
  //                           <span />
  //                         ) : col.cType === "Lbl" && index === scIndex + 1 ? (
  //                           col.label
  //                         ) : (
  //                           ""
  //                         );
  //                       })
  //                     ) : (
  //                       column.label
  //                     )}
  //                     {column.CtrlProps &&
  //                     column.CtrlProps.autoEnableDisable &&
  //                     !(
  //                       this.state.autoEnableDisalbe_Col_Info.length > 0 &&
  //                       this.state.autoEnableDisalbe_Col_Info.find(
  //                         (col) => col.index === index
  //                       )
  //                     )
  //                       ? this.add_autoEnableDisalbe_ColIdxs(
  //                           column.CtrlProps,
  //                           index
  //                         )
  //                       : ""}
  //                   </TableCell>
  //                 ))}
  //               </TableRow>
  //             </TableHead>
  //             <TableBody>
  //               {this.state.rows
  //                 ? this.state.rows
  //                     .slice(
  //                       this.state.page * rowsPerPage,
  //                       this.state.page * rowsPerPage + rowsPerPage
  //                     )
  //                     .map((row, rowIndex) => {
  //                       if (dataRowBgColor && dataRowBgColor.length > 0) {
  //                         let applyBgColor = false;
  //                         rowStyle = {};

  //                         if (
  //                           dataRowBgColorCondition
  //                           //&& dataRowBgColorCondition.length > 0
  //                         ) {
  //                           if (
  //                             this.checkCondition(dataRowBgColorCondition, row)
  //                           ) {
  //                             applyBgColor = true;
  //                           } else {
  //                             applyBgColor = false;
  //                           }
  //                         } else {
  //                           applyBgColor = true;
  //                         }

  //                         if (applyBgColor === true) {
  //                           rowStyle.backgroundColor = dataRowBgColor;
  //                         }
  //                       }

  //                       return (
  //                         <TableRow
  //                           hover
  //                           role="checkbox"
  //                           tabIndex={-1}
  //                           key={row.ID}
  //                           style={rowStyle}
  //                         >
  //                           {this.props.columns.map((column, index) => {
  //                             let value = row[column.id],
  //                               dynamicRowDataID;

  //                             if (
  //                               column.dynamicRowDataID &&
  //                               column.dynamicRowDataID.length > 0
  //                             ) {
  //                               if (row.dynamicRowDataID) {
  //                                 value =
  //                                   row.dynamicRowDataID[column.dynamicRowDataID];
  //                               } else {
  //                                 value = "";
  //                               }
  //                             }

  //                             return (
  //                               <TableCell
  //                                 key={column.id}
  //                                 align={column.align}
  //                                 sx={this.state.theme.cellborder}
  //                                 style={
  //                                   column.hide == true || column.hide == "true"
  //                                     ? {
  //                                         display: "none",
  //                                         padding: cellPaddingValue,
  //                                         backgroundColor: "",
  //                                       }
  //                                     : {
  //                                         padding: cellPaddingValue,
  //                                         backgroundColor: "",
  //                                       }
  //                                 }
  //                               >
  //                                 {this.displayCurrentCell(
  //                                   index,
  //                                   column,
  //                                   value,
  //                                   row,
  //                                   rowIndex
  //                                 )}
  //                               </TableCell>
  //                             );
  //                           })}
  //                         </TableRow>
  //                       );
  //                     })
  //                 : ""}
  //             </TableBody>
  //           </Table>
  //         </TableContainer>
  //         <TablePagination
  //           rowsPerPageOptions={[5, 10, 25, 50, 100]}
  //           component="div"
  //           count={this.state.rowsCount}
  //           rowsPerPage={rowsPerPage}
  //           page={this.state.page}
  //           onPageChange={this.handleChangePage}
  //           onRowsPerPageChange={(e, tblIndex) => {
  //             this.handleChangeRowsPerPage(e, tblIndex);
  //           }}
  //         />
  //       </Paper>
  //     );
  //   }

  render() {
    const {
      classes,
      width,
      height,
      maxHeight,
      minHeight,
      rowsPerPage,
      dataRowBgColor,
      dataRowBgColorCondition,
      cellPadding,
    } = this.props;

    let rowStyle = {};
    let cellPaddingValue = cellPadding !== undefined ? cellPadding : "4px";

    const tableheadingBgColor = "#A2CAF1"; // Using hex color with #

    return (
      //   <Paper style={{ width: "100%" }}>
      //   <div
      //     style={{
      //       maxHeight: maxHeight ? maxHeight + "px" : "440px",
      //       overflow: "auto",
      //     }}
      //   >
      <>
        {this.state.rows
          ? this.state.rows
              .slice(
                this.state.page * rowsPerPage,
                this.state.page * rowsPerPage + rowsPerPage
              )
              .map((row, rowIndex) => {
                if (dataRowBgColor && dataRowBgColor.length > 0) {
                  let applyBgColor = false;
                  rowStyle = {};

                  if (dataRowBgColorCondition) {
                    if (this.checkCondition(dataRowBgColorCondition, row)) {
                      applyBgColor = true;
                    } else {
                      applyBgColor = false;
                    }
                  } else {
                    applyBgColor = true;
                  }

                  if (applyBgColor === true) {
                    rowStyle.backgroundColor = dataRowBgColor;
                  }
                }

                return (
                  <Card
                    key={row.ID}
                    variant="outlined"
                    style={{
                      padding: "10px",
                      width: "70vw",
                      backgroundColor: "#F1F2FF",
                      marginTop: "10px",
                    }}
                  >
                    <Grid container>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <table>
                          {this.props.columns.map((column, index) => {
                            let value = row[column.id];
                            if (
                              column.dynamicRowDataID &&
                              column.dynamicRowDataID.length > 0
                            ) {
                              if (row.dynamicRowDataID) {
                                value =
                                  row.dynamicRowDataID[column.dynamicRowDataID];
                              } else {
                                value = "";
                              }
                            }

                            return (
                              <tr style={{ backgroundColor: "white" }}>
                                <td
                                  style={{
                                    padding: "10px",
                                    border: "1px solid #6e6e6e",
                                  }}
                                >
                                  <b> {column.label}</b>
                                </td>
                                <td
                                  style={{
                                    padding: "8px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    border: "1px solid #6e6e6e",
                                    width: "100%",
                                  }}
                                  colSpan={2}
                                >
                                  {this.displayCurrentCell(
                                    index,
                                    column,
                                    value,
                                    row,
                                    rowIndex
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </table>
                      </Grid>
                    </Grid>
                  </Card>
                );
              })
          : ""}
      </>
      //   </div>

      //   </Paper>
    );
  }
}

export default CustomMobileTable;

/*
Library Notes:

CtrlProps in ActionList:
While action contains inputs, so, don't forget to set CtrlProps with default value of input control. 
If you don't provide default value in CtrlProps, then all row will not contains key='RowIndex'+'ColumnIndex' with default value

*** column.type === "CtCmb" ***
 label=<CtrlProps.label>, 
 items=<{CtrlProps.rowItemsColID}||{CtrlProps.items}>, 
 colID=<CtrlProps.colID>, 
 value={if row[column.id] then row[column.id] else if <CtrlProps.defValue> then CtrlProps.defValue else ""}, 
 width={if <CtrlProps.width> then CtrlProps.width else 50}, 
 maxLength={if <CtrlProps.maxLength> then CtrlProps.maxLength else 1}, 
 disabled={if CtrlProps.disabled!== undefined then CtrlProps.disabled else false}
*/
