import React, { Component } from "react";

class JDS extends Component {
  componentDidMount() {
    document.title = "Top India Elevator : JDS";
  }
  render() {
    return <div>JDS</div>;
  }
}

export default JDS;
