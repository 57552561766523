import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import { useAppStore } from "../appStore";
import { useEffect } from "react";
import { useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import { Button, Grid } from "@mui/material";
import { Person } from "@mui/icons-material";
import Profile from "./Profile";

// import "./theam.css";

const AppBar = styled(
  MuiAppBar,
  {}
)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export default function Navbar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [helpButtonAnchor, setHelpButtonAnchor] = useState(null);
  const [open, setOpen] = useState(false);

  const updateOpen = useAppStore((state) => state.updateOpen);
  const dopen = useAppStore((state) => state.dopen);

  const [heading, setHeading] = useState(localStorage.getItem("Heading"));

  useEffect(() => {
    setHeading(localStorage.getItem("Heading"));
    document.title = heading + " : Top India Elevator";
  });

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleHelpClick = (event) => {
    setHelpButtonAnchor(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setHelpButtonAnchor(null);
    setOpen(false);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      style={{ marginTop: "35px" }}
    >
      <MenuItem onClick={handleMenuClose}>
        {localStorage.getItem("vac_userid")}
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      id={mobileMenuId}
      keepMounted
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <Profile />
    </Menu>
  );

  let ShortCutBtnStyle = {
    background: "#1ba9dd",
    color: "white",
    // border: "1px solid black",
    padding: "5px 8px",
    borderRadius: "6px",
  };
  let ShortCutStyle = {
    background: "#82cec9",
    color: "black",
    // border: "1px solid black",
    padding: "3px 8px",
    fontSize: "24px",
    borderRadius: "6px",
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" elevation={0} className="primary">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
            onClick={() => updateOpen(!dopen)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            {heading}
          </Typography>

          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <IconButton size="large" color="inherit" onClick={handleHelpClick}>
              <InfoIcon />
            </IconButton>
            <Menu
              anchorEl={helpButtonAnchor}
              id="account-menu"
              open={open}
              onClose={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  // your styling here
                },
              }}
              transformOrigin={{ horizontal: "center", vertical: "top" }}
              anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
            >
              <Grid
                container
                justifyContent={"flex-start"}
                direction={"column"}
                spacing={2}
                padding={1}
                style={{ backgroundColor: "#f2f2f2" }}
              >
                <Grid item>
                  <div>
                    <Typography fontSize={18} fontWeight={500}>
                      For Save :-{" "}
                      <span style={{ fontWeight: 600 }}>
                        <span style={ShortCutBtnStyle}>Alt</span> +{" "}
                        <span style={ShortCutStyle}>s</span>
                      </span>
                    </Typography>
                  </div>
                </Grid>
                <Grid item>
                  <div>
                    <Typography fontSize={18} fontWeight={500}>
                      For New :-{" "}
                      <span style={{ fontWeight: 600 }}>
                        <span style={ShortCutBtnStyle}>Alt</span> +{" "}
                        <span style={ShortCutStyle}>n</span>
                      </span>
                    </Typography>
                  </div>
                </Grid>
                <Grid item>
                  <div>
                    <Typography fontSize={18} fontWeight={500}>
                      For Delete :-{" "}
                      <span style={{ fontWeight: 600 }}>
                        <span style={ShortCutBtnStyle}>Alt</span> +{" "}
                        <span style={ShortCutStyle}>d</span>
                      </span>
                    </Typography>
                  </div>
                </Grid>
                <Grid item>
                  <div>
                    <Typography fontSize={18} fontWeight={500}>
                      For Print :-{" "}
                      <span style={{ fontWeight: 600 }}>
                        <span style={ShortCutBtnStyle}>Alt</span> +{" "}
                        <span style={ShortCutStyle}>p</span>
                      </span>
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Menu>
            <Profile />
          </Box>

          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}
