import axios, * as others from "axios";

// export const apiURL =
//   "https://ravitechworld.in/LiveTesting/TopIndiaElevatorERP/BackEndApi/"; //Testing API

// export const apiURL = "http://localhost:7001/"; //Loacal API

// export const apiAngularURL =
//   "http://127.0.0.1/Development/Top-India/other_url_testing/"; //Loacal OTHER API

// "http://127.0.0.1/Development/TopIndiaElevator/TOP_ERP/JSD/setsession_fromreact.php?SessionUserID=admin&Page=JSDEntry",

// export const apiAngularURLProposal =
//   "http://127.0.0.1/Development/Top-India/topindiaelevator.in-proposal/Local/topindiaelevatorproposal/setsession_fromreact.php?SessionUserID=admin&Page=JSDEntry"; //Loacal OT
// HER API

// export const apiAngularURLProposal = `http://127.0.0.1/Development/Top-India/php/top-india-proposal-jsd-packing/setsession_fromreact.php?SessionUserID=${localStorage.getItem(
//   "vac_userid"
// )}&`; //Loacal OTHER API

// export const apiAngularURLProposal = "https://topindiaelevator.in/tiepl/"; //tiepl

// export const apiAngularURLProposal = `https://ravitechworld.in/tiepl/setsession_fromreact.php?SessionUserID=${localStorage.getItem(
//   "vac_userid"
// )}&`; //tiepl Live AWS

export const apiAngularURLProposal = `https://topindiaelevator.in/tiepl/setsession_fromreact.php?SessionUserID=${localStorage.getItem(
  "vac_userid"
)}&`; //tiepl Live Topindiaelevator.in AWS

// export const apiURL = "http://13.49.106.142:7001/"; //Livetesting

// export const apiURL = "http://13.49.106.142/api/"; //Livetesting with api

// export const apiURL = "http://ravitechworld.in:7001/"; //Livetesting with ravitechword.in

// export const apiURL = "https://ravitechworld.in/api/"; //Livetesting with ravitechword.in
export const apiURL = "https://topindiaelevator.in/api/"; //Livetesting with topindiaelevator.in
// const axios = require("axios");
const axiosInstance = axios.create({
  baseURL: apiURL,
});

export const loginPath = "./";

// export function fetchproductdetails(data) {
//   return axiosInstance.post("productdetail.php", data);
// }
// export function fetchdistributordetails(data) {
//   return axiosInstance.post("distributordetail.php", data);
// }
// export function fetchUser(data) {
//   return axiosInstance.post("userdetail.php", data);
// }

// export function imageFrontEndBaseURL() {
//   return apiURL;
// }

// export function imageBackEndBaseURL() {
//   return "images/";
// }

// export function removeImage(formData) {
//   return axiosInstance.post("ImageUpload.php", formData);
// }

// export function imageUpload(formData, config) {
//   return axiosInstance.post("ImageUpload.php", formData, config);
// }
// // export function fetchCompany(data) {
// //   return axiosInstance.post("companydetail.php", data);
// // }

// export function fetchUser(data) {
//   return axiosInstance.post("userdetail.php", data);
// }

export function fetchLogin(data) {
  return axiosInstance.post("Login", data);
}

export function fetchUserManagement(data) {
  return axiosInstance.post("UserManagement", data);
}

export function fetchProposal(data) {
  return axiosInstance.post("Proposal", data);
}

export function fetchpricecalculation(data) {
  return axiosInstance.post("PriceCalculation", data);
}

export function fetchrailprice(data) {
  return axiosInstance.post("railprice", data);
}

export function fetchgeneralitemprice(data) {
  return axiosInstance.post("generalitemprice", data);
}

export function fetchcombinationbracketprice(data) {
  return axiosInstance.post("combinationbracketprice", data);
}

export function fetchcarinside(data) {
  return axiosInstance.post("carinside", data);
}

export function fetchrawmaterialandprocess(data) {
  return axiosInstance.post("rawmaterialandprocess", data);
}

export function fetchRailSelection(data) {
  return axiosInstance.post("RailSelection", data);
}

export function fetchPriceOfBracket(data) {
  return axiosInstance.post("PriceOfBracket", data);
}

export function fetchManualDoorFramePrice(data) {
  return axiosInstance.post("manualdoorframeprice", data);
}

export function fetchAutoDoorMainPrice(data) {
  return axiosInstance.post("autodoormainprice", data);
}

export function fetchMachinePrice(data) {
  return axiosInstance.post("machineprice", data);
}

export function fetchManualDoorPrice(data) {
  return axiosInstance.post("manualdoorprice", data);
}

export function fetchDoorBrand(data) {
  return axiosInstance.post("doorbrand", data);
}
export function fetchDrivePrice(data) {
  return axiosInstance.post("drivePricing", data);
}
export function fetchDriveName(data) {
  return axiosInstance.post("drivename", data);
}

export function fetchDoorMaterialGrade(data) {
  return axiosInstance.post("doormaterialgrade", data);
}

export function fetchPassengerCabin(data) {
  return axiosInstance.post("passengercabin", data);
}

export function fetchGoodsCabin(data) {
  return axiosInstance.post("goodscabin", data);
}

export function fetchArdPrice(data) {
  return axiosInstance.post("ARDprice", data);
}

export function fetchRopePrice(data) {
  return axiosInstance.post("ropeprice", data);
}

export function fetchComChainPrice(data) {
  return axiosInstance.post("comchainprice", data);
}

export function fetchPulleyPrice(data) {
  return axiosInstance.post("pulleyprice", data);
}

export function fetchPulleyDiameterSelection(data) {
  return axiosInstance.post("pulleydiameterselection", data);
}

export function fetchCopPrice(data) {
  return axiosInstance.post("copprice", data);
}

export function fetchLopPrice(data) {
  return axiosInstance.post("lopprice", data);
}

export function fetchCarAndCwFrame(data) {
  return axiosInstance.post("carandcwframe", data);
}

export function fetchCounterWeightKg(data) {
  return axiosInstance.post("counterweightkg", data);
}

export function fetchDashboard(data) {
  return axiosInstance.post("Dashboard", data);
}

export function fetchImportExcel(data) {
  return axiosInstance.post("ImportExport", data);
}
