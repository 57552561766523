import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import PasswordOutlined from "@mui/icons-material/PasswordOutlined";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Grid } from "@mui/material";
// import NewPassword from "./NewPassword";
import { Person } from "@mui/icons-material";
import CurrentPassword from "./CurrentPassword";
// import NewPassword from "./Pages/NewPassword";

export default function Profile() {
  const [ARD, setARD] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const openChangePassword = () => {
    let dialogContent = (
      <AlertResponsiveDialog
        alertMessage={<CurrentPassword nullARD={ARDNull} />}
        defaultOpenDialog={true}
        onYesClickCloseIfExeSuccessfully={true}
      />
    );
    setARD(dialogContent);
  };

  const ARDNull = () => {
    setARD(null);
  };
  const openDialog = () => {
    let dialogContent = (
      <AlertResponsiveDialog
        type="Delete"
        labelAgree="Logout"
        labelDisagree="Cancel"
        alertHeading="Logout Confirmation"
        alertTitle={"Do you want to Logout:?"}
        alertIcon="logout"
        alertMessage=""
        handleOnClickYes={() => {
          navigate("/");
          setARD(null);
          setAnchorEl(null);
        }}
        handleOnClickNo={() => {
          setARD(null);
          setAnchorEl(null);
        }}
        defaultOpenDialog={true}
        onYesClickCloseIfExeSuccessfully={true}
      />
    );

    setARD(dialogContent);
  };
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 0.5, mb: 0.5 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Button
              style={{
                color: "#3179ff",
                backgroundColor: "white",
                width: "auto",
                borderRadius: "10px",
                fontWeight: "500",
                fontSize: "15px",
                marginTop: "4px",
              }}
            >
              <Person /> &nbsp;
              {localStorage.getItem("vac_userid")}
            </Button>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={openChangePassword}>
          <ListItemIcon>
            <PasswordOutlined fontSize="small" />
          </ListItemIcon>
          Change Password
        </MenuItem>
        <MenuItem onClick={openDialog}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
      <Grid item>
        <Box display={{ xs: "none" }} style={{ textAlign: "right" }}>
          {ARD}
        </Box>
      </Grid>
    </React.Fragment>
  );
}
