import React, { useEffect } from "react";
import {
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  Tooltip,
  Typography,
  Divider,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { SlClose } from "react-icons/sl";
import LogoutIcon from "@mui/icons-material/Logout";
import SaveIcon from "@mui/icons-material/Save";

export default function AlertResponsiveDialog({
  label,
  labelAgree,
  labelDisagree,
  labelPrimary,
  PrimaryIcon,
  alertTitle,
  alertHeading,
  alertIcon = "close",
  alertMessage,
  noOutLine,
  color,
  disableAgree,
  autoFocusAgreeButton,
  fullScreenBreakPoint,
  fullscreenOnly,
  maxWidthClass,
  handleOnClickYes,
  handleOnClickNo,
  defaultOpenDialog,
  disabled,
  ToolTipLabel,
  style,
  onYesClickCloseIfExeSuccessfully,
}) {
  const openDefaultStatus =
    defaultOpenDialog && defaultOpenDialog == true ? true : false;
  const [open, setOpen] = React.useState(openDefaultStatus);
  const theme = useTheme();
  const fullScreen = useMediaQuery(
    theme.breakpoints.down(
      fullScreenBreakPoint !== undefined ? fullScreenBreakPoint : "md"
    )
  );

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (handleOnClickNo) {
      handleOnClickNo();
    }
  };

  const handleYes = () => {
    if (onYesClickCloseIfExeSuccessfully) {
      if (handleOnClickYes()) {
        setOpen(false);
      }
    } else {
      setOpen(false);
      handleOnClickYes();
    }
  };

  const alertBtnStyle = style;

  const AutoFocusAgree =
    autoFocusAgreeButton && autoFocusAgreeButton === true ? true : false;

  let btnColor = color ? color : "primary";
  let StyleBtn = {
    fontWeight: "bolder",
    fontSize: "20px",
  };

  let disableAgreeButton = false;
  if (disableAgree && disableAgree === true) {
    disableAgreeButton = true;
  }

  return (
    <div>
      {ToolTipLabel ? (
        noOutLine && noOutLine === true ? (
          <Tooltip title={ToolTipLabel}>
            <IconButton
              color={btnColor}
              onClick={handleClickOpen}
              disabled={disabled}
              style={alertBtnStyle}
            >
              {label}
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title={ToolTipLabel}>
            <Button
              variant="outlined"
              color={btnColor}
              onClick={handleClickOpen}
              disabled={disabled}
              style={alertBtnStyle}
            >
              {label}
            </Button>
          </Tooltip>
        )
      ) : noOutLine && noOutLine === true ? (
        <IconButton
          color={btnColor}
          onClick={handleClickOpen}
          disabled={disabled}
          style={alertBtnStyle}
        >
          {label}
        </IconButton>
      ) : (
        <Button
          variant="outlined"
          color={btnColor}
          onClick={handleClickOpen}
          disabled={disabled}
          style={alertBtnStyle}
        >
          {label}
        </Button>
      )}
      <Dialog
        fullScreen={fullscreenOnly ? true : fullScreen}
        maxWidth={maxWidthClass}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        {alertTitle ? (
          // <DialogTitle id="responsive-dialog-title">{alertTitle}</DialogTitle>
          <>
            <Typography
              fontSize="20px"
              color="black"
              marginTop={1}
              fontWeight="bolder"
              marginLeft={1}
            >
              {alertHeading ? alertHeading : "Delete confirmation"}
            </Typography>
            <Divider style={{ marginTop: "8px" }} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {alertIcon === "close" ? (
                <SlClose
                  style={{
                    fontSize: 70,
                    color: "#d32f2f",
                    marginTop: "10px",
                  }}
                />
              ) : alertIcon === "logout" ? (
                <LogoutIcon
                  style={{
                    fontSize: 70,
                    color: "#d32f2f",
                    marginTop: "10px",
                  }}
                />
              ) : (
                ""
              )}
              <DialogTitle
                id="responsive-dialog-title"
                style={{ fontWeight: "normal" }}
              >
                {alertTitle}
              </DialogTitle>
            </div>
            <Divider style={{ marginBottom: "8px" }} />
          </>
        ) : (
          <>
            <Typography
              fontSize="20px"
              color="black"
              marginTop={1}
              fontWeight="bolder"
              marginLeft={1}
            >
              {alertHeading ? alertHeading : ""}
            </Typography>
            <Divider style={{ marginTop: "8px" }} />
          </>
        )}
        {alertMessage ? (
          <DialogContent>
            <DialogContentText style={{ fontSize: "20px" }}>
              {alertMessage}
            </DialogContentText>
          </DialogContent>
        ) : (
          ""
        )}
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {labelDisagree ? (
            <Button
              autoFocus
              onClick={handleClose}
              style={{
                backgroundColor: "white",
                fontSize: "14px",
                color: "black",
                border: "1px solid black",
              }}
            >
              {labelDisagree}
            </Button>
          ) : (
            ""
          )}
          {labelAgree ? (
            <Button
              onClick={handleYes}
              color="primary"
              disabled={disableAgreeButton}
              style={{
                backgroundColor: "#d32f2f",
                fontSize: "14px",
                color: "white",
              }}
              autoFocus={AutoFocusAgree}
            >
              {labelAgree}
            </Button>
          ) : (
            ""
          )}
          {labelPrimary ? (
            <Button
              onClick={handleYes}
              color="primary"
              style={{
                backgroundColor: "#143ee6",
                fontSize: "14px",
                color: "white",
              }}
              startIcon={PrimaryIcon}
            >
              {labelPrimary}
            </Button>
          ) : (
            ""
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
