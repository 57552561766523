import React, { useEffect, useState } from "react";
import TextEditor from "./TextEditor";
import CtBtn from "./CustomTool/CtBtn";

function Terms({
  TermsCondition,
  PreparatoryWorkEntry,
  TermsPayment,
  TermsCancellation,
  Note,
  updateDetails,
  clearDetails,
}) {
  const [textInfo1, setTextInfo1] = useState("");
  const [textInfo2, setTextInfo2] = useState("");
  const [textInfo3, setTextInfo3] = useState("");
  const [textInfo4, setTextInfo4] = useState("");
  const [textInfo5, setTextInfo5] = useState("");
  const [textTobeSet1, setTextTobeSet1] = useState("");
  const [textTobeSet2, setTextTobeSet2] = useState("");
  const [textTobeSet3, setTextTobeSet3] = useState("");
  const [textTobeSet4, setTextTobeSet4] = useState("");
  const [textTobeSet5, setTextTobeSet5] = useState("");
  const [editorKey, setEditorKey] = useState(0); // Key for re-rendering
  const [editorKey2, setEditorKey2] = useState(0); // Key for re-rendering
  const [editorKey3, setEditorKey3] = useState(0); // Key for re-rendering
  const [editorKey4, setEditorKey4] = useState(0); // Key for re-rendering
  const [editorKey5, setEditorKey5] = useState(0); // Key for re-rendering

  useEffect(() => {
    setDefaultCondition();
    console.log("call useEffect");
  }, [
    TermsCondition,
    PreparatoryWorkEntry,
    TermsPayment,
    TermsCancellation,
    Note,
    editorKey,
    editorKey2,
    editorKey3,
    editorKey4,
    editorKey5,
  ]);

  const setDefaultCondition = () => {
    console.log("editorKey", editorKey);
    if (TermsCondition !== textTobeSet1) setTextTobeSet1(TermsCondition);
    if (PreparatoryWorkEntry !== textTobeSet2)
      setTextTobeSet2(PreparatoryWorkEntry);
    if (TermsPayment !== textTobeSet3) setTextTobeSet3(TermsPayment);
    if (TermsCancellation !== textTobeSet4) setTextTobeSet4(TermsCancellation);
    if (Note !== textTobeSet5) setTextTobeSet5(Note);
  };

  const onEditorStateChange = (ctrlInfo) => {
    let hasChanged = false;

    if (
      ctrlInfo.id === "TermsConditionDetails" &&
      ctrlInfo.value !== textInfo1
    ) {
      setTextInfo1(ctrlInfo.value);
      hasChanged = true;
    } else if (
      ctrlInfo.id === "PreparatoryWork" &&
      ctrlInfo.value !== textInfo2
    ) {
      setTextInfo2(ctrlInfo.value);
      hasChanged = true;
    } else if (ctrlInfo.id === "TermsPayment" && ctrlInfo.value !== textInfo3) {
      setTextInfo3(ctrlInfo.value);
      hasChanged = true;
    } else if (
      ctrlInfo.id === "TermsCancellation" &&
      ctrlInfo.value !== textInfo4
    ) {
      setTextInfo4(ctrlInfo.value);
      hasChanged = true;
    } else if (ctrlInfo.id === "Note" && ctrlInfo.value !== textInfo5) {
      setTextInfo5(ctrlInfo.value);
      hasChanged = true;
    }

    if (hasChanged) {
      updateDetails(ctrlInfo.id, ctrlInfo.value);
    }
  };

  const resetEditorStateToUndefined = (id) => {
    if (id === "TermsConditionDetails") {
      setTextTobeSet1(undefined);
    } else if (id === "PreparatoryWork") {
      setTextTobeSet2(undefined);
    } else if (id === "TermsPayment") {
      setTextTobeSet3(undefined);
    } else if (id === "TermsCancellation") {
      setTextTobeSet4(undefined);
    } else if (id === "Note") {
      setTextTobeSet5(undefined);
    }
  };

  const clear = (id) => {
    if (id == "TermsConditionDetails") {
      clearDetails(id);
      setTextInfo1(""); // Reset internal state
      setTextTobeSet1(""); // Reset EditorState
      setEditorKey((prevKey) => prevKey + 1); // Force re-render
    } else if (id == "PreparatoryWork") {
      clearDetails(id);
      setTextInfo2(""); // Reset internal state
      setTextTobeSet2(""); // Reset EditorState
      setEditorKey2((prevKey2) => prevKey2 + 1); // Force re-render
    } else if (id == "TermsPayment") {
      clearDetails(id);
      setTextInfo3(""); // Reset internal state
      setTextTobeSet3(""); // Reset EditorState
      setEditorKey3((prevKey3) => prevKey3 + 1); // Force re-render
    } else if (id == "TermsCancellation") {
      clearDetails(id);
      setTextInfo4(""); // Reset internal state
      setTextTobeSet4(""); // Reset EditorState
      setEditorKey4((prevKey4) => prevKey4 + 1); // Force re-render
    } else if (id == "Note") {
      clearDetails(id);
      setTextInfo5(""); // Reset internal state
      setTextTobeSet5(""); // Reset EditorState
      setEditorKey5((prevKey5) => prevKey5 + 1); // Force re-render
    }
  };

  let styleOfParagraph = {
    fontWeight: "bolder",
    textAlign: "center",
    fontSize: "21px",
    padding: "0px",
    margin: "0px",
  };

  // console.log("textTobeSet1", textTobeSet1);
  // console.log("textInfo1", textInfo1);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "0px",
          margin: "0px",
          gap: "20px",
        }}
      >
        <p style={styleOfParagraph}>Terms And Condition</p>
        <CtBtn
          label={"Clear"}
          firstletterBig={false}
          onClick={() => {
            clear("TermsConditionDetails");
          }}
        />
      </div>

      <br />
      {editorKey == 0 && (
        <TextEditor
          id="TermsConditionDetails"
          onEditorStateChange={onEditorStateChange}
          EditorState={textTobeSet1}
          resetEditorStateToUndefined={resetEditorStateToUndefined}
        />
      )}
      {editorKey > 0 && (
        <TextEditor
          id="TermsConditionDetails"
          onEditorStateChange={onEditorStateChange}
          EditorState={""}
          resetEditorStateToUndefined={resetEditorStateToUndefined}
        />
      )}
      <br />
      {/* <p style={styleOfParagraph}>Preparatory Work Entry</p> */}

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "0px",
          margin: "0px",
          gap: "20px",
        }}
      >
        <p style={styleOfParagraph}>Preparatory Work Entry</p>
        <CtBtn
          label={"Clear"}
          firstletterBig={false}
          onClick={() => {
            clear("PreparatoryWork");
          }}
        />
      </div>
      <br />
      {editorKey2 == 0 && (
        <TextEditor
          id="PreparatoryWork"
          onEditorStateChange={onEditorStateChange}
          EditorState={textTobeSet2}
          resetEditorStateToUndefined={resetEditorStateToUndefined}
        />
      )}
      {editorKey2 > 0 && (
        <TextEditor
          id="PreparatoryWork"
          onEditorStateChange={onEditorStateChange}
          EditorState={""}
          resetEditorStateToUndefined={resetEditorStateToUndefined}
        />
      )}
      <br />

      {/* <p style={styleOfParagraph}>Terms of Payment</p> */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "0px",
          margin: "0px",
          gap: "20px",
        }}
      >
        <p style={styleOfParagraph}>Terms of Payment</p>
        <CtBtn
          label={"Clear"}
          firstletterBig={false}
          onClick={() => {
            clear("TermsPayment");
          }}
        />
      </div>
      <br />
      {editorKey3 == 0 && (
        <TextEditor
          id="TermsPayment"
          onEditorStateChange={onEditorStateChange}
          EditorState={textTobeSet3}
          resetEditorStateToUndefined={resetEditorStateToUndefined}
        />
      )}
      {editorKey3 > 0 && (
        <TextEditor
          id="TermsPayment"
          onEditorStateChange={onEditorStateChange}
          EditorState={""}
          resetEditorStateToUndefined={resetEditorStateToUndefined}
        />
      )}
      <br />

      {/* <p style={styleOfParagraph}>Terms of Cancellation</p> */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "0px",
          margin: "0px",
          gap: "20px",
        }}
      >
        <p style={styleOfParagraph}>Terms of Cancellation</p>
        <CtBtn
          label={"Clear"}
          firstletterBig={false}
          onClick={() => {
            clear("TermsCancellation");
          }}
        />
      </div>
      <br />
      {editorKey4 == 0 && (
        <TextEditor
          id="TermsCancellation"
          onEditorStateChange={onEditorStateChange}
          EditorState={textTobeSet4}
          resetEditorStateToUndefined={resetEditorStateToUndefined}
        />
      )}
      {editorKey4 > 0 && (
        <TextEditor
          id="TermsCancellation"
          onEditorStateChange={onEditorStateChange}
          EditorState={""}
          resetEditorStateToUndefined={resetEditorStateToUndefined}
        />
      )}
      <br />

      {/* <p style={styleOfParagraph}>Note</p> */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "0px",
          margin: "0px",
          gap: "20px",
        }}
      >
        <p style={styleOfParagraph}>Note</p>
        <CtBtn
          label={"Clear"}
          firstletterBig={false}
          onClick={() => {
            clear("Note");
          }}
        />
      </div>
      <br />
      {editorKey5 == 0 && (
        <TextEditor
          id="Note"
          onEditorStateChange={onEditorStateChange}
          EditorState={textTobeSet5}
          resetEditorStateToUndefined={resetEditorStateToUndefined}
        />
      )}
      {editorKey5 > 0 && (
        <TextEditor
          id="Note"
          onEditorStateChange={onEditorStateChange}
          EditorState={""}
          resetEditorStateToUndefined={resetEditorStateToUndefined}
        />
      )}
      <br />
    </>
  );
}

export default Terms;
