import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import HOCVerifyIsUser from "../HOC/HOCVerifyIsUser";
import CtBtn from "../Components/CustomTool/CtBtn";
import CtTxt from "../Components/CustomTool/CtTxt";
import CtTextFieldPWD from "../Components/CustomTool/CtTxtPWD";
import CtCmb from "../Components/CustomTool/CtCmb";
import CtTxtNum from "../Components/CustomTool/CtTxtNum";
import MaterialTable from "../Components/CustomTool/MaterialTable";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import AlertResponsiveDialog from "../Components/CustomTool/AlertResponsiveDialog";
// import DepartmentMaster from "./DepartmentMaster";
import { Stack } from "@mui/material";
// import PlantMaster from "./PlantMaster";
// import { fetchUser } from "../Components/API";
import HoCtToastContainer from "../HOC/HoCtToastContainer";
import {
  applyTrimOnObjectValues,
  csvFileName,
  getRowOfRT,
  setKeyboardShortcuts,
} from "../SystemUtility/SystemUtility";
import {
  mapSetUserPrivileges_ToProps,
  mapStatetoProps,
} from "../mapRedux/mapReduxProps";
import { connect } from "react-redux";
import { withRouter } from "../HOC/withRouter";
import { fetchCopPrice } from "./API";
import MobileTable from "./CustomTool/MobileTable";
import CtART from "./CustomTool/ResponsiveTable";
import axios from "axios";
import CtTxtAmt from "../Components/CustomTool/CtTxtAmt";
import CtCmbEditable from "./CustomTool/CtCmbEditable";

class CopPriceMaster extends Component {
  state = {
    Unit: [{ vac_type: "Type" }],
    DisplayType: [],
    columnsdata: [
      {
        accessorKey: "bint_srno",
        header: "SR No",
      },
      {
        accessorKey: "vac_button_type",
        header: "Button Type",
      },
      {
        accessorKey: "vac_display_type",
        header: "Display Type",
      },
      {
        accessorKey: "bint_stop",
        header: "Stop",
      },
      {
        accessorKey: "dec_per_stop_price",
        header: "Per Stop Price Rise",
      },
      {
        accessorKey: "dec_stop_base_price",
        header: "Cop Base Price Of 2 Stops",
      },
    ],

    rows: [],
    formData: {
      bint_srno: 0,
      cmbButtonType: "",
      cmbDisplayType: "",
      txtStop: "",
      txtPerStopPrice: "",
      txtCopBasePrice: "",
    },
    dataLoadStatus: true,
    ARD: null,
    rowsCount: 0,
    rowsPerPage: 50,
  };

  //#region Component
  componentDidMount() {
    localStorage.setItem("Heading", "COP Price Master");
    this.props.verifyUserID(
      this.getInfo,
      this.props.setUserPrivileges,
      this.props.modules.copprice
    );
    this.ShortCut();
  }

  componentWillUnmount() {
    this.removeShortCut();
  }

  //#endregion Component

  //#region Handle

  ShortCut = () => {
    this.removeShortCut = setKeyboardShortcuts(
      this.saveCopPrice,
      this.clearInfo,
      () => {
        this.DeleteData(
          this.state.formData.bint_srno,
          this.state.formData.cmbButtonType
        );
      }
    );
  };

  handleOnChange = (e) => {
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: e.target.value },
    });
  };

  handleARDonActionClick_Edit = (rtRow) => {
    const row = getRowOfRT(rtRow, [
      "bint_srno",
      "vac_button_type",
      "vac_display_type",
      "bint_stop",
      "dec_per_stop_price",
      "dec_stop_base_price",
    ]);
    const bint_srno = row.bint_srno,
      cmbButtonType = row.vac_button_type,
      cmbDisplayType = row.vac_display_type,
      txtStop = row.bint_stop,
      txtPerStopPrice = row.dec_per_stop_price,
      txtCopBasePrice = row.dec_stop_base_price;
    this.setState({
      formData: {
        ...this.state.formData,
        bint_srno,
        cmbButtonType,
        cmbDisplayType,
        txtStop,
        txtPerStopPrice,
        txtCopBasePrice,
      },
    });
    document.getElementById("cmbButtonType").focus();
  };

  handleARDonActionClick_Delete = (rtRow) => {
    const row = getRowOfRT(rtRow, ["bint_srno", "vac_button_type"]);
    this.DeleteData(row.bint_srno, row.vac_button_type);
  };

  //#endregion Handle

  //#region function

  getInfo = () => {
    this.props.notifyProcessing();
    const reqData = {
      Op: "ListCopPrice",
    };
    fetchCopPrice(reqData)
      .then((res) => {
        this.props.closeUpdateProcessing();
        let rows = res.data.ListCopPrice,
          rowsCount = rows.length,
          DisplayType =
            res.data.DisplayType.length > 0 ? res.data.DisplayType : [];
        this.setState({
          rowsCount,
          rows,
          DisplayType,
        });
      })
      .catch((error) => {
        console.log("Unknown error occurred in onFormLoad.", error);
      });
    document.getElementById("cmbButtonType").focus();
  };

  clearInfo = () => {
    this.setState(
      {
        formData: {
          ...this.state.formData,
          bint_srno: 0,
          cmbButtonType: "",
          cmbDisplayType: "",
          txtStop: "",
          txtPerStopPrice: "",
          txtCopBasePrice: "",
        },
      },
      () => {
        document.getElementById("cmbButtonType").focus();
      }
    );
  };

  trimFormData = (nextFunctionCall) => {
    let formData = applyTrimOnObjectValues({
      formData: this.state.formData,
    });
    this.setState({ formData }, () => {
      if (nextFunctionCall) {
        nextFunctionCall();
      }
    });
  };

  validationCopPrice = () => {
    let result = false;
    if (this.state.formData.cmbButtonType.length <= 0) {
      this.props.toastErrorMsg("Select Button Type", "cmbButtonType");
    } else if (this.state.formData.cmbDisplayType.length <= 0) {
      this.props.toastErrorMsg("Enter Display Type", "cmbDisplayType");
    } else if (this.state.formData.txtStop.length <= 0) {
      this.props.toastErrorMsg("Enter Stop", "txtStop");
    } else if (this.state.formData.txtPerStopPrice.length <= 0) {
      this.props.toastErrorMsg("Enter Per Stop Price Rise", "txtPerStopPrice");
    } else if (this.state.formData.txtCopBasePrice.length <= 0) {
      this.props.toastErrorMsg(
        "Enter Cop Base Price Of 2Stops",
        "txtCopBasePrice"
      );
    } else {
      result = true;
    }
    return result;
  };

  saveCopPrice = () => {
    this.trimFormData(() => {
      if (this.validationCopPrice()) {
        this.props.notifyProcessing();
        const reqData = {
          Op: "SaveCopPrice",
          bint_srno: this.state.formData.bint_srno,
          vac_button_type: this.state.formData.cmbButtonType,
          vac_display_type: this.state.formData.cmbDisplayType,
          bint_stop: this.state.formData.txtStop,
          dec_per_stop_price: this.state.formData.txtPerStopPrice,
          dec_stop_base_price: this.state.formData.txtCopBasePrice,
        };
        fetchCopPrice(reqData)
          .then((res) => {
            this.props.updateProcessing(res.data.msgType, res.data.message);
            if (res.data.msgType === "success") {
              let rows = [],
                rowsCount = rows.length;
              if (res.data.ListCopPrice && res.data.ListCopPrice.length > 0) {
                rows = res.data.ListCopPrice;
                rowsCount = rows.length;
              }
              this.setState(
                {
                  rows,
                  rowsCount,
                },
                () => {
                  this.clearInfo();
                }
              );
            }
          })
          .catch((error) => {
            console.log("Unknown error occurred in Save Cop Price .", error);
          });
      }
    });
  };

  DeleteData = (bint_srno, vac_button_type) => {
    if (bint_srno > 0) {
      this.setState({ ARD: null }, () => {
        let ARD = (
          <AlertResponsiveDialog
            labelAgree="Delete"
            labelDisagree="Cancel"
            alertTitle={`Do you want to Delete Button type : ${vac_button_type} ?`}
            alertMessage=""
            handleOnClickYes={() => {
              return this.DeleteCopPrice(bint_srno);
            }}
            defaultOpenDialog={true}
            onYesClickCloseIfExeSuccessfully={true}
          />
        );
        this.setState({ ARD });
      });
    } else {
      this.props.toastErrorMsg("Select a record for deletion.");
    }
  };

  DeleteCopPrice = (bint_srno) => {
    this.props.notifyProcessing();
    const reqData = {
      Op: "DeleteCopPrice",
      bint_srno: bint_srno,
    };
    fetchCopPrice(reqData)
      .then((res) => {
        this.props.updateProcessing(res.data.msgType, res.data.message);
        if (res.data.msgType === "success") {
          let rows = [],
            rowsCount = rows.length;
          if (res.data.ListCopPrice && res.data.ListCopPrice.length > 0) {
            rows = res.data.ListCopPrice;
            rowsCount = rows.length;
          }
          this.setState(
            {
              rows,
              rowsCount,
              ARD: null,
            },
            () => {
              this.clearInfo();
            }
          );
        }
      })
      .catch((error) => {
        this.props.updateProcessing(
          "error",
          "Unknown error occurred in delete Cop Price. " + error
        );
      });
    return false;
  };

  //#endregion function

  render() {
    // console.log("this.props.ButtonType", this.props.ButtonType);
    return (
      <form>
        <Grid container direction="row" justifyContent="flex-end">
          <Grid item>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <CtBtn
                  className="secondary-button"
                  label="Save"
                  iconName="save"
                  onClick={this.saveCopPrice}
                />
              </Grid>
              <Grid item>
                <CtBtn
                  label="New"
                  className="new-button"
                  iconName="new"
                  onClick={this.clearInfo}
                />
              </Grid>
              <Grid item>
                <CtBtn
                  label="Delete"
                  className="delete-button"
                  iconName="delete"
                  onClick={() => {
                    this.DeleteData(
                      this.state.formData.bint_srno,
                      this.state.formData.cmbButtonType
                    );
                  }}
                  disabled={this.state.formData.bint_srno > 0 ? false : true}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          marginTop={0}
        >
          <Grid item>
            <CtCmb
              id="cmbButtonType"
              label="Button Type*"
              items={this.props.ButtonType}
              value={this.state.formData.cmbButtonType}
              handleOnChange={this.handleOnChange}
              width={220}
              maxLength={100}
              onKeyDown={this.props.onKeyDown}
              colID="vac_button_type"
            />
          </Grid>
          {/* <Grid item>
            <CtTxt
              id="cmbButtonType"
              label="Button Type*"
              maxLength={100}
              width="220"
              value={this.state.formData.cmbButtonType}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid> */}
          {/* <Grid item>
            <CtTxt
              id="cmbDisplayType"
              label="Display Type*"
              maxLength={100}
              width="220"
              value={this.state.formData.cmbDisplayType}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid> */}
          <Grid item>
            <CtCmb
              id="cmbDisplayType"
              label="Display Type*"
              items={this.state.DisplayType}
              value={this.state.formData.cmbDisplayType}
              handleOnChange={this.handleOnChange}
              width={220}
              maxLength={100}
              onKeyDown={this.props.onKeyDown}
              colID="vac_displaytype"
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtStop"
              label="Stop*"
              maxLength={100}
              width="220"
              value={this.state.formData.txtStop}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtAmt
              id="txtPerStopPrice"
              label="Per Stop Price Rise*"
              maxLength={100}
              width="220"
              value={this.state.formData.txtPerStopPrice}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
              RupeeSymbol={true}
            />
          </Grid>
          <Grid item>
            <CtTxtAmt
              id="txtCopBasePrice"
              label="Cop Base Price Of 2Stops*"
              maxLength={100}
              width="220"
              value={this.state.formData.txtCopBasePrice}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
              defaultAction={this.saveCopPrice}
              RupeeSymbol={true}
            />
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          marginTop={1}
        >
          {/* responsive table */}
          <Grid item className="maintable">
            <CtART
              rowsdata={this.state.rows}
              columnsdata={this.state.columnsdata}
              rowsPerPage={this.state.rowsPerPage}
              tblmaxHeight={500}
              csvFileName={csvFileName("Cop Price")}
              rowIconAction={[
                {
                  label: "Edit",
                  onClick: this.handleARDonActionClick_Edit,
                  icon: "Edit",
                  className: "secondary-button",
                },
                {
                  label: "Delete",
                  onClick: this.handleARDonActionClick_Delete,
                  icon: "delete",
                  className: "delete-button",
                },
              ]}
              showColumnFilters={false}
              actionColSize={80}
              density={"compact"}
              hideColIdList={["bint_srno"]}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Box display={{ xs: "none" }} style={{ textAlign: "right" }}>
            {this.state.ARD}
          </Box>
        </Grid>
      </form>
    );
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <CopPriceMaster {...props} navigate={navigate} />;
}
export default connect(
  mapStatetoProps,
  mapSetUserPrivileges_ToProps
)(HoCtToastContainer(HOCVerifyIsUser(withRouter(WithNavigate))));
