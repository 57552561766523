import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import HOCVerifyIsUser from "../HOC/HOCVerifyIsUser";
import HoCtToastContainer from "../HOC/HoCtToastContainer";
import "../App.css";
import CtTxt from "../Components/CustomTool/CtTxt";
import CtBtn from "../Components/CustomTool/CtBtn";
import MaterialTable from "../Components/CustomTool/MaterialTable";
import "./Table.css";

import { fetchDepartment } from "../Components/API";
import AlertResponsiveDialog from "../Components/CustomTool/AlertResponsiveDialog";
import Progress from "../Components/CustomTool/Progress";
import {
  applyTrimOnObjectValues,
  csvFileName,
} from "../SystemUtility/SystemUtility";
import ProposalEntry from "./ProposalEntry";
import { withRouter } from "../HOC/withRouter";
import { connect } from "react-redux";
import {
  mapSetUserPrivileges_ToProps,
  mapStatetoProps,
} from "../mapRedux/mapReduxProps";
import {
  Collapse,
  ListItem,
  ListItemIcon,
  Switch,
  Typography,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import TableList from "./CustomTool/TableList";

class OutputPaeameters extends Component {
  state = {
    columns: [
      {
        id: "bint_pout_sum_item_no",
        label: "SR",
      },
      {
        id: "vac_description",
        label: "DESCRIPTION",
      },
      {
        id: "dec_qty",
        label: "QTY",
      },
      {
        id: "vac_unit",
        label: "UNIT",
      },
      {
        id: "dec_price",
        label: "PRICE/QTY",
      },
      {
        id: "dec_amount",
        label: "AMOUNT",
      },
    ],
    // tableDetails: [
    //   {
    //     tableHeading: "A. RAIL BRACKET WITH HARDWARE",
    //     rows: [],
    //     rowsCount: 0,
    //     collapse: "RailCollapse",
    //   },
    //   {
    //     tableHeading: "B. LANDING & CAR DOOR COMPONENTS",
    //     rows: [],
    //     rowsCount: 0,
    //     collapse: "LandingCollapse",
    //   },
    //   {
    //     tableHeading: "C. MACHINE & MACHINE BASE",
    //     rows: [],
    //     rowsCount: 0,
    //     collapse: "MachineCollapse",
    //   },
    //   {
    //     tableHeading: "D. CAR FRAME & C/W FRAME",
    //     rows: [],
    //     rowsCount: 0,
    //     collapse: "CarCollapse",
    //   },
    //   {
    //     tableHeading: "E. ROPING",
    //     rows: [],
    //     rowsCount: 0,
    //     collapse: "RopingCollapse",
    //   },
    //   {
    //     tableHeading: "F. CABIN & ACCESSORIES",
    //     rows: [],
    //     rowsCount: 0,
    //     collapse: "CabinCollapse",
    //   },
    //   {
    //     tableHeading: "G. CONTROLLER & WIRING ACCESSORIES",
    //     rows: [],
    //     collapse: "ControllerCollapse",
    //   },
    //   {
    //     tableHeading: "H. MANUFACTURING LABOUR AND EXPENSE",
    //     rows: [],
    //     rowsCount: 0,
    //     collapse: "ManufacturingCollapse",
    //   },
    //   {
    //     tableHeading: "I. ERECTION, TRANSPORTATION & HOISTING",
    //     rows: [],
    //     rowsCount: 0,
    //     collapse: "ErectionCollapse",
    //   },
    // ],

    tableDetails:
      /*  this.props.location.state &&
      this.props.location.state.ListPOutAllSummary.length > 0
        ? this.props.location.state.ListPOutAllSummary
        : */ this.props.ListPOutAllSummary.length > 0
        ? this.props.ListPOutAllSummary
        : [],

    // RailCollapse: true,
    // LandingCollapse: true,
    // MachineCollapse: true,
    // CarCollapse: true,
    // RopingCollapse: true,
    // CabinCollapse: true,
    // ControllerCollapse: true,
    // ManufacturingCollapse: true,
    // ErectionCollapse: true,

    rows: [],
    formData: {
      txtSiteName:
        /*   this.props.location.state &&
        this.props.location.state.SiteName.length > 0
          ? this.props.location.state.SiteName
          : */ "",
      txtDateTime:
        /* this.props.location.state &&
        this.props.location.state.DateTime.length > 0
          ? this.props.location.state.DateTime
          :  */ "",
    },
    rowsPerPage: 50,
    dataLoadStatus: true,
    ARD: null,

    // isCheckedTable:
    //   localStorage.getItem("isCheckedTable") === "false" ? false : true,

    // RailCollapse: localStorage.getItem("RailCollapse"),
    // LandingCollapse: localStorage.getItem("LandingCollapse"),
    // MachineCollapse: localStorage.getItem("MachineCollapse"),
    // CarCollapse: localStorage.getItem("CarCollapse"),
    // RopingCollapse: localStorage.getItem("RopingCollapse"),
    // CabinCollapse: localStorage.getItem("CabinCollapse"),
    // ControllerCollapse: localStorage.getItem("ControllerCollapse"),
    // ManufacturingCollapse: localStorage.getItem("ManufacturingCollapse"),
    // ErectionCollapse: localStorage.getItem("ErectionCollapse"),
  };

  //#region component

  componentDidUpdate(prevProps) {
    if (prevProps.ListPOutAllSummary !== this.props.ListPOutAllSummary) {
      this.setState({ tableDetails: this.props.ListPOutAllSummary });
    }
  }

  componentDidMount() {
    this.props.verifyUserID(
      undefined,
      this.props.setUserPrivileges,
      this.props.modules.proposal
    );
  }

  //#endregion component

  //#region function

  // toggleClick = () => {
  //   if (this.state.isCheckedTable == true) {
  //     this.setState(
  //       {
  //         RailCollapse: true,
  //         LandingCollapse: true,
  //         MachineCollapse: true,
  //         CarCollapse: true,
  //         RopingCollapse: true,
  //         CabinCollapse: true,
  //         ControllerCollapse: true,
  //         ManufacturingCollapse: true,
  //         ErectionCollapse: true,
  //       },
  //       () => {
  //         localStorage.setItem("RailCollapse", true);
  //         localStorage.setItem("LandingCollapse", true);
  //         localStorage.setItem("MachineCollapse", true);
  //         localStorage.setItem("CarCollapse", true);
  //         localStorage.setItem("RopingCollapse", true);
  //         localStorage.setItem("CabinCollapse", true);
  //         localStorage.setItem("ControllerCollapse", true);
  //         localStorage.setItem("ManufacturingCollapse", true);
  //         localStorage.setItem("ErectionCollapse", true);
  //         //   localStorage.setItem("PayRollListCollapse", true);
  //         // localStorage.setItem("TraningCollapse", true);
  //         // localStorage.setItem("PayRollMasterCollapse", true);
  //       }
  //     );
  //   } else {
  //     this.setState(
  //       {
  //         RailCollapse: false,
  //         LandingCollapse: false,
  //         MachineCollapse: false,
  //         CarCollapse: false,
  //         RopingCollapse: false,
  //         CabinCollapse: false,
  //         ControllerCollapse: false,
  //         ManufacturingCollapse: false,
  //         ErectionCollapse: false,
  //       },
  //       () => {
  //         localStorage.setItem("RailCollapse", false);
  //         localStorage.setItem("LandingCollapse", false);
  //         localStorage.setItem("MachineCollapse", false);
  //         localStorage.setItem("CarCollapse", false);
  //         localStorage.setItem("RopingCollapse", false);
  //         localStorage.setItem("CabinCollapse", false);
  //         localStorage.setItem("ControllerCollapse", false);
  //         localStorage.setItem("ManufacturingCollapse", false);
  //         localStorage.setItem("ErectionCollapse", false);
  //       }
  //     );
  //   }
  // };

  //#endregion function

  //#region handle

  handleOnChange = (e) => {
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: e.target.value },
    });
  };

  handleChangeRowsPerPage = (rowsPerPage) => {
    this.setState({ rowsPerPage });
  };

  handleOnCheck = () => {
    const isCheckedTable = !this.state.isCheckedTable;
    this.setState({ isCheckedTable }, () => {
      this.toggleClick();
    });
  };

  handleCollapse = (CollapseName) => {
    this.setState((prevState) => {
      const newState = !prevState[CollapseName];
      localStorage.setItem(CollapseName, newState);
      if (
        localStorage.getItem("RailCollapse") === "true" &&
        localStorage.getItem("LandingCollapse") === "true" &&
        localStorage.getItem("MachineCollapse") === "true" &&
        localStorage.getItem("CarCollapse") === "true" &&
        localStorage.getItem("RopingCollapse") === "true" &&
        localStorage.getItem("CabinCollapse") === "true" &&
        localStorage.getItem("ControllerCollapse") === "true" &&
        localStorage.getItem("ManufacturingCollapse") === "true" &&
        localStorage.getItem("ErectionCollapse") === "true"
      ) {
        localStorage.setItem("isCheckedTable", true);
        return {
          [CollapseName]: newState,
          isCheckedTable: true,
        };
      } else if (
        localStorage.getItem("RailCollapse") === "false" &&
        localStorage.getItem("LandingCollapse") === "false" &&
        localStorage.getItem("MachineCollapse") === "false" &&
        localStorage.getItem("CarCollapse") === "false" &&
        localStorage.getItem("RopingCollapse") === "false" &&
        localStorage.getItem("CabinCollapse") === "false" &&
        localStorage.getItem("ControllerCollapse") === "false" &&
        localStorage.getItem("ManufacturingCollapse") === "false" &&
        localStorage.getItem("ErectionCollapse") === "false"
      ) {
        localStorage.setItem("isCheckedTable", false);
        return {
          [CollapseName]: newState,
          isCheckedTable: false,
        };
      } else {
        return {
          [CollapseName]: newState,
        };
      }
    });
  };

  //#endregion handle

  render() {
    // console.log("this.props.ListPOutAllSummary", this.props.ListPOutAllSummary);
    return (
      <form>
        <Grid container justifyContent={"space-between"}>
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              spacing={3}
            >
              {/* <Grid item marginTop={1.5}>
                <CtBtn
                  label={"Back"}
                  iconName={"left"}
                  className={"child-button"}
                  variant={"outlined"}
                  firstletterBig={false}
                  onClick={() => {
                    this.props.navigateTo("InputParametersEntry");
                  }}
                />
              </Grid>
              <Grid item>
                <CtTxt
                  id="txtSiteName"
                  value={this.state.formData.txtSiteName}
                  label="Site Name"
                  handleOnChange={this.handleOnChange}
                  maxLength={100}
                  width={200}
                  disabled={true}
                />
              </Grid>
              <Grid item>
                <CtTxt
                  id="txtDateTime"
                  value={this.state.formData.txtDateTime}
                  label="Date Time"
                  handleOnChange={this.handleOnChange}
                  maxLength={100}
                  width={200}
                  disabled={true}
                />
              </Grid> */}
            </Grid>
          </Grid>

          {/* <Grid item marginTop={1.5}>
            <b
              style={{
                paddingRight: "12px",
                paddingTop: "8px",
                cursor: "pointer",
              }}
              onClick={this.handleOnCheck}
            >
              {this.state.isCheckedTable ? "Collapse All" : "Expand All"}
            </b>

            <Switch
              checked={this.state.isCheckedTable}
              onChange={this.handleOnCheck}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Grid> */}
        </Grid>
        {/* <Grid container direction="row" justifyContent="flex-end">
          <Grid item>
            <b
              style={{
                paddingRight: "12px",
                paddingTop: "8px",
                cursor: "pointer",
              }}
              onClick={this.handleOnCheck}
            >
              {this.state.isCheckedTable ? "Collapse All" : "Expand All"}
            </b>

            <Switch
              checked={this.state.isCheckedTable}
              onChange={this.handleOnCheck}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Grid>
        </Grid> */}
        <Grid container direction={"column"}>
          {this.state.tableDetails.map((row, index) => (
            <Grid item key={index}>
              <Grid
                container
                direction="column"
                marginTop={2}
                paddingBottom={1}
                spacing={1}
                paddingRight={1}
                paddingLeft={1}
                style={{ backgroundColor: "#f0f0f0", borderRadius: "10px" }}
              >
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justifyContent={"space-between"}
                  >
                    <Grid item>
                      <Typography
                        fontWeight={"bold"}
                        fontSize={20}
                        style={{
                          color: "black",
                          textTransform: "uppercase",
                          marginTop: "4px",
                        }}
                      >
                        {row.vac_title}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        direction="row"
                        justifyContent={"flex-end"}
                      >
                        <Grid item>
                          <Typography
                            fontWeight={"bold"}
                            fontSize={20}
                            style={{
                              color: "black",
                              textTransform: "uppercase",
                              marginTop: "6px",
                            }}
                          >
                            Total : {row.dec_value}
                          </Typography>
                        </Grid>
                        {/* <Grid item>
                          <ListItem
                            onClick={() => {
                              this.handleCollapse(row.collapse);
                            }}
                          >
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                cursor: "pointer",
                                justifyContent: "center",
                              }}
                            >
                              {this.state[row.collapse] ? (
                                <ExpandLess />
                              ) : (
                                <ExpandMore />
                              )}
                            </ListItemIcon>
                          </ListItem>
                        </Grid> */}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/* <Collapse in={this.state[row.collapse]}> */}
                {/* <Grid item>
                    <Grid
                      container
                      //   justifyContent="center"
                      justifyContent="flex-start"
                      //   alignItems={"center"}
                      direction="row"
                      alignContent={"center"}
                      spacing={1}
                    >
                      {this.state.PayRollList.map((row, index) => {
                        if (this.props.userPrivilege[row.module]) {
                          return (
                            <Grid item lg={3} md={4} sm={6} xs={12} key={index}>
                              <Link
                                to={row.listLink}
                                style={{ textDecoration: "none" }}
                              >
                                <Card
                                  style={{
                                    height: "80px",
                                    justifyContent: "center",
                                    alignContent: "center",
                                  }}
                                  className="card"
                                >
                                  <CardContent>
                                    <Stack
                                      direction={"row"}
                                      spacing={3}
                                      alignItems={"center"}
                                      justifyContent={"space-between"}
                                    >
                                      <Stack
                                        direction={"row"}
                                        spacing={1}
                                        alignItems={"center"}
                                      >
                                        {row.Icon}
                                        <Typography>{row.ListName}</Typography>
                                      </Stack>
                                      {row.listEntry &&
                                      row.listEntry == true ? (
                                        <>
                                          <Stack
                                            direction={"row"}
                                            // justifyContent={"center"}
                                            spacing={0.5}
                                          >
                                            {this.props.userPrivilege[
                                              row.module
                                            ] == "M" ||
                                            this.props.userPrivilege[
                                              row.module
                                            ] == "W" ? (
                                              <Tooltip
                                                title={"Add " + row.ListName}
                                                arrow
                                              >
                                                <Link
                                                  to={row.EntryLink}
                                                  style={{
                                                    textDecoration: "none",
                                                  }}
                                                >
                                                  <AddCircleOutlinedIcon
                                                    style={{
                                                      color: "#177cdd",
                                                    }}
                                                    className="addIcon"
                                                  />
                                                </Link>
                                              </Tooltip>
                                            ) : (
                                              ""
                                            )}
                                            <Tooltip
                                              title={row.ListName + " List"}
                                              arrow
                                            >
                                              <ListAltOutlined />
                                            </Tooltip>
                                          </Stack>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {row.list && row.list == true ? (
                                        <Stack
                                          direction={"row"}
                                          // justifyContent={"center"}
                                          spacing={0.5}
                                        >
                                          <Tooltip
                                            title={row.ListName + " List"}
                                            arrow
                                          >
                                            <ListAltOutlined />
                                          </Tooltip>
                                        </Stack>
                                      ) : (
                                        ""
                                      )}
                                    </Stack>
                                  </CardContent>
                                </Card>
                              </Link>
                            </Grid>
                          );
                        }
                      })}
                    </Grid>
                  </Grid> */}
                <Grid item marginTop={3} className="maintable">
                  <TableList
                    columns={this.state.columns}
                    rows={row.POutItemdetail}
                    rowsCount={row.POutItemdetail.length}
                    rowsPerPage={this.state.rowsPerPage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                </Grid>
                {/* </Collapse> */}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </form>
    );
  }
}

// export default withRouter(OutputPaeameters);

function WithNavigate(props) {
  let navigate = useNavigate();
  return <OutputPaeameters {...props} navigate={navigate} />;
}
export default connect(
  mapStatetoProps,
  mapSetUserPrivileges_ToProps
)(HoCtToastContainer(HOCVerifyIsUser(withRouter(WithNavigate))));
