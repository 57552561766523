import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Grid, Tab, Typography } from "@mui/material";
import React, { Component } from "react";
import CtCmbEditable from "./CustomTool/CtCmbEditable";
import CtTxt from "./CustomTool/CtTxt";
import CtTxtNum from "./CustomTool/CtTxtNum";
import CtTxtAmt from "./CustomTool/CtTxtAmt";
import ControlledCheckbox from "./CustomTool/CtCheckBox";
import CtCmb from "./CustomTool/CtCmb";
import TableList from "./CustomTool/TableList";
import CtMultiLineText from "./CustomTool/CtMultiLineText";
import CtBtn from "./CustomTool/CtBtn";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import HoCtToastContainer from "../HOC/HoCtToastContainer";
import { withRouter } from "../HOC/withRouter";
import CtCRT from "./CustomTool/CustomResponsiveTable";
import { Delete, Edit, FlashAuto } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  mapSetUserPrivileges_ToProps,
  mapStatetoProps,
} from "../mapRedux/mapReduxProps";
import HOCVerifyIsUser from "../HOC/HOCVerifyIsUser";
import {
  applyTrimOnObjectValues,
  get_DMY_from_YMD,
  get_YMD_from_SYS,
  isValidItem,
  validatedate_min_max,
} from "../SystemUtility/SystemUtility";
import CtDtp from "./CustomTool/CtDtp";
import { fetchpricecalculation } from "./API";
import OutputPaeameters from "./OutputPaeameters";
import SummaryDetaild from "./SummaryDetaild";

class InputParametersEntry extends Component {
  state = {
    ListPOutAllSummary: [],
    PriceSummary: [],
    Extra1: 0,
    Extra2: 0,
    SalesProfitPercentage: 0,
    formData: {
      showPriceSummary: false,
      value: "1",
      value2: "1",
      txtInqueryNo: "",
      dtpInquery: get_YMD_from_SYS(new Date()),
      txtSiteName: "",
      vac_edit_inquery_no: "",
      vac_edit_revision_no: "",
      dat_edit_inquery_date: "",
      SellingPrice: "",

      //Basic Value
      cmbJobType: "",
      cmbElevatorType: "",
      txtPerson: "",
      txtLoadCapacity: 0,
      cmbLoadCapacity: 0,
      cmbElevatorSpeed: "",
      cmbReverse: "",
      txtBasement: "",
      cmbGroundFloor: "",
      txtFloors: "",
      txtStops: "",
      txtOpenings: "",
      txtPit: "",
      txtPitCustom: "",
      cmbMRL: "",
      cmbARD: "",
      cmbOLNS: "",
      cmbComChain: "NO",
      cmbRoping: "",
      cmbPosition: "",
      cmbProgressiveSafety: "",
      chkCarRail: false,
      chkCwRail: false,
      chkCwRail82: false,
      vac_specialcar_railtype: "",
      vac_specialCW_railtype: "",

      // Machine Type
      cmbMachineType: "",
      cmbMachineBrand: "",
      cmbModelNo: "",
      txtMachineLoad: "",
      txtMachineSpeed: "",

      // Door Type
      cmbDoorType: "",
      cmbDoorStyle: "",
      cmbDoorBrand: "",
      cmbDoorOpeningWidth: 0,
      cmbMsDoorType: "",
      cmbDoorEntranceHeight: "2000",
      cmbDoorFrameSize: "",
      cmbDoorMaterial: "",
      cmbGlassDoor: "NO",

      //Cabin Types
      txtHeightOfCabin: 0,
      cmbFlooringOfCabin: "",
      cmbMaterialOfCabinPanels: "",
      cmbCabinPanelThickness: 0,

      //Controller Types
      cmbDriveType: "",
      cmbCommunicationType: "",
      cmbDriveName: "",
      cmbCOPLOPType: "",
      cmbControllerType: "",
      cmbCOPLOPDisplayType: "",
      cmbInbuiltBiometricsDevice: "",
    },
    nextBtnName: "Continue To Machine Details",
    previousBtnName: "",
    ListRailPrice: [],
    EditData:
      this.props.location.state && this.props.location.state.editData
        ? this.props.location.state.editData
        : [],

    //basic state
    JobType: [{ vac_type: "TIEPL" }, { vac_type: "ELEVATOR KIT" }],
    ElevatorType: [{ vac_type: "PASSENGER" }, { vac_type: "GOODS" }],
    Position: [{ vac_type: "BACK" }, { vac_type: "SIDE" }],
    Roping: [{ vac_type: "1:1" }, { vac_type: "2:1" }, { vac_type: "4:1" }],
    ElevatorSpeed: [
      // { vac_type: "0.3" },
      // { vac_type: "0.5" },
      // { vac_type: "1" },
      // { vac_type: "1.5" },
      // { vac_type: "2" },
      // { vac_type: "2.5" },
      // { vac_type: "3" },
    ],
    CarAndCwFrame: [],
    Person:
      this.props.location.state && this.props.location.state.Person.length > 0
        ? this.props.location.state.Person
        : [],
    LoadCapacity:
      /*  [
      { vac_loadcapacity: "1000" },
      { vac_loadcapacity: "1500" },
      { vac_loadcapacity: "2000" },
      { vac_loadcapacity: "2500" },
      { vac_loadcapacity: "3000" },
      { vac_loadcapacity: "3500" },
      { vac_loadcapacity: "4000" },
      { vac_loadcapacity: "5000" },
      { vac_loadcapacity: "6000" },
    ] */ this.props.location.state &&
      this.props.location.state.LoadCapacity.length > 0
        ? this.props.location.state.LoadCapacity
        : [],
    YesNo: [{ type: "YES" }, { type: "NO" }],

    // Machine State
    MachineType: [{ vac_type: "GEARED" }, { vac_type: "GEARLESS" }],
    MachineBrand: [],
    ModelNo: [],

    // Door State
    DoorTypes: [{ vac_door: "AUTO" }, { vac_door: "MANUAL" }],

    DoorDetails: [],
    DoorOpeningWidth: [
      { vac_door_opening: "600" },
      { vac_door_opening: "650" },
      { vac_door_opening: "700" },
      { vac_door_opening: "800" },
      { vac_door_opening: "900" },
      { vac_door_opening: "1000" },
      { vac_door_opening: "1100" },
      { vac_door_opening: "1200" },
      { vac_door_opening: "1300" },
      { vac_door_opening: "1400" },
      { vac_door_opening: "1500" },
      { vac_door_opening: "1600" },
      { vac_door_opening: "1700" },
      { vac_door_opening: "1800" },
      { vac_door_opening: "1900" },
      { vac_door_opening: "2000" },
      { vac_door_opening: "2100" },
      { vac_door_opening: "2200" },
      { vac_door_opening: "2300" },
      { vac_door_opening: "2400" },
      { vac_door_opening: "2500" },
      { vac_door_opening: "2600" },
      { vac_door_opening: "2700" },
      { vac_door_opening: "2800" },
      { vac_door_opening: "2900" },
      { vac_door_opening: "3000" },
    ],
    DoorEntranceHeight: [
      { vac_type: "2000" },
      { vac_type: "2100" },
      { vac_type: "2200" },
      { vac_type: "2300" },
      { vac_type: "2400" },
    ],
    DoorFrameSize: [{ vac_frame: "40x90" }, { vac_frame: "60x120" }],
    DoorMaterial: [],
    GlassDoor: [],

    //Cabin State
    FlooringOfCabin: [
      { vac_type: "REGULAR CARPET" },
      { vac_type: "MARBLE BY CLIENT" },
      { vac_type: "MARBLE BY TOP" },
      // { vac_type: "MS CHEQUERED 3mm" },
      { vac_type: "MS CHEQUERED PLATE 5mm" },
      { vac_type: "SS CHEQUERED PLATE 3mm" },
      { vac_type: "ALLUMINIUM CHEQUERED PLATE 3mm" },
    ],
    MaterialOfCabinPanels: [],
    CabinPanelThickness: [
      { vac_type: "1.0" },
      { vac_type: "1.2" },
      { vac_type: "1.5" },
    ],

    //Controller State

    DriveType: [{ vac_type: "REGULAR" }, { vac_type: "INTEGRATED" }],
    CommunicationType: [{ vac_type: "PARALLEL" }, { vac_type: "SERIAL" }],
    DriveName: [],
    COPLOPType: [{ vac_type: "PUSH BUTTON" }, { vac_type: "TOUCH BUTTON" }],
    ControllerType: [
      { vac_type: "DOWN COLLECTIVE" },
      { vac_type: "FULL COLLECTIVE" },
    ],
    COPLOPDisplayType: [
      // { vac_type: "SEVEN SEGMENT DISPLAY" },
      // { vac_type: "DOT MATRIX DISPLAY" },
    ],
    InbuiltBiometricsDevice: [{ vac_type: "YES" }, { vac_type: "NO" }],

    //Price Calcuation Tables

    RailBracketHardware: [
      {
        bint_srno: "1",
        vac_description: "",
        bint_qty: "",
        vac_unit: "",
        dec_price: "",
        dec_amount: "",
      },
    ],

    // Site Info

    actionList: [
      {
        name: "Edit",
        icon: <Edit />,
        // link: "SalesEntry:",
        // actionType: "AlertResponsive",
        // visiblecondition: [
        //   { colID: "DisableEdit", value: false, relationalOperator: "==" },
        // ],
        // hide: (this.props.userPrivilege.Sales == 'R' || this.props.userPrivilege.Sales == 'W')
      },
      {
        name: "Delete",
        icon: <Delete style={{ color: "#d32f2f" }} />,
        // link: "SalesEntry:",
        // actionType: "AlertResponsive",
        // hide:
        //   this.props.userPrivilege.Sales == "R" ||
        //   this.props.userPrivilege.Sales == "W",
      },
    ],
    siteColumn: [
      { id: "Action", label: "Action", name: "", width: 80 },
      {
        id: "vac_floor",
        label: "Floor",
        // type: "CtMultiLineText",
        // CtrlProps: {
        //   maxLength: 100,
        //   items: [],
        //   width: 180,
        // },
      },
      {
        id: "vac_front",
        label: "Front",
        type: "CtTxt",
        // type: "CtCmb",
        CtrlProps: { width: 100, maxLength: 100 },
        // CtrlProps: {
        //   maxLength: 100,
        //   items: [],
        //   width: 180,
        // },
      },
      {
        id: "vac_reverse",
        label: "Reverse",
        type: "Chk",
        CtrlProps: { behaviour: "check" },
      },
    ],
    siteRows: [{ vac_floor: "1" }, { vac_floor: "2" }],
    previousSiteRows: [],
    siteRowsPerPage: 50,
    doorRows: [{ vac_floor: "1" }, { vac_floor: "2" }],
    doorRowsCount: 0,

    // Door Info

    doorColumn: [
      {
        id: "vac_floor",
        label: "Floor",
      },
      {
        id: "vac_front",
        label: "Enclosure",
        type: "CtCmb",
        CtrlProps: {
          colID: "enclosure",
          items: [
            { enclosure: "S.S Silver Linen" },
            { enclosure: "S.S Golden Linen" },
            { enclosure: "S.S Silver Mirror" },
            { enclosure: "S.S Golden Mirror" },
          ],
          width: 180,
        },
      },
      {
        id: "vac_set_all",
        label: "Set To All",
        type: "Chk",
        CtrlProps: {
          behaviour: "radio",
        },
      },
    ],
  };

  componentDidMount() {
    // document.title = "Top India Elevator : More Info";
    localStorage.setItem("Heading", "Price Calculator Entry");

    this.props.verifyUserID(
      this.getInfo,
      this.props.setUserPrivileges,
      this.props.modules.pricecalculation
    );
  }

  handleOnChange = (e) => {
    this.setState(
      {
        formData: { ...this.state.formData, [e.target.name]: e.target.value },
      },
      () => {
        if (e.target.name == "cmbElevatorType") {
          let cmbLoadCapacity = this.state.formData.cmbLoadCapacity,
            txtLoadCapacity = this.state.formData.txtLoadCapacity,
            txtPerson = this.state.formData.txtPerson;
          if (this.state.formData.cmbElevatorType == "GOODS") {
            txtLoadCapacity = 0;
            txtPerson = "";
          } else if (this.state.formData.cmbElevatorType == "PASSENGER") {
            cmbLoadCapacity = 0;
          }
          this.setState({
            formData: {
              ...this.state.formData,
              txtLoadCapacity,
              cmbLoadCapacity,
              txtPerson,
            },
          });
        } else if (e.target.name == "txtPerson") {
          let txtLoadCapacity = "";
          txtLoadCapacity = Number(this.state.formData.txtPerson) * 68;
          let ElevatorSpeed = this.GetSpeedFromPersonOrLoadCapacity(
            this.state.formData.txtPerson
          );
          this.setState({
            formData: {
              ...this.state.formData,
              txtLoadCapacity,
            },
            ElevatorSpeed,
          });
        } else if (
          e.target.name == "cmbLoadCapacity" &&
          this.state.formData.cmbElevatorType === "GOODS"
        ) {
          let ElevatorSpeed = this.GetSpeedFromPersonOrLoadCapacity(
            this.state.formData.cmbLoadCapacity
          );
          this.setState({
            ElevatorSpeed,
          });
        } else if (e.target.name == "txtStops") {
          let txtPit =
              this.state.formData.txtStops > 0
                ? Number((this.state.formData.txtStops - 1) * 3100) +
                  Number(6800)
                : "",
            cmbComChain =
              this.state.formData.txtStops > 0 &&
              Number((this.state.formData.txtStops - 1) * 3100) > 28000
                ? "YES"
                : "NO";

          this.setState({
            formData: {
              ...this.state.formData,
              txtPit,
              cmbComChain,
            },
          });
        } else if (e.target.name == "cmbMachineType") {
          this.setState({
            formData: {
              ...this.state.formData,
              cmbMachineBrand: "",
              cmbModelNo: "",
              txtMachineLoad: "",
              txtMachineSpeed: "",
            },
          });
        } else if (e.target.name == "cmbMachineBrand") {
          this.setState({
            formData: {
              ...this.state.formData,
              cmbModelNo: "",
              txtMachineLoad: "",
              txtMachineSpeed: "",
            },
          });
        } else if (e.target.name == "cmbModelNo") {
          let machineLoadSpeed = this.state.MachineBrand.filter((row) => {
            if (
              row.vac_machinetype == this.state.formData.cmbMachineType &&
              row.vac_machinemake == this.state.formData.cmbMachineBrand &&
              row.vac_model == this.state.formData.cmbModelNo
            ) {
              return row;
            }
          });

          if (
            machineLoadSpeed.length > 0 &&
            this.state.formData.cmbModelNo.length > 0
          ) {
            this.setState({
              formData: {
                ...this.state.formData,
                txtMachineLoad: machineLoadSpeed[0].bint_loadcapacity,
                txtMachineSpeed: machineLoadSpeed[0].dec_speed,
              },
            });
          } else {
            this.setState({
              formData: {
                ...this.state.formData,
                txtMachineLoad: "",
                txtMachineSpeed: "",
              },
            });
          }
        } else if (e.target.name == "cmbDoorType") {
          let cmbDoorFrameSize = this.state.formData.cmbDoorFrameSize,
            cmbDoorMaterial = this.state.formData.cmbDoorMaterial,
            cmbGlassDoor = this.state.formData.cmbGlassDoor,
            cmbMsDoorType = this.state.formData.cmbMsDoorType;
          if (this.state.formData.cmbDoorType == "AUTO") {
            cmbMsDoorType = "";
          } else if (this.state.formData.cmbDoorType == "MANUAL") {
            cmbDoorFrameSize = "";
            cmbDoorMaterial = "";
            cmbGlassDoor = "";
          }
          this.setState({
            formData: {
              ...this.state.formData,
              cmbDoorStyle: "",
              cmbDoorBrand: "",
              cmbMsDoorType,
              cmbDoorFrameSize,
              cmbDoorMaterial,
              cmbGlassDoor,
            },
          });
        } else if (e.target.name == "cmbDoorEntranceHeight") {
          let txtHeightOfCabin =
            Number(this.state.formData.cmbDoorEntranceHeight) + 200;

          this.setState({
            formData: {
              ...this.state.formData,
              txtHeightOfCabin,
            },
          });
        }
      }
    );
  };

  handleOnChangeTab = (event, newValue) => {
    // console.log("newValue", newValue);
    let value = this.state.formData.value;
    if (value == 1) {
      if (this.validateBasicPriceCalculation()) {
        this.setStateFunCtion(newValue);
      }
    } else if (value == 2) {
      if (this.validateMachinePriceCalculation()) {
        this.setStateFunCtion(newValue);
      }
    } else if (value == 3) {
      if (this.validateDoorPriceCalculation()) {
        this.setStateFunCtion(newValue);
      }
    } else if (value == 4) {
      if (this.validateCabinPriceCalculation()) {
        this.setStateFunCtion(newValue);
      }
    } else if (value == 5) {
      if (this.validateControllerPriceCalculation()) {
        this.setStateFunCtion(newValue);
      }
    } else {
      this.setStateFunCtion(newValue);
    }
  };

  handleOnChangeTab2 = (event, newValue) => {
    this.setState(
      {
        formData: { ...this.state.formData, value2: newValue },
      },
      () => {
        this.changeBtnName(newValue);
      }
    );
  };

  handleOnCheckChange = (e) => {
    this.setState(
      {
        formData: { ...this.state.formData, [e.target.name]: e.target.checked },
      },
      () => {
        let chkCwRail82 = this.state.formData.chkCwRail82,
          chkCwRail = this.state.formData.chkCwRail,
          vac_specialcar_railtype = "",
          vac_specialCW_railtype = "";

        if (this.state.formData.chkCarRail == true) {
          vac_specialcar_railtype = "CAR RAIL (82x62x16)";
        }
        if (e.target.name == "chkCwRail" && e.target.checked == true) {
          vac_specialCW_railtype = "C/W RAIL (70x65x9)";
          if (chkCwRail82 == true) {
            chkCwRail82 = false;
          }
        } else if (e.target.name == "chkCwRail82" && e.target.checked == true) {
          vac_specialCW_railtype = "C/W RAIL (82x62x16)";
          if (chkCwRail == true) {
            chkCwRail = false;
          }
        }
        this.setState(
          {
            formData: {
              ...this.state.formData,
              chkCwRail82,
              chkCwRail,
              vac_specialcar_railtype,
              vac_specialCW_railtype,
            },
          } /* ,
          () => {
            console.log(
              "chkCarRail, chkCwRail82, chkCwRail Inside handleCheck",
              this.state.formData.chkCarRail,
              this.state.formData.chkCwRail82,
              this.state.formData.chkCwRail
            );
          } */
        );
      } /* ,
      () => {
        if (e.target.name == "chkGroundFloor") {
          let Floor = this.state.formData.txtFloor,
            rows = [],
            txtNoOpening =
              Number(this.state.formData.txtBasement) +
              Number(this.state.formData.txtNoFloor) +
              (this.state.formData.chkGroundFloor == true ? 1 : 0);
          if (this.state.formData.chkGroundFloor == 1) {
            Floor += this.state.formData.txtFloor.length > 0 ? " + G" : "G";
            this.state.formData.txtFloor.length > 0
              ? Floor.split(" + ").map((row) =>
                  rows.push({ vac_floor: row, vac_front: true })
                )
              : rows.push({ vac_floor: "G", vac_front: true });
          } else {
            Floor =
              this.state.formData.txtBasement.length > 0
                ? Floor.replace(" + G", "")
                : Floor.replace("G", "");
            this.state.formData.txtBasement.length > 0
              ? Floor.split(" + ").map((row) =>
                  rows.push({ vac_floor: row, vac_front: true })
                )
              : rows.push();
          }

          console.log("rows in chk", rows);
          this.setState({
            formData: {
              ...this.state.formData,
              txtFloor: Floor,
              txtNoOpening: txtNoOpening,
              txtFloorHeight: txtNoOpening * 3,
            },
            siteRows: rows,
            doorRows: rows,
          });
        }
      } */
    );
  };

  handleOnSiteRowDataChange = (rows) => {
    // console.log("rows changes", rows);
    this.setState(
      {
        siteRows: rows,
        siteRowsCount: rows.length,
      },
      () => {
        let txtNoOpening = 0;
        this.state.siteRows.map((row) => {
          if (row.vac_front === true && row.vac_reverse === true) {
            txtNoOpening += 2;
          } else if (row.vac_front === true) {
            txtNoOpening += 1;
          } else if (row.vac_reverse === true) {
            txtNoOpening += 1;
          }
        });
        // console.log("this.state.siteRows", this.state.siteRows);
        // console.log("txtNoOpening", txtNoOpening);
        this.setState({
          formData: {
            ...this.state.formData,
            txtNoOpening: txtNoOpening,
          },
        });
      }
    );
  };

  handleOnDoorRowDataChange = (rows) => {
    this.setState({ doorRows: rows, doorRowsCount: rows.length }, () => {
      let SetToAll = false,
        enclosure = "",
        doorRows = this.state.doorRows;
      this.state.doorRows.map((row) => {
        if (row.vac_set_all === true) {
          SetToAll = true;
          enclosure = row.vac_front;
        }
      });
      if (SetToAll == true) {
        doorRows = this.state.doorRows.map((row) => {
          return { ...row, vac_front: enclosure };
        });
      }

      this.setState({
        doorRows,
      });
    });
  };

  getInfo = () => {
    this.props.notifyProcessing();
    let EditData = this.state.EditData[0];
    this.GetPriceCalculationMaster();
    // console.log("EditData.dec_elevatorspeed", EditData.dec_elevatorspeed);
    if (EditData) {
      this.props.closeUpdateProcessing();
      this.setState(
        {
          Extra1: EditData.dec_Extra1_amount,
          Extra2: EditData.dec_Extra2_amount,
          SalesProfitPercentage: EditData.dec_TopSales_Percentage,
          formData: {
            ...this.state.formData,
            txtInqueryNo: EditData.vac_inquery_no,
            vac_edit_inquery_no: EditData.vac_inquery_no,
            vac_edit_revision_no: EditData.vac_revision_no,
            dtpInquery: EditData.dat_inquery_date_YMD,
            dat_edit_inquery_date: EditData.dat_inquery_date_YMD,
            txtSiteName: EditData.vac_sitename,
            showPriceSummary: EditData.vac_show_price_icon,
            SellingPrice: EditData.dec_sellingprice,

            //Basic Value
            cmbJobType: EditData.vac_jobtype,
            cmbElevatorType: EditData.vac_elevatortype,
            txtPerson: EditData.bint_person,
            txtLoadCapacity:
              EditData.vac_elevatortype == "PASSENGER"
                ? EditData.dec_loadcapacitykg
                : "",
            cmbLoadCapacity:
              EditData.vac_elevatortype == "GOODS"
                ? EditData.dec_loadcapacitykg
                : "",
            cmbElevatorSpeed: EditData.dec_elevatorspeed,
            cmbReverse: EditData.vac_sideopenig,
            txtBasement: EditData.bint_basement,
            cmbGroundFloor: EditData.bint_groundfloor == 1 ? "YES" : "NO",
            txtFloors: EditData.bint_floor,
            txtStops: EditData.bint_stop,
            txtOpenings: EditData.bint_openings,
            txtPit: EditData.bint_pit_rise_oh,
            txtPitCustom: EditData.bint_custom_pit_rise_oh,
            cmbMRL: EditData.vac_mrl,
            cmbARD: EditData.vac_ard,
            cmbOLNS: EditData.vac_olns,
            cmbComChain: EditData.vac_comchain,
            cmbRoping: EditData.vac_roping,
            cmbPosition: EditData.vac_cw_position,
            cmbProgressiveSafety: EditData.vac_progressivesafety,
            chkCarRail:
              EditData.vac_specialcar_railtype.length > 0 ? true : false,
            chkCwRail:
              EditData.vac_specialCW_railtype == "C/W RAIL (70x65x9)"
                ? true
                : false,
            chkCwRail82:
              EditData.vac_specialCW_railtype == "C/W RAIL (82x62x16)"
                ? true
                : false,
            vac_specialcar_railtype: EditData.vac_specialcar_railtype,
            vac_specialCW_railtype: EditData.vac_specialCW_railtype,

            // Machine Type
            cmbMachineType: EditData.vac_machinetype,
            cmbMachineBrand: EditData.vac_machinebrand,
            cmbModelNo: EditData.vac_modelno,
            txtMachineLoad: EditData.bint_machineloadcapacity,
            txtMachineSpeed: EditData.dec_machinespeed,

            // Door Type
            cmbDoorType: EditData.vac_doortype,
            cmbDoorStyle: EditData.vac_doorstyle,
            cmbDoorBrand: EditData.vac_doorbrand,
            cmbDoorOpeningWidth: EditData.bint_dooropeningwidth,
            cmbMsDoorType: EditData.vac_msdoorframebytop,
            cmbDoorEntranceHeight: EditData.bint_doorentranceheight,
            cmbDoorFrameSize: EditData.vac_doorframsize,
            cmbDoorMaterial: EditData.vac_doormaterial,
            cmbGlassDoor: EditData.vac_glassdoor,

            //Cabin Types
            txtHeightOfCabin: EditData.dec_cabinheight,
            cmbFlooringOfCabin: EditData.vac_cabinflooring,
            cmbMaterialOfCabinPanels: EditData.vac_cabinpanelmaterial,
            cmbCabinPanelThickness: EditData.dec_cabinpanelthickness,

            //Controller Types
            cmbDriveType: EditData.vac_drivetype,
            cmbCommunicationType: EditData.vac_communication,
            cmbDriveName: EditData.vac_drivename,
            cmbCOPLOPType: EditData.vac_coploptype,
            cmbControllerType: EditData.vac_controllertype,
            cmbCOPLOPDisplayType: EditData.vac_coplopdisplaytype,
            cmbInbuiltBiometricsDevice: EditData.vac_inbuiltbiometricsdevice,
          },
        },
        () => {
          this.props.location.state &&
            this.props.location.state.showPriceCalculationOutput == "true" &&
            this.savePriceCalculator(true);
        }
      );
    } else if (Object.entries(this.props.PriceCalculation).length > 0) {
      // console.log("this.props.PriceCalculation", this.props.PriceCalculation);
      const MainData = this.props.PriceCalculation,
        BasicInfo = this.props.PriceCalculation.BasicInfo[0],
        machinedetails = this.props.PriceCalculation.machinedetails[0],
        doordetails = this.props.PriceCalculation.doordetails[0],
        cabindetails = this.props.PriceCalculation.cabindetails[0],
        controllerdetails = this.props.PriceCalculation.controllerdetails[0];

      this.props.closeUpdateProcessing();
      this.setState({
        formData: {
          ...this.state.formData,
          txtInqueryNo: MainData.vac_edit_inquery_no,
          vac_edit_inquery_no: MainData.vac_edit_inquery_no,
          vac_edit_revision_no: MainData.vac_edit_revision_no,
          dtpInquery: MainData.dat_edit_inquery_date,
          dat_edit_inquery_date: MainData.dat_edit_inquery_date,
          txtSiteName: MainData.vac_sitename,
          SellingPrice: MainData.SellingPrice,

          //Basic Value
          cmbJobType: BasicInfo.vac_jobtype,
          cmbElevatorType: BasicInfo.vac_elevatortype,
          txtPerson: BasicInfo.bint_person,
          txtLoadCapacity:
            BasicInfo.vac_elevatortype == "PASSENGER"
              ? BasicInfo.dec_loadcapacitykg
              : "",
          cmbLoadCapacity:
            BasicInfo.vac_elevatortype == "GOODS"
              ? BasicInfo.dec_loadcapacitykg
              : "",
          cmbElevatorSpeed: BasicInfo.dec_elevatorspeed,
          cmbReverse: BasicInfo.vac_sideopenig,
          txtBasement: BasicInfo.bint_basement,
          cmbGroundFloor: BasicInfo.bint_groundfloor == 1 ? "YES" : "NO",
          txtFloors: BasicInfo.bint_floor,
          txtStops: BasicInfo.bint_stop,
          txtOpenings: BasicInfo.bint_openings,
          txtPit: BasicInfo.bint_pit_rise_oh,
          txtPitCustom: BasicInfo.bint_custom_pit_rise_oh,
          cmbMRL: BasicInfo.vac_mrl,
          cmbARD: BasicInfo.vac_ard,
          cmbOLNS: BasicInfo.vac_olns,
          cmbComChain: BasicInfo.vac_comchain,
          cmbRoping: BasicInfo.vac_roping,
          cmbPosition: BasicInfo.vac_cw_position,
          cmbProgressiveSafety: BasicInfo.vac_progressivesafety,
          chkCarRail:
            BasicInfo.vac_specialcar_railtype.length > 0 ? true : false,
          chkCwRail:
            BasicInfo.vac_specialCW_railtype == "C/W RAIL (70x65x9)"
              ? true
              : false,
          chkCwRail82:
            BasicInfo.vac_specialCW_railtype == "C/W RAIL (82x62x16)"
              ? true
              : false,
          vac_specialcar_railtype: BasicInfo.vac_specialcar_railtype,
          vac_specialCW_railtype: BasicInfo.vac_specialCW_railtype,

          // Machine Type
          cmbMachineType: machinedetails.vac_machinetype,
          cmbMachineBrand: machinedetails.vac_machinebrand,
          cmbModelNo: machinedetails.vac_modelno,
          txtMachineLoad: machinedetails.bint_machineloadcapacity,
          txtMachineSpeed: machinedetails.dec_machinespeed,

          // Door Type
          cmbDoorType: doordetails.vac_doortype,
          cmbDoorStyle: doordetails.vac_doorstyle,
          cmbDoorBrand: doordetails.vac_doorbrand,
          cmbDoorOpeningWidth: doordetails.bint_dooropeningwidth,
          cmbMsDoorType: doordetails.vac_msdoorframebytop,
          cmbDoorEntranceHeight: doordetails.bint_doorentranceheight,
          cmbDoorFrameSize: doordetails.vac_doorframsize,
          cmbDoorMaterial: doordetails.vac_doormaterial,
          cmbGlassDoor: doordetails.vac_glassdoor,

          //Cabin Types
          txtHeightOfCabin: cabindetails.dec_cabinheight,
          cmbFlooringOfCabin: cabindetails.vac_cabinflooring,
          cmbMaterialOfCabinPanels: cabindetails.vac_cabinpanelmaterial,
          cmbCabinPanelThickness: cabindetails.dec_cabinpanelthickness,

          //Controller Types
          cmbDriveType: controllerdetails.vac_drivetype,
          cmbCommunicationType: controllerdetails.vac_communication,
          cmbDriveName: controllerdetails.vac_drivename,
          cmbCOPLOPType: controllerdetails.vac_coploptype,
          cmbControllerType: controllerdetails.vac_controllertype,
          cmbCOPLOPDisplayType: controllerdetails.vac_coplopdisplaytype,
          cmbInbuiltBiometricsDevice:
            controllerdetails.vac_inbuiltbiometricsdevice,
        },
        Person: MainData.Person,
        LoadCapacity: MainData.LoadCapacity,
      });
    } else {
      this.props.closeUpdateProcessing();
    }
  };

  GetPriceCalculationMaster = () => {
    this.props.notifyProcessing();
    const reqData = {
      Op: "GetPriceCalculationMaster",
    };
    fetchpricecalculation(reqData)
      .then((res) => {
        this.props.closeUpdateProcessing();
        let MachineBrand =
            res.data.MachineBrand.length > 0 ? res.data.MachineBrand : [],
          DoorMaterial =
            res.data.DoorMaterialGrade.length > 0
              ? res.data.DoorMaterialGrade
              : [],
          DriveName = res.data.DriveName.length > 0 ? res.data.DriveName : [],
          COPLOPDisplayType =
            res.data.DisplayType.length > 0 ? res.data.DisplayType : [],
          CarAndCwFrame =
            res.data.CarAndCwFrame.length > 0 ? res.data.CarAndCwFrame : [];
        this.setState(
          {
            MachineBrand,
            DoorMaterial,
            MaterialOfCabinPanels: DoorMaterial,
            DriveName,
            COPLOPDisplayType,
            DoorDetails: res.data.DoorDetails,
            CarAndCwFrame,
          },
          () => {
            let EditData = this.state.EditData[0];
            if (EditData) {
              let ElevatorSpeed = [];
              if (EditData.vac_elevatortype == "PASSENGER") {
                ElevatorSpeed = this.GetSpeedFromPersonOrLoadCapacity(
                  EditData.bint_person
                );
              } else if (EditData.vac_elevatortype == "GOODS") {
                ElevatorSpeed = this.GetSpeedFromPersonOrLoadCapacity(
                  EditData.dec_loadcapacitykg
                );
              }
              this.setState({
                ElevatorSpeed,
              });
            }
          }
        );
      })
      .catch((error) => {
        console.log("Unknown error occurred in getBrand Name.", error);
      });
  };

  GetSpeedFromPersonOrLoadCapacity = (PersonOrLoad) => {
    let ElevatorSpeed = [];
    this.state.CarAndCwFrame.filter((row) => {
      if (row.bint_passenger == Number(PersonOrLoad)) {
        ElevatorSpeed.push({ dec_speed: `${row.dec_speed}` });
      }
    });
    return ElevatorSpeed;
  };

  basicInfo = () => {
    // let cmbComChain =
    //   this.state.formData.txtStops > 0 &&
    //   Number((this.state.formData.txtStops - 1) * 3100) > 28000
    //     ? "YES"
    //     : "NO";

    return (
      <>
        <Grid
          container
          direction={"row"}
          justifyContent={"flex-start"}
          spacing={2}
          marginTop={1}
        >
          <Grid item>
            <CtCmb
              id="cmbJobType"
              label="Job Type*"
              items={this.state.JobType}
              value={this.state.formData.cmbJobType}
              handleOnChange={this.handleOnChange}
              width={180}
              maxLength={50}
              onKeyDown={this.props.onKeyDown}
              colID="vac_type"
            />
          </Grid>
          <Grid item>
            <CtCmb
              id="cmbElevatorType"
              label="Elevator Type*"
              items={this.state.ElevatorType}
              value={this.state.formData.cmbElevatorType}
              handleOnChange={this.handleOnChange}
              width={180}
              maxLength={50}
              onKeyDown={this.props.onKeyDown}
              colID="vac_type"
              nextCtrlID={
                this.state.formData.cmbElevatorType == "PASSENGER"
                  ? "txtPerson"
                  : this.state.formData.cmbElevatorType == "GOODS"
                  ? "txtLoadCapacity"
                  : "cmbElevatorSpeed"
              }
            />
          </Grid>
          {this.state.formData.cmbElevatorType == "PASSENGER" && (
            <>
              <Grid item>
                <CtCmbEditable
                  id="txtPerson"
                  label="Person*"
                  items={this.state.Person}
                  value={this.state.formData.txtPerson}
                  handleOnChange={this.handleOnChange}
                  width={180}
                  maxLength={10}
                  onKeyDown={this.props.onKeyDown}
                  colID="dec_passenger"
                  nextCtrlID={"cmbElevatorSpeed"}
                />
              </Grid>
              <Grid item>
                <CtTxtNum
                  id="txtLoadCapacity"
                  value={this.state.formData.txtLoadCapacity}
                  label="Load Capacity (KG)*"
                  handleOnChange={this.handleOnChange}
                  maxLength={10}
                  width={180}
                  onKeyDown={this.props.onKeyDown}
                  nextCtrlID={"cmbElevatorSpeed"}
                />
              </Grid>
            </>
          )}
          {this.state.formData.cmbElevatorType == "GOODS" && (
            <Grid item>
              <CtCmbEditable
                id="cmbLoadCapacity"
                label="Load Capacity (KG)*"
                items={this.state.LoadCapacity}
                value={this.state.formData.cmbLoadCapacity}
                handleOnChange={this.handleOnChange}
                width={220}
                maxLength={10}
                onKeyDown={this.props.onKeyDown}
                colID="dec_passenger"
                nextCtrlID={"cmbElevatorSpeed"}
              />
            </Grid>
          )}

          <Grid item>
            <CtCmb
              id="cmbElevatorSpeed"
              label="Elevator Speed (IN mps)*"
              items={this.state.ElevatorSpeed}
              value={this.state.formData.cmbElevatorSpeed}
              handleOnChange={this.handleOnChange}
              width={230}
              maxLength={10}
              onKeyDown={this.props.onKeyDown}
              colID="dec_speed"
              // colID="vac_type"
              nextCtrlID={"cmbReverse"}
            />
          </Grid>
          <Grid item>
            <CtCmb
              id="cmbReverse"
              label="Reverse / Side Opening*"
              items={this.state.YesNo}
              value={this.state.formData.cmbReverse}
              handleOnChange={this.handleOnChange}
              width={210}
              maxLength={50}
              onKeyDown={this.props.onKeyDown}
              colID="type"
              nextCtrlID={"txtBasement"}
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtBasement"
              value={this.state.formData.txtBasement}
              label="Basement"
              handleOnChange={this.handleOnChange}
              maxLength={10}
              width={180}
              onKeyDown={this.props.onKeyDown}
              nextCtrlID={"cmbGroundFloor"}
            />
          </Grid>
          <Grid item>
            <CtCmb
              id="cmbGroundFloor"
              label="Ground Floor"
              items={this.state.YesNo}
              value={this.state.formData.cmbGroundFloor}
              handleOnChange={this.handleOnChange}
              width={180}
              maxLength={50}
              onKeyDown={this.props.onKeyDown}
              colID="type"
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtFloors"
              value={this.state.formData.txtFloors}
              label="Floors"
              handleOnChange={this.handleOnChange}
              maxLength={10}
              width={180}
              onKeyDown={this.props.onKeyDown}
              nextCtrlID={"txtStops"}
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtStops"
              value={this.state.formData.txtStops}
              label="Stops*"
              handleOnChange={this.handleOnChange}
              maxLength={10}
              width={110}
              onKeyDown={this.props.onKeyDown}
              nextCtrlID={"txtOpenings"}
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtOpenings"
              value={this.state.formData.txtOpenings}
              label="Openings*"
              handleOnChange={this.handleOnChange}
              maxLength={10}
              width={110}
              onKeyDown={this.props.onKeyDown}
              nextCtrlID={"txtPitCustom"}
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtPit"
              value={this.state.formData.txtPit}
              label="PIT+RISE+OH"
              handleOnChange={this.handleOnChange}
              maxLength={10}
              width={180}
              onKeyDown={this.props.onKeyDown}
              disabled={true}
              nextCtrlID={"cmbMRL"}
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtPitCustom"
              value={this.state.formData.txtPitCustom}
              label="Custom PIT+RISE+OH"
              handleOnChange={this.handleOnChange}
              maxLength={10}
              width={205}
              onKeyDown={this.props.onKeyDown}
              nextCtrlID={"cmbMRL"}
            />
          </Grid>
          <Grid item>
            <CtCmb
              id="cmbMRL"
              label="MRL*"
              items={this.state.YesNo}
              value={this.state.formData.cmbMRL}
              handleOnChange={this.handleOnChange}
              width={250}
              maxLength={50}
              onKeyDown={this.props.onKeyDown}
              colID="type"
            />
          </Grid>
          <Grid item>
            <CtCmb
              id="cmbARD"
              label="ARD*"
              items={this.state.YesNo}
              value={this.state.formData.cmbARD}
              handleOnChange={this.handleOnChange}
              width={250}
              maxLength={50}
              onKeyDown={this.props.onKeyDown}
              colID="type"
            />
          </Grid>
          <Grid item>
            <CtCmb
              id="cmbOLNS"
              label="OLNS*"
              items={this.state.YesNo}
              value={this.state.formData.cmbOLNS}
              handleOnChange={this.handleOnChange}
              width={250}
              maxLength={50}
              onKeyDown={this.props.onKeyDown}
              colID="type"
            />
          </Grid>
          <Grid item>
            <CtCmb
              id="cmbComChain"
              label="Com Chain*"
              items={this.state.YesNo}
              value={this.state.formData.cmbComChain}
              // value={cmbComChain}
              handleOnChange={this.handleOnChange}
              width={250}
              maxLength={50}
              onKeyDown={this.props.onKeyDown}
              colID="type"
            />
          </Grid>
          <Grid item>
            <CtCmb
              id="cmbRoping"
              label="Roping*"
              items={this.state.Roping}
              value={this.state.formData.cmbRoping}
              handleOnChange={this.handleOnChange}
              width={250}
              maxLength={50}
              onKeyDown={this.props.onKeyDown}
              colID="vac_type"
            />
          </Grid>
          <Grid item>
            <CtCmb
              id="cmbPosition"
              label="C/W Position*"
              items={this.state.Position}
              value={this.state.formData.cmbPosition}
              handleOnChange={this.handleOnChange}
              width={250}
              maxLength={50}
              onKeyDown={this.props.onKeyDown}
              colID="vac_type"
            />
          </Grid>
          <Grid item>
            <CtCmb
              id="cmbProgressiveSafety"
              label="Progressive Safety*"
              items={this.state.YesNo}
              value={this.state.formData.cmbProgressiveSafety}
              handleOnChange={this.handleOnChange}
              width={250}
              maxLength={50}
              onKeyDown={this.props.onKeyDown}
              colID="type"
            />
          </Grid>
        </Grid>
        {/* <Grid
          container
          direction={"column"}
          justifyContent={"flex-start"}
          spacing={2}
          marginTop={1}
        > */}
        {/* <Grid item> */}
        <table style={{ marginTop: "20px" }}>
          <thead>
            <Typography
              fontWeight={"bold"}
              fontSize={18}
              color={"#177cdd"}
              // justifyContent={"center"}
              // alignItems={"center"}
              // textAlign={"center"}
            >
              SPECIAL INPUTS
            </Typography>
          </thead>
          <tr>
            <td>
              <label
                htmlFor="chkCarRail"
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <Typography fontWeight={"bold"} fontSize={17}>
                  IF CAR RAIL 82x62x16
                </Typography>
              </label>
            </td>
            <td style={{ paddingLeft: "10px", paddingBottom: "4px" }}>
              <ControlledCheckbox
                id={"chkCarRail"}
                // label={"Actual Leave"}
                handleCheckChange={this.handleOnCheckChange}
                checkboxColor={"#3179ff"}
                checked={
                  this.state.formData.chkCarRail == "false"
                    ? false
                    : this.state.formData.chkCarRail
                }
              />
            </td>
          </tr>
          <tr>
            <td>
              <label
                htmlFor="chkCwRail"
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <Typography fontWeight={"bold"} fontSize={17}>
                  IF CW RAIL 70x65x9
                </Typography>
              </label>
            </td>
            <td style={{ paddingLeft: "10px", paddingBottom: "4px" }}>
              <ControlledCheckbox
                id={"chkCwRail"}
                // label={"Actual Leave"}
                handleCheckChange={this.handleOnCheckChange}
                checkboxColor={"#3179ff"}
                checked={
                  this.state.formData.chkCwRail == "false"
                    ? false
                    : this.state.formData.chkCwRail
                }
              />
            </td>
          </tr>
          <tr>
            <td>
              <label
                htmlFor="chkCwRail82"
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <Typography fontWeight={"bold"} fontSize={17}>
                  IF CW RAIL 82x62x16
                </Typography>
              </label>
            </td>
            <td style={{ paddingLeft: "10px", paddingBottom: "4px" }}>
              <ControlledCheckbox
                id={"chkCwRail82"}
                // label={"Actual Leave"}
                handleCheckChange={this.handleOnCheckChange}
                checkboxColor={"#3179ff"}
                checked={
                  this.state.formData.chkCwRail82 == "false"
                    ? false
                    : this.state.formData.chkCwRail82
                }
              />
            </td>
          </tr>
        </table>
        {/* </Grid> */}
        {/* </Grid> */}
      </>
    );
  };

  machineRoom = () => {
    return (
      <form>
        <Grid
          container
          direction={"row"}
          justifyContent={"flex-start"}
          spacing={2}
          marginTop={1}
        >
          <Grid item>
            <CtCmb
              id="cmbMachineType"
              label="Machine Type*"
              items={this.state.MachineType}
              value={this.state.formData.cmbMachineType}
              handleOnChange={this.handleOnChange}
              width={220}
              maxLength={100}
              onKeyDown={this.props.onKeyDown}
              colID="vac_type"
              nextCtrlID={"cmbMachineBrand"}
            />
          </Grid>
          <Grid item>
            <CtCmbEditable
              id="cmbMachineBrand"
              label="Machine Brand*"
              items={this.state.MachineBrand}
              value={this.state.formData.cmbMachineBrand}
              handleOnChange={this.handleOnChange}
              width={230}
              maxLength={100}
              onKeyDown={this.props.onKeyDown}
              colID="vac_machinemake"
              nextCtrlID={"cmbModelNo"}
              dataFilter={[
                {
                  filterColID: "vac_machinetype",
                  value: this.state.formData.cmbMachineType,
                },
              ]}
            />
          </Grid>
          <Grid item>
            <CtCmbEditable
              id="cmbModelNo"
              label="Model No*"
              items={this.state.MachineBrand}
              value={this.state.formData.cmbModelNo}
              handleOnChange={this.handleOnChange}
              width={230}
              maxLength={100}
              onKeyDown={this.props.onKeyDown}
              colID="vac_model"
              nextCtrlID={"txtMachineLoad"}
              dataFilter={[
                {
                  filterColID: "vac_machinetype",
                  value: this.state.formData.cmbMachineType,
                  logOp: "and",
                },
                {
                  filterColID: "vac_machinemake",
                  value: this.state.formData.cmbMachineBrand,
                  logOp: "and",
                },
              ]}
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtMachineLoad"
              label="Machine Load Capacity"
              value={this.state.formData.txtMachineLoad}
              handleOnChange={this.handleOnChange}
              width={220}
              maxLength={10}
              onKeyDown={this.props.onKeyDown}
              nextCtrlID={"txtMachineSpeed"}
              disabled={true}
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtMachineSpeed"
              label="Machine Speed"
              value={this.state.formData.txtMachineSpeed}
              handleOnChange={this.handleOnChange}
              width={220}
              maxLength={10}
              disabled={true}
            />
          </Grid>
        </Grid>
      </form>
    );
  };

  doorInfo = () => {
    // let doorTypeManual = this.state.formData.cmbDoorType === "MANUAL",
    //   doorTypeAuto = this.state.formData.cmbDoorType === "AUTO",
    //   doorRowsCount = this.state.doorRows.length;

    return (
      <Grid
        container
        direction={"row"}
        justifyContent={"flex-start"}
        spacing={2}
        marginTop={1}
      >
        <Grid item>
          <CtCmb
            id="cmbDoorType"
            label="Door Type*"
            items={this.state.DoorDetails}
            value={this.state.formData.cmbDoorType}
            handleOnChange={this.handleOnChange}
            width={210}
            maxLength={100}
            onKeyDown={this.props.onKeyDown}
            colID="vac_door"
            nextCtrlID={"cmbDoorStyle"}
          />
        </Grid>
        <Grid item>
          <CtCmb
            id="cmbDoorStyle"
            label="Door Style*"
            items={this.state.DoorDetails}
            value={this.state.formData.cmbDoorStyle}
            handleOnChange={this.handleOnChange}
            width={210}
            maxLength={100}
            onKeyDown={this.props.onKeyDown}
            colID="vac_doorstyle"
            nextCtrlID={"cmbDoorBrand"}
            dataFilter={[
              {
                filterColID: "vac_door",
                value: this.state.formData.cmbDoorType,
              },
            ]}
          />
        </Grid>
        <Grid item>
          <CtCmb
            id="cmbDoorBrand"
            label="Door Brand*"
            items={this.state.DoorDetails}
            value={this.state.formData.cmbDoorBrand}
            handleOnChange={this.handleOnChange}
            width={210}
            maxLength={100}
            onKeyDown={this.props.onKeyDown}
            colID="vac_doorbrand"
            nextCtrlID={"cmbDoorOpeningWidth"}
            dataFilter={[
              {
                filterColID: "vac_door",
                value: this.state.formData.cmbDoorType,
                logOp: "and",
              },
              {
                filterColID: "vac_doorstyle",
                value: this.state.formData.cmbDoorStyle,
                logOp: "and",
              },
            ]}
          />
        </Grid>
        <Grid item>
          <CtCmbEditable
            id="cmbDoorOpeningWidth"
            label="Door Opening Width*"
            items={this.state.DoorOpeningWidth}
            value={this.state.formData.cmbDoorOpeningWidth}
            handleOnChange={this.handleOnChange}
            width={210}
            maxLength={100}
            onKeyDown={this.props.onKeyDown}
            colID="vac_door_opening"
            nextCtrlID={
              this.state.formData.cmbDoorType == "MANUAL"
                ? "cmbMsDoorType"
                : "cmbDoorEntranceHeight"
            }
          />
        </Grid>
        {this.state.formData.cmbDoorType == "MANUAL" && (
          <Grid item>
            <CtCmb
              id="cmbMsDoorType"
              label="Ms Door Frame By Top"
              items={this.state.YesNo}
              value={this.state.formData.cmbMsDoorType}
              handleOnChange={this.handleOnChange}
              width={210}
              maxLength={100}
              onKeyDown={this.props.onKeyDown}
              colID="type"
              nextCtrlID={"cmbDoorEntranceHeight"}
            />
          </Grid>
        )}
        <Grid item>
          <CtCmbEditable
            id="cmbDoorEntranceHeight"
            label="Door Entrance Height*"
            items={this.state.DoorEntranceHeight}
            value={this.state.formData.cmbDoorEntranceHeight}
            handleOnChange={this.handleOnChange}
            width={210}
            maxLength={100}
            onKeyDown={this.props.onKeyDown}
            colID="vac_type"
            nextCtrlID={
              this.state.formData.cmbDoorType == "AUTO"
                ? "cmbDoorFrameSize"
                : ""
            }
          />
        </Grid>

        {this.state.formData.cmbDoorType == "AUTO" && (
          <>
            <Grid item>
              <CtCmbEditable
                id="cmbDoorFrameSize"
                label="Door Frame Size"
                items={this.state.DoorFrameSize}
                value={this.state.formData.cmbDoorFrameSize}
                handleOnChange={this.handleOnChange}
                width={210}
                maxLength={100}
                onKeyDown={this.props.onKeyDown}
                colID="vac_frame"
                nextCtrlID={"cmbDoorMaterial"}
              />
            </Grid>
            <Grid item>
              <CtCmb
                id="cmbDoorMaterial"
                label="Door Material*"
                items={this.state.DoorMaterial}
                value={this.state.formData.cmbDoorMaterial}
                handleOnChange={this.handleOnChange}
                width={210}
                maxLength={100}
                onKeyDown={this.props.onKeyDown}
                colID="vac_doormaterialgrade"
                nextCtrlID={"cmbGlassDoor"}
              />
            </Grid>
            <Grid item>
              <CtCmb
                id="cmbGlassDoor"
                label="Glass Door*"
                items={this.state.YesNo}
                value={this.state.formData.cmbGlassDoor}
                handleOnChange={this.handleOnChange}
                width={210}
                maxLength={100}
                onKeyDown={this.props.onKeyDown}
                colID="type"
              />
            </Grid>
          </>
        )}
      </Grid>
    );
  };

  cabinInfo = () => {
    return (
      <from>
        <Grid
          container
          direction={"row"}
          justifyContent={"flex-start"}
          spacing={2}
          marginTop={1}
        >
          <Grid item>
            <CtTxtAmt
              id="txtHeightOfCabin"
              value={this.state.formData.txtHeightOfCabin}
              label="Height Of Cabin"
              handleOnChange={this.handleOnChange}
              maxLength={100}
              width={230}
              onKeyDown={this.props.onKeyDown}
              nextCtrlID={"cmbFlooringOfCabin"}
              disabled={true}
            />
          </Grid>
          <Grid item>
            <CtCmb
              id="cmbFlooringOfCabin"
              label="Flooring Of Cabin*"
              items={this.state.FlooringOfCabin}
              value={this.state.formData.cmbFlooringOfCabin}
              handleOnChange={this.handleOnChange}
              width={230}
              maxLength={100}
              onKeyDown={this.props.onKeyDown}
              colID="vac_type"
            />
          </Grid>
          <Grid item>
            <CtCmb
              id="cmbMaterialOfCabinPanels"
              label="Material Of Cabin Panels*"
              items={this.state.MaterialOfCabinPanels}
              value={this.state.formData.cmbMaterialOfCabinPanels}
              handleOnChange={this.handleOnChange}
              width={230}
              maxLength={100}
              onKeyDown={this.props.onKeyDown}
              colID="vac_doormaterialgrade"
            />
          </Grid>
          <Grid item>
            <CtCmb
              id="cmbCabinPanelThickness"
              label="Cabin Panel Thickness (in mm)*"
              items={this.state.CabinPanelThickness}
              value={this.state.formData.cmbCabinPanelThickness}
              handleOnChange={this.handleOnChange}
              width={230}
              maxLength={100}
              colID="vac_type"
            />
          </Grid>
        </Grid>
      </from>
    );
  };

  controllerInfo = () => {
    return (
      <Grid
        container
        direction={"row"}
        justifyContent={"flex-start"}
        spacing={2}
        marginTop={1}
      >
        <Grid item>
          <CtCmb
            id="cmbDriveType"
            label="Drive Type*"
            items={this.state.DriveName}
            value={this.state.formData.cmbDriveType}
            handleOnChange={this.handleOnChange}
            width={220}
            maxLength={100}
            onKeyDown={this.props.onKeyDown}
            colID="vac_drivetype"
          />
        </Grid>
        <Grid item>
          <CtCmb
            id="cmbCommunicationType"
            label="Communication Type*"
            items={this.state.DriveName}
            value={this.state.formData.cmbCommunicationType}
            handleOnChange={this.handleOnChange}
            width={220}
            maxLength={100}
            onKeyDown={this.props.onKeyDown}
            colID="vac_communicationtype"
            dataFilter={[
              {
                filterColID: "vac_drivetype",
                value: this.state.formData.cmbDriveType,
              },
            ]}
          />
        </Grid>
        <Grid item>
          <CtCmb
            id="cmbDriveName"
            label="Drive Name*"
            items={this.state.DriveName}
            value={this.state.formData.cmbDriveName}
            handleOnChange={this.handleOnChange}
            width={220}
            maxLength={100}
            onKeyDown={this.props.onKeyDown}
            colID="vac_drivename"
            dataFilter={[
              {
                filterColID: "vac_drivetype",
                value: this.state.formData.cmbDriveType,
              },
              {
                filterColID: "vac_communicationtype",
                value: this.state.formData.cmbCommunicationType,
              },
            ]}
          />
        </Grid>
        <Grid item>
          <CtCmb
            id="cmbCOPLOPType"
            label="COP LOP Type*"
            items={this.props.ButtonType}
            value={this.state.formData.cmbCOPLOPType}
            handleOnChange={this.handleOnChange}
            width={220}
            maxLength={100}
            onKeyDown={this.props.onKeyDown}
            colID="vac_button_type"
          />
        </Grid>
        <Grid item>
          <CtCmb
            id="cmbControllerType"
            label="Controller Type*"
            items={this.state.ControllerType}
            value={this.state.formData.cmbControllerType}
            handleOnChange={this.handleOnChange}
            width={220}
            maxLength={100}
            onKeyDown={this.props.onKeyDown}
            colID="vac_type"
          />
        </Grid>
        <Grid item>
          <CtCmb
            id="cmbCOPLOPDisplayType"
            label="COP & LOP Display Type*"
            items={this.state.COPLOPDisplayType}
            value={this.state.formData.cmbCOPLOPDisplayType}
            handleOnChange={this.handleOnChange}
            width={220}
            maxLength={100}
            onKeyDown={this.props.onKeyDown}
            colID="vac_display_type"
          />
        </Grid>
        <Grid item>
          <CtCmb
            id="cmbInbuiltBiometricsDevice"
            label="Inbuilt Biometrics Device*"
            items={this.state.InbuiltBiometricsDevice}
            value={this.state.formData.cmbInbuiltBiometricsDevice}
            handleOnChange={this.handleOnChange}
            width={220}
            maxLength={100}
            onKeyDown={this.props.onKeyDown}
            colID="vac_type"
          />
        </Grid>
      </Grid>
    );
  };

  nextBtnClick = () => {
    let tabNum = this.state.formData.value,
      currTabNum = this.state.formData.value;
    if (currTabNum == "1") {
      if (this.validateBasicPriceCalculation()) {
        this.savePriceCalculator();
        tabNum = "2";
      }
    } else if (currTabNum == "2") {
      if (this.validateMachinePriceCalculation()) {
        this.savePriceCalculator();
        tabNum = "3";
      }
    } else if (currTabNum == "3") {
      if (this.validateDoorPriceCalculation()) {
        this.savePriceCalculator();
        tabNum = "4";
      }
    } else if (currTabNum == "4") {
      if (this.validateCabinPriceCalculation()) {
        this.savePriceCalculator();
        tabNum = "5";
      }
    } else if (currTabNum == "5") {
      if (this.validateControllerPriceCalculation()) {
        this.savePriceCalculator();
      }
    }

    this.changeBtnName(tabNum);

    this.setState({
      formData: {
        ...this.state.formData,
        value: tabNum,
      },
    });
  };

  previousBtnClick = () => {
    let tabNum = "",
      currTabNum = this.state.formData.value;
    if (currTabNum == "2") {
      tabNum = "1";
    } else if (currTabNum == "3") {
      tabNum = "2";
    } else if (currTabNum == "4") {
      tabNum = "3";
    } else if (currTabNum == "5") {
      tabNum = "4";
    } else if (currTabNum == "6") {
      tabNum = "5";
    }

    this.changeBtnName(tabNum);

    this.setState({
      formData: {
        ...this.state.formData,
        value: tabNum,
      },
    });
  };

  changeBtnName = (newValue) => {
    let nextBtnName = "Continue To Machine Details",
      previousBtnName = "";
    if (newValue === "2") {
      nextBtnName = "Continue Door Details";
      previousBtnName = "Basic Details";
    } else if (newValue === "3") {
      nextBtnName = "Continue Cabin Details";
      previousBtnName = "Machine Details";
    } else if (newValue === "4") {
      nextBtnName = "Continue Controller & Wiring Details";
      previousBtnName = "Door Details";
    } else if (newValue === "5") {
      nextBtnName = "Save";
      previousBtnName = "Cabin Details";
    }

    this.setState({
      nextBtnName,
      previousBtnName,
    });
  };

  trimFormData = (nextFunctionCall) => {
    let formData = applyTrimOnObjectValues({
      formData: this.state.formData,
    });
    this.setState({ formData }, () => {
      if (nextFunctionCall) {
        nextFunctionCall();
      }
    });
  };

  setStateFunCtion = (newValue) => {
    this.setState(
      {
        formData: { ...this.state.formData, value: newValue },
      },
      () => {
        this.changeBtnName(newValue);
      }
    );
  };

  validateBasicPriceCalculation = () => {
    let result = false;

    if (this.state.formData.cmbJobType.length <= 0) {
      this.props.toastErrorMsg("Select Job Type", "cmbJobType");
    } else if (this.state.formData.cmbElevatorType.length <= 0) {
      this.props.toastErrorMsg("Select Elevator Type", "cmbElevatorType");
    } else if (
      this.state.formData.cmbElevatorType === "PASSENGER" &&
      (Number(this.state.formData.txtPerson) <= 0 ||
        Number(this.state.formData.txtLoadCapacity) <= 0)
    ) {
      if (Number(this.state.formData.txtPerson) <= 0) {
        this.props.toastErrorMsg("Select Number Of Person", "txtPerson");
      } else if (Number(this.state.formData.txtLoadCapacity) <= 0) {
        this.props.toastErrorMsg("Enter Load Capacity", "txtLoadCapacity");
      }
    } else if (
      isNaN(Number(this.state.formData.txtPerson)) ||
      (Number(this.state.formData.txtPerson) > 0 &&
        !isValidItem(
          this.state.Person,
          "dec_passenger",
          this.state.formData.txtPerson
        ))
    ) {
      this.props.toastErrorMsg("Select Valid Number Of Person", "txtPerson");
    } else if (
      this.state.formData.cmbElevatorType === "GOODS" &&
      Number(this.state.formData.cmbLoadCapacity) <= 0
    ) {
      if (Number(this.state.formData.cmbLoadCapacity) <= 0) {
        this.props.toastErrorMsg("Select Load Capacity", "cmbLoadCapacity");
      }
    } else if (
      isNaN(Number(this.state.formData.txtPerson)) ||
      (Number(this.state.formData.cmbLoadCapacity) > 0 &&
        !isValidItem(
          this.state.LoadCapacity,
          "dec_passenger",
          this.state.formData.cmbLoadCapacity
        ))
    ) {
      this.props.toastErrorMsg("Select Valid Load Capacity", "cmbLoadCapacity");
    } else if (this.state.formData.cmbElevatorSpeed.length <= 0) {
      this.props.toastErrorMsg(
        "Select Elevator Speed (IN mps)",
        "cmbElevatorSpeed"
      );
    } else if (this.state.formData.cmbReverse.length <= 0) {
      this.props.toastErrorMsg("Select Reverse / Side Opening", "cmbReverse");
    } else if (this.state.formData.txtStops.length <= 0) {
      this.props.toastErrorMsg("Enter Stops", "txtStops");
    } else if (this.state.formData.txtOpenings.length <= 0) {
      this.props.toastErrorMsg("Enter Openings", "txtOpenings");
    } else if (this.state.formData.cmbMRL.length <= 0) {
      this.props.toastErrorMsg("Select MRL", "cmbMRL");
    } else if (this.state.formData.cmbARD.length <= 0) {
      this.props.toastErrorMsg("Select ARD", "cmbARD");
    } else if (this.state.formData.cmbOLNS.length <= 0) {
      this.props.toastErrorMsg("Select OLNS", "cmbOLNS");
    } else if (this.state.formData.cmbComChain.length <= 0) {
      this.props.toastErrorMsg("Select Com Chain", "cmbComChain");
    } else if (this.state.formData.cmbRoping.length <= 0) {
      this.props.toastErrorMsg("Select Roping", "cmbRoping");
    } else if (this.state.formData.cmbProgressiveSafety.length <= 0) {
      this.props.toastErrorMsg(
        "Select Progressive Safety",
        "cmbProgressiveSafety"
      );
    } else {
      result = true;
    }
    return result;
  };

  validateMachinePriceCalculation = () => {
    let result = false;

    if (this.state.formData.cmbMachineType.length <= 0) {
      this.props.toastErrorMsg("Select Machine Type", "cmbMachineType");
    } else if (this.state.formData.cmbMachineBrand.length <= 0) {
      this.props.toastErrorMsg("Select Machine Brand", "cmbMachineBrand");
    } else if (
      this.state.formData.cmbMachineBrand.length > 0 &&
      !isValidItem(
        this.state.MachineBrand,
        "vac_machinemake",
        this.state.formData.cmbMachineBrand
      )
    ) {
      this.props.toastErrorMsg("Select Valid Machine Brand", "cmbMachineBrand");
    } else if (this.state.formData.cmbModelNo.length <= 0) {
      this.props.toastErrorMsg("Select Model No", "cmbModelNo");
    } else if (
      this.state.formData.cmbModelNo.length > 0 &&
      !isValidItem(
        this.state.MachineBrand,
        "vac_model",
        this.state.formData.cmbModelNo
      )
    ) {
      this.props.toastErrorMsg("Select Valid Model No", "cmbModelNo");
    } else {
      result = true;
    }
    return result;
  };

  validateDoorPriceCalculation = () => {
    let result = false;

    if (this.state.formData.cmbDoorType.length <= 0) {
      this.props.toastErrorMsg("Select Door Type", "cmbDoorType");
    } else if (this.state.formData.cmbDoorStyle.length <= 0) {
      this.props.toastErrorMsg("Select Door Style", "cmbDoorStyle");
    } else if (this.state.formData.cmbDoorBrand.length <= 0) {
      this.props.toastErrorMsg("Select Door Brand", "cmbDoorBrand");
    } else if (Number(this.state.formData.cmbDoorOpeningWidth) <= 0) {
      this.props.toastErrorMsg(
        "Select Door Opening Width",
        "cmbDoorOpeningWidth"
      );
    } else if (
      isNaN(Number(this.state.formData.cmbDoorOpeningWidth)) ||
      (Number(this.state.formData.cmbDoorOpeningWidth) > 0 &&
        !isValidItem(
          this.state.DoorOpeningWidth,
          "vac_door_opening",
          this.state.formData.cmbDoorOpeningWidth
        ))
    ) {
      this.props.toastErrorMsg(
        "Select Valid Door Opening Width",
        "cmbDoorOpeningWidth"
      );
    } else if (Number(this.state.formData.cmbDoorEntranceHeight) <= 0) {
      this.props.toastErrorMsg(
        "Select Valid Door Entrance Height",
        "cmbDoorEntranceHeight"
      );
    } else if (
      this.state.formData.cmbDoorType == "AUTO" &&
      this.state.formData.cmbDoorMaterial.length <= 0
    ) {
      this.props.toastErrorMsg("Select Door Material", "cmbDoorMaterial");
    } else if (
      this.state.formData.cmbDoorType == "AUTO" &&
      this.state.formData.cmbGlassDoor.length <= 0
    ) {
      this.props.toastErrorMsg("Select Glass Door", "cmbGlassDoor");
    } else {
      result = true;
    }
    return result;
  };

  validateCabinPriceCalculation = () => {
    let result = false;

    if (this.state.formData.cmbFlooringOfCabin.length <= 0) {
      this.props.toastErrorMsg(
        "Select Flooring Of Cabin",
        "cmbFlooringOfCabin"
      );
    } else if (this.state.formData.cmbMaterialOfCabinPanels.length <= 0) {
      this.props.toastErrorMsg(
        "Select Material Of Cabin Panels",
        "cmbMaterialOfCabinPanels"
      );
    } else if (this.state.formData.cmbCabinPanelThickness.length <= 0) {
      this.props.toastErrorMsg(
        "Select Cabin Panel Thickness",
        "cmbCabinPanelThickness"
      );
    } else {
      result = true;
    }
    return result;
  };

  validateControllerPriceCalculation = () => {
    let result = false;

    if (this.state.formData.cmbDriveType.length <= 0) {
      this.props.toastErrorMsg("Select Drive Type", "cmbDriveType");
    } else if (this.state.formData.cmbCommunicationType.length <= 0) {
      this.props.toastErrorMsg(
        "Select Communication Type",
        "cmbCommunicationType"
      );
    } else if (this.state.formData.cmbDriveName.length <= 0) {
      this.props.toastErrorMsg("Select Drive Name", "cmbDriveName");
    } else if (this.state.formData.cmbCOPLOPType.length <= 0) {
      this.props.toastErrorMsg("Select COP LOP Type", "cmbCOPLOPType");
    } else if (this.state.formData.cmbControllerType.length <= 0) {
      this.props.toastErrorMsg("Select Controller Type", "cmbControllerType");
    } else if (this.state.formData.cmbCOPLOPDisplayType.length <= 0) {
      this.props.toastErrorMsg(
        "Select COP LOP DisplayType",
        "cmbCOPLOPDisplayType"
      );
    } else if (this.state.formData.cmbInbuiltBiometricsDevice.length <= 0) {
      this.props.toastErrorMsg(
        "Select Inbuilt Biometrics Device",
        "cmbInbuiltBiometricsDevice"
      );
    } else {
      result = true;
    }
    return result;
  };

  validatePriceCalculation = () => {
    let result = false;
    if (this.state.formData.txtSiteName.length <= 0) {
      this.props.toastErrorMsg("Enter Site Name", "txtSiteName");
    } else if (!this.validateBasicPriceCalculation()) {
    } else if (!this.validateDoorPriceCalculation()) {
    } else if (!this.validateMachinePriceCalculation()) {
    } else if (!this.validateCabinPriceCalculation()) {
    } else if (!this.validateControllerPriceCalculation()) {
    } else {
      // console.log("inside else");
      result = true;
    }
    // console.log("result", result);
    return result;
  };

  save = () => {
    let value = this.state.formData.value,
      allConditionsPassed = true,
      list = false,
      showPriceSummary = false;
    if (this.state.formData.txtSiteName.length <= 0) {
      this.props.toastErrorMsg("Enter Site Name", "txtSiteName");
      allConditionsPassed = false;
    } else if (
      this.state.formData.cmbJobType.length <= 0 ||
      this.state.formData.cmbElevatorType.length <= 0 ||
      (this.state.formData.cmbElevatorType === "PASSENGER" &&
        (Number(this.state.formData.txtPerson) <= 0 ||
          Number(this.state.formData.txtLoadCapacity) <= 0)) ||
      isNaN(Number(this.state.formData.txtPerson)) ||
      (Number(this.state.formData.txtPerson) > 0 &&
        !isValidItem(
          this.state.Person,
          "dec_passenger",
          this.state.formData.txtPerson
        )) ||
      (this.state.formData.cmbElevatorType === "GOODS" &&
        Number(this.state.formData.cmbLoadCapacity) <= 0) ||
      isNaN(Number(this.state.formData.txtPerson)) ||
      (Number(this.state.formData.cmbLoadCapacity) > 0 &&
        !isValidItem(
          this.state.LoadCapacity,
          "dec_passenger",
          this.state.formData.cmbLoadCapacity
        )) ||
      this.state.formData.cmbElevatorSpeed.length <= 0 ||
      this.state.formData.cmbReverse.length <= 0 ||
      this.state.formData.txtStops.length <= 0 ||
      this.state.formData.txtOpenings.length <= 0 ||
      this.state.formData.cmbMRL.length <= 0 ||
      this.state.formData.cmbARD.length <= 0 ||
      this.state.formData.cmbOLNS.length <= 0 ||
      this.state.formData.cmbComChain.length <= 0 ||
      this.state.formData.cmbRoping.length <= 0 ||
      this.state.formData.cmbProgressiveSafety.length <= 0
    ) {
      value = "1";
      allConditionsPassed = false;
    } else if (
      this.state.formData.cmbMachineType.length <= 0 ||
      this.state.formData.cmbMachineBrand.length <= 0 ||
      (this.state.formData.cmbMachineBrand.length > 0 &&
        !isValidItem(
          this.state.MachineBrand,
          "vac_machinemake",
          this.state.formData.cmbMachineBrand
        )) ||
      this.state.formData.cmbModelNo.length <= 0 ||
      (this.state.formData.cmbModelNo.length > 0 &&
        !isValidItem(
          this.state.MachineBrand,
          "vac_model",
          this.state.formData.cmbModelNo
        ))
    ) {
      value = "2";
      // console.log("value", value);
      allConditionsPassed = false;
    } else if (
      this.state.formData.cmbDoorType.length <= 0 ||
      this.state.formData.cmbDoorStyle.length <= 0 ||
      this.state.formData.cmbDoorBrand.length <= 0 ||
      Number(this.state.formData.cmbDoorOpeningWidth) <= 0 ||
      isNaN(Number(this.state.formData.cmbDoorOpeningWidth)) ||
      (Number(this.state.formData.cmbDoorOpeningWidth) > 0 &&
        !isValidItem(
          this.state.DoorOpeningWidth,
          "vac_door_opening",
          this.state.formData.cmbDoorOpeningWidth
        )) ||
      Number(this.state.formData.cmbDoorEntranceHeight) <= 0 ||
      (this.state.formData.cmbDoorType == "AUTO" &&
        this.state.formData.cmbDoorMaterial.length <= 0) ||
      (this.state.formData.cmbDoorType == "AUTO" &&
        this.state.formData.cmbGlassDoor.length <= 0)
    ) {
      value = "3";
      allConditionsPassed = false;
    } else if (
      this.state.formData.cmbFlooringOfCabin.length <= 0 ||
      this.state.formData.cmbMaterialOfCabinPanels.length <= 0 ||
      this.state.formData.cmbCabinPanelThickness.length <= 0
    ) {
      value = "4";
      allConditionsPassed = false;
    } else if (
      this.state.formData.cmbDriveType.length <= 0 ||
      this.state.formData.cmbCommunicationType.length <= 0 ||
      this.state.formData.cmbDriveName.length <= 0 ||
      this.state.formData.cmbCOPLOPType.length <= 0 ||
      this.state.formData.cmbControllerType.length <= 0 ||
      this.state.formData.cmbCOPLOPDisplayType.length <= 0 ||
      this.state.formData.cmbInbuiltBiometricsDevice.length <= 0
    ) {
      value = "5";
      allConditionsPassed = false;
    }

    if (allConditionsPassed) {
      list = true;
      showPriceSummary = true;
    }

    this.setState(
      {
        formData: {
          ...this.state.formData,
          value: value,
          showPriceSummary,
        },
      },
      () => {
        // console.log("this.state.formData.value", this.state.formData.value);
        this.changeBtnName(this.state.formData.value);
        if (value == "1") {
          this.validateBasicPriceCalculation();
          this.savePriceCalculator(list);
        } else if (value == "2") {
          // console.log("call 2");
          this.validateMachinePriceCalculation();
          this.savePriceCalculator(list);
        } else if (value == "3") {
          this.validateDoorPriceCalculation();
          this.savePriceCalculator(list);
        } else if (value == "4") {
          this.validateCabinPriceCalculation();
          this.savePriceCalculator(list);
        } else if (value == "5") {
          this.validateControllerPriceCalculation();
          this.savePriceCalculator(list);
        }
      }
    );
  };

  savePriceCalculator = (OutPutList = false) => {
    this.trimFormData(() => {
      // if (this.validatePriceCalculation()) {
      this.props.notifyProcessing();
      let dec_loadcapacitykg =
          this.state.formData.cmbElevatorType == "GOODS"
            ? this.state.formData.cmbLoadCapacity
            : this.state.formData.txtLoadCapacity,
        BasicInfo = [
          {
            vac_jobtype: this.state.formData.cmbJobType,
            vac_elevatortype: this.state.formData.cmbElevatorType,
            bint_person: this.state.formData.txtPerson,

            dec_loadcapacitykg: dec_loadcapacitykg,
            dec_elevatorspeed: this.state.formData.cmbElevatorSpeed,
            vac_sideopenig: this.state.formData.cmbReverse,
            bint_basement: this.state.formData.txtBasement,
            bint_groundfloor:
              this.state.formData.cmbGroundFloor.length > 0 &&
              this.state.formData.cmbGroundFloor === "YES"
                ? 1
                : 0,
            bint_floor: this.state.formData.txtFloors,
            bint_stop: this.state.formData.txtStops,
            bint_openings: this.state.formData.txtOpenings,
            bint_pit_rise_oh: this.state.formData.txtPit,
            bint_custom_pit_rise_oh: this.state.formData.txtPitCustom,
            vac_mrl: this.state.formData.cmbMRL,
            vac_ard: this.state.formData.cmbARD,
            vac_olns: this.state.formData.cmbOLNS,
            vac_comchain: this.state.formData.cmbComChain,
            vac_roping: this.state.formData.cmbRoping,
            vac_cw_position: this.state.formData.cmbPosition,
            vac_progressivesafety: this.state.formData.cmbProgressiveSafety,
            vac_car_railtype: this.state.formData.vac_specialcar_railtype,
            vac_cw_railtype: this.state.formData.vac_specialCW_railtype,
            vac_specialcar_railtype:
              this.state.formData.vac_specialcar_railtype,
            vac_specialCW_railtype: this.state.formData.vac_specialCW_railtype,
          },
        ];
      const reqData = {
        Op: "SavePriceCalculation",
        vac_edit_inquery_no: this.state.formData.vac_edit_inquery_no,
        vac_edit_revision_no: this.state.formData.vac_edit_revision_no,
        dat_edit_inquery_date: this.state.formData.dat_edit_inquery_date,
        dat_inquery_date: this.state.formData.dtpInquery,
        vac_sitename: this.state.formData.txtSiteName,
        vac_show_price_icon: this.state.formData.showPriceSummary,
        BasicInfo: BasicInfo,
        machinedetails: [
          {
            vac_machinetype: this.state.formData.cmbMachineType,
            vac_machinebrand: this.state.formData.cmbMachineBrand,
            vac_modelno: this.state.formData.cmbModelNo,
            bint_machineloadcapacity: this.state.formData.txtMachineLoad,
            bint_machinespeed: this.state.formData.txtMachineSpeed,
          },
        ],
        doordetails: [
          {
            vac_doortype: this.state.formData.cmbDoorType,
            vac_doorstyle: this.state.formData.cmbDoorStyle,
            vac_doorbrand: this.state.formData.cmbDoorBrand,
            bint_dooropeningwidth: this.state.formData.cmbDoorOpeningWidth,
            vac_msdoorframebytop: this.state.formData.cmbMsDoorType,
            bint_doorentranceheight: this.state.formData.cmbDoorEntranceHeight,
            vac_doorframsize: this.state.formData.cmbDoorFrameSize,
            vac_doormaterial: this.state.formData.cmbDoorMaterial,
            vac_glassdoor: this.state.formData.cmbGlassDoor,
          },
        ],
        cabindetails: [
          {
            dec_cabinheight: this.state.formData.txtHeightOfCabin,
            vac_cabinflooring: this.state.formData.cmbFlooringOfCabin,
            vac_cabinpanelmaterial:
              this.state.formData.cmbMaterialOfCabinPanels,
            dec_cabinpanelthickness: this.state.formData.cmbCabinPanelThickness,
          },
        ],
        controllerdetails: [
          {
            vac_drivetype: this.state.formData.cmbDriveType,
            vac_communication: this.state.formData.cmbCommunicationType,
            vac_drivename: this.state.formData.cmbDriveName,
            vac_coploptype: this.state.formData.cmbCOPLOPType,
            vac_controllertype: this.state.formData.cmbControllerType,
            vac_coplopdisplaytype: this.state.formData.cmbCOPLOPDisplayType,
            vac_inbuiltbiometricsdevice:
              this.state.formData.cmbInbuiltBiometricsDevice,
          },
        ],
        bol_OutPutList: OutPutList,
        Extra1: this.state.Extra1,
        Extra2: this.state.Extra2,
        SalesProfitPercentage: this.state.SalesProfitPercentage,
      };
      fetchpricecalculation(reqData)
        .then((res) => {
          this.props.updateProcessing(res.data.msgType, res.data.message);
          if (res.data.msgType === "success") {
            let rows = [],
              rowsCount = rows.length,
              txtInqueryNo = "",
              ListPOutAllSummary =
                res.data.ListPOutAllSummary &&
                res.data.ListPOutAllSummary.length > 0
                  ? res.data.ListPOutAllSummary
                  : [],
              PriceSummary =
                res.data.PriceSummary && res.data.PriceSummary.length > 0
                  ? res.data.PriceSummary
                  : [];
            if (res.data.RailPriceList && res.data.RailPriceList.length > 0) {
              rows = res.data.RailPriceList;
              rowsCount = rows.length;
            }

            txtInqueryNo =
              res.data.Inquery_No && res.data.Inquery_No.length > 0
                ? res.data.Inquery_No
                : "";

            this.setState(
              {
                formData: {
                  ...this.state.formData,
                  txtInqueryNo,
                  SellingPrice: res.data.SellingPrice,
                  vac_edit_inquery_no: txtInqueryNo,
                  vac_edit_revision_no: res.data.Revision_No,
                  dat_edit_inquery_date: this.state.formData.dtpInquery,
                  chkCarRail:
                    this.state.formData.chkCarRail == "false" ||
                    this.state.formData.chkCarRail == false
                      ? false
                      : true,
                  chkCwRail82:
                    this.state.formData.chkCwRail82 == "false" ||
                    this.state.formData.chkCwRail82 == false
                      ? false
                      : true,
                  chkCwRail:
                    this.state.formData.chkCwRail == "false" ||
                    this.state.formData.chkCwRail == false
                      ? false
                      : true,
                },
                rows,
                rowsCount,
                ListPOutAllSummary,
                PriceSummary,
              } /* ,
                () => {
                  if (detaildOutput) {
                    this.props.setPriceCalculation({
                      ...reqData,
                      SellingPrice: this.state.formData.SellingPrice,
                      Person: this.state.Person,
                      LoadCapacity: this.state.LoadCapacity,
                    });
                    // this.props.navigateTo("OutputPaeameters", {
                    //   ListPOutAllSummary,
                    //   SiteName: this.state.formData.txtSiteName,
                    //   DateTime: get_DMY_from_YMD(
                    //     this.state.formData.dtpInquery
                    //   ),
                    // });
                  }
                } */
            );
          }
        })
        .catch((error) => {
          console.log(
            "Unknown error occurred in Save Price Calculation .",
            error
          );
        });
      // }
    });
  };

  setAmtPer = (Extra1, Extra2, SalesProfitPercentage) => {
    this.setState({
      Extra1,
      Extra2,
      SalesProfitPercentage,
    });
  };
  render() {
    let TabStyle = {
      padding: "0px",
    };
    let ArrowHideInSave = this.state.nextBtnName == "Save" ? true : false;

    // console.log("this.state.ListPOutAllSummary", this.state.ListPOutAllSummary);

    // console.log("this.props.location.state", this.props.location.state);
    // console.log("this.state.Person", this.state.Person);
    // console.log(
    //   "chkCarRail, chkCwRail82, chkCwRail",
    //   this.state.formData.chkCarRail,
    //   this.state.formData.chkCwRail82,
    //   this.state.formData.chkCwRail
    // );
    // console.log("this.props.PriceCalculation", this.props.PriceCalculation);
    return (
      <div>
        <Grid container justifyContent={"space-between"} spacing={2}>
          <Grid item>
            <CtBtn
              label={"Price Calculator List"}
              iconName={"List"}
              className={"child-button"}
              variant={"outlined"}
              firstletterBig={false}
              onClick={() => {
                this.props.setPriceCalculation({});
                this.props.navigateTo("InputParametersList");
              }}
            />
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <CtBtn
                  className="secondary-button"
                  label="Save"
                  iconName="save"
                  onClick={() => {
                    if (this.state.formData.dtpInquery.length <= 0) {
                      this.props.toastErrorMsg("Enter Date", "dtpInquery");
                    } else if (
                      validatedate_min_max(
                        this.state.formData.dtpInquery,
                        "dtpInquery",
                        this.props.toastErrorMsg
                      ) == false
                    ) {
                    } else if (this.state.formData.txtSiteName.length <= 0) {
                      this.props.toastErrorMsg(
                        "Enter Site Name",
                        "txtSiteName"
                      );
                    } else {
                      this.save();
                    }
                  }}
                  disabled={
                    this.state.EditData.length > 0 &&
                    this.props.userPrivilege["Entry:Price Calculator"] != "M"
                  }
                />
              </Grid>
              {/* <Grid item>
                <CtBtn
                  variant={"outlined"}
                  label="Price Summary"
                  className="child-button"
                  // iconName="new"
                  firstletterBig="false"
                  onClick={() => {
                    this.savePriceCalculator();
                  }}
                />
              </Grid>
              <Grid item>
                <CtBtn
                  variant={"outlined"}
                  label="DETAILED Output"
                  className="child-button"
                  // iconName="new"
                  firstletterBig="false"
                  onClick={() => {
                    this.savePriceCalculator();
                  }}
                />
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent={"flex-start"} spacing={2} marginTop={0}>
          <Grid item>
            <CtTxt
              id="txtInqueryNo"
              value={this.state.formData.txtInqueryNo}
              label="Inquery No"
              handleOnChange={this.handleOnChange}
              maxLength={50}
              width={250}
              disabled={true}
            />
          </Grid>
          <Grid item>
            <CtDtp
              id="dtpInquery"
              value={this.state.formData.dtpInquery}
              label="Inquery Date*"
              handleOnChange={this.handleOnChange}
              maxLength={50}
              onKeyDown={this.props.onKeyDown}
              // disabled={true}
              width={130}
              accountYear={localStorage.getItem("ACY")}
            />
          </Grid>
          <Grid item>
            <CtTxt
              id="txtSiteName"
              value={this.state.formData.txtSiteName}
              label="Site Name*"
              handleOnChange={this.handleOnChange}
              maxLength={100}
              width={230}
            />
          </Grid>
          <Grid item /* style={{ backgroundColor: "red" }} */>
            <Typography
              style={{
                paddingTop: "20px",

                fontSize: "18px",
              }}
            >
              Selling Price :<b> {this.state.formData.SellingPrice}</b>
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent={"space-between"} spacing={2}>
          <Grid
            item
            lg={this.state.ListPOutAllSummary.length > 0 ? 6 : 12}
            md={this.state.ListPOutAllSummary.length > 0 ? 6 : 12}
            sm={12}
            xs={12}
          >
            <Box sx={{ width: "100%", typography: "body1" }} marginTop={3}>
              <TabContext value={this.state.formData.value}>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    width: "100%",
                  }}
                >
                  <TabList
                    onChange={this.handleOnChangeTab}
                    aria-label="lab API tabs example"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab
                      label="Basic"
                      value="1"
                      TypographyProps={{ style: { fontWeight: "bold" } }}
                    />
                    <Tab
                      label="Machine Details"
                      value="2"
                      TypographyProps={{ style: { fontWeight: "bold" } }}
                    />
                    <Tab
                      label="Door Details"
                      value="3"
                      TypographyProps={{ style: { fontWeight: "bold" } }}
                    />
                    <Tab
                      label="Cabin Details"
                      value="4"
                      TypographyProps={{ style: { fontWeight: "bold" } }}
                    />
                    <Tab
                      label="Controller & wiring Details"
                      value="5"
                      TypographyProps={{ style: { fontWeight: "bold" } }}
                    />
                    {/* <Tab
                  label="Lift Specification"
                  value="6"
                  TypographyProps={{ style: { fontWeight: "bold" } }}
                /> */}
                  </TabList>
                </Box>
                <TabPanel value="1" style={TabStyle}>
                  {this.basicInfo()}
                </TabPanel>
                <TabPanel value="2" style={TabStyle}>
                  {this.machineRoom()}
                </TabPanel>
                <TabPanel value="3" style={TabStyle}>
                  {this.doorInfo()}
                </TabPanel>
                <TabPanel value="4" style={TabStyle}>
                  {this.cabinInfo()}
                </TabPanel>
                <TabPanel value="5" style={TabStyle}>
                  {this.controllerInfo()}
                </TabPanel>

                <Grid
                  container
                  justifyContent={"space-between"}
                  spacing={2}
                  marginTop={1}
                >
                  {this.state.formData.value != 1 ? (
                    <Grid item>
                      <CtBtn
                        label={this.state.previousBtnName}
                        iconName={"left"}
                        firstletterBig={false}
                        onClick={() => {
                          this.previousBtnClick();
                        }}
                      />
                    </Grid>
                  ) : (
                    ""
                  )}
                  {/* {this.state.formData.value != 5 ? ( */}
                  <Grid item>
                    <CtBtn
                      label={this.state.nextBtnName}
                      iconName={ArrowHideInSave ? "save" : "right"}
                      onClick={() => {
                        this.nextBtnClick();
                      }}
                      firstletterBig={false}
                      disabled={
                        this.state.EditData.length > 0 &&
                        this.props.userPrivilege["Entry:Price Calculator"] !=
                          "M"
                      }
                    />
                  </Grid>
                  {/* ) : (
                    ""
                  )} */}
                </Grid>
              </TabContext>
            </Box>
          </Grid>
          {this.state.ListPOutAllSummary.length > 0 ? (
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Box sx={{ width: "100%", typography: "body1" }} marginTop={3}>
                <TabContext value={this.state.formData.value2}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      width: "100%",
                    }}
                  >
                    <TabList
                      onChange={this.handleOnChangeTab2}
                      aria-label="lab API tabs example"
                      variant="scrollable"
                      scrollButtons="auto"
                    >
                      <Tab
                        label="Detailed Output"
                        value="1"
                        TypographyProps={{ style: { fontWeight: "bold" } }}
                      />
                      <Tab
                        label="Price Summary"
                        value="2"
                        TypographyProps={{ style: { fontWeight: "bold" } }}
                      />
                    </TabList>
                  </Box>
                  <TabPanel value="1" style={TabStyle}>
                    <div
                      style={{
                        maxHeight: "100vh",
                        overflowY: "auto",
                        padding: "10px",
                      }}
                    >
                      <OutputPaeameters
                        ListPOutAllSummary={this.state.ListPOutAllSummary}
                      />
                    </div>
                  </TabPanel>
                  <TabPanel value="2" style={TabStyle}>
                    <SummaryDetaild
                      ListPOutAllSummary={this.state.ListPOutAllSummary}
                      PriceSummary={this.state.PriceSummary}
                      setAmtPer={this.setAmtPer}
                    />
                  </TabPanel>

                  {/* <Grid
                  container
                  justifyContent={"space-between"}
                  spacing={2}
                  marginTop={1}
                >
                  {this.state.formData.value != 1 ? (
                    <Grid item>
                      <CtBtn
                        label={this.state.previousBtnName}
                        iconName={"left"}
                        firstletterBig={false}
                        onClick={() => {
                          this.previousBtnClick();
                        }}
                      />
                    </Grid>
                  ) : (
                    ""
                  )}
                  {this.state.formData.value != 5 ? (
                    <Grid item>
                      <CtBtn
                        label={this.state.nextBtnName}
                        iconName={ArrowHideInSave ? "save" : "right"}
                        onClick={() => {
                          this.nextBtnClick();
                        }}
                        firstletterBig={false}
                      />
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid> */}
                </TabContext>
              </Box>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </div>
    );
  }
}

// export default InputParametersEntry;

// export default HoCtToastContainer(withRouter(InputParametersEntry));

function WithNavigate(props) {
  let navigate = useNavigate();
  return <InputParametersEntry {...props} navigate={navigate} />;
}
export default connect(
  mapStatetoProps,
  mapSetUserPrivileges_ToProps
)(HoCtToastContainer(HOCVerifyIsUser(withRouter(WithNavigate))));
