import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import HOCVerifyIsUser from "../HOC/HOCVerifyIsUser";
import CtBtn from "../Components/CustomTool/CtBtn";
import CtTxt from "../Components/CustomTool/CtTxt";
import CtTextFieldPWD from "../Components/CustomTool/CtTxtPWD";
import CtCmb from "../Components/CustomTool/CtCmb";
import CtTxtNum from "../Components/CustomTool/CtTxtNum";
import MaterialTable from "../Components/CustomTool/MaterialTable";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import AlertResponsiveDialog from "../Components/CustomTool/AlertResponsiveDialog";
// import DepartmentMaster from "./DepartmentMaster";
import { Stack } from "@mui/material";
// import PlantMaster from "./PlantMaster";
// import { fetchUser } from "../Components/API";
import HoCtToastContainer from "../HOC/HoCtToastContainer";
import CtMultiSelect from "../Components/CustomTool/CtMultiSelect";
import {
  applyTrimOnObjectValues,
  csvFileName,
  getRowColData,
  getRowData,
  getRowOfRT,
  isValidItem,
  setKeyboardShortcuts,
} from "../SystemUtility/SystemUtility";
import {
  mapSetUserPrivileges_ToProps,
  mapStatetoProps,
} from "../mapRedux/mapReduxProps";
import { connect } from "react-redux";
import { withRouter } from "../HOC/withRouter";
import { fetchUser, fetchUserManagement } from "./API";
import MobileTable from "./CustomTool/MobileTable";
import CtART from "./CustomTool/ResponsiveTable";
import axios from "axios";
import CtCmbEditable from "./CustomTool/CtCmbEditable";

class UserManagement extends Component {
  state = {
    ERPRole: [
      ...(localStorage.getItem("ErpRole") === "Admin"
        ? [{ vac_erprole: "Admin" }]
        : []),
      { vac_erprole: "Proposal" },
      { vac_erprole: "JSD" },
      { vac_erprole: "Packing" },
    ],
    Branch: [],
    Status: [{ vac_activestatus: "Active" }, { vac_activestatus: "Inactive" }],
    columnsdata: [
      {
        accessorKey: "bint_srno",
        header: "SrNo",
      },
      {
        accessorKey: "vac_userid",
        header: "User ID",
      },
      {
        accessorKey: "vac_username",
        header: "Name",
      },
      //   {
      //     accessorKey: "vac_department",
      //     header: "Department",
      //   },
      {
        accessorKey: "vac_mobileno",
        header: "Mobile No",
      },
      {
        accessorKey: "vac_email",
        header: "Email",
        size: 220,
      },
      {
        accessorKey: "vac_erprole",
        header: "ERP Role",
      },
      {
        accessorKey: "vac_brcode",
        header: "Branch Code",
      },
      {
        accessorKey: "vac_brname",
        header: "Branch Name",
      },

      //   {
      //     accessorKey: "vac_activestatus",
      //     header: "Status",
      //   },
    ],

    rows: [
      // {
      //   bint_srno: "1",
      //   vac_userid: "admin",
      //   vac_username: "admin name",
      //   vac_mobileno: "1234567890",
      //   vac_email: "admin@gmail.com",
      // },
      // {
      //   bint_srno: "2",
      //   vac_userid: "User",
      //   vac_username: "User name",
      //   vac_mobileno: "1234567890",
      //   vac_email: "User@gmail.com",
      // },
    ],
    formData: {
      EditSrNo: "",
      EditUserID: "",
      txtName: "",
      //   txtDepartment: "",
      txtUserId: "",
      txtPassword: "",
      txtConfirmPassword: "",
      txtMobileNo: "",
      txtEmail: "",
      cmbERPRole: "",
      cmbBranchCode: "",
      cmbBranchName: "",
      cmbStatus: "Active",
    },
    cmbMulBranch: [],
    dataLoadStatus: true,
    ARD: null,
    rowsCount: 0,
    rowsPerPage: 50,
    DepartmentTreeList: [
      /* {
        value: "Department",
        label: "Department",
        children: [
          { value: "Department 1", label: "Department 1" },
          { value: "Department 2", label: "Department 2" },
          { value: "Department 3", label: "Department 3" },
          { value: "Department 4", label: "Department 4" },
        ],
      }, */
    ],
    checked: [],
    expanded: [],
    allModuleDepartmentList: [],

    PlantTreeList: [
      /* {
        value: "Plant",
        label: "Plant",
        children: [
          { value: "Plant 1", label: "Plant 1" },
          { value: "Plant 2", label: "Plant 2" },
          { value: "Plant 3", label: "Plant 3" },
          { value: "Plant 4", label: "Plant 4" },
        ],
      }, */
    ],
    checkedPlant: [],
    expandedPlant: [],
    allModulePlantList: [],

    PrivilegesTreeList: [
      // {
      //   value: "Master",
      //   label: "Master",
      //   children: [
      //     {
      //       value: "Proposal",
      //       label: "Proposal",
      //       children: [
      //         { value: "Plant 2", label: "Employee" },
      //         { value: "Plant 3", label: "Lift Type" },
      //         { value: "Plant 4", label: "Drive" },
      //         { value: "Plant 5", label: "Controller" },
      //         { value: "Plant 6", label: "Machine" },
      //         { value: "Plant 7", label: "Machine Room" },
      //         { value: "Plant 8", label: "Machine Placement" },
      //         { value: "Plant 9", label: "Car Enclosure / Car Cabin" },
      //         { value: "Plant 10", label: "Hoist Way Structure" },
      //         { value: "Plant 11", label: "Display Type" },
      //         { value: "Plant 12", label: "Door Type" },
      //         { value: "Plant 13", label: "Terms of Payment" },
      //         { value: "Plant 14", label: "Terms of Cancellation" },
      //         { value: "Plant 15", label: "Tax Detail" },
      //         { value: "Plant 16", label: "Note" },
      //         { value: "Plant 17", label: "Preparatory Work" },
      //         { value: "Plant 18", label: "Terms and Condition" },
      //       ],
      //     },
      //     {
      //       value: "Job Site Detail",
      //       label: "Job Site Detail",
      //       children: [
      //         { value: "Plant 20", label: "Door Board" },
      //         { value: "Plant 21", label: "Machine Master" },
      //         { value: "Plant 22", label: "Braking Resistor" },
      //         { value: "Plant 23", label: "Car & C.W. Rail Size" },
      //         { value: "Plant 24", label: "Car Cabin Fan / Blower" },
      //         { value: "Plant 25", label: "Cabin False Celling Design" },
      //         { value: "Plant 26", label: "Hand Rail Dimension" },
      //         { value: "Plant 27", label: "Hand Rail Colour" },
      //         { value: "Plant 28", label: "Controller Design" },
      //         { value: "Plant 29", label: "Drive Name" },
      //         { value: "Plant 30", label: "Counter Frame Design" },
      //         { value: "Plant 31", label: "Safety Block Type" },
      //         { value: "Plant 32", label: "Travelling Cable" },
      //         { value: "Plant 33", label: "COP Graphics" },
      //         { value: "Plant 34", label: "Car & Counter GuideShoe Type" },
      //         { value: "Plant 35", label: "Car Floor" },
      //       ],
      //     },
      //   ],
      // },
      // {
      //   value: "Entry",
      //   label: "Entry",
      //   children: [
      //     {
      //       value: "Entry 1",
      //       label: "Proposal",
      //       children: [
      //         { value: "ProposalRead", label: "Read" },
      //         { value: "ProposalWrite", label: "Write" },
      //         { value: "Proposalmodify", label: "Modify " },
      //       ],
      //     },
      //     {
      //       value: "Entry 2",
      //       label: "JSD",
      //       children: [
      //         { value: "JSDRead", label: "Read" },
      //         { value: "JSDWrite", label: "Write" },
      //         { value: "JSDmodify", label: "Modify " },
      //       ],
      //     },
      //     {
      //       value: "Entry 3",
      //       label: "Packing",
      //       children: [
      //         { value: "PackingRead", label: "Read" },
      //         { value: "PackingWrite", label: "Write" },
      //         { value: "Packingmodify", label: "Modify " },
      //       ],
      //     },
      //     // { value: "Entry 4", label: "faizan" },
      //     // {
      //     //   value: "Master1",
      //     //   label: "Master1",
      //     //   children: [
      //     //     { value: "Plant 55", label: "Proposal" },
      //     //     { value: "Plant 56", label: "Write" },
      //     //     { value: "Plant 57", label: "Modify" },
      //     //     //   { value: "Plant 4", label: "Plant 4" },
      //     //   ],
      //     // },
      //   ],
      // },
    ],
    checkedPrivileges: [],
    expandedPrivileges: [],
    allModulePrivilegesList: [
      {
        main: "Master",
        children: [
          { main: "Company", type: "A" },
          { main: "Customer", type: "A" },
          { main: "Supplier", type: "A" },
          { main: "User Management", type: "R" },
        ],
      },
      {
        main: "Entry",
        children: [
          {
            main: "Sales",
            type: "R",
            children: [
              { main: "Sales Read", type: "A" },
              { main: "Sales Write", type: "A" },
            ],
          },
          {
            main: "Purchase",
            type: "R",
            children: [
              { main: "Purchase Read", type: "A" },
              { main: "Purchase Write", type: "A" },
            ],
          },
        ],
      },
    ],

    plantHeadPrivileges: [
      "Entry:Recruitment Requisition:Read",
      "Entry:Recruitment Requisition:Write",
      "Entry:Recruitment Requisition:Modify",
      "Entry:Interview:Read",
      "Entry:Interview:Write",
      "Entry:Interview:Modify",
      "Entry:Employee:Read",
    ],

    hrPrivileges: [
      "Master:Department:Allow",
      "Master:Designation:Allow",
      "Master:Plant:Allow",
      "Master:Question Bank:Allow",
      "Master:Question Paper Style Bank:Allow",
      "Master:Candidate List:Allow",
      "Master:Roles and Responsibility:Allow",
      "Master:Check List:Allow",
      "Master:Vaccination:Allow",
      "Master:Medical Certificate:Allow",
      "Master:Memo:Allow",
      "Master:Stream:Allow",
      "Master:Contractor:Allow",
      "Master:Position Applied For:Allow",
      "Master:Pincode:Allow",
      "Master:Education:Allow",
      "Entry:Interview:Read",
      "Entry:Interview:Write",
      "Entry:Interview:Modify",
      "Entry:Employee:Read",
      "Entry:Employee:Write",
      "Entry:Employee:Modify",
      "Entry:GMP Training:Read",
      "Entry:GMP Training:Write",
      "Entry:GMP Training:Modify",
      "Entry:Induction Training:Read",
      "Entry:Induction Training:Write",
      "Entry:Induction Training:Modify",
      "Entry:Recruitment Requisition:Read",
      "Entry:Recruitment Requisition:Write",
      "Entry:Recruitment Requisition:Modify",
      "Entry:Resume:Read",
      "Entry:Resume:Write",
      "Entry:Resume:Modify",
      "Report:HR:Pay Slip:Allow",
      "Report:HR:Payroll Register:Allow",
      "Report:HR:Payroll Bank Register:Allow",
      "Report:HR:Annual Register:Allow",
      "Report:HR:Payroll PF Register:Allow",
      "Setting:HR Setting:Allow",
      "Setting:Punch Machine Data Fetching:Allow",
      "Setting:Holiday Schedule:Allow",
    ],
  };

  //#region Component
  componentDidMount() {
    document.title = "User Management : Top India";
    localStorage.setItem("Heading", "User Management");
    this.props.verifyUserID(
      undefined,
      this.props.setUserPrivileges,
      this.props.modules.employee
    );
    this.ShortCut();
    this.getInfo();
  }

  componentWillUnmount() {
    this.removeShortCut();
  }
  //#endregion

  //#region Handle

  ShortCut = () => {
    this.removeShortCut = setKeyboardShortcuts(
      this.SaveUser,
      this.clearInfo,
      () => {
        this.DeleteData(
          this.state.formData.EditSrNo,
          this.state.formData.txtUserId
        );
      }
    );
  };

  handleOnChangeBranch = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        let cmbMulBranch = this.state.cmbMulBranch;

        if (
          this.state.formData.cmbBranchName.length > 0 &&
          !this.state.cmbMulBranch.includes(this.state.formData.cmbBranchName)
        ) {
          cmbMulBranch.push(this.state.formData.cmbBranchName);
        }
        this.setState({
          cmbMulBranch,
        });
      }
    );
  };

  handleOnChange = (e) => {
    this.setState(
      {
        formData: { ...this.state.formData, [e.target.name]: e.target.value },
      },
      () => {
        // if (e.target.name === "cmbERPRole") {
        //   let checkedPrivileges = [];
        //   if (e.target.value === "Admin") {
        //     checkedPrivileges = this.getChildList(
        //       this.state.PrivilegesTreeList
        //     );
        //     // console.log("adminChildList", adminChildList);
        //   } else if (e.target.value === "Plant Head") {
        //     checkedPrivileges = this.state.plantHeadPrivileges;
        //   } else if (e.target.value === "HR") {
        //     checkedPrivileges = this.state.hrPrivileges;
        //   }
        //   this.setState({ checkedPrivileges });
        // }
        let cmbBranchCode = this.state.formData.cmbBranchCode,
          cmbBranchName = this.state.formData.cmbBranchName,
          cmbMulBranch =
            this.state.cmbMulBranch.length > 0 ? this.state.cmbMulBranch : [];
        if (e.target.name === "cmbBranchCode") {
          console.log("chnage code");
          let cmbBranchNameArry = this.state.Branch.filter((row) => {
            if (cmbBranchCode == row.vac_brcode) {
              return row.vac_brname;
            }
          });
          if (!cmbMulBranch.includes(this.state.formData.cmbBranchName)) {
            cmbMulBranch.push(this.state.formData.cmbBranchName);
          }
          cmbBranchName =
            cmbBranchNameArry.length > 0
              ? cmbBranchNameArry[0].vac_brname
              : cmbBranchName;
        } else if (e.target.name === "cmbBranchName") {
          let cmbBranchCodeArry = this.state.Branch.filter((row) => {
            if (cmbBranchName == row.vac_brname) {
              return row.vac_brcode;
            }
          });
          if (!cmbMulBranch.includes(this.state.formData.cmbBranchName)) {
            cmbMulBranch.push(this.state.formData.cmbBranchName);
          }
          cmbBranchCode =
            cmbBranchCodeArry.length > 0
              ? cmbBranchCodeArry[0].vac_brcode
              : cmbBranchCode;
        }

        cmbMulBranch = cmbMulBranch.filter((branch) => branch !== "");

        this.setState({
          formData: {
            ...this.state.formData,
            cmbBranchCode,
            cmbBranchName,
          },
          cmbMulBranch,
        });
      }
    );
  };

  // handleARDonActionClick_Edit = (row) => {
  //   this.props.notifyProcessing();
  //   // alert("click on edit");
  //   // console.log("row", row);

  //   this.props.updateProcessing("success", "on edit");
  //   // console.log("click on edit");
  // };
  handleARDonActionClick_Delete = (rtRow) => {
    // this.props.notifyProcessing();

    // this.props.updateProcessing("success", "on Delete");

    const row = getRowOfRT(rtRow, ["bint_srno", "vac_userid"]);
    // console.log("row", row.bint_srno, row.vac_userid);
    this.DeleteData(row.bint_srno, row.vac_userid);
  };

  handleARDonActionClick_Edit = (rtRow) => {
    const row = getRowOfRT(rtRow, [
      "bint_srno",
      "vac_username",
      "vac_userid",
      "vac_mobileno",
      "vac_email",
      "vac_erprole",
      "vac_brcode",
      "vac_brname",
    ]);

    const EditSrNo = row.bint_srno,
      txtName = row.vac_username,
      txtUserId = row.vac_userid,
      EditUserID = row.vac_userid,
      txtMobileNo = row.vac_mobileno,
      txtEmail = row.vac_email,
      cmbERPRole = row.vac_erprole,
      cmbBranchCode = row.vac_brcode,
      cmbBranchName = row.vac_brname;
    this.setState(
      {
        formData: {
          ...this.state.formData,
          EditSrNo,
          txtName,
          txtUserId,
          EditUserID,
          txtPassword: "",
          txtConfirmPassword: "",
          txtMobileNo,
          txtEmail,
          cmbERPRole,
          cmbBranchCode,
          cmbBranchName,
          // cmbStatus,
        },
      },
      () => {
        this.setPrivilegesToTree(this.state.allModulePrivilegesList);
        this.getPrivilegesOfUserID(txtUserId);
      }
    );
    // window.scrollTo(0, 0);
    document.getElementById("txtName").focus();
  };

  //   handleOnDepartmentClick = () => {
  //     let ARD = null;
  //     this.setState({ ARD }, () => {
  //       ARD = (
  //         <AlertResponsiveDialog
  //           labelDisagree="Close"
  //           alertMessage={
  //             <DepartmentMaster
  //               updateUserDepartmentList={this.updateUserDepartmentList}
  //             />
  //           }
  //           defaultOpenDialog={true}
  //           onYesClickCloseIfExeSuccessfully={true}
  //           handleOnClickNo={() => {}}
  //           maxWidthClass="100%"
  //         />
  //       );
  //       this.setState({ ARD });
  //     });
  //   };

  //   handleOnPlantClick = () => {
  //     let ARD = null;
  //     this.setState({ ARD }, () => {
  //       ARD = (
  //         <AlertResponsiveDialog
  //           labelDisagree="Close"
  //           alertMessage={<PlantMaster updatePlantList={this.updatePlantList} />}
  //           defaultOpenDialog={true}
  //           onYesClickCloseIfExeSuccessfully={true}
  //           handleOnClickNo={() => {}}
  //           maxWidthClass="100%"
  //         />
  //       );
  //       this.setState({ ARD });
  //     });
  //   };

  //#endregion

  //#region Function
  //   updatePlantList = (PlantTreeList) => {
  //     this.setState({
  //       PlantTreeList: [
  //         {
  //           value: "Plant",
  //           label: "Plant",
  //           children: PlantTreeList,
  //         },
  //       ],
  //     });
  //   };
  //   updateUserDepartmentList = (DepartmentList) => {
  //     this.setState({
  //       DepartmentTreeList: [
  //         {
  //           value: "Department",
  //           label: "Department",
  //           children: DepartmentList,
  //         },
  //       ],
  //     });
  //   };

  getInfo = () => {
    this.props.notifyProcessing();
    const reqData = {
      Op: "ListUser",
      ErpRole: localStorage.getItem("ErpRole"),
    };
    // fetchUser(reqData)
    // const res = axios
    //   .post("http://localhost:7000/UserManagement", reqData)
    fetchUserManagement(reqData)
      .then((response) => {
        this.props.closeUpdateProcessing();
        let rows = response.data.UserList,
          rowsCount = rows.length,
          allModulePrivilegesList = [],
          Branch = [];

        if (response.data.ModuleList) {
          allModulePrivilegesList = response.data.ModuleList;
          Branch = response.data.BranchName;
        }
        this.setState(
          {
            rowsCount,
            rows,
            allModulePrivilegesList,
            Branch,
          },
          () => {
            this.setPrivilegesToTree(this.state.allModulePrivilegesList);
          }
        );
      })
      .catch((error) => {
        console.log("Unknown error occurred in onFormLoad.", error);
      });
    document.getElementById("txtName").focus();
  };

  setPrivilegesToTree = (Arrprivileges, bolreturnvalue = false) => {
    let privileges = Arrprivileges,
      treeNodeList = [],
      children = [],
      childrenC = [],
      rowCType = "",
      rowCCType = "",
      parentArrType = [],
      childArrType = [];
    if (privileges && privileges.length > 0) {
      // console.log("privileges", privileges);
      privileges.map((rowP) => {
        // console.log(rowP.children);
        if (rowP.children && rowP.children.length > 0) {
          children = [];
          rowCType = "";
          rowP.children.map((rowC) => {
            parentArrType = [];
            if (rowC.type == "A") {
              rowCType = "Allow";
            } else if (rowC.type == "R") {
              rowCType = "Read";
              parentArrType.push(
                {
                  value: rowP.main + ":" + rowC.main + ":" + "Read",
                  label: "Read",
                },
                {
                  value: rowP.main + ":" + rowC.main + ":" + "Write",
                  label: "Write",
                },
                {
                  value: rowP.main + ":" + rowC.main + ":" + "Modify",
                  label: "Modify",
                }
              );
            }
            // console.log("parentArrType", parentArrType);
            // console.log("rowC", rowC);
            if (rowC.children && rowC.children.length > 0) {
              // console.log("rowC.children", rowC.children);
              childrenC = [];
              rowCCType = "";
              rowC.children.map((rowCC) => {
                // console.log("rowCC", rowCC);
                // console.log("rowCC.type", rowCC.type);
                childArrType = [];
                if (rowCC.type == "A") {
                  rowCCType = "Allow";
                } else if (rowCC.type == "R") {
                  rowCCType = "Read";
                  childArrType.push(
                    {
                      value:
                        rowP.main +
                        ":" +
                        rowC.main +
                        ":" +
                        rowCC.main +
                        ":" +
                        "Read",
                      label: "Read",
                    },
                    {
                      value:
                        rowP.main +
                        ":" +
                        rowC.main +
                        ":" +
                        rowCC.main +
                        ":" +
                        "Write",
                      label: "Write",
                    },
                    {
                      value:
                        rowP.main +
                        ":" +
                        rowC.main +
                        ":" +
                        rowCC.main +
                        ":" +
                        "Modify",
                      label: "Modify",
                    }
                  );
                }
                // console.log("childArrType", childArrType);
                if (childArrType.length > 0) {
                  childrenC.push({
                    value: rowP.main + ":" + rowC.main + ":" + rowCC.main,
                    label: rowCC.main,
                    children: childArrType,
                  });
                } else {
                  childrenC.push({
                    value:
                      rowP.main +
                      ":" +
                      rowC.main +
                      ":" +
                      rowCC.main +
                      ":" +
                      rowCCType,
                    label: rowCC.main,
                  });
                }
              });
              // console.log("childrenC", childrenC);
              if (childrenC.length > 0) {
                children.push({
                  value: rowP.main + ":" + rowC.main, // + ":" + rowCType,
                  label: rowC.main,
                  children: childrenC,
                });
              } else {
                children.push({
                  value: rowP.main + ":" + rowC.main + ":" + rowCType,
                  label: rowC.main,
                });
              }
              // console.log("children", children);
            } else {
              if (parentArrType.length > 0) {
                children.push({
                  value: rowP.main + ":" + rowC.main,
                  label: rowC.main,
                  children: parentArrType,
                });
              } else {
                children.push({
                  value: rowP.main + ":" + rowC.main + ":" + rowCType,
                  label: rowC.main,
                });
              }
              // console.log("children", children);
            }
          });
          treeNodeList.push({ value: rowP.main, label: rowP.main, children });
        } else {
          treeNodeList.push({ value: rowP.main, label: rowP.main });
        }
      });
    }
    if (bolreturnvalue) {
      return treeNodeList;
    } else {
      this.setState(
        {
          PrivilegesTreeList: treeNodeList,
        },
        () => {
          // let adminChildList = this.getChildList(this.state.PrivilegesTreeList);
          // console.log("adminChildList", adminChildList);
          // console.log("treeNodeList", treeNodeList);
          // console.log("nodeexapmleList", this.state.nodeexapmleList);
        }
      );
    }
  };

  DeleteData = (EditSrNo, EditUserID) => {
    if (Number(EditSrNo) > 0 && EditUserID.length > 0) {
      this.setState({ ARD: null }, () => {
        let ARD = (
          <AlertResponsiveDialog
            labelAgree="Delete"
            labelDisagree="Cancel"
            alertTitle={"Do you want to Delete User ID : " + EditUserID + " ?"}
            alertMessage=""
            handleOnClickYes={() => {
              return this.DeleteUser(EditSrNo, EditUserID);
            }}
            defaultOpenDialog={true}
            onYesClickCloseIfExeSuccessfully={true}
          />
        );

        this.setState({ ARD });
      });
    } else {
      this.props.toastErrorMsg("Select a record for deletion.");
    }
  };

  DeleteUser = (EditSrNo, txtUserId) => {
    this.props.notifyProcessing();
    const reqData = {
      Op: "DeleteUser",
      bint_srno: EditSrNo,
      ErpRole: localStorage.getItem("ErpRole"),
      // vac_userid: txtUserId,
      // bint_srno4DeptPlantList: localStorage.getItem("UserSrNo"),
      // bol_returnlist: true,
    };
    // console.log("reqdata", reqData);
    // const res = axios
    //   .post("http://localhost:7000/UserManagement", reqData)
    fetchUserManagement(reqData)
      .then((response) => {
        this.props.updateProcessing(
          response.data.msgType,
          response.data.message
        );
        if (response.data.msgType === "success") {
          let rows = [],
            rowsCount = rows.length;
          if (response.data.UserList && response.data.UserList.length > 0) {
            rows = response.data.UserList;
            rowsCount = rows.length;
          }
          this.setState(
            {
              rows,
              rowsCount,
              ARD: null,
            },
            () => {
              this.clearInfo();
            }
          );
          document.getElementById("txtName").focus();
        }
      })
      .catch((error) => {
        this.props.updateProcessing(
          "error",
          "Unknown error occurred in delete user. " + error
        );
      });
    return false;
  };

  clearInfo = () => {
    let txtName = "",
      txtUserId = "",
      txtPassword = "",
      txtConfirmPassword = "",
      txtMobileNo = "",
      txtEmail = "",
      cmbERPRole = "",
      EditSrNo = "",
      EditUserID = "";

    this.setState({
      formData: {
        ...this.state.formData,
        txtName,
        txtUserId,
        txtPassword,
        txtConfirmPassword,
        txtMobileNo,
        txtEmail,
        cmbERPRole,
        EditSrNo,
        EditUserID,
        cmbBranchCode: "",
        cmbBranchName: "",
      },
      cmbMulBranch: [],
      checked: [],
      checkedPlant: [],
      checkedPrivileges: [],
      expanded: [],
      expandedPlant: [],
      expandedPrivileges: [],
    });
    document.getElementById("txtName").focus();
  };

  validateUserManagement = () => {
    // console.log('this.state.formData.EditUserID', this.state.formData.EditUserID)
    let result = false;
    if (this.state.formData.txtName.length <= 0) {
      this.props.toastErrorMsg("Enter User Name", "txtName");
    } else if (this.state.formData.txtUserId.length <= 0) {
      this.props.toastErrorMsg("Enter User Id", "txtUserId");
    } else if (
      this.state.formData.EditUserID == "" &&
      this.state.formData.txtPassword.length < 4
    ) {
      this.props.toastErrorMsg(
        "Enter Password Alteast a 4 Characters",
        "txtPassword"
      );
    } else if (
      this.state.formData.EditUserID.length > 0 &&
      this.state.formData.txtPassword.length > 0 &&
      this.state.formData.txtPassword.length < 4
    ) {
      this.props.toastErrorMsg(
        "Enter Password Alteast a 4 Characters",
        "txtPassword"
      );
    } else if (
      this.state.formData.txtPassword !== this.state.formData.txtConfirmPassword
    ) {
      this.props.toastErrorMsg(
        "Password and Confirm Password Must Be Same",
        "txtPassword"
      );
    } else if (this.state.formData.txtMobileNo.length <= 0) {
      this.props.toastErrorMsg("Enter Mobile No", "txtMobileNo");
    } else if (this.state.formData.txtMobileNo.trim().length < 10) {
      this.props.toastErrorMsg("Enter 10 Digit Mobile No", "txtMobileNo");
    } else if (this.state.formData.txtEmail.trim().length <= 0) {
      this.props.toastErrorMsg("Enter Email", "txtEmail");
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
        this.state.formData.txtEmail
      )
    ) {
      this.props.toastErrorMsg("Enter valid Email", "txtEmail");
    } else if (this.state.formData.cmbERPRole.length <= 0) {
      this.props.toastErrorMsg("Select ERP Role", "cmbERPRole");
    } else if (this.state.formData.cmbBranchCode.length <= 0) {
      this.props.toastErrorMsg("Select Branch Code", "cmbBranchCode");
    } else if (
      this.state.formData.cmbBranchCode.length > 0 &&
      !isValidItem(
        this.state.Branch,
        "vac_brcode",
        this.state.formData.cmbBranchCode
      )
    ) {
      this.props.toastErrorMsg("Select Valid Branch Code", "cmbBranchCode");
    } else if (this.state.formData.cmbBranchName.length <= 0) {
      this.props.toastErrorMsg("Select Branch Name", "cmbBranchName");
    } else if (
      this.state.formData.cmbBranchName.length > 0 &&
      !isValidItem(
        this.state.Branch,
        "vac_brname",
        this.state.formData.cmbBranchName
      )
    ) {
      this.props.toastErrorMsg("Select Valid Branch Name", "cmbBranchName");
    } /* else if (this.state.checked.length <= 0) {
      this.props.toastErrorMsg("Select atleast one Department", "cmbERPRole");
    } else if (this.state.checkedPlant.length <= 0) {
      this.props.toastErrorMsg("Select atleast one Plant", "cmbERPRole");
    } */ else if (this.state.checkedPrivileges.length <= 0) {
      this.props.toastErrorMsg("Select atleast one Privileges to assign user");
    }
    //  else if (this.state.checked.length <= 0) {
    //   this.props.toastMsg("error", "Please assign Privileges to User");
    // }
    else {
      result = true;
    }
    return result;
  };

  //   getDepartmentChecked = () => {
  //     let departmentChecked = [];
  //     if (this.state.checked && this.state.checked.length > 0) {
  //       this.state.checked.map((r) => {
  //         // console.log("r", r);
  //         departmentChecked.push({ vac_department: r });
  //       });
  //     }
  //     // console.log("departmentChecked", departmentChecked);
  //     return departmentChecked;
  //   };

  //   getPlantChecked = () => {
  //     let plantChecked = [];
  //     if (this.state.checkedPlant && this.state.checkedPlant.length > 0) {
  //       this.state.checkedPlant.map((r) => {
  //         // console.log("r", r);
  //         plantChecked.push({ vac_plantname: r });
  //       });
  //     }
  //     // console.log("plantChecked", plantChecked);
  //     return plantChecked;
  //   };

  getLastCheckedPrivileges = () => {
    let checked = [];
    if (this.state.checkedPrivileges.length > 0) {
      let readPrivilege = undefined,
        writePrivilege = undefined,
        modifyPrivilege = undefined,
        prePartPrivilege = undefined;
      // console.log("count", this.state.checkedPrivileges.length);
      this.state.checkedPrivileges.map((curPrivilege, privilegeIndex) => {
        // console.log("index", privilegeIndex);
        // console.log("curPrivilege", curPrivilege);
        let privilegeInfo = curPrivilege.split(":"),
          curPartPrivilege = "",
          i = 0; //privilegeInfo[0] + ":" + privilegeInfo[1];
        for (i = 0; i < privilegeInfo.length - 1; i++) {
          curPartPrivilege = (i > 0 ? ":" : "") + privilegeInfo[i];
        }
        let privilegeInfoIndex = i;
        // console.log("privilegeInfo.length", privilegeInfo.length);
        // console.log("curPartPrivilege", curPartPrivilege);
        // console.log("prePartPrivilege", prePartPrivilege);
        if (
          prePartPrivilege !== undefined &&
          prePartPrivilege !== curPartPrivilege
        ) {
          let result = this.pushRWM_toChecked(
            checked,
            readPrivilege,
            writePrivilege,
            modifyPrivilege,
            privilegeIndex
          );
          // console.log("result", result);
          checked = result.checked;
          readPrivilege = result.readPrivilege;
          writePrivilege = result.writePrivilege;
          modifyPrivilege = result.modifyPrivilege;
        }
        prePartPrivilege = curPartPrivilege;
        if (privilegeInfo[privilegeInfoIndex] == "Read") {
          readPrivilege = curPrivilege;
        } else if (privilegeInfo[privilegeInfoIndex] == "Write") {
          writePrivilege = curPrivilege;
        } else if (privilegeInfo[privilegeInfoIndex] == "Modify") {
          modifyPrivilege = curPrivilege;
        } else {
          checked.push(curPrivilege);
        }
      });
      let result = this.pushRWM_toChecked(
        checked,
        readPrivilege,
        writePrivilege,
        modifyPrivilege
      );
      checked = result.checked;
    }
    // console.log("checked at the end of the Function => ", checked);
    return checked;
  };

  pushRWM_toChecked = (
    checked,
    readPrivilege,
    writePrivilege,
    modifyPrivilege,
    privilegeIndex = ""
  ) => {
    if (readPrivilege || writePrivilege || modifyPrivilege) {
      if (modifyPrivilege) {
        checked.push(modifyPrivilege);
      } else if (writePrivilege) {
        checked.push(writePrivilege);
      } else if (readPrivilege) {
        checked.push(readPrivilege);
      }
      readPrivilege = undefined;
      writePrivilege = undefined;
      modifyPrivilege = undefined;
      // console.log("checked at the index :: " + privilegeIndex, checked);
    }
    return { checked, readPrivilege, writePrivilege, modifyPrivilege };
  };

  checkChkBoxOnUpdate = () => {
    let checkedPrivileges = [],
      checked = [],
      checkedPlant = [];
    if (this.state.checkedPrivileges.length > 0) {
      this.state.checkedPrivileges.map((curPrivilege, privilegeIndex) => {
        // console.log("curPrivilege", curPrivilege);
        /** Need to Upgrade auto check read, write management for infinite management based on full module name login */
        // privilegeInfo = curPrivilege.split(":"),
        let privilegeInfo = this.givePrivilegeAndPage(curPrivilege.split(":")),
          // curPartPrivilege = privilegeInfo[0] + ":" + privilegeInfo[1];
          curPartPrivilege = privilegeInfo[0];
        if (privilegeInfo[1] == "Read") {
          checkedPrivileges.push(curPrivilege);
        } else if (privilegeInfo[1] == "Write") {
          curPartPrivilege = privilegeInfo[0] + ":" + "Read";
          checkedPrivileges.push(curPartPrivilege);
          checkedPrivileges.push(curPrivilege);
        } else if (privilegeInfo[1] == "Modify") {
          curPartPrivilege = privilegeInfo[0] + ":" + "Read";
          checkedPrivileges.push(curPartPrivilege);
          curPartPrivilege = privilegeInfo[0] + ":" + "Write";
          checkedPrivileges.push(curPartPrivilege);
          checkedPrivileges.push(curPrivilege);
        } else {
          checkedPrivileges.push(curPrivilege);
        }
      });
      this.setState({
        checkedPrivileges,
      });
    }
    if (this.state.checked.length > 0) {
      this.state.checked.map((curDept, privilegeIndex) => {
        let deptInfo = curDept.split(":");
        checked.push(deptInfo[1]);
      });
      this.setState({
        checked,
      });
    }
    if (this.state.checkedPlant.length > 0) {
      this.state.checkedPlant.map((curPlant, privilegeIndex) => {
        let plantInfo = curPlant.split(":");
        checkedPlant.push(plantInfo[1]);
      });
      this.setState({
        checkedPlant,
      });
    }
  };

  givePrivilegeAndPage = (FullName) => {
    let PageName = "",
      Privilege = "";
    for (let i = 0; i < FullName.length - 1; i++) {
      PageName += (PageName.length > 0 ? ":" : "") + FullName[i];
    }

    Privilege = FullName[FullName.length - 1];

    return [PageName, Privilege];
  };

  getPrivilegesOfUserID = (UserID) => {
    let checkedPrivileges = [],
      checked = [],
      checkedPlant = [],
      cmbMulBranch = [];
    this.state.rows.map((i) => {
      if (i.vac_userid == UserID) {
        // console.log("i.privileges", i.privileges);
        if (i.privileges && i.privileges != null && i.privileges.length > 0) {
          checkedPrivileges = i.privileges;
        }
        if (i.UserDeptList && i.UserDeptList.length > 0) {
          checked = i.UserDeptList;
        }
        if (i.UserPlantList && i.UserPlantList.length > 0) {
          checkedPlant = i.UserPlantList;
        }
        if (i.UserBranch && i.UserBranch.length > 0) {
          cmbMulBranch = i.UserBranch;
          cmbMulBranch = cmbMulBranch.map((item) => item.vac_brname);
        }
      }
    });
    this.setState(
      {
        checkedPrivileges,
        checkedPlant,
        checked,
        cmbMulBranch,
      },
      () => {
        // console.log("checkedPrivileges", checkedPrivileges);
        this.checkChkBoxOnUpdate();
      }
    );
  };

  SaveUser = () => {
    let userbranchlist = [];
    this.state.Branch.filter((row) => {
      this.state.cmbMulBranch.filter((branch) => {
        if (row.vac_brname == branch) {
          userbranchlist.push(row);
        }
      });
    });

    // this.getLastCheckedPrivileges();
    if (this.validateUserManagement()) {
      this.props.notifyProcessing();
      const reqData = {
        Op: "SaveUser",
        bint_srno: this.state.formData.EditSrNo,
        vac_username: this.state.formData.txtName,
        vac_userid: this.state.formData.txtUserId,
        vac_password: this.state.formData.txtPassword,
        vac_mobileno: this.state.formData.txtMobileNo,
        vac_email: this.state.formData.txtEmail,
        vac_erprole: this.state.formData.cmbERPRole,
        // vac_activestatus: this.state.formData.cmbStatus,
        // Department: this.getDepartmentChecked(),
        vac_privileges: this.getLastCheckedPrivileges(),
        // Plant: this.getPlantChecked(),
        bol_list: true,
        vac_brcode: this.state.formData.cmbBranchCode,
        vac_brname: this.state.formData.cmbBranchName,
        userbranchlist: userbranchlist,
        ErpRole: localStorage.getItem("ErpRole"),

        // bint_srno4DeptPlantList: localStorage.getItem("UserSrNo"),
      };
      // console.log("reqdata", reqData);

      // const res = axios
      //   .post("http://localhost:7000/UserManagement", reqData)
      fetchUserManagement(reqData)
        .then((res) => {
          // console.log("response", res);
          this.props.updateProcessing(res.data.msgType, res.data.message);
          if (res.data.msgType === "success") {
            let rows = [],
              rowsCount = rows.length;
            if (res.data.UserList && res.data.UserList.length > 0) {
              rows = res.data.UserList;
              rowsCount = rows.length;
            }
            this.setState(
              {
                rows,
                rowsCount,
              },
              () => {
                this.clearInfo();
              }
            );
          }
        })
        .catch((error) => {
          console.log("Unknown error occurred in User Managment.", error);
        });
    }
  };

  trimFormData = (nextFunctionCall) => {
    let formData = applyTrimOnObjectValues({
      formData: this.state.formData,
    });
    this.setState({ formData }, () => {
      if (nextFunctionCall) {
        nextFunctionCall();
      }
    });
  };

  //   OnDelete = () => {
  //     this.props.toastErrorMsg("Development Remain..");
  //   };

  //   onDeptCheck = (checked) => {
  //     this.setState({ checked });
  //   };

  //   onDeptExpand = (expanded) => {
  //     this.setState({ expanded });
  //   };

  //   onPlantCheck = (checkedPlant) => {
  //     this.setState({ checkedPlant });
  //   };

  //   onPlantExpand = (expandedPlant) => {
  //     this.setState({ expandedPlant });
  //   };

  onPrivilegesCheck = (checkedPrivileges) => {
    let modifyCheckedPrivileges = [];
    if (checkedPrivileges.length > 0) {
      checkedPrivileges.map((curPrivilege, privilegeIndex) => {
        let privilegeInfo = this.givePrivilegeAndPage(curPrivilege.split(":")),
          curPartPrivilege = privilegeInfo[0];
        /*  if (privilegeInfo[1] == "Read") {
          modifyCheckedPrivileges.push(curPrivilege);
        } else */ if (privilegeInfo[1] == "Write") {
          curPartPrivilege = privilegeInfo[0] + ":" + "Read";
          modifyCheckedPrivileges.push(curPartPrivilege);
          modifyCheckedPrivileges.push(curPrivilege);
        } else if (privilegeInfo[1] == "Modify") {
          curPartPrivilege = privilegeInfo[0] + ":" + "Read";
          modifyCheckedPrivileges.push(curPartPrivilege);
          curPartPrivilege = privilegeInfo[0] + ":" + "Write";
          modifyCheckedPrivileges.push(curPartPrivilege);
          modifyCheckedPrivileges.push(curPrivilege);
        } else {
          modifyCheckedPrivileges.push(curPrivilege);
        }
      });
    }
    this.setState({
      checkedPrivileges: modifyCheckedPrivileges,
    });
  };

  onPrivilegesExpand = (expandedPrivileges) => {
    this.setState({ expandedPrivileges });
  };

  getChildList = (List) => {
    let childList = [];
    List.map((pri) => {
      if (pri.children) {
        pri.children.map((priCh) => {
          if (priCh.children) {
            priCh.children.map((priChChild) => {
              if (priChChild.children) {
              } else {
                childList.push(priChChild.value);
              }
            });
          } else {
            childList.push(priCh.value);
          }
        });
      } else {
        childList.push(pri.value);
      }
    });
    return childList;
  };

  //#endregion

  render() {
    // console.log(
    //   "this.getLastCheckedPrivileges()",
    //   this.getLastCheckedPrivileges()
    // );
    // console.log("this.state.cmbMulBranch", this.state.cmbMulBranch);
    return (
      <form>
        <Grid container direction="row" justifyContent="flex-end">
          <Grid item>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <CtBtn
                  className="secondary-button"
                  label="Save"
                  iconName="save"
                  onClick={() => {
                    this.trimFormData(() => {
                      this.SaveUser();
                      // alert("work remain");
                    });
                  }}
                />
              </Grid>
              <Grid item>
                <CtBtn
                  label="New"
                  className="new-button"
                  iconName="new"
                  onClick={this.clearInfo}
                />
              </Grid>
              <Grid item>
                <CtBtn
                  label="Delete"
                  className="delete-button"
                  iconName="delete"
                  onClick={() => {
                    this.DeleteData(
                      this.state.formData.EditSrNo,
                      this.state.formData.txtUserId
                    );
                  }}
                  disabled={this.state.formData.EditSrNo > 0 ? false : true}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
          // className="unfreez-container"
        >
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
              marginTop={0}
            >
              <Grid item>
                <CtTxt
                  id="txtName"
                  value={this.state.formData.txtName}
                  label="Name*"
                  handleOnChange={this.handleOnChange}
                  width={220}
                  maxLength={100}
                  onKeyDown={this.props.onKeyDown}
                />
              </Grid>
              <Grid item>
                <CtTxt
                  id="txtUserId"
                  value={this.state.formData.txtUserId}
                  label="User ID*"
                  handleOnChange={this.handleOnChange}
                  width={220}
                  maxLength={100}
                  onKeyDown={this.props.onKeyDown}
                  nextCtrlID="txtPassword"
                />
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
              marginTop={0}
            >
              <Grid item>
                <CtTextFieldPWD
                  id="txtPassword"
                  label="Password*"
                  maxLength={100}
                  width="220"
                  value={this.state.formData.txtPassword}
                  onKeyDown={this.props.onKeyDown}
                  handleOnChange={this.handleOnChange}
                  autoFillPassword={false}
                  nextCtrlID="txtConfirmPassword"
                  // disabled={this.state.formData.EditSrNo > 0 ? true : false}
                />
              </Grid>
              <Grid item>
                <CtTextFieldPWD
                  id="txtConfirmPassword"
                  label="Confirm Password*"
                  maxLength={100}
                  width="220"
                  value={this.state.formData.txtConfirmPassword}
                  onKeyDown={this.props.onKeyDown}
                  handleOnChange={this.handleOnChange}
                  autoFillPassword={false}
                  nextCtrlID="txtMobileNo"
                  // disabled={this.state.formData.EditSrNo > 0 ? true : false}
                />
              </Grid>
              <Grid item>
                <CtTxtNum
                  id="txtMobileNo"
                  label="Mobile No*"
                  maxLength={10}
                  width="220"
                  value={this.state.formData.txtMobileNo}
                  onKeyDown={this.props.onKeyDown}
                  handleOnChange={this.handleOnChange}
                  nextCtrlID="txtEmail"
                />
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
              marginTop={0}
            >
              <Grid item>
                <CtTxt
                  id="txtEmail"
                  label="Email*"
                  maxLength={100}
                  width="220"
                  value={this.state.formData.txtEmail}
                  onKeyDown={this.props.onKeyDown}
                  handleOnChange={this.handleOnChange}
                  nextCtrlID="cmbERPRole"
                />
              </Grid>
              <Grid item>
                <CtCmb
                  id="cmbERPRole"
                  label="ERP Role*"
                  items={this.state.ERPRole}
                  value={this.state.formData.cmbERPRole}
                  handleOnChange={this.handleOnChange}
                  width={220}
                  maxLength={100}
                  onKeyDown={this.props.onKeyDown}
                  colID="vac_erprole"
                />
              </Grid>
              <Grid item>
                <CtCmbEditable
                  id="cmbBranchCode"
                  label="Branch Code*"
                  items={this.state.Branch}
                  value={this.state.formData.cmbBranchCode}
                  handleOnChange={this.handleOnChange}
                  width={220}
                  maxLength={100}
                  onKeyDown={this.props.onKeyDown}
                  colID="vac_brcode"
                />
              </Grid>
              <Grid item>
                <CtCmbEditable
                  id="cmbBranchName"
                  label="Branch Name*"
                  items={this.state.Branch}
                  value={this.state.formData.cmbBranchName}
                  handleOnChange={this.handleOnChange}
                  width={220}
                  maxLength={100}
                  onKeyDown={this.props.onKeyDown}
                  colID="vac_brname"
                />
              </Grid>
              {/* <Grid item>
                <div item className="SimpleTreeview">
                  <CheckboxTree
                    nodes={this.state.PrivilegesTreeList}
                    checked={this.state.checkedPrivileges}
                    expanded={this.state.expandedPrivileges}
                    onCheck={this.onPrivilegesCheck}
                    onExpand={this.onPrivilegesExpand}
                    iconsClass="fa4"
                  />
                </div>
              </Grid> */}
              {/*  <Grid item>
                <CtCmb
                  id="cmbStatus"
                  label="Status*"
                  items={this.state.Status}
                  value={this.state.formData.cmbStatus}
                  handleOnChange={this.handleOnChange}
                  width={150}
                  maxLength={50}
                  onKeyDown={this.props.onKeyDown}
                  colID="vac_activestatus"
                />
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
          marginTop={2}
        >
          <Grid item lg={6}>
            <Stack direction={"row"} spacing={2}>
              <Grid item sx={12}>
                <div item className="SimpleTreeview">
                  <CheckboxTree
                    nodes={this.state.PrivilegesTreeList}
                    checked={this.state.checkedPrivileges}
                    expanded={this.state.expandedPrivileges}
                    onCheck={this.onPrivilegesCheck}
                    onExpand={this.onPrivilegesExpand}
                    iconsClass="fa4"
                  />
                </div>
              </Grid>
            </Stack>
          </Grid>
          <Grid item lg={6}>
            <CtMultiSelect
              id="cmbMulBranch"
              label="Multiple Branch"
              items={this.state.Branch}
              handleOnChange={this.handleOnChangeBranch}
              selectedItems={this.state.cmbMulBranch}
              width="250"
              maxLength="1000"
              colID="vac_brname"
            />
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          marginTop={1}
        >
          {/* Material Table Only  */}
          {/* <Grid item className="maintable">
            <MaterialTable
              rowsdata={this.state.rows}
              columnsdata={this.state.columnsdata}
              rowsPerPage={this.state.rowsPerPage}
              tblmaxHeight={500}
              // csvFileName={"Item Master"}
              // newBtnName={"Add New Distributor"}
              // newBtnClick={this.handleOnNewEntryClick}
              // onEditAction={this.handleARDonActionClick_Edit}
              onDeleteAction={this.handleARDonActionClick_Delete}
              showColumnFilters={false}
              actionColSize={90}
              width={500}
              density={"compact"}
            />
          </Grid> */}

          {/* Material Table With Mobile */}
          {/* <Grid item marginTop={3} className="maintable">
            <MaterialTable
              rowsdata={this.state.rows}
              columnsdata={this.state.columnsdata}
              rowsPerPage={this.state.rowsPerPage}
              tblmaxHeight={500}
              csvFileName={csvFileName("User Management")}
              rowIconAction={[
                { label: "Edit", onClick: this.handleARDonActionClick_Edit },
                {
                  label: "Delete",
                  onClick: this.handleARDonActionClick_Delete,
                },
              ]}
              showColumnFilters={false}
              actionColSize={90}
              density={"compact"}
              hideColIdList={["vac_department", "bint_srno"]}
            />
          </Grid>
          <Grid item className="MobileTable">
            <MobileTable
              rowsData={this.state.rows}
              columnData={this.state.columnsdata}
              handleARDonActionClick_Edit={this.handleARDonActionClick_Edit}
              handleARDonActionClick_Delete={this.handleARDonActionClick_Delete}
              // editEnable={false}
              // deleteEnable={false}
            />
          </Grid> */}

          {/* responsive table */}
          <Grid item className="maintable">
            <CtART
              rowsdata={this.state.rows}
              columnsdata={this.state.columnsdata}
              rowsPerPage={this.state.rowsPerPage}
              tblmaxHeight={500}
              csvFileName={csvFileName("User Management")}
              rowIconAction={[
                {
                  label: "Edit",
                  onClick: this.handleARDonActionClick_Edit,
                  icon: "Edit",
                  className: "secondary-button",
                },
                {
                  label: "Delete",
                  onClick: this.handleARDonActionClick_Delete,
                  icon: "delete",
                  className: "delete-button",
                },
              ]}
              showColumnFilters={false}
              actionColSize={90}
              density={"compact"}
              hideColIdList={["bint_srno"]}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Box display={{ xs: "none" }} style={{ textAlign: "right" }}>
            {this.state.ARD}
          </Box>
        </Grid>
      </form>
    );
  }
}

// export default HoCtToastContainer(HOCVerifyIsUser(UserManagement));

function WithNavigate(props) {
  let navigate = useNavigate();
  return <UserManagement {...props} navigate={navigate} />;
}
export default connect(
  mapStatetoProps,
  mapSetUserPrivileges_ToProps
)(HoCtToastContainer(HOCVerifyIsUser(withRouter(WithNavigate))));
