import React from "react";
import TextField from "@mui/material/TextField";

const CtMultiLineText = ({
  id,
  label,
  disabled,
  value,
  width,
  handleOnChange,
  rows,
  maxLength,
  foreColor,
  onKeyDown,
  defaultAction,
  validateInput,
  variant,
  nextCtrlID,
  maxWidth,
}) => {
  let curVariant = variant ? variant : "standard";

  const useWidthStyle = {
    width:
      String(width).includes("%") || String(width).includes("px")
        ? width
        : width + "px",
  };
  if (maxWidth) {
    useWidthStyle["maxWidth"] = maxWidth;
  }
  // const custStyle = { color: foreColor }

  const handleOnKeyDown = onKeyDown
    ? (event) => {
        onKeyDown(event, 1, defaultAction, validateInput, nextCtrlID);
      }
    : null;
  const applyMaxLength = { maxLength: maxLength, readOnly: disabled };
  const applyMaxLengthMissing = { maxLength: 1, readOnly: disabled };

  return (
    <TextField
      id={id}
      name={id}
      label={label}
      disabled={disabled}
      value={value}
      onChange={handleOnChange}
      onKeyDown={handleOnKeyDown}
      multiline
      rows={rows}
      variant={curVariant}
      style={useWidthStyle}
      // inputProps={maxLength > 0 ? applyMaxLength : applyMaxLengthMissing, { style: { color: foreColor } }}
      inputProps={
        maxLength > 0
          ? { ...applyMaxLength, style: { color: foreColor } }
          : { ...applyMaxLengthMissing, style: { color: foreColor } }
      }
    />
  );
};

export default CtMultiLineText;
