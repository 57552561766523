import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import HOCVerifyIsUser from "../HOC/HOCVerifyIsUser";
import CtBtn from "../Components/CustomTool/CtBtn";
import CtCmb from "../Components/CustomTool/CtCmb";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import AlertResponsiveDialog from "../Components/CustomTool/AlertResponsiveDialog";
import HoCtToastContainer from "../HOC/HoCtToastContainer";
import { isValidItem } from "../SystemUtility/SystemUtility";
import {
  mapSetUserPrivileges_ToProps,
  mapStatetoProps,
} from "../mapRedux/mapReduxProps";
import { connect } from "react-redux";
import { withRouter } from "../HOC/withRouter";
import { fetchImportExcel } from "./API";
import CtCmbEditable from "./CustomTool/CtCmbEditable";
import TableList from "./CustomTool/TableList";
import readXlsxFile from "read-excel-file";
import { CSVLink } from "react-csv";

class ImportExportExcel extends Component {
  state = {
    columnsdata: [],
    targetColumns: [],
    rows: [],
    validRows: [],
    inValidRows: [],
    excelHeaders: [],
    disableSave: true,
    tblName: "",
    Master: [
      {
        vac_master: "Manual Door Price",
        columnsdata: [
          {
            id: "vac_brand",
            label: "Brand",
          },
          {
            id: "vac_type",
            label: "Type",
          },

          {
            id: "bint_opening",
            label: "Opening",
          },
          {
            id: "dec_price",
            label: "Price",
          },
        ],
        targetColumns: ["vac_brand", "vac_type", "bint_opening"],
        masterName: "tbl_manual_door_price_master",
      },
      {
        vac_master: "Machine Price",
        columnsdata: [
          {
            id: "vac_machinetype",
            label: "MC Type",
          },
          {
            id: "vac_machinemake",
            label: "MC Make",
          },
          {
            id: "vac_model",
            label: "MODEL NO.",
          },

          {
            id: "bint_person",
            label: "PERSONS",
          },
          {
            id: "bint_loadcapacity",
            label: "Load Capacity",
          },
          {
            id: "dec_speed",
            label: "Speed",
          },
          {
            id: "vac_roping",
            label: "Roping",
          },
          {
            id: "bint_sheave",
            label: "Sheave",
          },
          {
            id: "bint_ropedia",
            label: "Rope Dia",
          },
          {
            id: "bint_noofrope",
            label: "No Of Rope",
          },
          {
            id: "dec_amp",
            label: "AMP",
          },
          {
            id: "dec_kw",
            label: "KW",
          },
          {
            id: "dec_hz",
            label: "HZ",
          },
          {
            id: "dec_pole",
            label: "Pole",
          },
          {
            id: "dec_rpm",
            label: "RPM",
          },
          {
            id: "vac_gearratio",
            label: "Gear Ratio",
          },
          {
            id: "dec_purchaseprice",
            label: "Purchase Price",
          },
        ],
        targetColumns: [
          "vac_machinetype",
          "vac_machinemake",
          "vac_model",
          "bint_person",
          "bint_loadcapacity",
          "dec_speed",
          "vac_roping",
          "bint_sheave",
          "bint_ropedia",
          "bint_noofrope",
          "dec_amp",
          "dec_kw",
          "dec_hz",
          "dec_pole",
          "dec_rpm",
          "dec_purchaseprice",
        ],
        masterName: "tbl_machine",
      },
      {
        vac_master: "Manual Door Frame",
        columnsdata: [
          {
            id: "bint_opening",
            label: "Opening",
          },
          {
            id: "dec_price",
            label: "Price",
          },
        ],
        targetColumns: ["bint_opening", "dec_price"],
        masterName: "tbl_manual_door_frame_price",
      },
      { vac_master: "Auto Door Main Price List" },
    ],
    Action: [{ vac_action: "Import" }, { vac_action: "Export" }],
    formData: {
      cmbMaster: "",
      cmbAction: "",
    },
    dataLoadStatus: true,
    ARD: null,
    rowsCount: 0,
    rowsPerPage: 50,
  };

  //#region Component
  componentDidMount() {
    // document.title = "User Management : Top India";
    // localStorage.setItem("Heading", "Auto Door Frame Price Formula Master");
    localStorage.setItem("Heading", "Import And Export Excel");
    this.props.verifyUserID(
      undefined,
      this.props.setUserPrivileges,
      this.props.modules.importexport
    );
    // this.getInfo();
  }

  // #endregion Component

  // #region Handle

  handleChangeRowsPerPage = (rowsPerPage) => {
    this.setState({ rowsPerPage });
  };

  handleOnChange = (e) => {
    this.setState(
      {
        formData: { ...this.state.formData, [e.target.name]: e.target.value },
      },
      () => {
        if (e.target.name == "cmbMaster") {
          const selectedMaster = this.state.Master.find(
            (item) => item.vac_master === e.target.value
          );
          if (selectedMaster) {
            this.setState({
              columnsdata: selectedMaster.columnsdata || [],
              targetColumns: selectedMaster.targetColumns || [],
              tblName: selectedMaster.masterName || [],
            });
          }
        } else if (e.target.name == "cmbAction") {
          const selectedMaster = this.state.Master.find(
            (item) => item.vac_master === this.state.formData.cmbMaster
          );
          if (this.state.formData.cmbAction == "Import") {
            this.setState({
              columnsdata: selectedMaster.columnsdata || [],
              targetColumns: selectedMaster.targetColumns || [],
              rows: [],
            });
          } else if (this.state.formData.cmbAction == "Export") {
            this.setState(
              {
                columnsdata: selectedMaster.columnsdata || [],
              },
              () => {
                this.ExportExcelInBE();
              }
            );
          }
        }
      }
    );
  };

  //#endregion Handle

  //#region function

  validateReadExcel = () => {
    let result = false;
    let fileTypes = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/csv",
    ];
    let FileElement = document.getElementById("input");
    let selectedFile = FileElement.files[0];
    if (selectedFile) {
      if (selectedFile && fileTypes.includes(selectedFile.type)) {
        result = true;
      } else {
        this.props.toastErrorMsg("Please Select Vaild Exel File", "input");
      }
    } else {
      this.props.toastErrorMsg("Please Select Your File", "input");
    }
    return result;
  };

  ReadExcel = () => {
    if (this.validateReadExcel() === true) {
      this.props.notifyProcessing();
      const input = document.getElementById("input");
      let i = 0,
        columnsdata = [],
        rows = [],
        inValidRows = [],
        validRows = [],
        disableSave = false,
        edit_info = [];
      readXlsxFile(input.files[0]).then((c_row) => {
        c_row.map((r) => {
          if (i == 0) {
            columnsdata = this.createColumn(r);
          } else {
            let row = this.createRow(columnsdata, r);

            if (row.ImportStatus && row.ImportStatus.length > 0) {
              inValidRows.push(row);
            } else {
              validRows.push(row);
            }
            rows.push(row);
          }
          i = i + 1;
        });

        disableSave = validRows.length <= 0 ? true : false;

        this.props.closeUpdateProcessing();
        this.setState({
          columnsdata,
          rows,
          inValidRows,
          edit_info,
          validRows,
          totalRows: rows,
          disableSave,
        });
      });
    }
  };

  createColumn = (columnsdata) => {
    let cols = [];
    cols.push({
      id: "ImportStatus",
      label: "Is Empty, Enter Data & Retry",
      width: 100,
    });

    columnsdata.map((c, index) => {
      cols.push({ id: this.getColId(c.trim()), label: c, width: 120 });
    });

    return cols;
  };

  createRow = (columnsdata, rows) => {
    let returnrow = {},
      blankFeild = [];

    const checkRowsBlank = (index, c, id) => {
      if (c === "" || c === undefined || c === null) {
        blankFeild.push(id);
      }
    };

    rows.map((c, index) => {
      const column = this.state.columnsdata.find(
        (col) => col.id === columnsdata[index + 1].id
      );
      if (this.state.targetColumns.includes(columnsdata[index + 1].id)) {
        checkRowsBlank(index, c, column.label);
      } else if (c == undefined || c == null) {
        c = "";
      }
      returnrow[columnsdata[index + 1].id.trim()] = c;
    });

    let ImportStatus = blankFeild.join(", ");

    returnrow = { ...returnrow, ImportStatus: ImportStatus };
    blankFeild = [];
    return returnrow;
  };

  getColId = (colHeader) => {
    let colID = "";
    this.state.columnsdata.filter((row) => {
      if (row.label.toUpperCase() == colHeader) {
        colID = row;
      }
    });
    return colID.id;
  };

  ImportExcel = () => {
    this.setState({ ARD: null }, () => {
      if (
        this.state.validRows.length > 0 ||
        this.state.inValidRows.length > 0
      ) {
        let ARD = (
          <AlertResponsiveDialog
            labelPrimary="Import Excel"
            labelDisagree="Cancel"
            alertHeading="Import Excel"
            // alertTitle=
            alertMessage={`Total records in the Excel file: ${
              this.state.validRows.length + this.state.inValidRows.length
            }. ${
              this.state.inValidRows.length > 0
                ? `However, only ${this.state.validRows.length} records were successfully imported. ${this.state.inValidRows.length} records are missing required fields.`
                : `All records were imported successfully.`
            }`}
            handleOnClickYes={() => {
              return this.ImportExcelInBE(this.state.validRows);
            }}
            defaultOpenDialog={true}
            onYesClickCloseIfExeSuccessfully={true}
          />
        );

        this.setState({ ARD });
      }
    });
  };

  ImportExcelInBE = (rows) => {
    this.props.notifyProcessing();
    const reqData = {
      Op: "Import",
      tblName: this.state.tblName,
      rows: rows,
    };
    fetchImportExcel(reqData)
      .then((res) => {
        this.props.updateProcessing(res.data.msgType, res.data.message);
        // if (res.data.msgType === "success") {
        // }
        this.setState({
          ARD: null,
        });
      })
      .catch((error) => {
        console.log("Unknown error occurred in Save Rail Selection .", error);
      });
  };

  ExportExcelInBE = () => {
    this.props.notifyProcessing();
    const reqData = {
      Op: "Export",
      tblName: this.state.tblName,
    };
    fetchImportExcel(reqData)
      .then((res) => {
        this.props.updateProcessing(res.data.msgType, res.data.message);
        if (res.data.msgType === "success") {
          let rows = [],
            rowsCount = rows.length;
          if (res.data.ExportRows && res.data.ExportRows.length > 0) {
            rows = res.data.ExportRows;
            rowsCount = rows.length;
          }
          const excelHeaders = this.state.columnsdata.map((column) => ({
            label: column.label,
            key: column.id,
          }));
          this.setState({
            rows,
            rowsCount,
            excelHeaders,
          });
        }
      })
      .catch((error) => {
        console.log("Unknown error occurred in Export.", error);
      });
  };

  //#endregion function

  render() {
    let rows = [];
    return (
      <form>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          marginTop={0}
        >
          <Grid item>
            <CtCmbEditable
              id="cmbMaster"
              label="Import / Export Master*"
              items={this.state.Master}
              value={this.state.formData.cmbMaster}
              handleOnChange={this.handleOnChange}
              width={250}
              maxLength={100}
              colID="vac_master"
            />
          </Grid>
          {isValidItem(
            this.state.Master,
            "vac_master",
            this.state.formData.cmbMaster
          ) && (
            <Grid item>
              <CtCmb
                id="cmbAction"
                label="Excel Action*"
                items={this.state.Action}
                value={this.state.formData.cmbAction}
                handleOnChange={this.handleOnChange}
                width={160}
                maxLength={100}
                colID="vac_action"
              />
            </Grid>
          )}

          {this.state.formData.cmbAction == "Import" && (
            <>
              <Grid item marginTop={1}>
                <input type="file" id="input" />
                <CtBtn
                  className="excel-button"
                  label="Read Excel"
                  iconName="excel"
                  onClick={this.ReadExcel}
                  firstletterBig={false}
                />
              </Grid>
              <Grid item marginTop={1}>
                <CtBtn
                  className="excel-button"
                  label="Import Excel"
                  iconName="excel"
                  firstletterBig={false}
                  onClick={this.ImportExcel}
                  disabled={this.state.disableSave}
                />
              </Grid>
            </>
          )}
          {this.state.formData.cmbAction == "Export" && (
            <Grid item marginTop={1}>
              <CSVLink
                data={this.state.rows}
                filename={this.state.formData.cmbMaster}
                headers={this.state.excelHeaders}
              >
                <CtBtn
                  className="excel-button"
                  label="Export Excel"
                  iconName="excel"
                  firstletterBig={false}
                  onClick={this.ExportExcel}
                />
              </CSVLink>
            </Grid>
          )}
        </Grid>

        {this.state.formData.cmbAction == "Import" && (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            marginTop={1}
          >
            {/* responsive table */}
            <Grid item className="maintable">
              <TableList
                columns={this.state.columnsdata}
                rows={this.state.rows}
                rowsCount={this.state.rows.length}
                rowsPerPage={this.state.rowsPerPage}
                dataRowBgColor={"#fa968e"}
                dataRowBgColorCondition={{
                  condition: [
                    {
                      colID: "ImportStatus",
                      value: "",
                      relationalOperator: "!=",
                    },
                  ],
                }}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </Grid>
          </Grid>
        )}

        {this.state.formData.cmbAction == "Export" && (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            marginTop={1}
          >
            <Grid item className="maintable">
              <TableList
                columns={this.state.columnsdata}
                rows={this.state.rows}
                rowsCount={this.state.rows.length}
                rowsPerPage={this.state.rowsPerPage}
                // dataRowBgColor={"#fa968e"}
                // dataRowBgColorCondition={{
                //   condition: [
                //     {
                //       colID: "ImportStatus",
                //       value: "",
                //       relationalOperator: "!=",
                //     },
                //     // {
                //     //   colID: "emp_info",
                //     //   value: undefined,
                //     //   relationalOperator: "!=",
                //     //   logicalOperator: "||",
                //     // },
                //   ],
                //   // condition: [
                //   //   {
                //   //     colID: "emp_info",
                //   //     value: undefined,
                //   //     relationalOperator: "!=",
                //   //   },
                //   // ],
                // }}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </Grid>
          </Grid>
        )}
        <Grid item>
          <Box display={{ xs: "none" }} style={{ textAlign: "right" }}>
            {this.state.ARD}
          </Box>
        </Grid>
      </form>
    );
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <ImportExportExcel {...props} navigate={navigate} />;
}
export default connect(
  mapStatetoProps,
  mapSetUserPrivileges_ToProps
)(HoCtToastContainer(HOCVerifyIsUser(withRouter(WithNavigate))));
