import React, { Component } from "react";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
// import "../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { fetchTextEditor } from "./API";

class ControlledEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
      displaySetTextErrorMsg: true,
    };
  }

  onEditorStateChange = (editorState) => {
    this.setState(
      {
        editorState,
      },
      () => {
        if (this.props.onEditorStateChange && this.props.id) {
          this.callBackEditorStateChange();
        }
      }
    );
  };

  callBackEditorStateChange = () => {
    this.props.onEditorStateChange({
      id: this.props.id,
      value: draftToHtml(
        convertToRaw(this.state.editorState.getCurrentContent())
      ),
    });
  };

  componentWillReceiveProps(newProps) {
    if (
      newProps.EditorState &&
      newProps.EditorState !== this.state.editorState &&
      this.state.displaySetTextErrorMsg === true &&
      this.props.id
    ) {
      if (this.props.resetEditorStateToUndefined) {
        this.setEditorState(
          newProps.EditorState,
          this.props.resetEditorStateToUndefined
        );
      } else {
        alert(
          "To set TextEditor's Text, you must have to define & pass resetEditorStateToUndefined."
        );
        this.setState({ displaySetTextErrorMsg: false });
      }
    }
  }

  setEditorState = (textInfo, callBackFunction = undefined) => {
    this.setState(
      {
        editorState: EditorState.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(textInfo))
        ),
      },
      () => {
        this.callBackEditorStateChange();
        if (callBackFunction !== undefined && this.props.id) {
          callBackFunction(this.props.id);
        }
      }
    );
  };

  render() {
    const { editorState } = this.state;
    return (
      // <div>
      <div
        style={{
          backgroundColor: "#F6F6F6",
          padding: "10px",
        }}
      >
        <Editor
          editorState={editorState}
          editorStyle={{ paddingLeft: "10px" }}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={this.onEditorStateChange}
          toolbar={{
            options: [
              "inline",
              "blockType",
              "fontSize",
              "fontFamily",
              "colorPicker",
              "list",
              "textAlign",
              "link",
              "embedded",
              "remove",
              "history",
            ],
          }}
        />
      </div>
      // </div>
    );
  }
}

export default ControlledEditor;
