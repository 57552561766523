import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import HOCVerifyIsUser from "../HOC/HOCVerifyIsUser";
import CtBtn from "./CustomTool/CtBtn";
import CtTxt from "./CustomTool/CtTxt";
import CtCmb from "./CustomTool/CtCmb";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";
import HoCtToastContainer from "../HOC/HoCtToastContainer";
import {
  applyTrimOnObjectValues,
  csvFileName,
  getRowOfRT,
  setKeyboardShortcuts,
} from "../SystemUtility/SystemUtility";
import {
  mapSetUserPrivileges_ToProps,
  mapStatetoProps,
} from "../mapRedux/mapReduxProps";
import { connect } from "react-redux";
import { withRouter } from "../HOC/withRouter";
import { fetchPriceOfBracket } from "./API";
import CtART from "./CustomTool/ResponsiveTable";
import CtTxtAmt from "./CustomTool/CtTxtAmt";

class PriceOfBracket extends Component {
  state = {
    Unit: [{ vac_type: "SET" }],
    columnsdata: [
      {
        accessorKey: "bint_srno",
        header: "Sr No",
      },
      {
        accessorKey: "dec_combi_brkt",
        header: "COMBI BRKT",
        size: 370,
      },
      {
        accessorKey: "dec_car_gp_brkt",
        header: "CAR GP BRKT (100 - 150)",
        size: 370,
      },

      {
        accessorKey: "dec_cw_gp_brkt",
        header: "C/W GP BRKT (150 - 200)",
        size: 370,
      },
    ],

    rows: [],
    formData: {
      txtcombibrkt: "",
      txtcargpbrkt: "",
      txtcwgpbrkt: "",
      bint_srno: 0,
    },
    dataLoadStatus: true,
    ARD: null,
    rowsCount: 0,
    rowsPerPage: 50,
  };

  //#region Component
  componentDidMount() {
    // document.title = "User Management : Top India";
    localStorage.setItem("Heading", "Price Of Bracket");
    this.props.verifyUserID(
      this.getInfo,
      this.props.setUserPrivileges,
      this.props.modules.bracketmaster
    );
    this.ShortCut();
    // this.getInfo();
  }

  componentWillUnmount() {
    this.removeShortCut();
  }
  //#endregion Component

  //#region Handle

  ShortCut = () => {
    this.removeShortCut = setKeyboardShortcuts(
      this.SavePriceOfBracket,
      this.clearInfo,
      () => {
        this.DeleteData(
          this.state.formData.bint_srno,
          this.state.formData.txtcombibrkt
        );
      }
    );
  };

  handleOnChange = (e) => {
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: e.target.value },
    });
  };

  handleARDonActionClick_Edit = (rtRow) => {
    const row = getRowOfRT(rtRow, [
      "bint_srno",
      "dec_combi_brkt",
      "dec_car_gp_brkt",
      "dec_cw_gp_brkt",
    ]);

    const bint_srno = row.bint_srno,
      txtcombibrkt = row.dec_combi_brkt,
      txtcargpbrkt = row.dec_car_gp_brkt,
      txtcwgpbrkt = row.dec_cw_gp_brkt;
    this.setState({
      formData: {
        ...this.state.formData,
        txtcombibrkt,
        txtcargpbrkt,
        bint_srno,
        txtcwgpbrkt,
      },
    });
    // window.scrollTo(0, 0);
    document.getElementById("txtcombibrkt").focus();
  };

  handleARDonActionClick_Delete = (rtRow) => {
    const row = getRowOfRT(rtRow, ["bint_srno", "dec_combi_brkt"]);
    this.DeleteData(row.bint_srno, row.dec_combi_brkt);
  };

  //#endregion Handle

  //#region function
  DeleteData = (bint_srno, dec_combi_brkt) => {
    if (bint_srno > 0) {
      this.setState({ ARD: null }, () => {
        let ARD = (
          <AlertResponsiveDialog
            labelAgree="Delete"
            labelDisagree="Cancel"
            alertTitle={
              "Do you want to Delete Price Of Bracket : " +
              dec_combi_brkt +
              " ?"
            }
            alertMessage=""
            handleOnClickYes={() => {
              return this.DeletePriceOfBracket(bint_srno);
            }}
            defaultOpenDialog={true}
            onYesClickCloseIfExeSuccessfully={true}
          />
        );

        this.setState({ ARD });
      });
    } else {
      this.props.toastErrorMsg("Select a record for deletion.");
    }
  };

  DeletePriceOfBracket = (bint_srno) => {
    this.props.notifyProcessing();
    const reqData = {
      Op: "DeletePriceOfBracket",
      bint_srno: bint_srno,
    };
    fetchPriceOfBracket(reqData)
      .then((res) => {
        this.props.updateProcessing(res.data.msgType, res.data.message);
        if (res.data.msgType === "success") {
          let rows = [],
            rowsCount = rows.length;
          if (
            res.data.ListPriceOfBracket &&
            res.data.ListPriceOfBracket.length > 0
          ) {
            rows = res.data.ListPriceOfBracket;
            rowsCount = rows.length;
          }
          this.setState(
            {
              rows,
              rowsCount,
              ARD: null,
            },
            () => {
              this.clearInfo();
            }
          );
          document.getElementById("txtcombibrkt").focus();
        }
      })
      .catch((error) => {
        this.props.updateProcessing(
          "error",
          "Unknown error occurred in delete Price Of Bracket. " + error
        );
      });
    return false;
  };

  getInfo = () => {
    this.props.notifyProcessing();
    const reqData = {
      Op: "ListPriceOfBracket",
    };
    fetchPriceOfBracket(reqData)
      .then((res) => {
        this.props.closeUpdateProcessing();
        let rows = res.data.ListPriceOfBracket,
          rowsCount = rows.length;
        this.setState({
          rowsCount,
          rows,
        });
      })
      .catch((error) => {
        console.log("Unknown error occurred in onFormLoad.", error);
      });
    document.getElementById("txtcombibrkt").focus();
  };

  clearInfo = () => {
    this.setState(
      {
        formData: {
          ...this.state.formData,
          bint_srno: "",
          txtcargpbrkt: "",
          txtcombibrkt: "",
          txtcwgpbrkt: "",
        },
      },
      () => {
        document.getElementById("txtcombibrkt").focus();
      }
    );
  };

  trimFormData = (nextFunctionCall) => {
    let formData = applyTrimOnObjectValues({
      formData: this.state.formData,
    });
    this.setState({ formData }, () => {
      if (nextFunctionCall) {
        nextFunctionCall();
      }
    });
  };

  validationPriceBraket = () => {
    let result = false;
    if (this.state.formData.txtcombibrkt.length <= 0) {
      this.props.toastErrorMsg("Enter Combi Bracket", "txtcombibrkt");
    } else if (this.state.formData.txtcargpbrkt.length <= 0) {
      this.props.toastErrorMsg("Enter CAR GE Bracket", "txtcargpbrkt");
    } else if (this.state.formData.txtcwgpbrkt.length <= 0) {
      this.props.toastErrorMsg("Enter C/W GE Bracket", "txtcwgpbrkt");
    } else {
      result = true;
    }
    return result;
  };

  SavePriceOfBracket = () => {
    this.trimFormData(() => {
      if (this.validationPriceBraket()) {
        this.props.notifyProcessing();
        const reqData = {
          Op: "SavePriceOfBracket",
          bint_srno: this.state.formData.bint_srno,
          dec_combi_brkt: this.state.formData.txtcombibrkt,
          dec_car_gp_brkt: this.state.formData.txtcargpbrkt,
          dec_cw_gp_brkt: this.state.formData.txtcwgpbrkt,
        };
        fetchPriceOfBracket(reqData)
          .then((res) => {
            this.props.updateProcessing(res.data.msgType, res.data.message);
            if (res.data.msgType === "success") {
              let rows = [],
                rowsCount = rows.length;
              if (
                res.data.ListPriceOfBracket &&
                res.data.ListPriceOfBracket.length > 0
              ) {
                rows = res.data.ListPriceOfBracket;
                rowsCount = rows.length;
              }
              this.setState(
                {
                  rows,
                  rowsCount,
                },
                () => {
                  this.clearInfo();
                }
              );
            }
          })
          .catch((error) => {
            console.log(
              "Unknown error occurred in Save Price Of Bracket .",
              error
            );
          });
      }
    });
  };

  //#endregion function

  render() {
    return (
      <form>
        <Grid container direction="row" justifyContent="flex-end">
          <Grid item>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <CtBtn
                  className="secondary-button"
                  label="Save"
                  iconName="save"
                  onClick={this.SavePriceOfBracket}
                />
              </Grid>
              <Grid item>
                <CtBtn
                  label="New"
                  className="new-button"
                  iconName="new"
                  onClick={this.clearInfo}
                />
              </Grid>
              <Grid item>
                <CtBtn
                  label="Delete"
                  className="delete-button"
                  iconName="delete"
                  onClick={() => {
                    this.DeleteData(
                      this.state.formData.bint_srno,
                      this.state.formData.txtcombibrkt
                    );
                  }}
                  disabled={
                    Number(this.state.formData.bint_srno) > 0 ? false : true
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          marginTop={0}
        >
          <Grid item>
            <CtTxtAmt
              id="txtcombibrkt"
              label="COMBI BRKT*"
              maxLength={100}
              width="220"
              value={this.state.formData.txtcombibrkt}
              handleOnChange={this.handleOnChange}
              onKeyDown={this.props.onKeyDown}
              nextCtrlID="txtcargpbrkt"
            />
          </Grid>
          <Grid item>
            <CtTxtAmt
              id="txtcargpbrkt"
              label="CAR GP BRKT (100 - 150)*"
              maxLength={100}
              width="250"
              value={this.state.formData.txtcargpbrkt}
              handleOnChange={this.handleOnChange}
              onKeyDown={this.props.onKeyDown}
              nextCtrlID="txtcwgpbrkt"
            />
          </Grid>
          <Grid item>
            <CtTxtAmt
              id="txtcwgpbrkt"
              label="C/W GP BRKT (150 - 200)*"
              maxLength={100}
              width="250"
              value={this.state.formData.txtcwgpbrkt}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          marginTop={1}
        >
          {/* responsive table */}
          <Grid item className="maintable">
            <CtART
              rowsdata={this.state.rows}
              columnsdata={this.state.columnsdata}
              rowsPerPage={this.state.rowsPerPage}
              tblmaxHeight={500}
              csvFileName={csvFileName("Price Of Bracket")}
              rowIconAction={[
                {
                  label: "Edit",
                  onClick: this.handleARDonActionClick_Edit,
                  icon: "Edit",
                  className: "secondary-button",
                },
                {
                  label: "Delete",
                  onClick: this.handleARDonActionClick_Delete,
                  icon: "delete",
                  className: "delete-button",
                },
              ]}
              showColumnFilters={false}
              actionColSize={130}
              density={"compact"}
              hideColIdList={["bint_srno"]}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Box display={{ xs: "none" }} style={{ textAlign: "right" }}>
            {this.state.ARD}
          </Box>
        </Grid>
      </form>
    );
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <PriceOfBracket {...props} navigate={navigate} />;
}
export default connect(
  mapStatetoProps,
  mapSetUserPrivileges_ToProps
)(HoCtToastContainer(HOCVerifyIsUser(withRouter(WithNavigate))));
