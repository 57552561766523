import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import HOCVerifyIsUser from "../HOC/HOCVerifyIsUser";
import HoCtToastContainer from "../HOC/HoCtToastContainer";
import "../App.css";
import CtTxt from "../Components/CustomTool/CtTxt";
import CtBtn from "../Components/CustomTool/CtBtn";
import MaterialTable from "../Components/CustomTool/MaterialTable";
import "./Table.css";

import { fetchDepartment, fetchpricecalculation } from "../Components/API";
import AlertResponsiveDialog from "../Components/CustomTool/AlertResponsiveDialog";
import Progress from "../Components/CustomTool/Progress";
import {
  applyTrimOnObjectValues,
  csvFileName,
  get_30DaysBack_FromCurrentDate,
  get_YMD_from_DMY,
  get_YMD_from_SYS,
  getFinancialYear,
  getRowOfRT,
} from "../SystemUtility/SystemUtility";
import ProposalEntry from "./ProposalEntry";
import { withRouter } from "../HOC/withRouter";
import { connect } from "react-redux";
import {
  mapSetUserPrivileges_ToProps,
  mapStatetoProps,
} from "../mapRedux/mapReduxProps";
import CtDtp from "./CustomTool/CtDtp";
import CtART from "./CustomTool/ResponsiveTable";
import { Person } from "@mui/icons-material";

class InputParametersList extends Component {
  state = {
    columnsdata: [
      {
        accessorKey: "vac_inquery_no",
        header: "Inquiry No",
      },
      {
        accessorKey: "vac_revision_no",
        header: "Revision No",
      },
      {
        accessorKey: "vac_sitename",
        header: "Site Name",
      },
      {
        accessorKey: "dat_inquery_date",
        header: "Date",
      },
      {
        accessorKey: "vac_show_price_icon",
        header: "vac_show_price_icon",
      },
      // {
      //   accessorKey: "Time",
      //   header: "Time",
      //   // size: 200,
      // },
      {
        accessorKey: "dec_sellingprice",
        header: "Selling Price",
      },
    ],
    rows: [
      /*  {
        InqueryNo: "Top/050724/1A",
        sitename: "bhavnagar",
      }, */
    ],
    rowsCount: 0,
    formData: {
      dtpfromdate: get_30DaysBack_FromCurrentDate(new Date()),
      dtptodate: get_YMD_from_SYS(new Date()),
    },
    Person: [],
    rowsPerPage: 50,
    dataLoadStatus: true,
    ARD: null,
  };

  //#region component

  componentDidMount() {
    localStorage.setItem("Heading", "Price Calculator List");
    // document.title = "Top India Elevator : Input Parameters List";
    // this.ShortCut();
    // // this.onFormLoad();
    this.props.verifyUserID(
      this.getInfo,
      this.props.setUserPrivileges,
      this.props.modules.pricecalculation
    );
  }

  // componentWillUnmount() {
  //   this.removeShortCut();
  // }

  //#endregion component

  //#region function

  // ShortCut = () => {
  //   this.removeShortCut = setKeyboardShortcuts(
  //     this.SaveDepartmentMaster,
  //     this.clearInfo,
  //     () => {
  //       this.DeleteData(this.state.formData.EditDepartment);
  //     }
  //   );
  // };

  getInfo = () => {
    this.props.notifyProcessing();
    const reqData = {
      Op: "LoadPriceCalculationList",
      dat_fromdate: this.state.formData.dtpfromdate,
      dat_todate: this.state.formData.dtptodate,
    };
    fetchpricecalculation(reqData)
      .then((res) => {
        this.props.closeUpdateProcessing();
        let rows =
            res.data.PriceCalculationList.length > 0
              ? res.data.PriceCalculationList
              : [],
          rowsCount = rows.length;
        let Person = [];
        Person =
          res.data.PassangerList.length > 0 ? res.data.PassangerList : [];
        this.setState({
          rows,
          rowsCount,
          Person,
        });
      })
      .catch((error) => {
        console.log("Unknown error occurred in onFormLoad.", error);
      });
  };

  trimFormData = (nextFunctionCall) => {
    let formData = applyTrimOnObjectValues({
      formData: this.state.formData,
    });
    this.setState({ formData }, () => {
      if (nextFunctionCall) {
        nextFunctionCall();
      }
    });
  };

  DeleteData = (vac_inquery_no, vac_revision_no, dat_inquery_date) => {
    if (vac_inquery_no.length > 0) {
      this.setState({ ARD: null }, () => {
        let ARD = (
          <AlertResponsiveDialog
            labelAgree="Delete"
            labelDisagree="Cancel"
            alertTitle={
              "Do you want to Delete Inquiry No : " + vac_inquery_no + " ?"
            }
            alertMessage=""
            handleOnClickYes={() => {
              return this.DeletePriceCalce(
                vac_inquery_no,
                vac_revision_no,
                dat_inquery_date
              );
            }}
            defaultOpenDialog={true}
            onYesClickCloseIfExeSuccessfully={true}
          />
        );

        this.setState({ ARD });
      });
    } else {
      this.props.toastErrorMsg("Select a record for deletion.");
    }
  };

  DeletePriceCalce = (vac_inquery_no, vac_revision_no, dat_inquery_date) => {
    // console.log("dat_inquery_date");
    this.props.notifyProcessing();
    const reqData = {
      Op: "DeletePriceCalculation",
      vac_inquery_no: vac_inquery_no,
      vac_revision_no: vac_revision_no,
      TblYear: getFinancialYear(get_YMD_from_DMY(dat_inquery_date)),
      dat_fromdate: this.state.formData.dtpfromdate,
      dat_todate: this.state.formData.dtptodate,
    };
    fetchpricecalculation(reqData)
      .then((res) => {
        this.props.updateProcessing(res.data.msgType, res.data.message);
        if (res.data.msgType === "success") {
          let rows = [],
            rowsCount = rows.length;
          if (
            res.data.PriceCalculationList &&
            res.data.PriceCalculationList.length > 0
          ) {
            rows = res.data.PriceCalculationList;
            rowsCount = rows.length;
          }
          this.setState({
            rows,
            rowsCount,
            ARD: null,
          });
          document.getElementById("dtpfromdate").focus();
        }
      })
      .catch((error) => {
        this.props.updateProcessing(
          "error",
          "Unknown error occurred in delete Input Parameters Master. " + error
        );
      });
    return false;
  };

  //#endregion function

  //#region handle

  handleOnChange = (e) => {
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: e.target.value },
    });
  };

  handleARDonActionClick_Delete = (rtRow) => {
    const row = getRowOfRT(rtRow, [
      "vac_inquery_no",
      "vac_revision_no",
      "dat_inquery_date",
    ]);
    this.DeleteData(
      row.vac_inquery_no,
      row.vac_revision_no,
      row.dat_inquery_date
    );
  };

  handleARDonActionClick_Edit = (rtRow) => {
    const row = getRowOfRT(rtRow, ["vac_inquery_no", "vac_revision_no"]);
    let Person = [],
      LoadCapacity = [];
    this.state.Person.forEach((row) => {
      if (row.vac_type == "Passenger") {
        Person.push({ dec_passenger: `${row.dec_passenger}` });
      } else {
        LoadCapacity.push({ dec_passenger: `${row.dec_passenger}` });
      }
    });
    let editData = this.state.rows.filter((item) => {
      if (
        item.vac_inquery_no == row.vac_inquery_no &&
        item.vac_revision_no == row.vac_revision_no
      ) {
        return item;
      }
    });

    this.props.navigateTo("InputParametersEntry", {
      editData,
      Person,
      LoadCapacity,
    });
  };

  handleARDonActionClick_PriceSummary = (rtRow) => {
    const row = getRowOfRT(rtRow, [
      "vac_inquery_no",
      "vac_revision_no",
      "vac_show_price_icon",
    ]);
    let Person = [],
      LoadCapacity = [];
    this.state.Person.forEach((row) => {
      if (row.vac_type == "Passenger") {
        Person.push({ dec_passenger: `${row.dec_passenger}` });
      } else {
        LoadCapacity.push({ dec_passenger: `${row.dec_passenger}` });
      }
    });
    let editData = this.state.rows.filter((item) => {
      if (
        item.vac_inquery_no == row.vac_inquery_no &&
        item.vac_revision_no == row.vac_revision_no
      ) {
        return item;
      }
    });

    console.log("row.vac_show_price_icon", row.vac_show_price_icon);

    this.props.navigateTo("InputParametersEntry", {
      editData,
      Person,
      LoadCapacity,
      showPriceCalculationOutput: row.vac_show_price_icon,
    });
  };

  handleOnNewEntryClick = () => {
    let Person = [],
      LoadCapacity = [];
    this.state.Person.forEach((row) => {
      if (row.vac_type == "Passenger") {
        Person.push({ dec_passenger: `${row.dec_passenger}` });
      } else {
        LoadCapacity.push({ dec_passenger: `${row.dec_passenger}` });
      }
    });
    this.props.navigateTo("InputParametersEntry", { Person, LoadCapacity });
  };

  //#endregion handle

  render() {
    // console.log("this.props.PriceCalculation", this.props.PriceCalculation);

    return (
      <form>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          marginTop={0}
          spacing={3}
        >
          <Grid item>
            <CtDtp
              id="dtpfromdate"
              value={this.state.formData.dtpfromdate}
              label="From Date*"
              handleOnChange={this.handleOnChange}
              maxLength={50}
              onKeyDown={this.props.onKeyDown}
              // disabled={true}
              width={150}
            />
          </Grid>
          <Grid item>
            <CtDtp
              id="dtptodate"
              value={this.state.formData.dtptodate}
              label="To Date*"
              handleOnChange={this.handleOnChange}
              maxLength={50}
              onKeyDown={this.props.onKeyDown}
              // disabled={true}
              width={150}
            />
          </Grid>
          <Grid item marginTop={1}>
            <CtBtn
              label={"Load"}
              iconName={"load"}
              className={"child-button"}
              variant={"outlined"}
              firstletterBig={false}
              onClick={this.getInfo}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          className="title"
          marginTop={3}
        >
          <Grid item marginTop={3} className="maintable">
            <CtART
              rowsdata={this.state.rows}
              columnsdata={this.state.columnsdata}
              rowsPerPage={this.state.rowsPerPage}
              tblmaxHeight={500}
              csvFileName={csvFileName("InputParametersList")}
              rowIconAction={[
                {
                  label: "Edit",
                  onClick: this.handleARDonActionClick_Edit,
                  icon: "Edit",
                  className: "secondary-button",
                },
                this.props.userPrivilege["Entry:Price Calculator"] == "M"
                  ? {
                      label: "Delete",
                      onClick: this.handleARDonActionClick_Delete,
                      icon: "delete",
                      className: "delete-button",
                    }
                  : "",
                {
                  label: "Price Summary",
                  onClick: this.handleARDonActionClick_PriceSummary,
                  icon: "Currency",
                  className: "child-button",
                },
              ]}
              tableButtonAction={[
                this.props.userPrivilege["Entry:Price Calculator"] != "R"
                  ? {
                      label: "New Price Calculation",
                      onClick: this.handleOnNewEntryClick,
                      addPluseIcon: true,
                    }
                  : "",
              ]}
              showColumnFilters={false}
              actionColSize={120}
              density={"compact"}
              hideColIdList={["vac_show_price_icon"]}
            />
          </Grid>
          <Grid item>
            <Box display={{ xs: "none" }} style={{ textAlign: "right" }}>
              {this.state.ARD}
            </Box>
          </Grid>
        </Grid>
      </form>
    );
  }
}

// export default withRouter(InputParametersList);

function WithNavigate(props) {
  let navigate = useNavigate();
  return <InputParametersList {...props} navigate={navigate} />;
}
export default connect(
  mapStatetoProps,
  mapSetUserPrivileges_ToProps
)(HoCtToastContainer(HOCVerifyIsUser(withRouter(WithNavigate))));
