import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import HOCVerifyIsUser from "../HOC/HOCVerifyIsUser";
import CtBtn from "../Components/CustomTool/CtBtn";
import CtTxt from "../Components/CustomTool/CtTxt";
import CtTextFieldPWD from "../Components/CustomTool/CtTxtPWD";
import CtCmb from "../Components/CustomTool/CtCmb";
import CtTxtNum from "../Components/CustomTool/CtTxtNum";
import MaterialTable from "../Components/CustomTool/MaterialTable";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import AlertResponsiveDialog from "../Components/CustomTool/AlertResponsiveDialog";
// import DepartmentMaster from "./DepartmentMaster";
import { Stack } from "@mui/material";
// import PlantMaster from "./PlantMaster";
// import { fetchUser } from "../Components/API";
import HoCtToastContainer from "../HOC/HoCtToastContainer";
import {
  applyTrimOnObjectValues,
  csvFileName,
  getRowOfRT,
  setKeyboardShortcuts,
} from "../SystemUtility/SystemUtility";
import {
  mapSetUserPrivileges_ToProps,
  mapStatetoProps,
} from "../mapRedux/mapReduxProps";
import { connect } from "react-redux";
import { withRouter } from "../HOC/withRouter";
import { fetchGoodsCabin, fetchUser } from "./API";
import MobileTable from "./CustomTool/MobileTable";
import CtART from "./CustomTool/ResponsiveTable";
import axios from "axios";
import CtTxtAmt from "../Components/CustomTool/CtTxtAmt";
import CtCmbEditable from "./CustomTool/CtCmbEditable";
import TableList from "./CustomTool/TableList";

class GoodsLiftCabin extends Component {
  state = {
    Unit: [{ vac_type: "Type" }],
    columnsdata: [
      {
        accessorKey: "bint_srno",
        header: "bint_srno",
      },
      {
        accessorKey: "bint_goods",
        header: "Goods Lift",
      },
      {
        accessorKey: "bint_width",
        header: "Inside Width",
      },
      {
        accessorKey: "bint_depth",
        header: "Inside Depth",
      },
      {
        accessorKey: "bint_platform_width",
        header: "Platform Width",
      },
      {
        accessorKey: "bint_platform_depth",
        header: "Platform Depth",
      },
      {
        accessorKey: "dec_platform2mm_ms",
        header: "Platform 2mm MS KG",
      },
      {
        accessorKey: "bint_4x2_channel",
        header: "No Of 4x2 Channel",
      },
    ],

    rows: [],
    formData: {
      bint_srno: 0,
      txtGoods: "",
      txtInsideWidth: "",
      txtInsideDepth: "",
      txtPlatformWidth: "",
      txtPlatformDepth: "",
      txtPlatform: "",
      txtNoOfChannel: "",
    },

    MaterialColumn: [
      {
        id: "vac_doormaterialgrade",
        label: "Material Grade",
      },
      {
        id: "bint_material_kg",
        label: "Material KG",
        type: "CtTxtNum",
        CtrlProps: {
          width: 100,
          maxLength: 10,
        },
      },
    ],

    MaterialRowsPerPage: 50,
    MaterialRows: [],
    BlankMaterialRows: [],
    MaterialRowsCount: 0,
    dataLoadStatus: true,
    ARD: null,
    rowsCount: 0,
    rowsPerPage: 50,
  };

  //#region Component
  componentDidMount() {
    // document.title = "User Management : Top India";
    localStorage.setItem("Heading", "Goods Lift Cabin Price List Entry");
    this.props.verifyUserID(
      this.getInfo,
      this.props.setUserPrivileges,
      this.props.modules.goodscabin
    );
    this.ShortCut();
  }

  componentWillUnmount() {
    this.removeShortCut();
  }

  //#endregion Component

  //#region Handle

  ShortCut = () => {
    this.removeShortCut = setKeyboardShortcuts(
      this.saveGoodsCabin,
      () => {
        this.clearInfo(true);
      },
      () => {
        this.DeleteData(
          this.state.formData.bint_srno,
          this.state.formData.txtGoods
        );
      }
    );
  };

  handleOnChange = (e) => {
    this.setState(
      {
        formData: { ...this.state.formData, [e.target.name]: e.target.value },
      },
      () => {
        if (e.target.name == "txtInsideWidth") {
          let txtPlatformWidth =
              Number(this.state.formData.txtInsideWidth) > 0
                ? Number(this.state.formData.txtInsideWidth) + 60 + 215
                : 0,
            txtPlatform = 0;

          if (
            Number(e.target.value) > 0 &&
            Number(this.state.formData.txtPlatformDepth) > 0
          ) {
            txtPlatform =
              (txtPlatformWidth *
                Number(this.state.formData.txtPlatformDepth) *
                2 *
                7.86) /
              1000000;
            txtPlatform = txtPlatform.toFixed(2);
          }
          this.setState({
            formData: {
              ...this.state.formData,
              txtPlatformWidth,
              txtPlatform,
            },
          });
        } else if (e.target.name == "txtInsideDepth") {
          let txtPlatformDepth =
              Number(this.state.formData.txtInsideDepth) > 0
                ? Number(this.state.formData.txtInsideDepth) + 125 + 215
                : 0,
            txtPlatform = 0;
          if (
            Number(this.state.formData.txtPlatformWidth) > 0 &&
            Number(e.target.value) > 0
          ) {
            txtPlatform =
              (Number(this.state.formData.txtPlatformWidth) *
                txtPlatformDepth *
                2 *
                7.86) /
              1000000;

            txtPlatform = txtPlatform.toFixed(2);
          }
          this.setState({
            formData: {
              ...this.state.formData,
              txtPlatformDepth,
              txtPlatform,
            },
          });
        }
      }
    );
  };

  handleARDonActionClick_Edit = (rtRow) => {
    console.log("rtRow", rtRow.original.goodsmaterailkglist);
    const row = getRowOfRT(rtRow, [
      "bint_srno",
      "bint_goods",
      "bint_width",
      "bint_depth",
      "bint_platform_width",
      "bint_platform_depth",
      "dec_platform2mm_ms",
      "bint_4x2_channel",
    ]);
    const bint_srno = row.bint_srno,
      txtGoods = row.bint_goods,
      txtInsideWidth = row.bint_width,
      txtInsideDepth = row.bint_depth,
      txtPlatformWidth = row.bint_platform_width,
      txtPlatformDepth = row.bint_platform_depth,
      txtPlatform = row.dec_platform2mm_ms,
      txtNoOfChannel = row.bint_4x2_channel;

    let MaterialRows = this.state.MaterialRows.map((ListRow) => {
      let MatchingRow = rtRow.original.goodsmaterailkglist.find(
        (EditRow) =>
          ListRow.vac_doormaterialgrade == EditRow.vac_doormaterialgrade
      );
      if (MatchingRow) {
        return { ...ListRow, ...MatchingRow };
      }
      return { ...ListRow };
    });

    this.setState({
      formData: {
        ...this.state.formData,
        bint_srno,
        txtGoods,
        txtInsideWidth,
        txtInsideDepth,
        txtPlatformWidth,
        txtPlatformDepth,
        txtPlatform,
        txtNoOfChannel,
      },
      MaterialRows,
      MaterialRowsCount: MaterialRows.length,
    });
    document.getElementById("txtGoods").focus();
  };
  handleARDonActionClick_Delete = (rtRow) => {
    const row = getRowOfRT(rtRow, ["bint_srno", "bint_goods"]);
    this.DeleteData(row.bint_srno, row.bint_goods);
  };

  handleChangeMaterialRowsPerPage = (MaterialRowsPerPage) => {
    this.setState({ MaterialRowsPerPage });
  };

  handleOnMaterialRowDataChange = (rows) => {
    this.setState({
      MaterialRows: rows,
      MaterialRowsCount: rows.length,
    });
  };

  //#endregion Handle

  //#region function

  getInfo = () => {
    this.props.notifyProcessing();
    const reqData = {
      Op: "ListGoodsCabin",
    };
    fetchGoodsCabin(reqData)
      .then((res) => {
        this.props.closeUpdateProcessing();
        let rows = res.data.GoodsCabinList,
          rowsCount = rows.length,
          MaterialRows = res.data.DoorMaterialGradeList,
          MaterialRowsCount = MaterialRows.length;
        this.setState({
          rowsCount,
          rows,
          MaterialRows,
          BlankMaterialRows: MaterialRows,
          MaterialRowsCount,
        });
      })
      .catch((error) => {
        console.log("Unknown error occurred in onFormLoad.", error);
      });
    document.getElementById("txtGoods").focus();
  };

  clearInfo = (clearRowData = false) => {
    let MaterialRows =
      clearRowData == true
        ? this.state.BlankMaterialRows
        : this.state.MaterialRows;
    this.setState({
      formData: {
        ...this.state.formData,
        bint_srno: 0,
        txtGoods: "",
        txtInsideWidth: "",
        txtInsideDepth: "",
        txtPlatformWidth: "",
        txtPlatformDepth: "",
        txtPlatform: "",
        txtNoOfChannel: "",
      },
      MaterialRows,
      MaterialRowsCount: MaterialRows.length,
    });
  };

  trimFormData = (nextFunctionCall) => {
    let formData = applyTrimOnObjectValues({
      formData: this.state.formData,
    });
    this.setState({ formData }, () => {
      if (nextFunctionCall) {
        nextFunctionCall();
      }
    });
  };

  validationGoodsCabin = () => {
    let result = false;
    if (Number(this.state.formData.txtGoods) <= 0) {
      this.props.toastErrorMsg("Enter Goods", "txtGoods");
    } else if (Number(this.state.formData.txtInsideWidth) <= 0) {
      this.props.toastErrorMsg("Enter Inside Width", "txtInsideWidth");
    } else if (Number(this.state.formData.txtInsideDepth) <= 0) {
      this.props.toastErrorMsg("Enter Inside Widt", "txtInsideDepth");
    } else if (Number(this.state.formData.txtPlatformWidth) <= 0) {
      this.props.toastErrorMsg("Enter Platform Width", "txtPlatformWidth");
    } else if (Number(this.state.formData.txtPlatformDepth) <= 0) {
      this.props.toastErrorMsg("Enter Platform Depth", "txtPlatformDepth");
    } else if (Number(this.state.formData.txtPlatform) <= 0) {
      this.props.toastErrorMsg("Enter Platform 2mm MS KG", "txtPlatform");
    } else if (Number(this.state.formData.txtNoOfChannel) <= 0) {
      this.props.toastErrorMsg("Enter No Of 4x2 Channel", "txtNoOfChannel");
    } else {
      result = true;
    }
    return result;
  };

  saveGoodsCabin = () => {
    this.trimFormData(() => {
      if (this.validationGoodsCabin()) {
        this.props.notifyProcessing();
        const reqData = {
          Op: "SaveGoodsCabin",
          bint_srno: this.state.formData.bint_srno,
          bint_goods: this.state.formData.txtGoods,
          bint_width: this.state.formData.txtInsideWidth,
          bint_depth: this.state.formData.txtInsideDepth,
          bint_platform_width: this.state.formData.txtPlatformWidth,
          bint_platform_depth: this.state.formData.txtPlatformDepth,
          dec_platform2mm_ms: this.state.formData.txtPlatform,
          bint_4x2_channel: this.state.formData.txtNoOfChannel,
          goodsmaterailkglist: this.state.MaterialRows,
        };
        fetchGoodsCabin(reqData)
          .then((res) => {
            this.props.updateProcessing(res.data.msgType, res.data.message);
            if (res.data.msgType === "success") {
              let rows = [],
                rowsCount = rows.length,
                MaterialRows = [],
                MaterialRowsCount = MaterialRows.length;
              if (
                res.data.GoodsCabinList &&
                res.data.GoodsCabinList.length > 0
              ) {
                rows = res.data.GoodsCabinList;
                rowsCount = rows.length;
              }
              if (
                res.data.DoorMaterialGradeList &&
                res.data.DoorMaterialGradeList.length > 0
              ) {
                MaterialRows = res.data.DoorMaterialGradeList;
                MaterialRowsCount = MaterialRows.length;
              }
              this.setState(
                {
                  rows,
                  rowsCount,
                  MaterialRows,
                  MaterialRowsCount,
                },
                () => {
                  this.clearInfo();
                }
              );
            }
          })
          .catch((error) => {
            console.log(
              "Unknown error occurred in Save Goods Cabin Price .",
              error
            );
          });
      }
    });
  };

  DeleteData = (bint_srno, bint_goods) => {
    if (bint_srno > 0) {
      this.setState({ ARD: null }, () => {
        let ARD = (
          <AlertResponsiveDialog
            labelAgree="Delete"
            labelDisagree="Cancel"
            alertTitle={`Do you want to Delete Goods : ${bint_goods} ?`}
            alertMessage=""
            handleOnClickYes={() => {
              return this.DeleteGoodsCabin(bint_srno);
            }}
            defaultOpenDialog={true}
            onYesClickCloseIfExeSuccessfully={true}
          />
        );
        this.setState({ ARD });
      });
    } else {
      this.props.toastErrorMsg("Select a record for deletion.");
    }
  };

  DeleteGoodsCabin = (bint_srno) => {
    this.props.notifyProcessing();
    const reqData = {
      Op: "DeleteGoodsCabin",
      bint_srno: bint_srno,
    };
    fetchGoodsCabin(reqData)
      .then((res) => {
        this.props.updateProcessing(res.data.msgType, res.data.message);
        if (res.data.msgType === "success") {
          let rows = [],
            rowsCount = rows.length,
            MaterialRows = [],
            MaterialRowsCount = MaterialRows.length;
          if (res.data.GoodsCabinList && res.data.GoodsCabinList.length > 0) {
            rows = res.data.GoodsCabinList;
            rowsCount = rows.length;
          }
          if (
            res.data.DoorMaterialGradeList &&
            res.data.DoorMaterialGradeList.length > 0
          ) {
            MaterialRows = res.data.DoorMaterialGradeList;
            MaterialRowsCount = MaterialRows.length;
          }
          this.setState(
            {
              rows,
              rowsCount,
              MaterialRows,
              MaterialRowsCount,
              ARD: null,
            },
            () => {
              this.clearInfo();
            }
          );
        }
      })
      .catch((error) => {
        this.props.updateProcessing(
          "error",
          "Unknown error occurred in delete Goods Cabin. " + error
        );
      });
    return false;
  };

  //#endregion function

  render() {
    return (
      <form>
        <Grid container direction="row" justifyContent="flex-end">
          <Grid item>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <CtBtn
                  className="secondary-button"
                  label="Save"
                  iconName="save"
                  onClick={this.saveGoodsCabin}
                />
              </Grid>
              <Grid item>
                <CtBtn
                  label="New"
                  className="new-button"
                  iconName="new"
                  onClick={() => {
                    this.clearInfo(true);
                  }}
                />
              </Grid>
              <Grid item>
                <CtBtn
                  label="Delete"
                  className="delete-button"
                  iconName="delete"
                  onClick={() => {
                    this.DeleteData(
                      this.state.formData.bint_srno,
                      this.state.formData.txtGoods
                    );
                  }}
                  disabled={
                    Number(this.state.formData.bint_srno) > 0 ? false : true
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          marginTop={0}
        >
          <Grid item>
            <CtTxtNum
              id="txtGoods"
              label="Goods Lift*"
              value={this.state.formData.txtGoods}
              handleOnChange={this.handleOnChange}
              onKeyDown={this.props.onKeyDown}
              width={220}
              maxLength={10}
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtInsideWidth"
              label="Inside Width*"
              value={this.state.formData.txtInsideWidth}
              handleOnChange={this.handleOnChange}
              onKeyDown={this.props.onKeyDown}
              width={220}
              maxLength={10}
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtInsideDepth"
              label="Inside Depth*"
              value={this.state.formData.txtInsideDepth}
              handleOnChange={this.handleOnChange}
              onKeyDown={this.props.onKeyDown}
              width={220}
              maxLength={10}
            />
          </Grid>

          <Grid item>
            <CtTxtNum
              id="txtPlatformWidth"
              label="Platform Width*"
              value={this.state.formData.txtPlatformWidth}
              handleOnChange={this.handleOnChange}
              onKeyDown={this.props.onKeyDown}
              width={220}
              maxLength={10}
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtPlatformDepth"
              label="Platform Depth*"
              value={this.state.formData.txtPlatformDepth}
              handleOnChange={this.handleOnChange}
              onKeyDown={this.props.onKeyDown}
              width={220}
              maxLength={10}
            />
          </Grid>
          <Grid item>
            <CtTxtAmt
              id="txtPlatform"
              label="Platform 2mm MS KG*"
              maxLength={10}
              width="220"
              value={this.state.formData.txtPlatform}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtNoOfChannel"
              label="No Of 4x2 Channel*"
              value={this.state.formData.txtNoOfChannel}
              handleOnChange={this.handleOnChange}
              width={220}
              maxLength={10}
            />
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          marginTop={2}
        >
          <Grid item>
            <TableList
              noMobileLayout={true}
              columns={this.state.MaterialColumn}
              rows={this.state.MaterialRows}
              rowsCount={this.state.MaterialRowsCount}
              rowsPerPage={this.state.MaterialRowsPerPage}
              handleChangeRowsPerPage={this.handleChangeMaterialRowsPerPage}
              handleOnRowDataChange={this.handleOnMaterialRowDataChange}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          marginTop={1}
        >
          {/* responsive table */}
          <Grid item className="maintable">
            <CtART
              rowsdata={this.state.rows}
              columnsdata={this.state.columnsdata}
              rowsPerPage={this.state.rowsPerPage}
              tblmaxHeight={500}
              csvFileName={csvFileName("Goods Lift Cabin Price")}
              rowIconAction={[
                {
                  label: "Edit",
                  onClick: this.handleARDonActionClick_Edit,
                  icon: "Edit",
                  className: "secondary-button",
                },
                {
                  label: "Delete",
                  onClick: this.handleARDonActionClick_Delete,
                  icon: "delete",
                  className: "delete-button",
                },
              ]}
              showColumnFilters={false}
              actionColSize={90}
              density={"compact"}
              hideColIdList={["bint_srno"]}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Box display={{ xs: "none" }} style={{ textAlign: "right" }}>
            {this.state.ARD}
          </Box>
        </Grid>
      </form>
    );
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <GoodsLiftCabin {...props} navigate={navigate} />;
}
export default connect(
  mapStatetoProps,
  mapSetUserPrivileges_ToProps
)(HoCtToastContainer(HOCVerifyIsUser(withRouter(WithNavigate))));
