import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import HOCVerifyIsUser from "../HOC/HOCVerifyIsUser";
import CtBtn from "../Components/CustomTool/CtBtn";
import CtTxt from "../Components/CustomTool/CtTxt";
import CtTextFieldPWD from "../Components/CustomTool/CtTxtPWD";
import CtCmb from "../Components/CustomTool/CtCmb";
import CtTxtNum from "../Components/CustomTool/CtTxtNum";
import MaterialTable from "../Components/CustomTool/MaterialTable";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import AlertResponsiveDialog from "../Components/CustomTool/AlertResponsiveDialog";
// import DepartmentMaster from "./DepartmentMaster";
import { Stack } from "@mui/material";
// import PlantMaster from "./PlantMaster";
// import { fetchUser } from "../Components/API";
import HoCtToastContainer from "../HOC/HoCtToastContainer";
import {
  applyTrimOnObjectValues,
  csvFileName,
  getRowOfRT,
  setKeyboardShortcuts,
} from "../SystemUtility/SystemUtility";
import {
  mapSetUserPrivileges_ToProps,
  mapStatetoProps,
} from "../mapRedux/mapReduxProps";
import { connect } from "react-redux";
import { withRouter } from "../HOC/withRouter";
import { fetchRopePrice, fetchUser } from "./API";
import MobileTable from "./CustomTool/MobileTable";
import CtART from "./CustomTool/ResponsiveTable";
import axios from "axios";
import CtTxtAmt from "../Components/CustomTool/CtTxtAmt";
import CtCmbEditable from "./CustomTool/CtCmbEditable";

class MainGovernorWire extends Component {
  state = {
    Unit: [{ vac_unit: "MTR" }],
    Type: [
      { vac_type: "IWRC (8x19)" },
      { vac_type: "FMC (6x19)" },
      { vac_type: "FMC (8x19)" },
    ],
    Make: [],

    columnsdata: [
      {
        accessorKey: "bint_srno",
        header: "Sr No",
      },
      {
        accessorKey: "bint_ropedia",
        header: "Rope Dia (IN mm)",
        size: 220,
      },
      {
        accessorKey: "vac_type",
        header: "Type",
        size: 220,
      },

      {
        accessorKey: "vac_make",
        header: "Make",
      },
      {
        accessorKey: "dec_kgmtr",
        header: "KG / MTR",
      },
      {
        accessorKey: "dec_priceperunit",
        header: "Price Per Unit",
        size: 220,
      },

      {
        accessorKey: "vac_unit",
        header: "Unit",
      },
    ],

    rows: [],
    formData: {
      bint_srno: 0,
      txtRope: "",
      cmbType: "",
      cmbMake: "",
      txtKg: "",
      txtPrice: "",
      cmbUnit: "",
    },
    dataLoadStatus: true,
    ARD: null,
    rowsCount: 0,
    rowsPerPage: 50,
  };

  //#region Component
  componentDidMount() {
    // document.title = "User Management : Top India";
    localStorage.setItem(
      "Heading",
      "Main & Governor Wire Rope Price List & Technical Detail"
    );
    this.props.verifyUserID(
      this.getInfo,
      this.props.setUserPrivileges,
      this.props.modules.maingovernorwirerope
    );
    this.ShortCut();
  }

  componentWillUnmount() {
    this.removeShortCut();
  }

  //#endregion Component

  //#region Handle

  ShortCut = () => {
    this.removeShortCut = setKeyboardShortcuts(
      this.saveRopeDia,
      this.clearInfo,
      () => {
        this.DeleteData(
          this.state.formData.bint_srno,
          this.state.formData.txtRope,
          this.state.formData.cmbMake
        );
      }
    );
  };

  handleOnChange = (e) => {
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: e.target.value },
    });
  };

  handleARDonActionClick_Edit = (rtRow) => {
    const row = getRowOfRT(rtRow, [
      "bint_srno",
      "bint_ropedia",
      "vac_type",
      "vac_make",
      "dec_kgmtr",
      "dec_priceperunit",
      "vac_unit",
    ]);
    const bint_srno = row.bint_srno,
      txtRope = row.bint_ropedia,
      cmbType = row.vac_type,
      cmbMake = row.vac_make,
      txtKg = row.dec_kgmtr,
      txtPrice = row.dec_priceperunit,
      cmbUnit = row.vac_unit;
    this.setState({
      formData: {
        ...this.state.formData,
        bint_srno,
        txtRope,
        cmbType,
        cmbMake,
        txtKg,
        txtPrice,
        cmbUnit,
      },
    });
    document.getElementById("txtRope").focus();
  };

  handleARDonActionClick_Delete = (rtRow) => {
    const row = getRowOfRT(rtRow, ["bint_srno", "bint_ropedia", "vac_type"]);
    this.DeleteData(row.bint_srno, row.bint_ropedia, row.vac_type);
  };

  //#endregion Handle

  //#region function

  getInfo = () => {
    this.props.notifyProcessing();
    const reqData = {
      Op: "ListRopePrice",
    };
    fetchRopePrice(reqData)
      .then((res) => {
        this.props.closeUpdateProcessing();
        let rows = res.data.ListRopePrice,
          rowsCount = rows.length,
          Make = res.data.Make && res.data.Make.length > 0 ? res.data.Make : [];
        this.setState({
          rowsCount,
          rows,
          Make,
        });
      })
      .catch((error) => {
        console.log("Unknown error occurred in onFormLoad.", error);
      });
    document.getElementById("txtRope").focus();
  };

  clearInfo = () => {
    this.setState({
      formData: {
        ...this.state.formData,
        bint_srno: 0,
        txtRope: "",
        cmbType: "",
        cmbMake: "",
        txtKg: "",
        txtPrice: "",
        cmbUnit: "",
      },
    });
  };

  trimFormData = (nextFunctionCall) => {
    let formData = applyTrimOnObjectValues({
      formData: this.state.formData,
    });
    this.setState({ formData }, () => {
      if (nextFunctionCall) {
        nextFunctionCall();
      }
    });
  };

  validationRopePrice = () => {
    let result = false;
    if (this.state.formData.txtRope.length <= 0) {
      this.props.toastErrorMsg("Enter Rope Dia (IN mm)", "txtRope");
    } else if (this.state.formData.cmbType.length <= 0) {
      this.props.toastErrorMsg("Select Type (IWRC / FMC)", "cmbType");
    } else if (this.state.formData.cmbMake.length <= 0) {
      this.props.toastErrorMsg("Select Make", "cmbMake");
    } else if (this.state.formData.txtKg.length <= 0) {
      this.props.toastErrorMsg("Enter KG / MTR", "txtKg");
    } else if (this.state.formData.txtPrice.length <= 0) {
      this.props.toastErrorMsg("Enter Price Per Unit", "txtPrice");
    } else if (this.state.formData.cmbUnit.length <= 0) {
      this.props.toastErrorMsg("Select Unit", "cmbUnit");
    } else {
      result = true;
    }
    return result;
  };

  saveRopeDia = () => {
    this.trimFormData(() => {
      if (this.validationRopePrice()) {
        this.props.notifyProcessing();
        const reqData = {
          Op: "SaveRopePrice",
          bint_srno: this.state.formData.bint_srno,
          bint_ropedia: this.state.formData.txtRope,
          vac_type: this.state.formData.cmbType,
          vac_make: this.state.formData.cmbMake,
          dec_kgmtr: this.state.formData.txtKg,
          dec_priceperunit: this.state.formData.txtPrice,
          vac_unit: this.state.formData.cmbUnit,
        };
        fetchRopePrice(reqData)
          .then((res) => {
            this.props.updateProcessing(res.data.msgType, res.data.message);
            if (res.data.msgType === "success") {
              let rows = [],
                rowsCount = rows.length,
                Make =
                  res.data.Make && res.data.Make.length > 0
                    ? res.data.Make
                    : [];
              if (res.data.ListRopePrice && res.data.ListRopePrice.length > 0) {
                rows = res.data.ListRopePrice;
                rowsCount = rows.length;
              }
              this.setState(
                {
                  rows,
                  rowsCount,
                  Make,
                },
                () => {
                  this.clearInfo();
                }
              );
            }
          })
          .catch((error) => {
            console.log(
              "Unknown error occurred in Save Rope Dia Price .",
              error
            );
          });
      }
    });
  };

  DeleteData = (bint_srno, bint_ropedia, vac_type) => {
    if (bint_srno > 0) {
      this.setState({ ARD: null }, () => {
        let ARD = (
          <AlertResponsiveDialog
            labelAgree="Delete"
            labelDisagree="Cancel"
            alertTitle={`Do you want to Delete Rope Dia (IN mm) : ${bint_ropedia} And Type (IWRC / FMC) : ${vac_type} ?`}
            alertMessage=""
            handleOnClickYes={() => {
              return this.DeleteRopeDia(bint_srno);
            }}
            defaultOpenDialog={true}
            onYesClickCloseIfExeSuccessfully={true}
          />
        );
        this.setState({ ARD });
      });
    } else {
      this.props.toastErrorMsg("Select a record for deletion.");
    }
  };

  DeleteRopeDia = (bint_srno) => {
    this.props.notifyProcessing();
    const reqData = {
      Op: "DeleteRopePrice",
      bint_srno: bint_srno,
    };
    fetchRopePrice(reqData)
      .then((res) => {
        this.props.updateProcessing(res.data.msgType, res.data.message);
        if (res.data.msgType === "success") {
          let rows = [],
            rowsCount = rows.length,
            Make =
              res.data.Make && res.data.Make.length > 0 ? res.data.Make : [];
          if (res.data.ListRopePrice && res.data.ListRopePrice.length > 0) {
            rows = res.data.ListRopePrice;
            rowsCount = rows.length;
          }
          this.setState(
            {
              rows,
              rowsCount,
              ARD: null,
              Make,
            },
            () => {
              this.clearInfo();
            }
          );
        }
      })
      .catch((error) => {
        this.props.updateProcessing(
          "error",
          "Unknown error occurred in delete Rope Dia. " + error
        );
      });
    return false;
  };

  //#endregion function

  render() {
    return (
      <form>
        <Grid container direction="row" justifyContent="flex-end">
          <Grid item>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <CtBtn
                  className="secondary-button"
                  label="Save"
                  iconName="save"
                  onClick={this.saveRopeDia}
                />
              </Grid>
              <Grid item>
                <CtBtn
                  label="New"
                  className="new-button"
                  iconName="new"
                  onClick={this.clearInfo}
                />
              </Grid>
              <Grid item>
                <CtBtn
                  label="Delete"
                  className="delete-button"
                  iconName="delete"
                  onClick={() => {
                    this.DeleteData(
                      this.state.formData.bint_srno,
                      this.state.formData.txtRope,
                      this.state.formData.cmbMake
                    );
                  }}
                  disabled={this.state.formData.bint_srno > 0 ? false : true}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          marginTop={0}
        >
          <Grid item>
            <CtTxtNum
              id="txtRope"
              label="Rope Dia (IN mm)*"
              maxLength={100}
              width="220"
              value={this.state.formData.txtRope}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
              nextCtrlID="cmbType"
            />
          </Grid>
          <Grid item>
            <CtCmb
              id="cmbType"
              label="Type (IWRC / FMC)*"
              items={this.state.Type}
              value={this.state.formData.cmbType}
              handleOnChange={this.handleOnChange}
              width={220}
              maxLength={100}
              colID="vac_type"
            />
          </Grid>
          <Grid item>
            <CtCmbEditable
              id="cmbMake"
              label="Make*"
              items={this.state.Make}
              value={this.state.formData.cmbMake}
              handleOnChange={this.handleOnChange}
              width={220}
              maxLength={100}
              colID="vac_make"
            />
          </Grid>
          <Grid item>
            <CtTxtAmt
              id="txtKg"
              label="KG / MTR*"
              maxLength={100}
              width="220"
              value={this.state.formData.txtKg}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
              nextCtrlID="txtPrice"
            />
          </Grid>
          <Grid item>
            <CtTxtAmt
              id="txtPrice"
              label="Price Per Unit*"
              maxLength={100}
              width="220"
              value={this.state.formData.txtPrice}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
              RupeeSymbol={true}
            />
          </Grid>
          <Grid item>
            <CtCmb
              id="cmbUnit"
              label="Unit*"
              items={this.state.Unit}
              value={this.state.formData.cmbUnit}
              handleOnChange={this.handleOnChange}
              width={220}
              maxLength={100}
              colID="vac_unit"
            />
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          marginTop={1}
        >
          {/* responsive table */}
          <Grid item className="maintable">
            <CtART
              rowsdata={this.state.rows}
              columnsdata={this.state.columnsdata}
              rowsPerPage={this.state.rowsPerPage}
              tblmaxHeight={500}
              csvFileName={csvFileName("Rail Price Master")}
              rowIconAction={[
                {
                  label: "Edit",
                  onClick: this.handleARDonActionClick_Edit,
                  icon: "Edit",
                  className: "secondary-button",
                },
                {
                  label: "Delete",
                  onClick: this.handleARDonActionClick_Delete,
                  icon: "delete",
                  className: "delete-button",
                },
              ]}
              showColumnFilters={false}
              actionColSize={90}
              density={"compact"}
              hideColIdList={["bint_srno"]}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Box display={{ xs: "none" }} style={{ textAlign: "right" }}>
            {this.state.ARD}
          </Box>
        </Grid>
      </form>
    );
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <MainGovernorWire {...props} navigate={navigate} />;
}
export default connect(
  mapStatetoProps,
  mapSetUserPrivileges_ToProps
)(HoCtToastContainer(HOCVerifyIsUser(withRouter(WithNavigate))));
