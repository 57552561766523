import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import HOCVerifyIsUser from "../HOC/HOCVerifyIsUser";
import CtBtn from "../Components/CustomTool/CtBtn";
import CtTxt from "../Components/CustomTool/CtTxt";
import CtTextFieldPWD from "../Components/CustomTool/CtTxtPWD";
import CtCmb from "../Components/CustomTool/CtCmb";
import CtTxtNum from "../Components/CustomTool/CtTxtNum";
import MaterialTable from "../Components/CustomTool/MaterialTable";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import AlertResponsiveDialog from "../Components/CustomTool/AlertResponsiveDialog";
// import DepartmentMaster from "./DepartmentMaster";
import { Stack } from "@mui/material";
// import PlantMaster from "./PlantMaster";
// import { fetchUser } from "../Components/API";
import HoCtToastContainer from "../HOC/HoCtToastContainer";
import {
  applyTrimOnObjectValues,
  csvFileName,
  getRowOfRT,
  setKeyboardShortcuts,
} from "../SystemUtility/SystemUtility";
import {
  mapSetUserPrivileges_ToProps,
  mapStatetoProps,
} from "../mapRedux/mapReduxProps";
import { connect } from "react-redux";
import { withRouter } from "../HOC/withRouter";
import { fetchCarAndCwFrame } from "./API";
import MobileTable from "./CustomTool/MobileTable";
import CtART from "./CustomTool/ResponsiveTable";
import axios from "axios";
import CtTxtAmt from "../Components/CustomTool/CtTxtAmt";
import CtCmbEditable from "./CustomTool/CtCmbEditable";
import CtMultiLineText from "./CustomTool/CtMultiLineText";

class CarAndCwFrameSelection extends Component {
  state = {
    FrameType: [{ vac_type: "REGULAR" }, { vac_type: "DOUBLE C.W FRAME" }],
    columnsdata: [
      {
        accessorKey: "bint_srno",
        header: "bint_srno",
      },
      {
        accessorKey: "bint_passenger",
        header: "Passenger / Load Capacity",
      },
      {
        accessorKey: "dec_speed",
        header: "Speed",
      },

      {
        accessorKey: "bint_bottomthickness",
        header: "Car Frame Bottom Thickness",
      },
      {
        accessorKey: "bint_uprightthickness",
        header: "Car Frame Upright Thickness",
      },
      {
        accessorKey: "bint_topthicknness",
        header: "Car Frame Top Thickness",
      },
      {
        accessorKey: "vac_type",
        header: "C/W Frame Type",
      },
      {
        accessorKey: "bint_framebottom",
        header: "C/W Frame Bottom",
      },
      {
        accessorKey: "bint_frameupright",
        header: "C/W Frame Upright",
      },
      {
        accessorKey: "bint_frametop",
        header: "C/W Frame Top",
      },
    ],

    rows: [],
    formData: {
      bint_srno: 0,
      txtPassenger: "",
      txtSpeed: "",
      txtCarBottomThickness: "",
      txtCarTopThickness: "",
      txtCarUprightThickness: "",
      cmbCwFrameType: "",
      txtCwFrameBottom: "",
      txtCwFrameUpright: "",
      txtCwFrameTop: "",
    },
    dataLoadStatus: true,
    ARD: null,
    rowsCount: 0,
    rowsPerPage: 50,
  };

  //#region Component
  componentDidMount() {
    // document.title = "User Management : Top India";
    localStorage.setItem("Heading", "Car Frame & C/W Frame Selection");
    this.props.verifyUserID(
      this.getInfo,
      this.props.setUserPrivileges,
      this.props.modules.carframe
    );
    this.ShortCut();
  }

  componentWillUnmount() {
    this.removeShortCut();
  }

  //#endregion Component

  //#region Handle

  ShortCut = () => {
    this.removeShortCut = setKeyboardShortcuts(
      this.SaveUser,
      this.clearInfo,
      () => {
        this.DeleteData(
          this.state.formData.bint_srno,
          this.state.formData.txtPassenger,
          this.state.formData.txtSpeed
        );
      }
    );
  };

  handleOnChange = (e) => {
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: e.target.value },
    });
  };

  handleARDonActionClick_Edit = (rtRow) => {
    const row = getRowOfRT(rtRow, [
      "bint_srno",
      "bint_passenger",
      "dec_speed",
      "bint_bottomthickness",
      "bint_uprightthickness",
      "bint_topthicknness",
      "vac_type",
      "bint_framebottom",
      "bint_frameupright",
      "bint_frametop",
    ]);
    const bint_srno = row.bint_srno,
      txtPassenger = row.bint_passenger,
      txtSpeed = row.dec_speed,
      txtCarBottomThickness = row.bint_bottomthickness,
      txtCarTopThickness = row.bint_topthicknness,
      txtCarUprightThickness = row.bint_uprightthickness,
      cmbCwFrameType = row.vac_type,
      txtCwFrameBottom = row.bint_framebottom,
      txtCwFrameUpright = row.bint_frameupright,
      txtCwFrameTop = row.bint_frametop;
    this.setState({
      formData: {
        ...this.state.formData,
        bint_srno,
        txtPassenger,
        txtSpeed,
        txtCarBottomThickness,
        txtCarTopThickness,
        txtCarUprightThickness,
        cmbCwFrameType,
        txtCwFrameBottom,
        txtCwFrameUpright,
        txtCwFrameTop,
      },
    });
    document.getElementById("txtPassenger").focus();
  };

  handleARDonActionClick_Delete = (rtRow) => {
    const row = getRowOfRT(rtRow, ["bint_srno", "bint_passenger", "dec_speed"]);
    this.DeleteData(row.bint_srno, row.bint_passenger, row.dec_speed);
  };

  //#endregion Handle

  //#region function

  getInfo = () => {
    this.props.notifyProcessing();
    const reqData = {
      Op: "ListCarAndCwFrame",
    };
    fetchCarAndCwFrame(reqData)
      .then((res) => {
        this.props.closeUpdateProcessing();
        let rows = res.data.ListCarAndCwFrame,
          rowsCount = rows.length;
        this.setState({
          rowsCount,
          rows,
        });
      })
      .catch((error) => {
        console.log("Unknown error occurred in onFormLoad.", error);
      });
    document.getElementById("txtPassenger").focus();
  };

  clearInfo = () => {
    this.setState(
      {
        formData: {
          ...this.state.formData,
          bint_srno: 0,
          txtPassenger: "",
          txtSpeed: "",
          txtCarBottomThickness: "",
          txtCarTopThickness: "",
          txtCarUprightThickness: "",
          cmbCwFrameType: "",
          txtCwFrameBottom: "",
          txtCwFrameUpright: "",
          txtCwFrameTop: "",
        },
      },
      () => {
        document.getElementById("txtPassenger").focus();
      }
    );
  };

  trimFormData = (nextFunctionCall) => {
    let formData = applyTrimOnObjectValues({
      formData: this.state.formData,
    });
    this.setState({ formData }, () => {
      if (nextFunctionCall) {
        nextFunctionCall();
      }
    });
  };

  validationCarAndCwFrame = () => {
    let result = false;
    if (this.state.formData.txtPassenger.length <= 0) {
      this.props.toastErrorMsg(
        "Enter Passenger / Load Capacity",
        "txtPassenger"
      );
    } else if (this.state.formData.txtSpeed.length <= 0) {
      this.props.toastErrorMsg("Enter Speed", "txtSpeed");
    } else if (this.state.formData.txtCarBottomThickness.length <= 0) {
      this.props.toastErrorMsg(
        "Enter Car Frame Bottom Thickness",
        "txtCarBottomThickness"
      );
    } else if (this.state.formData.txtCarUprightThickness.length <= 0) {
      this.props.toastErrorMsg(
        "Enter Car Frame Upright Thickness",
        "txtCarUprightThickness"
      );
    } else if (this.state.formData.txtCarTopThickness.length <= 0) {
      this.props.toastErrorMsg(
        "Enter Car Frame Top Thickness",
        "txtCarTopThickness"
      );
    } else if (this.state.formData.cmbCwFrameType.length <= 0) {
      this.props.toastErrorMsg("Select C/W Frame Type", "cmbCwFrameType");
    } else if (this.state.formData.txtCwFrameBottom.length <= 0) {
      this.props.toastErrorMsg("Enter C/W Frame Bottom", "txtCwFrameBottom");
    } else if (this.state.formData.txtCwFrameUpright.length <= 0) {
      this.props.toastErrorMsg("Enter C/W Frame Upright", "txtCwFrameUpright");
    } else if (this.state.formData.txtCwFrameTop.length <= 0) {
      this.props.toastErrorMsg("Enter C/W Frame Top", "txtCwFrameTop");
    } else {
      result = true;
    }
    return result;
  };

  saveCarAndCwFrame = () => {
    this.trimFormData(() => {
      if (this.validationCarAndCwFrame()) {
        this.props.notifyProcessing();
        const reqData = {
          Op: "SaveCarAndCwFrame",
          bint_srno: this.state.formData.bint_srno,
          bint_passenger: this.state.formData.txtPassenger,
          dec_speed: this.state.formData.txtSpeed,
          bint_bottomthickness: this.state.formData.txtCarBottomThickness,
          bint_uprightthickness: this.state.formData.txtCarUprightThickness,
          bint_topthicknness: this.state.formData.txtCarTopThickness,
          vac_type: this.state.formData.cmbCwFrameType,
          bint_framebottom: this.state.formData.txtCwFrameBottom,
          bint_frameupright: this.state.formData.txtCwFrameUpright,
          bint_frametop: this.state.formData.txtCwFrameTop,
        };
        fetchCarAndCwFrame(reqData)
          .then((res) => {
            this.props.updateProcessing(res.data.msgType, res.data.message);
            if (res.data.msgType === "success") {
              let rows = [],
                rowsCount = rows.length;
              if (
                res.data.ListCarAndCwFrame &&
                res.data.ListCarAndCwFrame.length > 0
              ) {
                rows = res.data.ListCarAndCwFrame;
                rowsCount = rows.length;
              }
              this.setState(
                {
                  rows,
                  rowsCount,
                },
                () => {
                  this.clearInfo();
                }
              );
            }
          })
          .catch((error) => {
            console.log("Unknown error occurred in Save Door Brand .", error);
          });
      }
    });
  };

  DeleteData = (bint_srno, bint_passenger, bint_speed) => {
    if (bint_srno > 0) {
      this.setState({ ARD: null }, () => {
        let ARD = (
          <AlertResponsiveDialog
            labelAgree="Delete"
            labelDisagree="Cancel"
            alertTitle={`Do you want to Delete Passenger : ${bint_passenger} And Speed : ${bint_speed} ?`}
            alertMessage=""
            handleOnClickYes={() => {
              return this.DeleteCarAndCwFrame(bint_srno);
            }}
            defaultOpenDialog={true}
            onYesClickCloseIfExeSuccessfully={true}
          />
        );
        this.setState({ ARD });
      });
    } else {
      this.props.toastErrorMsg("Select a record for deletion.");
    }
  };

  DeleteCarAndCwFrame = (bint_srno) => {
    this.props.notifyProcessing();
    const reqData = {
      Op: "DeleteCarAndCwFrame",
      bint_srno: bint_srno,
    };
    fetchCarAndCwFrame(reqData)
      .then((res) => {
        this.props.updateProcessing(res.data.msgType, res.data.message);
        if (res.data.msgType === "success") {
          let rows = [],
            rowsCount = rows.length;
          if (
            res.data.ListCarAndCwFrame &&
            res.data.ListCarAndCwFrame.length > 0
          ) {
            rows = res.data.ListCarAndCwFrame;
            rowsCount = rows.length;
          }
          this.setState(
            {
              rows,
              rowsCount,
              ARD: null,
            },
            () => {
              this.clearInfo();
            }
          );
        }
      })
      .catch((error) => {
        this.props.updateProcessing(
          "error",
          "Unknown error occurred in delete Door Brand. " + error
        );
      });
    return false;
  };

  //#endregion function

  render() {
    return (
      <form>
        <Grid container direction="row" justifyContent="flex-end">
          <Grid item>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <CtBtn
                  className="secondary-button"
                  label="Save"
                  iconName="save"
                  onClick={this.saveCarAndCwFrame}
                />
              </Grid>
              <Grid item>
                <CtBtn
                  label="New"
                  className="new-button"
                  iconName="new"
                  onClick={this.clearInfo}
                />
              </Grid>
              <Grid item>
                <CtBtn
                  label="Delete"
                  className="delete-button"
                  iconName="delete"
                  onClick={() => {
                    this.DeleteData(
                      this.state.formData.bint_srno,
                      this.state.formData.txtPassenger,
                      this.state.formData.txtSpeed
                    );
                  }}
                  disabled={
                    Number(this.state.formData.bint_srno) > 0 ? false : true
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          marginTop={0}
        >
          <Grid item>
            <CtTxtNum
              id="txtPassenger"
              label="Passenger / Load Capacity"
              value={this.state.formData.txtPassenger}
              handleOnChange={this.handleOnChange}
              width={220}
              onKeyDown={this.props.onKeyDown}
              maxLength={10}
              colID="vac_type"
            />
          </Grid>
          <Grid item>
            <CtTxtAmt
              id="txtSpeed"
              label="Speed"
              value={this.state.formData.txtSpeed}
              handleOnChange={this.handleOnChange}
              width={220}
              onKeyDown={this.props.onKeyDown}
              maxLength={10}
              colID="vac_type"
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtCarBottomThickness"
              label="Car Frame Bottom Thickness"
              value={this.state.formData.txtCarBottomThickness}
              handleOnChange={this.handleOnChange}
              width={220}
              onKeyDown={this.props.onKeyDown}
              maxLength={10}
              colID="vac_type"
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtCarUprightThickness"
              label="Car Frame Upright Thickness"
              value={this.state.formData.txtCarUprightThickness}
              handleOnChange={this.handleOnChange}
              width={220}
              onKeyDown={this.props.onKeyDown}
              maxLength={10}
              colID="vac_type"
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtCarTopThickness"
              label="Car Frame Top Thickness"
              value={this.state.formData.txtCarTopThickness}
              handleOnChange={this.handleOnChange}
              width={220}
              onKeyDown={this.props.onKeyDown}
              maxLength={10}
              colID="vac_type"
            />
          </Grid>
          <Grid item>
            <CtCmb
              id="cmbCwFrameType"
              label="C/W Frame Type"
              items={this.state.FrameType}
              value={this.state.formData.cmbCwFrameType}
              handleOnChange={this.handleOnChange}
              width={220}
              onKeyDown={this.props.onKeyDown}
              maxLength={100}
              colID="vac_type"
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtCwFrameBottom"
              label="C/W Frame Bottom"
              value={this.state.formData.txtCwFrameBottom}
              handleOnChange={this.handleOnChange}
              width={220}
              onKeyDown={this.props.onKeyDown}
              maxLength={10}
              colID="vac_type"
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtCwFrameUpright"
              label="C/W Frame Upright"
              value={this.state.formData.txtCwFrameUpright}
              handleOnChange={this.handleOnChange}
              width={220}
              onKeyDown={this.props.onKeyDown}
              maxLength={10}
              colID="vac_type"
            />
          </Grid>
          <Grid item>
            <CtTxtNum
              id="txtCwFrameTop"
              label="C/W Frame Top"
              value={this.state.formData.txtCwFrameTop}
              handleOnChange={this.handleOnChange}
              width={220}
              onKeyDown={this.props.onKeyDown}
              maxLength={10}
              colID="vac_type"
              defaultAction={this.saveCarAndCwFrame}
            />
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          marginTop={1}
        >
          {/* responsive table */}
          <Grid item className="maintable">
            <CtART
              rowsdata={this.state.rows}
              columnsdata={this.state.columnsdata}
              rowsPerPage={this.state.rowsPerPage}
              tblmaxHeight={500}
              csvFileName={csvFileName("Passenger Lift Cabin Price")}
              rowIconAction={[
                {
                  label: "Edit",
                  onClick: this.handleARDonActionClick_Edit,
                  icon: "Edit",
                  className: "secondary-button",
                },
                {
                  label: "Delete",
                  onClick: this.handleARDonActionClick_Delete,
                  icon: "delete",
                  className: "delete-button",
                },
              ]}
              showColumnFilters={false}
              actionColSize={90}
              density={"compact"}
              hideColIdList={["bint_srno"]}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Box display={{ xs: "none" }} style={{ textAlign: "right" }}>
            {this.state.ARD}
          </Box>
        </Grid>
      </form>
    );
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <CarAndCwFrameSelection {...props} navigate={navigate} />;
}
export default connect(
  mapStatetoProps,
  mapSetUserPrivileges_ToProps
)(HoCtToastContainer(HOCVerifyIsUser(withRouter(WithNavigate))));
