// import React, { useState } from "react";
// import { styled, useTheme } from "@mui/material/styles";
// import Box from "@mui/material/Box";
// import MuiDrawer from "@mui/material/Drawer";
// import List from "@mui/material/List";
// import CssBaseline from "@mui/material/CssBaseline";
// import Divider from "@mui/material/Divider";
// import IconButton from "@mui/material/IconButton";
// import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// import ListItem from "@mui/material/ListItem";
// import ListItemButton from "@mui/material/ListItemButton";
// import ListItemIcon from "@mui/material/ListItemIcon";
// import ListItemText from "@mui/material/ListItemText";
// import { useNavigate, useLocation } from "react-router-dom";
// import { useAppStore } from "../appStore";
// import "./theam.css";
// import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";
// import { Grid } from "@mui/material";
// import DashboardIcon from "@mui/icons-material/Dashboard";
// import Collapse from "@mui/material/Collapse";
// import ExpandLessIcon from "@mui/icons-material/ExpandLess";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
// import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
// import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
// import WorkIcon from "@mui/icons-material/Work";
// import { Home, ManageAccounts } from "@mui/icons-material";

// const drawerWidth = 240;

// const openedMixin = (theme) => ({
//   width: drawerWidth,
//   transition: theme.transitions.create("width", {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.enteringScreen,
//   }),
//   overflowX: "hidden",
// });

// const closedMixin = (theme) => ({
//   transition: theme.transitions.create("width", {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   overflowX: "hidden",
//   width: `calc(${theme.spacing(7)} + 1px)`,
//   [theme.breakpoints.up("sm")]: {
//     width: `calc(${theme.spacing(8)} + 1px)`,
//   },
// });

// const DrawerHeader = styled("div")(({ theme }) => ({
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "flex-end",
//   padding: theme.spacing(0, 1),
//   // necessary for content to be below app bar
//   ...theme.mixins.toolbar,
// }));

// const Drawer = styled(MuiDrawer, {
//   shouldForwardProp: (prop) => prop !== "open",
// })(({ theme, open }) => ({
//   width: drawerWidth,
//   flexShrink: 0,
//   whiteSpace: "nowrap",
//   boxSizing: "border-box",
//   ...(open && {
//     ...openedMixin(theme),
//     "& .MuiDrawer-paper": openedMixin(theme),
//   }),
//   ...(!open && {
//     ...closedMixin(theme),
//     "& .MuiDrawer-paper": closedMixin(theme),
//   }),
// }));

// export default function SideNav() {
//   const [ARD, setARD] = useState(null);
//   const [isCollapse, setIsCollapse] = useState(false);
//   const openDialog = () => {
//     console.log("ARD", ARD);
//     let dialogContent = (
//       <AlertResponsiveDialog
//         type="Delete"
//         labelAgree="Logout"
//         labelDisagree="Cancel"
//         alertHeading="Logout Confirmation"
//         alertTitle={"Do you want to Logout:?"}
//         alertIcon="logout"
//         alertMessage=""
//         handleOnClickYes={() => {
//           navigate("/");
//           setARD(null);
//         }}
//         handleOnClickNo={() => {
//           setARD(null);
//         }}
//         defaultOpenDialog={true}
//         onYesClickCloseIfExeSuccessfully={true}
//       />
//     );

//     setARD(dialogContent);
//   };

//   const theme = useTheme();
//   // const [open, setOpen] = React.useState(true);
//   const navigate = useNavigate();
//   const location = useLocation();
//   const isItemActive = (path) => location.pathname === path;
//   const open = useAppStore((state) => state.dopen);

//   const handleCollapse = () => {
//     setIsCollapse(!isCollapse);
//   };

//   return (
//     <Box sx={{ display: "flex" }}>
//       <Box height={30} />
//       <CssBaseline />
//       <Drawer variant="permanent" open={open}>
//         <DrawerHeader>
//           <IconButton>
//             {theme.direction === "rtl" ? (
//               <ChevronRightIcon />
//             ) : (
//               <ChevronLeftIcon />
//             )}
//           </IconButton>
//         </DrawerHeader>
//         <Divider />
//         <List>
//           <ListItem
//             disablePadding
//             className={
//               isItemActive("/Dashboard")
//                 ? "primary-active-list-item"
//                 : "primary-inactive-list-item"
//             }
//             onClick={() => {
//               navigate("/Dashboard");
//             }}
//           >
//             <ListItemButton
//               sx={{
//                 minHeight: 48,
//                 justifyContent: open ? "initial" : "center",
//                 px: 2.5,
//               }}
//             >
//               <ListItemIcon
//                 sx={{
//                   minWidth: 0,
//                   mr: open ? 3 : "auto",
//                   justifyContent: "center",
//                 }}
//               >
//                 <DashboardIcon />
//               </ListItemIcon>
//               <ListItemText
//                 primary="Dashboard"
//                 sx={{ opacity: open ? 1 : 0 }}
//               />
//             </ListItemButton>
//           </ListItem>
//           <ListItem
//             disablePadding
//             className={
//               isItemActive("/UserManagement")
//                 ? "primary-active-list-item"
//                 : "primary-inactive-list-item"
//             }
//             onClick={() => {
//               navigate("/UserManagement");
//             }}
//           >
//             <ListItemButton
//               sx={{
//                 minHeight: 48,
//                 justifyContent: open ? "initial" : "center",
//                 px: 2.5,
//               }}
//             >
//               <ListItemIcon
//                 sx={{
//                   minWidth: 0,
//                   mr: open ? 3 : "auto",
//                   justifyContent: "center",
//                 }}
//               >
//                 <ManageAccounts />
//               </ListItemIcon>
//               <ListItemText
//                 primary="User Management"
//                 sx={{ opacity: open ? 1 : 0 }}
//               />
//             </ListItemButton>
//           </ListItem>
//           {/* <ListItem disablePadding onClick={handleCollapse}>
//             <ListItemButton
//               sx={{
//                 minHeight: 48,
//                 justifyContent: open ? "initial" : "center",
//                 px: 2.5,
//               }}
//             >
//               <ListItemIcon
//                 sx={{
//                   minWidth: 0,
//                   mr: open ? 3 : "auto",
//                   justifyContent: "center",
//                 }}
//               >
//                 <PeopleAltIcon />
//               </ListItemIcon>
//               <ListItemText primary="CRM" sx={{ opacity: open ? 1 : 0 }} />
//               <ListItemIcon
//                 sx={{
//                   minWidth: 0,
//                   mr: open ? 3 : "auto",
//                   justifyContent: "center",
//                 }}
//               >
//                 {isCollapse ? <ExpandLessIcon /> : <ExpandMoreIcon />}
//               </ListItemIcon>
//             </ListItemButton>
//           </ListItem> */}
//           {/* <Collapse in={isCollapse}> */}
//           <ListItem
//             disablePadding
//             className={
//               isItemActive("/Proposal")
//                 ? "primary-active-list-item"
//                 : "primary-inactive-list-item"
//             }
//             onClick={() => {
//               navigate("/Proposal");
//             }}
//           >
//             <ListItemButton
//               sx={{
//                 minHeight: 48,
//                 justifyContent: open ? "initial" : "center",
//                 px: 2.5,
//               }}
//             >
//               <ListItemIcon
//                 sx={{
//                   minWidth: 0,
//                   mr: open ? 3 : "auto",
//                   justifyContent: "center",
//                 }}
//               >
//                 <AdminPanelSettingsIcon />
//               </ListItemIcon>
//               <ListItemText primary="Proposal" sx={{ opacity: open ? 1 : 0 }} />
//             </ListItemButton>
//           </ListItem>
//           <ListItem
//             disablePadding
//             className={
//               isItemActive("/JDS")
//                 ? "primary-active-list-item"
//                 : "primary-inactive-list-item"
//             }
//             onClick={() => {
//               navigate("/JDS");
//             }}
//           >
//             <ListItemButton
//               sx={{
//                 minHeight: 48,
//                 justifyContent: open ? "initial" : "center",
//                 px: 2.5,
//               }}
//             >
//               <ListItemIcon
//                 sx={{
//                   minWidth: 0,
//                   mr: open ? 3 : "auto",
//                   justifyContent: "center",
//                 }}
//               >
//                 <WorkIcon />
//               </ListItemIcon>
//               <ListItemText
//                 primary="Job Site Details"
//                 sx={{ opacity: open ? 1 : 0 }}
//               />
//             </ListItemButton>
//           </ListItem>
//           {/* </Collapse> */}
//           <ListItem
//             disablePadding
//             onClick={() => {
//               openDialog();
//             }}
//           >
//             <ListItemButton
//               sx={{
//                 minHeight: 48,
//                 justifyContent: open ? "initial" : "center",
//                 px: 2.5,
//               }}
//             >
//               <ListItemIcon
//                 sx={{
//                   minWidth: 0,
//                   mr: open ? 3 : "auto",
//                   justifyContent: "center",
//                 }}
//               >
//                 <PowerSettingsNewIcon />
//               </ListItemIcon>
//               <ListItemText primary="Logout" sx={{ opacity: open ? 1 : 0 }} />
//             </ListItemButton>
//           </ListItem>
//           {/* <ListItem
//             disablePadding
//             className={
//               isItemActive("/Product")
//                 ? "primary-active-list-item"
//                 : "primary-inactive-list-item"
//             }
//             onClick={() => {
//               navigate("/Product");
//             }}
//           >
//             <ListItemButton
//               sx={{
//                 minHeight: 48,
//                 justifyContent: open ? "initial" : "center",
//                 px: 2.5,
//               }}
//             >
//               <ListItemIcon
//                 sx={{
//                   minWidth: 0,
//                   mr: open ? 3 : "auto",
//                   justifyContent: "center",
//                 }}
//               >
//                 <LocalGroceryStoreIcon />
//               </ListItemIcon>
//               <ListItemText primary="Product" sx={{ opacity: open ? 1 : 0 }} />
//             </ListItemButton>
//           </ListItem>
//           <ListItem
//             disablePadding
//             className={
//               isItemActive("/Distributor")
//                 ? "primary-active-list-item"
//                 : "primary-inactive-list-item"
//             }
//             onClick={() => {
//               navigate("/Distributor");
//             }}
//           >
//             <ListItemButton
//               sx={{
//                 minHeight: 48,
//                 justifyContent: open ? "initial" : "center",
//                 px: 2.5,
//               }}
//             >
//               <ListItemIcon
//                 sx={{
//                   minWidth: 0,
//                   mr: open ? 3 : "auto",
//                   justifyContent: "center",
//                 }}
//               >
//                 <AddBusinessIcon />
//               </ListItemIcon>
//               <ListItemText
//                 primary="Distributor"
//                 sx={{ opacity: open ? 1 : 0 }}
//               />
//             </ListItemButton>
//           </ListItem>
//           <ListItem
//             disablePadding
//             onClick={() => {
//               openDialog();
//             }}
//           >
//             <ListItemButton
//               sx={{
//                 minHeight: 48,
//                 justifyContent: open ? "initial" : "center",
//                 px: 2.5,
//               }}
//             >
//               <ListItemIcon
//                 sx={{
//                   minWidth: 0,
//                   mr: open ? 3 : "auto",
//                   justifyContent: "center",
//                 }}
//               >
//                 <PowerSettingsNewIcon />
//               </ListItemIcon>
//               <ListItemText primary="Logout" sx={{ opacity: open ? 1 : 0 }} />
//             </ListItemButton>
//           </ListItem> */}
//         </List>
//       </Drawer>
//       <Grid item>
//         <Box display={{ xs: "none" }} style={{ textAlign: "right" }}>
//           {ARD}
//         </Box>
//       </Grid>
//     </Box>
//   );
// }

import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import LogoutIcon from "@mui/icons-material/Logout";
import HomeIcon from "@mui/icons-material/Home";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppStore } from "../appStore";
import "./theam.css";
import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";
import { Grid, Tooltip, Typography } from "@mui/material";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AddTaskIcon from "@mui/icons-material/AddTask";
import ListAltIcon from "@mui/icons-material/ListAlt";
import Collapse from "@mui/material/Collapse";
import PaymentIcon from "@mui/icons-material/Payment";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ThreePRoundedIcon from "@mui/icons-material/ThreePRounded";
import WarehouseRoundedIcon from "@mui/icons-material/WarehouseRounded";
import HelpCenterRoundedIcon from "@mui/icons-material/HelpCenterRounded";
import ReceiptLongRoundedIcon from "@mui/icons-material/ReceiptLongRounded";
import PersonIcon from "@mui/icons-material/Person";
import BadgeIcon from "@mui/icons-material/Badge";
import ChecklistIcon from "@mui/icons-material/Checklist";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import ArticleIcon from "@mui/icons-material/Article";
import SubjectIcon from "@mui/icons-material/Subject";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import AssessmentIcon from "@mui/icons-material/Assessment";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import FeedIcon from "@mui/icons-material/Feed";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import SettingsIcon from "@mui/icons-material/Settings";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import {
  AccountBalanceWallet,
  AccountBalanceWalletOutlined,
  AdminPanelSettings,
  AdminPanelSettingsOutlined,
  AdminPanelSettingsSharp,
  Calculate,
  ContactEmergency,
  CurrencyRupee,
  DashboardCustomize,
  Description,
  Dns,
  EditCalendar,
  EditCalendarSharp,
  Engineering,
  FactCheck,
  Fax,
  Groups,
  InstallDesktop,
  Logout,
  MeetingRoom,
  MoveToInbox,
  Percent,
  PinDrop,
  PinDropSharp,
  Rule,
  SettingsEthernet,
  Sort,
  Stream,
  SupportAgent,
  TableView,
  ThreeP,
  ThreePOutlined,
} from "@mui/icons-material";
import StreamIcon from "@mui/icons-material/Stream";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import TerminalIcon from "@mui/icons-material/Terminal";
import SchoolIcon from "@mui/icons-material/School";
import PostAddIcon from "@mui/icons-material/PostAdd";
import InterpreterModeIcon from "@mui/icons-material/InterpreterMode";
import AddchartIcon from "@mui/icons-material/Addchart";
import EventIcon from "@mui/icons-material/Event";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import TransformIcon from "@mui/icons-material/Transform";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import TransgenderIcon from "@mui/icons-material/Transgender";
import AddCommentIcon from "@mui/icons-material/AddComment";
import AddCardIcon from "@mui/icons-material/AddCard";
import {
  mapSetUserPrivileges_ToProps,
  mapStatetoProps,
} from "../mapRedux/mapReduxProps";
import { connect } from "react-redux";
import CategoryIcon from "@mui/icons-material/Category";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AppsOutageIcon from "@mui/icons-material/AppsOutage";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import RoomPreferencesIcon from "@mui/icons-material/RoomPreferences";
import AddHomeWorkIcon from "@mui/icons-material/AddHomeWork";
import SpatialTrackingIcon from "@mui/icons-material/SpatialTracking";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import StarRateIcon from "@mui/icons-material/StarRate";
import MoveDownIcon from "@mui/icons-material/MoveDown";
// import RupeeSetting from "../Images/RupeeSetting.png";
// import RupeeRegister from "../Images/RupeeRegister.png";
// import RupeeReports from "../Images/RupeeReports.png";
// import LeaveList from "../Images/LeaveList.png";
// import PaidLeaveList from "../Images/PaidLeaveList.png";
// import gmp from "../Images/GMP.png";
// import Transaction from "../Images/Transaction.png";
import Packing from "../Images/packing.png";
import { apiAngularURLProposal } from "./API";
// import { apiAngularURLProposal } from "./API";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  // width: 0,
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const SideNav = (props) => {
  const [ARD, setARD] = useState(null);
  const [isCollapse, setisCollapse] = React.useState({
    /* menuid:
      localStorage.getItem("menuid") == "true"
        ? localStorage.getItem("menuid")
        : false, */
  });
  const handleCollapseAll = (menuid, value) => {
    let newValue = value === undefined ? !isCollapse[menuid] : value;
    setisCollapse({ ...isCollapse, [menuid]: newValue });
    localStorage.setItem(menuid, newValue);
  };

  const openDialog = () => {
    let dialogContent = (
      <AlertResponsiveDialog
        type="Delete"
        labelAgree="Logout"
        labelDisagree="Cancel"
        alertHeading="Logout Confirmation"
        alertTitle={"Do you want to Logout:?"}
        alertIcon="logout"
        alertMessage=""
        handleOnClickYes={() => {
          navigate("/");
          setARD(null);
          localStorage.removeItem("authtoken");
          localStorage.removeItem("user_id");
          localStorage.removeItem("UserID");
          localStorage.removeItem("UserName");
          localStorage.removeItem("UserMobileNo");
          localStorage.removeItem("UserBrCode");
          localStorage.removeItem("UserBrName");
          localStorage.removeItem("ErpRole");
          localStorage.removeItem("proposalFromDate");
          localStorage.removeItem("proposalToDate");
          localStorage.removeItem("proposalBranch");
          localStorage.removeItem("IsJSDMultiBranch");
          localStorage.removeItem("DispatchDelete");
        }}
        handleOnClickNo={() => {
          setARD(null);
        }}
        defaultOpenDialog={true}
        onYesClickCloseIfExeSuccessfully={true}
      />
    );

    setARD(dialogContent);
  };

  useEffect(() => {});

  const theme = useTheme();
  // const [open, setOpen] = React.useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const isItemActive = (path) => location.pathname === path;
  const open = useAppStore((state) => state.dopen);

  const SingleNumIconStyle = {
    marginLeft: "6px",
    paddingRight: "7px",
  };

  const DoubleNumIconStyle = {
    marginLeft: "0px",
    paddingRight: "3px",
  };

  const [menuTree, setMenuTree] = useState([
    {
      menu: { title: "Dashboard", key: "Dashboard", icon: <DashboardIcon /> },
    },
    {
      menu: {
        module: "Master",
        title: "Master",
        key: "Master",
        icon: <DashboardCustomize />,
        visible: true,
      },

      child: [
        {
          menu: {
            module: "Master:Proposal",
            title: "Proposal",
            key: "Proposal Master",
            icon: <ManageAccountsIcon />,
            visible: true,
          },
          child: [
            {
              menu: {
                module: "Master:Proposal:User Management",
                // icon: <PermContactCalendarIcon />,
                icon: <b style={SingleNumIconStyle}>1</b>,
                title: "User Management",
                key: "UserManagement",
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Proposal:Lift Type",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={SingleNumIconStyle}>2</b>,
                title: "Lift Type",
                // key: "PlantMaster",
                herfKey: `${apiAngularURLProposal}Page=LiftType`,
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Proposal:Drive",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={SingleNumIconStyle}>3</b>,
                title: "Drive",
                // key: "PlantMaster",
                herfKey: `${apiAngularURLProposal}Page=Drive`,
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Proposal:Controller",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={SingleNumIconStyle}>4</b>,
                title: "Controller",
                // key: "PlantMaster",
                herfKey: `${apiAngularURLProposal}Page=Controller`,
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Proposal:Machine Master",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={SingleNumIconStyle}>5</b>,
                title: "Machine",
                // key: "PlantMaster",
                herfKey: `${apiAngularURLProposal}Page=Machine`,
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Proposal:Machine Room",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={SingleNumIconStyle}>6</b>,
                title: "Machine Room",
                // key: "PlantMaster",
                herfKey: `${apiAngularURLProposal}Page=MachineRoom`,
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Proposal:Machine Placement",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={SingleNumIconStyle}>7</b>,
                title: "Machine Placement",
                // key: "PlantMaster",
                herfKey: `${apiAngularURLProposal}Page=MachinePlacement`,
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Proposal:Car Enclosure / Car Cabin",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={SingleNumIconStyle}>8</b>,
                title: "Car Enclosure /<br/>Car Cabin",
                // key: "PlantMaster",
                herfKey: `${apiAngularURLProposal}Page=CarCabin`,
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Proposal:Hoist Way Structure",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={SingleNumIconStyle}>9</b>,
                title: "Hoist Way Structure",
                // key: "PlantMaster",
                herfKey: `${apiAngularURLProposal}Page=HoistWayStructure`,
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Proposal:Display Type",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={DoubleNumIconStyle}>10</b>,
                title: "Display Type",
                // key: "PlantMaster",
                herfKey: `${apiAngularURLProposal}Page=DisplayType`,
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Proposal:Door Type",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={DoubleNumIconStyle}>11</b>,
                title: "Door Type",
                // key: "PlantMaster",
                herfKey: `${apiAngularURLProposal}Page=DoorType`,
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Proposal:Terms of Payment",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={DoubleNumIconStyle}>12</b>,
                title: "Terms of Payment",
                // key: "PlantMaster",
                herfKey: `${apiAngularURLProposal}Page=TermsPayment`,
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Proposal:Terms of Cancellation",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={DoubleNumIconStyle}>13</b>,
                title: "Terms of Cancellation",
                // key: "PlantMaster",
                herfKey: `${apiAngularURLProposal}Page=TermsCancellation`,
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Proposal:Tax Detail",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={DoubleNumIconStyle}>14</b>,
                title: "Tax Detail",
                // key: "PlantMaster",
                herfKey: `${apiAngularURLProposal}Page=Tax`,
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Proposal:Note",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={DoubleNumIconStyle}>15</b>,
                title: "Note",
                // key: "PlantMaster",
                herfKey: `${apiAngularURLProposal}Page=Note`,
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Proposal:Preparatory Work",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={DoubleNumIconStyle}>16</b>,
                title: "Preparatory Work",
                // key: "PlantMaster",
                herfKey: `${apiAngularURLProposal}Page=PreparatoryWork`,
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Proposal:Terms and Condition",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={DoubleNumIconStyle}>17</b>,
                title: "Terms and Condition",
                // key: "PlantMaster",
                herfKey: `${apiAngularURLProposal}Page=TermsCondition`,
                visible: true,
              },
            },
          ],
        },
        {
          menu: {
            module: "Master:Job Site Detail",
            title: "Job Site Detail",
            key: "Job Site Detail",
            icon: <ManageAccountsIcon />,
            visible: true,
          },
          child: [
            {
              menu: {
                module: "Master:Job Site Detail:Door Board",
                // icon: <PermContactCalendarIcon />,
                icon: <b style={SingleNumIconStyle}>1</b>,
                title: "Door Board",
                // key: "Employee",
                herfKey: `${apiAngularURLProposal}Page=BrandEntry`,
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Job Site Detail:Machine Master",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={SingleNumIconStyle}>2</b>,
                title: "Machine Master",
                // key: "PlantMaster",
                herfKey: `${apiAngularURLProposal}Page=MachineMaster`,
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Job Site Detail:Braking Resistor",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={SingleNumIconStyle}>3</b>,
                title: "Braking Resistor",
                // key: "PlantMaster",
                herfKey: `${apiAngularURLProposal}Page=BrakresistorList`,
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Job Site Detail:Car & C.W. Rail Size",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={SingleNumIconStyle}>4</b>,
                title: "Car & C.W. Rail Size",
                // key: "PlantMaster",
                herfKey: `${apiAngularURLProposal}Page=CarAndCW`,
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Job Site Detail:Car Cabin Fan / Blower",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={SingleNumIconStyle}>5</b>,
                title: "Car Cabin<br/>Fan / Blower",
                // key: "PlantMaster",
                herfKey: `${apiAngularURLProposal}Page=CarCabinFan`,
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Job Site Detail:Cabin False Celling Design",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={SingleNumIconStyle}>6</b>,
                title: "Cabin False<br/>Celling Design",
                // key: "PlantMaster",
                herfKey: `${apiAngularURLProposal}Page=CellingDesign`,
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Job Site Detail:Hand Rail Dimension",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={SingleNumIconStyle}>7</b>,
                title: "Hand Rail Dimension",
                // key: "PlantMaster",
                herfKey: `${apiAngularURLProposal}Page=HandRail`,
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Job Site Detail:Hand Rail Colour",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={SingleNumIconStyle}>8</b>,
                title: "Hand Rail Colour",
                // key: "PlantMaster",
                herfKey: `${apiAngularURLProposal}Page=HandRailColour`,
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Job Site Detail:Controller Design",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={SingleNumIconStyle}>9</b>,
                title: "Controller Design",
                // key: "PlantMaster",
                herfKey: `${apiAngularURLProposal}Page=ControllerDesign`,
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Job Site Detail:Drive Name",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={DoubleNumIconStyle}>10</b>,
                title: "Drive Name",
                // key: "PlantMaster",
                herfKey: `${apiAngularURLProposal}Page=DriveName`,
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Job Site Detail:Counter Frame Design",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={DoubleNumIconStyle}>11</b>,
                title: "Counter Frame<br/>Design",
                // key: "PlantMaster",
                herfKey: `${apiAngularURLProposal}Page=CounterFrame`,
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Job Site Detail:Safety Block Type",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={DoubleNumIconStyle}>12</b>,
                title: "Safety Block Type",
                // key: "PlantMaster",
                herfKey: `${apiAngularURLProposal}Page=SafetyBlock`,
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Job Site Detail:Travelling Cable",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={DoubleNumIconStyle}>13</b>,
                title: "Travelling Cable",
                // key: "PlantMaster",
                herfKey: `${apiAngularURLProposal}Page=TravellingCable`,
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Job Site Detail:COP Graphics",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={DoubleNumIconStyle}>14</b>,
                title: "COP Graphics",
                // key: "PlantMaster",
                herfKey: `${apiAngularURLProposal}Page=COPGraphics`,
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Job Site Detail:Car & Counter GuideShoe Type",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={DoubleNumIconStyle}>15</b>,
                title: "Car & Counter<br/>GuideShoe Type",
                // key: "PlantMaster",
                herfKey: `${apiAngularURLProposal}Page=CounterGuideShoe`,
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Job Site Detail:Car Floor",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={DoubleNumIconStyle}>16</b>,
                title: "Car Floor",
                // key: "PlantMaster",
                herfKey: `${apiAngularURLProposal}Page=CarFloor`,
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Job Site Detail:Roping",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={DoubleNumIconStyle}>17</b>,
                title: "Roping",
                // key: "PlantMaster",
                herfKey: `${apiAngularURLProposal}Page=Roping`,
                visible: true,
              },
            },
          ],
        },

        {
          menu: {
            module: "Master:Price Calculator",
            title: "Price Calculator<br/>Master",
            key: "Price Calculator Master",
            icon: <ManageAccountsIcon />,
            visible: true,
          },
          child: [
            {
              menu: {
                module: "Master:Price Calculator:Rail Price",
                // icon: <PermContactCalendarIcon />,
                icon: <b style={SingleNumIconStyle}>1</b>,
                title: "Rail Price Master",
                key: "RailPriceMaster",
                visible: true,
              },
            },
            // {
            //   menu: {
            //     module: "Master:Price Calculator:Gp Bracket Price",
            //     // icon: <WarehouseRoundedIcon />,
            //     icon: <b style={SingleNumIconStyle}>2</b>,
            //     title: "Gp Bracket Price<br/>Master",
            //     key: "GpBracketPriceMaster",
            //     visible: true,
            //   },
            // },
            // {
            //   menu: {
            //     module: "Master:Price Calculator:Counter Weight Price List",
            //     // icon: <WarehouseRoundedIcon />,
            //     icon: <b style={SingleNumIconStyle}>3</b>,
            //     title: "Counter Weight<br/>Price List",
            //     key: "CounterWeightPrice",
            //     visible: true,
            //   },
            // },
            {
              menu: {
                module: "Master:Price Calculator:General Item Price List",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={SingleNumIconStyle}>2</b>,
                title: "General Item<br/>Price List",
                key: "GeneralItemPrice",
                visible: true,
              },
            },
            {
              menu: {
                module:
                  "Master:Price Calculator:Main & Governor Wire Rope List & Technical Detail",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={SingleNumIconStyle}>3</b>,
                title:
                  "Main & Governor<br/>Wire Rope Price<br/>List & Tecnical<br/>Detail",
                key: "MainGovernorWire",
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Price Calculator:Raw Material & Process Price",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={SingleNumIconStyle}>4</b>,
                title: "Raw Material & <br/>Process Price List",
                key: "RawMaterialProcess",
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Price Calculator:Counter Weight KG",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={SingleNumIconStyle}>5</b>,
                title: "Counter Weight KG",
                key: "CounterWeightKg",
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Price Calculator:Pulley Price List",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={SingleNumIconStyle}>6</b>,
                title: "Pulley Price List",
                key: "PulleyPriceList",
                visible: true,
              },
            },
            // {
            //   menu: {
            //     module:
            //       "Master:Price Calculator:Items Related To Safety Set Price",
            //     // icon: <WarehouseRoundedIcon />,
            //     icon: <b style={SingleNumIconStyle}>9</b>,
            //     title: "Items Related To <br/>Safety Set Price List",
            //     key: "ItemsRelatedSafety",
            //     visible: true,
            //   },
            // },
            {
              menu: {
                module: "Master:Price Calculator:Passenger Lift Cabin Price",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={SingleNumIconStyle}>7</b>,
                title: "Passenger Lift Cabin<br/>Price List",
                key: "PassengerLiftCabinPrice",
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Price Calculator:Goods Lift Cabin Entry",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={SingleNumIconStyle}>8</b>,
                title: "Goods Lift Cabin <br/>Price List",
                key: "GoodsLiftCabin",
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Price Calculator:Auto Door Main Price",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={SingleNumIconStyle}>9</b>,
                title: "Auto Door Main Price<br/>List",
                key: "AutoDoorMainPrice",
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Price Calculator:Auto Door Panel Price Formula",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={DoubleNumIconStyle}>10</b>,
                title: "Auto Door Panel<br/>Price Formula Master",
                key: "AutoDoorPanelPriceFomula",
                visible: true,
              },
            },
            // {
            //   menu: {
            //     module: "Master:Price Calculator:Auto Door Frame Price Formula",
            //     // icon: <WarehouseRoundedIcon />,
            //     icon: <b style={DoubleNumIconStyle}>14</b>,
            //     title: "Auto Door Frame<br/>Price Formula Master",
            //     key: "AutoDoorFramePriceFormula",
            //     visible: true,
            //   },
            // },
            // {
            //   menu: {
            //     module: "Master:Price Calculator:Master Of Rail Selacion",
            //     // icon: <WarehouseRoundedIcon />,
            //     icon: <b style={DoubleNumIconStyle}>15</b>,
            //     title: "Master For Rail<br/>Selection",
            //     key: "RailSelection",
            //     visible: true,
            //   },
            // },
            {
              menu: {
                module:
                  "Master:Price Calculator:Car Inside, Car DBG & CW DBG Selection",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={DoubleNumIconStyle}>11</b>,
                title:
                  "Master For Car<br/>Inside, Car DBG &<br/>C/W DBG Selection",
                key: "CarInsideDbgCwSelection",
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Price Calculator:Safety Main Price",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={DoubleNumIconStyle}>12</b>,
                title: "Safety Main Price",
                key: "SafetyMainPrice",
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Price Calculator:Manual Door Price",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={DoubleNumIconStyle}>13</b>,
                title: "Manual Door Price",
                key: "ManualDoorPrice",
                visible: true,
              },
            },
            {
              menu: {
                module:
                  "Master:Price Calculator:Controller & Main VF Drive Pricing",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={DoubleNumIconStyle}>14</b>,
                title: "Controller & Main VF<br/>Drive Pricing",
                key: "ControllerMainVfDrivePricing",
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Price Calculator:Combination Bracket Price",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={DoubleNumIconStyle}>15</b>,
                title: "Combination Bracket<br/>Price",
                key: "CombinationBracketPrice",
                visible: true,
              },
            },
            {
              menu: {
                module:
                  "Master:Price Calculator:Car Frame & CW Frame Selection",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={DoubleNumIconStyle}>16</b>,
                title: "Car Frame & C/W<br/>Frame Selection",
                key: "CarAndCwFrameSelection",
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Price Calculator:Price OF Bracket Master",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={DoubleNumIconStyle}>17</b>,
                title: "Price Of Bracket",
                key: "PriceOfBracket",
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Price Calculator:Manual Door Frame Price",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={DoubleNumIconStyle}>18</b>,
                title: "Manual Door Frame<br/>Price",
                key: "ManualDoorFrameMaster",
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Price Calculator:Machine Price",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={DoubleNumIconStyle}>19</b>,
                title: "Machine Price",
                key: "MachinePrice",
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Price Calculator:Door Material Grade",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={DoubleNumIconStyle}>20</b>,
                title: "Door Material Grade",
                key: "DoorMaterialGrade",
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Price Calculator:Door Brand",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={DoubleNumIconStyle}>21</b>,
                title: "Door Brand",
                key: "DoorBrand",
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Price Calculator:Drive Name",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={DoubleNumIconStyle}>22</b>,
                title: "Drive Name",
                key: "DriveName",
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Price Calculator:ARD Price List",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={DoubleNumIconStyle}>23</b>,
                title: "ARD Price List",
                key: "ArdPrice",
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Price Calculator:Com Chain Price Per KG",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={DoubleNumIconStyle}>24</b>,
                title: "Com Chain Price<br />Per Kg",
                key: "ComChainPricePerKg",
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Price Calculator:Pulley Diameter Selection",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={DoubleNumIconStyle}>25</b>,
                title: "Pulley Diameter<br/>Selection",
                key: "PulleyDiameterSelection",
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Price Calculator:Cop Price Master",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={DoubleNumIconStyle}>26</b>,
                title: "Cop Price Master",
                key: "CopPriceMaster",
                visible: true,
              },
            },
            {
              menu: {
                module: "Master:Price Calculator:Lop Price Master",
                // icon: <WarehouseRoundedIcon />,
                icon: <b style={DoubleNumIconStyle}>27</b>,
                title: "Lop Price Master",
                key: "LopPriceMaster",
                visible: true,
              },
            },
          ],
        },
      ],
    },
    {
      menu: {
        title: "Entry",
        key: "Entry",
        icon: <Sort />,
        visible: true,
      },
      child: [
        {
          menu: {
            module: "Entry:Proposal",
            icon: <Description />,
            title: "Proposal",
            key: "Proposal",
            visible: true,
          },
        },
        {
          menu: {
            module: "Entry:JSD",
            title: "JSD",
            // key: "Payroll",
            herfKey: `${apiAngularURLProposal}Page=JSDEntry`,
            icon: <MeetingRoom />,
            visible: true,
          },
        },
        {
          menu: {
            module: "Entry:Material Packing Edit",
            icon: (
              <img src={Packing} alt="Packing" height={"27px"} width={"27px"} />
            ),
            title: "Packing",
            // key: "HrSetting",
            // herfKey: `http://127.0.0.1/Development/Top-India/php/top-india-proposal-jsd-packing/tin/index.html#/`,
            // herfKey: `https://ravitechworld.in/tiepl/tin/index.html`,
            herfKey: `${apiAngularURLProposal}Page=Packing`,
            visible: true,
          },
        },
        {
          menu: {
            module: "Entry:Price Calculator",
            title: "Price Calculator",
            key: "InputParametersList",
            icon: <Calculate />,
            visible: true,
          },
        },
      ],
    },

    {
      menu: {
        module: "Master:Import Export",
        title: "Import & Export<br/>Excel",
        key: "ImportExportExcel",
        icon: <TableView />,
        visible: true,
      },
    },
    {
      menu: {
        module: "Master:Branch",
        title: "Branch",
        herfKey: `${apiAngularURLProposal}Page=Branch`,
        icon: <TableView />,
        visible: true,
      },
    },

    {
      menu: {
        title: "Logout",
        key: "Logout",
        icon: <Logout />,
        onClick: () => {
          openDialog();
        },
        visible: true,
      },
    },
  ]);

  const [liveMenu, setLiveMnu] = useState("");

  const displayLiveMenu = () => {
    setLiveMnu(displayMenu(menuTree));
  };

  const setMenuBasedOnPrivileges = (privileges) => {};

  const displayMenu = (menuList) => {
    // console.log("menuList", menuList);

    const menu = menuList.map((row) => {
      if (
        row.menu.module === undefined ||
        props.userPrivilege[row.menu.module] ||
        (row.child && row.child.length > 0)
      ) {
        // if (row.menu.visible === true) {
        let MenuIcon = "";
        if (row.child && row.child.length > 0) {
          if (localStorage.getItem(row.menu.key) == "true") {
            MenuIcon = <ExpandLessIcon />;
            if (isCollapse[row.menu.key] === undefined) {
              handleCollapseAll(row.menu.key, "true");
            }
          } else {
            MenuIcon = <ExpandMoreIcon />;
          }
        }

        let childDisplayCount = 0;

        let MenuItem = (
          <>
            <Divider />
            <List>
              <ListItem
                disablePadding
                className={
                  isItemActive("/" + row.menu["key"])
                    ? "primary-active-list-item"
                    : "primary-inactive-list-item"
                }
                onClick={() => {
                  if (row.child && row.child.length > 0) {
                    handleCollapseAll(row.menu["key"]);
                  } else if (row.menu.onClick) {
                    row.menu.onClick();
                  } else {
                    localStorage.setItem("Heading", row.menu.title);
                    navigate("/" + row.menu["key"]);
                  }
                }}
              >
                <Tooltip
                  title={
                    <span
                      className="span"
                      dangerouslySetInnerHTML={{
                        __html: open ? row.menu.title : row.menu.title,
                      }}
                    />
                  }
                  arrow
                  placement="right"
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 0,
                        ml: row.child && row.child.length > 0 && !open ? 1 : 0,
                        justifyContent: "flex-start",
                      }}
                    >
                      {row.menu["icon"]}
                    </ListItemIcon>
                    <ListItemText>
                      {/* <span className="span">{open ? row.menu.title : ""}</span> */}
                      {/* <span
                        className="span"
                        dangerouslySetInnerHTML={{
                          __html: open ? row.menu.title : "",
                        }}
                      /> */}

                      {row.menu.herfKey ? (
                        <a
                          href={row.menu.herfKey}
                          rel="noreferrer noopener"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: "#313131",
                          }}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: open ? row.menu.title : "",
                            }}
                          />
                        </a>
                      ) : (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: open ? row.menu.title : "",
                          }}
                        />
                      )}
                    </ListItemText>
                    {MenuIcon}
                  </ListItemButton>
                </Tooltip>
              </ListItem>
              {row.child && row.child.length > 0 ? (
                <Collapse
                  in={isCollapse[row.menu.key]}
                  timeout="auto"
                  unmountOnExit
                >
                  {row.child.map((ch) => {
                    let menuItem;
                    if (ch.child && ch.child.length > 0) {
                      let childMenu = [];
                      childMenu.push(ch);
                      menuItem = displayMenu(childMenu);
                      if (
                        menuItem.length > 0 &&
                        menuItem[0] !== undefined &&
                        menuItem[0] !== ""
                      ) {
                        childDisplayCount = menuItem.length;
                      }
                    } else {
                      if (props.userPrivilege[ch.menu.module]) {
                        childDisplayCount++;
                      }
                      menuItem = props.userPrivilege[ch.menu.module] ? (
                        <ListItem
                          disablePadding
                          className={
                            isItemActive("/" + ch.menu["key"])
                              ? "primary-active-list-item"
                              : "primary-inactive-list-item"
                          }
                          onClick={() => {
                            localStorage.setItem("Heading", ch.menu.title);
                            if (ch.menu.key) {
                              navigate("/" + ch.menu.key);
                            }
                            console.log("ch.menu.herfKey", ch.menu.herfKey);
                          }}
                        >
                          <Tooltip
                            title={
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: ch.menu.title,
                                }}
                              />
                            }
                            arrow
                            placement="right"
                          >
                            <ListItemButton
                              sx={{
                                minHeight: 48,
                                justifyContent: open ? "initial" : "center",
                                px: 2.5,
                              }}
                            >
                              <ListItemIcon
                                sx={{
                                  minWidth: 0,
                                  mr: open ? 3 : "auto",
                                  justifyContent: "center",
                                }}
                              >
                                {ch.menu.icon}
                              </ListItemIcon>
                              <ListItemText
                                // primary={(ch.menu.title)}
                                sx={{ opacity: open ? 1 : 0 }}
                              >
                                {ch.menu.herfKey ? (
                                  <a
                                    href={ch.menu.herfKey}
                                    rel="noreferrer noopener"
                                    target="_blank"
                                    style={{
                                      textDecoration: "none",
                                      color: "#313131",
                                    }}
                                  >
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: ch.menu.title,
                                      }}
                                    />
                                  </a>
                                ) : (
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: ch.menu.title,
                                    }}
                                  />
                                )}
                              </ListItemText>
                            </ListItemButton>
                          </Tooltip>
                        </ListItem>
                      ) : (
                        ""
                      );
                    }

                    return menuItem;
                  })}
                </Collapse>
              ) : (
                ""
              )}
            </List>
          </>
        );

        if (
          row.child &&
          row.child.length > 0 &&
          childDisplayCount == 0 //||
          //   !props.userPrivilege[row.menu.module]
        ) {
          return "";
        }

        return MenuItem;
      }
    });

    return menu;
  };

  // useEffect(() => {
  //   displayLiveMenu();
  // },
  // [props.userPrivilege]
  // );

  return (
    <Box sx={{ display: "flex" }}>
      <Box height={30} />
      <CssBaseline />
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List className="hover">{displayMenu(menuTree)}</List>
        {/* <List className="hover">{liveMenu}</List> */}
      </Drawer>
      <Grid item>
        <Box display={{ xs: "none" }} style={{ textAlign: "right" }}>
          {ARD}
        </Box>
      </Grid>
    </Box>
  );
};

export default connect(mapStatetoProps, mapSetUserPrivileges_ToProps)(SideNav);
// export default SideNav;
